import { takeEvery, all } from 'redux-saga/effects';

import { clearImportErrorService, importCustomersService } from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* importGroupWatchers() {
  yield all([
    takeEvery(EntryActionTypes.GROUP_IMPORT, ({ payload: { data } }) =>
      importCustomersService(data),
    ),
    takeEvery(EntryActionTypes.GROUP_IMPORT_ERROR_CLEAR, () => clearImportErrorService()),
  ]);
}
