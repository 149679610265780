import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { fetchGroupByCurrentPathRequest } from '../requests';
import {
  currentUserIdSelector,
  notificationsBySellitemIdSelector,
  pathSelector,
  projectManagerByIdSelector,
} from '../../../selectors';
import {
  createProjectManager,
  deleteProjectManager,
  handleProjectManagerCreate,
  handleProjectManagerDelete,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import mergeRecords from '../../../utils/merge-records';

export function* createProjectManagerService(projectId, data) {
  const localId = yield call(createLocalId);

  yield put(
    createProjectManager({
      ...data,
      projectId,
      id: localId,
    }),
  );

  let projectManager;
  try {
    ({ item: projectManager } = yield call(request, api.createProjectManager, projectId, data));
  } catch (error) {
    yield put(createProjectManager.failure(localId, error));
  }

  yield put(createProjectManager.success(localId, projectManager));
}

export function* createManagerInCurrentProjectService(data) {
  const { projectId } = yield select(pathSelector);

  yield call(createProjectManagerService, projectId, data);
}

export function* handleProjectManagerCreateService(projectManager) {
  const currentUserId = yield select(currentUserIdSelector);
  const isCurrentUser = projectManager.userId === currentUserId;

  let user;
  let project;
  let group;
  let users1;
  let users2;
  let projectManagers;
  let groups;
  let groupMemberships1;
  let groupMemberships2;
  let labels;
  let customers;
  let products;
  let bags;
  let bagitems;
  let discounts;
  let sellitems;
  let sellitemMemberships;
  let sellitemLabels;
  let sellitemCustomers;
  let sellitemDiscounts;
  let payments;
  let attachments;
  let notifications;

  if (isCurrentUser) {
    const { groupId } = yield select(pathSelector);

    yield put(
      handleProjectManagerCreate.fetchProject(projectManager.projectId, currentUserId, groupId),
    );

    try {
      ({
        item: project,
        included: { users: users1, projectManagers, groups, groupMemberships: groupMemberships1 },
      } = yield call(request, api.getProject, projectManager.projectId));
    } catch {
      return;
    }

    let body;
    try {
      body = yield call(fetchGroupByCurrentPathRequest);
    } catch {} // eslint-disable-line no-empty

    if (body && body.project && body.project.id === projectManager.projectId) {
      ({
        project,
        group,
        users: users2,
        groupMemberships: groupMemberships2,
        labels,
        customers,
        products,
        bags,
        bagitems,
        discounts,
        sellitems,
        sellitemMemberships,
        sellitemLabels,
        sellitemCustomers,
        sellitemDiscounts,
        payments,
        attachments,
      } = body);

      if (body.sellitem) {
        notifications = yield select(notificationsBySellitemIdSelector, body.sellitem.id);
      }
    }
  } else {
    try {
      ({ item: user } = yield call(request, api.getUser, projectManager.userId));
    } catch {
      return;
    }
  }

  yield put(
    handleProjectManagerCreate(
      projectManager,
      project,
      group,
      isCurrentUser ? mergeRecords(users1, users2) : [user],
      projectManagers,
      groups,
      mergeRecords(groupMemberships1, groupMemberships2),
      labels,
      customers,
      products,
      bags,
      bagitems,
      discounts,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      sellitemCustomers,
      sellitemDiscounts,
      payments,
      attachments,
      notifications,
    ),
  );
}

export function* deleteProjectManagerService(id) {
  let projectManager = yield select(projectManagerByIdSelector, id);

  const currentUserId = yield select(currentUserIdSelector);
  const { projectId } = yield select(pathSelector);

  yield put(
    deleteProjectManager(
      id,
      projectManager.userId === currentUserId,
      projectManager.projectId === projectId,
    ),
  );

  try {
    ({ item: projectManager } = yield call(request, api.deleteProjectManager, id));
  } catch (error) {
    yield put(deleteProjectManager.failure(id, error));
    return;
  }

  yield put(deleteProjectManager.success(projectManager));
}

export function* handleProjectManagerDeleteService(projectManager) {
  const currentUserId = yield select(currentUserIdSelector);
  const { projectId } = yield select(pathSelector);

  yield put(
    handleProjectManagerDelete(
      projectManager,
      projectManager.userId === currentUserId,
      projectManager.projectId === projectId,
    ),
  );
}
