import { call, put, select } from 'redux-saga/effects';

// import { goToBagService } from './router';
import request from '../request';
import { blockByIdSelector, nextProductPositionSelector, pathSelector } from '../../../selectors';
import {
  createProduct,
  deleteProduct,
  fetchProduct,
  handleProductCreate,
  handleProductDelete,
  handleProductUpdate,
  handleProductImport,
  updateProduct,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createProductService(blockId, data) {
  const { groupId } = yield select(blockByIdSelector, blockId);

  const nextData = {
    ...data,
    blockId,
    position: yield select(nextProductPositionSelector, blockId),
  };

  const localId = yield call(createLocalId);

  yield put(
    createProduct({
      ...nextData,
      blockId,
      id: localId,
    }),
  );

  let product;
  try {
    ({ item: product } = yield call(request, api.createProduct, groupId, nextData));
  } catch (error) {
    yield put(createProduct.failure(localId, error));
    return;
  }

  yield put(createProduct.success(localId, product));
  // yield call(goToBagService, groupId);
}

export function* createProductInCurrentGroupService(data) {
  const { groupId } = yield select(pathSelector);

  yield call(createProductService, groupId, data);
}

export function* handleProductCreateService(product) {
  yield put(handleProductCreate(product));
}

export function* fetchProductService(id) {
  yield put(fetchProduct(id));

  let product;
  let sellitems;
  let customers;
  let sellitemMemberships;
  let sellitemLabels;
  let sellitemCustomers;
  let payments;
  let attachments;

  try {
    ({
      item: product,
      included: {
        customers,
        sellitems,
        sellitemMemberships,
        sellitemLabels,
        sellitemCustomers,
        payments,
        attachments,
      },
    } = yield call(request, api.getProduct, id));
  } catch (error) {
    yield put(fetchProduct.failure(id, error));
    return;
  }

  yield put(
    fetchProduct.success(
      product,
      customers,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      sellitemCustomers,
      payments,
      attachments,
    ),
  );
}

export function* updateProductService(id, data) {
  yield put(updateProduct(id, data));

  let product;
  try {
    ({ item: product } = yield call(request, api.updateProduct, id, data));
  } catch (error) {
    yield put(updateProduct.failure(id, error));
    return;
  }

  yield put(updateProduct.success(product));
}

export function* handleProductUpdateService(product) {
  yield put(handleProductUpdate(product));
}

export function* moveProductService(id, blockId, index) {
  const position = yield select(nextProductPositionSelector, blockId, index, id);

  yield call(updateProductService, id, {
    blockId,
    position,
  });
}

export function* moveCurrentProductService(blockId, index) {
  const { productId } = yield select(pathSelector);

  yield call(moveProductService, productId, blockId, index);
}

export function* deleteProductService(id) {
  yield put(deleteProduct(id));

  let product;
  try {
    ({ item: product } = yield call(request, api.deleteProduct, id));
  } catch (error) {
    yield put(deleteProduct.failure(id, error));
    return;
  }

  yield put(deleteProduct.success(product));
}

export function* handleProductDeleteService(product) {
  yield put(handleProductDelete(product));
}

export function* handleProductImportService(products, bagitems) {
  yield put(handleProductImport(products, bagitems));
}
