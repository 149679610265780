import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Discount';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    description: attr(),
    formula: attr(),
    type: attr(),
    amount: attr(),
    quantity: attr(),
    isClosed: attr(),
    bagId: fk({
      to: 'Bag',
      as: 'bag',
      relatedName: 'discounts',
    }),
    agentId: fk({
      to: 'Agent',
      as: 'agent',
      relatedName: 'discounts',
    }),
  };

  static reducer({ type, payload }, Discount) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
        if (payload.discounts) {
          payload.discounts.forEach((discount) => {
            Discount.upsert(discount);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Discount.all().delete();

        if (payload.discounts) {
          payload.discounts.forEach((discount) => {
            Discount.upsert(discount);
          });
        }

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_CREATE_HANDLE:
      case ActionTypes.GROUP_CREATE__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        if (payload.discounts) {
          payload.discounts.forEach((discount) => {
            Discount.upsert(discount);
          });
        }

        break;
      case ActionTypes.DISCOUNT_CREATE:
        Discount.upsert(payload.discount);

        break;
      case ActionTypes.DISCOUNT_CREATE__SUCCESS:
        Discount.withId(payload.localId).delete();
        Discount.upsert(payload.discount);

        break;
      case ActionTypes.DISCOUNT_CREATE_HANDLE:
        Discount.upsert(payload.discount);

        if (payload.discounts) {
          payload.discounts.forEach((discount) => {
            Discount.upsert(discount);
          });
        }

        break;
      case ActionTypes.DISCOUNT_UPDATE__SUCCESS:
        Discount.upsert(payload.discount);

        break;
      case ActionTypes.DISCOUNT_DELETE:
        Discount.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.DISCOUNT_DELETE__SUCCESS:
      case ActionTypes.DISCOUNT_DELETE_HANDLE: {
        const discountModel = Discount.withId(payload.discount.id);

        if (discountModel) {
          discountModel.deleteWithRelated();
        }

        break;
      }
      default:
    }
  }

  getOrderedProductsQuerySet() {
    return this.product.orderBy('name');
  }

  deleteRelated() {
    this.group.bags.toModelArray().forEach((bagModel) => {
      try {
        bagModel.users.remove(this.userId);
      } catch {} // eslint-disable-line no-empty
    });

    try {
      this.group.filterUsers.remove(this.userId);
    } catch {} // eslint-disable-line no-empty
  }

  deleteWithRelated() {
    // this.deleteRelated();
    this.delete();
  }
}
