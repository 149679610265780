import EntryActionTypes from '../../constants/EntryActionTypes';

export const importGroup = (data) => ({
  type: EntryActionTypes.GROUP_IMPORT,
  payload: {
    data,
  },
});

export const clearGroupImportError = () => ({
  type: EntryActionTypes.GROUP_IMPORT_ERROR_CLEAR,
  payload: {},
});
