import ActionTypes from '../constants/ActionTypes';

export const createDiscount = (discount) => ({
  type: ActionTypes.DISCOUNT_CREATE,
  payload: {
    discount,
  },
});

createDiscount.success = (localId, discount, bag) => ({
  type: ActionTypes.DISCOUNT_CREATE__SUCCESS,
  payload: {
    localId,
    discount,
    bag,
  },
});

createDiscount.failure = (localId, error) => ({
  type: ActionTypes.DISCOUNT_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleDiscountCreate = (discount) => ({
  type: ActionTypes.DISCOUNT_CREATE_HANDLE,
  payload: {
    discount,
  },
});

export const updateDiscount = (id, data) => ({
  type: ActionTypes.DISCOUNT_UPDATE,
  payload: {
    id,
    data,
  },
});

updateDiscount.success = (discount, bag) => ({
  type: ActionTypes.DISCOUNT_UPDATE__SUCCESS,
  payload: {
    discount,
    bag,
  },
});

updateDiscount.failure = (id, error) => ({
  type: ActionTypes.DISCOUNT_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleDiscountUpdate = (discount) => ({
  type: ActionTypes.DISCOUNT_UPDATE_HANDLE,
  payload: {
    discount,
  },
});

export const deleteDiscount = (id) => ({
  type: ActionTypes.DISCOUNT_DELETE,
  payload: {
    id,
  },
});

deleteDiscount.success = (discount) => ({
  type: ActionTypes.DISCOUNT_DELETE__SUCCESS,
  payload: {
    discount,
  },
});

deleteDiscount.failure = (id, error) => ({
  type: ActionTypes.DISCOUNT_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleDiscountDelete = (discount) => ({
  type: ActionTypes.DISCOUNT_DELETE_HANDLE,
  payload: {
    discount,
  },
});
