import { Model, attr, fk, many, oneToOne } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';
import Config from '../constants/Config';
import { Statuses } from '../constants/Enums';

export default class extends Model {
  static modelName = 'Sellitem';

  static fields = {
    id: attr(),
    invoice: attr(),
    position: attr(),
    name: attr(),
    description: attr(),
    sellPrice: attr(),
    discount1: attr(),
    discount2: attr(),
    discount3: attr(),
    discount4: attr(),
    discount5: attr(),
    total: attr(),
    totalForCommission: attr(),
    commissionPercent: attr(),
    bonus: attr(),
    status: attr(),
    legalStatus: attr(),
    paymentMethod: attr(),
    bookDate: attr(),
    dueDate: attr(),
    timer: attr(),
    isSubscribed: attr({
      getDefault: () => false,
    }),
    isActionsFetching: attr({
      getDefault: () => false,
    }),
    isAllActionsFetched: attr({
      getDefault: () => false,
    }),
    isDenied: attr({
      getDefault: () => false,
    }),
    groupId: fk({
      to: 'Group',
      as: 'group',
      relatedName: 'sellitems',
    }),
    agentId: fk({
      to: 'Agent',
      as: 'agent',
      relatedName: 'sellitems',
    }),
    productId: fk({
      to: 'Product',
      as: 'product',
      relatedName: 'sellitems',
    }),
    bagitemId: fk({
      to: 'Bagitem',
      as: 'bagitem',
      relatedName: 'sellitems',
    }),
    coverAttachmentId: oneToOne({
      to: 'Attachment',
      as: 'coverAttachment',
      relatedName: 'coveredSellitem',
    }),
    users: many('User', 'sellitems'),
    labels: many('Label', 'sellitems'),
    customers: many('Customer', 'sellitems'),
    // discounts: many('Discount', 'sellitems'),
    discounts: many({
      to: 'Discount',
      through: 'SellitemDiscount',
      relatedName: 'sellitems',
    }),
  };

  static reducer({ type, payload }, Sellitem) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.BAG_RECALCULATE_COMMISSION__SUCCESS:
      case ActionTypes.SELLITEMS_FETCH__SUCCESS:
        if (payload.sellitems) {
          payload.sellitems.forEach((sellitem) => {
            Sellitem.upsert(sellitem);
          });
        }

        if (payload.sellitemMemberships) {
          payload.sellitemMemberships.forEach(({ sellitemId, userId }) => {
            Sellitem.withId(sellitemId).users.add(userId);
          });
        }

        if (payload.sellitemLabels) {
          payload.sellitemLabels.forEach(({ sellitemId, labelId }) => {
            Sellitem.withId(sellitemId).labels.add(labelId);
          });
        }

        if (payload.sellitemCustomers) {
          payload.sellitemCustomers.forEach(({ sellitemId, customerId }) => {
            Sellitem.withId(sellitemId).customers.add(customerId);
          });
        }

        // if (payload.sellitemDiscounts) {
        //   payload.sellitemDiscounts.forEach(({ sellitemId, discountId }) => {
        //     Sellitem.withId(sellitemId).discounts.add(discountId);
        //   });
        // }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Sellitem.all().delete();

        if (payload.sellitems) {
          payload.sellitems.forEach((sellitem) => {
            Sellitem.upsert(sellitem);
          });
        }

        if (payload.sellitemMemberships) {
          payload.sellitemMemberships.forEach(({ sellitemId, userId }) => {
            Sellitem.withId(sellitemId).users.add(userId);
          });
        }

        if (payload.sellitemLabels) {
          payload.sellitemLabels.forEach(({ sellitemId, labelId }) => {
            Sellitem.withId(sellitemId).labels.add(labelId);
          });
        }

        if (payload.sellitemCustomers) {
          payload.sellitemCustomers.forEach(({ sellitemId, customerId }) => {
            Sellitem.withId(sellitemId).customers.add(customerId);
          });
        }

        // if (payload.sellitemDiscounts) {
        //   payload.sellitemDiscounts.forEach(({ sellitemId, discountId }) => {
        //     Sellitem.withId(sellitemId).discounts.add(discountId);
        //   });
        // }

        break;
      case ActionTypes.USER_TO_SELLITEM_ADD: {
        const sellitemModel = Sellitem.withId(payload.sellitemId);
        sellitemModel.users.add(payload.id);

        if (payload.isCurrent) {
          sellitemModel.isSubscribed = true;
        }

        break;
      }
      case ActionTypes.USER_TO_SELLITEM_ADD__SUCCESS:
      case ActionTypes.USER_TO_SELLITEM_ADD_HANDLE:
        try {
          Sellitem.withId(payload.sellitemMembership.sellitemId).users.add(
            payload.sellitemMembership.userId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      case ActionTypes.USER_FROM_SELLITEM_REMOVE:
        Sellitem.withId(payload.sellitemId).users.remove(payload.id);

        break;
      case ActionTypes.USER_FROM_SELLITEM_REMOVE__SUCCESS:
      case ActionTypes.USER_FROM_SELLITEM_REMOVE_HANDLE:
        try {
          Sellitem.withId(payload.sellitemMembership.sellitemId).users.remove(
            payload.sellitemMembership.userId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      case ActionTypes.CUSTOMER_FETCH__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.sellitems.forEach((sellitem) => {
          Sellitem.upsert(sellitem);
        });

        payload.sellitemMemberships.forEach(({ sellitemId, userId }) => {
          Sellitem.withId(sellitemId).users.add(userId);
        });

        payload.sellitemLabels.forEach(({ sellitemId, labelId }) => {
          Sellitem.withId(sellitemId).labels.add(labelId);
        });

        payload.sellitemCustomers.forEach(({ sellitemId, customerId }) => {
          Sellitem.withId(sellitemId).customers.add(customerId);
        });

        // if (payload.sellitemDiscounts) {
        //   payload.sellitemDiscounts.forEach(({ sellitemId, discountId }) => {
        //     Sellitem.withId(sellitemId).discounts.add(discountId);
        //   });
        // }

        break;
      case ActionTypes.LABEL_TO_SELLITEM_ADD:
        Sellitem.withId(payload.sellitemId).labels.add(payload.id);

        break;
      case ActionTypes.LABEL_TO_SELLITEM_ADD__SUCCESS:
      case ActionTypes.LABEL_TO_SELLITEM_ADD_HANDLE:
        try {
          Sellitem.withId(payload.sellitemLabel.sellitemId).labels.add(
            payload.sellitemLabel.labelId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      case ActionTypes.LABEL_FROM_SELLITEM_REMOVE:
        Sellitem.withId(payload.sellitemId).labels.remove(payload.id);

        break;
      case ActionTypes.LABEL_FROM_SELLITEM_REMOVE__SUCCESS:
      case ActionTypes.LABEL_FROM_SELLITEM_REMOVE_HANDLE:
        try {
          Sellitem.withId(payload.sellitemLabel.sellitemId).labels.remove(
            payload.sellitemLabel.labelId,
          );
        } catch {} // eslint-disable-line no-empty

        break;

      // case ActionTypes.CUSTOMER_TO_SELLITEM_ADD:
      //   Sellitem.withId(payload.sellitemCustomer.sellitemId).customers.add(payload.id);

      //   break;
      case ActionTypes.CUSTOMER_CREATE__SUCCESS:
      case ActionTypes.CUSTOMER_TO_SELLITEM_ADD__SUCCESS:
      case ActionTypes.CUSTOMER_TO_SELLITEM_ADD_HANDLE:
        try {
          if (payload.sellitemCustomer) {
            Sellitem.withId(payload.sellitemCustomer.sellitemId).customers.add(
              payload.sellitemCustomer.customerId,
            );
          }
        } catch {} // eslint-disable-line no-empty

        break;
      // case ActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE:
      //   Sellitem.withId(payload.sellitemId).customers.remove(payload.customerId);

      //   break;
      case ActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE__SUCCESS:
      case ActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE_HANDLE:
        try {
          Sellitem.withId(payload.sellitemCustomer.sellitemId).customers.remove(
            payload.sellitemCustomer.customerId,
          );
        } catch {} // eslint-disable-line no-empty

        break;

      case ActionTypes.SELLITEM_CREATE_HANDLE:
        Sellitem.upsert(payload.sellitem);

        break;
      case ActionTypes.SELLITEM_UPDATE__SUCCESS:
      case ActionTypes.SELLITEM_UPDATE_HANDLE: {
        const sellitem = Sellitem.withId(payload.sellitem.id);

        if (
          sellitem.status === Statuses.REVIEW.name &&
          payload.sellitem.status === Statuses.BOOKING.name
        ) {
          sellitem.update({
            ...payload.sellitem,
            isDenied: true,
          });
        } else {
          sellitem.update(payload.sellitem);
        }

        break;
      }
      case ActionTypes.SELLITEM_CREATE__SUCCESS:
        Sellitem.upsert(payload.sellitem);

        break;
      case ActionTypes.SELLITEM_UPDATE:
        Sellitem.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.SELLITEM_DELETE:
        Sellitem.withId(payload.id).delete();

        break;
      case ActionTypes.SELLITEM_DELETE__SUCCESS:
      case ActionTypes.SELLITEM_DELETE_HANDLE: {
        const sellitemModel = Sellitem.withId(payload.sellitem.id);

        if (sellitemModel) {
          sellitemModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.ACTIONS_FETCH:
        Sellitem.withId(payload.sellitemId).update({
          isActionsFetching: true,
        });

        break;
      case ActionTypes.ACTIONS_FETCH__SUCCESS:
        Sellitem.withId(payload.sellitemId).update({
          isActionsFetching: false,
          isAllActionsFetched: payload.actions.length < Config.SELLITEMS_LIMIT,
        });

        break;
      case ActionTypes.CUSTOMERS_FETCH__SUCCESS:
      case ActionTypes.BAG_FETCH__SUCCESS:
      case ActionTypes.NOTIFICATION_CREATE_HANDLE:
        if (payload.sellitems) {
          payload.sellitems.forEach((sellitem) => {
            Sellitem.upsert(sellitem);
          });
        }

        break;
      // case ActionTypes.DISCOUNT_TO_SELLITEM_ADD:
      //   Sellitem.withId(payload.sellitemDiscount.sellitemId).discounts.add(
      //     payload.sellitemDiscount.discountId,
      //   );
      //   break;
      // case ActionTypes.DISCOUNT_TO_SELLITEM_ADD__SUCCESS:
      // case ActionTypes.DISCOUNT_TO_SELLITEM_ADD_HANDLE:
      //   try {
      //     Sellitem.withId(payload.sellitemDiscount.sellitemId).discounts.add(
      //       payload.sellitemDiscount.discountId,
      //     );
      //   } catch {} // eslint-disable-line no-empty

      //   break;
      // case ActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE:
      //   Sellitem.withId(payload.sellitemId).discounts.remove(payload.discountId);
      //   break;
      // case ActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE__SUCCESS:
      // case ActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE_HANDLE:
      //   try {
      //     Sellitem.withId(payload.sellitemDiscount.sellitemId).discounts.remove(
      //       payload.sellitemDiscount.discountId,
      //     );
      //   } catch {} // eslint-disable-line no-empty

      //   break;

      default:
    }
  }

  getCreatorQuerySet() {
    return this.user;
  }

  getProductQuerySet() {
    return this.product;
  }

  getOrderedPaymentsQuerySet() {
    return this.payments.orderBy('id');
  }

  getOrderedCustomersQuerySet() {
    return this.customers.orderBy('id');
  }

  getOrderedDiscountsQuerySet() {
    return this.discounts.orderBy('id');
  }

  getOrderedAttachmentsQuerySet() {
    return this.attachments.orderBy('id', false);
  }

  getOrderedInSellitemActionsQuerySet() {
    return this.actions.orderBy('id', false);
  }

  getUnreadNotificationsQuerySet() {
    return this.notifications.filter({
      isRead: false,
    });
  }

  deleteRelated() {
    this.payments.delete();
    this.customers.delete();
    this.discounts.delete();
    this.attachments.delete();
    this.actions.delete();
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
