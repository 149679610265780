import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List, Button } from 'semantic-ui-react';
import NameEdit from '../CustomerItem/NameEdit';
import AddPopup from './Addresses/AddPopup';
import Actions from './Actions';

import styles from './RightSidebar.module.scss';

const RightSidebar = React.memo(
  ({
    currentUser,
    canEdit,
    actions,
    sellitem,
    customer,
    canEditAllCommentActions,
    onUpdateCustomer,
    onActionsFetch,
    onCommentActionCreate,
    onCommentActionUpdate,
    onCommentActionDelete,
  }) => {
    const [t] = useTranslation();

    const phoneEdit = useRef(null);
    const emailEdit = useRef(null);
    const idNumberEdit = useRef(null);
    const nameEdit = useRef(null);

    const handleHeaderClick = useCallback(
      (field) => {
        if (canEdit) {
          switch (field) {
            case 'name':
              nameEdit.current.open();
              break;
            case 'phone':
              phoneEdit.current.open();
              break;
            case 'email':
              emailEdit.current.open();
              break;
            case 'idNumber':
              idNumberEdit.current.open();
              break;
            default:
          }
        }
      },
      [canEdit],
    );

    const handleFieldUpdate = useCallback(
      (data, field) => {
        switch (field) {
          case 'name':
            onUpdateCustomer(customer?.id, {
              name: data,
            });
            break;
          case 'phone':
            onUpdateCustomer(customer?.id, {
              phone: data,
            });
            break;
          case 'email':
            onUpdateCustomer(customer?.id, {
              email: data,
            });
            break;
          case 'idNumber':
            onUpdateCustomer(customer?.id, {
              idNumber: data,
            });
            break;
          default:
        }
      },
      [customer, onUpdateCustomer],
    );

    return (
      <div className={`scroller ${styles.wrapper}`}>
        {customer && (
          <List className={styles.listCustomer}>
            <List.Item className={styles.listItem}>
              <List.Content
                className={styles.listValueName}
                onClick={() => handleHeaderClick('name')}
              >
                {canEdit ? (
                  <NameEdit
                    ref={nameEdit}
                    defaultValue={customer.name}
                    onUpdate={(data) => handleFieldUpdate(data, 'name')}
                  >
                    <h3 className={styles.headerName}>{customer.name}</h3>
                  </NameEdit>
                ) : (
                  <h3 className={styles.headerName}>{customer.name}</h3>
                )}
              </List.Content>
            </List.Item>
            <List.Item className={styles.listItem}>
              <List.Content floated="right">
                <AddPopup onUpdate={onUpdateCustomer} defaultData={customer}>
                  <Button
                    basic
                    size="mini"
                    content={customer?.address || '...'}
                    className={styles.editAddressButton}
                    disabled={!canEdit}
                  />
                </AddPopup>
              </List.Content>
              <List.Content className={styles.listLabel}>
                {t('common.customerGroup.address')}
              </List.Content>
            </List.Item>
            <List.Item className={styles.listItem}>
              <List.Content
                className={styles.listValue}
                floated="right"
                onClick={() => handleHeaderClick('phone')}
              >
                {canEdit ? (
                  <NameEdit
                    ref={phoneEdit}
                    defaultValue={customer.phone}
                    onUpdate={(data) => handleFieldUpdate(data, 'phone')}
                  >
                    <div className={styles.headerName}>{customer.phone}</div>
                  </NameEdit>
                ) : (
                  <div className={styles.headerName}>{customer.phone}</div>
                )}
              </List.Content>
              <List.Content className={styles.listLabel}>
                {t('common.customerGroup.phone')}
              </List.Content>
            </List.Item>
            <List.Item className={styles.listItem}>
              <List.Content
                className={styles.listValue}
                floated="right"
                onClick={() => handleHeaderClick('email')}
              >
                {canEdit ? (
                  <NameEdit
                    ref={emailEdit}
                    defaultValue={customer.email || ''}
                    onUpdate={(data) => handleFieldUpdate(data, 'email')}
                  >
                    <div className={styles.headerName}>{customer.email || ''}</div>
                  </NameEdit>
                ) : (
                  <div className={styles.headerName}>{customer.email || ''}</div>
                )}
              </List.Content>
              <List.Content className={styles.listLabel}>
                {t('common.customerGroup.email')}
              </List.Content>
            </List.Item>
            <List.Item className={styles.listItem}>
              <List.Content
                className={styles.listValue}
                floated="right"
                onClick={() => handleHeaderClick('idNumber')}
              >
                {canEdit ? (
                  <NameEdit
                    ref={idNumberEdit}
                    defaultValue={customer.idNumber || ''}
                    onUpdate={(data) => handleFieldUpdate(data, 'idNumber')}
                  >
                    <div className={styles.headerName}>{customer.idNumber || ''}</div>
                  </NameEdit>
                ) : (
                  <div className={styles.headerName}>{customer.idNumber || ''}</div>
                )}
              </List.Content>
              <List.Content className={styles.listLabel}>
                {t('common.customerGroup.idNumber')}
              </List.Content>
            </List.Item>
            {/* <List.Item className={styles.listItemTotal}>
              <List.Content className={styles.listValue} floated="right">
                <div className={styles.headerName}>{0}</div>
              </List.Content>
              <List.Content className={styles.listLabel}>
                {t('common.customerGroup.totalProductBought')}
              </List.Content>
            </List.Item>
            <List.Item className={styles.listItemTotal}>
              <List.Content className={styles.listValue} floated="right">
                <div className={styles.headerName}>{0}</div>
              </List.Content>
              <List.Content className={styles.listLabel}>
                {t('common.customerGroup.totalProductAmount')}
              </List.Content>
            </List.Item>
            <List.Item className={styles.listItemTotal}>
              <List.Content className={styles.listValue} floated="right">
                <div className={styles.headerName}>{0}</div>
              </List.Content>
              <List.Content className={styles.listLabel}>
                {t('common.customerGroup.totalAmountPaid')}
              </List.Content>
            </List.Item>
            <List.Item className={styles.listItemTotal}>
              <List.Content className={styles.listValue} floated="right">
                <div className={styles.headerName}>{0}</div>
              </List.Content>
              <List.Content className={styles.listLabel}>
                {t('common.customerGroup.totalAmountNotPaid')}
              </List.Content>
            </List.Item> */}
          </List>
        )}
        <Actions
          currentUser={currentUser}
          items={actions}
          isFetching={sellitem.isActionsFetching}
          isAllFetched={sellitem.isAllActionsFetched}
          canEdit={canEdit}
          canEditAllComments={canEditAllCommentActions}
          onFetch={onActionsFetch}
          onCommentCreate={onCommentActionCreate}
          onCommentUpdate={onCommentActionUpdate}
          onCommentDelete={onCommentActionDelete}
        />
      </div>
    );
  },
);

RightSidebar.propTypes = {
  currentUser: PropTypes.objectOf(PropTypes.shape).isRequired,
  canEditAllCommentActions: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.object()).isRequired,
  sellitem: PropTypes.objectOf(PropTypes.shape).isRequired,
  onUpdateCustomer: PropTypes.func.isRequired,
  customer: PropTypes.objectOf(PropTypes.shape),
  onActionsFetch: PropTypes.func.isRequired,
  onCommentActionCreate: PropTypes.func.isRequired,
  onCommentActionUpdate: PropTypes.func.isRequired,
  onCommentActionDelete: PropTypes.func.isRequired,
};

RightSidebar.defaultProps = {
  customer: undefined,
};

export default RightSidebar;
