import isEmail from 'validator/lib/isEmail';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Header, Message, Segment } from 'semantic-ui-react';
import { useDidUpdate, usePrevious, useToggle } from '../../lib/hooks';
import { Input } from '../../lib/custom-ui';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as Cover } from '../../assets/images/cover.svg';

import { useForm } from '../../hooks';
import { isUsername } from '../../utils/validator';

import styles from './Login.module.scss';

const createMessage = (error) => {
  if (!error) {
    return error;
  }

  switch (error.message) {
    case 'Invalid email or username':
      return {
        type: 'error',
        content: 'common.invalidEmailOrUsername',
      };
    case 'Invalid password':
      return {
        type: 'error',
        content: 'common.invalidPassword',
      };
    case 'User disbled':
      return {
        type: 'error',
        content: 'common.userDisble',
      };
    case 'Failed to fetch':
      return {
        type: 'warning',
        content: 'common.noInternetConnection',
      };
    case 'Network request failed':
      return {
        type: 'warning',
        content: 'common.serverConnectionFailed',
      };
    default:
      return {
        type: 'warning',
        content: 'common.unknownError',
      };
  }
};

const Login = React.memo(
  ({ defaultData, isSubmitting, error, onAuthenticate, onMessageDismiss }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [data, handleFieldChange, setData] = useForm(() => ({
      emailOrUsername: '',
      password: '',
      ...defaultData,
    }));

    const message = useMemo(() => createMessage(error), [error]);
    const [focusPasswordFieldState, focusPasswordField] = useToggle();

    const emailOrUsernameField = useRef(null);
    const passwordField = useRef(null);

    const handleSubmit = useCallback(() => {
      const cleanData = {
        ...data,
        emailOrUsername: data.emailOrUsername.trim(),
      };

      if (!isEmail(cleanData.emailOrUsername) && !isUsername(cleanData.emailOrUsername)) {
        emailOrUsernameField.current.select();
        return;
      }

      if (!cleanData.password) {
        passwordField.current.focus();
        return;
      }

      onAuthenticate(cleanData);
    }, [onAuthenticate, data]);

    useEffect(() => {
      emailOrUsernameField.current.select();
    }, []);

    useEffect(() => {
      if (wasSubmitting && !isSubmitting && error) {
        switch (error.message) {
          case 'Invalid email or username':
            emailOrUsernameField.current.select();

            break;
          case 'Invalid password':
            setData((prevData) => ({
              ...prevData,
              password: '',
            }));
            focusPasswordField();

            break;
          default:
        }
      }
    }, [isSubmitting, wasSubmitting, error, setData, focusPasswordField]);

    useDidUpdate(() => {
      passwordField.current.focus();
    }, [focusPasswordFieldState]);

    return (
      <Grid
        textAlign="center"
        style={{ height: '100vh', backgroundColor: 'white' }}
        verticalAlign="middle"
      >
        <Grid.Column widescreen={8} largeScreen={8} computer={8} only="computer">
          <div className={styles.descriptionWrapper}>
            <Header as="h1" color="teal" content="TiciLand" className={styles.descriptionTitle} />
            <Header
              as="h2"
              content={t('common.projectManagement')}
              className={styles.descriptionSubtitle}
            />
            <Cover />
          </div>
        </Grid.Column>
        <Grid.Column widescreen={4} largeScreen={5} computer={6} tablet={16} mobile={16}>
          <Header as="h1" color="teal" textAlign="center">
            <Logo className={styles.logo} />
            {t('common.logInToTiciLand')}
          </Header>
          {message && (
            <Message
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                [message.type]: true,
              }}
              visible
              content={t(message.content)}
              onDismiss={onMessageDismiss}
            />
          )}
          <Form size="large" onSubmit={handleSubmit}>
            <Segment stacked>
              <Form.Field>
                <Input
                  fluid
                  ref={emailOrUsernameField}
                  name="emailOrUsername"
                  value={data.emailOrUsername}
                  readOnly={isSubmitting}
                  className={styles.input}
                  icon="user"
                  iconPosition="left"
                  placeholder={t('common.emailOrUsername')}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field>
                <Input.Password
                  fluid
                  ref={passwordField}
                  name="password"
                  value={data.password}
                  readOnly={isSubmitting}
                  className={styles.input}
                  icon="lock"
                  iconPosition="left"
                  placeholder={t('common.password')}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Button
                primary
                size="large"
                icon="right arrow"
                labelPosition="right"
                content={t('action.logIn')}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </Segment>
          </Form>
          {/* <Message>
            New to us? <Link to=" ">Sign Up</Link>
          </Message> */}
        </Grid.Column>
      </Grid>
    );
  },
);

Login.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onAuthenticate: PropTypes.func.isRequired,
  onMessageDismiss: PropTypes.func.isRequired,
};

Login.defaultProps = {
  error: undefined,
};

export default Login;
