import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Icon, Radio, Table } from 'semantic-ui-react';

import DeletePopup from '../DeletePopup';
import Roles from '../../constants/Roles';

import styles from './Item.module.scss';

const Item = React.memo(({ name, username, email, role, isAdmin, status, onUpdate, onDelete }) => {
  const [t] = useTranslation();
  const userStatus = [
    {
      key: 'active',
      text: t(`common.active`),
      value: 'active',
      icon: { avatar: 'true', name: 'user' },
    },
    {
      key: 'pause',
      text: t(`common.pause`),
      value: 'pause',
      icon: { avatar: 'true', name: 'user outline' },
    },
    {
      key: 'disable',
      text: t(`common.disable`),
      value: 'disable',
      icon: { avatar: 'true', name: 'user x' },
    },
  ];

  const handleRoleChange = useCallback(
    (e, { value }) => {
      onUpdate({
        role: value,
      });
    },
    [onUpdate],
  );

  const handleIsAdminChange = useCallback(() => {
    onUpdate({
      isAdmin: !isAdmin,
    });
  }, [isAdmin, onUpdate]);

  const handleStatusChange = useCallback(
    (event, selected) => {
      onUpdate({
        status: selected.value,
      });
    },
    [onUpdate],
  );

  return (
    <Table.Row>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{username || '-'}</Table.Cell>
      <Table.Cell>{email}</Table.Cell>
      <Table.Cell collapsing>
        <Icon name={userStatus.filter((sts) => sts.value === status)[0].icon.name} />
        <Dropdown inline options={userStatus} value={status} onChange={handleStatusChange} />
      </Table.Cell>
      <Table.Cell collapsing>
        <Radio toggle checked={isAdmin} onChange={handleIsAdminChange} />
      </Table.Cell>
      <Table.Cell collapsing>
        <Dropdown
          fluid
          selection
          name="newRole"
          options={Roles.map((key) => ({
            text: t(`role.${key}`),
            value: key,
          }))}
          value={role}
          placeholder={t('role.role')}
          className={styles.field}
          onChange={handleRoleChange}
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <DeletePopup
          title={t('common.deleteUser', {
            context: 'title',
          })}
          content={t('common.areYouSureYouWantToDeleteThisUser')}
          buttonContent={t('action.deleteUser')}
          onConfirm={onDelete}
        >
          <Button content={t('action.delete')} className={styles.button} />
        </DeletePopup>
      </Table.Cell>
    </Table.Row>
  );
});

Item.propTypes = {
  name: PropTypes.string.isRequired,
  username: PropTypes.string,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

Item.defaultProps = {
  username: undefined,
};

export default Item;
