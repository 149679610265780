import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { Comment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { ActionTypes, Statuses } from '../../../constants/Enums';
import ItemComment from './ItemComment';
import User from '../../User';
import Paths from '../../../constants/Paths';

import styles from './Item.module.scss';

const Item = React.memo(({ type, data, createdAt, user }) => {
  const [t] = useTranslation();

  let contentNode;
  switch (type) {
    case ActionTypes.CREATE_SELLITEM:
      contentNode = (
        <Trans i18nKey="actions.userCreatedThisSellitem">
          <span className={styles.text}>{' added this sellitem'}</span>
        </Trans>
      );

      break;
    case ActionTypes.MOVE_SELLITEM:
      if (data.oldValue.name) {
        contentNode = (
          <Trans
            i18nKey="actions.userMovedThisSellitemFromCustomerToCustomer"
            values={{
              fromCustomer: data.oldValue?.name,
              toCustomer: data.newValue?.name,
            }}
          >
            <span className={styles.text}>
              {' moved this sellitem from '}
              {data.fromCustomer}
              {' to '}
              {data.toCustomer}
            </span>
          </Trans>
        );
      } else {
        contentNode = (
          <Trans
            i18nKey="actions.userAddedThisSellitemToCustomer"
            values={{
              customer: data.newValue?.name,
            }}
          >
            <span className={styles.text}>
              {' added customer '}
              {data.customer}
              {' to sellitem'}
            </span>
          </Trans>
        );
      }

      break;
    case ActionTypes.UPDATE_SELLITEM:
      switch (data.prop) {
        case 'status':
          switch (data.oldValue) {
            case Statuses.REVIEW.name:
              if (data.newValue === Statuses.BOOKING.name) {
                if (['manager', 'director'].includes(user.role)) {
                  contentNode = (
                    <Trans
                      i18nKey="actions.userRefuseSellitem"
                      values={{
                        note: data.note || '',
                      }}
                    >
                      <span className={styles.text}>
                        {' refuse with reason '}
                        {data.note}
                      </span>
                    </Trans>
                  );
                } else {
                  contentNode = (
                    <Trans i18nKey="actions.userCanceledSellitem">
                      <span className={styles.text}>{' user canceled sellitem '}</span>
                    </Trans>
                  );
                }
              } else {
                contentNode = (
                  <Trans
                    i18nKey="actions.userApprovedSellitem"
                    values={{
                      note: data.note || '',
                    }}
                  >
                    <span className={styles.text}>
                      {' approved with note '}
                      {data.note}
                    </span>
                  </Trans>
                );
              }
              break;
            case Statuses.REVIEWCANCEL.name:
              if (data.newValue === Statuses.CANCELED.name) {
                contentNode = (
                  <Trans
                    i18nKey="actions.userApprovedCanCelSellitem"
                    values={{
                      note: data.note || '',
                    }}
                  >
                    <span className={styles.text}>
                      {' approved with reason '}
                      {data.note}
                    </span>
                  </Trans>
                );
              } else {
                contentNode = (
                  <Trans
                    i18nKey="actions.userRefuseCancelSellitem"
                    values={{
                      note: data.note || '',
                    }}
                  >
                    <span className={styles.text}>
                      {' refuse with note '}
                      {data.note}
                    </span>
                  </Trans>
                );
              }
              break;
            default:
              contentNode = (
                <Trans
                  i18nKey="actions.userUpdatedSellitemStatus"
                  values={{
                    // fromStatus: t(`status.${data.oldValue}`),
                    toStatus: t(`status.${data.newValue}`),
                  }}
                >
                  <span className={styles.text}>
                    {' updated status this sellitem to '}
                    {data.toStatus}
                  </span>
                </Trans>
              );
          }
          if (data.newValue === Statuses.REVIEWCANCEL.name) {
            contentNode = (
              <Trans
                i18nKey="actions.userReviewCanceledSellitem"
                values={{
                  note: data.note || '',
                }}
              >
                <span className={styles.text}>
                  {' review cancel this sellitem with reason '}
                  {data.note}
                </span>
              </Trans>
            );
          }
          break;
        case 'productId':
          contentNode = (
            <Trans
              i18nKey="actions.userUpdatedSellitemProduct"
              values={{
                fromProduct: data.oldProduct?.name,
                toProduct: data.newProduct?.name,
              }}
            >
              <span className={styles.text}>
                {' updated status this sellitem from '}
                {data.fromProduct}
                {' to '}
                {data.toProduct}
              </span>
            </Trans>
          );
          break;
        case 'agentId':
          contentNode = (
            <Trans
              i18nKey="actions.userUpdatedSellitemAgent"
              values={{
                fromAgent: data.oldAgent?.name || '',
                toAgent: data.newAgent?.name || '',
              }}
            >
              <span className={styles.text}>
                {' updated agent this sellitem from '}
                {data.fromAgent}
                {' to '}
                {data.toAgent}
              </span>
            </Trans>
          );
          break;
        case 'bonus':
        case 'discount1':
        case 'discount2':
        case 'discount3':
        case 'discount4':
        case 'discount5':
          contentNode = (
            <Trans
              i18nKey="actions.userUpdatedSellitemField"
              values={{
                fieldName: t(`common.${data.prop}`),
                fromValue: (data.oldValue * 1).toLocaleString('en-GB'),
                toValue: (data.newValue * 1).toLocaleString('en-GB'),
              }}
            >
              <span className={styles.text}>
                {' updated '}
                {data.fieldName}
                {' this sellitem from '}
                {data.fromAgent}
                {' to '}
                {data.toAgent}
              </span>
            </Trans>
          );
          break;
        case 'discounts':
          if (data.newValue) {
            contentNode = (
              <Trans
                i18nKey="actions.userAddedDiscountToThisSellitem"
                values={{
                  discount: data.newValue?.name,
                }}
              >
                <span className={styles.text}>
                  {' added discount '}
                  {data.discount}
                  {' to this sellitem '}
                </span>
              </Trans>
            );
          } else {
            contentNode = (
              <Trans
                i18nKey="actions.userRemovedDiscountToThisSellitem"
                values={{
                  discount: data.oldValue?.name,
                }}
              >
                <span className={styles.text}>
                  {' removed discount '}
                  {data.discount}
                  {' to this sellitem '}
                </span>
              </Trans>
            );
          }
          break;
        case 'customers':
          if (data.newValue) {
            contentNode = (
              <Trans
                i18nKey="actions.userAddedCustomerToThisCustomer"
                values={{
                  customer: data.newValue?.name,
                }}
              >
                <span className={styles.text}>
                  {' added customer '}
                  {data.customer}
                  {' to this sellitem '}
                </span>
              </Trans>
            );
          } else {
            contentNode = (
              <Trans
                i18nKey="actions.userRemovedCustomerToThisCustomer"
                values={{
                  customer: data.oldValue?.name,
                }}
              >
                <span className={styles.text}>
                  {' removed customer '}
                  {data.customer}
                  {' to this sellitem '}
                </span>
              </Trans>
            );
          }
          break;
        case 'paymentMethod':
          if (data.oldValue) {
            if (data.note) {
              contentNode = (
                <Trans
                  i18nKey="actions.userUpdatedSellitemPaymentMethodWithReason"
                  values={{
                    fromPaymentMethod: t(`common.${data.oldValue}`),
                    toPaymentMethod: t(`common.${data.newValue}`),
                    reason: data.note,
                  }}
                >
                  <span className={styles.text}>
                    {' updated payment method this sellitem from '}
                    {data.fromPaymentMethod}
                    {' to '}
                    {data.toPaymentMethod}
                    {' reason '}
                    {data.reason}
                  </span>
                </Trans>
              );
            } else {
              contentNode = (
                <Trans
                  i18nKey="actions.userUpdatedSellitemPaymentMethod"
                  values={{
                    fromPaymentMethod: t(`common.${data.oldValue}`),
                    toPaymentMethod: t(`common.${data.newValue}`),
                  }}
                >
                  <span className={styles.text}>
                    {' updated payment method this sellitem from '}
                    {data.fromPaymentMethod}
                    {' to '}
                    {data.toPaymentMethod}
                  </span>
                </Trans>
              );
            }
          } else {
            contentNode = (
              <Trans
                i18nKey="actions.userAddedSellitemPaymentMethod"
                values={{
                  toPaymentMethod: t(`common.${data.newValue}`),
                }}
              >
                <span className={styles.text}>
                  {' added payment method this sellitem to '}
                  {data.toPaymentMethod}
                </span>
              </Trans>
            );
          }
          break;
        default:
          contentNode = (
            <Trans i18nKey="actions.userUpdatedSellitem">
              <span className={styles.text}>{' updated sellitem '}</span>
            </Trans>
          );
      }

      break;
    case ActionTypes.TRANSFER_SELLITEM:
      if (data.oldValue) {
        contentNode = (
          <Link
            to={Paths.SELLITEMS.replace(':id', data.oldValue.id)}
            className={styles.linkSellitem}
          >
            <Trans
              i18nKey="actions.userTransferedThisSellitemFromSellitem"
              values={{
                productName: data.oldValue.productName,
              }}
            >
              <span className={styles.text}>
                {' transfer this sellitem from '}
                {data.productName}
              </span>
            </Trans>
          </Link>
        );
      }
      if (data.newValue) {
        contentNode = (
          <Link
            to={Paths.SELLITEMS.replace(':id', data.newValue.id)}
            className={styles.linkSellitem}
          >
            <Trans
              i18nKey="actions.userTransferedThisSellitemToSellitem"
              values={{
                productName: data.newValue.productName,
              }}
            >
              <span className={styles.text}>
                {' transfer this sellitem to '}
                {data.productName}
              </span>
            </Trans>
          </Link>
        );
      }
      break;
    default:
      contentNode = null;
  }

  return (
    <Comment>
      <span className={styles.user}>
        <User name={user.name} avatarUrl={user.avatarUrl} />
      </span>
      <div className={classNames(styles.content)}>
        <div className={styles.title}>
          <span className={styles.author}>{user.name}</span>
          <span className={styles.date}>
            {t('format:longDateTime', {
              postProcess: 'formatDate',
              value: createdAt,
            })}
          </span>
        </div>
        <div>{contentNode}</div>
      </div>
    </Comment>
  );
});

Item.Comment = ItemComment;

Item.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  createdAt: PropTypes.instanceOf(Date).isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Item;
