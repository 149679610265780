import socket from './socket';

/* Actions */

const getDashboard = (id, bagId, headers) =>
  socket.get(`/groups/${id}/stats?bagId=${bagId}`, undefined, headers).then((body) => ({
    ...body,
  }));

export default {
  getDashboard,
};
