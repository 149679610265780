import { all, takeEvery } from 'redux-saga/effects';

import {
  createGroupInCurrentProjectService,
  deleteGroupService,
  fetchGroupService,
  handleGroupCreateService,
  handleGroupDeleteService,
  handleGroupUpdateService,
  moveGroupService,
  updateGroupService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* groupWatchers() {
  yield all([
    takeEvery(EntryActionTypes.GROUP_IN_CURRENT_PROJECT_CREATE, ({ payload: { data } }) =>
      createGroupInCurrentProjectService(data),
    ),
    takeEvery(EntryActionTypes.GROUP_CREATE_HANDLE, ({ payload: { group } }) =>
      handleGroupCreateService(group),
    ),
    takeEvery(EntryActionTypes.GROUP_FETCH, ({ payload: { id } }) => fetchGroupService(id)),
    takeEvery(EntryActionTypes.GROUP_UPDATE, ({ payload: { id, data } }) =>
      updateGroupService(id, data),
    ),
    takeEvery(EntryActionTypes.GROUP_UPDATE_HANDLE, ({ payload: { group } }) =>
      handleGroupUpdateService(group),
    ),
    takeEvery(EntryActionTypes.GROUP_MOVE, ({ payload: { id, index } }) =>
      moveGroupService(id, index),
    ),
    takeEvery(EntryActionTypes.GROUP_DELETE, ({ payload: { id } }) => deleteGroupService(id)),
    takeEvery(EntryActionTypes.GROUP_DELETE_HANDLE, ({ payload: { group } }) =>
      handleGroupDeleteService(group),
    ),
  ]);
}
