import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isAdminSelector,
  isManagerSelector,
  isSaleAdminSelector,
  isCurrentUserMemberForCurrentGroupSelector,
  productsForCurrentGroupSelector,
  agentsSelector,
  currentProjectSelector,
  bagsForCurrentGroupSelector,
} from '../selectors';
import { clearUserCreateError, createSellitem, updateProduct } from '../actions/entry';
import AddForm from '../components/Sellitems/AddForm';

const mapStateToProps = (state) => {
  const { data: defaultData, isSubmitting, error } = state.ui.sellitemCreateForm;
  const currentProject = currentProjectSelector(state);
  const products = productsForCurrentGroupSelector(state);
  // products = products.filter(({ finalTotal }) => finalTotal > 0);

  const bags = bagsForCurrentGroupSelector(state);

  const agents = agentsSelector(state);

  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);
  const isManager = isManagerSelector(state);
  const isSaleAdmin = isSaleAdminSelector(state);

  return {
    currentProject,
    isAdmin: isAdmin || (isCurrentUserMember && isSaleAdmin),
    isManager,
    defaultData,
    products,
    bags,
    agents,
    isSubmitting,
    error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreateSellitem: createSellitem,
      onMessageDismiss: clearUserCreateError,
      onUpdateProduct: (data) => updateProduct(data.id, data),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddForm);
