/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { withPopup } from '../../lib/popup';
import { Input, Popup } from '../../lib/custom-ui';

import { useForm } from '../../hooks';

import styles from './AddPopup.module.scss';

const AddStep = React.memo(({ onCreate, onClose }) => {
  const [t] = useTranslation();

  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 10);

  const [data, handleFieldChange, setData] = useForm({
    name: '',
    description: '',
    status: 'new',
    startDate: new Date(),
    endDate,
  });

  const nameField = useRef(null);

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    startDate: false,
    endDate: false,
  });

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      name: data.name.trim(),
      description: data.description.trim() || null,
      startDate: data.startDate,
      endDate: data.endDate,
    };

    const err = {
      name: !cleanData.name,
      // description: !cleanData.description,
      startDate: !cleanData.startDate,
      endDate: !cleanData.endDate,
    };

    // if (!cleanData.name) {
    //   cleanData.name = undefined;
    // }

    // if (!cleanData.description) {
    //   cleanData.description = undefined;
    // }

    // if (!cleanData.startDate) {
    //   cleanData.startDate = t('format:postDate', {
    //     postProcess: 'formatDate',
    //     value: new Date(),
    //   });
    // }

    // if (!cleanData.endDate) {
    //   cleanData.endDate = t('format:postDate', {
    //     postProcess: 'formatDate',
    //     value: endDate,
    //   });
    // }
    setErrors(err);

    if (Object.values(err).some((e) => e)) {
      return;
    }

    onCreate(cleanData);
    onClose();
  }, [onCreate, onClose, data]);

  const handleDatePickerChange = useCallback(
    (date, type) => {
      setData((prevData) => ({
        ...prevData,
        [type]: date,
      }));
    },
    [setData],
  );

  useEffect(() => {
    nameField.current.select();
  }, []);

  return (
    <>
      <Popup.Header>
        {t('common.createBag', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field
            required
            error={errors.name}
            onFocus={() => setErrors({ ...errors, name: false })}
          >
            <Input
              fluid
              ref={nameField}
              name="name"
              value={data.name}
              className={styles.field}
              placeholder={`${t('common.name')} *`}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Field>
            <Input
              fluid
              name="description"
              value={data.description}
              className={styles.field}
              placeholder={t('common.description')}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field
              required
              error={errors.startDate}
              onFocus={() => setErrors({ ...errors, startDate: false })}
            >
              <label htmlFor="startDate">{t('common.startDate')}</label>
              <DatePicker
                key="startDate"
                dateFormat={t('format:longDate')}
                className={styles.field}
                placeholderText={t('common.startDate')}
                selectsStart
                startDate={data.startDate}
                endDate={data.endDate}
                selected={data.startDate}
                onChange={(date) => handleDatePickerChange(date, 'startDate')}
              />
            </Form.Field>
            <Form.Field
              required
              error={errors.startDate}
              onFocus={() => setErrors({ ...errors, endDate: false })}
            >
              <label htmlFor="startDate">{t('common.endDate')}</label>
              <DatePicker
                key="endDate"
                dateFormat={t('format:longDate')}
                className={styles.field}
                placeholderText={t('common.startDate')}
                selectsEnd
                startDate={data.startDate}
                endDate={data.endDate}
                minDate={data.startDate}
                selected={data.endDate}
                onChange={(date) => handleDatePickerChange(date, 'endDate')}
              />
            </Form.Field>
          </Form.Group>
          <Button positive content={t('action.createBag')} />
        </Form>
      </Popup.Content>
    </>
  );
});

AddStep.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withPopup(AddStep, { onMouseDown: () => {} });
