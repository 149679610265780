import { call, put } from 'redux-saga/effects';

import request from '../request';
import {
  createSellitemCustomer,
  deleteSellitemCustomer,
  handleSellitemCustomerCreate,
  handleSellitemCustomerDelete,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createSellitemCustomerService(data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createSellitemCustomer({
      ...nextData,
      id: localId,
    }),
  );

  let customer;
  try {
    ({ item: customer } = yield call(
      request,
      api.createSellitemCustomer,
      nextData.sellitemId,
      nextData,
    ));
  } catch (error) {
    yield put(createSellitemCustomer.failure(localId, error));
    return;
  }

  yield put(createSellitemCustomer.success(localId, customer));
}

export function* handleSellitemCustomerCreateService(sellitemCustomer, customers) {
  yield put(handleSellitemCustomerCreate(sellitemCustomer, customers));
}

export function* deleteSellitemCustomerService(sellitemId, customerId) {
  yield put(deleteSellitemCustomer(sellitemId, customerId));

  let customer;
  try {
    ({ item: customer } = yield call(request, api.deleteSellitemCustomer, sellitemId, customerId));
  } catch (error) {
    yield put(deleteSellitemCustomer.failure(sellitemId, error));
    return;
  }

  yield put(deleteSellitemCustomer.success(customer));
}

export function* handleSellitemCustomerDeleteService(label) {
  yield put(handleSellitemCustomerDelete(label));
}

export function* updateSellitemCustomerService(data) {
  yield call(deleteSellitemCustomerService, data.sellitemId, data.oldCustomerId);
  yield call(createSellitemCustomerService, data);
}
