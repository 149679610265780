import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createDiscount,
  deleteDiscount,
  handleDiscountCreate,
  handleDiscountDelete,
  handleDiscountUpdate,
  updateDiscount,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createDiscountService(bagId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createDiscount({
      ...nextData,
      bagId,
      id: localId,
    }),
  );

  let discount;
  try {
    ({ item: discount } = yield call(request, api.createDiscount, bagId, nextData));
  } catch (error) {
    yield put(createDiscount.failure(localId, error));
    return;
  }

  yield put(createDiscount.success(localId, discount));
}

export function* createDiscountInCurrentBagService(data) {
  const { bagId } = yield select(pathSelector);

  yield call(createDiscountService, bagId, data);
}

export function* handleDiscountCreateService(label) {
  yield put(handleDiscountCreate(label));
}

export function* updateDiscountService(id, data) {
  yield put(updateDiscount(id, data));

  let discount;
  try {
    ({ item: discount } = yield call(request, api.updateDiscount, id, data));
  } catch (error) {
    yield put(updateDiscount.failure(id, error));
    return;
  }

  yield put(updateDiscount.success(discount));
}

export function* handleDiscountUpdateService(bag) {
  yield put(handleDiscountUpdate(bag));
}

export function* deleteDiscountService(id) {
  yield put(deleteDiscount(id));

  let discount;
  try {
    ({ item: discount } = yield call(request, api.deleteDiscount, id));
  } catch (error) {
    yield put(deleteDiscount.failure(id, error));
    return;
  }

  yield put(deleteDiscount.success(discount));
}

export function* handleDiscountDeleteService(discount) {
  yield put(handleDiscountDelete(discount));
}
