/* eslint-disable import/prefer-default-export */
import ActionTypes from '../constants/ActionTypes';

export const importGroup = (data) => ({
  type: ActionTypes.GROUP_IMPORT,
  payload: {
    data,
  },
});

importGroup.success = (message) => ({
  type: ActionTypes.GROUP_IMPORT__SUCCESS,
  payload: {
    message,
  },
});

importGroup.failure = (error) => ({
  type: ActionTypes.GROUP_IMPORT__FAILURE,
  payload: {
    error,
  },
});

export const clearImportError = () => ({
  type: ActionTypes.GROUP_IMPORT_ERROR_CLEAR,
  payload: {},
});
