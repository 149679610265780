import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import React, { useMemo, useRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Segment, Dropdown } from 'semantic-ui-react';
import { HotTable, HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';
import exportXlsx from './export';
import { Statuses } from '../../constants/Enums';
import Summary from '../ProductTable/Summary';
import MenusPopup from './MenusPopup';
import MessagePopup from './MessagePopup';

import { usePrevious } from '../../lib/hooks';

import styles from './Accountand.module.scss';
import globalStyles from '../../styles.module.scss';

const undoQ = [];
const redoQ = [];

const PaymentTable = React.memo(
  ({
    sellitems,
    bags,
    onUpdateSellitem,
    canEdit,
    canRecalculate,
    onRecalculateCommission,
    isSubmitting,
    error,
  }) => {
    const [t] = useTranslation();
    const hotTableRef = useRef();
    const summaryRef = useRef();

    const wasSubmitting = usePrevious(isSubmitting);
    const bagOptions = [{ key: 0, value: 0, text: t('common.allBags') }, ...bags];

    const nestedHeaders = useMemo(
      () => [
        [
          { label: t('common.accountants.products'), colspan: 13 },
          { label: t('common.accountants.deposit'), colspan: 2 },
          { label: t('common.accountants.discount'), colspan: 7 },
          '',
          { label: t('common.accountants.payment'), colspan: 20 },
          '',
          '',
          '',
          '',
        ],
        [
          '#',
          t('common.accountants.block'),
          t('common.accountants.nameProduct'),
          t('common.accountants.status'),
          t('common.accountants.nameCustomer'),
          t('common.accountants.landArea_title'),
          t('common.accountants.landPrice_title'),
          t('common.accountants.factor_title'),
          t('common.accountants.totalLand_title'),
          t('common.accountants.floorArea_title'),
          t('common.accountants.floorPrice_title'),
          t('common.accountants.totalFloor_title'),
          t('common.accountants.totalAmount_title'),
          t('common.accountants.deposit'),
          t('common.accountants.dateDeposit'),
          t('common.accountants.discount1'),
          t('common.accountants.discount2'),
          t('common.accountants.discount3'),
          t('common.accountants.loanPercent_title'),
          t('common.accountants.loanAmount'),
          t('common.accountants.discountOther'),
          t('common.accountants.totalDiscount_title'),
          t('common.accountants.totalAfterDiscount'),
          t('common.accountants.process2'),
          t('common.accountants.gotDateProcess2_title'),
          t('common.accountants.process3'),
          t('common.accountants.gotDateProcess3_title'),
          t('common.accountants.process4'),
          t('common.accountants.gotDateProcess4_title'),
          t('common.accountants.process5'),
          t('common.accountants.gotDateProcess5_title'),
          t('common.accountants.process6'),
          t('common.accountants.gotDateProcess6_title'),
          t('common.accountants.process7'),
          t('common.accountants.gotDateProcess7_title'),
          t('common.accountants.process8'),
          t('common.accountants.gotDateProcess8_title'),
          t('common.accountants.process9'),
          t('common.accountants.gotDateProcess9_title'),
          t('common.accountants.process10'),
          t('common.accountants.gotDateProcess10_title'),
          t('common.accountants.totalPaid'),
          t('common.accountants.remain'),
          t('common.accountants.bonus'),
          t('common.accountants.totalForCommission_title'),
          t('common.accountants.commissionPercent'),
          t('common.accountants.commissionAmount'),
          t('common.accountants.totalCommissionAmount'),
          t('common.accountants.ownAgent'),
          t('common.accountants.agent'),
        ],
      ],
      [t],
    );

    const [currentBag, setCurrentBag] = useState(0);
    const [dataTable, setDataTable] = useState([]);

    useEffect(() => {
      let newDataTable = [...sellitems];
      if (currentBag !== 0) {
        newDataTable = sellitems.filter((sl) => sl.bagitem?.bagId === currentBag);
      }
      setDataTable(newDataTable);
    }, [currentBag, sellitems]);

    const handleChangeBag = useCallback(
      (e, data) => {
        setCurrentBag(data.value);
        let newDataTable = [...sellitems];
        if (data.value !== 0) {
          newDataTable = sellitems.filter((sl) => sl.bagitem?.bagId === data.value);
        }
        setDataTable(newDataTable);
      },
      [sellitems],
    );

    const handleExport = useCallback(() => {
      const nestedHeadersExport = [
        'STT',
        t('common.accountants.products'),
        t('common.accountants.deposit'),
        t('common.accountants.discount'),
        t('common.accountants.payment'),
      ];
      const headers = [
        'STT',
        t('common.accountants.block'),
        t('common.accountants.nameProduct'),
        t('common.accountants.status'),
        t('common.accountants.nameCustomer'),
        t('common.accountants.landArea'),
        t('common.accountants.landPrice'),
        t('common.accountants.factor'),
        t('common.accountants.totalLand'),
        t('common.accountants.floorArea'),
        t('common.accountants.floorPrice'),
        t('common.accountants.totalFloor'),
        t('common.accountants.totalAmount'),
        t('common.accountants.deposit'),
        t('common.accountants.dateDeposit'),
        t('common.accountants.discount1'),
        t('common.accountants.discount2'),
        t('common.accountants.discount3'),
        t('common.accountants.loanPercent'),
        t('common.accountants.loanAmount'),
        t('common.accountants.discountOther'),
        t('common.accountants.totalDiscount'),
        t('common.accountants.totalAfterDiscount'),
        t('common.accountants.process2'),
        t('common.accountants.gotDateProcess2'),
        t('common.accountants.process3'),
        t('common.accountants.gotDateProcess3'),
        t('common.accountants.process4'),
        t('common.accountants.gotDateProcess4'),
        t('common.accountants.process5'),
        t('common.accountants.gotDateProcess5'),
        t('common.accountants.process6'),
        t('common.accountants.gotDateProcess6'),
        t('common.accountants.process7'),
        t('common.accountants.gotDateProcess7'),
        t('common.accountants.process8'),
        t('common.accountants.gotDateProcess8'),
        t('common.accountants.process9'),
        t('common.accountants.gotDateProcess9'),
        t('common.accountants.process10'),
        t('common.accountants.gotDateProcess10'),
        t('common.accountants.totalPaid'),
        t('common.accountants.remain'),
        t('common.accountants.bonus'),
        t('common.accountants.totalForCommission'),
        t('common.accountants.commissionPercent'),
        t('common.accountants.commissionAmount'),
        t('common.accountants.totalCommissionAmount'),
        t('common.accountants.ownAgent'),
        t('common.accountants.agent'),
      ];
      exportXlsx(dataTable, nestedHeadersExport, headers, { name: 'TiciLand - Kế toán' });
    }, [dataTable, t]);

    const handleRedo = useCallback(() => {
      const hotInstance = redoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = sellitems.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'redo');
      }
    }, [sellitems]);

    const handleUndo = useCallback(() => {
      const hotInstance = undoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = sellitems.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'undo');
      }
    }, [sellitems]);

    const afterSelection = useCallback(() => {
      const selected = hotTableRef.current.hotInstance.getSelected();
      if (
        !(
          selected.length === 1 &&
          selected[0][0] === selected[0][2] &&
          selected[0][1] === selected[0][3]
        )
      ) {
        const data = [];
        selected.forEach((it) => {
          data.push(hotTableRef.current.hotInstance.getData(...it).flat());
        });
        if (summaryRef.current) summaryRef.current.show(data);
      } else if (summaryRef.current) {
        // const data = hotTableRef.current.hotInstance.getDataAtCell(selected[0][0], selected[0][1]);
        // summaryRef.current.show([[data]]);
        summaryRef.current.show([]);
      }
    }, []);

    const afterDeselect = () => {
      if (summaryRef.current) summaryRef.current.show([]);
    };

    const afterChange = useCallback(
      (changes, source) => {
        const sourceChange = ['edit', 'CopyPaste.paste', 'Autofill.fill', 'undo', 'redo'];
        if (sourceChange.indexOf(source) > -1 && changes.length > 0) {
          changes.forEach(([row, prop, oldValue, newValue]) => {
            if ((oldValue === null || oldValue === undefined) && newValue === '') return;

            if (oldValue !== newValue) {
              let newData;
              const { id } = sellitems[row];

              switch (prop) {
                default:
                  newData = {
                    [prop]: newValue || null,
                  };
                  onUpdateSellitem(id, newData);
                  break;
              }
              if (source === 'undo') {
                if (redoQ.length > 9) redoQ.shift();
                redoQ.push([id, prop, oldValue]);
              } else {
                if (source !== 'redo') redoQ.length = 0;
                if (undoQ.length > 9) undoQ.shift();
                undoQ.push([id, prop, oldValue]);
              }
            }
          });
        }
      },
      [onUpdateSellitem, sellitems],
    );

    const statusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
      /* eslint-disable no-param-reassign */
      if (value) {
        const status = Object.values(Statuses).find(({ label }) => label === value) || {
          name: '-',
          color: 'red',
        };
        td.style.color = '#fff';
        td.classList.add(globalStyles[`background${upperFirst(camelCase(status.color))}`]);
      }
      return td;
    };

    const percentRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        `${value * 1}%`,
        cellProperties,
      ]);
      td.classList.add(styles.valueNumber);
      return td;
    };

    const beforeKeyDown = useCallback(
      (e) => {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          // e.preventDefault();
          e.stopImmediatePropagation();
          return;
        }
        if (e.ctrlKey || e.metaKey) {
          if (e.keyCode === 90) {
            if (e.shiftKey) {
              handleRedo();
            } else {
              handleUndo();
            }
          }
        }
      },
      [handleRedo, handleUndo],
    );

    const afterFilter = useCallback(() => {
      const dataFilter = hotTableRef.current.hotInstance.getData().map((dt) => dt[0]);
      const newData = sellitems.filter((sl) => dataFilter.includes(sl.id));
      setDataTable(newData);
    }, [sellitems]);

    useEffect(() => {
      undoQ.length = 0;
      redoQ.length = 0;
    }, []);

    const [openMessage, setOpenMessage] = useState(false);
    useEffect(() => {
      if (wasSubmitting && !isSubmitting) {
        setOpenMessage(true);
      }
    }, [wasSubmitting, error, isSubmitting]);

    return (
      <Segment className={styles.wrapper}>
        {openMessage && <MessagePopup onClose={() => setOpenMessage(false)} error={error} />}
        <div className={styles.topWrapper}>
          <div className={styles.filterWrapper}>
            <Dropdown
              className={styles.filterBag}
              placeholder={t('common.selectBag')}
              search
              selection
              value={currentBag}
              options={bagOptions}
              onChange={handleChangeBag}
            />
          </div>
          {canRecalculate && (
            <Button
              type="button"
              className={styles.buttonRecalculateCommission}
              onClick={() => onRecalculateCommission(currentBag)}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t('common.accountants.recalculateCommission')}
            </Button>
          )}
        </div>
        {dataTable.length > 0 && (
          <div id="hot-app" className={styles.tableWrapper}>
            <HotTable
              ref={hotTableRef}
              className={styles.hottable}
              data={dataTable}
              // colHeaders={colHeaders}
              nestedHeaders={nestedHeaders}
              hiddenColumns={{ columns: [0] }}
              height="100%"
              stretchH="all"
              autoColumnSize={false}
              autoRowSize={false}
              manualColumnResize
              fixedColumnsLeft={3}
              rowHeaders
              enterMoves={{ col: 1, row: 0 }}
              dropdownMenu={['filter_by_value', 'filter_action_bar']}
              filters
              afterChange={afterChange}
              afterSelection={afterSelection}
              afterDeselect={afterDeselect}
              beforeKeyDown={beforeKeyDown}
              afterFilter={afterFilter}
              licenseKey="non-commercial-and-evaluation"
            >
              <HotColumn width={0} data="id" />
              <HotColumn width={0} data="block.name" readOnly />
              <HotColumn width={70} data="product.name" readOnly />
              <HotColumn
                width={90}
                data="statusLabel"
                wordWrap={false}
                renderer={statusRenderer}
                readOnly
              />
              <HotColumn width={150} data="customer.name" readOnly />
              <HotColumn
                width={80}
                data="product.landArea"
                type="numeric"
                numericFormat={{ pattern: '0,0.00' }}
                readOnly
              />
              <HotColumn
                width={100}
                data="product.landPrice"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={60}
                data="product.factor"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={120}
                data="totalLandPrice"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={80}
                data="product.floorArea"
                type="numeric"
                numericFormat={{ pattern: '0,0.00' }}
                readOnly
              />
              <HotColumn
                width={100}
                data="product.floorPrice"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={120}
                data="totalFloorPrice"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={130}
                data="sellPrice"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={100}
                data="payments.0.amount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="payments.0.gotDate" readOnly />
              <HotColumn
                width={120}
                data="discount1"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={120}
                data="discount2"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={120}
                data="discount3"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={70}
                data="loanPercent"
                type="numeric"
                numericFormat={{ pattern: '0,0.00' }}
                renderer={percentRenderer}
              />
              <HotColumn
                width={120}
                data="loanAmount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
              />
              <HotColumn
                width={120}
                data="discountMore"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={120}
                data="totalDiscount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={120}
                data="total"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={100}
                data="payments.1.amount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="payments.1.gotDate" readOnly />
              <HotColumn
                width={100}
                data="payments.2.amount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="payments.2.gotDate" readOnly />
              <HotColumn
                width={100}
                data="payments.3.amount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="payments.3.gotDate" readOnly />
              <HotColumn
                width={100}
                data="payments.4.amount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="payments.4.gotDate" readOnly />
              <HotColumn
                width={100}
                data="payments.5.amount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="payments.5.gotDate" readOnly />
              <HotColumn
                width={100}
                data="payments.6.amount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="payments.6.gotDate" readOnly />
              <HotColumn
                width={100}
                data="payments.7.amount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="payments.7.gotDate" readOnly />
              <HotColumn
                width={100}
                data="payments.8.amount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="payments.8.gotDate" readOnly />
              <HotColumn
                width={100}
                data="payments.9.amount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="payments.9.gotDate" readOnly />
              <HotColumn
                width={110}
                data="totalPaid"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={110}
                data="remain"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={110}
                data="bonus"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={123}
                data="totalForCommission"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
              />
              <HotColumn
                width={90}
                data="commissionPercent"
                type="numeric"
                numericFormat={{ pattern: '0,0.00' }}
                readOnly
                renderer={percentRenderer}
              />
              <HotColumn
                width={125}
                data="commissionAmount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn
                width={125}
                data="totalCommissionAmount"
                type="numeric"
                numericFormat={{ pattern: '0,0' }}
                readOnly
              />
              <HotColumn width={90} data="agent.name" readOnly />
              <HotColumn width={90} data="agentSelled.name" readOnly />
            </HotTable>
            <div>
              <Summary ref={summaryRef} />
              <Button.Group className={styles.actionButtons}>
                <MenusPopup
                  closeIcon={false}
                  canExport={canEdit && sellitems.length > 0}
                  canImport={canEdit}
                  onExport={handleExport}
                >
                  <Button size="mini" icon="bars" />
                </MenusPopup>
                <Button
                  className={styles.buttonTableActionUndo}
                  size="mini"
                  content={t('action.undo')}
                  icon="undo"
                  labelPosition="left"
                  onClick={handleUndo}
                  disabled={undoQ.length < 1}
                />
                <Button
                  className={styles.buttonTableActionRedo}
                  size="mini"
                  content={t('action.redo')}
                  icon="redo"
                  labelPosition="right"
                  onClick={handleRedo}
                  disabled={redoQ.length < 1}
                />
              </Button.Group>
            </div>
          </div>
        )}
      </Segment>
    );
  },
);

PaymentTable.propTypes = {
  sellitems: PropTypes.arrayOf(PropTypes.object()).isRequired,
  bags: PropTypes.arrayOf(PropTypes.object()).isRequired,
  onUpdateSellitem: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canRecalculate: PropTypes.bool.isRequired,
  onRecalculateCommission: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

export default PaymentTable;
