import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeGroupMembershipByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ GroupMembership }, id) => {
      const groupMembershipModel = GroupMembership.withId(id);

      if (!groupMembershipModel) {
        return groupMembershipModel;
      }

      return groupMembershipModel.ref;
    },
  );

export const groupMembershipByIdSelector = makeGroupMembershipByIdSelector();
