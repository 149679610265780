/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Icon, Grid, GridColumn } from 'semantic-ui-react';

import DroppableTypes from '../../constants/DroppableTypes';
import ProductContainer from '../../containers/ProductContainer';
import NameEdit from './NameEdit';
import ProductAdd from './ProductAdd';
import ActionsPopup from './ActionsPopup';
import { ReactComponent as PlusMathIcon } from '../../assets/images/plus-math-icon.svg';

import styles from './Block.module.scss';

const Block = React.memo(
  ({ id, index, name, isPersisted, productIds, canEdit, onUpdate, onDelete, onProductCreate }) => {
    const [t] = useTranslation();
    const [isAddProductOpened, setIsAddProductOpened] = useState(false);

    const nameEdit = useRef(null);
    const blockWrapper = useRef(null);

    const handleHeaderClick = useCallback(() => {
      if (isPersisted && canEdit) {
        nameEdit.current.open();
      }
    }, [isPersisted, canEdit]);

    const handleNameUpdate = useCallback(
      (newName) => {
        onUpdate({
          name: newName,
        });
      },
      [onUpdate],
    );

    const handleAddProductClick = useCallback(() => {
      setIsAddProductOpened(true);
    }, []);

    const handleAddProductClose = useCallback(() => {
      setIsAddProductOpened(false);
    }, []);

    const handleNameEdit = useCallback(() => {
      nameEdit.current.open();
    }, []);

    const handleProductAdd = useCallback(() => {
      setIsAddProductOpened(true);
    }, []);

    useEffect(() => {
      if (isAddProductOpened) {
        blockWrapper.current.scrollTop = blockWrapper.current.scrollHeight;
      }
    }, [productIds, isAddProductOpened]);

    const productsNode = (
      <Droppable
        droppableId={`block:${id}`}
        type={DroppableTypes.PRODUCT}
        isDropDisabled
        direction="horizontal"
      >
        {({ innerRef, droppableProps, placeholder }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...droppableProps} ref={innerRef}>
            <div className={styles.products}>
              {productIds.map((productId, productIndex) => (
                <ProductContainer key={productId} id={productId} index={productIndex} />
              ))}
              {placeholder}
              {canEdit && (
                <ProductAdd
                  isOpened={isAddProductOpened}
                  onCreate={onProductCreate}
                  onClose={handleAddProductClose}
                />
              )}
              {/* {!isAddProductOpened && canEdit && (
                <button
                  type="button"
                  disabled={!isPersisted}
                  className={classNames(styles.addProductButton)}
                  onClick={handleAddProductClick}
                >
                  <PlusMathIcon className={styles.addProductButtonIcon} />
                  <span className={styles.addProductButtonText}>
                    {productIds.length > 0 ? t('action.addAnotherProduct') : t('action.addProduct')}
                  </span>
                </button>
              )} */}
            </div>
          </div>
        )}
      </Droppable>
    );

    if (productIds.length <= 0) return null;

    return (
      <Draggable
        draggableId={`block:${id}`}
        index={index}
        isDragDisabled={!isPersisted || !canEdit}
      >
        {({ innerRef, draggableProps, dragHandleProps }) => (
          <div
            {...draggableProps} // eslint-disable-line react/jsx-props-no-spreading
            data-drag-scroller
            ref={innerRef}
            className={styles.innerWrapper}
          >
            {/* eslint-disable jsx-a11y/click-events-have-key-events,
                               jsx-a11y/no-static-element-interactions,
                               react/jsx-props-no-spreading */}
            <div className={styles.outerWrapper}>
              <div
                {...dragHandleProps}
                className={classNames(styles.header, canEdit && styles.headerEditable)}
                onClick={handleHeaderClick}
              >
                {/* eslint-enable jsx-a11y/click-events-have-key-events,
                                jsx-a11y/no-static-element-interactions,
                                react/jsx-props-no-spreading */}
                <NameEdit ref={nameEdit} defaultValue={name} onUpdate={handleNameUpdate}>
                  <div className={styles.headerName}>{name}</div>
                </NameEdit>
                {isPersisted && canEdit && (
                  <ActionsPopup
                    onNameEdit={handleNameEdit}
                    onProductAdd={handleProductAdd}
                    onDelete={onDelete}
                  >
                    <Button className={classNames(styles.headerButton, styles.target)}>
                      <Icon fitted name="pencil" size="small" />
                    </Button>
                  </ActionsPopup>
                )}
              </div>
              <div
                ref={blockWrapper}
                className={classNames(
                  styles.productsInnerWrapper,
                  (isAddProductOpened || !canEdit) && styles.productsInnerWrapperFull,
                )}
              >
                <div className={styles.productsOuterWrapper}>{productsNode}</div>
              </div>
              {/* {!isAddProductOpened && canEdit && (
                <button
                  type="button"
                  disabled={!isPersisted}
                  className={classNames(styles.addProductButton)}
                  onClick={handleAddProductClick}
                >
                  <PlusMathIcon className={styles.addProductButtonIcon} />
                  <span className={styles.addProductButtonText}>
                    {productIds.length > 0 ? t('action.addAnotherProduct') : t('action.addProduct')}
                  </span>
                </button>
              )} */}
            </div>
          </div>
        )}
      </Draggable>
    );
  },
);

Block.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  productIds: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  canEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onProductCreate: PropTypes.func.isRequired,
};

export default Block;
