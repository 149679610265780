import { call, select } from 'redux-saga/effects';

import request from '../request';
import { currentGroupSelector, pathsMatchSelector } from '../../../selectors';
import api from '../../../api';
import Paths from '../../../constants/Paths';
import mergeRecords from '../../../utils/merge-records';

// eslint-disable-next-line import/prefer-default-export
export function* fetchGroupByCurrentPathRequest() {
  const pathsMatch = yield select(pathsMatchSelector);

  let group;
  let customer;
  let product;
  let sellitem;
  let bag;
  let users;
  let projects;
  let groupMemberships;
  let labels;
  let blocks;
  let customers;
  let products;
  let bags;
  let bagitems;
  let discounts;
  let sellitems;
  let sellitems2;
  let sellitems3;
  let sellitemMemberships;
  let sellitemLabels;
  let sellitemCustomers;
  let sellitemDiscounts;
  let payments;
  let payments2;
  let attachments;

  let currentGroup;

  if (pathsMatch) {
    let groupId;

    switch (pathsMatch.path) {
      case Paths.GROUPS:
      case Paths.TRANSACTIONS:
      case Paths.DASHBOARD:
      case Paths.ACCOUNTANT:
      case Paths.DEBTS:
        groupId = pathsMatch.params.id;
        break;
      case Paths.CUSTOMERS:
        groupId = pathsMatch.params.groupId;
        break;

      // case Paths.CUSTOMERS:
      //   ({
      //     item: customer,
      //     item: { groupId },
      //     included: { sellitems: sellitems2, payments: payments2 },
      //   } = yield call(request, api.getCustomer, pathsMatch.params.id));
      //   break;
      case Paths.PRODUCTS:
        ({
          item: product,
          item: { groupId },
          included: { sellitems: sellitems3 },
        } = yield call(request, api.getProduct, pathsMatch.params.id));
        break;
      case Paths.SELLITEMS:
        currentGroup = yield select(currentGroupSelector);
        if (currentGroup && currentGroup.id === pathsMatch.params.id) {
          groupId = pathsMatch.params.id;
          break;
        }
        ({
          item: sellitem,
          item: { groupId },
        } = yield call(request, api.getSellitem, pathsMatch.params.id));
        break;
      case Paths.BAGS:
        currentGroup = yield select(currentGroupSelector);
        if (currentGroup && currentGroup.id === pathsMatch.params.id) {
          groupId = pathsMatch.params.id;
          break;
        }
        ({
          item: bag,
          item: { groupId },
        } = yield call(request, api.getBag, pathsMatch.params.id));

        break;
      default:
        break;
    }

    if (groupId) {
      ({
        item: group,
        included: {
          users,
          projects,
          groupMemberships,
          labels,
          blocks,
          customers,
          products,
          bags,
          bagitems,
          discounts,
          sellitems,
          sellitemMemberships,
          sellitemLabels,
          sellitemCustomers,
          sellitemDiscounts,
          payments,
          attachments,
        },
      } = yield call(request, api.getGroup, groupId));
    }
  }

  return {
    group,
    customer,
    product,
    bag,
    sellitem,
    users,
    groupMemberships,
    labels,
    blocks,
    customers,
    products,
    bags,
    bagitems,
    discounts,
    sellitems: mergeRecords(sellitems, sellitems2, sellitems3),
    sellitemMemberships,
    sellitemLabels,
    sellitemCustomers,
    sellitemDiscounts,
    payments: mergeRecords(payments, payments2),
    attachments,
    project: projects[0],
  };
}
