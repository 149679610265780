import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { withPopup } from '../../lib/popup';
import { Popup } from '../../lib/custom-ui';

import Paths from '../../constants/Paths';
import { ActionTypes, Statuses } from '../../constants/Enums';
import User from '../User';

import styles from './NotificationsPopup.module.scss';

const NotificationsStep = React.memo(({ items, onDelete, onClose }) => {
  const [t] = useTranslation();

  const handleDelete = useCallback(
    (id) => {
      onDelete(id);
    },
    [onDelete],
  );

  const renderItemContent = useCallback(
    ({ action, sellitem }) => {
      switch (action.type) {
        case ActionTypes.UPDATE_SELLITEM:
          if (action.data.prop === 'status') {
            let notiMsg;

            if (action.data.newValue === Statuses.REVIEW.name) {
              notiMsg = 'common.userReviewedSellitem';
            } else if (
              action.data.oldValue === Statuses.REVIEW.name &&
              action.data.newValue === Statuses.APPROVED.name
            ) {
              notiMsg = 'common.userApprovedSellitem';
            } else if (
              action.data.oldValue === Statuses.REVIEW.name &&
              action.data.newValue === Statuses.BOOKING.name
            ) {
              notiMsg = 'common.userNotApprovedSellitem';
            } else if (action.data.newValue === Statuses.REVIEWCANCEL.name) {
              notiMsg = 'common.userReviewCanceledSellitem';
            } else if (
              action.data.oldValue === Statuses.REVIEWCANCEL.name &&
              action.data.newValue === Statuses.CANCELED.name
            ) {
              notiMsg = 'common.userCanceledSellitem';
            } else if (
              action.data.oldValue === Statuses.REVIEWCANCEL.name &&
              action.data.newValue !== Statuses.CANCELED.name
            ) {
              notiMsg = 'common.userNotCanceledSellitem';
            }

            return (
              <Trans
                i18nKey={notiMsg}
                values={{
                  user: action.user.name,
                  sellitem: sellitem.productName,
                }}
              >
                {action.user.name}
                <Link to={Paths.SELLITEMS.replace(':id', sellitem.id)} onClick={onClose}>
                  {sellitem.productName}
                </Link>
              </Trans>
            );
          }

          break;

        case ActionTypes.MOVE_SELLITEM:
          return (
            <Trans
              i18nKey="common.userMovedSellitemFromCustomerToCustomer"
              values={{
                user: action.user.name,
                sellitem: sellitem.name,
                fromCustomer: action.data.fromCustomer.name,
                toCustomer: action.data.toCustomer.name,
              }}
            >
              {action.user.name}
              {' moved '}
              <Link to={Paths.SELLITEMS.replace(':id', sellitem.id)} onClick={onClose}>
                {sellitem.name}
              </Link>
              {' from '}
              {action.data.fromCustomer.name}
              {' to '}
              {action.data.toCustomer.name}
            </Trans>
          );
        case ActionTypes.COMMENT_SELLITEM:
          return (
            <Trans
              i18nKey="common.userLeftNewCommentToSellitem"
              values={{
                user: action.user.name,
                comment: action.data.text,
                sellitem: sellitem.name,
              }}
            >
              {action.user.name}
              {` left a new comment «${action.data.text}» to `}
              <Link to={Paths.SELLITEMS.replace(':id', sellitem.id)} onClick={onClose}>
                {sellitem.name}
              </Link>
            </Trans>
          );
        default:
      }

      return null;
    },
    [onClose],
  );

  return (
    <>
      <Popup.Header>{t('common.notifications')}</Popup.Header>
      <Popup.Content>
        {items.length > 0
          ? items.map((item) => (
              <div key={item.id} className={styles.wrapper}>
                {item.sellitem && item.action ? (
                  <>
                    <User
                      name={item.action.user.name}
                      avatarUrl={item.action.user.avatarUrl}
                      size="large"
                    />
                    <span className={styles.content}>{renderItemContent(item)}</span>
                  </>
                ) : (
                  <div className={styles.deletedContent}>
                    {t('common.sellitemOrActionAreDeleted')}
                  </div>
                )}
                <Button
                  type="button"
                  icon="close"
                  className={styles.button}
                  onClick={() => handleDelete(item.id)}
                />
              </div>
            ))
          : t('common.noUnreadNotifications')}
      </Popup.Content>
    </>
  );
});

NotificationsStep.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withPopup(NotificationsStep, {
  position: 'bottom right',
});
