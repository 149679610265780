import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'SellitemDiscount';

  static fields = {
    id: attr(),
    discountValue: attr(),
    discountAmount: attr(),
    sellitemId: fk({
      to: 'Sellitem',
      as: 'sellitem',
      relatedName: 'discountsSellitem',
    }),
    discountId: fk({
      to: 'Discount',
      as: 'discount',
      relatedName: 'sellitemDiscounts',
    }),
  };

  static reducer({ type, payload }, SellitemDiscount) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        // case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
        if (payload.sellitemDiscounts) {
          payload.sellitemDiscounts.forEach((sellitemDiscount) => {
            SellitemDiscount.upsert(sellitemDiscount);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        SellitemDiscount.all().delete();

        payload.sellitemDiscounts.forEach((sellitemDiscount) => {
          SellitemDiscount.upsert(sellitemDiscount);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
        // case ActionTypes.PROJECT_CREATE__SUCCESS:
        // case ActionTypes.PROJECT_CREATE_HANDLE:
        // case ActionTypes.GROUP_CREATE__SUCCESS:
        // case ActionTypes.GROUP_FETCH__SUCCESS:
        if (payload.sellitemDiscounts) {
          payload.sellitemDiscounts.forEach((sellitemDiscount) => {
            SellitemDiscount.upsert(sellitemDiscount);
          });
        }

        break;
      case ActionTypes.DISCOUNT_TO_SELLITEM_ADD:
        SellitemDiscount.upsert(payload.sellitemDiscount);

        break;
      case ActionTypes.DISCOUNT_TO_SELLITEM_ADD__SUCCESS:
        SellitemDiscount.withId(payload.localId).delete();
        SellitemDiscount.upsert(payload.sellitemDiscount);

        break;
      case ActionTypes.DISCOUNT_TO_SELLITEM_ADD_HANDLE:
        SellitemDiscount.upsert(payload.sellitemDiscount);

        if (payload.sellitemDiscounts) {
          payload.sellitemDiscounts.forEach((sellitemDiscount) => {
            SellitemDiscount.upsert(sellitemDiscount);
          });
        }

        break;
      case ActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE:
        // SellitemDiscount.withId(payload.id).deleteWithRelated();
        SellitemDiscount.get({
          sellitemId: payload.sellitemId,
          discountId: payload.discountId,
        }).delete();

        break;
      case ActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE__SUCCESS:
      case ActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE_HANDLE: {
        const sellitemDiscountModel = SellitemDiscount.withId(payload.sellitemDiscount.id);

        if (sellitemDiscountModel) {
          sellitemDiscountModel.deleteWithRelated();
        }

        break;
      }
      default:
    }
  }

  deleteRelated() {
    this.group.sellitems.toModelArray().forEach((sellitemModel) => {
      try {
        sellitemModel.discounts.remove(this.discountId);
      } catch {} // eslint-disable-line no-empty
    });
  }

  deleteWithRelated() {
    // this.deleteRelated();
    this.delete();
  }
}
