import { all, takeEvery } from 'redux-saga/effects';

import {
  addUserToSellitemService,
  addUserToCurrentSellitemService,
  addUserToFilterInCurrentGroupService,
  clearCurrentUserEmailUpdateErrorService,
  clearCurrentUserPasswordUpdateErrorService,
  clearCurrentUserUsernameUpdateErrorService,
  clearUserCreateErrorService,
  createUserService,
  deleteUserService,
  removeUserFromSellitemService,
  removeUserFromCurrentSellitemService,
  removeUserFromFilterInCurrentGroupService,
  updateUserService,
  updateCurrentUserAvatarService,
  updateCurrentUserEmailService,
  updateCurrentUserPasswordService,
  updateCurrentUserService,
  updateCurrentUserUsernameService,
  handleUserCreateService,
  handleUserUpdateService,
  handleUserDeleteService,
  handleUserToSellitemAddService,
  handleUserFromSellitemRemoveService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* userWatchers() {
  yield all([
    takeEvery(EntryActionTypes.USER_CREATE, ({ payload: { data } }) => createUserService(data)),
    takeEvery(EntryActionTypes.USER_CREATE_HANDLE, ({ payload: { user } }) =>
      handleUserCreateService(user),
    ),
    takeEvery(EntryActionTypes.USER_CREATE_ERROR_CLEAR, () => clearUserCreateErrorService()),
    takeEvery(EntryActionTypes.USER_UPDATE, ({ payload: { id, data } }) =>
      updateUserService(id, data),
    ),
    takeEvery(EntryActionTypes.CURRENT_USER_UPDATE, ({ payload: { data } }) =>
      updateCurrentUserService(data),
    ),
    takeEvery(EntryActionTypes.USER_UPDATE_HANDLE, ({ payload: { user } }) =>
      handleUserUpdateService(user),
    ),
    takeEvery(EntryActionTypes.CURRENT_USER_EMAIL_UPDATE, ({ payload: { data } }) =>
      updateCurrentUserEmailService(data),
    ),
    takeEvery(EntryActionTypes.CURRENT_USER_EMAIL_UPDATE_ERROR_CLEAR, () =>
      clearCurrentUserEmailUpdateErrorService(),
    ),
    takeEvery(EntryActionTypes.CURRENT_USER_PASSWORD_UPDATE, ({ payload: { data } }) =>
      updateCurrentUserPasswordService(data),
    ),
    takeEvery(EntryActionTypes.CURRENT_USER_PASSWORD_UPDATE_ERROR_CLEAR, () =>
      clearCurrentUserPasswordUpdateErrorService(),
    ),
    takeEvery(EntryActionTypes.CURRENT_USER_USERNAME_UPDATE, ({ payload: { data } }) =>
      updateCurrentUserUsernameService(data),
    ),
    takeEvery(EntryActionTypes.CURRENT_USER_USERNAME_UPDATE_ERROR_CLEAR, () =>
      clearCurrentUserUsernameUpdateErrorService(),
    ),
    takeEvery(EntryActionTypes.CURRENT_USER_AVATAR_UPDATE, ({ payload: { data } }) =>
      updateCurrentUserAvatarService(data),
    ),
    takeEvery(EntryActionTypes.USER_DELETE, ({ payload: { id } }) => deleteUserService(id)),
    takeEvery(EntryActionTypes.USER_DELETE_HANDLE, ({ payload: { user } }) =>
      handleUserDeleteService(user),
    ),
    takeEvery(EntryActionTypes.USER_TO_SELLITEM_ADD, ({ payload: { id, sellitemId } }) =>
      addUserToSellitemService(id, sellitemId),
    ),
    takeEvery(EntryActionTypes.USER_TO_CURRENT_SELLITEM_ADD, ({ payload: { id } }) =>
      addUserToCurrentSellitemService(id),
    ),
    takeEvery(EntryActionTypes.USER_TO_SELLITEM_ADD_HANDLE, ({ payload: { sellitemMembership } }) =>
      handleUserToSellitemAddService(sellitemMembership),
    ),
    takeEvery(EntryActionTypes.USER_FROM_SELLITEM_REMOVE, ({ payload: { id, sellitemId } }) =>
      removeUserFromSellitemService(id, sellitemId),
    ),
    takeEvery(EntryActionTypes.USER_FROM_CURRENT_SELLITEM_REMOVE, ({ payload: { id } }) =>
      removeUserFromCurrentSellitemService(id),
    ),
    takeEvery(
      EntryActionTypes.USER_FROM_SELLITEM_REMOVE_HANDLE,
      ({ payload: { sellitemMembership } }) =>
        handleUserFromSellitemRemoveService(sellitemMembership),
    ),
    takeEvery(EntryActionTypes.USER_TO_FILTER_IN_CURRENT_GROUP_ADD, ({ payload: { id } }) =>
      addUserToFilterInCurrentGroupService(id),
    ),
    takeEvery(EntryActionTypes.USER_FROM_FILTER_IN_CURRENT_GROUP_REMOVE, ({ payload: { id } }) =>
      removeUserFromFilterInCurrentGroupService(id),
    ),
  ]);
}
