import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, TextArea, Button } from 'semantic-ui-react';
import { Statuses } from '../../../constants/Enums';

import styles from './ConfirmForm.module.scss';
import { useForm } from '../../../hooks';

const ConfirmForm = React.memo(({ sellitem, onUpdateSellitem, openModal, onClose }) => {
  const [t] = useTranslation();

  const [error, setError] = useState(false);
  const [data, handleChangeData] = useForm({
    note: '',
  });
  const handleSubmit = useCallback(() => {
    if (!data.note) {
      setError(true);
      return;
    }
    const newData = {
      id: sellitem.id,
      status: Statuses.REVIEWCANCEL.name,
      statusNote: data.note || undefined,
    };
    onUpdateSellitem(newData);
    onClose();
  }, [data.note, onClose, onUpdateSellitem, sellitem]);

  return (
    <Modal open={openModal} closeIcon size="small" centered={false} onClose={onClose}>
      <Modal.Header>{t('common.reviewCancelSellitem_title')}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field required error={error} onFocus={() => setError(false)}>
            <TextArea name="note" onChange={handleChangeData} placeholder={t('common.reason')} />
          </Form.Field>
          <Button className={styles.buttonReview} primary onClick={handleSubmit}>
            {t('action.reviewCancel')}
          </Button>
          <Button className={styles.buttonReview} color="red" onClick={onClose}>
            {t('action.cancel')}
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
});

ConfirmForm.propTypes = {
  sellitem: PropTypes.objectOf(PropTypes.shape),
  openModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onUpdateSellitem: PropTypes.func.isRequired,
};

ConfirmForm.defaultProps = {
  sellitem: undefined,
  openModal: undefined,
};

export default ConfirmForm;
