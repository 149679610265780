import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Bag';

  static fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    isParent: attr(),
    startDate: attr(),
    endDate: attr(),
    discount1: attr(),
    discount2: attr(),
    discount3: attr(),
    discount4: attr(),
    discount5: attr(),
    commission: attr(),
    status: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    groupId: fk({
      to: 'Group',
      as: 'group',
      relatedName: 'bags',
    }),
    categoryId: fk({
      to: 'Category',
      as: 'category',
      relatedName: 'bags',
    }),
    creatorUserId: fk({
      to: 'User',
      as: 'creator',
      relatedName: 'bags',
    }),
    // products: many('Product', 'bags'),
  };

  static reducer({ type, payload }, Bag) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.BAGS_FETCH__SUCCESS:
        if (payload.bags) {
          payload.bags.forEach((bag) => {
            Bag.upsert(bag);
          });
        }

        // if (payload.bagitems) {
        //   payload.bagitems.forEach(({ bagId, productId }) => {
        //     Bag.withId(bagId).products.add(productId);
        //   });
        // }

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__BAG_FETCH:
      case ActionTypes.BAG_FETCH:
        if (Bag.idExists(payload.id)) {
          Bag.withId(payload.id).update({
            isFetching: true,
          });
        }

        break;
      case ActionTypes.BAG_FETCH__SUCCESS:
        Bag.upsert({
          ...payload.bag,
          isFetching: false,
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Bag.all().delete();

        if (payload.bags) {
          payload.bags.forEach((bag) => {
            Bag.upsert(bag);
          });
        }

        // if (payload.bagitems) {
        //   payload.bagitems.forEach(({ bagId, productId }) => {
        //     Bag.withId(bagId).products.add(productId);
        //   });
        // }

        break;
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.bags.forEach((bag) => {
          Bag.upsert(bag);
        });

        if (payload.bagitems) {
          payload.bagitems.forEach(({ bagId, productId }) => {
            Bag.withId(bagId).products.add(productId);
          });
        }

        break;
      case ActionTypes.BAG_CREATE:
      case ActionTypes.BAG_CREATE_HANDLE:
      case ActionTypes.BAG_UPDATE__SUCCESS:
      case ActionTypes.BAG_UPDATE_HANDLE:
        Bag.upsert(payload.bag);

        break;
      case ActionTypes.BAG_CREATE__SUCCESS:
        Bag.withId(payload.localId).delete();
        Bag.upsert(payload.bag);

        break;
      case ActionTypes.BAG_UPDATE:
        Bag.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.BAG_DELETE:
        Bag.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.BAG_DELETE__SUCCESS:
      case ActionTypes.BAG_DELETE_HANDLE: {
        const bagModel = Bag.withId(payload.bag.id);

        if (bagModel) {
          bagModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.BAGITEM_CREATE__SUCCESS:
      case ActionTypes.BAGITEM_UPDATE__SUCCESS:
        if (payload.bag) {
          Bag.upsert(payload.bag);
        }
        break;
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('id', false);
  }

  getOrderedBagitemsQuerySet() {
    return this.bagitems.orderBy('id', false);
  }

  getOrderedDiscountsQuerySet() {
    return this.discounts.orderBy('id');
  }

  // getOrderedFilteredProductsModelArray() {
  //   let productModels = this.getOrderedProductsQuerySet().toModelArray();

  //   const filterUserIds = this.board.filterUsers.toRefArray().map((user) => user.id);
  //   const filterAgentIds = this.board.filterLAgents.toRefArray().map((agent) => agent.id);

  //   if (filterUserIds.length > 0) {
  //     productModels = productModels.filter((productModel) => {
  //       const users = productModel.users.toRefArray();

  //       return users.some((user) => filterUserIds.includes(user.id));
  //     });
  //   }

  //   if (filterAgentIds.length > 0) {
  //     productModels = productModels.filter((productModel) => {
  //       const agents = productModel.agents.toRefArray();

  //       return agents.some((agent) => filterAgentIds.includes(agent.id));
  //     });
  //   }

  //   return productModels;
  // }

  deleteRelated() {
    this.sellitems.toModelArray().forEach((sellitemModel) => {
      sellitemModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
