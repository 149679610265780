import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'GroupMembership';

  static fields = {
    id: attr(),
    groupId: fk({
      to: 'Group',
      as: 'group',
      relatedName: 'memberships',
    }),
    userId: fk({
      to: 'User',
      as: 'user',
      relatedName: 'groupMemberships',
    }),
  };

  static reducer({ type, payload }, GroupMembership) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
        if (payload.groupMemberships) {
          payload.groupMemberships.forEach((groupMembership) => {
            GroupMembership.upsert(groupMembership);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        GroupMembership.all().delete();

        payload.groupMemberships.forEach((groupMembership) => {
          GroupMembership.upsert(groupMembership);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_CREATE__SUCCESS:
      case ActionTypes.PROJECT_CREATE_HANDLE:
      case ActionTypes.GROUP_CREATE__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.groupMemberships.forEach((groupMembership) => {
          GroupMembership.upsert(groupMembership);
        });

        break;
      case ActionTypes.GROUP_MEMBERSHIP_CREATE:
        GroupMembership.upsert(payload.groupMembership);

        break;
      case ActionTypes.GROUP_MEMBERSHIP_CREATE__SUCCESS:
        GroupMembership.withId(payload.localId).delete();
        GroupMembership.upsert(payload.groupMembership);

        break;
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        GroupMembership.upsert(payload.groupMembership);

        if (payload.groupMemberships) {
          payload.groupMemberships.forEach((groupMembership) => {
            GroupMembership.upsert(groupMembership);
          });
        }

        break;
      case ActionTypes.GROUP_MEMBERSHIP_DELETE:
        GroupMembership.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.GROUP_MEMBERSHIP_DELETE__SUCCESS:
      case ActionTypes.GROUP_MEMBERSHIP_DELETE_HANDLE: {
        const groupMembershipModel = GroupMembership.withId(payload.groupMembership.id);

        if (groupMembershipModel) {
          groupMembershipModel.deleteWithRelated();
        }

        break;
      }
      default:
    }
  }

  deleteRelated() {
    this.group.sellitems.toModelArray().forEach((sellitemModel) => {
      try {
        sellitemModel.users.remove(this.userId);
      } catch {} // eslint-disable-line no-empty
    });

    try {
      this.group.filterUsers.remove(this.userId);
    } catch {} // eslint-disable-line no-empty
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
