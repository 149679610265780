import socket from './socket';

/* Transformers */

export const transformBag = (bag) => ({
  ...bag,
  ...(bag.startDate && {
    startDate: new Date(bag.startDate),
  }),
  ...(bag.endDate && {
    endDate: new Date(bag.endDate),
  }),
});

export const transformBagData = (data) => ({
  ...data,
  ...(data.startDate && {
    startDate: data.startDate.toISOString(),
  }),
  ...(data.endDate && {
    endDate: data.endDate.toISOString(),
  }),
});

/* Actions */

const getBags = (groupId, data, headers) =>
  socket.get(`/groups/${groupId}/bags`, data, headers).then((body) => {
    return {
      ...body,
      items: body.items.map(transformBag),
    };
  });

const searchBags = (groupId, data, headers) =>
  socket.get(`/groups/${groupId}/search-bags`, data, headers).then((body) => ({
    ...body,
  }));

const createBag = (groupId, data, headers) =>
  socket.post(`/groups/${groupId}/bags`, transformBagData(data), headers).then((body) => ({
    ...body,
    item: transformBag(body.item),
  }));

const getBag = (id, headers) =>
  socket.get(`/bags/${id}`, undefined, headers).then((body) => ({
    ...body,
    item: transformBag(body.item),
  }));

const updateBag = (id, data, headers) =>
  socket.patch(`/bags/${id}`, transformBagData(data), headers).then((body) => ({
    ...body,
    item: transformBag(body.item),
  }));

const deleteBag = (id, headers) =>
  socket.delete(`/bags/${id}`, undefined, headers).then((body) => ({
    ...body,
    item: transformBag(body.item),
  }));

const recalculateCommission = (id, headers) =>
  socket.get(`/bags/${id}/commission`, undefined, headers).then((body) => ({
    ...body,
    item: transformBag(body.item),
  }));

/* Event handlers */

const makeHandleBagCreate = (next) => (body) => {
  next({
    ...body,
    item: transformBag(body.item),
  });
};

const makeHandleBagUpdate = (next) => (body) => {
  next({
    ...body,
    item: transformBag(body.item),
  });
};

const makeHandleBagDelete = makeHandleBagUpdate;

export default {
  getBags,
  searchBags,
  createBag,
  getBag,
  updateBag,
  deleteBag,
  recalculateCommission,
  makeHandleBagCreate,
  makeHandleBagUpdate,
  makeHandleBagDelete,
};
