import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';

import Paths from '../../constants/Paths';
import { PaymentStatuses, ProductStatuses } from '../../constants/Enums';
import NameEdit from './NameEdit';
import User from '../User';
import Label from '../Label';
import DueDate from '../DueDate';
import Timer from '../Timer';

import styles from './Product.module.scss';

const Product = React.memo(
  ({
    id,
    index,
    name,
    status,
    sellitem,
    productPrice,
    dueDate,
    timer,
    coverUrl,
    isPersisted,
    notificationsTotal,
    users,
    labels,
    canEdit,
    onUpdate,
  }) => {
    const nameEdit = useRef(null);

    const handleClick = useCallback(() => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }, []);

    const handleNameUpdate = useCallback(
      (newName) => {
        onUpdate({
          name: newName,
        });
      },
      [onUpdate],
    );

    let sellPrice = productPrice;
    if (sellitem?.total) {
      sellPrice = sellitem.total;
    }

    let totalPaid = '';
    let totalRemain = '';
    if (sellitem?.payments) {
      const sumPaid = sellitem.payments
        .filter((pm) => pm.status === PaymentStatuses.FINISH.name)
        .reduce((sum, { amount }) => sum + amount * 1, 0);
      totalRemain = (sellPrice - sumPaid).toLocaleString('en-GB');
      totalPaid = (sumPaid * 1).toLocaleString('en-GB');
    }

    const contentNode = (
      <>
        {coverUrl && <img src={coverUrl} alt="" className={styles.cover} />}
        <div
          className={styles.details}
          style={{ backgroundColor: ProductStatuses[status.toUpperCase()].hex }}
        >
          {labels.length > 0 && (
            <span className={styles.labels}>
              {labels.map((label) => (
                <span
                  key={label.id}
                  className={classNames(styles.attachment, styles.attachmentLeft)}
                >
                  <Label name={label.name} color={label.color} size="tiny" />
                </span>
              ))}
            </span>
          )}

          <div className={styles.name}>{name}</div>
          <div className={styles.sellPrice}>{(sellPrice * 1).toLocaleString('en-GB')}</div>
          <div className={styles.totalPaid}>{totalPaid}</div>
          <div className={styles.remain}>{totalRemain}</div>
          {(dueDate || timer || notificationsTotal > 0) && (
            <span className={styles.attachments}>
              {notificationsTotal > 0 && (
                <span
                  className={classNames(
                    styles.attachment,
                    styles.attachmentLeft,
                    styles.notification,
                  )}
                >
                  {notificationsTotal}
                </span>
              )}
              {dueDate && (
                <span className={classNames(styles.attachment, styles.attachmentLeft)}>
                  <DueDate value={dueDate} size="tiny" />
                </span>
              )}
              {timer && (
                <span className={classNames(styles.attachment, styles.attachmentLeft)}>
                  <Timer startedAt={timer.startedAt} total={timer.total} size="tiny" />
                </span>
              )}
            </span>
          )}
          {users.length > 0 && (
            <span className={classNames(styles.attachments, styles.attachmentsRight)}>
              {users.map((user) => (
                <span
                  key={user.id}
                  className={classNames(styles.attachment, styles.attachmentRight)}
                >
                  <User name={user.name} avatarUrl={user.avatarUrl} size="small" />
                </span>
              ))}
            </span>
          )}
        </div>
      </>
    );

    return (
      <Draggable
        draggableId={`product:${id}`}
        index={index}
        // isDragDisabled={!isPersisted || !canEdit}
        isDragDisabled
      >
        {({ innerRef, draggableProps, dragHandleProps }) => {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...draggableProps} {...dragHandleProps} ref={innerRef} className={styles.wrapper}>
              <NameEdit ref={nameEdit} defaultValue={name} onUpdate={handleNameUpdate}>
                <div className={styles.product}>
                  {isPersisted ? (
                    <>
                      {sellitem?.id ? (
                        <Link
                          to={Paths.SELLITEMS.replace(':id', sellitem?.id)}
                          className={styles.content}
                          onClick={handleClick}
                        >
                          {sellitem?.status === 'review' && (
                            <span className={classNames(styles.approve)}>
                              <Icon fitted name="check" size="small" />
                            </span>
                          )}

                          {contentNode}
                        </Link>
                      ) : (
                        <>
                          {sellitem?.status === 'review' && (
                            <span className={classNames(styles.approve)}>
                              <Icon fitted name="check" size="small" />
                            </span>
                          )}

                          {contentNode}
                        </>
                      )}

                      {canEdit && (
                        <Button
                          className={classNames(styles.actionsButton, styles.target)}
                          style={{ backgroundColor: ProductStatuses[status.toUpperCase()].hex }}
                        >
                          <Icon fitted name="bars" size="small" />
                        </Button>
                      )}
                    </>
                  ) : (
                    <span className={styles.content}>{contentNode}</span>
                  )}
                </div>
              </NameEdit>
            </div>
          );
        }}
      </Draggable>
    );
  },
);

Product.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  sellitem: PropTypes.objectOf(PropTypes.shape),
  productPrice: PropTypes.number.isRequired,
  dueDate: PropTypes.instanceOf(Date),
  timer: PropTypes.objectOf(PropTypes.shape),
  coverUrl: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  blockId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  notificationsTotal: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.object()).isRequired,
  labels: PropTypes.arrayOf(PropTypes.object()).isRequired,
  allProjectsToBlocks: PropTypes.arrayOf(PropTypes.object()).isRequired,
  allGroupMemberships: PropTypes.arrayOf(PropTypes.object()).isRequired,
  allLabels: PropTypes.arrayOf(PropTypes.object()).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

Product.defaultProps = {
  dueDate: undefined,
  timer: undefined,
  coverUrl: undefined,
  sellitem: undefined,
};

export default Product;
