import router from './router';
import socket from './socket';
import login from './login';
import core from './core';
import modal from './modal';
import user from './user';
import project from './project';
import projectManager from './project-manager';
import agent from './agent';
import category from './category';
import account from './account';
import block from './block';
import group from './group';
import groupMembership from './group-membership';
import importGroup from './import';
import label from './label';
import customer from './customer';
import customers from './customers';
import product from './product';
import products from './products';
import sellitems from './sellitems';
import sellitem from './sellitem';
import sellitemCustomers from './sellitem-customers';
import sellitemDiscounts from './sellitem-discounts';
import bag from './bag';
import bagitems from './bagitems';
import discounts from './discounts';
import bags from './bags';
import payment from './payment';
import attachment from './attachment';
import actions from './actions';
import action from './action';
import commentAction from './comment-action';
import notification from './notification';
import projectAgent from './project-agent';
import status from './status';
import dashboard from './dashboard';

export default [
  router,
  socket,
  login,
  core,
  modal,
  user,
  project,
  projectManager,
  agent,
  category,
  account,
  block,
  group,
  groupMembership,
  importGroup,
  label,
  customer,
  customers,
  product,
  products,
  sellitems,
  sellitem,
  sellitemCustomers,
  sellitemDiscounts,
  bags,
  bag,
  bagitems,
  discounts,
  payment,
  attachment,
  actions,
  action,
  commentAction,
  notification,
  projectAgent,
  status,
  dashboard,
];
