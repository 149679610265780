import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import ContractPaper from '../PaymentTable/ContractPaper';
import PrintContractPaper from '../PaymentTable/PrintContractPaper';

const ButtonPrintRenderer = React.memo(
  ({
    row,
    // col,
    // prop,
    // cellProperties,
    // value,
    sellitem,
    payments,
    accounts,
    customers,
    discounts,
    product,
    description,
    paymentDeposit,
    paymentConfirm1,
    paymentConfirm2,
  }) => {
    // The avaiable renderer-related props are:
    // - row (row index)
    // - col (column index)
    // - prop (column property name)
    // - TD (the HTML cell element)
    // - cellProperties (the cellProperties object for the edited cell)
    // console.log(props);

    const printPayment = () => {
      let paymentConfirm;
      if (row === 0) {
        // Cọc
        paymentConfirm = paymentDeposit;
      } else if (row === 1) {
        // TTGĐ1
        paymentConfirm = paymentConfirm1;
      } else {
        // TTGĐ2 ~
        paymentConfirm = paymentConfirm2;
      }

      const htmlPrint = ContractPaper(
        sellitem,
        payments,
        accounts,
        customers,
        discounts,
        product,
        row,
        description,
        paymentConfirm,
      );
      PrintContractPaper(htmlPrint);
    };
    return (
      <div style={{ width: '100%' }}>
        <Button
          size="mini"
          icon="print"
          style={{ width: '100%', margin: '-10px 0px 0px -10px' }}
          onClick={printPayment}
        />
      </div>
    );
  },
);

ButtonPrintRenderer.propTypes = {
  row: PropTypes.number,
  // col: PropTypes.number,
  // prop: PropTypes.string,
  // cellProperties: PropTypes.objectOf(PropTypes.shape),
  // value: PropTypes.string,
  sellitem: PropTypes.objectOf(PropTypes.shape).isRequired,
  payments: PropTypes.arrayOf(PropTypes.object()).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object()).isRequired,
  customers: PropTypes.arrayOf(PropTypes.object()).isRequired,
  discounts: PropTypes.arrayOf(PropTypes.object()).isRequired,
  product: PropTypes.objectOf(PropTypes.shape).isRequired,
  description: PropTypes.string.isRequired,
  paymentDeposit: PropTypes.string.isRequired,
  paymentConfirm1: PropTypes.string.isRequired,
  paymentConfirm2: PropTypes.string.isRequired,
};

ButtonPrintRenderer.defaultProps = {
  row: undefined,
  // col: undefined,
  // prop: undefined,
  // value: undefined,
  // cellProperties: undefined,
};

export default ButtonPrintRenderer;
