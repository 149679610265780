import socket from './socket';

/* Actions */

const createSellitemMembership = (sellitemId, data, headers) =>
  socket.post(`/sellitems/${sellitemId}/memberships`, data, headers);

const deleteSellitemMembership = (sellitemId, userId, headers) =>
  socket.delete(`/sellitems/${sellitemId}/memberships?userId=${userId}`, undefined, headers);

export default {
  createSellitemMembership,
  deleteSellitemMembership,
};
