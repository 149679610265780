/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { Dropdown, Tab, TextArea, Button } from 'semantic-ui-react';
import JoditEditor from 'jodit-react';

import styles from './EditorPane.module.scss';

const EditorPane = React.memo(
  ({
    paymentDeposit,
    paymentConfirm1,
    paymentConfirm2,
    contract1,
    contract2,
    contract3,
    contract4,
    appendix1,
    appendix2,
    appendix3,
    appendix4,
    appendix5,
    onUpdate,
  }) => {
    const [t] = useTranslation();

    const docContents = {
      paymentDeposit,
      paymentConfirm1,
      paymentConfirm2,
      contract1,
      contract2,
      contract3,
      appendix1,
      appendix2,
      appendix3,
      appendix4,
      appendix5,
    };
    const [docName, setDocName] = useState('');

    const docNameOptions = useMemo(
      () => [
        {
          key: 0,
          value: 'paymentDeposit',
          text: 'Xác nhận đặt cọc',
        },
        {
          key: 1,
          value: 'paymentConfirm1',
          text: 'Xác nhận TTGĐ1',
        },
        {
          key: 2,
          value: 'paymentConfirm2',
          text: 'Xác nhận TT từ GĐ2',
        },
        {
          key: 3,
          value: 'contract1',
          text: 'Hợp đồng TT thường',
        },
        {
          key: 4,
          value: 'contract2',
          text: 'Hợp đồng TT nhanh',
        },
        {
          key: 5,
          value: 'contract3',
          text: 'Hợp đồng Ngân hàng hỗ trợ',
        },
        {
          key: 6,
          value: 'appendix1',
          text: 'Phụ lục 1',
        },
        {
          key: 7,
          value: 'appendix2',
          text: 'Phụ lục 2',
        },
        {
          key: 8,
          value: 'appendix3',
          text: 'Phụ lục 3',
        },
        {
          key: 9,
          value: 'appendix4',
          text: 'Phụ lục 4',
        },
        {
          key: 10,
          value: 'appendix5',
          text: 'Phụ lục 5',
        },
      ],
      [],
    );

    const suggestions = [
      { text: 'Số hợp đồng', value: '$SHD$' },
      { text: 'Tên khách hàng', value: '$TKH$' },
      { text: 'Ký hiệu lô đất LKXX.YY', value: '$KHLD$' },
      { text: 'Miêu tả dự án', value: '$MTDA$' },
      { text: 'Ngày hôm nay', value: '$NHN$' },
      { text: 'Ngày hôm nay DD/MM/YYYY', value: '$NHNSHORT$' },
      { text: 'Ngày nộp tiền', value: '$NNT$' },
      { text: 'Ngày nộp tiền DD/MM/YYYY', value: '$NNTSHORT$' },
      { text: 'Ngày nộp cọc', value: '$NNC$' },
      { text: 'Ngày nộp cọc DD/MM/YYYY', value: '$NNCSHORT$' },
      { text: 'Số tiền cọc bằng số', value: '$STCBS$' },
      { text: 'Số tiền cọc bằng chữ', value: '$STCBC$' },
      { text: 'Hình thức nộp tiền', value: '$HTNT$' },
      { text: 'Thông tin khách hàng', value: '$TTKH$' },
      { text: 'Địa chỉ liên hệ', value: '$DCLH$' },
      { text: 'Số điện thoại', value: '$SDT$' },
      { text: 'Số đợt nộp tiền', value: '$SDNT$' },
      { text: 'Số tiền nộp bằng số', value: '$STNBS$' },
      { text: 'Số tiền nộp bằng chữ', value: '$STNBC$' },
      { text: 'Địa chỉ thửa đất', value: '$DCTD$' },
      { text: 'Số lô đất', value: '$SLD$' },
      { text: 'Diện tích bằng số', value: '$DTBS$' },
      { text: 'Diện tích bằng chữ', value: '$DTBC$' },
      { text: 'Diện tích sàn bằng số', value: '$DTSBS$', url: '#' },
      { text: 'Diện tích sàn bằng chữ', value: '$DTSBC$', url: '#' },
      { text: 'Đơn giá đất', value: '$DGD$' },
      { text: 'Đơn giá xây dựng', value: '$DGXD$' },
      { text: 'Tổng tiền xây dựng bằng số', value: '$TTXDBS$', url: '#' },
      { text: 'Tổng tiền xây dựng bằng chữ', value: '$TTXDBC$', url: '#' },
      { text: 'Tổng giá trị hợp đồng bằng số', value: '$TGTHDBS$' },
      { text: 'Tổng giá trị Hợp đồng Bằng chữ', value: '$TGTHDBC$' },
      { text: 'Thành tiền đất trước VAT bằng số', value: '$TTDTVATBS$' },
      { text: 'Thành tiền đất trước VAT bằng chữ', value: '$TTDTVATBC$' },
      { text: 'Thành tiền đất sau VAT bằng số', value: '$TTDSVATBS$' },
      { text: 'Thành tiền đất sau VAT bằng chữ', value: '$TTDSVATBC$' },
      { text: 'Ưu đãi tổng cộng bằng số', value: '$UDTCBS$' },
      { text: 'Ưu đãi tổng cộng bằng chữ', value: '$UDTCBC$' },
      {
        text: 'Chiết khấu 1',
        value: '$CK1BS$',
      },
      {
        text: 'Chiết khấu 1 số tiền bằng số',
        value: '$CK1STBS$',
      },
      {
        text: 'Chiết khấu 1 số tiền bằng chữ',
        value: '$CK1STBC$',
      },
      {
        text: 'Nội dung chiết khấu 1',
        value: '$NDCK1$',
      },
      {
        text: 'Chiết khấu 2',
        value: '$CK2BS$',
      },
      {
        text: 'Chiết khấu 2 số tiền bằng số',
        value: '$CK2STBS$',
      },
      {
        text: 'Chiết khấu 2 số tiền bằng chữ',
        value: '$CK2STBC$',
      },
      {
        text: 'Nội dung chiết khấu 2',
        value: '$NDCK2$',
      },
      {
        text: 'Chiết khấu 3',
        value: '$CK3BS$',
      },
      {
        text: 'Chiết khấu 3 số tiền bằng số',
        value: '$CK3STBS$',
      },
      {
        text: 'Chiết khấu 3 số tiền bằng chữ',
        value: '$CK3STBC$',
      },
      {
        text: 'Nội dung chiết khấu 3',
        value: '$NDCK3$',
      },
      {
        text: 'Nội dung phiếu bốc thăm',
        value: '$NDCK4$',
      },
      {
        text: 'Danh sách chiết khấu',
        value: '$DSCK$',
      },
      {
        text: 'Tổng số tiền còn lại sau khi trừ chiết khấu bằng số',
        value: '$TSTCLSKTCKBS$',
      },
      {
        text: 'Tổng số tiền còn lại sau khi trừ chiết khấu bằng chữ',
        value: '$TSTCLSKTCKBC$',
      },
      { text: 'Bảng tiến độ thanh toán', value: '$BTDTT$' },
      { text: 'Thời hạn thanh toán giai đoạn 1', value: '$THTTGD1$' },
      { text: 'Phần trăm đợt 1', value: '$PTD1$' },
      { text: 'Số tiền đợt 1 bằng số', value: '$STD1BS$' },
      { text: 'Số tiền đợt 1 bằng chữ', value: '$STD1BC$' },
      { text: 'Phần trăm đợt 2', value: '$PTD2$' },
      { text: 'Số tiền đợt 2 bằng số', value: '$STD2BS$' },
      { text: 'Số tiền đợt 2 bằng chữ', value: '$STD2BC$' },
      { text: 'Phần trăm đợt 3', value: '$PTD3$' },
      { text: 'Số tiền đợt 3 bằng số', value: '$STD3BS$' },
      { text: 'Số tiền đợt 3 bằng chữ', value: '$STD3BC$' },
      { text: 'Phần trăm đợt 4', value: '$PTD4$' },
      { text: 'Số tiền đợt 4 bằng số', value: '$STD4BS$' },
      { text: 'Số tiền đợt 4 bằng chữ', value: '$STD4BC$' },
      { text: 'Phần trăm đợt 5', value: '$PTD5$' },
      { text: 'Số tiền đợt 5 bằng số', value: '$STD5BS$' },
      { text: 'Số tiền đợt 5 bằng chữ', value: '$STD5BC$' },
      { text: 'Số tiền nộp tổng cộng bằng số', value: '$STNTCBS$' },
      { text: 'Số tiền nộp tổng cộng bằng chữ', value: '$STNTCBC$' },
      { text: 'Số tài khoản', value: '$STK$' },
      { text: 'Tên ngân hàng', value: '$TNN$' },
      { text: 'Ngắt trang mới', value: '$NTM$' },
    ];

    const joditEditor = useRef(null);
    const [content, setContent] = useState('');

    const config = {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      height: 640,
      controls: {
        font: { list: { Calibri: 'Calibri' } },
      },
      extraButtons: [
        {
          name: 'font size',
          icon: 'fontsize',
          command: 'fontSize',
          list: ['13pt', '14pt', '15pt', '16pt', '17pt', '18pt', '19pt', '20pt'],
          template: (editor, key, value) => value,
          tooltip: 'Font size',
          // isActiveChild: (editor, control) => {
          //   const fontSize = editor.selection.getHTML().match(/font-size: (\w+);/) || [
          //     'font-size: 14px',
          //     '14px',
          //   ];

          //   return `${control.args[1]}px` === fontSize[1];
          // },
        },
        {
          name: 'Nhập biến số',
          list: suggestions.map((x) => `${x.text} @${x.value}`),
          exec(editor, t, { control }) {
            if (control.args) {
              const key = control.args[0];
              const variable = key.match(/@(.*)/);
              editor.s.insertHTML(
                `<span style="color: red; padding: 2px; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;">${variable[0]}</span>`,
              );
            }
          },
          template: (editor, key, value) => {
            return `<span>${value}</span>`;
          },
        },
      ],
    };

    const handleFieldChange = (event, data) => {
      const htmlContract = docContents[data.value];
      setContent(htmlContract);
      setDocName(data.value);
    };

    const saveEditorToHtml = () => {
      onUpdate({ [docName]: content });
    };

    return (
      <Tab.Pane attached={false} className={styles.wrapper}>
        <div>
          <Dropdown
            selection
            name="doc"
            value={docName}
            options={docNameOptions}
            className={styles.field}
            placeholder="Chọn loại tài liệu cần sửa"
            onChange={handleFieldChange}
          />
          <Button
            floated="right"
            style={{ margin: 2 }}
            disabled={docName === ''}
            onClick={saveEditorToHtml}
          >
            {t('action.save')}
          </Button>
        </div>
        <JoditEditor
          ref={joditEditor}
          value={content}
          config={config}
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex={1}
          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(newContent) => {}}
        />
      </Tab.Pane>
    );
  },
);

EditorPane.propTypes = {
  paymentDeposit: PropTypes.string.isRequired,
  paymentConfirm1: PropTypes.string.isRequired,
  paymentConfirm2: PropTypes.string.isRequired,
  contract1: PropTypes.string.isRequired,
  contract2: PropTypes.string.isRequired,
  contract3: PropTypes.string.isRequired,
  contract4: PropTypes.string.isRequired,
  appendix1: PropTypes.string.isRequired,
  appendix2: PropTypes.string.isRequired,
  appendix3: PropTypes.string.isRequired,
  appendix4: PropTypes.string.isRequired,
  appendix5: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
export default EditorPane;
