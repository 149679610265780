import EntryActionTypes from '../../constants/EntryActionTypes';

export const createMembershipInCurrentGroup = (data) => ({
  type: EntryActionTypes.MEMBERSHIP_IN_CURRENT_GROUP_CREATE,
  payload: {
    data,
  },
});

export const handleGroupMembershipCreate = (groupMembership) => ({
  type: EntryActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE,
  payload: {
    groupMembership,
  },
});

export const deleteGroupMembership = (id) => ({
  type: EntryActionTypes.GROUP_MEMBERSHIP_DELETE,
  payload: {
    id,
  },
});

export const handleGroupMembershipDelete = (groupMembership) => ({
  type: EntryActionTypes.GROUP_MEMBERSHIP_DELETE_HANDLE,
  payload: {
    groupMembership,
  },
});
