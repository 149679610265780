import { Previewer } from 'pagedjs';

const PrintContractPaper = (html) => {
  const iframe = document.createElement('iframe');
  // iframe.id = 'iframe';
  // iframe.style.cssText = 'display: none';
  iframe.style.cssText = 'position:absolute;bottom:0;width:0;height:0';

  document.body.appendChild(iframe);
  const doc = iframe.contentDocument;

  doc.open('text/html', 'replace');

  const htmlPrint = `<!doctype html><html><head>
    <link href="/pagedjs/print.css" rel="stylesheet" type="text/css">
    <script src="/pagedjs/paged.polyfill.js"></script>

    </head><body><div id="printContent">
      ${html}
    </div></body></html>`;

  doc.write(htmlPrint);
  doc.close();
  const paged = new Previewer();

  paged.preview(html, ['/pagedjs/print.css'], iframe).then((flow) => {
    console.log('Rendered', flow.total, 'pages.');
    // iframe.contentWindow.print();
    setTimeout(() => {
      doc.defaultView.print();
      setTimeout(() => {
        // this code will be executed 10ms after you close printing window
        iframe.parentElement.removeChild(iframe);
      }, 10);
    }, 1000);
  });

  // console.log(hot.getData());
  // doc.defaultView.print();

  // setTimeout(() => {
  //   // this code will be executed 10ms after you close printing window
  //   // iframe.parentElement.removeChild(iframe);
  // }, 10);
};

export default PrintContractPaper;
