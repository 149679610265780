/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import { Input } from '../../lib/custom-ui';
import SearchCustomers from '../SearchCustomers';
import { usePrevious } from '../../lib/hooks';

import { useForm } from '../../hooks';
import { Statuses } from '../../constants/Enums';

import styles from './AddOrder.module.scss';

const AddOrder = React.memo(
  ({
    defaultData,
    isSubmitting,
    error,
    product,
    searchResults,
    onCreateSellitem,
    onSearchCustomers,
  }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [data, handleFieldChange, setData] = useForm({
      ...product,
      customerId: 0,
      invoice: '',
      colorCode: '',
      sizeCode: '',
      quantity: 1,
    });

    const [customerSelected, setCustomerSelected] = useState({});

    const quantityField = useRef(null);
    const [customerError, setCustomerError] = useState(false);

    const handleSubmit = useCallback(() => {
      const cleanData = {
        ...data,
        color: data.color,
        size: data.size,
        quantity: data.quantity,
        customerId: customerSelected.id,
        invoice: `${customerSelected.code}-${t('format:longDate', {
          postProcess: 'formatDate',
          value: new Date(),
        })}`,
        productId: product.id,
        status: Statuses.NEWORDER.name,
      };

      let isError;

      if (!customerSelected.id) {
        setCustomerError(true);
        isError = true;
      }

      if (isError) return;

      onCreateSellitem(cleanData);
    }, [onCreateSellitem, data, product, customerSelected, t]);

    const handleCancel = useCallback(() => {
      setData({
        name: '',
        color: '',
        size: '',
        quantity: 0,
      });
    }, [setData]);

    useEffect(() => {
      setData({
        ...product,
        customerId: 0,
        invoice: '',
        colorCode: '',
        sizeCode: '',
        quantity: 1,
      });
    }, [product, setData]);

    useEffect(() => {
      if (wasSubmitting && !isSubmitting) {
        if (!error) {
          setData({
            ...defaultData,
            customerId: 0,
          });
        }
      }
    }, [wasSubmitting, error, isSubmitting, defaultData, setData]);

    return (
      <Form className={styles.formAddSellitem}>
        <Form.Group className={styles.fieldGroup}>
          <Form.Field
            width="8"
            required
            error={customerError}
            onFocus={() => setCustomerError(false)}
          >
            <SearchCustomers
              searchResults={searchResults}
              onSearchCustomers={onSearchCustomers}
              onCustomerSelected={setCustomerSelected}
            />
          </Form.Field>
          <Form.Field className={styles.formActions}>
            <Button positive content={t('action.save')} onClick={handleSubmit} />
            <Button basic content={t('action.cancel')} onClick={handleCancel} />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  },
);

AddOrder.propTypes = {
  defaultData: PropTypes.objectOf(PropTypes.shape).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  product: PropTypes.objectOf(PropTypes.shape).isRequired,
  onCreateSellitem: PropTypes.func.isRequired,
  searchResults: PropTypes.objectOf(PropTypes.shape).isRequired,
  onSearchCustomers: PropTypes.func.isRequired,
};

AddOrder.defaultProps = {
  error: undefined,
};

export default AddOrder;
