import socket from './socket';

/* Actions */

const createCategory = (data, headers) => socket.post(`/categories`, data, headers);

const updateCategory = (id, data, headers) => socket.patch(`/categories/${id}`, data, headers);

const deleteCategory = (id, headers) => socket.delete(`/categories/${id}`, undefined, headers);

export default {
  createCategory,
  updateCategory,
  deleteCategory,
};
