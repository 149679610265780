import socket from './socket';

/* Actions */

const createAccount = (data, headers) => socket.post(`/accounts`, data, headers);

const updateAccount = (id, data, headers) => socket.patch(`/accounts/${id}`, data, headers);

const deleteAccount = (id, headers) => socket.delete(`/accounts/${id}`, undefined, headers);

export default {
  createAccount,
  updateAccount,
  deleteAccount,
};
