import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Paths from '../../constants/Paths';

// eslint-disable-next-line no-unused-vars
const LinkRenderer = React.memo(({ row, col, prop, cellProperties }) => {
  // The avaiable renderer-related props are:
  // - row (row index)
  // - col (column index)
  // - prop (column property name)
  // - TD (the HTML cell element)
  // - cellProperties (the cellProperties object for the edited cell)
  // console.log(props);

  const data = cellProperties?.instance.getSourceDataAtRow(row);
  const sellitemId = data?.sellitem?.id || data.id;
  return <Link to={Paths.SELLITEMS.replace(':id', sellitemId)}>{data.product.name}</Link>;
});

LinkRenderer.propTypes = {
  row: PropTypes.number,
  col: PropTypes.number,
  prop: PropTypes.string,
  cellProperties: PropTypes.objectOf(PropTypes.shape),
};

LinkRenderer.defaultProps = {
  row: undefined,
  col: undefined,
  prop: undefined,
  cellProperties: undefined,
};

export default LinkRenderer;
