import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { pathSelector } from '../../../selectors';
import { searchCustomers } from '../../../actions';
import api from '../../../api';

export function* searchCustomersService(groupId, query) {
  yield put(searchCustomers(groupId, query));

  let customers;
  let sellitems;
  let payments;

  try {
    ({
      items: customers,
      included: { sellitems, payments },
    } = yield call(request, api.searchCustomers, groupId, {
      query,
    }));
  } catch (error) {
    yield put(searchCustomers.failure(groupId, error));
    return;
  }

  yield put(searchCustomers.success(groupId, customers, sellitems, payments));
}

export function* searchCustomersInCurrentGroupService(query) {
  const { groupId } = yield select(pathSelector);

  yield call(searchCustomersService, groupId, query);
}
