import socket from './socket';

/* Actions */

const createBlock = (groupId, data, headers) =>
  socket.post(`/groups/${groupId}/blocks`, data, headers);

const updateBlock = (id, data, headers) => socket.patch(`/blocks/${id}`, data, headers);

const deleteBlock = (id, headers) => socket.delete(`/blocks/${id}`, undefined, headers);

export default {
  createBlock,
  updateBlock,
  deleteBlock,
};
