import EntryActionTypes from '../../constants/EntryActionTypes';

export const createLabelInCurrentGroup = (data) => ({
  type: EntryActionTypes.LABEL_IN_CURRENT_GROUP_CREATE,
  payload: {
    data,
  },
});

export const handleLabelCreate = (label) => ({
  type: EntryActionTypes.LABEL_CREATE_HANDLE,
  payload: {
    label,
  },
});

export const updateLabel = (id, data) => ({
  type: EntryActionTypes.LABEL_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleLabelUpdate = (label) => ({
  type: EntryActionTypes.LABEL_UPDATE_HANDLE,
  payload: {
    label,
  },
});

export const deleteLabel = (id) => ({
  type: EntryActionTypes.LABEL_DELETE,
  payload: {
    id,
  },
});

export const handleLabelDelete = (label) => ({
  type: EntryActionTypes.LABEL_DELETE_HANDLE,
  payload: {
    label,
  },
});

export const addLabelToSellitem = (id, sellitemId) => ({
  type: EntryActionTypes.LABEL_TO_SELLITEM_ADD,
  payload: {
    id,
    sellitemId,
  },
});

export const addLabelToCurrentSellitem = (id) => ({
  type: EntryActionTypes.LABEL_TO_CURRENT_SELLITEM_ADD,
  payload: {
    id,
  },
});

export const handleLabelToSellitemAdd = (sellitemLabel) => ({
  type: EntryActionTypes.LABEL_TO_SELLITEM_ADD_HANDLE,
  payload: {
    sellitemLabel,
  },
});

export const removeLabelFromSellitem = (id, sellitemId) => ({
  type: EntryActionTypes.LABEL_FROM_SELLITEM_REMOVE,
  payload: {
    id,
    sellitemId,
  },
});

export const removeLabelFromCurrentSellitem = (id) => ({
  type: EntryActionTypes.LABEL_FROM_CURRENT_SELLITEM_REMOVE,
  payload: {
    id,
  },
});

export const handleLabelFromSellitemRemove = (sellitemLabel) => ({
  type: EntryActionTypes.LABEL_FROM_SELLITEM_REMOVE_HANDLE,
  payload: {
    sellitemLabel,
  },
});

export const addLabelToFilterInCurrentGroup = (id) => ({
  type: EntryActionTypes.LABEL_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    id,
  },
});

export const removeLabelFromFilterInCurrentGroup = (id) => ({
  type: EntryActionTypes.LABEL_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    id,
  },
});
