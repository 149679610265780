import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  currentUserSelector,
  agentsForCurrentProjectSelector,
  categoriesForCurrentProjectSelector,
  blocksForCurrentGroupSelector,
} from '../selectors';
import {
  clearUserCreateError,
  createProduct,
  closeModal,
  createAttachmentProduct,
} from '../actions/entry';
import ProductAddModal from '../components/ProductAddModal';

const mapStateToProps = (state) => {
  const { data: defaultData, isSubmitting, error } = state.ui.productCreateForm;
  const { isAdmin } = currentUserSelector(state);
  const blocks = blocksForCurrentGroupSelector(state);
  const agents = agentsForCurrentProjectSelector(state);
  const categories = categoriesForCurrentProjectSelector(state);

  return {
    blocks,
    agents,
    categories,
    defaultData,
    isSubmitting,
    error,
    isAdmin,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAttachmentCreate: createAttachmentProduct,
      onCreate: (data) => createProduct(data.blockId, data),
      onMessageDismiss: clearUserCreateError,
      onClose: closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductAddModal);
