import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateBagitem } from '../actions/entry';

import { makeBagByIdSelector, bagitemsForBagIdSelector, pathSelector } from '../selectors';
import BagListItem from '../components/BagListItem';
import { ProductStatuses } from '../constants/Enums';

const makeMapStateToProps = () => {
  const bagByIdSelector = makeBagByIdSelector();

  return (state, { id }) => {
    const { bagId } = pathSelector(state);
    const { name, category, startDate, endDate, status } = bagByIdSelector(state, id);
    const bagitems = bagitemsForBagIdSelector(state, id);

    return {
      id,
      name,
      categoryName: category?.name || '',
      startDate,
      endDate,
      status,
      selledTotal: bagitems.filter(({ status: s }) => s === ProductStatuses.SELLED.name).length,
      total: bagitems.length,
      isActive: bagId === id,
    };
  };
};

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      onUpdate: (data) => updateBagitem(id, data),
    },
    dispatch,
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(BagListItem);
