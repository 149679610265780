/* eslint-disable no-unused-vars */
import React, { useImperativeHandle, useState } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './Summary.module.scss';

const Summary = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const [selected, setSelected] = useState([]);

  useImperativeHandle(ref, () => ({
    show(dt) {
      setSelected(dt);
    },
  }));

  // if (selected.length === 1 && selected[0].length === 1) {
  //   return (
  //     <div className={styles.wrapper}>
  //       <span className={styles.item}>{String(selected[0])}</span>
  //     </div>
  //   );
  // }

  const flat = selected.flat().filter((it) => it !== null);
  const numberFlat = flat.filter((it) => /^-?[\d.]+$/.test(it));
  const count = flat.length;
  const countNumber = numberFlat.length;
  const total =
    countNumber > 0 && numberFlat.reduce((sum, current) => sum + parseFloat(current), 0);
  const avg = total / countNumber || 0;

  return (
    <div className={styles.wrapper}>
      {countNumber > 0 && (
        <>
          <span className={styles.item}>
            {t('common.summary.total')}: {total.toLocaleString('en-GB')}
          </span>
          <span className={styles.item}>
            {t('common.summary.average')}: {avg.toLocaleString('en-GB')}
          </span>
          <span className={styles.item}>
            {t('common.summary.countNumber')}: {countNumber.toLocaleString('en-GB')}
          </span>
        </>
      )}
      {count > 0 && (
        <span className={styles.item}>
          {t('common.summary.count')}: {count.toLocaleString('en-GB')}
        </span>
      )}
    </div>
  );
});

// Summary.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.object()).isRequired,
// };

export default Summary;
