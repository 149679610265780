import XLSX from 'sheetjs-style';

const exportXlsx = (sellitems, nestedHeaders, colHeaders, project) => {
  const s = {
    border: {
      top: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
      bottom: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
    },
  };
  const hs = {
    ...s,
    alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
    font: {
      bold: true,
    },
  };
  const h1 = {
    alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    font: {
      sz: 12,
      bold: true,
    },
  };
  const h2 = {
    ...s,
    alignment: { horizontal: 'center', vertical: 'center' },
    font: {
      sz: 14,
      bold: true,
    },
  };

  const dataHeaders = [
    {
      STT: {
        v: `CÔNG TY CỔ PHẦN ĐẦU TƯ - PHÁT TRIỂN ĐÔ THỊ ÂN PHÚ
      28 - Phan Bội Châu - Phường Hòa Thuận - Tam Kỳ - Quảng Nam`,
        s: h1,
      },
      block: { v: '', s: h1 },
      nameProduct: { v: '', s: h1 },
      statusLabel: { v: '', s: h1 },
      nameCustomer: { v: '', s: h1 },
      landArea: { v: '', s: h1 },
      landPrice: { v: '', s: h1 },
      factor: { v: '', s: h1 },
      totalLandPrice: { v: '', s: h1 },
      floorArea: { v: '', s: h1 },
      floorPrice: { v: '', s: h1 },
      totalFloorPrice: { v: '', s: h1 },
      sellPrice: { v: '', s: h1 },
      process1: { v: '', s: h1 },
      process1GotDate: { v: '', s: h1 },
      discount1: { v: '', s: h1 },
      discount2: { v: '', s: h1 },
      discount3: { v: '', s: h1 },
      loanPercent: { v: '', s: h1 },
      loanAmount: { v: '', s: h1 },
      discountMore: { v: '', s: h1 },
      totalDiscount: { v: '', s: h1 },
      total: { v: '', s: h1 },
      process2: { v: '', s: h1 },
      process2GotDate: { v: '', s: h1 },
      process3: { v: '', s: h1 },
      process3GotDate: { v: '', s: h1 },
      process4: { v: '', s: h1 },
      process4GotDate: { v: '', s: h1 },
      process5: { v: '', s: h1 },
      process5GotDate: { v: '', s: h1 },
      process6: { v: '', s: h1 },
      process6GotDate: { v: '', s: h1 },
      process7: { v: '', s: h1 },
      process7GotDate: { v: '', s: h1 },
      process8: { v: '', s: h1 },
      process8GotDate: { v: '', s: h1 },
      process9: { v: '', s: h1 },
      process9GotDate: { v: '', s: h1 },
      process10: { v: '', s: h1 },
      process10GotDate: { v: '', s: h1 },
      totalPaid: { v: '', s: h1 },
      remain: { v: '', s: h1 },
      bonus: { v: '', s: h1 },
      totalForCommission: { v: '', s: h1 },
      commissionPercent: { v: '', s: h1 },
      commissionAmount: { v: '', s: h1 },
      totalCommissionAmount: { v: '', s: h1 },
      ownAgent: { v: '', s: h1 },
      agent: { v: '', s: h1 },
    },
    {
      STT: {
        v: 'BẢNG THEO DÕI BÁN HÀNG',
        s: h2,
      },
      block: { v: '', s: h2 },
      nameProduct: { v: '', s: h2 },
      statusLabel: { v: '', s: h2 },
      nameCustomer: { v: '', s: h2 },
      landArea: { v: '', s: h2 },
      landPrice: { v: '', s: h2 },
      factor: { v: '', s: h2 },
      totalLandPrice: { v: '', s: h2 },
      floorArea: { v: '', s: h2 },
      floorPrice: { v: '', s: h2 },
      totalFloorPrice: { v: '', s: h2 },
      sellPrice: { v: '', s: h2 },
      process1: { v: '', s: h2 },
      process1GotDate: { v: '', s: h2 },
      discount1: { v: '', s: h2 },
      discount2: { v: '', s: h2 },
      discount3: { v: '', s: h2 },
      loanPercent: { v: '', s: h2 },
      loanAmount: { v: '', s: h2 },
      discountMore: { v: '', s: h2 },
      totalDiscount: { v: '', s: h2 },
      total: { v: '', s: h2 },
      process2: { v: '', s: h2 },
      process2GotDate: { v: '', s: h2 },
      process3: { v: '', s: h2 },
      process3GotDate: { v: '', s: h2 },
      process4: { v: '', s: h2 },
      process4GotDate: { v: '', s: h2 },
      process5: { v: '', s: h2 },
      process5GotDate: { v: '', s: h2 },
      process6: { v: '', s: h2 },
      process6GotDate: { v: '', s: h2 },
      process7: { v: '', s: h2 },
      process7GotDate: { v: '', s: h2 },
      process8: { v: '', s: h2 },
      process8GotDate: { v: '', s: h2 },
      process9: { v: '', s: h2 },
      process9GotDate: { v: '', s: h2 },
      process10: { v: '', s: h2 },
      process10GotDate: { v: '', s: h2 },
      totalPaid: { v: '', s: h2 },
      remain: { v: '', s: h2 },
      bonus: { v: '', s: h2 },
      totalForCommission: { v: '', s: h2 },
      commissionPercent: { v: '', s: h2 },
      commissionAmount: { v: '', s: h2 },
      totalCommissionAmount: { v: '', s: h2 },
      ownAgent: { v: '', s: h2 },
      agent: { v: '', s: h2 },
    },
    {
      STT: {
        v: nestedHeaders[0],
        s: hs,
      },
      block: { v: nestedHeaders[1], s: hs },
      nameProduct: { v: '', s: hs },
      statusLabel: { v: '', s: hs },
      nameCustomer: { v: '', s: hs },
      landArea: { v: '', s: hs },
      landPrice: { v: '', s: hs },
      factor: { v: '', s: hs },
      totalLandPrice: { v: '', s: hs },
      floorArea: { v: '', s: hs },
      floorPrice: { v: '', s: hs },
      totalFloorPrice: { v: '', s: hs },
      sellPrice: { v: '', s: hs },
      process1: { v: nestedHeaders[2], s: hs },
      process1GotDate: { v: '', s: hs },
      discount1: { v: nestedHeaders[3], s: hs },
      discount2: { v: '', s: hs },
      discount3: { v: '', s: hs },
      loanPercent: { v: '', s: hs },
      loanAmount: { v: '', s: hs },
      discountMore: { v: '', s: hs },
      totalDiscount: { v: '', s: hs },
      total: { v: colHeaders[22], s: hs },
      process2: { v: nestedHeaders[4], s: hs },
      process2GotDate: { v: '', s: hs },
      process3: { v: '', s: hs },
      process3GotDate: { v: '', s: hs },
      process4: { v: '', s: hs },
      process4GotDate: { v: '', s: hs },
      process5: { v: '', s: hs },
      process5GotDate: { v: '', s: hs },
      process6: { v: '', s: hs },
      process6GotDate: { v: '', s: hs },
      process7: { v: '', s: hs },
      process7GotDate: { v: '', s: hs },
      process8: { v: '', s: hs },
      process8GotDate: { v: '', s: hs },
      process9: { v: '', s: hs },
      process9GotDate: { v: '', s: hs },
      process10: { v: '', s: hs },
      process10GotDate: { v: '', s: hs },
      totalPaid: { v: '', s: hs },
      remain: { v: '', s: hs },
      bonus: { v: colHeaders[43], s: hs },
      totalForCommission: { v: colHeaders[44], s: hs },
      commissionPercent: { v: colHeaders[45], s: hs },
      commissionAmount: { v: colHeaders[46], s: hs },
      totalCommissionAmount: { v: colHeaders[47], s: hs },
      ownAgent: { v: colHeaders[48], s: hs },
      agent: { v: colHeaders[49], s: hs },
    },
    {
      STT: { v: colHeaders[0], s: hs },
      block: { v: colHeaders[1], s: hs },
      nameProduct: { v: colHeaders[2], s: hs },
      statusLabel: { v: colHeaders[3], s: hs },
      nameCustomer: { v: colHeaders[4], s: hs },
      landArea: { v: colHeaders[5], s: hs },
      landPrice: { v: colHeaders[6], s: hs },
      factor: { v: colHeaders[7], s: hs },
      totalLandPrice: { v: colHeaders[8], s: hs },
      floorArea: { v: colHeaders[9], s: hs },
      floorPrice: { v: colHeaders[10], s: hs },
      totalFloorPrice: { v: colHeaders[11], s: hs },
      sellPrice: { v: colHeaders[12], s: hs },
      process1: { v: colHeaders[13], s: hs },
      process1GotDate: { v: colHeaders[14], s: hs },
      discount1: { v: colHeaders[15], s: hs },
      discount2: { v: colHeaders[16], s: hs },
      discount3: { v: colHeaders[17], s: hs },
      loanPercent: { v: colHeaders[18], s: hs },
      loanAmount: { v: colHeaders[19], s: hs },
      discountMore: { v: colHeaders[20], s: hs },
      totalDiscount: { v: colHeaders[21], s: hs },
      total: { v: colHeaders[22], s: hs },
      process2: { v: colHeaders[23], s: hs },
      process2GotDate: { v: colHeaders[24], s: hs },
      process3: { v: colHeaders[25], s: hs },
      process3GotDate: { v: colHeaders[26], s: hs },
      process4: { v: colHeaders[27], s: h2 },
      process4GotDate: { v: colHeaders[28], s: hs },
      process5: { v: colHeaders[29], s: hs },
      process5GotDate: { v: colHeaders[30], s: hs },
      process6: { v: colHeaders[31], s: hs },
      process6GotDate: { v: colHeaders[32], s: hs },
      process7: { v: colHeaders[33], s: hs },
      process7GotDate: { v: colHeaders[34], s: hs },
      process8: { v: colHeaders[35], s: hs },
      process8GotDate: { v: colHeaders[36], s: hs },
      process9: { v: colHeaders[37], s: hs },
      process9GotDate: { v: colHeaders[38], s: hs },
      process10: { v: colHeaders[39], s: hs },
      process10GotDate: { v: colHeaders[40], s: hs },
      totalPaid: { v: colHeaders[41], s: hs },
      remain: { v: colHeaders[42], s: hs },
      bonus: { v: colHeaders[43], s: hs },
      totalForCommission: { v: colHeaders[44], s: hs },
      commissionPercent: { v: colHeaders[45], s: hs },
      commissionAmount: { v: colHeaders[46], s: hs },
      totalCommissionAmount: { v: colHeaders[47], s: hs },
      ownAgent: { v: colHeaders[48], s: hs },
      agent: { v: colHeaders[49], s: hs },
    },
  ];

  let r = dataHeaders.length;
  const data = sellitems.map((sl, index) => {
    r += 1;
    return {
      STT: { t: 'n', v: index + 1, s },
      block: { v: sl.block.name, s },
      nameProduct: { v: sl.product.name, s },
      statusLabel: { v: sl.statusLabel, s },
      nameCustomer: { v: sl.customer?.name || '', s },
      landArea: { t: 'n', v: sl.product.landArea, z: '#,##0.00', s },
      landPrice: { t: 'n', v: sl.product.landPrice, z: '#,##0', s },
      factor: { t: 'n', v: sl.product.factor, z: '#,##0.00', s },
      totalLandPrice: { t: 'n', f: `=F${r}*G${r}*H${r}`, z: '#,##0', s },
      floorArea: { t: 'n', v: sl.product.floorArea, z: '#,##0.00', s },
      floorPrice: { t: 'n', v: sl.product.floorPrice, z: '#,##0', s },
      totalFloorPrice: { t: 'n', f: `=J${r}*K${r}`, z: '#,##0', s },
      sellPrice: { t: 'n', f: `=I${r}+L${r}`, z: '#,##0', s },
      process1: { t: 'n', v: sl.payments[0]?.amount || 0, z: '#,##0', s },
      process1GotDate: { v: sl.payments[0]?.gotDate || '', s },
      discount1: { t: 'n', v: sl.discount1 || 0, z: '#,##0', s },
      discount2: { t: 'n', v: sl.discount2 || 0, z: '#,##0', s },
      discount3: { t: 'n', v: sl.discount3 || 0, z: '#,##0', s },
      loanPercent: { t: 'n', v: sl.loanPercent || '', z: '#,##0.00', s },
      loanAmount: { t: 'n', v: sl.loanAmount || '', z: '#,##0', s },
      discountMore: { f: `=S${r}*T${r}`, z: '#,##0', s },
      totalDiscount: { f: `=SUM(P${r}:R${r},U${r})`, z: '#,##0', s },
      total: { f: `=M${r}-V${r}`, z: '#,##0', s },
      process2: { t: 'n', v: sl.payments[1]?.amount || 0, z: '#,##0', s },
      process2GotDate: { v: sl.payments[1]?.gotDate || '', s },
      process3: { t: 'n', v: sl.payments[2]?.amount || 0, z: '#,##0', s },
      process3GotDate: { v: sl.payments[2]?.gotDate || '', s },
      process4: { t: 'n', v: sl.payments[3]?.amount || 0, z: '#,##0', s },
      process4GotDate: { v: sl.payments[3]?.gotDate || '', s },
      process5: { t: 'n', v: sl.payments[4]?.amount || 0, z: '#,##0', s },
      process5GotDate: { v: sl.payments[4]?.gotDate || '', s },
      process6: { t: 'n', v: sl.payments[5]?.amount || 0, z: '#,##0', s },
      process6GotDate: { v: sl.payments[5]?.gotDate || '', s },
      process7: { t: 'n', v: sl.payments[6]?.amount || 0, z: '#,##0', s },
      process7GotDate: { v: sl.payments[6]?.gotDate || '', s },
      process8: { t: 'n', v: sl.payments[7]?.amount || 0, z: '#,##0', s },
      process8GotDate: { v: sl.payments[7]?.gotDate || '', s },
      process9: { t: 'n', v: sl.payments[8]?.amount || 0, z: '#,##0', s },
      process9GotDate: { v: sl.payments[8]?.gotDate || '', s },
      process10: { t: 'n', v: sl.payments[9]?.amount || 0, z: '#,##0', s },
      process10GotDate: { v: sl.payments[9]?.gotDate || '', s },
      totalPaid: {
        f: `=N${r}+X${r}+Z${r}+AB${r}+AD${r}+AF${r}+AH${r}+AJ${r}+AL${r}+AN${r}`,
        z: '#,##0',
        s,
      },
      remain: { f: `=W${r}-AP${r}`, z: '#,##0', s },
      bonus: { t: 'n', v: sl.bonus || 0, z: '#,##0', s },
      totalForCommission: { t: 'n', v: sl.totalForCommission || 0, z: '#,##0', s },
      commissionPercent: { t: 'n', v: (sl.commissionPercent * 1) / 100 || 0, z: '0.00%', s },
      commissionAmount: { f: `=AS${r}*AT${r}`, z: '#,##0', s },
      totalCommissionAmount: { f: `=AR${r}+AU${r}`, z: '#,##0', s },
      ownAgent: { v: sl.agent?.name || '', s },
      agent: { v: sl.agentSelled?.name || '', s },
    };
  });

  const ws = XLSX.utils.json_to_sheet(dataHeaders.concat(data), { skipHeader: true });
  const wscols = [
    { wch: 4 }, // stt
    { wch: 7 }, // block
    { wch: 8 }, // nameProduct
    { wch: 16 }, // statusLabel
    { wch: 16 }, // nameCustomer
    { wch: 8 }, // landArea
    { wch: 16 }, // landPrice
    { wch: 8 }, // factor
    { wch: 16 }, // totalLandPrice
    { wch: 8 }, // floorArea
    { wch: 16 }, // floorPrice
    { wch: 16 }, // totalFloorPrice
    { wch: 16 }, // sellPrice
    { wch: 16 }, // process1
    { wch: 10 }, // process1GotDate
    { wch: 16 }, // discount1
    { wch: 16 }, // discount2
    { wch: 16 }, // discount3
    { wch: 10 }, // loanPercent
    { wch: 16 }, // loanAmount
    { wch: 16 }, // discountMore
    { wch: 16 }, // totalDiscount
    { wch: 16 }, // totalWithoutDiscount
    { wch: 16 }, // process2
    { wch: 10 }, // process2GotDate
    { wch: 16 }, // process3
    { wch: 10 }, // process3GotDate
    { wch: 16 }, // process4
    { wch: 10 }, // process4GotDate
    { wch: 16 }, // process5
    { wch: 10 }, // process5GotDate
    { wch: 16 }, // process6
    { wch: 10 }, // process6GotDate
    { wch: 16 }, // process7
    { wch: 10 }, // process7GotDate
    { wch: 16 }, // process8
    { wch: 10 }, // process8GotDate
    { wch: 16 }, // process9
    { wch: 10 }, // process9GotDate
    { wch: 16 }, // process10
    { wch: 10 }, // process10GotDate
    { wch: 16 }, // totalPaid
    { wch: 16 }, // remain
    { wch: 14 }, // bonus
    { wch: 16 }, // totalForCommission
    { wch: 12 }, // commissionPercent
    { wch: 16 }, // commissionAmount
    { wch: 16 }, // totalCommissionAmount
    { wch: 14 }, // ownAgent
    { wch: 14 }, // agent
  ];
  ws['!cols'] = wscols;
  ws['!rows'] = [{ hpx: 36 }, { hpx: 36 }];

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 49 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 49 } },
    { s: { r: 2, c: 1 }, e: { r: 2, c: 12 } },
    { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
    { s: { r: 2, c: 15 }, e: { r: 2, c: 21 } },
    { s: { r: 2, c: 23 }, e: { r: 2, c: 42 } },
    { s: { r: 2, c: 0 }, e: { r: 3, c: 0 } },
    { s: { r: 2, c: 22 }, e: { r: 3, c: 22 } },
    { s: { r: 2, c: 43 }, e: { r: 3, c: 43 } },
    { s: { r: 2, c: 44 }, e: { r: 3, c: 44 } },
    { s: { r: 2, c: 45 }, e: { r: 3, c: 45 } },
    { s: { r: 2, c: 46 }, e: { r: 3, c: 46 } },
    { s: { r: 2, c: 47 }, e: { r: 3, c: 47 } },
    { s: { r: 2, c: 48 }, e: { r: 3, c: 48 } },
    { s: { r: 2, c: 49 }, e: { r: 3, c: 49 } },
  ];
  ws['!merges'] = merge;

  const wb = { Sheets: { 'Sheet 1': ws }, SheetNames: ['Sheet 1'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    cellStyles: true,
    skipHeader: true,
    type: 'array',
  });
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const blob = new Blob([excelBuffer], { type: fileType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${project.name}.xlsx`;
  link.click();
};

export default exportXlsx;
