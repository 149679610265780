import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { lastProductIdByGroupIdSelector, pathSelector } from '../../../selectors';
import { searchProducts, fetchProducts } from '../../../actions';
import api from '../../../api';

export function* searchProductsService(groupId, query) {
  yield put(searchProducts(groupId, query));
  let products;
  let sellitems;
  let attachments;

  try {
    ({
      items: products,
      included: { sellitems, attachments },
    } = yield call(request, api.searchProducts, groupId, {
      query,
    }));
  } catch (error) {
    yield put(searchProducts.failure(groupId, error));
    return;
  }

  yield put(searchProducts.success(groupId, products, sellitems, attachments));
}

export function* searchProductsInCurrentGroupService(query) {
  const { groupId } = yield select(pathSelector);

  yield call(searchProductsService, groupId, query);
}

export function* fetchProductsService(groupId) {
  const lastId = yield select(lastProductIdByGroupIdSelector, groupId);
  yield put(fetchProducts(groupId));

  let products;
  let attachments;

  try {
    ({
      items: products,
      included: { attachments },
    } = yield call(request, api.getProducts, groupId, {
      beforeId: lastId,
    }));
  } catch (error) {
    yield put(fetchProducts.failure(groupId, error));
    return;
  }
  yield put(fetchProducts.success(groupId, products, attachments));
}

export function* fetchProductsInCurrentGroupService() {
  const { groupId } = yield select(pathSelector);

  yield call(fetchProductsService, groupId);
}
