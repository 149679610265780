import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { currentUserIdSelector } from './user';
import { isLocalId } from '../utils/local-id';

export const makeSellitemByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Sellitem }, id) => {
      const sellitemModel = Sellitem.withId(id);

      if (!sellitemModel) {
        return sellitemModel;
      }
      return {
        ...sellitemModel.ref,
        coverUrl: sellitemModel.coverAttachment && sellitemModel.coverAttachment.coverUrl,
        customer: sellitemModel.customer?.ref,
        product: sellitemModel.product?.ref,
        // bagitem: sellitemModel.bagitem?.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const sellitemByIdSelector = makeSellitemByIdSelector();

export const makeUsersBySellitemIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Sellitem }, id) => {
      const sellitemModel = Sellitem.withId(id);

      if (!sellitemModel) {
        return sellitemModel;
      }

      return sellitemModel.users.toRefArray();
    },
  );

export const usersBySellitemIdSelector = makeUsersBySellitemIdSelector();

export const makeLabelsBySellitemIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Sellitem }, id) => {
      const sellitemModel = Sellitem.withId(id);

      if (!sellitemModel) {
        return sellitemModel;
      }

      return sellitemModel.labels.toRefArray();
    },
  );

export const labelsBySellitemIdSelector = makeLabelsBySellitemIdSelector();

export const makePaymentsBySellitemIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Sellitem }, id) => {
      const sellitemModel = Sellitem.withId(id);

      if (!sellitemModel) {
        return sellitemModel;
      }

      return sellitemModel.payments.toRefArray();
    },
  );

export const paymentsBySellitemIdSelector = makePaymentsBySellitemIdSelector();

export const makeLastActionIdBySellitemIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Sellitem }, id) => {
      const sellitemModel = Sellitem.withId(id);

      if (!sellitemModel) {
        return sellitemModel;
      }

      const lastActionModel = sellitemModel.getOrderedInSellitemActionsQuerySet().last();

      return lastActionModel && lastActionModel.id;
    },
  );

export const lastActionIdBySellitemIdSelector = makeLastActionIdBySellitemIdSelector();

export const makeNotificationsBySellitemIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Sellitem }, id) => {
      const sellitemModel = Sellitem.withId(id);

      if (!sellitemModel) {
        return sellitemModel;
      }

      return sellitemModel.getUnreadNotificationsQuerySet().toRefArray();
    },
  );

export const notificationsBySellitemIdSelector = makeNotificationsBySellitemIdSelector();

export const makeNotificationsTotalBySellitemIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Sellitem }, id) => {
      const sellitemModel = Sellitem.withId(id);

      if (!sellitemModel) {
        return sellitemModel;
      }

      return sellitemModel.getUnreadNotificationsQuerySet().count();
    },
  );

export const notificationsTotalBySellitemIdSelector = makeNotificationsTotalBySellitemIdSelector();

export const currentSellitemSelector = createSelector(
  orm,
  (state) => pathSelector(state).sellitemId,
  ({ Sellitem }, id) => {
    if (!id) {
      return id;
    }

    const sellitemModel = Sellitem.withId(id);

    if (!sellitemModel) {
      return sellitemModel;
    }

    return sellitemModel.ref;
  },
);

export const usersForCurrentSellitemSelector = createSelector(
  orm,
  (state) => pathSelector(state).sellitemId,
  ({ Sellitem }, id) => {
    if (!id) {
      return id;
    }

    const sellitemModel = Sellitem.withId(id);

    if (!sellitemModel) {
      return sellitemModel;
    }

    return sellitemModel.users.toRefArray();
  },
);

export const labelsForCurrentSellitemSelector = createSelector(
  orm,
  (state) => pathSelector(state).sellitemId,
  ({ Sellitem }, id) => {
    if (!id) {
      return id;
    }

    const sellitemModel = Sellitem.withId(id);

    if (!sellitemModel) {
      return sellitemModel;
    }

    return sellitemModel.labels.toRefArray();
  },
);

export const customersForCurrentSellitemSelector = createSelector(
  orm,
  (state) => pathSelector(state).sellitemId,
  ({ Sellitem }, id) => {
    if (!id) {
      return id;
    }

    const sellitemModel = Sellitem.withId(id);

    if (!sellitemModel) {
      return sellitemModel;
    }
    return sellitemModel.customers.toRefArray();
  },
);

export const discountsForCurrentSellitemSelector = createSelector(
  orm,
  (state) => pathSelector(state).sellitemId,
  ({ Sellitem, SellitemDiscount }, id) => {
    if (!id) {
      return id;
    }

    const sellitemModel = Sellitem.withId(id);

    if (!sellitemModel) {
      return sellitemModel;
    }
    // console.log('SellitemDiscount', SellitemDiscount.filter({ sellitemId: id }).toRefArray());
    return SellitemDiscount.filter({ sellitemId: id }).toRefArray();
    // const discounts = sellitemModel.discounts.toRefArray().map((it) => ({..it, }));
    // return sellitemModel.discounts.toRefArray();
  },
);

export const makeCustomersBySellitemIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Sellitem }, id) => {
      const sellitemModel = Sellitem.withId(id);

      if (!sellitemModel) {
        return sellitemModel;
      }

      return sellitemModel.customers.toRefArray();
    },
  );

export const customersBySellitemIdSelector = makeCustomersBySellitemIdSelector();

export const paymentsForCurrentSellitemSelector = createSelector(
  orm,
  (state) => pathSelector(state).sellitemId,
  ({ Sellitem }, id) => {
    if (!id) {
      return id;
    }

    const sellitemModel = Sellitem.withId(id);

    if (!sellitemModel) {
      return sellitemModel;
    }

    return sellitemModel
      .getOrderedPaymentsQuerySet()
      .toRefArray()
      .map((payment) => ({
        ...payment,
        isPersisted: !isLocalId(payment.id),
      }));
  },
);

export const paymentsForSellitemIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ Sellitem }, id) => {
    if (!id) {
      return id;
    }

    const sellitemModel = Sellitem.withId(id);

    if (!sellitemModel) {
      return sellitemModel;
    }

    return sellitemModel
      .getOrderedPaymentsQuerySet()
      .toModelArray()
      .map((payment, index) => {
        const account = payment.account.ref;
        return {
          ...payment.ref,
          index: index + 1,
          accountId: account.id,
          accountName: account.name,
          accountNumber: account.accountNumber,
          accountDescription: account.description,
          accountType: account.type,
          isPersisted: !isLocalId(payment.id),
        };
      });
  },
);

export const attachmentsForCurrentSellitemSelector = createSelector(
  orm,
  (state) => pathSelector(state).sellitemId,
  ({ Sellitem }, id) => {
    if (!id) {
      return id;
    }

    const sellitemModel = Sellitem.withId(id);

    if (!sellitemModel) {
      return sellitemModel;
    }

    return sellitemModel
      .getOrderedAttachmentsQuerySet()
      .toRefArray()
      .map((attachment) => ({
        ...attachment,
        isCover: attachment.id === sellitemModel.coverAttachmentId,
        isPersisted: !isLocalId(attachment.id),
      }));
  },
);

export const actionsForCurrentSellitemSelector = createSelector(
  orm,
  (state) => pathSelector(state).sellitemId,
  (state) => currentUserIdSelector(state),
  ({ Sellitem }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const sellitemModel = Sellitem.withId(id);

    if (!sellitemModel) {
      return sellitemModel;
    }

    return sellitemModel
      .getOrderedInSellitemActionsQuerySet()
      .toModelArray()
      .map((actionModel) => ({
        ...actionModel.ref,
        isPersisted: !isLocalId(actionModel.id),
        user: {
          ...actionModel.user.ref,
          isCurrent: actionModel.user.id === currentUserId,
        },
      }));
  },
);

export const notificationIdsForCurrentSellitemSelector = createSelector(
  orm,
  (state) => pathSelector(state).sellitemId,
  ({ Sellitem }, id) => {
    if (!id) {
      return id;
    }

    const sellitemModel = Sellitem.withId(id);

    if (!sellitemModel) {
      return sellitemModel;
    }

    return sellitemModel
      .getUnreadNotificationsQuerySet()
      .toRefArray()
      .map((notification) => notification.id);
  },
);
