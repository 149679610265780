import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  // isCurrentUserMemberForCurrentGroupSelector,
  // isAdminSelector,
  // isSaleAdminSelector,
  customersForCurrentGroupSelector,
  pathSelector,
} from '../selectors';
import { createCustomer, searchCustomers } from '../actions/entry';
import LeftSidebar from '../components/Customers/LeftSidebar';

const mapStateToProps = (state) => {
  const { customerId } = pathSelector(state);
  // const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  // const isAdmin = isAdminSelector(state);
  // const isSaleAdmin = isSaleAdminSelector(state);
  const allCustomers = customersForCurrentGroupSelector(state);

  const { customers } = state;

  return {
    customerId,
    customers: allCustomers,
    // canEdit: isAdmin || (isCurrentUserMember && isSaleAdmin),
    canEdit: false,
    searchCustomerResults: customers || { isSubmitting: false, customers: [], query: '' },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCustomerCreate: createCustomer,
      onSearchCustomers: (query) => searchCustomers(query),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
