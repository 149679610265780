import EntryActionTypes from '../../constants/EntryActionTypes';

export const createDiscount = (data) => ({
  type: EntryActionTypes.DISCOUNT_CREATE,
  payload: {
    data,
  },
});

export const handleDiscountCreate = (discount) => ({
  type: EntryActionTypes.DISCOUNT_CREATE_HANDLE,
  payload: {
    discount,
  },
});

export const updateDiscount = (id, data) => ({
  type: EntryActionTypes.DISCOUNT_UPDATE,
  payload: {
    id,
    data,
  },
});

export const updateCurrentDiscount = (data) => ({
  type: EntryActionTypes.CURRENT_DISCOUNT_UPDATE,
  payload: {
    data,
  },
});

export const handleDiscountUpdate = (discount) => ({
  type: EntryActionTypes.DISCOUNT_UPDATE_HANDLE,
  payload: {
    discount,
  },
});

export const deleteDiscount = (id) => ({
  type: EntryActionTypes.DISCOUNT_DELETE,
  payload: {
    id,
  },
});

export const deleteCurrentDiscount = () => ({
  type: EntryActionTypes.CURRENT_DISCOUNT_DELETE,
  payload: {},
});

export const handleDiscountDelete = (discount) => ({
  type: EntryActionTypes.DISCOUNT_DELETE_HANDLE,
  payload: {
    discount,
  },
});
