/* eslint-disable no-param-reassign */
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { HotTable, HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';
import DatePickerEditor from '../HotTable/DatePickerEditor';
import SelectEditor from '../HotTable/SelectEditor';
import ButtonPrint from '../HotTable/ButtonPrint';
import ButtonContract from '../HotTable/ButtonContract';
import ConfirmForm from './ConfirmForm';
import Summary from '../ProductTable/Summary';

import { PaymentStatuses, PaymentMethods, Statuses } from '../../constants/Enums';
import globalStyles from '../../styles.module.scss';

import styles from './PaymentTable.module.scss';
import NumberEditor from '../HotTable/NumberEditor';

const undoQ = [];
const redoQ = [];

const PaymentTable = React.memo(
  ({
    sellitem,
    payments,
    accounts,
    customers,
    discounts,
    product,
    description,
    paymentDeposit,
    paymentConfirm1,
    paymentConfirm2,
    contract1,
    contract2,
    contract3,
    appendix1,
    appendix2,
    appendix3,
    appendix4,
    appendix5,
    canEdit,
    onUpdateSellitem,
    onUpdatePayment,
    onPaymentCreate,
    onDeletePayment,
  }) => {
    const [t] = useTranslation();
    const hotTableRef = useRef();
    const summaryRef = useRef();

    const colHeaders = useMemo(
      () => [
        t('common.paymentsSellitem.name'),
        t('common.paymentsSellitem.percent'),
        t('common.paymentsSellitem.amount'),
        t('common.paymentsSellitem.dueDate'),
        t('common.paymentsSellitem.accountId'),
        t('common.paymentsSellitem.gotDate'),
        t('common.paymentsSellitem.status'),
        t('common.paymentsSellitem.refNo'),
        t('common.paymentsSellitem.contract'),
        t('common.paymentsSellitem.note'),
      ],
      [t],
    );
    const readOnly =
      !canEdit ||
      sellitem.status === Statuses.FINISH.name ||
      sellitem.status === Statuses.CANCELED.name;

    const [paymentMethod, setpaymentMethod] = useState(sellitem.paymentMethod);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const handlePaymentCreate = useCallback(() => {
      if (payments.length < 10 && (payments.length > 0 || accounts.length > 0)) {
        const newData = {
          sellitemId: sellitem.id,
          accountId: payments.length > 0 ? payments[0].accountId : accounts[0].id,
          status: PaymentStatuses.NEW.name,
          name: `TTGD${payments.length}`,
          type: `process${payments.length + 1}`,
        };
        onPaymentCreate(newData);
      }
    }, [accounts, onPaymentCreate, payments, sellitem.id]);

    const handleInputChange = (e, { value }) => {
      if (canEdit && value !== sellitem.paymentMethod) {
        setpaymentMethod(value);
        if (sellitem.status === Statuses.BOOKING.name || sellitem.status === Statuses.REVIEW.name) {
          const newData = {
            paymentMethod: value,
          };
          onUpdateSellitem(sellitem.id, newData);
        } else {
          setOpenConfirmModal(true);
        }
      }
    };

    const afterChange = useCallback(
      (changes, source) => {
        const sourceChange = ['edit', 'CopyPaste.paste', 'Autofill.fill', 'undo', 'redo'];
        if (sourceChange.indexOf(source) > -1 && changes.length > 0) {
          changes.forEach(([row, prop, oldValue, newValue]) => {
            if ((oldValue === null || oldValue === undefined) && newValue === '') return;

            // eslint-disable-next-line eqeqeq
            if (oldValue != newValue) {
              let newData;
              let newDate = null;
              const { id } = payments[row];
              const totalPercent = payments
                .filter((pm) => pm.id !== id)
                .reduce((sum, { percent }) => sum + percent, 0);
              let amountDeposit = 0;
              if (payments[0]?.status === PaymentStatuses.FINISH.name) {
                amountDeposit = payments[0]?.amount || 0;
              }
              switch (prop) {
                case 'accountName':
                  newData = {
                    accountId: accounts.find(({ name }) => name === newValue)?.id || undefined,
                  };
                  break;
                case 'dueDate':
                case 'gotDate':
                  if (newValue) {
                    newDate = new Date(newValue);
                  }
                  newData = {
                    [prop]: newDate,
                  };
                  break;
                case 'percent': {
                  let newValueUpdate = newValue;
                  if (newValueUpdate > 100 - totalPercent * 1)
                    newValueUpdate = Math.max(0, 100 - totalPercent * 1);
                  newData = {
                    [prop]: newValueUpdate,
                    amount:
                      row === 1
                        ? (newValueUpdate / 100) * sellitem.total - amountDeposit
                        : (newValueUpdate / 100) * sellitem.total,
                  };
                  break;
                }
                default:
                  newData = {
                    [prop]: newValue || null,
                  };
                  break;
              }
              if (source === 'undo') {
                if (redoQ.length > 9) redoQ.shift();
                redoQ.push([id, prop, oldValue]);
              } else {
                if (source !== 'redo') redoQ.length = 0;
                if (undoQ.length > 9) undoQ.shift();
                undoQ.push([id, prop, oldValue]);
              }
              if (prop === 'status' && newValue === PaymentStatuses.FINISH.name) {
                redoQ.length = 0;
                undoQ.length = 0;
              }
              onUpdatePayment(id, newData);
            }
          });
        }
      },
      [accounts, onUpdatePayment, payments, sellitem.total],
    );

    const afterSelection = useCallback(() => {
      const selected = hotTableRef.current.hotInstance.getSelected();
      if (
        !(
          selected.length === 1 &&
          selected[0][0] === selected[0][2] &&
          selected[0][1] === selected[0][3]
        )
      ) {
        const data = [];
        selected.forEach((it) => {
          data.push(hotTableRef.current.hotInstance.getData(...it).flat());
        });
        if (summaryRef.current) summaryRef.current.show(data);
      } else if (summaryRef.current) {
        summaryRef.current.show([]);
      }
    }, []);

    const afterDeselect = () => {
      if (summaryRef.current) summaryRef.current.show([]);
    };

    const handleRedo = useCallback(() => {
      const hotInstance = redoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = payments.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'redo');
      }
    }, [payments]);

    const handleUndo = useCallback(() => {
      const hotInstance = undoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = payments.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'undo');
      }
    }, [payments]);

    const beforeKeyDown = useCallback(
      (e) => {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          // e.preventDefault();
          e.stopImmediatePropagation();
          return;
        }
        if (e.ctrlKey || e.metaKey) {
          if (e.keyCode === 90) {
            if (e.shiftKey) {
              handleRedo();
            } else {
              handleUndo();
            }
          }
        }
      },
      [handleRedo, handleUndo],
    );

    const beforeRemoveRow = (row) => {
      const { id, status } = payments[row];
      if (status !== PaymentStatuses.FINISH.name) {
        onDeletePayment(id);
      }
      return false;
    };

    const dateRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      const status = instance.getDataAtRowProp(row, 'status');
      cellProperties.readOnly = readOnly || status === PaymentStatuses.FINISH.name;
      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        value
          ? t('format:date', {
              postProcess: 'formatDate',
              value: new Date(value),
            })
          : '',
        cellProperties,
      ]);
      return td;
    };

    const statusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      const paymentStatus = instance.getDataAtRowProp(row, 'status');
      const paymentGotDate = instance.getDataAtRowProp(row, 'gotDate');
      let readOnly1 =
        !canEdit ||
        sellitem.status === Statuses.BOOKING.name ||
        sellitem.status === Statuses.REVIEW.name ||
        sellitem.status === Statuses.CANCELED.name ||
        paymentStatus === PaymentStatuses.FINISH.name ||
        !paymentGotDate;

      if (row > 0) {
        const prevStatus = instance.getDataAtRowProp(row - 1, 'status');
        readOnly1 = readOnly1 || prevStatus !== PaymentStatuses.FINISH.name;
      }

      cellProperties.readOnly = readOnly1;

      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
      if (value) {
        const status = PaymentStatuses[value.toUpperCase()] || { name: '-', color: 'red' };
        td.innerText = t(`statusPayment.${status.name}`);
        td.style.color = '#fff';
        td.classList.add(globalStyles[`background${upperFirst(camelCase(status.color))}`]);
      }
      return td;
    };

    const percentRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      const status = instance.getDataAtRowProp(row, 'status');
      cellProperties.readOnly = readOnly || row === 0 || status === PaymentStatuses.FINISH.name;
      Handsontable.renderers.NumericRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        `${value * 1}%`,
        cellProperties,
      ]);
      return td;
    };

    const commonRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      const status = instance.getDataAtRowProp(row, 'status');
      cellProperties.readOnly = readOnly || status === PaymentStatuses.FINISH.name;
      if (cellProperties.type === 'numeric') {
        Handsontable.renderers.NumericRenderer.apply(this, [
          instance,
          td,
          row,
          col,
          prop,
          value,
          cellProperties,
        ]);
      } else {
        Handsontable.renderers.TextRenderer.apply(this, [
          instance,
          td,
          row,
          col,
          prop,
          value,
          cellProperties,
        ]);
      }

      return td;
    };

    useEffect(() => {
      undoQ.length = 0;
      redoQ.length = 0;
      hotTableRef.current.hotInstance.refreshDimensions();
    }, []);

    return (
      <div id="hot-app-payment-table" className={styles.wrapper}>
        <div className={styles.paymentWrapper}>
          <Grid>
            <Grid.Column widescreen={4} largeScreen={4} computer={4} tablet={16} mobile={16}>
              <Header as="h4" textAlign="left" style={{ color: 'white' }}>
                <Icon name="bars" className={styles.moduleIcon} />
                {t('common.paymentsSellitem.paymentMethod')}
              </Header>
            </Grid.Column>
            <Grid.Column widescreen={4} largeScreen={4} computer={4} tablet={16} mobile={16}>
              <Checkbox
                radio
                name="paymentMethod"
                label={t('common.paymentsSellitem.paymentMethodProcess')}
                value={PaymentMethods.PROCESS}
                checked={sellitem.paymentMethod === PaymentMethods.PROCESS}
                onChange={handleInputChange}
                color="green"
                style={{ color: 'white' }}
                readOnly={readOnly}
              />
            </Grid.Column>
            <Grid.Column widescreen={4} largeScreen={4} computer={4} tablet={16} mobile={16}>
              <Checkbox
                radio
                name="paymentMethod"
                label={t('common.paymentsSellitem.paymentMethodQuick')}
                value={PaymentMethods.QUICK}
                checked={sellitem.paymentMethod === PaymentMethods.QUICK}
                onChange={handleInputChange}
                color="green"
                style={{ color: 'white' }}
                readOnly={readOnly}
              />
            </Grid.Column>
            <Grid.Column widescreen={4} largeScreen={4} computer={4} tablet={16} mobile={16}>
              <Checkbox
                radio
                name="paymentMethod"
                label={t('common.paymentsSellitem.paymentMethodBank')}
                value={PaymentMethods.BANK}
                checked={sellitem.paymentMethod === PaymentMethods.BANK}
                onChange={handleInputChange}
                color="green"
                style={{ color: 'white' }}
                readOnly={readOnly}
              />
            </Grid.Column>
          </Grid>
          {sellitem.status !== Statuses.BOOKING.name &&
            sellitem.status !== Statuses.REVIEW.name && (
              <ConfirmForm
                sellitem={sellitem}
                onUpdateSellitem={onUpdateSellitem}
                openModal={openConfirmModal}
                onClose={() => setOpenConfirmModal(false)}
                paymentMethod={paymentMethod}
                changePaymentMethod={setpaymentMethod}
              />
            )}
        </div>
        <Segment className={styles.tableWrapper}>
          <HotTable
            ref={hotTableRef}
            className="tablePayment"
            data={payments}
            colHeaders={colHeaders}
            // hiddenColumns={{ columns: [0] }}
            readOnly={readOnly}
            height="auto"
            stretchH="all"
            autoColumnSize={false}
            autoRowSize={false}
            manualColumnResize
            rowHeaders
            rowHeights={43}
            contextMenu={{
              items: {
                undo: {
                  name: `${t('action.undo')}<span style="color:gray;float:right;">⌘Z</span>`,
                  callback() {
                    handleUndo();
                  },
                  disabled: () => undoQ.length < 1,
                },
                redo: {
                  name: `${t('action.redo')}<span style="color:gray;float:right;">⌘⇧Z</span>`,
                  callback() {
                    handleRedo();
                  },
                  disabled: () => redoQ.length < 1,
                },
                separator: '---------',
                copy: {
                  name: `${t('action.copy')}<span style="color:gray;float:right;">⌘C</span>`,
                },
                remove_row: {
                  name: t('action.delete'),
                  disabled() {
                    return (
                      // eslint-disable-next-line react/no-this-in-sfc
                      payments[this.getSelectedLast()[0]]?.status === PaymentStatuses.FINISH.name ||
                      sellitem.status === Statuses.CANCELED.name
                    );
                  },
                },
              },
            }}
            enterMoves={{ col: 1, row: 0 }}
            // dropdownMenu={['filter_by_value', 'filter_action_bar']}
            // filters
            afterChange={afterChange}
            afterSelection={afterSelection}
            afterDeselect={afterDeselect}
            beforeKeyDown={beforeKeyDown}
            beforeRemoveRow={beforeRemoveRow}
            licenseKey="non-commercial-and-evaluation"
          >
            <HotColumn width={70} data="name" renderer={commonRenderer} />
            <HotColumn width={40} data="percent" renderer={percentRenderer} className="htRight" />
            <HotColumn
              width={100}
              data="amount"
              renderer={commonRenderer}
              type="numeric"
              numericFormat={{ pattern: '0,0' }}
              editor={NumberEditor}
            />
            <HotColumn
              width={70}
              data="dueDate"
              editor={DatePickerEditor}
              renderer={dateRenderer}
            />
            <HotColumn
              width={70}
              data="accountName"
              editor={SelectEditor}
              options={accounts.map(({ name }) => ({
                label: name,
                value: name,
                color: '#109dc0',
              }))}
            />
            <HotColumn
              width={70}
              data="gotDate"
              editor={DatePickerEditor}
              renderer={dateRenderer}
            />
            <HotColumn
              width={70}
              data="status"
              wordWrap={false}
              renderer={statusRenderer}
              editor={SelectEditor}
              options={Object.values(PaymentStatuses).map(({ name, hex }) => ({
                label: t(`statusPayment.${name}`),
                value: name,
                color: hex,
              }))}
            />
            <HotColumn
              width={45}
              data="refNo"
              settings={[{ dropdownMenu: false }]}
              readOnly
              disableVisualSelection
            >
              <ButtonPrint
                hot-renderer
                sellitem={sellitem}
                payments={payments}
                accounts={accounts}
                customers={customers}
                discounts={discounts}
                product={product}
                description={description}
                paymentDeposit={paymentDeposit}
                paymentConfirm1={paymentConfirm1}
                paymentConfirm2={paymentConfirm2}
                contract1={contract1}
                contract2={contract2}
                contract3={contract3}
                appendix1={appendix1}
                appendix2={appendix2}
                appendix3={appendix3}
                appendix4={appendix4}
                appendix5={appendix5}
              />
            </HotColumn>
            <HotColumn
              width={45}
              data="refNo"
              settings={[{ dropdownMenu: false }]}
              readOnly
              disableVisualSelection
            >
              <ButtonContract
                hot-renderer
                sellitem={sellitem}
                payments={payments}
                accounts={accounts}
                customers={customers}
                discounts={discounts}
                product={product}
                description={description}
                paymentDeposit={paymentDeposit}
                paymentConfirm1={paymentConfirm1}
                paymentConfirm2={paymentConfirm2}
                contract1={contract1}
                contract2={contract2}
                contract3={contract3}
                appendix1={appendix1}
                appendix2={appendix2}
                appendix3={appendix3}
                appendix4={appendix4}
                appendix5={appendix5}
              />
            </HotColumn>
            <HotColumn
              width={70}
              data="description"
              renderer={commonRenderer}
              readOnly={readOnly}
            />
          </HotTable>
          <div>
            <Summary ref={summaryRef} />
            <Button.Group className={styles.actionButtons}>
              {payments.length > 0 &&
                payments.length < 10 &&
                sellitem.status !== Statuses.CANCELED.name && (
                  <Button
                    className={styles.addProductButton}
                    size="mini"
                    icon="cube"
                    onClick={handlePaymentCreate}
                    disabled={!canEdit}
                  />
                )}
              <Button
                className={styles.buttonTableActionUndo}
                size="mini"
                content={t('action.undo')}
                icon="undo"
                labelPosition="left"
                onClick={handleUndo}
                disabled={undoQ.length < 1}
              />
              <Button
                className={styles.buttonTableActionRedo}
                size="mini"
                content={t('action.redo')}
                icon="redo"
                labelPosition="right"
                onClick={handleRedo}
                disabled={redoQ.length < 1}
              />
            </Button.Group>
          </div>
        </Segment>
      </div>
    );
  },
);

PaymentTable.propTypes = {
  sellitem: PropTypes.objectOf(PropTypes.shape).isRequired,
  payments: PropTypes.arrayOf(PropTypes.object()).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object()).isRequired,
  customers: PropTypes.arrayOf(PropTypes.object()).isRequired,
  discounts: PropTypes.arrayOf(PropTypes.object()).isRequired,
  description: PropTypes.string.isRequired,
  paymentDeposit: PropTypes.string.isRequired,
  paymentConfirm1: PropTypes.string.isRequired,
  paymentConfirm2: PropTypes.string.isRequired,
  contract1: PropTypes.string.isRequired,
  contract2: PropTypes.string.isRequired,
  contract3: PropTypes.string.isRequired,
  appendix1: PropTypes.string.isRequired,
  appendix2: PropTypes.string.isRequired,
  appendix3: PropTypes.string.isRequired,
  appendix4: PropTypes.string.isRequired,
  appendix5: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onUpdateSellitem: PropTypes.func.isRequired,
  onUpdatePayment: PropTypes.func.isRequired,
  onPaymentCreate: PropTypes.func.isRequired,
  product: PropTypes.objectOf(PropTypes.shape).isRequired,
  onDeletePayment: PropTypes.func.isRequired,
};

export default PaymentTable;
