import { Model, attr, fk, oneToOne } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';
// import Config from '../constants/Config';

export default class extends Model {
  static modelName = 'Product';

  static fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    landNumber: attr(),
    landDescription: attr(),
    mapNumber: attr(),
    certificate: attr(),
    street: attr(),
    buildingLine: attr(),
    direction: attr(),
    density: attr(),
    width: attr(),
    len: attr(),
    landArea: attr(),
    floorArea: attr(),
    landPrice: attr(),
    floorPrice: attr(),
    factor: attr(),
    status: attr(),
    legalStatus: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    isWithWeightAmount: attr({
      getDefault: () => false,
    }),
    isSubscribed: attr(),
    isSellitemsFetching: attr({
      getDefault: () => false,
    }),
    isAllSellitemsFetched: attr({
      getDefault: () => false,
    }),
    groupId: fk({
      to: 'Group',
      as: 'group',
      relatedName: 'products',
    }),
    blockId: fk({
      to: 'Block',
      as: 'block',
      relatedName: 'products',
    }),
    creatorUserId: fk({
      to: 'User',
      as: 'creator',
      relatedName: 'products',
    }),
    agentId: fk({
      to: 'Agent',
      as: 'agent',
      relatedName: 'products',
    }),
    categoryId: fk({
      to: 'Category',
      as: 'category',
      relatedName: 'products',
    }),
    coverAttachmentId: oneToOne({
      to: 'Attachment',
      as: 'coverAttachment',
      relatedName: 'coveredProduct',
    }),
  };

  static reducer({ type, payload }, Product) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.PRODUCTS_FETCH__SUCCESS:
        if (payload.products) {
          payload.products.forEach((product) => {
            Product.upsert(product);
          });
        }

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__PRODUCT_FETCH:
      case ActionTypes.PRODUCT_FETCH:
        if (Product.idExists(payload.id)) {
          Product.withId(payload.id).update({
            isFetching: true,
          });
        }

        break;
      case ActionTypes.PRODUCT_FETCH__SUCCESS:
        Product.upsert({
          ...payload.product,
          isFetching: false,
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Product.all().delete();

        if (payload.products) {
          payload.products.forEach((product) => {
            Product.upsert(product);
          });
        }

        break;
      case ActionTypes.SELLITEMS_FETCH__SUCCESS:
      case ActionTypes.CUSTOMER_FETCH__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.products.forEach((product) => {
          Product.upsert(product);
        });

        break;
      case ActionTypes.PRODUCT_CREATE:
      case ActionTypes.PRODUCT_CREATE_HANDLE:
      case ActionTypes.PRODUCT_UPDATE__SUCCESS:
      case ActionTypes.PRODUCT_UPDATE_HANDLE:
        Product.upsert(payload.product);

        break;
      case ActionTypes.PRODUCT_CREATE__SUCCESS:
        Product.withId(payload.localId).delete();
        Product.upsert(payload.product);

        break;
      case ActionTypes.PRODUCT_UPDATE:
        Product.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.PRODUCT_DELETE:
        Product.withId(payload.id).delete();

        break;
      case ActionTypes.PRODUCT_DELETE__SUCCESS:
      case ActionTypes.PRODUCT_DELETE_HANDLE: {
        const productModel = Product.withId(payload.product.id);

        if (productModel) {
          productModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.PRODUCTS_IMPORT_HANDLE:
      case ActionTypes.NOTIFICATION_CREATE_HANDLE:
        if (payload.products) {
          payload.products.forEach((product) => {
            Product.upsert(product);
          });
        }

        break;
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('id', false);
  }

  getOrderedAttachmentsQuerySet() {
    return this.attachments.orderBy('id', false);
  }

  getOrderedSellitemsQuerySet() {
    return this.sellitems.orderBy('id', false);
  }

  getOrderedProjectAgentsQuerySet() {
    return this.projectAgents.orderBy('id', false);
  }

  getOrderedFilteredSellitemsModelArray() {
    const sellitemModels = this.getOrderedSellitemsQuerySet().toModelArray();

    return sellitemModels;
  }

  deleteRelated() {
    this.sellitems.delete();
    this.bagitems.delete();
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
