import { all, takeEvery } from 'redux-saga/effects';

import {
  createDiscountInCurrentBagService,
  updateDiscountService,
  deleteDiscountService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* discountWatchers() {
  yield all([
    takeEvery(EntryActionTypes.DISCOUNT_CREATE, ({ payload: { data } }) =>
      createDiscountInCurrentBagService(data),
    ),
    takeEvery(EntryActionTypes.DISCOUNT_UPDATE, ({ payload: { id, data } }) =>
      updateDiscountService(id, data),
    ),
    takeEvery(EntryActionTypes.DISCOUNT_DELETE, ({ payload: { id, data } }) =>
      deleteDiscountService(id, data),
    ),
  ]);
}
