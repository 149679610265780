import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Icon, List } from 'semantic-ui-react';

import styles from './Discounts.module.scss';
import ActionsPopup from './ActionsPopup';
import { DiscountTypes } from '../../../constants/Enums';
import AddPopup from './AddPopup';

const Discounts = React.memo(({ items, canEdit, onCreate, onUpdate, onDelete }) => {
  const [t] = useTranslation();

  const handleUpdate = useCallback(
    (id, data) => {
      onUpdate(id, data);
    },
    [onUpdate],
  );

  const handleDelete = useCallback(
    (id) => {
      onDelete(id);
    },
    [onDelete],
  );

  return (
    <>
      <AddPopup
        onCreate={onCreate}
        onUpdate={onUpdate}
        defaultData={{ type: DiscountTypes.PERCENT }}
      >
        <Button
          basic
          icon="plus"
          size="mini"
          floated="right"
          content={t('action.add')}
          disabled={!canEdit}
          className={styles.addButton}
        />
      </AddPopup>
      <h4>{t('common.discount')}</h4>
      <List celled verticalAlign="middle" className={styles.addresses}>
        {items.map(({ id, name, description, type, amount, quantity, isClosed }) => (
          <List.Item key={id} className={classNames(canEdit && styles.contentHoverable)}>
            <List.Content className={isClosed ? styles.isClosed : undefined}>
              {name}&nbsp;
              <span className={styles.meta}>
                ({(amount * 1).toLocaleString('en-GB')}
                {type === DiscountTypes.PERCENT ? '%' : 'VNĐ'})
              </span>
              <br />
              <span className={styles.meta}>{description}</span>
            </List.Content>
            {canEdit && (
              <ActionsPopup
                isClosed={isClosed}
                defaultData={{ id, name, description, type, amount, quantity }}
                onCreate={onCreate}
                onUpdate={(data) => handleUpdate(id, data)}
                onDelete={() => handleDelete(id)}
              >
                <Button className={classNames(styles.button, styles.target)}>
                  <Icon fitted name="pencil" size="small" />
                </Button>
              </ActionsPopup>
            )}
          </List.Item>
        ))}
        {items.length === 0 && <List.Item content={t('common.noDiscounts')} />}
      </List>
    </>
  );
});

Discounts.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object()).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Discounts;
