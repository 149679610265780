import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  isAdminSelector,
  isSaleAdminSelector,
  productsForCurrentGroupSelector,
  agentsForCurrentProjectSelector,
  categoriesForCurrentProjectSelector,
} from '../selectors';
import {
  createPaymentInCurrentProduct,
  createProjectAgent,
  openProductAddModal,
  updateSellitem,
  updateProduct,
} from '../actions/entry';
import { getRightSidebar, setRightSidebar } from '../utils/right-sidebar-storage';

import Products from '../components/Products';

const mapStateToProps = (state) => {
  const products = productsForCurrentGroupSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);
  const isSaleAdmin = isSaleAdminSelector(state);

  const agents = agentsForCurrentProjectSelector(state);
  const categories = categoriesForCurrentProjectSelector(state);

  const toggleRightSidebar = getRightSidebar();

  return {
    products: products.map((p) => {
      const landTotal = Math.round(p.landPrice * p.landArea * p.factor, 0);
      const floorTotal = p.floorPrice * p.floorArea;
      return {
        ...p,
        landTotal,
        floorTotal,
        finalTotal: landTotal + floorTotal,
      };
    }),
    agents,
    categories,
    toggleRightSidebar,
    canEdit: isAdmin || (isCurrentUserMember && isSaleAdmin),
    onSetRightSidebar: (data) => setRightSidebar(data),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onPaymentCreate: (sellitemId, data) => createPaymentInCurrentProduct(sellitemId, data),
      onProductCreate: openProductAddModal,
      onProjectAgentCreate: (data) => createProjectAgent(data),
      onUpdateProduct: (id, data) => updateProduct(id, data),
      onUpdateSellitem: (id, data) => updateSellitem(id, data),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Products);
