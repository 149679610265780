import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Input, Search, Button, Popup as SemanticUIPopup } from 'semantic-ui-react';
import CustomerForm from '../CustomerForm';
import CustomerFullForm from '../CustomerFullForm';
import DeleteStep from '../../DeleteStep';

import styles from './SearchCustomers.module.scss';

const SearchCustomers = React.memo(
  ({
    isPrimary,
    isAdmin,
    disabled,
    currentValue,
    disableEdit,
    sellitem,
    // sellitemCustomers,
    searchResults,
    onSearchCustomers,
    onUpdateSellitem,
    onCreateCustomer,
    onRemove,
    onCreateSellitemCustomer,
    onDeleteSellitemCustomer,
    onupdateSellitemCustomer,
    onUpdateCustomer,
  }) => {
    const [t] = useTranslation();

    const [query, setQuery] = useState('');
    const [currentCustomer, setCurrentCustomer] = useState(currentValue);
    const [typing, setTyping] = useState({ isSearching: false });
    const searchField = useRef(null);
    const { isSubmitting, customers } = searchResults;

    const [openModalCustomer, setOpenModalCustomer] = useState(false);
    const [openModalCustomerFullForm, setOpenModalCustomerFullForm] = useState();

    const handleSearchChange = useCallback(
      (e, data) => {
        if (typing) {
          clearTimeout(typing.timeout);
        }
        if (data && data.value.length >= data.minCharacters) {
          setQuery(data.value);
        } else {
          setQuery('');
          setTyping({ isSearching: false });
        }
        setTyping({
          isSearching: true,
          timeout: setTimeout(() => {
            if (data && data.value.length >= data.minCharacters) {
              onSearchCustomers(data.value);
            }
          }, 300),
        });
      },
      [onSearchCustomers, typing],
    );

    const results = customers.map(({ id, name, phone }) => ({
      id,
      title: name,
      phone,
    }));
    results.push({
      id: -1,
      title: t('common.createNew'),
      phone: query,
    });

    const resultRenderer = ({ id, title, phone }) => {
      return (
        <div key={id} className={styles.result}>
          <span className={styles.title}>{title}</span>
          <span className={styles.phone}>{phone !== null ? ` - ${phone}` : ''}</span>
        </div>
      );
    };

    useEffect(() => {
      setQuery(currentValue.name);
    }, [currentValue.name]);

    const handleResultSelect = (event, { result }) => {
      if (result.id > 0) {
        const customer = customers.find((p) => p.id === result.id);
        setQuery(customer.name);
        setCurrentCustomer(customer);
        if (isPrimary) {
          onUpdateSellitem({ id: sellitem.id, customerId: customer.id, name: customer.name });
        } else if (currentValue?.id) {
          onupdateSellitemCustomer({
            sellitemId: sellitem.id,
            oldCustomerId: currentValue.id,
            customerId: customer.id,
          });
        } else {
          onCreateSellitemCustomer({ sellitemId: sellitem.id, customerId: customer.id });
        }
      } else {
        setOpenModalCustomer(true);
      }
    };

    const handleRemove = useCallback(() => {
      onRemove();
      if (currentCustomer?.id) onDeleteSellitemCustomer(sellitem.id, currentCustomer.id);
    }, [currentCustomer, onDeleteSellitemCustomer, onRemove, sellitem]);

    const handleOpenCustomerFullFormModal = useCallback(() => {
      setOpenModalCustomerFullForm(true);
    }, []);

    // const handleBlurSearchInput = useCallback(() => {
    //   if (query !== sellitem.name) {
    //     onUpdateSellitem({ id: sellitem.id, name: query });
    //   }
    // }, [onUpdateSellitem, query, sellitem]);

    useEffect(() => {
      setCurrentCustomer(currentValue);
    }, [currentValue]);

    return (
      <>
        <div className={styles.wrapper}>
          {openModalCustomer && (
            <CustomerForm
              isPrimary={isPrimary}
              defaultName={query.trim()}
              sellitemId={sellitem.id}
              onCreateCustomer={onCreateCustomer}
              onClose={() => setOpenModalCustomer(false)}
            />
          )}
          <Search
            fluid
            className={styles.searchWrapper}
            minCharacters={1}
            loading={isSubmitting}
            noResultsMessage={t('common.noResultsMessage')}
            disabled={disabled}
            input={
              <Input
                className={styles.inputSearch}
                fluid
                icon={false}
                ref={searchField}
                size="small"
                value={query}
                // onBlur={handleBlurSearchInput}
              />
            }
            onResultSelect={handleResultSelect}
            onSearchChange={handleSearchChange}
            resultRenderer={resultRenderer}
            results={typing.isSearching ? results : []}
            value={query}
          />
          {isAdmin && !!currentValue?.id && !disableEdit && (
            <>
              <CustomerFullForm
                customer={currentCustomer}
                openModal={openModalCustomerFullForm}
                onClose={() => setOpenModalCustomerFullForm(false)}
                onUpdateCustomer={onUpdateCustomer}
              />
              <Button
                type="button"
                className={styles.buttonEditCustomer}
                icon="edit"
                onClick={handleOpenCustomerFullFormModal}
              />
            </>
          )}
        </div>
        {!isPrimary && !disabled && (
          <SemanticUIPopup
            position="bottom center"
            trigger={<Button className={styles.buttonRemoveCustomer} type="button" icon="minus" />}
            content={
              <DeleteStep
                title={t('common.deleteCustomer', {
                  context: 'title',
                })}
                content={t('common.areYouSureYouWantToDeleteThisCustomer')}
                buttonContent={t('action.deleteCustomer')}
                onConfirm={handleRemove}
              />
            }
            on="click"
            hideOnScroll
          />
        )}
      </>
    );
  },
);

SearchCustomers.propTypes = {
  isPrimary: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  // sellitemCustomers: PropTypes.arrayOf(PropTypes.object()).isRequired,
  currentValue: PropTypes.objectOf(PropTypes.shape),
  disableEdit: PropTypes.bool,
  sellitem: PropTypes.objectOf(PropTypes.shape).isRequired,
  searchResults: PropTypes.objectOf(PropTypes.shape).isRequired,
  onSearchCustomers: PropTypes.func.isRequired,
  onUpdateSellitem: PropTypes.func,
  onCreateCustomer: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onRemove: PropTypes.func,
  onCreateSellitemCustomer: PropTypes.func,
  onDeleteSellitemCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func.isRequired,
  onupdateSellitemCustomer: PropTypes.func,
};

SearchCustomers.defaultProps = {
  disabled: false,
  disableEdit: false,
  isPrimary: false,
  currentValue: {},
  onUpdateSellitem: undefined,
  onRemove: undefined,
  onCreateSellitemCustomer: undefined,
  onDeleteSellitemCustomer: undefined,
  onupdateSellitemCustomer: undefined,
};

export default SearchCustomers;
