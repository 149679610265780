/* eslint-disable import/prefer-default-export */
import ActionTypes from '../constants/ActionTypes';

export const searchBags = (groupId, query) => ({
  type: ActionTypes.BAGS_FETCH,
  payload: {
    groupId,
    query,
  },
});

searchBags.success = (groupId, bags) => ({
  type: ActionTypes.BAGS_FETCH__SUCCESS,
  payload: {
    groupId,
    bags,
  },
});

searchBags.failure = (groupId, error) => ({
  type: ActionTypes.BAGS_FETCH__FAILURE,
  payload: {
    groupId,
    error,
  },
});

export const fetchBags = (groupId) => ({
  type: ActionTypes.BAGS_FETCH,
  payload: {
    groupId,
  },
});

fetchBags.success = (groupId, bags) => ({
  type: ActionTypes.BAGS_FETCH__SUCCESS,
  payload: {
    groupId,
    bags,
  },
});

fetchBags.failure = (groupId, error) => ({
  type: ActionTypes.BAGS_FETCH__FAILURE,
  payload: {
    groupId,
    error,
  },
});
