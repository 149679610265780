import { call, put, select } from 'redux-saga/effects';

import { goToBagService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createBag,
  deleteBag,
  fetchBag,
  handleBagCreate,
  handleBagDelete,
  handleBagUpdate,
  updateBag,
  recalculateCommission,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createBagService(groupId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createBag({
      ...nextData,
      groupId,
      id: localId,
    }),
  );

  let bag;
  try {
    ({ item: bag } = yield call(request, api.createBag, groupId, nextData));
  } catch (error) {
    yield put(createBag.failure(localId, error));
    return;
  }

  yield put(createBag.success(localId, bag));
  yield call(goToBagService, bag.id);
}

export function* createBagInCurrentGroupService(data) {
  const { groupId } = yield select(pathSelector);

  yield call(createBagService, groupId, data);
}

export function* handleBagCreateService(bag) {
  yield put(handleBagCreate(bag));
}

export function* fetchBagService(id) {
  yield put(fetchBag(id));

  let bag;
  let bagitems;
  let discounts;
  let sellitems;

  try {
    ({
      item: bag,
      included: { bagitems, discounts, sellitems },
    } = yield call(request, api.getBag, id));
  } catch (error) {
    yield put(fetchBag.failure(id, error));
    return;
  }
  yield put(fetchBag.success(bag, bagitems, discounts, sellitems));
}

export function* updateBagService(id, data) {
  yield put(updateBag(id, data));

  let bag;
  try {
    ({ item: bag } = yield call(request, api.updateBag, id, data));
  } catch (error) {
    yield put(updateBag.failure(id, error));
    return;
  }

  yield put(updateBag.success(bag));
}

export function* handleBagUpdateService(bag) {
  yield put(handleBagUpdate(bag));
}

export function* deleteBagService(id) {
  yield put(deleteBag(id));

  let bag;
  try {
    ({ item: bag } = yield call(request, api.deleteBag, id));
  } catch (error) {
    yield put(deleteBag.failure(id, error));
    return;
  }

  yield put(deleteBag.success(bag));
}

export function* handleBagDeleteService(label) {
  yield put(handleBagDelete(label));
}

export function* recalculateCommissionService(id) {
  yield put(recalculateCommission(id));

  let bag;
  let sellitems;

  try {
    ({
      item: bag,
      included: { sellitems },
    } = yield call(request, api.recalculateCommission, id));
  } catch (error) {
    yield put(recalculateCommission.failure(id, error));
    return;
  }
  yield put(recalculateCommission.success(bag, sellitems));
}
