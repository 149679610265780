/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, List, Segment } from 'semantic-ui-react';

import NameEdit from '../CustomerItem/NameEdit';
import DueDate from '../DueDate';
import DueDateEditPopup from '../DueDateEditPopup';
import AddPopup from './Addresses/AddPopup';
import styles from './RightSidebar.module.scss';
import { ProductStatuses } from '../../constants/Enums';

const RightSidebar = React.memo(({ canEdit, customer, onUpdate }) => {
  const [t] = useTranslation();

  const nameEdit = useRef(null);
  const phoneEdit = useRef(null);
  const emailEdit = useRef(null);
  const idNumberEdit = useRef(null);
  const provinceEdit = useRef(null);
  const addressEdit = useRef(null);

  const handleHeaderClick = useCallback(() => {
    if (canEdit) {
      nameEdit.current.open();
    }
  }, [canEdit]);

  const handleNameUpdate = useCallback(
    (value) => {
      onUpdate(customer.id, {
        name: value,
      });
    },
    [customer.id, onUpdate],
  );

  const handlePhoneHeaderClick = useCallback(() => {
    if (canEdit) {
      phoneEdit.current.open();
    }
  }, [canEdit]);

  const handleEmailHeaderClick = useCallback(() => {
    if (canEdit) {
      emailEdit.current.open();
    }
  }, [canEdit]);

  const handleUpdate = useCallback(
    (field, value) => {
      onUpdate(customer.id, {
        [field]: value,
      });
    },
    [customer.id, onUpdate],
  );

  const handleIdNumberHeaderClick = useCallback(() => {
    if (canEdit) {
      idNumberEdit.current.open();
    }
  }, [canEdit]);

  const handleAddressHeaderClick = useCallback(() => {
    if (canEdit) {
      addressEdit.current.open();
    }
  }, [canEdit]);

  const handleAddressUpdate = useCallback(
    (value) => {
      onUpdate(customer.id, {
        address: value,
      });
    },
    [customer.id, onUpdate],
  );

  return (
    <Segment className={styles.wrapper}>
      <List divided verticalAlign="middle" className={styles.details}>
        <List.Item>
          <List.Content
            as="h4"
            style={{ minHeight: '28px', marginTop: '0px' }}
            onClick={handleHeaderClick}
          >
            <NameEdit ref={nameEdit} defaultValue={customer.name} onUpdate={handleNameUpdate}>
              <div className={styles.headerName}>{customer.name}</div>
            </NameEdit>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            <AddPopup onUpdate={onUpdate} defaultData={customer}>
              <Button
                basic
                size="mini"
                content={customer?.address || '...'}
                className={styles.editAddressButton}
                disabled={!canEdit || customer.product?.status === ProductStatuses.SELLED.name}
              />
            </AddPopup>
          </List.Content>
          <List.Content className={styles.listLabel}>
            {t('common.customerGroup.address')}
          </List.Content>
        </List.Item>
        <List.Item onClick={handlePhoneHeaderClick}>
          <List.Content floated="right" style={{ padding: '0' }}>
            <NameEdit
              ref={phoneEdit}
              defaultValue={customer.phone || ''}
              onUpdate={(data) => handleUpdate('phone', data)}
              canEdit={canEdit}
            >
              <div className={styles.headerName}>{customer.phone}</div>
            </NameEdit>
          </List.Content>
          <List.Content>
            {t('common.customerGroup.phone')} {canEdit && <Icon name="pencil" size="tiny" />}
          </List.Content>
        </List.Item>
        <List.Item onClick={handleEmailHeaderClick}>
          <List.Content floated="right" style={{ padding: '0' }}>
            <NameEdit
              ref={emailEdit}
              defaultValue={customer.email || ''}
              onUpdate={(data) => handleUpdate('email', data)}
            >
              <div className={styles.headerName}>{customer.email}</div>
            </NameEdit>
          </List.Content>
          <List.Content>
            {t('common.customerGroup.email')} {canEdit && <Icon name="pencil" size="tiny" />}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content floated="right" style={{ padding: '0' }}>
            {canEdit ? (
              <DueDateEditPopup
                title={t('common.customerGroup.birthDay')}
                defaultValue={new Date(customer.birthday) || new Date()}
                onUpdate={(data) => handleUpdate('birthday', data.toISOString())}
              >
                {customer.birthday ? (
                  <DueDate value={new Date(customer.birthday)} size="tiny" />
                ) : (
                  <span>---</span>
                )}
              </DueDateEditPopup>
            ) : (
              <span>
                {customer.birthday ? (
                  <DueDate value={new Date(customer.birthday)} size="tiny" />
                ) : (
                  <span>---</span>
                )}
              </span>
            )}
          </List.Content>
          <List.Content>
            {t('common.customerGroup.birthDay')} {canEdit && <Icon name="pencil" size="tiny" />}
          </List.Content>
        </List.Item>

        <List.Item onClick={handleIdNumberHeaderClick}>
          <List.Content floated="right" style={{ padding: '0' }}>
            <NameEdit
              ref={idNumberEdit}
              defaultValue={customer.idNumber || ''}
              onUpdate={(data) => handleUpdate('idNumber', data)}
            >
              <div className={styles.headerName}>{customer.idNumber}</div>
            </NameEdit>
          </List.Content>
          <List.Content>
            {t('common.customerGroup.idNumber')} {canEdit && <Icon name="pencil" size="tiny" />}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {customer.totalProduct.toLocaleString('en-GB')}
          </List.Content>
          <List.Content>{t('common.customerGroup.totalProductBought')}</List.Content>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {customer.totalProductAmount.toLocaleString('en-GB')}
          </List.Content>
          <List.Content>{t('common.customerGroup.totalProductAmount')}</List.Content>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {customer.totalDiscount.toLocaleString('en-GB')}
          </List.Content>
          <List.Content>{t('common.accountants.totalDiscount')}</List.Content>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {customer.totalAmountPaid.toLocaleString('en-GB')}
          </List.Content>
          <List.Content>{t('common.customerGroup.totalAmountPaid')}</List.Content>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {customer.totalAmountNotPaid.toLocaleString('en-GB')}
          </List.Content>
          <List.Content>{t('common.customerGroup.totalAmountNotPaid')}</List.Content>
        </List.Item>
      </List>
      <hr style={{ margin: '0 -14px' }} />
    </Segment>
  );
});

RightSidebar.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
};

export default RightSidebar;
