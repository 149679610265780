import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  addProductStatusToFilterInCurrentGroup,
  removeAllProductStatusFromFilterInCurrentGroup,
} from '../actions/entry';
import { currentUserSelector } from '../selectors';
import ProductWidget from '../components/ProductWidget';

const mapStateToProps = (state) => {
  const { isAdmin } = currentUserSelector(state);

  return {
    canAdd: isAdmin,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onStatusToFilterAdd: addProductStatusToFilterInCurrentGroup,
      onStatusFromFilterRemoveAll: removeAllProductStatusFromFilterInCurrentGroup,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductWidget);
