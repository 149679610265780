const PREFIX = '@entry';

export default {
  PREFIX,

  /* Socket */

  SOCKET_DISCONNECT_HANDLE: `${PREFIX}/SOCKET_DISCONNECT_HANDLE`,
  SOCKET_RECONNECT_HANDLE: `${PREFIX}/SOCKET_RECONNECT_HANDLE`,

  /* Login */

  AUTHENTICATE: `${PREFIX}/AUTHENTICATE`,
  AUTHENTICATE_ERROR_CLEAR: `${PREFIX}/AUTHENTICATE_ERROR_CLEAR`,
  LOGOUT: `${PREFIX}/LOGOUT`,

  /* Core */

  CORE_INITIALIZE: `${PREFIX}/CORE_INITIALIZE`,

  /* Modal */

  MODAL_OPEN: `${PREFIX}/MODAL_OPEN`,
  MODAL_CLOSE: `${PREFIX}/MODAL_CLOSE`,

  /* User */

  USER_CREATE: `${PREFIX}/USER_CREATE`,
  USER_CREATE_HANDLE: `${PREFIX}/USER_CREATE_HANDLE`,
  USER_CREATE_ERROR_CLEAR: `${PREFIX}/USER_CREATE_ERROR_CLEAR`,
  USER_UPDATE: `${PREFIX}/USER_UPDATE`,
  CURRENT_USER_UPDATE: `${PREFIX}/CURRENT_USER_UPDATE`,
  USER_UPDATE_HANDLE: `${PREFIX}/USER_UPDATE_HANDLE`,
  CURRENT_USER_EMAIL_UPDATE: `${PREFIX}/CURRENT_USER_EMAIL_UPDATE`,
  CURRENT_USER_EMAIL_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_EMAIL_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_PASSWORD_UPDATE: `${PREFIX}/CURRENT_USER_PASSWORD_UPDATE`,
  CURRENT_USER_PASSWORD_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_PASSWORD_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_USERNAME_UPDATE: `${PREFIX}/CURRENT_USER_USERNAME_UPDATE`,
  CURRENT_USER_USERNAME_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_USERNAME_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_AVATAR_UPDATE: `${PREFIX}/CURRENT_USER_AVATAR_UPDATE`,
  USER_DELETE: `${PREFIX}/USER_DELETE`,
  USER_DELETE_HANDLE: `${PREFIX}/USER_DELETE_HANDLE`,
  USER_TO_SELLITEM_ADD: `${PREFIX}/USER_TO_SELLITEM_ADD`,
  USER_TO_CURRENT_SELLITEM_ADD: `${PREFIX}/USER_TO_CURRENT_SELLITEM_ADD`,
  USER_TO_SELLITEM_ADD_HANDLE: `${PREFIX}/USER_TO_SELLITEM_ADD_HANDLE`,
  USER_FROM_SELLITEM_REMOVE: `${PREFIX}/USER_FROM_SELLITEM_REMOVE`,
  USER_FROM_CURRENT_SELLITEM_REMOVE: `${PREFIX}/USER_FROM_CURRENT_SELLITEM_REMOVE`,
  USER_FROM_SELLITEM_REMOVE_HANDLE: `${PREFIX}/USER_FROM_SELLITEM_REMOVE_HANDLE`,
  USER_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/USER_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  USER_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/USER_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  /* Project */

  PROJECT_CREATE: `${PREFIX}/PROJECT_CREATE`,
  PROJECT_CREATE_HANDLE: `${PREFIX}/PROJECT_CREATE_HANDLE`,
  CURRENT_PROJECT_UPDATE: `${PREFIX}/CURRENT_PROJECT_UPDATE`,
  PROJECT_UPDATE_HANDLE: `${PREFIX}/PROJECT_UPDATE_HANDLE`,
  CURRENT_PROJECT_BACKGROUND_IMAGE_UPDATE: `${PREFIX}/CURRENT_PROJECT_BACKGROUND_IMAGE_UPDATE`,
  CURRENT_PROJECT_DELETE: `${PREFIX}/CURRENT_PROJECT_DELETE`,
  PROJECT_DELETE_HANDLE: `${PREFIX}/PROJECT_DELETE_HANDLE`,

  /* Project manager */

  MANAGER_IN_CURRENT_PROJECT_CREATE: `${PREFIX}/MANAGER_IN_CURRENT_PROJECT_CREATE`,
  PROJECT_MANAGER_CREATE_HANDLE: `${PREFIX}/PROJECT_MANAGER_CREATE_HANDLE`,
  PROJECT_MANAGER_DELETE: `${PREFIX}/PROJECT_MANAGER_DELETE`,
  PROJECT_MANAGER_DELETE_HANDLE: `${PREFIX}/PROJECT_MANAGER_DELETE_HANDLE`,

  /* Agent */

  AGENT_CREATE: `${PREFIX}/AGENT_CREATE`,
  AGENT_IN_CURRENT_PROJECT_CREATE: `${PREFIX}/AGENT_IN_CURRENT_PROJECT_CREATE`,
  AGENT_CREATE_HANDLE: `${PREFIX}/AGENT_CREATE_HANDLE`,
  AGENT_UPDATE: `${PREFIX}/AGENT_UPDATE`,
  AGENT_UPDATE_HANDLE: `${PREFIX}/AGENT_UPDATE_HANDLE`,
  AGENT_DELETE: `${PREFIX}/AGENT_DELETE`,
  AGENT_DELETE_HANDLE: `${PREFIX}/AGENT_DELETE_HANDLE`,
  AGENT_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/AGENT_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  AGENT_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/AGENT_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  /* Category */
  CATEGORY_CREATE: `${PREFIX}/CATEGORY_CREATE`,
  CATEGORY_IN_CURRENT_PROJECT_CREATE: `${PREFIX}/CATEGORY_IN_CURRENT_PROJECT_CREATE`,
  CATEGORY_CREATE_HANDLE: `${PREFIX}/CATEGORY_CREATE_HANDLE`,
  CATEGORY_UPDATE: `${PREFIX}/CATEGORY_UPDATE`,
  CATEGORY_UPDATE_HANDLE: `${PREFIX}/CATEGORY_UPDATE_HANDLE`,
  CATEGORY_DELETE: `${PREFIX}/CATEGORY_DELETE`,
  CATEGORY_DELETE_HANDLE: `${PREFIX}/CATEGORY_DELETE_HANDLE`,
  CATEGORY_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/CATEGORY_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  CATEGORY_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/CATEGORY_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  /* Account */
  ACCOUNT_CREATE: `${PREFIX}/ACCOUNT_CREATE`,
  ACCOUNT_IN_CURRENT_PROJECT_CREATE: `${PREFIX}/ACCOUNT_IN_CURRENT_PROJECT_CREATE`,
  ACCOUNT_CREATE_HANDLE: `${PREFIX}/ACCOUNT_CREATE_HANDLE`,
  ACCOUNT_UPDATE: `${PREFIX}/ACCOUNT_UPDATE`,
  ACCOUNT_UPDATE_HANDLE: `${PREFIX}/ACCOUNT_UPDATE_HANDLE`,
  ACCOUNT_DELETE: `${PREFIX}/ACCOUNT_DELETE`,
  ACCOUNT_DELETE_HANDLE: `${PREFIX}/ACCOUNT_DELETE_HANDLE`,

  /* Block */

  BLOCK_IN_CURRENT_GROUP_CREATE: `${PREFIX}/BLOCK_IN_CURRENT_GROUP_CREATE`,
  BLOCK_CREATE_HANDLE: `${PREFIX}/BLOCK_CREATE_HANDLE`,
  BLOCK_UPDATE: `${PREFIX}/BLOCK_UPDATE`,
  BLOCK_UPDATE_HANDLE: `${PREFIX}/BLOCK_UPDATE_HANDLE`,
  BLOCK_MOVE: `${PREFIX}/BLOCK_MOVE`,
  BLOCK_DELETE: `${PREFIX}/BLOCK_DELETE`,
  BLOCK_DELETE_HANDLE: `${PREFIX}/BLOCK_DELETE_HANDLE`,

  /* Group */

  GROUP_IN_CURRENT_PROJECT_CREATE: `${PREFIX}/GROUP_IN_CURRENT_PROJECT_CREATE`,
  GROUP_CREATE_HANDLE: `${PREFIX}/GROUP_CREATE_HANDLE`,
  GROUP_FETCH: `${PREFIX}/GROUP_FETCH`,
  GROUP_UPDATE: `${PREFIX}/GROUP_UPDATE`,
  GROUP_UPDATE_HANDLE: `${PREFIX}/GROUP_UPDATE_HANDLE`,
  GROUP_MOVE: `${PREFIX}/GROUP_MOVE`,
  GROUP_DELETE: `${PREFIX}/GROUP_DELETE`,
  GROUP_DELETE_HANDLE: `${PREFIX}/GROUP_DELETE_HANDLE`,

  /* Group membership */

  MEMBERSHIP_IN_CURRENT_GROUP_CREATE: `${PREFIX}/MEMBERSHIP_IN_CURRENT_GROUP_CREATE`,
  GROUP_MEMBERSHIP_CREATE_HANDLE: `${PREFIX}/GROUP_MEMBERSHIP_CREATE_HANDLE`,
  GROUP_MEMBERSHIP_DELETE: `${PREFIX}/GROUP_MEMBERSHIP_DELETE`,
  GROUP_MEMBERSHIP_DELETE_HANDLE: `${PREFIX}/GROUP_MEMBERSHIP_DELETE_HANDLE`,

  /* Label */

  LABEL_IN_CURRENT_GROUP_CREATE: `${PREFIX}/LABEL_IN_CURRENT_GROUP_CREATE`,
  LABEL_CREATE_HANDLE: `${PREFIX}/LABEL_CREATE_HANDLE`,
  LABEL_UPDATE: `${PREFIX}/LABEL_UPDATE`,
  LABEL_UPDATE_HANDLE: `${PREFIX}/LABEL_UPDATE_HANDLE`,
  LABEL_DELETE: `${PREFIX}/LABEL_DELETE`,
  LABEL_DELETE_HANDLE: `${PREFIX}/LABEL_DELETE_HANDLE`,
  LABEL_TO_SELLITEM_ADD: `${PREFIX}/LABEL_TO_SELLITEM_ADD`,
  LABEL_TO_CURRENT_SELLITEM_ADD: `${PREFIX}/LABEL_TO_CURRENT_SELLITEM_ADD`,
  LABEL_TO_SELLITEM_ADD_HANDLE: `${PREFIX}/LABEL_TO_SELLITEM_ADD_HANDLE`,
  LABEL_FROM_SELLITEM_REMOVE: `${PREFIX}/LABEL_FROM_SELLITEM_REMOVE`,
  LABEL_FROM_CURRENT_SELLITEM_REMOVE: `${PREFIX}/LABEL_FROM_CURRENT_SELLITEM_REMOVE`,
  LABEL_FROM_SELLITEM_REMOVE_HANDLE: `${PREFIX}/LABEL_FROM_SELLITEM_REMOVE_HANDLE`,
  LABEL_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/LABEL_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  LABEL_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/LABEL_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  /* Customer */

  CUSTOMER_CREATE: `${PREFIX}/CUSTOMER_CREATE`,
  CUSTOMER_CREATE_HANDLE: `${PREFIX}/CUSTOMER_CREATE_HANDLE`,
  CUSTOMER_FETCH: `${PREFIX}/CUSTOMER_FETCH`,
  CUSTOMER_UPDATE: `${PREFIX}/CUSTOMER_UPDATE`,
  CUSTOMER_UPDATE_HANDLE: `${PREFIX}/CUSTOMER_UPDATE_HANDLE`,
  CUSTOMER_MOVE: `${PREFIX}/CUSTOMER_MOVE`,
  CUSTOMER_DELETE: `${PREFIX}/CUSTOMER_DELETE`,
  CUSTOMER_DELETE_HANDLE: `${PREFIX}/CUSTOMER_DELETE_HANDLE`,
  CUSTOMERS_SEARCH: `${PREFIX}/CUSTOMERS_SEARCH`,
  CUSTOMERS_FETCH: `${PREFIX}/CUSTOMERS_FETCH`,

  CUSTOMER_TO_SELLITEM_ADD: `${PREFIX}/CUSTOMER_TO_SELLITEM_ADD`,
  CUSTOMER_TO_CURRENT_SELLITEM_ADD: `${PREFIX}/CUSTOMER_TO_CURRENT_SELLITEM_ADD`,
  CUSTOMER_TO_SELLITEM_ADD_HANDLE: `${PREFIX}/CUSTOMER_TO_SELLITEM_ADD_HANDLE`,
  CUSTOMER_FROM_SELLITEM_REMOVE: `${PREFIX}/CUSTOMER_FROM_SELLITEM_REMOVE`,
  CUSTOMER_FROM_CURRENT_SELLITEM_REMOVE: `${PREFIX}/CUSTOMER_FROM_CURRENT_SELLITEM_REMOVE`,
  CUSTOMER_FROM_SELLITEM_REMOVE_HANDLE: `${PREFIX}/CUSTOMER_FROM_SELLITEM_REMOVE_HANDLE`,
  CUSTOMER_TO_SELLITEM_UPDATE: `${PREFIX}/CUSTOMER_TO_SELLITEM_UPDATE`,

  /* Import */

  GROUP_IMPORT: `${PREFIX}/GROUP_IMPORT`,
  GROUP_IMPORT_ERROR_CLEAR: `${PREFIX}/GROUP_IMPORT_ERROR_CLEAR`,

  /* Product */

  PRODUCT_CREATE: `${PREFIX}/PRODUCT_CREATE`,
  PRODUCT_CREATE_HANDLE: `${PREFIX}/PRODUCT_CREATE_HANDLE`,
  PRODUCT_UPDATE: `${PREFIX}/PRODUCT_UPDATE`,
  CURRENT_PRODUCT_UPDATE: `${PREFIX}/CURRENT_PRODUCT_UPDATE`,
  PRODUCT_UPDATE_HANDLE: `${PREFIX}/PRODUCT_UPDATE_HANDLE`,
  PRODUCT_MOVE: `${PREFIX}/PRODUCT_MOVE`,
  CURRENT_PRODUCT_MOVE: `${PREFIX}/CURRENT_PRODUCT_MOVE`,
  PRODUCT_DELETE: `${PREFIX}/PRODUCT_DELETE`,
  CURRENT_PRODUCT_DELETE: `${PREFIX}/CURRENT_PRODUCT_DELETE`,
  PRODUCT_DELETE_HANDLE: `${PREFIX}/PRODUCT_DELETE_HANDLE`,
  PRODUCTS_SEARCH: `${PREFIX}/PRODUCTS_SEARCH`,
  PRODUCTS_FETCH: `${PREFIX}/PRODUCTS_FETCH`,
  PRODUCTS_IMPORT_HANDLE: `${PREFIX}/PRODUCTS_IMPORT_HANDLE`,

  /* Bag */

  BAG_CREATE: `${PREFIX}/BAG_CREATE`,
  BAG_CREATE_HANDLE: `${PREFIX}/BAG_CREATE_HANDLE`,
  BAG_UPDATE: `${PREFIX}/BAG_UPDATE`,
  CURRENT_BAG_UPDATE: `${PREFIX}/CURRENT_BAG_UPDATE`,
  BAG_UPDATE_HANDLE: `${PREFIX}/BAG_UPDATE_HANDLE`,
  BAG_DELETE: `${PREFIX}/BAG_DELETE`,
  CURRENT_BAG_DELETE: `${PREFIX}/CURRENT_BAG_DELETE`,
  BAG_DELETE_HANDLE: `${PREFIX}/BAG_DELETE_HANDLE`,
  BAGS_SEARCH: `${PREFIX}/BAGS_SEARCH`,
  BAGS_FETCH: `${PREFIX}/BAGS_FETCH`,

  BAGITEM_CREATE: `${PREFIX}/BAGITEM_CREATE`,
  BAGITEM_CREATE_HANDLE: `${PREFIX}/BAGITEM_CREATE_HANDLE`,
  BAGITEM_UPDATE: `${PREFIX}/BAGITEM_UPDATE`,
  BAGITEM_UPDATE_HANDLE: `${PREFIX}/BAGITEM_UPDATE_HANDLE`,
  BAGITEM_DELETE: `${PREFIX}/BAGITEM_DELETE`,
  BAGITEM_DELETE_HANDLE: `${PREFIX}/BAGITEM_DELETE_HANDLE`,

  BAG_RECALCULATE_COMMISSION: `${PREFIX}/BAG_RECALCULATE_COMMISSION`,

  /* Discount */

  DISCOUNT_CREATE: `${PREFIX}/DISCOUNT_CREATE`,
  DISCOUNT_CREATE_HANDLE: `${PREFIX}/DISCOUNT_CREATE_HANDLE`,
  DISCOUNT_UPDATE: `${PREFIX}/DISCOUNT_UPDATE`,
  DISCOUNT_UPDATE_HANDLE: `${PREFIX}/DISCOUNT_UPDATE_HANDLE`,
  DISCOUNT_DELETE: `${PREFIX}/DISCOUNT_DELETE`,
  DISCOUNT_DELETE_HANDLE: `${PREFIX}/DISCOUNT_DELETE_HANDLE`,

  DISCOUNT_TO_SELLITEM_ADD: `${PREFIX}/DISCOUNT_TO_SELLITEM_ADD`,
  DISCOUNT_TO_CURRENT_SELLITEM_ADD: `${PREFIX}/DISCOUNT_TO_CURRENT_SELLITEM_ADD`,
  DISCOUNT_TO_SELLITEM_ADD_HANDLE: `${PREFIX}/DISCOUNT_TO_SELLITEM_ADD_HANDLE`,
  DISCOUNT_FROM_SELLITEM_REMOVE: `${PREFIX}/DISCOUNT_FROM_SELLITEM_REMOVE`,
  DISCOUNT_FROM_CURRENT_SELLITEM_REMOVE: `${PREFIX}/DISCOUNT_FROM_CURRENT_SELLITEM_REMOVE`,
  DISCOUNT_FROM_SELLITEM_REMOVE_HANDLE: `${PREFIX}/DISCOUNT_FROM_SELLITEM_REMOVE_HANDLE`,

  /* Sellitem */

  SELLITEM_CREATE: `${PREFIX}/SELLITEM_CREATE`,
  SELLITEM_CREATE_HANDLE: `${PREFIX}/SELLITEM_CREATE_HANDLE`,
  SELLITEM_UPDATE: `${PREFIX}/SELLITEM_UPDATE`,
  CURRENT_SELLITEM_UPDATE: `${PREFIX}/CURRENT_SELLITEM_UPDATE`,
  SELLITEM_UPDATE_HANDLE: `${PREFIX}/SELLITEM_UPDATE_HANDLE`,
  SELLITEM_MOVE: `${PREFIX}/SELLITEM_MOVE`,
  CURRENT_SELLITEM_MOVE: `${PREFIX}/CURRENT_SELLITEM_MOVE`,
  SELLITEM_TRANSFER: `${PREFIX}/SELLITEM_TRANSFER`,
  CURRENT_SELLITEM_TRANSFER: `${PREFIX}/CURRENT_SELLITEM_TRANSFER`,
  SELLITEM_DELETE: `${PREFIX}/SELLITEM_DELETE`,
  CURRENT_SELLITEM_DELETE: `${PREFIX}/CURRENT_SELLITEM_DELETE`,
  SELLITEM_DELETE_HANDLE: `${PREFIX}/SELLITEM_DELETE_HANDLE`,

  /* Sellitems */
  SELLITEMS_IN_CURRENT_GROUP_FETCH: `${PREFIX}/SELLITEMS_IN_CURRENT_GROUP_FETCH`,
  SELLITEMS_SEARCH: `${PREFIX}/SELLITEMS_SEARCH`,

  // /* Sellitem discount */

  // DISCOUNT_IN_CURRENT_SELLITEM_CREATE: `${PREFIX}/DISCOUNT_IN_CURRENT_SELLITEM_CREATE`,
  // SELLITEM_DISCOUNT_CREATE_HANDLE: `${PREFIX}/SELLITEM_DISCOUNT_CREATE_HANDLE`,
  // SELLITEM_DISCOUNT_DELETE: `${PREFIX}/SELLITEM_DISCOUNT_DELETE`,
  // SELLITEM_DISCOUNT_DELETE_HANDLE: `${PREFIX}/SELLITEM_DISCOUNT_DELETE_HANDLE`,

  /* Payment */

  PAYMENT_IN_CURRENT_CUSTOMER_CREATE: `${PREFIX}/PAYMENT_IN_CURRENT_CUSTOMER_CREATE`,
  PAYMENT_IN_CURRENT_SELLITEM_CREATE: `${PREFIX}/PAYMENT_IN_CURRENT_SELLITEM_CREATE`,
  PAYMENT_IN_CURRENT_PRODUCT_CREATE: `${PREFIX}/PAYMENT_IN_CURRENT_PRODUCT_CREATE`,
  PAYMENT_CREATE_HANDLE: `${PREFIX}/PAYMENT_CREATE_HANDLE`,
  PAYMENT_UPDATE: `${PREFIX}/PAYMENT_UPDATE`,
  PAYMENT_UPDATE_HANDLE: `${PREFIX}/PAYMENT_UPDATE_HANDLE`,
  PAYMENT_DELETE: `${PREFIX}/PAYMENT_DELETE`,
  PAYMENT_DELETE_HANDLE: `${PREFIX}/PAYMENT_DELETE_HANDLE`,
  PAYMENT_INIT_HANDLE: `${PREFIX}/PAYMENT_INIT_HANDLE`,

  /* Attachment */

  ATTACHMENT_IN_CURRENT_SELLITEM_CREATE: `${PREFIX}/ATTACHMENT_IN_CURRENT_SELLITEM_CREATE`,
  ATTACHMENT_CREATE_HANDLE: `${PREFIX}/ATTACHMENT_CREATE_HANDLE`,
  ATTACHMENT_UPDATE: `${PREFIX}/ATTACHMENT_UPDATE`,
  ATTACHMENT_UPDATE_HANDLE: `${PREFIX}/ATTACHMENT_UPDATE_HANDLE`,
  ATTACHMENT_DELETE: `${PREFIX}/ATTACHMENT_DELETE`,
  ATTACHMENT_DELETE_HANDLE: `${PREFIX}/ATTACHMENT_DELETE_HANDLE`,
  ATTACHMENT_PRODUCT_CREATE: `${PREFIX}/ATTACHMENT_PRODUCT_CREATE`,

  /* Actions */

  ACTIONS_IN_CURRENT_SELLITEM_FETCH: `${PREFIX}/ACTIONS_IN_CURRENT_SELLITEM_FETCH`,

  /* Action */

  ACTION_CREATE_HANDLE: `${PREFIX}/ACTION_CREATE_HANDLE`,
  ACTION_UPDATE_HANDLE: `${PREFIX}/ACTION_UPDATE_HANDLE`,
  ACTION_DELETE_HANDLE: `${PREFIX}/ACTION_DELETE_HANDLE`,

  /* Comment action */

  COMMENT_ACTION_IN_CURRENT_SELLITEM_CREATE: `${PREFIX}/COMMENT_ACTION_IN_CURRENT_SELLITEM_CREATE`,
  COMMENT_ACTION_UPDATE: `${PREFIX}/COMMENT_ACTION_UPDATE`,
  COMMENT_ACTION_DELETE: `${PREFIX}/COMMENT_ACTION_DELETE`,

  /* Notification */

  NOTIFICATION_CREATE_HANDLE: `${PREFIX}/NOTIFICATION_CREATE_HANDLE`,
  NOTIFICATION_DELETE: `${PREFIX}/NOTIFICATION_DELETE`,
  NOTIFICATION_DELETE_HANDLE: `${PREFIX}/NOTIFICATION_DELETE_HANDLE`,

  /* ProjectAgent */
  PROJECT_AGENT_CREATE: `${PREFIX}/PROJECT_AGENT_CREATE`,
  PROJECT_AGENT_CREATE_HANDLE: `${PREFIX}/PROJECT_AGENT_CREATE_HANDLE`,
  PROJECT_AGENT_UPDATE: `${PREFIX}/PROJECT_AGENT_UPDATE`,
  PROJECT_AGENT_UPDATE_HANDLE: `${PREFIX}/PROJECT_AGENT_UPDATE_HANDLE`,

  /* Status */
  STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,
  BAG_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/BAG_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  BAG_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/BAG_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,
  PRODUCT_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/PRODUCT_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  PRODUCT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/PRODUCT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,
  PRODUCT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE_ALL: `${PREFIX}/PRODUCT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE_ALL`,

  /* Dashboard */
  DASHBOARD_FETCH: `${PREFIX}/DASHBOARD_FETCH`,
};
