import { createSelector as createReselectSelector } from 'reselect';
import { createSelector as createReduxOrmSelector } from 'redux-orm';

import orm from '../orm';
import { currentUserIdSelector } from './user';
import matchPaths from '../utils/match-paths';
import Paths from '../constants/Paths';

export const pathnameSelector = ({
  router: {
    location: { pathname },
  },
}) => pathname;

export const locationSelector = ({ router: { location } }) => location;

export const pathsMatchSelector = createReselectSelector(pathnameSelector, (pathname) =>
  matchPaths(pathname, Object.values(Paths)),
);

export const pathSelector = createReduxOrmSelector(
  orm,
  pathsMatchSelector,
  (state) => currentUserIdSelector(state),
  ({ Project, Group, Customer, Product, Bag, Sellitem }, pathsMatch, currentUserId) => {
    if (pathsMatch) {
      switch (pathsMatch.path) {
        case Paths.PROJECTS: {
          const projectModel = Project.withId(pathsMatch.params.id);

          if (!projectModel) {
            return {
              projectId: null,
            };
          }

          if (!projectModel.hasManagerUser(currentUserId)) {
            if (!projectModel.hasMemberUserForAnyGroup(currentUserId)) {
              return {
                projectId: null,
              };
            }
          }

          return {
            projectId: projectModel.id,
          };
        }
        case Paths.PRODUCTS: {
          const productModel = Product.withId(pathsMatch.params.id);
          let groupModel = productModel && productModel.group;
          if (!productModel) {
            groupModel = Group.withId(pathsMatch.params.id);
          }
          const projectModel = groupModel && groupModel.project;

          if (!projectModel) {
            return {
              productId: null,
              groupId: null,
              projectId: null,
            };
          }

          if (!projectModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                productId: null,
                groupId: null,
                projectId: null,
              };
            }
          }

          return {
            productId: productModel ? productModel.id : null,
            groupId: groupModel.id,
            projectId: projectModel.id,
          };
        }
        case Paths.BAGS: {
          const bagModel = Bag.withId(pathsMatch.params.id);
          let groupModel = bagModel && bagModel.group;
          if (!bagModel) {
            groupModel = Group.withId(pathsMatch.params.id);
          }
          const projectModel = groupModel && groupModel.project;

          if (!projectModel) {
            return {
              bagId: null,
              groupId: null,
              projectId: null,
            };
          }

          if (!projectModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                bagId: null,
                groupId: null,
                projectId: null,
              };
            }
          }

          return {
            bagId: bagModel ? bagModel.id : null,
            groupId: groupModel.id,
            projectId: projectModel.id,
          };
        }
        case Paths.TRANSACTIONS:
        case Paths.ACCOUNTANT:
        case Paths.DEBTS:
        case Paths.GROUPS: {
          const groupModel = Group.withId(pathsMatch.params.id);
          const projectModel = groupModel && groupModel.project;

          if (!projectModel) {
            return {
              groupId: null,
              projectId: null,
            };
          }

          if (!projectModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                groupId: null,
                projectId: null,
              };
            }
          }

          return {
            groupId: groupModel.id,
            projectId: projectModel.id,
          };
        }
        case Paths.CUSTOMERS: {
          const customerModel = Customer.withId(pathsMatch.params.id);
          const { groupId, id } = pathsMatch.params;
          const groupModel = Group.withId(groupId);
          const projectModel = groupModel && groupModel.project;

          if (!projectModel) {
            return {
              customerId: id,
              groupId,
              projectId: null,
            };
          }

          if (!projectModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                customerId: id,
                groupId,
                projectId: null,
              };
            }
          }

          return {
            customerId: customerModel ? customerModel.id : null,
            groupId,
            projectId: projectModel.id,
          };
        }
        case Paths.SELLITEMS: {
          const sellitemModel = Sellitem.withId(pathsMatch.params.id);
          const groupModel = sellitemModel
            ? sellitemModel.group
            : Group.withId(pathsMatch.params.id);
          const projectModel = groupModel && groupModel.project;

          if (!projectModel) {
            return {
              sellitemId: null,
              groupId: null,
              projectId: null,
              // blockId: null,
            };
          }

          if (!projectModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                sellitemId: null,
                groupId: null,
                projectId: null,
                // blockId: null,
              };
            }
          }

          return {
            sellitemId: sellitemModel ? sellitemModel.id : groupModel.id,
            groupId: groupModel.id,
            projectId: projectModel.id,
            // blockId: sellitemModel.blockId,
          };
        }
        case Paths.DASHBOARD: {
          const groupModel = Group.withId(pathsMatch.params.id);
          const projectModel = groupModel && groupModel.project;

          if (!projectModel) {
            return {
              groupId: null,
              projectId: null,
            };
          }

          if (!projectModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                groupId: null,
                projectId: null,
              };
            }
          }

          return {
            groupId: groupModel.id,
            projectId: projectModel.id,
          };
        }
        default:
      }
    }

    return {};
  },
);
