import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { isLocalId } from '../utils/local-id';

export const makeBagByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Bag }, id) => {
      const bagModel = Bag.withId(id);

      if (!bagModel) {
        return bagModel;
      }

      return {
        ...bagModel.ref,
        creator: bagModel.creator?.ref,
        category: bagModel.category?.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const currentBagSelector = createSelector(
  orm,
  (state) => pathSelector(state).bagId,
  ({ Bag }, id) => {
    if (!id) {
      return id;
    }

    const bagModel = Bag.withId(id);

    if (!bagModel) {
      return bagModel;
    }

    return {
      ...bagModel.ref,
      creator: bagModel.creator.ref,
      category: bagModel.category?.ref,
      discounts: bagModel.getOrderedDiscountsQuerySet().toRefArray(),
    };
  },
);

export const bagitemsForCurrentBagSelector = createSelector(
  orm,
  (state) => pathSelector(state).bagId,
  (state) => pathSelector(state).groupId,
  ({ Bag, Group }, id, groupId) => {
    if (!id) {
      return id;
    }

    const bagModel = Bag.withId(id);
    const groupModel = Group.withId(groupId);

    if (!bagModel) {
      return bagModel;
    }

    let bagitemsModel = bagModel.getOrderedBagitemsQuerySet().toModelArray();
    if (groupModel) {
      const { filterBagStatuses } = groupModel;
      if (filterBagStatuses.length > 0) {
        bagitemsModel = bagitemsModel.filter((bagitemModel) =>
          filterBagStatuses.includes(bagitemModel.status),
        );
      }
    }

    return bagitemsModel.map((bagitemModel) => ({
      ...bagitemModel.ref,
      product: bagitemModel.product.ref,
    }));
  },
);

export const bagitemsForBagIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ Bag }, id) => {
    if (!id) {
      return id;
    }

    const bagModel = Bag.withId(id);

    if (!bagModel) {
      return bagModel;
    }

    return bagModel
      .getOrderedBagitemsQuerySet()
      .toModelArray()
      .map((bagitemModel) => {
        const { landPrice, floorPrice, factor } = bagitemModel.ref;
        return {
          ...bagitemModel.product.ref,
          landPrice,
          floorPrice,
          factor,
          block: bagitemModel.product.block?.ref.name,
          agent: bagitemModel.agent.ref,
        };
      });
  },
);

export const productsForCurrentBagSelector = createSelector(
  orm,
  (state) => pathSelector(state).bagId,
  ({ Bag, Group }, id) => {
    if (!id) {
      return id;
    }

    const bagModel = Bag.withId(id);

    if (!bagModel) {
      return bagModel;
    }

    const groupModel = Group.withId(bagModel.groupId);

    const { filterAgents, filterProductStatuses } = groupModel;
    let bagitemsModel = bagModel.getOrderedBagitemsQuerySet().toModelArray();

    if (filterProductStatuses.length > 0) {
      bagitemsModel = bagitemsModel.filter((bagitemModel) =>
        filterProductStatuses.includes(bagitemModel.product.status),
      );
    }

    const filterAgentIds = filterAgents.toRefArray().map((agent) => agent.id);
    if (filterAgentIds.length > 0) {
      bagitemsModel = bagitemsModel.filter((bagitemModel) =>
        filterAgentIds.includes(bagitemModel.product?.agent?.id),
      );
    }

    const discounts = bagModel.getOrderedDiscountsQuerySet().toRefArray();

    return bagitemsModel
      .map((bagitemModel) => {
        const p = bagitemModel.product.ref;
        const { id: bagitemId, landPrice, floorPrice, factor } = bagitemModel.ref;
        const landTotal = Math.round(landPrice * p.landArea * factor, 0);
        const landTotalWithoutVAT = Math.round(landTotal / 1.1);
        const floorTotal = floorPrice * p.floorArea;
        return {
          ...p,
          bagitemId,
          landPrice,
          floorPrice,
          factor,
          landTotal,
          landTotalWithoutVAT,
          floorTotal,
          finalTotal: landTotal + floorTotal,
          blockName: bagitemModel.product.block?.ref.name,
          agentName: bagitemModel.agent?.ref.name,
          discounts,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  },
);
