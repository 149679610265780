/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';

import DatePicker from 'react-datepicker';
import Handsontable from 'handsontable';
// import { Statuses } from '../../constants/Enums';

import styles from './DateEditor.module.scss';

export default class DatePickerEditor extends Handsontable.editors.TextEditor {
  init() {
    this.inputRef = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.date = new Date();
  }

  setValue(value) {
    this.date = value;
  }

  getValue() {
    return this.date;
  }

  open() {
    super.open();
    // if (tdPosition.bottom - tdPosition.top):
    // this.TEXTAREA_PARENT.style.minWidth = `${tdPosition.width + 1}px`;
    this.render();
  }

  close() {
    super.close();

    if (this.isOpened()) {
      if (this.instance.rootDocument.body.contains(this.datePicker)) {
        this.instance.rootDocument.body.removeChild(this.datePicker);
      }
      this.instance.selectCell(this.row, this.col);
    }
    this.instance.listen();
  }

  focus() {
    super.focus();
    this.instance.listen();
    if (this.inputRef.current) {
      this.inputRef.current.setFocus(true);
    }
  }

  onChange(value) {
    this.setValue(value.toISOString());
    // this.setValue(this.date ? value : value.toISOString());
    this.finishEditing(false, false, () => setTimeout(() => this.close()));
  }

  onBeforeKeyDown(event) {
    super.onBeforeKeyDown(event);

    const keys = Handsontable.helper.KEY_CODES;
    switch (event.keyCode) {
      case keys.ARROW_LEFT:
      case keys.ARROW_RIGHT:
      case keys.ARROW_UP:
      case keys.ARROW_DOWN:
        event.stopImmediatePropagation();

        break;

      case keys.ENTER:
      case keys.ESCAPE: {
        // this.close();
        this.finishEditing(true, false, () => setTimeout(() => this.close()));
        break;
      }

      default:
        break;
    }
  }

  // prepare(row, col, prop, td, originalValue, cellProperties) {
  //   super.prepare(row, col, prop, td, originalValue, cellProperties);

  // }

  stopMousedownPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    let selected = new Date();
    if (this.date) {
      selected = new Date(this.date);
    }

    const td = this.getEditedCell();
    const tdPosition = td.getBoundingClientRect();

    const renderResult = (
      <DatePicker
        ref={this.inputRef}
        // autoFocus
        inline
        dateFormat="dd/MM/yyyy"
        selected={selected}
        onBlur={() => this.finishEditing()}
        onChange={this.onChange}
      />
    );

    // Handsontable.dom.empty(this.TEXTAREA_PARENT);
    // Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'htDatepickerHolder');
    this.datePicker = document.createElement('div');
    this.datePicker.onmousedown = this.stopMousedownPropagation;
    // this.datePicker.addEventListener('mousedown', this.stopMousedownPropagation);

    this.datePicker.classList.add(styles.wrapper);
    this.datePicker.style.left = `${tdPosition.left - 1}px`;
    this.datePicker.style.top = `${tdPosition.top - 1}px`;

    ReactDOM.render(renderResult, this.datePicker);
    // this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
    this.instance.rootDocument.body.appendChild(this.datePicker);

    // console.log(
    //   this.instance.rootDocument.body.clientHeight,
    //   tdPosition.top,
    //   this.datePicker.clientHeight,
    // );
    // if (
    //   this.instance.rootDocument.body.clientHeight - tdPosition.top >
    //   this.datePicker.clientHeight
    // ) {
    //   this.datePicker.style.top = `${tdPosition.top - 1}px`;
    // } else {
    //   this.datePicker.style.top = `${
    //     tdPosition.top - this.datePicker.clientHeight + this.TEXTAREA.clientHeight + 6
    //   }px`;
    // }

    // console.log(
    //   this.instance.rootDocument.body.clientHeight,
    //   tdPosition.top,
    //   this.datePicker.clientHeight,
    // );

    // this.TEXTAREA.value = this.originalValue;
  }
}
