import { takeEvery } from 'redux-saga/effects';

import { fetchSellitemsInCurrentGroupService } from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* sellitemsWatchers() {
  yield takeEvery(EntryActionTypes.SELLITEMS_IN_CURRENT_GROUP_FETCH, () =>
    fetchSellitemsInCurrentGroupService(),
  );
}
