import React from 'react';
import PropTypes from 'prop-types';

import Filters from './Filters';
import Memberships from '../Memberships';

import styles from './GroupActions.module.scss';

const GroupActions = React.memo(
  ({
    path,
    memberships,
    categories,
    agents,
    filterUsers,
    filterCategories,
    filterAgents,
    filterStatuses,
    filterProductStatuses,
    allUsers,
    canEditMemberships,
    onMembershipCreate,
    onMembershipDelete,
    onUserToFilterAdd,
    onUserFromFilterRemove,
    onCategoryToFilterAdd,
    onCategoryFromFilterRemove,
    onAgentToFilterAdd,
    onAgentFromFilterRemove,
    onStatusToFilterAdd,
    onStatusFromFilterRemove,
    onProductStatusToFilterAdd,
    onProductStatusFromFilterRemove,
  }) => {
    return (
      <div className={styles.actions}>
        <div className={styles.action}>
          <Memberships
            items={memberships}
            allUsers={allUsers}
            canEdit={canEditMemberships}
            onCreate={onMembershipCreate}
            onDelete={onMembershipDelete}
          />
        </div>
        <div className={styles.action}>
          <Filters
            path={path}
            users={filterUsers}
            categories={filterCategories}
            agents={filterAgents}
            statuses={filterStatuses}
            productStatuses={filterProductStatuses}
            allGroupMemberships={memberships}
            allCategories={categories}
            allAgents={agents}
            onUserAdd={onUserToFilterAdd}
            onUserRemove={onUserFromFilterRemove}
            onCategoryAdd={onCategoryToFilterAdd}
            onCategoryRemove={onCategoryFromFilterRemove}
            onAgentAdd={onAgentToFilterAdd}
            onAgentRemove={onAgentFromFilterRemove}
            onStatusAdd={onStatusToFilterAdd}
            onStatusRemove={onStatusFromFilterRemove}
            onProductStatusAdd={onProductStatusToFilterAdd}
            onProductStatusRemove={onProductStatusFromFilterRemove}
          />
        </div>
      </div>
    );
  },
);

GroupActions.propTypes = {
  path: PropTypes.string.isRequired,
  memberships: PropTypes.arrayOf(PropTypes.object()).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  agents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  filterUsers: PropTypes.arrayOf(PropTypes.object()).isRequired,
  filterCategories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  filterAgents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  filterStatuses: PropTypes.arrayOf(PropTypes.object()).isRequired,
  filterProductStatuses: PropTypes.arrayOf(PropTypes.object()).isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.object()).isRequired,
  canEditMemberships: PropTypes.bool.isRequired,
  onMembershipCreate: PropTypes.func.isRequired,
  onMembershipDelete: PropTypes.func.isRequired,
  onUserToFilterAdd: PropTypes.func.isRequired,
  onUserFromFilterRemove: PropTypes.func.isRequired,
  onCategoryToFilterAdd: PropTypes.func.isRequired,
  onCategoryFromFilterRemove: PropTypes.func.isRequired,
  onAgentToFilterAdd: PropTypes.func.isRequired,
  onAgentFromFilterRemove: PropTypes.func.isRequired,
  onStatusToFilterAdd: PropTypes.func.isRequired,
  onStatusFromFilterRemove: PropTypes.func.isRequired,
  onProductStatusToFilterAdd: PropTypes.func.isRequired,
  onProductStatusFromFilterRemove: PropTypes.func.isRequired,
};

export default GroupActions;
