import { call, put, select } from 'redux-saga/effects';

import { goToProjectService } from './router';
import request from '../request';
import { fetchGroupByCurrentPathRequest } from '../requests';
import {
  groupMembershipByIdSelector,
  currentUserIdSelector,
  isCurrentUserManagerForCurrentProjectSelector,
  notificationsBySellitemIdSelector,
  pathSelector,
} from '../../../selectors';
import {
  createGroupMembership,
  deleteGroupMembership,
  handleGroupMembershipCreate,
  handleGroupMembershipDelete,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import mergeRecords from '../../../utils/merge-records';

export function* createGroupMembershipService(groupId, data) {
  const localId = yield call(createLocalId);

  yield put(
    createGroupMembership({
      ...data,
      groupId,
      id: localId,
    }),
  );

  let groupMembership;
  try {
    ({ item: groupMembership } = yield call(request, api.createGroupMembership, groupId, data));
  } catch (error) {
    yield put(createGroupMembership.failure(localId, error));
    return;
  }

  yield put(createGroupMembership.success(localId, groupMembership));
}

export function* createMembershipInCurrentGroupService(data) {
  const { groupId } = yield select(pathSelector);

  yield call(createGroupMembershipService, groupId, data);
}

export function* handleGroupMembershipCreateService(groupMembership) {
  const currentUserId = yield select(currentUserIdSelector);
  const isCurrentUser = groupMembership.userId === currentUserId;

  let user;
  let project;
  let group1;
  let users1;
  let users2;
  let projectManagers;
  let groups;
  let groupMemberships1;
  let groupMemberships2;
  let labels;
  let customers;
  let products;
  let bags;
  let bagitems;
  let discounts;
  let sellitems;
  let sellitemMemberships;
  let sellitemLabels;
  let sellitemCustomers;
  let sellitemDiscounts;
  let payments;
  let attachments;
  let notifications;

  if (isCurrentUser) {
    let group2;
    try {
      ({ item: group2 } = yield call(request, api.getGroup, groupMembership.groupId));
    } catch {
      return;
    }

    const { groupId } = yield select(pathSelector);

    yield put(handleGroupMembershipCreate.fetchProject(group2.projectId, currentUserId, groupId));

    try {
      ({
        item: project,
        included: { users: users1, projectManagers, groups, groupMemberships: groupMemberships1 },
      } = yield call(request, api.getProject, group2.projectId));
    } catch {
      return;
    }

    let body;
    try {
      body = yield call(fetchGroupByCurrentPathRequest);
    } catch {} // eslint-disable-line no-empty

    if (body && body.project && body.project.id === group2.projectId) {
      ({
        project,
        group: group1,
        users: users2,
        groupMemberships: groupMemberships2,
        labels,
        customers,
        products,
        bags,
        bagitems,
        discounts,
        sellitems,
        sellitemMemberships,
        sellitemLabels,
        sellitemCustomers,
        sellitemDiscounts,
        payments,
        attachments,
      } = body);

      if (body.sellitem) {
        notifications = yield select(notificationsBySellitemIdSelector, body.sellitem.id);
      }
    }
  } else {
    try {
      ({ item: user } = yield call(request, api.getUser, groupMembership.userId));
    } catch {
      return;
    }
  }

  yield put(
    handleGroupMembershipCreate(
      groupMembership,
      project,
      group1,
      isCurrentUser ? mergeRecords(users1, users2) : [user],
      projectManagers,
      groups,
      mergeRecords(groupMemberships1, groupMemberships2),
      labels,
      customers,
      products,
      bags,
      bagitems,
      discounts,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      sellitemCustomers,
      sellitemDiscounts,
      payments,
      attachments,
      notifications,
    ),
  );
}

export function* deleteGroupMembershipService(id) {
  let groupMembership = yield select(groupMembershipByIdSelector, id);

  const currentUserId = yield select(currentUserIdSelector);
  const { groupId, projectId } = yield select(pathSelector);

  if (groupMembership.userId === currentUserId && groupMembership.groupId === groupId) {
    const isCurrentUserManager = yield select(isCurrentUserManagerForCurrentProjectSelector);

    if (!isCurrentUserManager) {
      yield call(goToProjectService, projectId);
    }
  }

  yield put(deleteGroupMembership(id));

  try {
    ({ item: groupMembership } = yield call(request, api.deleteGroupMembership, id));
  } catch (error) {
    yield put(deleteGroupMembership.failure(id, error));
    return;
  }

  yield put(deleteGroupMembership.success(groupMembership));
}

export function* handleGroupMembershipDeleteService(groupMembership) {
  const currentUserId = yield select(currentUserIdSelector);
  const { groupId, projectId } = yield select(pathSelector);

  if (groupMembership.userId === currentUserId && groupMembership.groupId === groupId) {
    const isCurrentUserManager = yield select(isCurrentUserManagerForCurrentProjectSelector);

    if (!isCurrentUserManager) {
      yield call(goToProjectService, projectId);
    }
  }

  yield put(handleGroupMembershipDelete(groupMembership));
}
