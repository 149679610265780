import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { pathSelector } from '../../../selectors';
import { createProjectAgent, updateProjectAgent } from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createProjectAgentService(productId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createProjectAgent({
      ...nextData,
      productId,
      id: localId,
    }),
  );

  let projectAgent;
  try {
    ({ item: projectAgent } = yield call(request, api.createProjectAgent, productId, nextData));
  } catch (error) {
    yield put(createProjectAgent.failure(localId, error));
    return;
  }

  yield put(createProjectAgent.success(localId, productId, projectAgent));
}

export function* createProjectAgentInCurrentProductService(data) {
  const { productId } = yield select(pathSelector);

  yield call(createProjectAgentService, productId, data);
}

export function* updateProjectAgentService(id, data) {
  yield put(updateProjectAgent(id, data));

  let projectAgent;
  try {
    ({ item: projectAgent } = yield call(request, api.updateProjectAgent, id, data));
  } catch (error) {
    yield put(updateProjectAgent.failure(id, error));
    return;
  }

  yield put(updateProjectAgent.success(projectAgent));
}
