import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  isAdminSelector,
  // isSaleAdminSelector,
  agentsForCurrentProjectSelector,
  blocksForCurrentGroupSelector,
  categoriesForCurrentProjectSelector,
  pathSelector,
  productsFilteredForCurrentGroupSelector,
  productsForCurrentBagSelector,
  currentBagSelector,
  currentProjectSelector,
} from '../selectors';
import {
  createBagitem,
  openProductAddModal,
  updateProduct,
  updateBag,
  updateBagitem,
  deleteBagitem,
  createDiscount,
  updateDiscount,
  deleteDiscount,
} from '../actions/entry';
import { getRightSidebar, setRightSidebar } from '../utils/right-sidebar-storage';
import { ProductStatuses } from '../constants/Enums';

import Bags from '../components/Bags';

const mapStateToProps = (state) => {
  const { bagId } = pathSelector(state);
  // const products = productsForCurrentGroupSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);
  // const isSaleAdmin = isSaleAdminSelector(state);
  const currentBag = currentBagSelector(state);
  let allProducts = productsFilteredForCurrentGroupSelector(state);
  const lockedStatuses = [
    ProductStatuses.LOCKED.name,
    ProductStatuses.DEPOSIT.name,
    ProductStatuses.SELLED.name,
  ];
  let products;
  if (bagId) {
    products = productsForCurrentBagSelector(state);
    products = products.map((p) => ({
      ...p,
      statusLabel: ProductStatuses[p.status.toUpperCase()]?.label,
    }));
    const productIds = products.map(({ id }) => id);
    allProducts = allProducts.filter(
      ({ id, status }) => !productIds.includes(id) && !lockedStatuses.includes(status),
    );
  } else {
    // products = allProducts;
    products = allProducts
      // .filter(({ status }) => !lockedStatuses.includes(status))
      .map((p) => {
        const landTotal = Math.round(p.landPrice * p.landArea * p.factor, 0);
        const floorTotal = p.floorPrice * p.floorArea;
        return {
          ...p,
          statusLabel: ProductStatuses[p.status.toUpperCase()]?.label,
          landTotal,
          floorTotal,
          finalTotal: landTotal + floorTotal,
        };
      });
  }

  const agents = agentsForCurrentProjectSelector(state);
  const blocks = blocksForCurrentGroupSelector(state);
  const categories = categoriesForCurrentProjectSelector(state);

  const { discount1, discount2, discount3, discount4, discount5 } = currentProjectSelector(state);

  const toggleRightSidebar = getRightSidebar();

  return {
    products,
    allProducts,
    currentBag,
    agents,
    blocks,
    categories,
    project: { discount1, discount2, discount3, discount4, discount5 },
    toggleRightSidebar,
    canEdit: isAdmin && isCurrentUserMember,
    onSetRightSidebar: (data) => setRightSidebar(data),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreateBagitem: (data) => createBagitem(data),
      onCreateProduct: openProductAddModal,
      onDeleteBagitem: (id) => deleteBagitem(id),
      onCreateDiscount: (data) => createDiscount(data),
      onUpdateDiscount: (id, data) => updateDiscount(id, data),
      onDeleteDiscount: (id) => deleteDiscount(id),
      onUpdateProduct: (id, data) => updateProduct(id, data),
      onUpdateBag: (id, data) => updateBag(id, data),
      onUpdateBagitem: (id, data) => updateBagitem(id, data),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Bags);
