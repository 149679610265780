import { all, takeEvery } from 'redux-saga/effects';

import {
  createBlockInCurrentGroupService,
  deleteBlockservice,
  handleBlockCreateService,
  handleBlockDeleteService,
  handleBlockUpdateService,
  moveBlockService,
  updateBlockservice,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* blockWatchers() {
  yield all([
    takeEvery(EntryActionTypes.BLOCK_IN_CURRENT_GROUP_CREATE, ({ payload: { data } }) =>
      createBlockInCurrentGroupService(data),
    ),
    takeEvery(EntryActionTypes.BLOCK_CREATE_HANDLE, ({ payload: { block } }) =>
      handleBlockCreateService(block),
    ),
    takeEvery(EntryActionTypes.BLOCK_UPDATE, ({ payload: { id, data } }) =>
      updateBlockservice(id, data),
    ),
    takeEvery(EntryActionTypes.BLOCK_UPDATE_HANDLE, ({ payload: { block } }) =>
      handleBlockUpdateService(block),
    ),
    takeEvery(EntryActionTypes.BLOCK_MOVE, ({ payload: { id, index } }) =>
      moveBlockService(id, index),
    ),
    takeEvery(EntryActionTypes.BLOCK_DELETE, ({ payload: { id } }) => deleteBlockservice(id)),
    takeEvery(EntryActionTypes.BLOCK_DELETE_HANDLE, ({ payload: { block } }) =>
      handleBlockDeleteService(block),
    ),
  ]);
}
