/* eslint-disable no-unused-vars */
import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeSellitemCustomersByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ SellitemCustomers, Sellitem }, id) => {
      let sellitemCustomers = SellitemCustomers.all()
        .toRefArray()
        .filter((sc) => sc.fromSellitemId === id);
      const sellitem = Sellitem.withId(id);
      if (sellitem.customerId) {
        sellitemCustomers = sellitemCustomers.filter(
          (sc) => sc.toCustomerId !== sellitem.customerId,
        );
      }
      return sellitemCustomers;
    },
  );

export const makeAllSellitemCustomersByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ SellitemCustomers }, id) => {
      const sellitemCustomers = SellitemCustomers.all()
        .toRefArray()
        .filter((sc) => sc.fromSellitemId === id);
      return sellitemCustomers;
    },
  );

export const sellitemCustomersByIdSelector = makeSellitemCustomersByIdSelector();
export const allSellitemCustomersByIdSelector = makeAllSellitemCustomersByIdSelector();
