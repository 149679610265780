import socket from './socket';

/* Actions */

const createSellitemCustomer = (sellitemId, data, headers) =>
  socket.post(`/sellitems/${sellitemId}/customers`, data, headers);

const deleteSellitemCustomer = (sellitemId, customerId, headers) =>
  socket.delete(`/sellitems/${sellitemId}/customers/${customerId}`, undefined, headers);

export default {
  createSellitemCustomer,
  deleteSellitemCustomer,
};
