import React from 'react';
import PropTypes from 'prop-types';

import HeaderContainer from '../../containers/HeaderContainer';
import ProjectContainer from '../../containers/ProjectContainer';
import GroupActionsContainer from '../../containers/GroupActionsContainer';
import ProductWidgetsContainer from '../../containers/ProductWidgetsContainer';

import Paths from '../../constants/Paths';

import styles from './Fixed.module.scss';

const Fixed = ({ path, projectId, group }) => (
  <div className={styles.wrapper}>
    <HeaderContainer />
    {projectId && (
      <div className="hidden-desktop show-mobile">
        <ProductWidgetsContainer />
      </div>
    )}
    {projectId && <ProjectContainer />}
    {group && !group.isFetching && path !== Paths.ACCOUNTANT && path !== Paths.DEBTS && (
      <div className="hidden-mobile">
        <GroupActionsContainer />
      </div>
    )}
  </div>
);

Fixed.propTypes = {
  path: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  group: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Fixed.defaultProps = {
  projectId: undefined,
  group: undefined,
};

export default Fixed;
