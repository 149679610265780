import React, { useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { withPopup } from '../../../lib/popup';
import { Popup } from '../../../lib/custom-ui';
import { useSteps } from '../../../hooks';

import ImportContainer from '../../../containers/ImportContainer';

import styles from './ActionsPopup.module.scss';

const StepTypes = {
  IMPORT: 'IMPORT',
};

const ActionsStep = React.memo(() => {
  const [t] = useTranslation();
  const [step, openStep, handleBack] = useSteps();

  const handleImportStepClick = useCallback(() => {
    openStep(StepTypes.IMPORT);
  }, [openStep]);

  if (step) {
    switch (step.type) {
      case StepTypes.IMPORT:
        return (
          <ImportContainer
            title={t('action.importProducts', {
              context: 'title',
            })}
            type="product"
            onBack={handleBack}
          />
        );
      default:
    }
  }

  return (
    <>
      <Popup.Header>
        {t('common.actions', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Menu secondary vertical className={styles.menu}>
          <Menu.Item className={styles.menuItem} onClick={handleImportStepClick}>
            {t('action.importProducts', {
              context: 'title',
            })}
          </Menu.Item>
        </Menu>
      </Popup.Content>
    </>
  );
});

ActionsStep.propTypes = {
  // onClose: PropTypes.func.isRequired,
};

export default withPopup(ActionsStep);
