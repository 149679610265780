/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, List } from 'semantic-ui-react';
import ActionsPopup from './ActionsPopup';
import BagListItemContainer from '../../containers/BagListItemContainer';
import { ReactComponent as DotIcon } from '../../assets/images/dots-vertical.svg';

import styles from './LeftSidebar.module.scss';
import Paths from '../../constants/Paths';
import AddPopup from './AddPopup';

const LeftSidebar = React.memo(
  ({ canEdit, bagIds, bag, searchResults, onSearchProducts, onBagCreate }) => {
    const [t] = useTranslation();

    const [query, setQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [focused, setFocused] = useState(false);
    const searchField = useRef(null);
    const { isSubmitting, products } = searchResults;

    let intervalId;
    const handleSearchChange = useCallback(
      (e, data) => {
        clearInterval(intervalId);
        if (data && data.value.length >= data.minCharacters) {
          setQuery(data.value);
        } else {
          setIsSearching(false);
          setQuery('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        intervalId = setInterval(() => {
          if (data && data.value.length >= data.minCharacters) {
            onSearchProducts(data.value);
            setIsSearching(true);
          }
          clearInterval(intervalId);
        }, 1000);
      },
      [onSearchProducts],
    );

    const results = products.map(({ id, name }) => ({
      id,
      title: name,
      productid: id,
    }));

    const handleWindowKeyDown = useCallback(
      (e) => {
        if (!focused && e.keyCode === 191) {
          e.preventDefault();
          searchField.current.select();
        }
      },
      [focused],
    );

    useEffect(() => {
      window.addEventListener('keydown', handleWindowKeyDown);

      return () => {
        window.removeEventListener('keydown', handleWindowKeyDown);
      };
    }, [handleWindowKeyDown]);

    const resultRenderer = () => {
      if (results.length > 0)
        return results.map((productItem, index) => (
          <BagListItemContainer key={productItem.id} id={productItem.id} index={index} />
        ));
      return t('common.noResultsMessage');
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.sidebarLeftHeader}>
          <Link to={Paths.BAGS} className={styles.searchWrapper}>
            <div>{t('common.bagParent')}</div>
          </Link>
          {canEdit && (
            <>
              <AddPopup onCreate={onBagCreate}>
                <Button
                  circular
                  size="mini"
                  icon="shopping bag"
                  className={styles.addProductButton}
                />
              </AddPopup>
              <ActionsPopup>
                <div className={styles.actions}>
                  <DotIcon className={styles.dotIcon} />
                </div>
              </ActionsPopup>
            </>
          )}
        </div>
        <List divided selection className={styles.wrapperProducts}>
          {!isSearching && bagIds && bagIds.length > 0
            ? bagIds.map((bagId, index) => (
                <BagListItemContainer key={bagId} id={bagId} index={index} />
              ))
            : resultRenderer()}
        </List>
      </div>
    );
  },
);

LeftSidebar.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  bagIds: PropTypes.arrayOf(PropTypes.object()).isRequired,
  bag: PropTypes.objectOf(PropTypes.shape),
  searchResults: PropTypes.objectOf(PropTypes.shape).isRequired,
  onSearchProducts: PropTypes.func.isRequired,
  onBagCreate: PropTypes.func.isRequired,
};

LeftSidebar.defaultProps = {
  bag: undefined,
};

export default LeftSidebar;
