import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { currentGroupSelector } from './group';
import { isLocalId } from '../utils/local-id';
import { Statuses } from '../constants/Enums';

export const makeProductByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Product }, id) => {
      const productModel = Product.withId(id);

      if (!productModel) {
        return productModel;
      }

      return {
        ...productModel.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const productByIdSelector = makeProductByIdSelector();

export const currentProductSelector = createSelector(
  orm,
  (state) => pathSelector(state).productId,
  ({ Product }, id) => {
    if (!id) {
      return id;
    }

    const productModel = Product.withId(id);

    if (!productModel) {
      return productModel;
    }

    return productModel.ref;
  },
);

export const makeSellitemsByProductIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    (state) => currentGroupSelector(state),
    ({ Product }, id) => {
      const productModel = Product.withId(id);

      if (!productModel) {
        return productModel;
      }

      const sellitemsModel = productModel.getOrderedFilteredSellitemsModelArray();

      return sellitemsModel.map((sellitemModel) => ({
        ...sellitemModel.ref,
        creator: sellitemModel.creator?.ref.name,
        product: productModel.ref,
        agent: productModel.agent?.ref,
      }));
    },
  );

export const sellitemsByProductIdSelector = makeSellitemsByProductIdSelector();

export const makeCurrentSellitemByProductIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    (state) => currentGroupSelector(state),
    ({ Product }, id) => {
      const productModel = Product.withId(id);

      if (!productModel) {
        return productModel;
      }

      const currentSellitemModel = productModel
        .getOrderedFilteredSellitemsModelArray()
        .map((sellitemModel) => ({
          ...sellitemModel.ref,
          creator: sellitemModel.creator?.ref.name,
          product: productModel.ref,
          agent: productModel.agent?.ref,
          payments: sellitemModel.getOrderedPaymentsQuerySet().toRefArray(),
        }))
        .find((sl) => sl.status !== Statuses.CANCELED.name);

      return {
        ...currentSellitemModel,
      };
    },
  );

export const currentSellitemByProductIdSelector = makeCurrentSellitemByProductIdSelector();

export const makeProjectAgentsByProductIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Product }, id) => {
      const productModel = Product.withId(id);

      if (!productModel) {
        return productModel;
      }
      return productModel
        .getOrderedProjectAgentsQuerySet()
        .toModelArray()
        .map((projectAgentModel) => ({
          ...projectAgentModel.ref,
          creator: projectAgentModel.creator?.ref.name,
          product: projectAgentModel.product?.ref,
        }));
    },
  );
export const projectAgentsByProductIdSelector = makeProjectAgentsByProductIdSelector();

export const makeAttachmentsByProductIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Product, Attachment }, id) => {
      const productModel = Product.withId(id);

      if (!productModel) {
        return productModel;
      }

      let attachmentRefArray = productModel.getOrderedAttachmentsQuerySet().toRefArray();
      if (productModel.coverAttachmentId) {
        const attachmentModel = Attachment.withId(productModel.coverAttachmentId);
        if (
          attachmentModel &&
          !attachmentRefArray.find((p) => p.id === productModel.coverAttachmentId)
        ) {
          attachmentRefArray = attachmentRefArray.concat([attachmentModel.ref]);
        }
      }

      return attachmentRefArray.map((attachment) => ({
        ...attachment,
        isCover: attachment.id === productModel.coverAttachmentId,
        isPersisted: !isLocalId(attachment.id),
      }));
    },
  );
export const attachmentsByProductIdSelector = makeAttachmentsByProductIdSelector();
