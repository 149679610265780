import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  isAdminSelector,
  isSaleAdminSelector,
  // makeProductIdsByBlockIdSelector,
  makeBlockByIdSelector,
  // productsForCurrentGroupSelector,
  productIdsByBlockIdSelector,
} from '../selectors';
import { createProduct, deleteBlock, updateBlock } from '../actions/entry';
import Block from '../components/Block';

const makeMapStateToProps = () => {
  const blockByIdSelector = makeBlockByIdSelector();
  // const productIdsByBlockIdSelector = makeProductIdsByBlockIdSelector();

  return (state, { id, index }) => {
    const { name, isPersisted } = blockByIdSelector(state, id);
    // const products = productsForCurrentGroupSelector(state);
    // const productIds = products.filter((p) => p.blockId === id).map((p) => p.id);
    const productIds = productIdsByBlockIdSelector(state, id);
    const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
    const isAdmin = isAdminSelector(state);
    const isSaleAdmin = isSaleAdminSelector(state);

    return {
      id,
      index,
      name,
      isPersisted,
      productIds,
      canEdit: isAdmin || (isCurrentUserMember && isSaleAdmin),
    };
  };
};

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      onUpdate: (data) => updateBlock(id, data),
      onDelete: () => deleteBlock(id),
      onProductCreate: (data) => createProduct(id, data),
    },
    dispatch,
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(Block);
