import ActionTypes from '../constants/ActionTypes';

const initialState = {
  groupData: null,
  dashboard: null,
  isFetching: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.DASHBOARD_FETCH:
      return {
        ...initialState,
        isFetching: true,
      };
    case ActionTypes.DASHBOARD_FETCH__SUCCESS:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    case ActionTypes.DASHBOARD_FETCH__FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
