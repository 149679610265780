/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import User from '../User';
import Label from '../Label';
import { ProductStatuses, Statuses } from '../../constants/Enums';
import StatusesPopup from '../StatusesPopup';
import GroupMembershipsPopup from '../GroupMembershipsPopup';
import GroupCategoriesPopup from '../GroupCategoriesPopup';
import GroupAgentsPopup from '../GroupAgentsPopup';

import styles from './Filters.module.scss';
import Paths from '../../constants/Paths';

const Filters = React.memo(
  ({
    path,
    users,
    categories,
    agents,
    statuses,
    productStatuses,
    allGroupMemberships,
    allCategories,
    allAgents,
    onUserAdd,
    onUserRemove,
    onCategoryAdd,
    onCategoryRemove,
    onAgentAdd,
    onAgentRemove,
    onStatusAdd,
    onStatusRemove,
    onProductStatusAdd,
    onProductStatusRemove,
  }) => {
    const [t] = useTranslation();

    const handleRemoveUserClick = useCallback(
      (id) => {
        onUserRemove(id);
      },
      [onUserRemove],
    );

    const handleRemoveAgentClick = useCallback(
      (id) => {
        onAgentRemove(id);
      },
      [onAgentRemove],
    );

    const handleRemoveCategoryClick = useCallback(
      (id) => {
        onCategoryRemove(id);
      },
      [onCategoryRemove],
    );

    const handleRemoveStatusClick = useCallback(
      (key) => {
        onStatusRemove(key);
      },
      [onStatusRemove],
    );

    const handleRemoveProductStatusClick = useCallback(
      (key) => {
        onProductStatusRemove(key);
      },
      [onProductStatusRemove],
    );

    return (
      <>
        {/* <span className={styles.filter}>
          <GroupMembershipsPopup
            items={allGroupMemberships}
            currentUserIds={users.map((user) => user.id)}
            title="common.filterByMembers_title"
            onUserSelect={onUserAdd}
            onUserDeselect={onUserRemove}
          >
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.members')}:`}</span>
              {users.length === 0 && <span className={styles.filterLabel}>{t('common.all')}</span>}
            </button>
          </GroupMembershipsPopup>
          {users.map((user) => (
            <span key={user.id} className={styles.filterItem}>
              <User
                name={user.name}
                avatarUrl={user.avatarUrl}
                size="tiny"
                onClick={() => handleRemoveUserClick(user.id)}
              />
            </span>
          ))}
        </span> */}
        <span className={styles.filter}>
          <GroupAgentsPopup
            items={allAgents}
            currentAgentIds={agents.map((agent) => agent.id)}
            title="common.filterByAgents_title"
            onAgentSelect={onAgentAdd}
            onAgentDeselect={onAgentRemove}
          >
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.agent')}:`}</span>
              {agents.length === 0 && <span className={styles.filterLabel}>{t('common.all')}</span>}
            </button>
          </GroupAgentsPopup>
          {agents.map((agent) => (
            <span key={agent.id} className={styles.filterItem}>
              <Label
                name={agent.name}
                size="tiny"
                color="light-orange"
                onClick={() => handleRemoveAgentClick(agent.id)}
              />
            </span>
          ))}
        </span>
        {/* <span className={styles.filter}>
          <GroupCategoriesPopup
            items={allCategories}
            currentCategoryIds={categories.map((category) => category.id)}
            title="common.filterByCategories_title"
            onCategorySelect={onCategoryAdd}
            onCategoryDeselect={onCategoryRemove}
          >
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.category')}:`}</span>
              {categories.length === 0 && (
                <span className={styles.filterLabel}>{t('common.all')}</span>
              )}
            </button>
          </GroupCategoriesPopup>
          {categories.map((category) => (
            <span key={category.id} className={styles.filterItem}>
              <Label
                name={category.name}
                size="tiny"
                color="tank-green"
                onClick={() => handleRemoveCategoryClick(category.id)}
              />
            </span>
          ))}
        </span> */}
        {path === Paths.SELLITEMS && (
          <span className={styles.filter}>
            <StatusesPopup
              items={Object.values(Statuses)}
              isFilter
              currentStatuses={statuses}
              title="common.filterByStatuses"
              canEdit
              onSelect={onStatusAdd}
              onDeselect={onStatusRemove}
              labelStatus="status"
            >
              <button type="button" className={styles.filterButton}>
                <span className={styles.filterTitle}>{`${t('common.status')}:`}</span>
                {statuses.length === 0 && (
                  <span className={styles.filterLabel}>{t('common.all')}</span>
                )}
              </button>
            </StatusesPopup>
            {statuses
              .map((status) => Statuses[status.toUpperCase()])
              .map(({ name: status, color }) => (
                <span key={status} className={styles.filterItem}>
                  <Label
                    name={t(`status.${status}`, {
                      context: 'title',
                    })}
                    color={color}
                    size="small"
                    onClick={() => handleRemoveStatusClick(status)}
                  />
                </span>
              ))}
          </span>
        )}
        {(path === Paths.BAGS || path === Paths.GROUPS) && (
          <span className={styles.filter}>
            <StatusesPopup
              items={Object.values(ProductStatuses)}
              isFilter
              currentStatuses={productStatuses}
              title="common.filterByStatuses"
              canEdit
              onSelect={onProductStatusAdd}
              onDeselect={onProductStatusRemove}
              labelStatus="status"
            >
              <button type="button" className={styles.filterButton}>
                <span className={styles.filterTitle}>{`${t('common.status')}:`}</span>
                {statuses.length === 0 && (
                  <span className={styles.filterLabel}>{t('common.all')}</span>
                )}
              </button>
            </StatusesPopup>
            {productStatuses
              .map((status) => ProductStatuses[status.toUpperCase()])
              .map(({ name: status, color }) => (
                <span key={status} className={styles.filterItem}>
                  <Label
                    name={t(`status.${status}`, {
                      context: 'title',
                    })}
                    color={color}
                    size="small"
                    onClick={() => handleRemoveProductStatusClick(status)}
                  />
                </span>
              ))}
          </span>
        )}
      </>
    );
  },
);

Filters.propTypes = {
  path: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object()).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  agents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.object()).isRequired,
  productStatuses: PropTypes.arrayOf(PropTypes.object()).isRequired,
  allGroupMemberships: PropTypes.arrayOf(PropTypes.object()).isRequired,
  allCategories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  allAgents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  onUserAdd: PropTypes.func.isRequired,
  onUserRemove: PropTypes.func.isRequired,
  onCategoryAdd: PropTypes.func.isRequired,
  onCategoryRemove: PropTypes.func.isRequired,
  onAgentAdd: PropTypes.func.isRequired,
  onAgentRemove: PropTypes.func.isRequired,
  onStatusAdd: PropTypes.func.isRequired,
  onStatusRemove: PropTypes.func.isRequired,
  onProductStatusAdd: PropTypes.func.isRequired,
  onProductStatusRemove: PropTypes.func.isRequired,
};

export default Filters;
