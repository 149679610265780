import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Tab } from 'semantic-ui-react';

import ManagersPane from './ManagersPane';
import BackgroundPane from './BackgroundPane';
import CommonPane from './CommonPane';
import GeneralPane from './GeneralPane';
import EditorPane from './EditorPane';

const ProjectSettingsModal = React.memo(
  ({
    name,
    description,
    province,
    district,
    ward,
    address,
    paymentMethod,
    startDate,
    openDate,
    paymentDeposit,
    paymentConfirm1,
    paymentConfirm2,
    contract1,
    contract2,
    contract3,
    contract4,
    appendix1,
    appendix2,
    appendix3,
    appendix4,
    appendix5,
    background,
    backgroundImage,
    isBackgroundImageUpdating,
    managers,
    allUsers,
    agents,
    categories,
    accounts,
    isAutoUpdateProduct,
    isAutoUpdateSellitem,
    onUpdate,
    onBackgroundImageUpdate,
    onDelete,
    onManagerCreate,
    onManagerDelete,
    onAccountUpdate,
    onAccountCreate,
    onAccountDelete,
    onCategoryUpdate,
    onCategoryCreate,
    onCategoryDelete,
    onAgentUpdate,
    onAgentCreate,
    onAgentDelete,
    onClose,
  }) => {
    const [t] = useTranslation();
    const handleBackgroundUpdate = useCallback(
      (newBackground) => {
        onUpdate({
          background: newBackground,
        });
      },
      [onUpdate],
    );

    const handleBackgroundImageDelete = useCallback(() => {
      onUpdate({
        backgroundImage: null,
      });
    }, [onUpdate]);

    const handleEditorUpdate = useCallback(
      (data) => {
        onUpdate(data);
      },
      [onUpdate],
    );

    const panes = [
      {
        menuItem: t('common.general', {
          context: 'title',
        }),
        render: () => (
          <GeneralPane
            name={name}
            description={description}
            province={province}
            district={district}
            ward={ward}
            address={address}
            paymentMethod={paymentMethod}
            isAutoUpdateProduct={isAutoUpdateProduct}
            isAutoUpdateSellitem={isAutoUpdateSellitem}
            startDate={startDate}
            openDate={openDate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        ),
      },
      {
        menuItem: t('common.managers', {
          context: 'title',
        }),
        render: () => (
          <ManagersPane
            items={managers}
            allUsers={allUsers}
            onCreate={onManagerCreate}
            onDelete={onManagerDelete}
          />
        ),
      },
      {
        menuItem: t('common.agent', {
          context: 'title',
        }),
        render: () => (
          <CommonPane
            items={agents}
            columns={[
              { type: 'text', className: 'htCenter htMiddle', data: 'id' },
              { type: 'text', data: 'name' },
              { type: 'text', className: 'htCenter htMiddle', data: 'code' },
              { type: 'text', className: 'htCenter htMiddle', data: 'director' },
              { type: 'text', className: 'htCenter htMiddle', data: 'saler' },
              { type: 'text', className: 'htCenter htMiddle', data: 'contact1' },
              { type: 'text', className: 'htCenter htMiddle', data: 'contact2' },
            ]}
            colHeaders={[
              'ID',
              t('common.name'),
              t('common.code'),
              t('common.director'),
              t('common.saler'),
              t('common.contact1'),
              t('common.contact2'),
            ]}
            // colWidths={[0, 65, 40, 85, 85, 85, 85]}
            onUpdate={onAgentUpdate}
            onCreate={onAgentCreate}
            onDelete={onAgentDelete}
          />
        ),
      },
      {
        menuItem: t('common.category', {
          context: 'title',
        }),
        render: () => (
          <CommonPane
            items={categories}
            columns={[
              { type: 'text', data: 'id' },
              { type: 'text', data: 'name', validator: /\w+/, allowInvalid: false },
              { type: 'text', data: 'color' },
            ]}
            colHeaders={['ID', t('common.name'), t('common.color')]}
            onUpdate={onCategoryUpdate}
            onCreate={onCategoryCreate}
            onDelete={onCategoryDelete}
          />
        ),
      },
      {
        menuItem: t('common.accountPayment', {
          context: 'title',
        }),
        render: () => (
          <CommonPane
            items={accounts}
            columns={[
              { type: 'text', data: 'id' },
              { type: 'text', data: 'name', validator: /\w+/, allowInvalid: false },
              { type: 'text', data: 'description' },
              { type: 'text', data: 'accountNumber' },
              { type: 'checkbox', data: 'isClosed', className: 'htCenter htMiddle' },
            ]}
            colHeaders={[
              'ID',
              t('common.name'),
              t('common.description'),
              t('common.accountNumber'),
              t('common.isClosed'),
            ]}
            onUpdate={onAccountUpdate}
            onCreate={onAccountCreate}
            onDelete={onAccountDelete}
          />
        ),
      },
      {
        menuItem: t('common.background', {
          context: 'title',
        }),
        render: () => (
          <BackgroundPane
            item={background}
            imageCoverUrl={backgroundImage && backgroundImage.coverUrl}
            isImageUpdating={isBackgroundImageUpdating}
            onUpdate={handleBackgroundUpdate}
            onImageUpdate={onBackgroundImageUpdate}
            onImageDelete={handleBackgroundImageDelete}
          />
        ),
      },
      {
        menuItem: t('common.editor', {
          context: 'title',
        }),
        render: () => (
          <EditorPane
            paymentDeposit={paymentDeposit}
            paymentConfirm1={paymentConfirm1}
            paymentConfirm2={paymentConfirm2}
            contract1={contract1}
            contract2={contract2}
            contract3={contract3}
            contract4={contract4}
            appendix1={appendix1}
            appendix2={appendix2}
            appendix3={appendix3}
            appendix4={appendix4}
            appendix5={appendix5}
            onUpdate={handleEditorUpdate}
          />
        ),
      },
    ];

    return (
      <Modal open closeIcon closeOnEscape={false} centered={false} onClose={onClose}>
        <Modal.Content>
          <Tab
            menu={{
              secondary: true,
              pointing: true,
            }}
            panes={panes}
          />
        </Modal.Content>
      </Modal>
    );
  },
);

ProjectSettingsModal.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  province: PropTypes.string,
  district: PropTypes.string,
  ward: PropTypes.string,
  address: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  paymentMethod: PropTypes.array.isRequired,
  startDate: PropTypes.instanceOf(Date),
  openDate: PropTypes.instanceOf(Date),
  paymentDeposit: PropTypes.string.isRequired,
  paymentConfirm1: PropTypes.string.isRequired,
  paymentConfirm2: PropTypes.string.isRequired,
  contract1: PropTypes.string.isRequired,
  contract2: PropTypes.string.isRequired,
  contract3: PropTypes.string.isRequired,
  contract4: PropTypes.string.isRequired,
  appendix1: PropTypes.string.isRequired,
  appendix2: PropTypes.string.isRequired,
  appendix3: PropTypes.string.isRequired,
  appendix4: PropTypes.string.isRequired,
  appendix5: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  background: PropTypes.object,
  backgroundImage: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  isBackgroundImageUpdating: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  managers: PropTypes.array.isRequired,
  allUsers: PropTypes.array.isRequired,
  agents: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  onUpdate: PropTypes.func.isRequired,
  onBackgroundImageUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onManagerCreate: PropTypes.func.isRequired,
  onManagerDelete: PropTypes.func.isRequired,
  onAccountUpdate: PropTypes.func.isRequired,
  onAccountCreate: PropTypes.func.isRequired,
  onAccountDelete: PropTypes.func.isRequired,
  onCategoryUpdate: PropTypes.func.isRequired,
  onCategoryCreate: PropTypes.func.isRequired,
  onCategoryDelete: PropTypes.func.isRequired,
  onAgentUpdate: PropTypes.func.isRequired,
  onAgentCreate: PropTypes.func.isRequired,
  onAgentDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isAutoUpdateProduct: PropTypes.bool,
  isAutoUpdateSellitem: PropTypes.bool,
};

ProjectSettingsModal.defaultProps = {
  description: '',
  province: '',
  district: '',
  ward: '',
  address: '',
  startDate: undefined,
  openDate: undefined,
  background: undefined,
  backgroundImage: undefined,
  isAutoUpdateProduct: false,
  isAutoUpdateSellitem: false,
};

export default ProjectSettingsModal;
