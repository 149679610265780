/* eslint-disable no-unused-vars */
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import { HotTable, HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';
import { exportXlsx, exportLand } from './export';
// import { HyperFormula } from 'hyperformula';

import Summary from './Summary';
import MenusPopup from '../Customers/MenusPopup';

import SelectEditor from '../HotTable/SelectEditor';
import NumberEditor from '../HotTable/NumberEditor';
import { ProductStatuses } from '../../constants/Enums';

import styles from './ProductTable.module.scss';
import globalStyles from '../../styles.module.scss';

const undoQ = [];
const redoQ = [];

const ProductTable = React.memo(
  ({
    products,
    agents,
    blocks,
    categories,
    currentBag,
    showRightSidebar,
    canEdit,
    onDeleteBagitem,
    onUpdateProduct,
    onUpdateBagitem,
  }) => {
    const [t] = useTranslation();

    const hotTableRef = useRef();
    const summaryRef = useRef();

    const blockOptions = useMemo(
      () =>
        blocks.map(({ name }) => ({
          label: name,
          value: name,
        })),
      [blocks],
    );

    const agentOptions = useMemo(
      () =>
        agents.map(({ name }) => ({
          label: name,
          value: name,
        })),
      [agents],
    );

    const colHeaders = useMemo(
      () => [
        // 'id',
        t('common.blocks'),
        t('common.code'),
        t('common.status', { context: 'header' }),
        t('common.landNumber', { context: 'header' }),
        t('common.landDescription', { context: 'header' }),
        t('common.mapNumber', { context: 'header' }),
        t('common.houseForm', { context: 'header' }),
        t('common.certificate', { context: 'header' }),
        t('common.street'),
        t('common.direction'),
        t('common.density', { context: 'header' }),
        t('common.landArea', { context: 'header' }),
        t('common.floorArea', { context: 'header' }),
        t('common.landPrice', { context: 'header' }),
        t('common.floorPrice', { context: 'header' }),
        t('common.landTotal', { context: 'header' }),
        t('common.floorTotal', { context: 'header' }),
        t('common.factor'),
        t('common.total'),
        t('common.properties'),
        t('common.agent'),
      ],
      [t],
    );

    const categoryName = (currentBag.category && currentBag.category.name.toLowerCase()) || '';

    const handleRedo = useCallback(() => {
      const hotInstance = redoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = products.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'redo');
      }
    }, [products]);

    const handleUndo = useCallback(() => {
      const hotInstance = undoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = products.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'undo');
      }
    }, [products]);

    const handleExport = useCallback(() => {
      if (categoryName === 'shophouse') {
        const shophouseHeaders = [
          'STT',
          t('common.code'),
          t('common.landNumber'),
          t('common.houseForm'),
          t('common.certificate'),
          t('common.street'),
          t('common.direction'),
          t('common.density'),
          t('common.landArea'),
          t('common.floorArea'),
          t('common.landPrice'),
          t('common.floorPrice'),
          t('common.landTotal'),
          t('common.floorTotal'),
          t('common.factor'),
          t('common.total'),
          t('common.properties'),
          t('common.agent'),
        ];
        exportXlsx(
          products,
          shophouseHeaders,
          currentBag || { name: 'TiciLand', startDate: new Date() },
        );
      } else {
        const landHeaders = [
          'STT',
          t('common.code'),
          t('common.landNumber'),
          t('common.landDescription'),
          t('common.mapNumber'),
          t('common.houseForm'),
          t('common.certificate'),
          t('common.street'),
          t('common.direction'),
          t('common.density'),
          t('common.landArea'),
          t('common.floorArea'),
          t('common.landPrice'),
          t('common.floorPrice'),
          t('common.landTotal'),
          t('common.floorTotal'),
          t('common.factor'),
          t('common.total'),
          t('common.properties'),
          t('common.agent'),
          t('common.note'),
        ];
        exportLand(
          products,
          landHeaders,
          currentBag || { name: 'TiciLand', startDate: new Date() },
        );
      }

      // const exportPlugin = hotTableRef.current.hotInstance.getPlugin('exportFile');
      // exportPlugin.downloadFile('csv', {
      //   columnHeaders: true,
      //   filename: 'TiciLand [YYYY]-[MM]-[DD]',
      // });
    }, [categoryName, currentBag, products, t]);

    const afterChange = useCallback(
      (changes, source) => {
        const sourceChange = ['edit', 'CopyPaste.paste', 'Autofill.fill', 'undo', 'redo'];
        if (sourceChange.indexOf(source) > -1 && changes.length > 0) {
          changes.forEach(([row, prop, oldValue, newValue]) => {
            if ((oldValue === null || oldValue === undefined) && newValue === '') return;

            // eslint-disable-next-line eqeqeq
            if (oldValue != newValue) {
              let newData;
              const { id, bagitemId } = products[row];

              switch (prop) {
                case 'blockName':
                  newData = {
                    blockId: blocks.find(({ name }) => name === newValue)?.id || undefined,
                  };
                  onUpdateProduct(id, newData);
                  break;
                case 'agentName':
                  newData = {
                    agentId: agents.find(({ name }) => name === newValue)?.id || undefined,
                  };
                  if (currentBag) {
                    onUpdateBagitem(bagitemId, newData);
                  } else {
                    onUpdateProduct(id, newData);
                  }
                  break;
                // case 'statusLabel':
                //   newData = {
                //     status:
                //       Object.values(ProductStatuses).find(({ label }) => label === newValue)
                //         ?.name || null,
                //   };
                //   onUpdateProduct(id, newData);
                //   break;
                case 'landPrice':
                case 'floorPrice':
                case 'factor':
                  newData = {
                    [prop]: newValue || '0',
                  };
                  if (currentBag) {
                    onUpdateBagitem(bagitemId, newData);
                  } else {
                    onUpdateProduct(id, newData);
                  }
                  break;
                default:
                  newData = {
                    [prop]: newValue || undefined,
                  };
                  onUpdateProduct(id, newData);
                  break;
              }
              if (source === 'undo') {
                if (redoQ.length > 9) redoQ.shift();
                redoQ.push([id, prop, oldValue]);
              } else {
                if (source !== 'redo') redoQ.length = 0;
                if (undoQ.length > 9) undoQ.shift();
                undoQ.push([id, prop, oldValue]);
              }
            }
          });
        }
      },
      [agents, blocks, currentBag, onUpdateBagitem, onUpdateProduct, products],
    );

    const afterSelection = useCallback(() => {
      const selected = hotTableRef.current.hotInstance.getSelected();
      if (
        !(
          selected.length === 1 &&
          selected[0][0] === selected[0][2] &&
          selected[0][1] === selected[0][3]
        )
      ) {
        const data = [];
        selected.forEach((it) => {
          data.push(hotTableRef.current.hotInstance.getData(...it).flat());
        });
        if (summaryRef.current) summaryRef.current.show(data);
      } else if (summaryRef.current) {
        // const data = hotTableRef.current.hotInstance.getDataAtCell(selected[0][0], selected[0][1]);
        // summaryRef.current.show([[data]]);
        summaryRef.current.show([]);
      }
    }, []);

    const afterDeselect = () => {
      if (summaryRef.current) summaryRef.current.show([]);
    };

    const afterGetColHeader = (col, TH) => {
      if (col === 8 || col === 15) {
        const button = TH.querySelector('.changeType');

        if (!button) {
          return;
        }

        button.parentElement.removeChild(button);
      }
    };

    const beforeRemoveRow = (row, count, physicalRows) => {
      physicalRows.forEach((r) => {
        const { bagitemId, status } = products[r];
        if (status === ProductStatuses.SELLING.name) {
          onDeleteBagitem(bagitemId);
          // return true;
        }
      });
      return false;
    };

    const beforeKeyDown = useCallback(
      (e) => {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          // e.preventDefault();
          e.stopImmediatePropagation();
          return;
        }
        if (e.ctrlKey || e.metaKey) {
          if (e.keyCode === 90) {
            if (e.shiftKey) {
              handleRedo();
            } else {
              handleUndo();
            }
          }
        }
      },
      [handleRedo, handleUndo],
    );

    const statusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
      /* eslint-disable no-param-reassign */
      if (value) {
        const status = Object.values(ProductStatuses).find(({ label }) => label === value) || {
          name: '-',
          color: 'red',
        };
        // td.innerText = t(`status.${status.name}`);
        td.style.color = '#fff';
        td.classList.add(globalStyles[`background${upperFirst(camelCase(status.color))}`]);
      }
      /* eslint-enable no-param-reassign */
      return td;
    };

    const percentRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        `${value * 1}%`,
        cellProperties,
      ]);
      return td;
    };

    const priceRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.renderers.NumericRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
      /* eslint-disable no-param-reassign */
      td.style.backgroundColor = '#fff2cc';
      /* eslint-enable no-param-reassign */
      return td;
    };

    // const agentRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    //   Handsontable.renderers.NumericRenderer.apply(this, [
    //     instance,
    //     td,
    //     row,
    //     col,
    //     prop,
    //     agents.find(({ id }) => id === value)?.name,
    //     cellProperties,
    //   ]);
    //   return td;
    // };

    // const [, setValue] = useState(0);
    useEffect(() => {
      undoQ.length = 0;
      redoQ.length = 0;
      // setValue(1);
    }, []);

    useEffect(() => {
      hotTableRef.current.hotInstance.refreshDimensions();
    }, [showRightSidebar]);

    return (
      <div id="hot-app" className={styles.wrapper}>
        <HotTable
          ref={hotTableRef}
          data={products}
          colHeaders={colHeaders}
          readOnly={!canEdit}
          hiddenColumns={categoryName !== 'shophouse' && { columns: [4, 5] }}
          height="100%"
          stretchH="all"
          autoColumnSize={false}
          autoRowSize={false}
          manualColumnResize
          rowHeaders
          // rowHeights={23}
          contextMenu={{
            items: {
              undo: {
                name: `${t('action.undo')}<span style="color:gray;float:right;">⌘Z</span>`,
                callback() {
                  handleUndo();
                },
                disabled: () => undoQ.length < 1,
              },
              redo: {
                name: `${t('action.redo')}<span style="color:gray;float:right;">⌘⇧Z</span>`,
                callback() {
                  handleRedo();
                },
                disabled: () => redoQ.length < 1,
              },
              separator: '---------',
              copy: { name: `${t('action.copy')}<span style="color:gray;float:right;">⌘C</span>` },
              separator2: '---------',
              remove_row: {
                name: `${t('action.removeFromBag')}`,
                disabled() {
                  return (
                    // eslint-disable-next-line react/no-this-in-sfc
                    products[this.getSelectedLast()[0]]?.status !== ProductStatuses.SELLING.name
                  );
                },
                // callback() {
                //   handleRemoveFromBag();
                // },
              },
            },
          }}
          enterMoves={{ col: 1, row: 0 }}
          dropdownMenu={['filter_by_value', 'filter_action_bar']}
          filters
          afterChange={afterChange}
          afterGetColHeader={afterGetColHeader}
          afterSelection={afterSelection}
          afterDeselect={afterDeselect}
          beforeKeyDown={beforeKeyDown}
          beforeRemoveRow={beforeRemoveRow}
          licenseKey="non-commercial-and-evaluation"
        >
          {/* <HotColumn width={0} data="id" /> */}
          <HotColumn
            width={64}
            data="blockName"
            // renderer={blockRenderer}
            editor={SelectEditor}
            options={blockOptions}
          />
          <HotColumn width={70} data="name" wordWrap={false} />
          <HotColumn
            width={70}
            data="statusLabel"
            wordWrap={false}
            renderer={statusRenderer}
            // editor={SelectEditor}
            // options={Object.values(ProductStatuses).map(({ name, hex }) => ({
            //   label: t(`status.${name}`),
            //   value: name,
            //   color: hex,
            // }))}
            readOnly
          />
          <HotColumn width={60} data="landNumber" />
          <HotColumn width={120} data="landDescription" wordWrap={false} />
          <HotColumn width={60} data="mapNumber" wordWrap={false} />
          <HotColumn width={60} data="houseForm" wordWrap={false} />
          <HotColumn width={80} data="certificate" wordWrap={false} />
          <HotColumn width={70} data="street" wordWrap={false} />
          <HotColumn width={70} data="direction" wordWrap={false} />
          <HotColumn width={50} data="density" className="htRight" renderer={percentRenderer} />
          <HotColumn
            width={55}
            data="landArea"
            type="numeric"
            numericFormat={{ pattern: '0,0.00' }}
          />
          <HotColumn
            width={55}
            data="floorArea"
            type="numeric"
            numericFormat={{ pattern: '0,0.00' }}
          />
          <HotColumn
            width={90}
            data="landPrice"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            renderer={priceRenderer}
            editor={NumberEditor}
          />
          <HotColumn
            width={90}
            data="floorPrice"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            renderer={priceRenderer}
            editor={NumberEditor}
          />
          <HotColumn
            width={100}
            data="landTotal"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn
            width={100}
            data="floorTotal"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn width={40} data="factor" type="numeric" numericFormat={{ pattern: '0,.00' }} />
          <HotColumn
            width={100}
            data="finalTotal"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn width={100} data="description" />
          <HotColumn
            width={75}
            data="agentName"
            wordWrap={false}
            // renderer={agentRenderer}
            editor={SelectEditor}
            options={agentOptions}
          />
          {/* <HotColumn
            width={90}
            data="dueDate"
            editor={DatePickerEditor}
            renderer={dueDateRenderer}
          /> */}
        </HotTable>
        <div>
          <Summary ref={summaryRef} />
          <Button.Group className={styles.actionButtons}>
            <MenusPopup
              category={currentBag.category || { name: '' }}
              closeIcon={false}
              canExport={canEdit && products.length > 0}
              canImport={canEdit}
              onExport={handleExport}
            >
              <Button size="mini" icon="bars" />
            </MenusPopup>
            {/* <Button icon className={styles.print} onClick={handlePrint}>
              <Icon name="print" /> {t('action.print')}
            </Button> */}
            <Button
              className={styles.buttonTableActionUndo}
              size="mini"
              content={t('action.undo')}
              icon="undo"
              labelPosition="left"
              onClick={handleUndo}
              disabled={undoQ.length < 1}
            />
            <Button
              className={styles.buttonTableActionRedo}
              size="mini"
              content={t('action.redo')}
              icon="redo"
              labelPosition="right"
              onClick={handleRedo}
              disabled={redoQ.length < 1}
            />
          </Button.Group>
        </div>
      </div>
    );
  },
);

ProductTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object()).isRequired,
  agents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  blocks: PropTypes.arrayOf(PropTypes.object()).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  currentBag: PropTypes.objectOf(PropTypes.shape),
  showRightSidebar: PropTypes.bool,
  canEdit: PropTypes.bool.isRequired,
  onDeleteBagitem: PropTypes.func.isRequired,
  onUpdateProduct: PropTypes.func.isRequired,
  onUpdateBagitem: PropTypes.func.isRequired,
};

ProductTable.defaultProps = {
  currentBag: undefined,
  showRightSidebar: false,
};

export default ProductTable;
