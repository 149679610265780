const USERS = 'USERS';
const USER_SETTINGS = 'USER_SETTINGS';
const PROJECT_ADD = 'PROJECT_ADD';
const PROJECT_SETTINGS = 'PROJECT_SETTINGS';
const PRODUCT_ADD = 'PRODUCT_ADD';
const BAGITEM_DETAIL = 'BAGITEM_DETAIL';

export default {
  USERS,
  USER_SETTINGS,
  PROJECT_ADD,
  PROJECT_SETTINGS,
  PRODUCT_ADD,
  BAGITEM_DETAIL,
};
