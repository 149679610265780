import { takeEvery, all } from 'redux-saga/effects';

import { searchCustomersInCurrentGroupService } from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* customersWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CUSTOMERS_SEARCH, ({ payload: { query } }) =>
      searchCustomersInCurrentGroupService(query),
    ),
  ]);
}
