import socket from './socket';

/* Actions */

const createGroupMembership = (groupId, data, headers) =>
  socket.post(`/groups/${groupId}/memberships`, data, headers);

const deleteGroupMembership = (id, headers) =>
  socket.delete(`/group-memberships/${id}`, undefined, headers);

export default {
  createGroupMembership,
  deleteGroupMembership,
};
