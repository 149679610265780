import { all, takeEvery } from 'redux-saga/effects';

import {
  createBagInCurrentGroupService,
  handleBagCreateService,
  handleBagUpdateService,
  updateBagService,
  recalculateCommissionService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* bagWatchers() {
  yield all([
    takeEvery(EntryActionTypes.BAG_CREATE, ({ payload: { data } }) =>
      createBagInCurrentGroupService(data),
    ),
    takeEvery(EntryActionTypes.BAG_CREATE_HANDLE, ({ payload: { bag } }) =>
      handleBagCreateService(bag),
    ),
    takeEvery(EntryActionTypes.BAG_UPDATE, ({ payload: { id, data } }) =>
      updateBagService(id, data),
    ),
    takeEvery(EntryActionTypes.BAG_UPDATE_HANDLE, ({ payload: { bag } }) =>
      handleBagUpdateService(bag),
    ),
    takeEvery(EntryActionTypes.BAG_RECALCULATE_COMMISSION, ({ payload: { id } }) =>
      recalculateCommissionService(id),
    ),
  ]);
}
