import ActionTypes from '../constants/ActionTypes';

export const createUser = (data) => ({
  type: ActionTypes.USER_CREATE,
  payload: {
    data,
  },
});

createUser.success = (user) => ({
  type: ActionTypes.USER_CREATE__SUCCESS,
  payload: {
    user,
  },
});

createUser.failure = (error) => ({
  type: ActionTypes.USER_CREATE__FAILURE,
  payload: {
    error,
  },
});

export const handleUserCreate = (user) => ({
  type: ActionTypes.USER_CREATE_HANDLE,
  payload: {
    user,
  },
});

export const clearUserCreateError = () => ({
  type: ActionTypes.USER_CREATE_ERROR_CLEAR,
  payload: {},
});

export const updateUser = (id, data) => ({
  type: ActionTypes.USER_UPDATE,
  payload: {
    id,
    data,
  },
});

updateUser.success = (user) => ({
  type: ActionTypes.USER_UPDATE__SUCCESS,
  payload: {
    user,
  },
});

updateUser.failure = (id, error) => ({
  type: ActionTypes.USER_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleUserUpdate = (user, users, isCurrent) => ({
  type: ActionTypes.USER_UPDATE_HANDLE,
  payload: {
    user,
    users,
    isCurrent,
  },
});

export const updateUserEmail = (id, data) => ({
  type: ActionTypes.USER_EMAIL_UPDATE,
  payload: {
    id,
    data,
  },
});

updateUserEmail.success = (user) => ({
  type: ActionTypes.USER_EMAIL_UPDATE__SUCCESS,
  payload: {
    user,
  },
});

updateUserEmail.failure = (id, error) => ({
  type: ActionTypes.USER_EMAIL_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const clearUserEmailUpdateError = (id) => ({
  type: ActionTypes.USER_EMAIL_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

export const updateUserPassword = (id, data) => ({
  type: ActionTypes.USER_PASSWORD_UPDATE,
  payload: {
    id,
    data,
  },
});

updateUserPassword.success = (user) => ({
  type: ActionTypes.USER_PASSWORD_UPDATE__SUCCESS,
  payload: {
    user,
  },
});

updateUserPassword.failure = (id, error) => ({
  type: ActionTypes.USER_PASSWORD_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const clearUserPasswordUpdateError = (id) => ({
  type: ActionTypes.USER_PASSWORD_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

export const updateUserUsername = (id, data) => ({
  type: ActionTypes.USER_USERNAME_UPDATE,
  payload: {
    id,
    data,
  },
});

updateUserUsername.success = (user) => ({
  type: ActionTypes.USER_USERNAME_UPDATE__SUCCESS,
  payload: {
    user,
  },
});

updateUserUsername.failure = (id, error) => ({
  type: ActionTypes.USER_USERNAME_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const clearUserUsernameUpdateError = (id) => ({
  type: ActionTypes.USER_USERNAME_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

export const updateUserAvatar = (id) => ({
  type: ActionTypes.USER_AVATAR_UPDATE,
  payload: {
    id,
  },
});

updateUserAvatar.success = (user) => ({
  type: ActionTypes.USER_AVATAR_UPDATE__SUCCESS,
  payload: {
    user,
  },
});

updateUserAvatar.failure = (id, error) => ({
  type: ActionTypes.USER_AVATAR_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const deleteUser = (id) => ({
  type: ActionTypes.USER_DELETE,
  payload: {
    id,
  },
});

deleteUser.success = (user) => ({
  type: ActionTypes.USER_DELETE__SUCCESS,
  payload: {
    user,
  },
});

deleteUser.failure = (id, error) => ({
  type: ActionTypes.USER_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleUserDelete = (user) => ({
  type: ActionTypes.USER_DELETE_HANDLE,
  payload: {
    user,
  },
});

export const addUserToSellitem = (id, sellitemId, isCurrent) => ({
  type: ActionTypes.USER_TO_SELLITEM_ADD,
  payload: {
    id,
    sellitemId,
    isCurrent,
  },
});

addUserToSellitem.success = (sellitemMembership) => ({
  type: ActionTypes.USER_TO_SELLITEM_ADD__SUCCESS,
  payload: {
    sellitemMembership,
  },
});

addUserToSellitem.failure = (id, sellitemId, error) => ({
  type: ActionTypes.USER_TO_SELLITEM_ADD__FAILURE,
  payload: {
    id,
    sellitemId,
    error,
  },
});

export const handleUserToSellitemAdd = (sellitemMembership) => ({
  type: ActionTypes.USER_TO_SELLITEM_ADD_HANDLE,
  payload: {
    sellitemMembership,
  },
});

export const removeUserFromSellitem = (id, sellitemId) => ({
  type: ActionTypes.USER_FROM_SELLITEM_REMOVE,
  payload: {
    id,
    sellitemId,
  },
});

removeUserFromSellitem.success = (sellitemMembership) => ({
  type: ActionTypes.USER_FROM_SELLITEM_REMOVE__SUCCESS,
  payload: {
    sellitemMembership,
  },
});

removeUserFromSellitem.failure = (id, sellitemId, error) => ({
  type: ActionTypes.USER_FROM_SELLITEM_REMOVE__FAILURE,
  payload: {
    id,
    sellitemId,
    error,
  },
});

export const handleUserFromSellitemRemove = (sellitemMembership) => ({
  type: ActionTypes.USER_FROM_SELLITEM_REMOVE_HANDLE,
  payload: {
    sellitemMembership,
  },
});

export const addUserToGroupFilter = (id, groupId) => ({
  type: ActionTypes.USER_TO_GROUP_FILTER_ADD,
  payload: {
    id,
    groupId,
  },
});

export const removeUserFromGroupFilter = (id, groupId) => ({
  type: ActionTypes.USER_FROM_GROUP_FILTER_REMOVE,
  payload: {
    id,
    groupId,
  },
});
