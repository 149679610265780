import { all, takeEvery } from 'redux-saga/effects';

import {
  createSellitemCustomerService,
  deleteSellitemCustomerService,
  handleSellitemCustomerCreateService,
  handleSellitemCustomerDeleteService,
  updateSellitemCustomerService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* customerWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CUSTOMER_TO_SELLITEM_ADD, ({ payload: { data } }) =>
      createSellitemCustomerService(data),
    ),
    takeEvery(
      EntryActionTypes.CUSTOMER_TO_SELLITEM_ADD_HANDLE,
      ({ payload: { sellitemCustomer, customers } }) =>
        handleSellitemCustomerCreateService(sellitemCustomer, customers),
    ),
    takeEvery(
      EntryActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE,
      ({ payload: { sellitemId, customerId } }) =>
        deleteSellitemCustomerService(sellitemId, customerId),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE_HANDLE, ({ payload: { customer } }) =>
      handleSellitemCustomerDeleteService(customer),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_TO_SELLITEM_UPDATE, ({ payload: { data } }) =>
      updateSellitemCustomerService(data),
    ),
  ]);
}
