import ActionTypes from '../constants/ActionTypes';

export const createCategory = (category) => ({
  type: ActionTypes.CATEGORY_CREATE,
  payload: {
    category,
  },
});

createCategory.success = (localId, category) => ({
  type: ActionTypes.CATEGORY_CREATE__SUCCESS,
  payload: {
    localId,
    category,
  },
});

createCategory.failure = (localId, error) => ({
  type: ActionTypes.CATEGORY_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleCategoryCreate = (category) => ({
  type: ActionTypes.CATEGORY_CREATE_HANDLE,
  payload: {
    category,
  },
});

export const updateCategory = (id, data) => ({
  type: ActionTypes.CATEGORY_UPDATE,
  payload: {
    id,
    data,
  },
});

updateCategory.success = (category) => ({
  type: ActionTypes.CATEGORY_UPDATE__SUCCESS,
  payload: {
    category,
  },
});

updateCategory.failure = (id, error) => ({
  type: ActionTypes.CATEGORY_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleCategoryUpdate = (category) => ({
  type: ActionTypes.CATEGORY_UPDATE_HANDLE,
  payload: {
    category,
  },
});

export const deleteCategory = (id) => ({
  type: ActionTypes.CATEGORY_DELETE,
  payload: {
    id,
  },
});

deleteCategory.success = (category) => ({
  type: ActionTypes.CATEGORY_DELETE__SUCCESS,
  payload: {
    category,
  },
});

deleteCategory.failure = (id, error) => ({
  type: ActionTypes.CATEGORY_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleCategoryDelete = (category) => ({
  type: ActionTypes.CATEGORY_DELETE_HANDLE,
  payload: {
    category,
  },
});

export const addCategoryToGroupFilter = (id, groupId) => ({
  type: ActionTypes.CATEGORY_TO_GROUP_FILTER_ADD,
  payload: {
    id,
    groupId,
  },
});

export const removeCategoryFromGroupFilter = (id, groupId) => ({
  type: ActionTypes.CATEGORY_FROM_GROUP_FILTER_REMOVE,
  payload: {
    id,
    groupId,
  },
});
