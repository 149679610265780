import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { pathSelector, productByIdSelector } from '../selectors';
import { clearUserCreateError, createSellitem, searchCustomers } from '../actions/entry';
import AddOrder from '../components/Products/AddOrder';

const mapStateToProps = (state) => {
  const { data: defaultData, isSubmitting, error } = state.ui.sellitemCreateForm;
  const { customers } = state;
  const { productId } = pathSelector(state);
  let product;
  if (productId) {
    product = productByIdSelector(state, productId);
  }
  return {
    defaultData,
    isSubmitting,
    error,
    product,
    searchResults: customers || { isSubmitting: false, customers: [], query: '' },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSearchCustomers: searchCustomers,
      onCreateSellitem: (data) => createSellitem(data.customerId, data),
      onMessageDismiss: clearUserCreateError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddOrder);
