import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HotTable, HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';
import LinkRenderer from '../HotTable/LinkRenderer';
import Summary from './Summary';
import { Statuses } from '../../constants/Enums';

import styles from './CustomerTable.module.scss';
import globalStyles from '../../styles.module.scss';

const undoQ = [];
const redoQ = [];

const CustomerTable = React.memo(({ sellitems }) => {
  const [t] = useTranslation();

  const hotTableRef = useRef();
  const summaryRef = useRef();

  const colHeaders = useMemo(
    () => [
      t('common.products'),
      t('common.status'),
      t('common.accountants.ownAgent'),
      t('common.accountants.agent'),
      t('common.accountants.landArea_title'),
      t('common.accountants.floorArea_title'),
      t('common.accountants.totalFloor_title'),
      t('common.accountants.totalAmount_title'),
      t('common.accountants.totalDiscount'),
      t('common.accountants.totalPaid'),
      t('common.accountants.remain'),
    ],
    [t],
  );

  const afterSelection = useCallback(() => {
    const selected = hotTableRef.current.hotInstance.getSelected();
    if (
      !(
        selected.length === 1 &&
        selected[0][0] === selected[0][2] &&
        selected[0][1] === selected[0][3]
      )
    ) {
      const data = [];
      selected.forEach((it) => {
        data.push(hotTableRef.current.hotInstance.getData(...it).flat());
      });
      if (summaryRef.current) summaryRef.current.show(data);
    } else if (summaryRef.current) {
      // const data = hotTableRef.current.hotInstance.getDataAtCell(selected[0][0], selected[0][1]);
      // summaryRef.current.show([[data]]);
      summaryRef.current.show([]);
    }
  }, []);

  const afterDeselect = () => {
    if (summaryRef.current) summaryRef.current.show([]);
  };

  const afterGetColHeader = (col, TH) => {
    if (col === 4 || col === 10) {
      const button = TH.querySelector('.changeType');

      if (!button) {
        return;
      }

      button.parentElement.removeChild(button);
    }
  };

  const handleRedo = useCallback(() => {
    const hotInstance = redoQ.pop();
    if (hotInstance && hotInstance.length > 0) {
      hotInstance[0] = sellitems.findIndex((item) => item.id === hotInstance[0]);
      hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
      hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'redo');
    }
  }, [sellitems]);

  const handleUndo = useCallback(() => {
    const hotInstance = undoQ.pop();
    if (hotInstance && hotInstance.length > 0) {
      hotInstance[0] = sellitems.findIndex((item) => item.id === hotInstance[0]);
      hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
      hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'undo');
    }
  }, [sellitems]);

  const beforeKeyDown = useCallback(
    (e) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        // e.preventDefault();
        e.stopImmediatePropagation();
        return;
      }
      if (e.ctrlKey || e.metaKey) {
        if (e.keyCode === 90) {
          if (e.shiftKey) {
            handleRedo();
          } else {
            handleUndo();
          }
        }
      }
    },
    [handleRedo, handleUndo],
  );

  const statusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    Handsontable.renderers.TextRenderer.apply(this, [
      instance,
      td,
      row,
      col,
      prop,
      value,
      cellProperties,
    ]);
    /* eslint-disable no-param-reassign */
    if (value) {
      const status = Object.values(Statuses).find(({ label }) => label === value) || {
        name: '-',
        color: 'red',
      };
      td.style.color = '#fff';
      td.classList.add(globalStyles[`background${upperFirst(camelCase(status.color))}`]);
    }
    return td;
  };

  // const [, setValue] = useState(0);
  useEffect(() => {
    undoQ.length = 0;
    redoQ.length = 0;
    // setValue(1);
  }, []);

  return (
    <div id="hot-app" className={styles.wrapper}>
      <HotTable
        ref={hotTableRef}
        data={sellitems}
        colHeaders={colHeaders}
        height="100%"
        stretchH="all"
        autoColumnSize={false}
        autoRowSize={false}
        manualColumnResize
        rowHeaders={false}
        rowHeights={34}
        enterMoves={{ col: 1, row: 0 }}
        dropdownMenu={['filter_by_value', 'filter_action_bar']}
        filters
        afterGetColHeader={afterGetColHeader}
        afterSelection={afterSelection}
        afterDeselect={afterDeselect}
        beforeKeyDown={beforeKeyDown}
        licenseKey="non-commercial-and-evaluation"
      >
        {/* <HotColumn width={0} data="id" /> */}
        <HotColumn width={70} data="product.name" readOnly>
          <LinkRenderer hot-renderer />
        </HotColumn>
        <HotColumn
          width={90}
          data="statusLabel"
          wordWrap={false}
          renderer={statusRenderer}
          readOnly
        />
        <HotColumn width={85} data="agent.name" readOnly />
        <HotColumn width={85} data="agentSelledName" readOnly />
        <HotColumn
          width={60}
          data="product.landArea"
          type="numeric"
          numericFormat={{ pattern: '0,0.00' }}
          readOnly
        />
        <HotColumn
          width={60}
          data="product.floorArea"
          type="numeric"
          numericFormat={{ pattern: '0,0.00' }}
          readOnly
        />
        <HotColumn
          width={90}
          data="totalFloorPrice"
          type="numeric"
          numericFormat={{ pattern: '0,0' }}
          readOnly
        />
        <HotColumn
          width={100}
          data="sellPrice"
          type="numeric"
          numericFormat={{ pattern: '0,0' }}
          readOnly
        />
        <HotColumn
          width={90}
          data="totalDiscount"
          type="numeric"
          numericFormat={{ pattern: '0,0' }}
          readOnly
        />
        <HotColumn
          width={90}
          data="totalPaid"
          type="numeric"
          numericFormat={{ pattern: '0,0' }}
          readOnly
        />
        <HotColumn
          width={90}
          data="remain"
          type="numeric"
          numericFormat={{ pattern: '0,0' }}
          readOnly
        />
      </HotTable>
      <div>
        <Summary ref={summaryRef} />
      </div>
    </div>
  );
});

CustomerTable.propTypes = {
  sellitems: PropTypes.arrayOf(PropTypes.object()).isRequired,
};

export default CustomerTable;
