import socket from './socket';
import { transformBag } from './bags';
import { transformSellitem } from './sellitems';
import { transformAttachment } from './attachments';

/* Actions */

const createGroup = (projectId, data, headers) =>
  socket.post(`/projects/${projectId}/groups`, data, headers);

const getGroup = (id, headers) =>
  socket.get(`/groups/${id}`, undefined, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      bags: body.included.bags.map(transformBag),
      sellitems: body.included.sellitems.map(transformSellitem),
      attachments: body.included.attachments.map(transformAttachment),
    },
  }));

const updateGroup = (id, data, headers) => socket.patch(`/groups/${id}`, data, headers);

const deleteGroup = (id, headers) => socket.delete(`/groups/${id}`, undefined, headers);

export default {
  createGroup,
  getGroup,
  updateGroup,
  deleteGroup,
};
