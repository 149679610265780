import { call, put, select } from 'redux-saga/effects';

import { goToProjectService } from './router';
import request from '../request';
import { blockByIdSelector, nextBlockPositionSelector, pathSelector } from '../../../selectors';
import {
  createBlock,
  deleteBlock,
  handleBlockCreate,
  handleBlockDelete,
  handleBlockUpdate,
  updateBlock,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createBlockservice(groupId, data) {
  const nextData = {
    ...data,
    position: yield select(nextBlockPositionSelector, groupId),
  };

  const localId = yield call(createLocalId);

  yield put(
    createBlock({
      ...nextData,
      groupId,
      id: localId,
    }),
  );

  let block;

  try {
    ({ item: block } = yield call(request, api.createBlock, groupId, nextData));
  } catch (error) {
    yield put(createBlock.failure(localId, error));
    return;
  }

  yield put(createBlock.success(localId, block));
}

export function* createBlockInCurrentGroupService(data) {
  const { groupId } = yield select(pathSelector);

  yield call(createBlockservice, groupId, data);
}

export function* handleBlockCreateService(block) {
  yield put(handleBlockCreate(block));
}

export function* updateBlockservice(id, data) {
  yield put(updateBlock(id, data));

  let block;
  try {
    ({ item: block } = yield call(request, api.updateBlock, id, data));
  } catch (error) {
    yield put(updateBlock.failure(id, error));
    return;
  }

  yield put(updateBlock.success(block));
}

export function* handleBlockUpdateService(block) {
  yield put(handleBlockUpdate(block));
}

export function* deleteBlockservice(id) {
  const { blockId, projectId } = yield select(pathSelector);

  if (id === blockId) {
    yield call(goToProjectService, projectId);
  }

  yield put(deleteBlock(id));

  let block;
  try {
    ({ item: block } = yield call(request, api.deleteBlock, id));
  } catch (error) {
    yield put(deleteBlock.failure(id, error));
    return;
  }

  yield put(deleteBlock.success(block));
}

export function* updateBlockService(id, data) {
  yield put(updateBlock(id, data));

  let customer;
  try {
    ({ item: customer } = yield call(request, api.updateBlock, id, data));
  } catch (error) {
    yield put(updateBlock.failure(id, error));
    return;
  }

  yield put(updateBlock.success(customer));
}

export function* moveBlockService(id, index) {
  const { groupId } = yield select(blockByIdSelector, id);
  const position = yield select(nextBlockPositionSelector, groupId, index, id);

  yield call(updateBlockService, id, {
    position,
  });
}

export function* handleBlockDeleteService(block) {
  const { blockId, projectId } = yield select(pathSelector);

  if (block.id === blockId) {
    yield call(goToProjectService, projectId);
  }

  yield put(handleBlockDelete(block));
}
