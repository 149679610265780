import ActionTypes from '../constants/ActionTypes';

export const createPayment = (payment) => ({
  type: ActionTypes.PAYMENT_CREATE,
  payload: {
    payment,
  },
});

createPayment.success = (localId, payment) => ({
  type: ActionTypes.PAYMENT_CREATE__SUCCESS,
  payload: {
    localId,
    payment,
  },
});

createPayment.failure = (localId, error) => ({
  type: ActionTypes.PAYMENT_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handlePaymentCreate = (payment) => ({
  type: ActionTypes.PAYMENT_CREATE_HANDLE,
  payload: {
    payment,
  },
});

export const updatePayment = (id, data) => ({
  type: ActionTypes.PAYMENT_UPDATE,
  payload: {
    id,
    data,
  },
});

updatePayment.success = (payment) => ({
  type: ActionTypes.PAYMENT_UPDATE__SUCCESS,
  payload: {
    payment,
  },
});

updatePayment.failure = (id, error) => ({
  type: ActionTypes.PAYMENT_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handlePaymentUpdate = (payment) => ({
  type: ActionTypes.PAYMENT_UPDATE_HANDLE,
  payload: {
    payment,
  },
});

export const deletePayment = (id) => ({
  type: ActionTypes.PAYMENT_DELETE,
  payload: {
    id,
  },
});

deletePayment.success = (payment) => ({
  type: ActionTypes.PAYMENT_DELETE__SUCCESS,
  payload: {
    payment,
  },
});

deletePayment.failure = (id, error) => ({
  type: ActionTypes.PAYMENT_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handlePaymentDelete = (payment) => ({
  type: ActionTypes.PAYMENT_DELETE_HANDLE,
  payload: {
    payment,
  },
});

export const handlePaymentInit = (payments) => ({
  type: ActionTypes.PAYMENT_INIT_HANDLE,
  payload: {
    payments,
  },
});
