import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Radio, Tab } from 'semantic-ui-react';

import styles from './PreferencesPane.module.scss';

const PreferencesPane = React.memo(({ subscribeToOwnSellitems, onUpdate }) => {
  const [t] = useTranslation();

  const handleSubscribeToOwnSellitemsChange = useCallback(() => {
    onUpdate({
      subscribeToOwnSellitems: !subscribeToOwnSellitems,
    });
  }, [subscribeToOwnSellitems, onUpdate]);

  return (
    <Tab.Pane attached={false} className={styles.wrapper}>
      <Radio
        toggle
        checked={subscribeToOwnSellitems}
        label={t('common.subscribeToMyOwnSellitemsByDefault')}
        onChange={handleSubscribeToOwnSellitemsChange}
      />
    </Tab.Pane>
  );
});

PreferencesPane.propTypes = {
  subscribeToOwnSellitems: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default PreferencesPane;
