import { all, takeEvery } from 'redux-saga/effects';

import {
  createBagitemInCurrentBagService,
  updateBagitemService,
  deleteBagitemService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* bagitemWatchers() {
  yield all([
    takeEvery(EntryActionTypes.BAGITEM_CREATE, ({ payload: { data } }) =>
      createBagitemInCurrentBagService(data),
    ),
    takeEvery(EntryActionTypes.BAGITEM_UPDATE, ({ payload: { id, data } }) =>
      updateBagitemService(id, data),
    ),
    takeEvery(EntryActionTypes.BAGITEM_DELETE, ({ payload: { id, data } }) =>
      deleteBagitemService(id, data),
    ),
  ]);
}
