import socket from './socket';

/* Actions */

const createProjectAgent = (productId, data, headers) =>
  socket.post(`/products/${productId}/projectAgents`, data, headers).then((body) => ({
    ...body,
    item: body.item,
  }));

const updateProjectAgent = (id, data, headers) =>
  socket.patch(`/projectAgents/${id}`, data, headers).then((body) => ({
    ...body,
    item: body.item,
  }));

export default {
  createProjectAgent,
  updateProjectAgent,
};
