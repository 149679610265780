const RIGHT_SIDEBAR_KEY = 'rightSidebar';

export const getRightSidebar = () => {
  const rightSidebar = localStorage.getItem(RIGHT_SIDEBAR_KEY);
  if (rightSidebar === 'false') {
    return false;
  }
  return true;
};

export const setRightSidebar = (rightSidebar) => {
  localStorage.setItem(RIGHT_SIDEBAR_KEY, rightSidebar);
};

export const removeRightSidebar = () => {
  localStorage.removeItem(RIGHT_SIDEBAR_KEY);
};
