import ActionTypes from '../constants/ActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const handleLocationChange = (
  group,
  customer,
  block,
  users,
  projects,
  groupMemberships,
  labels,
  blocks,
  customers,
  products,
  bags,
  bagitems,
  discounts,
  sellitems,
  sellitemMemberships,
  sellitemLabels,
  sellitemCustomers,
  sellitemDiscounts,
  payments,
  attachments,
  notifications,
  projectAgents,
  dashboard,
) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE,
  payload: {
    group,
    customer,
    block,
    users,
    projects,
    groupMemberships,
    labels,
    blocks,
    customers,
    products,
    bags,
    bagitems,
    discounts,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    sellitemCustomers,
    sellitemDiscounts,
    payments,
    attachments,
    notifications,
    projectAgents,
    dashboard,
  },
});

handleLocationChange.fetchGroup = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__GROUP_FETCH,
  payload: {
    id,
  },
});

handleLocationChange.fetchCustomer = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__CUSTOMER_FETCH,
  payload: {
    id,
  },
});

handleLocationChange.fetchProduct = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__PRODUCT_FETCH,
  payload: {
    id,
  },
});

handleLocationChange.fetchBag = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__BAG_FETCH,
  payload: {
    id,
  },
});

handleLocationChange.fetchDashboard = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__DASHBOARD_FETCH,
  payload: {
    id,
  },
});
