import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

import ProjectsContainer from '../../containers/ProjectsContainer';
import GroupWrapperContainer from '../../containers/GroupWrapperContainer';

import styles from './Static.module.scss';

const Static = ({ sellitemId, groupId, projectId }) => {
  const [t] = useTranslation();

  if (projectId === undefined) {
    return (
      <div className={styles.root}>
        <ProjectsContainer />
      </div>
    );
  }

  if (sellitemId === null) {
    return (
      <div className={classNames(styles.root, styles.flex)}>
        <div className={styles.message}>
          <h1>
            {t('common.sellitemNotFound', {
              context: 'title',
            })}
          </h1>
        </div>
      </div>
    );
  }

  if (groupId === null) {
    return (
      <div className={classNames(styles.root, styles.flex)}>
        <div className={styles.message}>
          <h1>
            {t('common.groupNotFound', {
              context: 'title',
            })}
          </h1>
        </div>
      </div>
    );
  }

  if (projectId === null) {
    return (
      <div className={classNames(styles.root, styles.flex)}>
        <div className={styles.message}>
          <h1>
            {t('common.projectNotFound', {
              context: 'title',
            })}
          </h1>
        </div>
      </div>
    );
  }

  if (groupId === undefined) {
    return (
      <div className={classNames(styles.group, styles.flex)}>
        <div className={styles.message}>
          <Icon inverted name="hand point up outline" size="huge" className={styles.messageIcon} />
          <h1 className={styles.messageTitle}>
            {t('common.openGroup', {
              context: 'title',
            })}
          </h1>
          <div className={styles.messageContent}>
            <Trans i18nKey="common.createNewOneOrSelectExistingOne" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.group, styles.flex)}>
      <GroupWrapperContainer />
    </div>
  );
};

Static.propTypes = {
  sellitemId: PropTypes.string,
  groupId: PropTypes.string,
  projectId: PropTypes.string,
};

Static.defaultProps = {
  sellitemId: undefined,
  groupId: undefined,
  projectId: undefined,
};

export default Static;
