import { call, put, select } from 'redux-saga/effects';

// import { goToCustomerService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createCustomer,
  deleteCustomer,
  fetchCustomer,
  handleCustomerCreate,
  handleCustomerDelete,
  handleCustomerUpdate,
  updateCustomer,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createCustomerService(data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createCustomer({
      ...nextData,
      id: localId,
    }),
  );

  let customer;
  let sellitemCustomer;
  try {
    ({
      item: customer,
      included: { sellitemCustomer },
    } = yield call(request, api.createCustomer, nextData));
  } catch (error) {
    yield put(createCustomer.failure(localId, error));
    return;
  }

  yield put(createCustomer.success(localId, customer, sellitemCustomer));
}

export function* handleCustomerCreateService(label) {
  yield put(handleCustomerCreate(label));
}

export function* fetchCustomerService(id) {
  yield put(fetchCustomer(id));

  // let group;
  let customer;
  let block;
  // let users;
  // let projects;
  // let groupMemberships;
  // let labels;
  // let customers;
  let sellitems;
  let sellitemMemberships;
  let sellitemLabels;
  let sellitemCustomers;
  let payments;
  let attachments;

  try {
    ({
      item: customer,
      included: {
        block,
        sellitems,
        sellitemMemberships,
        sellitemLabels,
        sellitemCustomers,
        payments,
        attachments,
      },
    } = yield call(request, api.getCustomer, id));
  } catch (error) {
    yield put(fetchCustomer.failure(id, error));
    return;
  }

  yield put(
    fetchCustomer.success(
      customer,
      block,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      sellitemCustomers,
      payments,
      attachments,
    ),
  );
}

export function* updateCustomerService(id, data) {
  const { groupId } = yield select(pathSelector);
  const newData = {
    ...data,
    groupId,
  };
  yield put(updateCustomer(id, newData));

  let customer;
  try {
    ({ item: customer } = yield call(request, api.updateCustomer, id, newData));
  } catch (error) {
    yield put(updateCustomer.failure(id, error));
    return;
  }

  yield put(updateCustomer.success(customer));
}

export function* handleCustomerUpdateService(label) {
  yield put(handleCustomerUpdate(label));
}

export function* deleteCustomerService(id) {
  yield put(deleteCustomer(id));

  let customer;
  try {
    ({ item: customer } = yield call(request, api.deleteCustomer, id));
  } catch (error) {
    yield put(deleteCustomer.failure(id, error));
    return;
  }

  yield put(deleteCustomer.success(customer));
}

export function* handleCustomerDeleteService(label) {
  yield put(handleCustomerDelete(label));
}
