import XLSX from 'sheetjs-style';

export const exportXlsx = (products, colHeaders, currentBag) => {
  const s = {
    // font: { bold: false },
    border: {
      top: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
      bottom: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
    },
  };
  const hs = {
    ...s,
    // fill: { patternType: 'solid', bgColor: { indexed: 64 } },
    alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    font: {
      // name: 'Roboto',
      // sz: 24,
      bold: true,
      // color: { rgb: 'FFFFAA00' },
    },
  };
  const h1 = {
    alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    font: {
      sz: 12,
      bold: true,
    },
  };
  const h2 = {
    ...s,
    alignment: { horizontal: 'center', vertical: 'center' },
    font: {
      // name: 'Roboto',
      sz: 14,
      bold: true,
      // color: { rgb: 'FFFFAA00' },
    },
  };

  const dataHeaders = [
    {
      STT: {
        v: `CÔNG TY CỔ PHẦN ĐẦU TƯ - PHÁT TRIỂN ĐÔ THỊ ÂN PHÚ
      28 - Phan Bội Châu - Phường Hòa Thuận - Tam Kỳ - Quảng Nam`,
        s: h1,
      },
      code: { v: '', s: h1 },
      landNumber: { v: '', s: h1 },
      houseForm: { v: '', s: h1 },
      certificate: { v: '', s: h1 },
      street: { v: '', s: h1 },
      direction: { v: '', s: h1 },
      density: { v: '', s: h1 },
      landArea: { v: '', s: h1 },
      floorArea: { v: '', s: h1 },
      landPrice: { v: '', s: h1 },
      floorPrice: { v: '', s: h1 },
      landTotal: { v: '', s: h1 },
      floorTotal: { v: '', s: h1 },
      factor: { v: '', s: h1 },
      finalTotal: { v: '', s: h1 },
      description: { v: '', s: h1 },
      agent: { v: '', s: h1 },
    },
    {
      STT: {
        v: `${
          currentBag.description?.toUpperCase() || currentBag.name.toUpperCase()
          // eslint-disable-next-line prettier/prettier
        } (ÁP DỤNG TỪ NGÀY ${currentBag.startDate.toLocaleString('vi-VN').substring(10)}${currentBag.endDate ? ` ĐẾN ${currentBag.endDate.toLocaleString('vi-VN').substring(10)}` : ''})`,
        s: h2,
      },
      code: { v: '', s: h2 },
      landNumber: { v: '', s: h2 },
      houseForm: { v: '', s: h2 },
      certificate: { v: '', s: h2 },
      street: { v: '', s: h2 },
      direction: { v: '', s: h2 },
      density: { v: '', s: h2 },
      landArea: { v: '', s: h2 },
      floorArea: { v: '', s: h2 },
      landPrice: { v: '', s: h2 },
      floorPrice: { v: '', s: h2 },
      landTotal: { v: '', s: h2 },
      floorTotal: { v: '', s: h2 },
      factor: { v: '', s: h2 },
      finalTotal: { v: '', s: h2 },
      description: { v: '', s: h2 },
      agent: { v: '', s: h2 },
    },
    {
      STT: { v: colHeaders[0], s: hs },
      code: { v: colHeaders[1], s: hs },
      landNumber: { v: colHeaders[2], s: hs },
      houseForm: { v: colHeaders[3], s: hs },
      certificate: { v: colHeaders[4], s: hs },
      street: { v: colHeaders[5], s: hs },
      direction: { v: colHeaders[6], s: hs },
      density: { v: colHeaders[7], s: hs },
      landArea: { v: colHeaders[8], s: hs },
      floorArea: { v: colHeaders[9], s: hs },
      landPrice: { v: colHeaders[10], s: hs },
      floorPrice: { v: colHeaders[11], s: hs },
      landTotal: { v: colHeaders[12], s: hs },
      floorTotal: { v: colHeaders[13], s: hs },
      factor: { v: colHeaders[14], s: hs },
      finalTotal: { v: colHeaders[15], s: hs },
      description: { v: colHeaders[16], s: hs },
      agent: { v: colHeaders[17], s: hs },
    },
  ];

  let r = dataHeaders.length;
  const data = products.map(
    (
      {
        name,
        description,
        landNumber,
        houseForm,
        certificate,
        street,
        direction,
        density,
        landArea,
        floorArea,
        landPrice,
        floorPrice,
        factor,
        // finalTotal,
        agentName,
      },
      index,
    ) => {
      r += 1;
      return {
        STT: { t: 'n', v: index + 1, s },
        code: { v: name, s },
        landNumber: { v: landNumber || '', s },
        houseForm: { v: houseForm || '', s },
        certificate: { v: certificate || '', s },
        street: { v: street || '', s },
        direction: { v: direction || '', s },
        density: { t: 'n', v: density / 100, z: '0%', s },
        landArea: { t: 'n', v: landArea, z: '#,##0.00', s },
        floorArea: { t: 'n', v: floorArea, z: '#,##0.00', s },
        landPrice: { t: 'n', v: landPrice, z: '#,##0', s },
        floorPrice: { t: 'n', v: floorPrice, z: '#,##0', s },
        landTotal: { f: `=I${r}*K${r}*O${r}`, z: '#,##0', s },
        floorTotal: { f: `=J${r}*L${r}`, z: '#,##0', s },
        factor: { t: 'n', v: factor, z: '#,##0.00', s },
        finalTotal: { f: `M${r}+N${r}`, z: '#,##0', s },
        description: { v: description || '', s },
        agent: { v: agentName || '', s },
      };
    },
  );

  const ws = XLSX.utils.json_to_sheet(dataHeaders.concat(data), { skipHeader: true });
  const wscols = [
    { wch: 4 }, // stt
    { wch: 8 }, // code
    { wch: 5 }, // landNumber
    { wch: 8 }, // houseForm
    { wch: 10 }, // certificate
    { wch: 10 }, // street
    { wch: 10 }, // direction
    { wch: 5 }, // density
    { wch: 6 }, // landArea
    { wch: 6 }, // floorArea
    { wch: 12 }, // landPrice
    { wch: 12 }, // floorPrice
    { wch: 14 }, // landTotal
    { wch: 14 }, // floorTotal
    { wch: 4 }, // factor
    { wch: 14 }, // finalTotal
    { wch: 10 }, // description
    { wch: 10 }, // agent
  ];
  ws['!cols'] = wscols;
  ws['!rows'] = [{ hpx: 36 }, { hpx: 36 }];

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 17 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 17 } },
  ];
  ws['!merges'] = merge;

  const wb = { Sheets: { 'Sheet 1': ws }, SheetNames: ['Sheet 1'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    cellStyles: true,
    skipHeader: true,
    type: 'array',
  });
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const blob = new Blob([excelBuffer], { type: fileType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${currentBag.name}.xlsx`;
  link.click();
};

export const exportLand = (products, colHeaders, currentBag) => {
  const s = {
    // font: { bold: false },
    border: {
      top: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
      bottom: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
    },
  };
  const hs = {
    ...s,
    // fill: { patternType: 'solid', bgColor: { indexed: 64 } },
    alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    font: {
      // name: 'Roboto',
      // sz: 24,
      bold: true,
      // color: { rgb: 'FFFFAA00' },
    },
  };
  const h1 = {
    alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    font: {
      sz: 12,
      bold: true,
    },
  };
  const h2 = {
    ...s,
    alignment: { horizontal: 'center', vertical: 'center' },
    font: {
      // name: 'Roboto',
      sz: 14,
      bold: true,
      // color: { rgb: 'FFFFAA00' },
    },
  };

  const dataHeaders = [
    {
      STT: {
        v: `CÔNG TY CỔ PHẦN ĐẦU TƯ - PHÁT TRIỂN ĐÔ THỊ ÂN PHÚ
      28 - Phan Bội Châu - Phường Hòa Thuận - Tam Kỳ - Quảng Nam`,
        s: h1,
      },
      code: { v: '', s: h1 },
      landNumber: { v: '', s: h1 },
      landDescription: { v: '', s: h1 },
      mapNumber: { v: '', s: h1 },
      houseForm: { v: '', s: h1 },
      certificate: { v: '', s: h1 },
      street: { v: '', s: h1 },
      direction: { v: '', s: h1 },
      density: { v: '', s: h1 },
      landArea: { v: '', s: h1 },
      floorArea: { v: '', s: h1 },
      landPrice: { v: '', s: h1 },
      floorPrice: { v: '', s: h1 },
      landTotal: { v: '', s: h1 },
      floorTotal: { v: '', s: h1 },
      factor: { v: '', s: h1 },
      finalTotal: { v: '', s: h1 },
      description: { v: '', s: h1 },
      agent: { v: '', s: h1 },
      note: { v: '', s: h1 },
    },
    {
      STT: {
        v: `${
          currentBag.description?.toUpperCase() || currentBag.name.toUpperCase()
          // eslint-disable-next-line prettier/prettier
        } (ÁP DỤNG TỪ NGÀY ${currentBag.startDate.toLocaleString('vi-VN').substring(10)}${currentBag.endDate ? ` ĐẾN ${currentBag.endDate.toLocaleString('vi-VN').substring(10)}` : ''})`,
        s: h2,
      },
      code: { v: '', s: h2 },
      landNumber: { v: '', s: h2 },
      landDescription: { v: '', s: h2 },
      mapNumber: { v: '', s: h2 },
      houseForm: { v: '', s: h2 },
      certificate: { v: '', s: h2 },
      street: { v: '', s: h2 },
      direction: { v: '', s: h2 },
      density: { v: '', s: h2 },
      landArea: { v: '', s: h2 },
      floorArea: { v: '', s: h2 },
      landPrice: { v: '', s: h2 },
      floorPrice: { v: '', s: h2 },
      landTotal: { v: '', s: h2 },
      floorTotal: { v: '', s: h2 },
      factor: { v: '', s: h2 },
      finalTotal: { v: '', s: h2 },
      description: { v: '', s: h2 },
      agent: { v: '', s: h2 },
      note: { v: '', s: h2 },
    },
    {
      STT: { v: colHeaders[0], s: hs },
      code: { v: colHeaders[1], s: hs },
      landNumber: { v: colHeaders[2], s: hs },
      landDescription: { v: colHeaders[3], s: hs },
      mapNumber: { v: colHeaders[4], s: hs },
      houseForm: { v: colHeaders[5], s: hs },
      certificate: { v: colHeaders[6], s: hs },
      street: { v: colHeaders[7], s: hs },
      direction: { v: colHeaders[8], s: hs },
      density: { v: colHeaders[9], s: hs },
      landArea: { v: colHeaders[10], s: hs },
      floorArea: { v: colHeaders[11], s: hs },
      landPrice: { v: colHeaders[12], s: hs },
      floorPrice: { v: colHeaders[13], s: hs },
      landTotal: { v: colHeaders[14], s: hs },
      floorTotal: { v: colHeaders[15], s: hs },
      factor: { v: colHeaders[16], s: hs },
      finalTotal: { v: colHeaders[17], s: hs },
      description: { v: colHeaders[18], s: hs },
      agent: { v: colHeaders[19], s: hs },
      note: { v: colHeaders[20], s: hs },
    },
  ];

  let r = dataHeaders.length;
  const data = products.map(
    (
      {
        name,
        description,
        landNumber,
        landDescription,
        mapNumber,
        houseForm,
        certificate,
        street,
        direction,
        density,
        landArea,
        floorArea,
        landPrice,
        floorPrice,
        factor,
        // finalTotal,
        agentName,
      },
      index,
    ) => {
      r += 1;
      return {
        STT: { t: 'n', v: index + 1, s },
        code: { v: name, s },
        landNumber: { v: landNumber || '', s },
        landDescription: { v: landDescription || '', s },
        mapNumber: { v: mapNumber || '', s },
        houseForm: { v: houseForm || '', s },
        certificate: { v: certificate || '', s },
        street: { v: street || '', s },
        direction: { v: direction || '', s },
        density: { t: 'n', v: density / 100, z: '0%', s },
        landArea: { t: 'n', v: landArea, z: '#,##0.00', s },
        floorArea: { t: 'n', v: floorArea, z: '#,##0.00', s },
        landPrice: { t: 'n', v: landPrice, z: '#,##0', s },
        floorPrice: { t: 'n', v: floorPrice, z: '#,##0', s },
        landTotal: { f: `=I${r}*K${r}*O${r}`, z: '#,##0', s },
        floorTotal: { f: `=J${r}*L${r}`, z: '#,##0', s },
        factor: { t: 'n', v: factor, z: '#,##0.00', s },
        finalTotal: { f: `M${r}+N${r}`, z: '#,##0', s },
        description: { v: description || '', s },
        agent: { v: agentName || '', s },
        note: { v: '', s },
      };
    },
  );

  const ws = XLSX.utils.json_to_sheet(dataHeaders.concat(data), { skipHeader: true });
  const wscols = [
    { wch: 4 }, // stt
    { wch: 8 }, // code
    { wch: 5 }, // landNumber
    { wch: 10 }, // landDescription
    { wch: 6 }, // mapNumber
    { wch: 8 }, // houseForm
    { wch: 10 }, // certificate
    { wch: 10 }, // street
    { wch: 10 }, // direction
    { wch: 5 }, // density
    { wch: 6 }, // landArea
    { wch: 6 }, // floorArea
    { wch: 12 }, // landPrice
    { wch: 12 }, // floorPrice
    { wch: 14 }, // landTotal
    { wch: 14 }, // floorTotal
    { wch: 4 }, // factor
    { wch: 14 }, // finalTotal
    { wch: 10 }, // description
    { wch: 10 }, // agent
    { wch: 10 }, // agent
  ];
  ws['!cols'] = wscols;
  ws['!rows'] = [{ hpx: 36 }, { hpx: 36 }];

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 20 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 20 } },
  ];
  ws['!merges'] = merge;

  const wb = { Sheets: { 'Sheet 1': ws }, SheetNames: ['Sheet 1'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    cellStyles: true,
    skipHeader: true,
    type: 'array',
  });
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const blob = new Blob([excelBuffer], { type: fileType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${currentBag.name}.xlsx`;
  link.click();
};

// export default { exportXlsx, exportLand };
