import EntryActionTypes from '../../constants/EntryActionTypes';

export const createPaymentInCurrentCustomer = (data) => ({
  type: EntryActionTypes.PAYMENT_IN_CURRENT_CUSTOMER_CREATE,
  payload: {
    data,
  },
});

export const createPaymentInCurrentSellitem = (data) => ({
  type: EntryActionTypes.PAYMENT_IN_CURRENT_SELLITEM_CREATE,
  payload: {
    data,
  },
});

export const createPaymentInCurrentProduct = (sellitemId, data) => ({
  type: EntryActionTypes.PAYMENT_IN_CURRENT_PRODUCT_CREATE,
  payload: {
    sellitemId,
    data,
  },
});

export const handlePaymentCreate = (payment) => ({
  type: EntryActionTypes.PAYMENT_CREATE_HANDLE,
  payload: {
    payment,
  },
});

export const updatePayment = (id, data) => ({
  type: EntryActionTypes.PAYMENT_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handlePaymentUpdate = (payment) => ({
  type: EntryActionTypes.PAYMENT_UPDATE_HANDLE,
  payload: {
    payment,
  },
});

export const deletePayment = (id) => ({
  type: EntryActionTypes.PAYMENT_DELETE,
  payload: {
    id,
  },
});

export const handlePaymentDelete = (payment) => ({
  type: EntryActionTypes.PAYMENT_DELETE_HANDLE,
  payload: {
    payment,
  },
});

export const handlePaymentInit = (payments) => ({
  type: EntryActionTypes.PAYMENT_INIT_HANDLE,
  payload: {
    payments,
  },
});
