/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown, List, Segment } from 'semantic-ui-react';
import HotTable from '@handsontable/react';
import Handsontable from 'handsontable';

import NameEdit from '../CustomerItem/NameEdit';
import DueDate from '../DueDate';
import DueDateEditPopup from '../DueDateEditPopup';
import { ProductStatuses } from '../../constants/Enums';
import Discounts from './Discounts';
// import PolicyEdit from './PolicyEdit';
// import { Markdown } from '../../lib/custom-ui';

import styles from './RightSidebar.module.scss';

const RightSidebar = React.memo(
  ({
    currentBag,
    products,
    agents,
    categories,
    project,
    isPersisted,
    canEdit,
    onUpdate,
    onCreateDiscount,
    onUpdateDiscount,
    onDeleteDiscount,
  }) => {
    const [t] = useTranslation();

    const nameEdit = useRef('');
    const descriptionEdit = useRef('');
    const commissionTableRef = useRef();

    const summary = useMemo(
      () => ({
        selled: products.filter(({ status: s }) => s === ProductStatuses.SELLED.name).length,
        deposit: products.filter(({ status: s }) => s === ProductStatuses.DEPOSIT.name).length,
        locked: products.filter(({ status: s }) => s === ProductStatuses.LOCKED.name).length,
      }),
      [products],
    );

    const agentOptions = useMemo(
      () =>
        agents.map((it) => ({
          key: it.id,
          value: it.id,
          text: it.name,
        })),
      [agents],
    );

    const [agentId, setAgentId] = useState(agentOptions[0].value);

    const allCommissions = useMemo(() => {
      if (currentBag.commission) {
        return currentBag?.commission;
      }
      if (agents) {
        const current = agents.map(({ id, commission }) => ({ id, commission }));
        return current;
      }
      return [];
    }, [agents, currentBag.commission]);

    const currentCommission = useMemo(
      () => allCommissions.find(({ id }) => id === agentId).commission || [],
      [agentId, allCommissions],
    );

    const handleHeaderClick = useCallback(
      (field) => {
        if (isPersisted && canEdit) {
          switch (field) {
            case 'name':
              if (!currentBag.isParent) nameEdit.current.open();
              break;
            case 'description':
              descriptionEdit.current.open();
              break;
            default:
          }
        }
      },
      [isPersisted, canEdit, currentBag.isParent],
    );

    const handleUpdate = useCallback(
      (field, newName) => {
        onUpdate(currentBag.id, {
          [field]: newName,
        });
      },
      [currentBag.id, onUpdate],
    );

    const handleCategoryChange = useCallback(
      (e, { value }) => {
        onUpdate(currentBag.id, {
          categoryId: value,
        });
      },
      [currentBag.id, onUpdate],
    );

    const handleChange = useCallback((e, { value }) => {
      setAgentId(value);
    }, []);

    const afterChange = useCallback(
      (changes, source) => {
        const sourceChange = ['edit', 'CopyPaste.paste', 'Autofill.fill', 'undo', 'redo'];
        if (sourceChange.indexOf(source) > -1 && changes.length > 0) {
          changes.forEach(([row, prop, oldValue, newValue]) => {
            if ((oldValue === null || oldValue === undefined) && newValue === '') return;

            // eslint-disable-next-line eqeqeq
            if (oldValue != newValue) {
              let newData;
              let commissions = [];

              switch (prop) {
                case 'quantity':
                case 'percent':
                  commissions = allCommissions.map((it) => ({
                    ...it,
                    commission: it.id === agentId ? currentCommission : it.commission,
                  }));
                  newData = {
                    commission: commissions,
                  };
                  onUpdate(currentBag.id, newData);
                  break;
                default:
                  newData = {
                    [prop]: newValue || null,
                  };
                  onUpdate(currentBag.id, newData);
                  break;
              }
            }
          });
        }
      },
      [agentId, allCommissions, currentBag.id, currentCommission, onUpdate],
    );

    const percentRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        `${(value * 1).toFixed(2)}%`,
        cellProperties,
      ]);
      return td;
    };

    // useEffect(() => {
    //   commissionTableRef.current?.hotInstance.refreshDimensions();
    // }, []);
    console.log('Bags RightSidebar render');

    return (
      <Segment className={styles.wrapper}>
        <List divided verticalAlign="middle">
          <List.Item>
            <List.Content
              as="h4"
              className={styles.productName}
              onClick={() => handleHeaderClick('name')}
            >
              {canEdit && !currentBag.isParent ? (
                <NameEdit
                  ref={nameEdit}
                  defaultValue={currentBag.name}
                  onUpdate={(data) => handleUpdate('name', data)}
                >
                  <div className={styles.headerName1}>{currentBag.name}</div>
                </NameEdit>
              ) : (
                <div className={styles.headerName}>{currentBag.name}</div>
              )}
            </List.Content>
          </List.Item>
          <List.Item
            className={styles.productName}
            onClick={() => handleHeaderClick('description')}
          >
            {canEdit ? (
              <NameEdit
                ref={descriptionEdit}
                defaultValue={currentBag.description || ''}
                onUpdate={(data) => handleUpdate('description', data)}
              >
                <div>{currentBag.description}</div>
              </NameEdit>
            ) : (
              <div>{currentBag.description}</div>
            )}
          </List.Item>
          <List.Item>
            <List.Content>
              {currentBag.startDate && (
                <span>
                  {canEdit ? (
                    <DueDateEditPopup
                      title="action.editStartDate"
                      defaultValue={currentBag.startDate}
                      onUpdate={(data) => handleUpdate('startDate', data)}
                    >
                      <DueDate value={currentBag.startDate} size="tiny" />
                    </DueDateEditPopup>
                  ) : (
                    <DueDate value={currentBag.startDate} size="tiny" />
                  )}
                </span>
              )}
              {currentBag.endDate && (
                <span>
                  ~
                  {canEdit ? (
                    <DueDateEditPopup
                      title="action.editEndDate"
                      defaultValue={currentBag.endDate}
                      onUpdate={(data) => handleUpdate('endDate', data)}
                    >
                      <DueDate value={currentBag.endDate} size="tiny" />
                    </DueDateEditPopup>
                  ) : (
                    <DueDate value={currentBag.endDate} size="tiny" />
                  )}
                </span>
              )}
            </List.Content>
          </List.Item>
          <List.Item className={styles.productName}>
            <List.Content floated="right">
              <Dropdown
                fluid
                required
                inline
                name="categoryId"
                disabled={!canEdit || products.length > 0 || currentBag.isParent}
                pointing="top right"
                value={currentBag.categoryId}
                options={categories.map((c) => ({
                  key: c.id,
                  value: c.id,
                  text: c.name,
                }))}
                className={styles.category}
                placeholder={`${t('common.category')} *`}
                onChange={handleCategoryChange}
              />
            </List.Content>
            <List.Content>{t('common.category')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{t(`status.${currentBag.status}`)}</List.Content>
            <List.Content>{t('common.status')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{products.length}</List.Content>
            <List.Content>{t('common.products')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {products.length - summary.selled - summary.deposit - summary.locked}
            </List.Content>
            <List.Content>{t('status.selling')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{summary.locked}</List.Content>
            <List.Content>{t('status.locked')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{summary.deposit}</List.Content>
            <List.Content>{t('status.deposit')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{summary.selled}</List.Content>
            <List.Content>{t('status.selled')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {Math.round(
                products.reduce((sum, { finalTotal }) => sum + finalTotal, 0),
                0,
              ).toLocaleString('en-GB')}
            </List.Content>
            <List.Content>{t('common.finalTotal')}:</List.Content>
          </List.Item>
        </List>
        <Discounts
          items={currentBag.discounts}
          canEdit={canEdit}
          onCreate={onCreateDiscount}
          onUpdate={onUpdateDiscount}
          onDelete={onDeleteDiscount}
        />
        <h4>{t('common.commission')}</h4>
        <Dropdown
          options={agentOptions}
          defaultValue={agentId}
          onChange={handleChange}
          className={styles.agents}
        />
        <div id="hot-app-bag-commission">
          <HotTable
            ref={commissionTableRef}
            data={currentCommission}
            readOnly={!canEdit}
            colHeaders={[t('common.quantity'), t('common.percent')]}
            columns={[
              {
                data: 'quantity',
                type: 'numeric',
                // className: 'htRight',
              },
              {
                data: 'percent',
                renderer: percentRenderer,
                className: 'htRight',
              },
            ]}
            // autoColumnSize={false}
            // autoRowSize={false}
            // colWidths={[40, 60]}
            // rowHeights={23}
            // hiddenColumns={{ columns: [0] }}
            // rowHeaders
            stretchH="all"
            height="auto"
            // width="auto"
            minSpareRows={0}
            contextMenu={['row_above', 'row_below', '---------', 'remove_row']}
            afterChange={afterChange}
            // beforeKeyDown={beforeKeyDown}
            // beforeChange={beforeChange}
            // beforeRemoveRow={beforeRemoveRow}
            licenseKey="non-commercial-and-evaluation"
          />
        </div>
        {/* <h4>{t('common.policy')}</h4>
        <div>
          <PolicyEdit
            defaultValue={currentBag.policy}
            onUpdate={(data) => handleUpdate('policy', data)}
          >
            {currentBag.policy ? (
              <button type="button" className={styles.policyText}>
                <Markdown linkStopPropagation linkTarget="_blank">
                  {currentBag.policy}
                </Markdown>
              </button>
            ) : (
              <button type="button" className={styles.policyButton}>
                <span className={styles.policyButtonText}>{t('action.addMoreDetailedPolicy')}</span>
              </button>
            )}
          </PolicyEdit>
        </div> */}
      </Segment>
    );
  },
);

RightSidebar.propTypes = {
  isPersisted: PropTypes.bool.isRequired,
  currentBag: PropTypes.objectOf(PropTypes.shape).isRequired,
  products: PropTypes.arrayOf(PropTypes.object()).isRequired,
  agents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  project: PropTypes.objectOf(PropTypes.shape).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCreateDiscount: PropTypes.func.isRequired,
  onUpdateDiscount: PropTypes.func.isRequired,
  onDeleteDiscount: PropTypes.func.isRequired,
};

// RightSidebar.defaultProps = {
//   defaultColor: undefined,
// };

export default RightSidebar;
