import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'ProjectAgent';

  static fields = {
    id: attr(),
    quantity: attr(),
    webPrice: attr(),
    exchangeRate: attr(),
    note: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    productId: fk({
      to: 'Product',
      as: 'product',
      relatedName: 'projectAgents',
    }),
    creatorUserId: fk({
      to: 'User',
      as: 'creator',
      relatedName: 'projectAgents',
    }),
  };

  static reducer({ type, payload }, ProjectAgent) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.LOCATION_CHANGE_HANDLE__PRODUCT_FETCH:
      case ActionTypes.PRODUCT_FETCH__SUCCESS:
        if (payload.projectAgents) {
          payload.projectAgents.forEach((projectAgent) => {
            ProjectAgent.upsert(projectAgent);
          });
        }

        break;
      case ActionTypes.PROJECT_AGENT_CREATE:
        ProjectAgent.upsert(payload.projectAgent);

        break;
      case ActionTypes.PROJECT_AGENT_CREATE__SUCCESS:
        ProjectAgent.withId(payload.localId).delete();
        ProjectAgent.upsert(payload.projectAgent);

        break;
      case ActionTypes.PROJECT_AGENT_UPDATE__SUCCESS:
        ProjectAgent.withId(payload.id).update(payload.data);

        break;
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('id', false);
  }
}
