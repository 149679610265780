import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  currentProjectSelector,
  managersForCurrentProjectSelector,
  agentsForCurrentProjectSelector,
  categoriesForCurrentProjectSelector,
  accountsForCurrentProjectSelector,
  usersSelector,
} from '../selectors';
import {
  closeModal,
  createAccount,
  createCategory,
  createAgent,
  createManagerInCurrentProject,
  deleteAccount,
  deleteCategory,
  deleteAgent,
  deleteCurrentProject,
  deleteProjectManager,
  updateCurrentProject,
  updateAccount,
  updateCategory,
  updateAgent,
  updateCurrentProjectBackgroundImage,
} from '../actions/entry';
import ProjectSettingsModal from '../components/ProjectSettingsModal';

const mapStateToProps = (state) => {
  const users = usersSelector(state);

  const {
    name,
    description,
    province,
    district,
    ward,
    address,
    startDate,
    openDate,
    paymentMethod,
    paymentDeposit,
    paymentConfirm1,
    paymentConfirm2,
    contract1,
    contract2,
    contract3,
    contract4,
    appendix1,
    appendix2,
    appendix3,
    appendix4,
    appendix5,
    background,
    backgroundImage,
    isBackgroundImageUpdating,
    isAutoUpdateProduct,
    isAutoUpdateSellitem,
  } = currentProjectSelector(state);

  const managers = managersForCurrentProjectSelector(state);
  const agents = agentsForCurrentProjectSelector(state);
  const categories = categoriesForCurrentProjectSelector(state);
  const accounts = accountsForCurrentProjectSelector(state);

  return {
    name,
    description,
    province,
    district,
    ward,
    address,
    startDate: new Date(startDate),
    openDate: new Date(openDate),
    paymentMethod,
    paymentDeposit: paymentDeposit || '',
    paymentConfirm1: paymentConfirm1 || '',
    paymentConfirm2: paymentConfirm2 || '',
    contract1: contract1 || '',
    contract2: contract2 || '',
    contract3: contract3 || '',
    contract4: contract4 || '',
    appendix1: appendix1 || '',
    appendix2: appendix2 || '',
    appendix3: appendix3 || '',
    appendix4: appendix4 || '',
    appendix5: appendix5 || '',
    background,
    backgroundImage,
    isAutoUpdateProduct,
    isAutoUpdateSellitem,
    isBackgroundImageUpdating,
    managers: managers.filter(({ user: { email } }) => email !== 'kanri@tipici.vn'),
    allUsers: users,
    agents,
    categories,
    accounts,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdate: updateCurrentProject,
      onBackgroundImageUpdate: updateCurrentProjectBackgroundImage,
      onAccountUpdate: updateAccount,
      onAccountCreate: createAccount,
      onAccountDelete: deleteAccount,
      onCategoryUpdate: updateCategory,
      onCategoryCreate: createCategory,
      onCategoryDelete: deleteCategory,
      onAgentUpdate: updateAgent,
      onAgentCreate: createAgent,
      onAgentDelete: deleteAgent,
      onDelete: deleteCurrentProject,
      onManagerCreate: createManagerInCurrentProject,
      onManagerDelete: deleteProjectManager,
      onClose: closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsModal);
