import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeAgentByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Agent }, id) => {
      const agentModel = Agent.withId(id);

      if (!agentModel) {
        return agentModel;
      }

      return agentModel.ref;
    },
  );

export const agentsSelector = createSelector(orm, ({ Agent }) => Agent.all().toRefArray());

export const agentByIdSelector = makeAgentByIdSelector();
