import { all, takeEvery } from 'redux-saga/effects';

import { createProjectAgentInCurrentProductService, updateProjectAgentService } from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* projectAgentWatchers() {
  yield all([
    takeEvery(EntryActionTypes.PROJECT_AGENT_CREATE, ({ payload: { data } }) =>
      createProjectAgentInCurrentProductService(data),
    ),
    takeEvery(EntryActionTypes.PROJECT_AGENT_UPDATE, ({ payload: { id, data } }) =>
      updateProjectAgentService(id, data),
    ),
  ]);
}
