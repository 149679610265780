import { all, takeEvery } from 'redux-saga/effects';

import {
  createPaymentInCurrentCustomerService,
  createPaymentInCurrentSellitemService,
  createPaymentInCurrentProductService,
  deletePaymentService,
  handlePaymentCreateService,
  handlePaymentDeleteService,
  handlePaymentUpdateService,
  handlePaymentInitService,
  updatePaymentService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* paymentWatchers() {
  yield all([
    takeEvery(EntryActionTypes.PAYMENT_IN_CURRENT_CUSTOMER_CREATE, ({ payload: { data } }) =>
      createPaymentInCurrentCustomerService(data),
    ),
    takeEvery(EntryActionTypes.PAYMENT_IN_CURRENT_SELLITEM_CREATE, ({ payload: { data } }) =>
      createPaymentInCurrentSellitemService(data),
    ),
    takeEvery(
      EntryActionTypes.PAYMENT_IN_CURRENT_PRODUCT_CREATE,
      ({ payload: { sellitemId, data } }) => createPaymentInCurrentProductService(sellitemId, data),
    ),
    takeEvery(EntryActionTypes.PAYMENT_CREATE_HANDLE, ({ payload: { payment } }) =>
      handlePaymentCreateService(payment),
    ),
    takeEvery(EntryActionTypes.PAYMENT_UPDATE, ({ payload: { id, data } }) =>
      updatePaymentService(id, data),
    ),
    takeEvery(EntryActionTypes.PAYMENT_UPDATE_HANDLE, ({ payload: { payment } }) =>
      handlePaymentUpdateService(payment),
    ),
    takeEvery(EntryActionTypes.PAYMENT_DELETE, ({ payload: { id } }) => deletePaymentService(id)),
    takeEvery(EntryActionTypes.PAYMENT_DELETE_HANDLE, ({ payload: { payment } }) =>
      handlePaymentDeleteService(payment),
    ),
    takeEvery(EntryActionTypes.PAYMENT_INIT_HANDLE, ({ payload: { payments } }) =>
      handlePaymentInitService(payments),
    ),
  ]);
}
