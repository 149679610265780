import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  addLabelToGroupFilter,
  addLabelToSellitem,
  createLabel,
  deleteLabel,
  handleLabelCreate,
  handleLabelDelete,
  handleLabelFromSellitemRemove,
  handleLabelToSellitemAdd,
  handleLabelUpdate,
  removeLabelFromGroupFilter,
  removeLabelFromSellitem,
  updateLabel,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createLabelService(groupId, data) {
  const localId = yield call(createLocalId);

  yield put(
    createLabel({
      ...data,
      groupId,
      id: localId,
    }),
  );

  let label;
  try {
    ({ item: label } = yield call(request, api.createLabel, groupId, data));
  } catch (error) {
    yield put(createLabel.failure(localId, error));
    return;
  }

  yield put(createLabel.success(localId, label));
}

export function* createLabelInCurrentGroupService(data) {
  const { groupId } = yield select(pathSelector);

  yield call(createLabelService, groupId, data);
}

export function* handleLabelCreateService(label) {
  yield put(handleLabelCreate(label));
}

export function* updateLabelService(id, data) {
  yield put(updateLabel(id, data));

  let label;
  try {
    ({ item: label } = yield call(request, api.updateLabel, id, data));
  } catch (error) {
    yield put(updateLabel.failure(id, error));
    return;
  }

  yield put(updateLabel.success(label));
}

export function* handleLabelUpdateService(label) {
  yield put(handleLabelUpdate(label));
}

export function* deleteLabelService(id) {
  yield put(deleteLabel(id));

  let label;
  try {
    ({ item: label } = yield call(request, api.deleteLabel, id));
  } catch (error) {
    yield put(deleteLabel.failure(id, error));
    return;
  }

  yield put(deleteLabel.success(label));
}

export function* handleLabelDeleteService(label) {
  yield put(handleLabelDelete(label));
}

export function* addLabelToSellitemService(id, sellitemId) {
  yield put(addLabelToSellitem(id, sellitemId));

  let sellitemLabel;
  try {
    ({ item: sellitemLabel } = yield call(request, api.createSellitemLabel, sellitemId, {
      labelId: id,
    }));
  } catch (error) {
    yield put(addLabelToSellitem.failure(id, sellitemId, error));
    return;
  }

  yield put(addLabelToSellitem.success(sellitemLabel));
}

export function* addLabelToCurrentSellitemService(id) {
  const { sellitemId } = yield select(pathSelector);

  yield call(addLabelToSellitemService, id, sellitemId);
}

export function* handleLabelToSellitemAddService(sellitemLabel) {
  yield put(handleLabelToSellitemAdd(sellitemLabel));
}

export function* removeLabelFromSellitemService(id, sellitemId) {
  yield put(removeLabelFromSellitem(id, sellitemId));

  let sellitemLabel;
  try {
    ({ item: sellitemLabel } = yield call(request, api.deleteSellitemLabel, sellitemId, id));
  } catch (error) {
    yield put(removeLabelFromSellitem.failure(id, sellitemId, error));
    return;
  }

  yield put(removeLabelFromSellitem.success(sellitemLabel));
}

export function* removeLabelFromCurrentSellitemService(id) {
  const { sellitemId } = yield select(pathSelector);

  yield call(removeLabelFromSellitemService, id, sellitemId);
}

export function* handleLabelFromSellitemRemoveService(sellitemLabel) {
  yield put(handleLabelFromSellitemRemove(sellitemLabel));
}

export function* addLabelToGroupFilterService(id, groupId) {
  yield put(addLabelToGroupFilter(id, groupId));
}

export function* addLabelToFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(addLabelToGroupFilterService, id, groupId);
}

export function* removeLabelFromGroupFilterService(id, groupId) {
  yield put(removeLabelFromGroupFilter(id, groupId));
}

export function* removeLabelFromFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(removeLabelFromGroupFilterService, id, groupId);
}
