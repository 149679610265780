import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  actionsForCurrentSellitemSelector,
  currentSellitemSelector,
  productByIdSelector,
  customerByIdSelector,
  isCurrentUserMemberForCurrentGroupSelector,
  isAdminSelector,
  isSaleAdminSelector,
  currentUserSelector,
  agentByIdSelector,
} from '../selectors';
import {
  updateCustomer,
  fetchActionsInCurrentSellitem,
  createCommentActionInCurrentSellitem,
  updateCommentAction,
  deleteCommentAction,
} from '../actions/entry';
import RightSidebar from '../components/Sellitems/RightSidebar';
import { ProductStatuses, Statuses } from '../constants/Enums';

const mapStateToProps = (state) => {
  // const { sellitemId } = pathSelector(state);
  // const sellitem = sellitemByIdSelector(state, sellitemId);
  const sellitem = currentSellitemSelector(state);
  const product = productByIdSelector(state, sellitem.productId);
  let actions = actionsForCurrentSellitemSelector(state);
  let customer;
  if (sellitem.customerId) {
    customer = customerByIdSelector(state, sellitem.customerId);
  }
  const currentUser = currentUserSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);
  const isSaleAdmin = isSaleAdminSelector(state);

  actions = actions.map((ac) => {
    if (ac.data.prop === 'productId') {
      const oldProduct = productByIdSelector(state, ac.data.oldValue);
      const newProduct = productByIdSelector(state, ac.data.newValue);
      return {
        ...ac,
        data: {
          ...ac.data,
          oldProduct,
          newProduct,
        },
      };
    }
    if (ac.data.prop === 'agentId') {
      const oldAgent = agentByIdSelector(state, ac.data.oldValue);
      const newAgent = agentByIdSelector(state, ac.data.newValue);
      return {
        ...ac,
        data: {
          ...ac.data,
          oldAgent,
          newAgent,
        },
      };
    }
    return {
      ...ac,
    };
  });

  return {
    currentUser,
    canEdit:
      (isAdmin || (isCurrentUserMember && isSaleAdmin)) &&
      sellitem.status !== Statuses.CANCELED.name &&
      product?.status !== ProductStatuses.SELLED.name,
    actions,
    sellitem,
    product,
    customer,
    canEditAllCommentActions: isCurrentUserMember,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdateCustomer: (id, data) => updateCustomer(id, data),
      onActionsFetch: fetchActionsInCurrentSellitem,
      onCommentActionCreate: createCommentActionInCurrentSellitem,
      onCommentActionUpdate: updateCommentAction,
      onCommentActionDelete: deleteCommentAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RightSidebar);
