/* eslint-disable no-unused-vars */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closePopup } from '../lib/popup';

import { clearGroupImportError, importGroup } from '../actions/entry';
import ImportStep from '../components/ImportStep';

const mapStateToProps = ({
  ui: {
    importForm: { data: defaultData, isSubmitting, response },
  },
}) => {
  return {
    defaultData,
    isSubmitting,
    response,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      importGroup,
      onMessageDismiss: clearGroupImportError,
      onClose: closePopup,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ImportStep);
