import ActionTypes from '../constants/ActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const searchProducts = (groupId, query) => ({
  type: ActionTypes.PRODUCTS_FETCH,
  payload: {
    groupId,
    query,
  },
});

searchProducts.success = (groupId, products, sellitems, attachments) => ({
  type: ActionTypes.PRODUCTS_FETCH__SUCCESS,
  payload: {
    groupId,
    products,
    sellitems,
    attachments,
  },
});

searchProducts.failure = (groupId, error) => ({
  type: ActionTypes.PRODUCTS_FETCH__FAILURE,
  payload: {
    groupId,
    error,
  },
});

export const fetchProducts = (groupId) => ({
  type: ActionTypes.PRODUCTS_FETCH,
  payload: {
    groupId,
  },
});

fetchProducts.success = (groupId, products, sellitems, attachments) => ({
  type: ActionTypes.PRODUCTS_FETCH__SUCCESS,
  payload: {
    groupId,
    products,
    sellitems,
    attachments,
  },
});

fetchProducts.failure = (groupId, error) => ({
  type: ActionTypes.PRODUCTS_FETCH__FAILURE,
  payload: {
    groupId,
    error,
  },
});
