import upperFirst from 'lodash/upperFirst';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ProgressBar.module.scss';

const SIZES = {
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
};

const ProgressBar = React.memo(({ total, value, color, size }) => {
  const bar = (
    <div
      className={classNames(styles.bars, styles.bar)}
      style={{
        backgroundColor: color,
        width: `${(value / total) * 100}%`,
      }}
    />
  );

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.bars, styles[`wrapper${upperFirst(size)}`])}>{bar}</div>
    </div>
  );
});

ProgressBar.propTypes = {
  total: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
};

ProgressBar.defaultProps = {
  size: SIZES.TINY,
};

export default ProgressBar;
