import { Model, attr } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Agent';

  static fields = {
    id: attr(),
    name: attr(),
    code: attr(),
    director: attr(),
    saler: attr(),
    contact1: attr(),
    contact2: attr(),
    commission: attr(),
  };

  static reducer({ type, payload }, Agent) {
    switch (type) {
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Agent.all().delete();

        payload.agents.forEach((agent) => {
          Agent.upsert(agent);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
        payload.agents.forEach((agent) => {
          Agent.upsert(agent);
        });

        break;
      case ActionTypes.AGENT_CREATE:
      case ActionTypes.AGENT_CREATE_HANDLE:
      case ActionTypes.AGENT_UPDATE__SUCCESS:
      case ActionTypes.AGENT_UPDATE_HANDLE:
        Agent.upsert(payload.agent);

        break;
      case ActionTypes.AGENT_CREATE__SUCCESS:
        Agent.withId(payload.localId).delete();
        Agent.upsert(payload.agent);

        break;
      // case ActionTypes.AGENT_CREATE_HANDLE:
      //   Agent.upsert(payload.agent);

      //   if (payload.agents) {
      //     payload.agents.forEach((agent) => {
      //       Agent.upsert(agent);
      //     });
      //   }

      //   break;
      case ActionTypes.AGENT_UPDATE:
        Agent.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.AGENT_DELETE:
        Agent.withId(payload.id).delete();

        break;
      case ActionTypes.AGENT_DELETE__SUCCESS:
      case ActionTypes.AGENT_DELETE_HANDLE: {
        const agentModel = Agent.withId(payload.agent.id);

        if (agentModel) {
          agentModel.delete();
        }

        break;
      }
      default:
    }
  }
}
