import socket from './socket';

/* Actions */

const createSellitemLabel = (sellitemId, data, headers) =>
  socket.post(`/sellitems/${sellitemId}/labels`, data, headers);

const deleteSellitemLabel = (sellitemId, labelId, headers) =>
  socket.delete(`/sellitems/${sellitemId}/labels/${labelId}`, undefined, headers);

export default {
  createSellitemLabel,
  deleteSellitemLabel,
};
