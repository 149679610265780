/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { Button, Divider, Header, Tab } from 'semantic-ui-react';

import InformationEdit from './InformationEdit';
import DeletePopup from '../../DeletePopup';

import styles from './GeneralPane.module.scss';

const GeneralPane = React.memo(
  ({
    name,
    description,
    province,
    district,
    ward,
    address,
    startDate,
    openDate,
    paymentMethod,
    isAutoUpdateProduct,
    isAutoUpdateSellitem,
    onUpdate,
    onDelete,
  }) => {
    const [t] = useTranslation();

    return (
      <Tab.Pane attached={false} className={styles.wrapper}>
        <InformationEdit
          defaultData={{
            name,
            description,
            province,
            district,
            ward,
            address,
            startDate,
            openDate,
            paymentMethod,
            isAutoUpdateProduct,
            isAutoUpdateSellitem,
          }}
          onUpdate={onUpdate}
        />
        {/* <Divider horizontal section>
        <Header as="h4">
          {t('common.dangerZone', {
            context: 'title',
          })}
        </Header>
      </Divider>
      <div className={styles.action}>
        <DeletePopup
          title={t('common.deleteProject', {
            context: 'title',
          })}
          content={t('common.areYouSureYouWantToDeleteThisProject')}
          buttonContent={t('action.deleteProject')}
          onConfirm={onDelete}
        >
          <Button className={styles.actionButton}>
            {t('action.deleteProject', {
              context: 'title',
            })}
          </Button>
        </DeletePopup>
      </div> */}
      </Tab.Pane>
    );
  },
);

GeneralPane.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  province: PropTypes.string,
  district: PropTypes.string,
  ward: PropTypes.string,
  address: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  openDate: PropTypes.instanceOf(Date),
  isAutoUpdateProduct: PropTypes.bool.isRequired,
  isAutoUpdateSellitem: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paymentMethod: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
GeneralPane.defaultProps = {
  description: '',
  province: '',
  district: '',
  ward: '',
  address: '',
  startDate: undefined,
  openDate: undefined,
};
export default GeneralPane;
