import XLSX from 'sheetjs-style';

const exportXlsx = (payments, colHeaders, project) => {
  const s = {
    border: {
      top: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
      bottom: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
    },
  };
  const hs = {
    ...s,
    alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
    font: {
      bold: true,
    },
  };
  const h1 = {
    alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    font: {
      sz: 12,
      bold: true,
    },
  };
  const h2 = {
    ...s,
    alignment: { horizontal: 'center', vertical: 'center' },
    font: {
      sz: 14,
      bold: true,
    },
  };

  const data = payments.map((pm, index) => ({
    STT: { t: 'n', v: index + 1, s },
    productName: { v: pm.product?.name || '', s },
    customerName: { v: pm.customer?.name || '', s },
    phone: { v: pm.customer?.phone || '', s },
    paymentName: { v: pm.name, s },
    agent: { v: pm.product?.agent?.name || '', s },
    agentSelled: { v: pm.sellitem?.agent?.name || '', s },
    percent: { t: 'n', v: pm.percent / 100 || 0, z: '0.00%', s },
    amount: { t: 'n', v: pm.amount || 0, z: '#,##0', s },
    dueDate: { v: pm.dueDate || '', s },
    statusPayment: { v: pm.statusLabel || '', s },
    accountId: { v: pm.account?.name || '', s },
    gotDate: { v: pm.gotDate || '', s },
    note: { v: pm.note || '', s },
  }));

  const data2 = [
    {
      STT: {
        v: `CÔNG TY CỔ PHẦN ĐẦU TƯ - PHÁT TRIỂN ĐÔ THỊ ÂN PHÚ
      28 - Phan Bội Châu - Phường Hòa Thuận - Tam Kỳ - Quảng Nam`,
        s: h1,
      },
      productName: { v: '', s: h1 },
      customerName: { v: '', s: h1 },
      phone: { v: '', s: h1 },
      agent: { v: '', s: h1 },
      agentSelled: { v: '', s: h1 },
      paymentName: { v: '', s: h1 },
      percent: { v: '', s: h1 },
      amount: { v: '', s: h1 },
      dueDate: { v: '', s: h1 },
      statusPayment: { v: '', s: h1 },
      accountId: { v: '', s: h1 },
      gotDate: { v: '', s: h1 },
      note: { v: '', s: h1 },
    },
    {
      STT: {
        v: '',
        s: h2,
      },
      productName: { v: '', s: h2 },
      customerName: { v: '', s: h2 },
      phone: { v: '', s: h2 },
      agent: { v: '', s: h2 },
      agentSelled: { v: '', s: h2 },
      paymentName: { v: '', s: h2 },
      percent: { v: '', s: h2 },
      amount: { v: '', s: h2 },
      dueDate: { v: '', s: h2 },
      statusPayment: { v: '', s: h2 },
      accountId: { v: '', s: h2 },
      gotDate: { v: '', s: h2 },
      note: { v: '', s: h2 },
    },
    {
      STT: { v: colHeaders[0], s: hs },
      productName: { v: colHeaders[1], s: hs },
      customerName: { v: colHeaders[2], s: hs },
      phone: { v: colHeaders[3], s: hs },
      agent: { v: colHeaders[4], s: hs },
      agentSelled: { v: colHeaders[5], s: hs },
      paymentName: { v: colHeaders[6], s: hs },
      percent: { v: colHeaders[7], s: hs },
      amount: { v: colHeaders[8], s: hs },
      dueDate: { v: colHeaders[9], s: hs },
      statusPayment: { v: colHeaders[10], s: hs },
      accountId: { v: colHeaders[11], s: hs },
      gotDate: { v: colHeaders[12], s: hs },
      note: { v: colHeaders[13], s: hs },
    },
  ];

  const ws = XLSX.utils.json_to_sheet(data2.concat(data), { skipHeader: true });
  const wscols = [
    { wch: 4 }, // stt
    { wch: 14 }, // productName
    { wch: 16 }, // customerName
    { wch: 14 }, // phone
    { wch: 14 }, // agent
    { wch: 14 }, // agentSelled
    { wch: 16 }, // paymentName
    { wch: 8 }, // percent
    { wch: 16 }, // amount
    { wch: 14 }, // dueDate
    { wch: 10 }, // statusPayment
    { wch: 14 }, // accountId
    { wch: 12 }, // gotDate
    { wch: 16 }, // note
  ];
  ws['!cols'] = wscols;
  ws['!rows'] = [{ hpx: 36 }, { hpx: 36 }];

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 13 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 13 } },
  ];
  ws['!merges'] = merge;

  const wb = { Sheets: { 'Sheet 1': ws }, SheetNames: ['Sheet 1'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    cellStyles: true,
    skipHeader: true,
    type: 'array',
  });
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const blob = new Blob([excelBuffer], { type: fileType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${project.name}.xlsx`;
  link.click();
};

export default exportXlsx;
