import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  labelsForCurrentGroupSelector,
  makeProductByIdSelector,
  currentSellitemByProductIdSelector,
  membershipsForCurrentGroupSelector,
  pathSelector,
  projectsToBlocksForCurrentUserSelector,
} from '../selectors';
import {
  createLabelInCurrentGroup,
  deleteProduct,
  deleteLabel,
  fetchGroup,
  moveProduct,
  // transferProduct,
  updateLabel,
  updateProduct,
} from '../actions/entry';
import Product from '../components/Product';

const makeMapStateToProps = () => {
  const productByIdSelector = makeProductByIdSelector();

  return (state, { id, index }) => {
    const { projectId } = pathSelector(state);
    const allProjectsToBlocks = projectsToBlocksForCurrentUserSelector(state);
    const allGroupMemberships = membershipsForCurrentGroupSelector(state);
    const allLabels = labelsForCurrentGroupSelector(state);
    const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
    const currentSellitem = currentSellitemByProductIdSelector(state, id);

    const {
      name,
      dueDate,
      landArea,
      floorArea,
      landPrice,
      floorPrice,
      factor,
      status,
      timer,
      coverUrl,
      groupId,
      blockId,
      isPersisted,
    } = productByIdSelector(state, id);

    const users = [];
    const labels = [];
    const notificationsTotal = 0;

    return {
      id,
      index,
      name,
      status,
      sellitem: currentSellitem,
      productPrice: Math.round(landArea * landPrice * factor + floorArea * floorPrice, 0),
      dueDate,
      timer,
      coverUrl,
      groupId,
      blockId,
      projectId,
      isPersisted,
      notificationsTotal,
      users,
      labels,
      allProjectsToBlocks,
      allGroupMemberships,
      allLabels,
      canEdit: isCurrentUserMember,
    };
  };
};

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      onUpdate: (data) => updateProduct(id, data),
      onMove: (blockId, index) => moveProduct(id, blockId, index),
      // onTransfer: (groupId, blockId) => transferProduct(id, groupId, blockId),
      onDelete: () => deleteProduct(id),
      onGroupFetch: fetchGroup,
      onLabelCreate: (data) => createLabelInCurrentGroup(data),
      onLabelUpdate: (labelId, data) => updateLabel(labelId, data),
      onLabelDelete: (labelId) => deleteLabel(labelId),
    },
    dispatch,
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(Product);
