import EntryActionTypes from '../../constants/EntryActionTypes';

export const createAccountInCurrentProject = (data) => ({
  type: EntryActionTypes.ACCOUNT_IN_CURRENT_PROJECT_CREATE,
  payload: {
    data,
  },
});

export const createAccount = (account) => ({
  type: EntryActionTypes.ACCOUNT_CREATE,
  payload: {
    account,
  },
});

export const handleAccountCreate = (account) => ({
  type: EntryActionTypes.ACCOUNT_CREATE_HANDLE,
  payload: {
    account,
  },
});

export const updateAccount = (id, data) => ({
  type: EntryActionTypes.ACCOUNT_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleAccountUpdate = (account) => ({
  type: EntryActionTypes.ACCOUNT_UPDATE_HANDLE,
  payload: {
    account,
  },
});

export const deleteAccount = (id) => ({
  type: EntryActionTypes.ACCOUNT_DELETE,
  payload: {
    id,
  },
});

export const handleAccountDelete = (account) => ({
  type: EntryActionTypes.ACCOUNT_DELETE_HANDLE,
  payload: {
    account,
  },
});
