import { all, takeEvery } from 'redux-saga/effects';

import {
  createProductService,
  handleProductCreateService,
  updateProductService,
  handleProductUpdateService,
  moveProductService,
  moveCurrentProductService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* productWatchers() {
  yield all([
    takeEvery(EntryActionTypes.PRODUCT_CREATE, ({ payload: { blockId, data } }) =>
      createProductService(blockId, data),
    ),
    takeEvery(EntryActionTypes.PRODUCT_CREATE_HANDLE, ({ payload: { product } }) =>
      handleProductCreateService(product),
    ),
    takeEvery(EntryActionTypes.PRODUCT_UPDATE, ({ payload: { id, data } }) =>
      updateProductService(id, data),
    ),
    takeEvery(EntryActionTypes.PRODUCT_MOVE, ({ payload: { id, blockId, index } }) =>
      moveProductService(id, blockId, index),
    ),
    takeEvery(EntryActionTypes.CURRENT_PRODUCT_MOVE, ({ payload: { blockId, index } }) =>
      moveCurrentProductService(blockId, index),
    ),
    takeEvery(EntryActionTypes.PRODUCT_UPDATE_HANDLE, ({ payload: { product } }) =>
      handleProductUpdateService(product),
    ),
  ]);
}
