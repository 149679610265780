import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { lastSellitemIdByGroupIdSelector, pathSelector } from '../../../selectors';
import { fetchSellitems } from '../../../actions';
import api from '../../../api';

export function* fetchSellitemsService(groupId) {
  const lastId = yield select(lastSellitemIdByGroupIdSelector, groupId);

  yield put(fetchSellitems(groupId));

  let sellitems;
  let customers;
  let sellitemMemberships;
  let sellitemLabels;
  let sellitemCustomers;
  let products;
  let payments;
  let attachments;

  try {
    ({
      items: sellitems,
      included: {
        customers,
        sellitemMemberships,
        sellitemLabels,
        sellitemCustomers,
        products,
        payments,
        attachments,
      },
    } = yield call(request, api.getSellitems, groupId, {
      beforeId: lastId,
    }));
  } catch (error) {
    yield put(fetchSellitems.failure(groupId, error));
    return;
  }

  yield put(
    fetchSellitems.success(
      groupId,
      sellitems,
      customers,
      sellitemMemberships,
      sellitemLabels,
      sellitemCustomers,
      products,
      payments,
      attachments,
    ),
  );
}

export function* fetchSellitemsInCurrentGroupService() {
  const { groupId } = yield select(pathSelector);

  yield call(fetchSellitemsService, groupId);
}
