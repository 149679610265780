import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Statuses } from '../constants/Enums';

import {
  pathSelector,
  sellitemByIdSelector,
  isAdminSelector,
  isManagerSelector,
  isSaleAdminSelector,
  isCurrentUserMemberForCurrentGroupSelector,
  productsForCurrentGroupSelector,
  agentsSelector,
  customersForCurrentSellitemSelector,
  paymentsForCurrentSellitemSelector,
  actionsForCurrentSellitemSelector,
  discountsForCurrentSellitemSelector,
} from '../selectors';
import {
  clearUserCreateError,
  createSellitem,
  updateSellitem,
  searchCustomers,
  updateProduct,
  createCustomer,
  createSellitemCustomer,
  deleteSellitemCustomer,
  createSellitemDiscount,
  deleteSellitemDiscount,
} from '../actions/entry';
import EditForm from '../components/Sellitems/EditForm';

const mapStateToProps = (state) => {
  const { sellitemId } = pathSelector(state);
  let sellitem;
  let customers;
  let discounts;
  let payments;
  if (sellitemId) {
    sellitem = sellitemByIdSelector(state, sellitemId);
    customers = customersForCurrentSellitemSelector(state, sellitemId);
    discounts = discountsForCurrentSellitemSelector(state, sellitemId);
    payments = paymentsForCurrentSellitemSelector(state);
  }

  const products = productsForCurrentGroupSelector(state);
  const agents = agentsSelector(state);

  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);
  const isSaleAdmin = isSaleAdminSelector(state);
  const isManager = isManagerSelector(state);

  const actions = actionsForCurrentSellitemSelector(state);
  const oldStatus = actions.find(
    (ac) => ac.data?.prop === 'status' && ac.data?.newValue === Statuses.REVIEWCANCEL.name,
  )?.data.oldValue;

  return {
    canEdit: isAdmin || (isCurrentUserMember && isSaleAdmin),
    isManager,
    defaultData: { ...sellitem },
    products,
    discounts,
    agents,
    payments,
    sellitemId,
    sellitem,
    customers,
    oldStatus,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreateSellitem: createSellitem,
      onCreateSellitemDiscount: createSellitemDiscount,
      onDeleteSellitemDiscount: (sellitemId, discountId) =>
        deleteSellitemDiscount(sellitemId, discountId),
      onSearchCustomers: searchCustomers,
      onUpdateSellitem: (data) => updateSellitem(data.id, data),
      onMessageDismiss: clearUserCreateError,
      onUpdateProduct: (data) => updateProduct(data.id, data),
      onCreateCustomer: createCustomer,
      onCreateSellitemCustomer: createSellitemCustomer,
      onDeleteSellitemCustomer: (sellitemId, currenCustomerId) =>
        deleteSellitemCustomer(sellitemId, currenCustomerId),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
