import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Popup } from '../../lib/custom-ui';
import { withPopup } from '../../lib/popup';

import styles from './ProductInfosPopup.module.scss';

const CategoriesStep = React.memo(
  ({ item, allCategories, title, onCategorySelect, onBack, onClose }) => {
    const [t] = useTranslation();
    const categoryOptions = allCategories.map((it) => ({ value: it.id, label: it.name, id: 0 }));
    const [selectedCategory, setSelectedCategory] = useState({
      value: item.id,
      label: item.name,
      id: item.id,
    });

    const handleAgentChange = useCallback(
      (selected) => {
        setSelectedCategory(selected);
        onCategorySelect(selected);
        onClose();
      },
      [onCategorySelect, setSelectedCategory, onClose],
    );

    return (
      <>
        <Popup.Header onBack={onBack}>{title}</Popup.Header>
        <Popup.Content className={styles.select}>
          <Select
            isClearable={false}
            placeholder={t('common.selectCategory')}
            noOptionsMessage={() => t('common.noOptionsMessage')}
            components={{ ClearIndicator: null }}
            value={selectedCategory}
            name="agent"
            options={categoryOptions}
            onChange={handleAgentChange}
          />
        </Popup.Content>
      </>
    );
  },
);

CategoriesStep.propTypes = {
  item: PropTypes.objectOf(PropTypes.shape).isRequired,
  allCategories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  title: PropTypes.string,
  onCategorySelect: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
};

CategoriesStep.defaultProps = {
  title: 'common.agent',
  onBack: undefined,
  onClose: undefined,
};

export default withPopup(CategoriesStep);
