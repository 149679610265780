import { all, takeEvery } from 'redux-saga/effects';

import {
  createCustomerService,
  deleteCustomerService,
  handleCustomerCreateService,
  handleCustomerDeleteService,
  handleCustomerUpdateService,
  updateCustomerService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* customerWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CUSTOMER_CREATE, ({ payload: { data } }) =>
      createCustomerService(data),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_CREATE_HANDLE, ({ payload: { customer } }) =>
      handleCustomerCreateService(customer),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_UPDATE, ({ payload: { id, data } }) =>
      updateCustomerService(id, data),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_UPDATE_HANDLE, ({ payload: { customer } }) =>
      handleCustomerUpdateService(customer),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_DELETE, ({ payload: { id } }) => deleteCustomerService(id)),
    takeEvery(EntryActionTypes.CUSTOMER_DELETE_HANDLE, ({ payload: { customer } }) =>
      handleCustomerDeleteService(customer),
    ),
  ]);
}
