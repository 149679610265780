import { call, put, select } from 'redux-saga/effects';

import { goToGroupService, goToProjectService } from './router';
import request from '../request';
import { groupByIdSelector, nextGroupPositionSelector, pathSelector } from '../../../selectors';
import {
  createGroup,
  deleteGroup,
  fetchGroup,
  handleGroupCreate,
  handleGroupDelete,
  handleGroupUpdate,
  updateGroup,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createGroupService(projectId, data) {
  const nextData = {
    ...data,
    position: yield select(nextGroupPositionSelector, projectId),
  };

  const localId = yield call(createLocalId);

  yield put(
    createGroup({
      ...nextData,
      projectId,
      id: localId,
    }),
  );

  let group;
  let groupMemberships;

  try {
    ({
      item: group,
      included: { groupMemberships },
    } = yield call(request, api.createGroup, projectId, nextData));
  } catch (error) {
    yield put(createGroup.failure(localId, error));
    return;
  }

  yield put(createGroup.success(localId, group, groupMemberships));
  yield call(goToGroupService, group.id);
}

export function* createGroupInCurrentProjectService(data) {
  const { projectId } = yield select(pathSelector);

  yield call(createGroupService, projectId, data);
}

export function* handleGroupCreateService(group) {
  yield put(handleGroupCreate(group));
}

export function* fetchGroupService(id) {
  yield put(fetchGroup(id));

  let group;
  let users;
  let projects;
  let groupMemberships;
  let labels;
  let blocks;
  let customers;
  let products;
  let bags;
  let bagitems;
  let discounts;
  let sellitems;
  let sellitemMemberships;
  let sellitemLabels;
  let sellitemCustomers;
  let sellitemDiscounts;
  let payments;
  let attachments;

  try {
    ({
      item: group,
      included: {
        users,
        projects,
        groupMemberships,
        labels,
        blocks,
        customers,
        products,
        bags,
        bagitems,
        discounts,
        sellitems,
        sellitemMemberships,
        sellitemLabels,
        sellitemCustomers,
        sellitemDiscounts,
        payments,
        attachments,
      },
    } = yield call(request, api.getGroup, id));
  } catch (error) {
    yield put(fetchGroup.failure(id, error));
    return;
  }

  yield put(
    fetchGroup.success(
      group,
      users,
      projects,
      groupMemberships,
      labels,
      blocks,
      customers,
      products,
      bags,
      bagitems,
      discounts,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      sellitemCustomers,
      sellitemDiscounts,
      payments,
      attachments,
    ),
  );
}

export function* updateGroupService(id, data) {
  yield put(updateGroup(id, data));

  let group;
  try {
    ({ item: group } = yield call(request, api.updateGroup, id, data));
  } catch (error) {
    yield put(updateGroup.failure(id, error));
    return;
  }

  yield put(updateGroup.success(group));
}

export function* handleGroupUpdateService(group) {
  yield put(handleGroupUpdate(group));
}

export function* moveGroupService(id, index) {
  const { projectId } = yield select(groupByIdSelector, id);
  const position = yield select(nextGroupPositionSelector, projectId, index, id);

  yield call(updateGroupService, id, {
    position,
  });
}

export function* deleteGroupService(id) {
  const { groupId, projectId } = yield select(pathSelector);

  if (id === groupId) {
    yield call(goToProjectService, projectId);
  }

  yield put(deleteGroup(id));

  let group;
  try {
    ({ item: group } = yield call(request, api.deleteGroup, id));
  } catch (error) {
    yield put(deleteGroup.failure(id, error));
    return;
  }

  yield put(deleteGroup.success(group));
}

export function* handleGroupDeleteService(group) {
  const { groupId, projectId } = yield select(pathSelector);

  if (group.id === groupId) {
    yield call(goToProjectService, projectId);
  }

  yield put(handleGroupDelete(group));
}
