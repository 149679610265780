import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Header, Modal } from 'semantic-ui-react';
import { Input } from '../../lib/custom-ui';

import { useForm } from '../../hooks';

import styles from './ProjectAddModal.module.scss';

const ProjectAddModal = React.memo(({ defaultData, isSubmitting, onCreate, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange] = useForm(() => ({
    name: '',
    ...defaultData,
  }));

  const nameField = useRef(null);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      name: data.name.trim(),
      description: data.description?.trim() || undefined,
    };

    if (!cleanData.name) {
      nameField.current.select();
      return;
    }

    onCreate(cleanData);
  }, [onCreate, data]);

  useEffect(() => {
    nameField.current.select();
  }, []);

  return (
    <Modal open closeIcon size="tiny" onClose={onClose}>
      <Modal.Content className={styles.wrapper}>
        <Header size="huge">
          {t('common.createProject', {
            context: 'title',
          })}
        </Header>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label htmlFor="name">{t('common.enterProjectTitle')}</label>
            <Input
              fluid
              ref={nameField}
              name="name"
              value={data.name}
              readOnly={isSubmitting}
              className={styles.field}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="description">{t('common.enterProjectDescription')}</label>
            <Input
              fluid
              name="description"
              value={data.description}
              readOnly={isSubmitting}
              className={styles.field}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Button
            color="green"
            icon="checkmark"
            content={t('action.createProject')}
            floated="right"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </Form>
      </Modal.Content>
    </Modal>
  );
});

ProjectAddModal.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isSubmitting: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProjectAddModal;
