import { Model, attr, many } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';
import { ProjectBackgroundTypes } from '../constants/Enums';

export default class extends Model {
  static modelName = 'Project';

  static fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    province: attr(),
    district: attr(),
    ward: attr(),
    address: attr(),
    startDate: attr(),
    openDate: attr(),
    paymentMethod: attr(),
    paymentDeposit: attr(),
    paymentConfirm1: attr(),
    paymentConfirm2: attr(),
    contract1: attr(),
    contract2: attr(),
    contract3: attr(),
    contract4: attr(),
    appendix1: attr(),
    appendix2: attr(),
    appendix3: attr(),
    appendix4: attr(),
    appendix5: attr(),
    background: attr(),
    backgroundImage: attr(),
    isAutoUpdateSellitem: attr({
      getDefault: () => false,
    }),
    isAutoUpdateProduct: attr({
      getDefault: () => false,
    }),
    isBackgroundImageUpdating: attr({
      getDefault: () => false,
    }),
    managerUsers: many({
      to: 'User',
      through: 'ProjectManager',
      relatedName: 'projects',
    }),
    agents: many({
      to: 'Agent',
      as: 'agent',
      relatedName: 'projects',
    }),
    categories: many({
      to: 'Category',
      as: 'category',
      relatedName: 'projects',
    }),
    accounts: many({
      to: 'Account',
      as: 'account',
      relatedName: 'projects',
    }),
  };

  static reducer({ type, payload }, Project) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        if (payload.projects) {
          payload.projects.forEach((project) => {
            Project.upsert(project);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Project.all().delete();

        payload.projects.forEach((project) => {
          Project.upsert(project);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.projects.forEach((project) => {
          Project.upsert(project);
        });

        break;
      case ActionTypes.PROJECT_CREATE__SUCCESS:
      case ActionTypes.PROJECT_CREATE_HANDLE:
      case ActionTypes.PROJECT_UPDATE__SUCCESS:
      case ActionTypes.PROJECT_UPDATE_HANDLE:
        Project.upsert(payload.project);

        break;
      case ActionTypes.PROJECT_UPDATE: {
        const project = Project.withId(payload.id);
        project.update(payload.data);

        if (
          payload.data.backgroundImage === null &&
          project.background &&
          project.background.type === ProjectBackgroundTypes.IMAGE
        ) {
          project.background = null;
        }

        break;
      }
      case ActionTypes.PROJECT_BACKGROUND_IMAGE_UPDATE:
        Project.withId(payload.id).update({
          isBackgroundImageUpdating: true,
        });

        break;
      case ActionTypes.PROJECT_BACKGROUND_IMAGE_UPDATE__SUCCESS:
        Project.withId(payload.project.id).update({
          ...payload.project,
          isBackgroundImageUpdating: false,
        });

        break;
      case ActionTypes.PROJECT_BACKGROUND_IMAGE_UPDATE__FAILURE:
        Project.withId(payload.id).update({
          isBackgroundImageUpdating: false,
        });

        break;
      case ActionTypes.PROJECT_DELETE:
        Project.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.PROJECT_DELETE__SUCCESS:
      case ActionTypes.PROJECT_DELETE_HANDLE: {
        const projectModel = Project.withId(payload.project.id);

        if (projectModel) {
          projectModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        if (payload.project) {
          const projectModel = Project.withId(payload.project.id);

          if (projectModel) {
            projectModel.deleteWithRelated();
          }

          Project.upsert(payload.project);
        }

        break;
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE__PROJECT_FETCH:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE__PROJECT_FETCH: {
        const projectModel = Project.withId(payload.id);

        if (projectModel) {
          projectModel.groups.toModelArray().forEach((groupModel) => {
            if (groupModel.id !== payload.currentGroupId) {
              groupModel.update({
                isFetching: null,
              });

              groupModel.deleteRelated(payload.currentUserId);
            }
          });
        }

        break;
      }
      default:
    }
  }

  getOrderedManagersQuerySet() {
    return this.managers.orderBy('id');
  }

  getOrderedGroupsQuerySet() {
    return this.groups.orderBy('position');
  }

  getOrderedAvailableGroupsModelArray(userId) {
    if (this.hasManagerUser(userId)) {
      return this.getOrderedGroupsQuerySet().toModelArray();
    }

    return this.getOrderedGroupsQuerySet()
      .toModelArray()
      .filter((groupModel) => groupModel.hasMemberUser(userId));
  }

  hasManagerUser(userId) {
    return this.managers
      .filter({
        userId,
      })
      .exists();
  }

  hasMemberUserForAnyGroup(userId) {
    return this.groups.toModelArray().some((groupModel) => groupModel.hasMemberUser(userId));
  }

  deleteRelated() {
    this.managers.delete();

    this.groups.toModelArray().forEach((groupModel) => {
      groupModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
