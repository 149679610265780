import ActionTypes from '../constants/ActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const fetchSellitems = (groupId) => ({
  type: ActionTypes.SELLITEMS_FETCH,
  payload: {
    groupId,
  },
});

fetchSellitems.success = (
  groupId,
  sellitems,
  customers,
  sellitemMemberships,
  sellitemLabels,
  sellitemCustomers,
  products,
  payments,
  attachments,
) => ({
  type: ActionTypes.SELLITEMS_FETCH__SUCCESS,
  payload: {
    groupId,
    sellitems,
    customers,
    sellitemMemberships,
    sellitemLabels,
    sellitemCustomers,
    products,
    payments,
    attachments,
  },
});

fetchSellitems.failure = (groupId, error) => ({
  type: ActionTypes.SELLITEMS_FETCH__FAILURE,
  payload: {
    groupId,
    error,
  },
});
