/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List, Segment, Button } from 'semantic-ui-react';

import NameEdit from '../Product/NameEdit';
import AgentsPopup from '../ProductInfosPopup';
import CategoriesPopup from '../ProductInfosPopup/CategoriesPopup';
import ProjectAgentsPopup from './ActionsPopup/ProjectAgentsPopup';
import { useModal } from '../../hooks';

import styles from './RightSidebar.module.scss';

const RightSidebar = React.memo(
  ({
    product,
    defaultColor,
    sellitems,
    categories,
    agents,
    isPersisted,
    projectAgents,
    canEdit,
    onUpdate,
    onProjectAgentCreate,
  }) => {
    const [t] = useTranslation();

    const nameEdit = useRef(null);
    const sellPriceEdit = useRef(null);
    const weightAmountEdit = useRef(null);

    const handleHeaderClick = useCallback(
      (field) => {
        if (isPersisted && canEdit) {
          switch (field) {
            case 'name':
              nameEdit.current.open();
              break;
            case 'sellPrice':
              sellPriceEdit.current.open();
              break;
            case 'weightAmount':
              weightAmountEdit.current.open();
              break;
            default:
          }
        }
      },
      [isPersisted, canEdit],
    );

    const handleFieldUpdate = useCallback(
      (data, field) => {
        switch (field) {
          case 'name':
            onUpdate(product.id, {
              name: data,
            });
            break;
          case 'agent':
            onUpdate(product.id, {
              agentId: data.value,
            });
            break;
          case 'category':
            onUpdate(product.id, {
              categoryId: data.value,
            });
            break;
          case 'sellPrice':
            onUpdate(product.id, {
              sellPrice: data,
            });
            break;
          case 'weightAmount':
            onUpdate(product.id, {
              weightAmount: data,
            });
            break;
          default:
        }
      },
      [product.id, onUpdate],
    );

    const [totalProductInProjectAgent, setTotalProductInProjectAgent] = useState(0);
    useEffect(() => {
      let productInProjectAgent = 0;
      if (projectAgents.length > 0) {
        projectAgents.forEach((projectAgent) => {
          productInProjectAgent += projectAgent.quantity;
        });
      }
      setTotalProductInProjectAgent(
        productInProjectAgent -
          sellitems.filter(
            (sellItem) => sellItem.status !== 'canceledOk' && sellItem.status !== 'canceled',
          ).length,
      );
    }, [projectAgents, sellitems, setTotalProductInProjectAgent]);

    const [imageProduct, setImageUrl] = useState(product.imageUrl);
    useEffect(() => {
      setImageUrl(product.imageUrl);
      if (product.variation && product.variation.colors) {
        const color = product.variation.colors.find((item) => item.name === defaultColor);
        if (color && color.imageUrl) setImageUrl(color.imageUrl);
      }
    }, [defaultColor, product]);

    let sizes = '';
    if (product.variation) {
      if (product.variation.sizes) {
        product.variation.sizes.forEach((size, index) => {
          sizes += index === 0 ? size.name : `,${size.name}`;
        });
      }
      if (
        product.variation.colors &&
        product.variation.colors[0] &&
        product.variation.colors[0].sizes
      ) {
        product.variation.colors[0].sizes.forEach((size, index) => {
          sizes += index === 0 ? size.name : `,${size.name}`;
        });
      }
    } else {
      sizes = product.size;
    }

    const [modal, openModal, handleModalClose] = useModal();

    return (
      <Segment className={styles.details}>
        <List divided verticalAlign="middle">
          <List.Item>
            <List.Content
              as="h4"
              className={styles.productName}
              onClick={() => handleHeaderClick('name')}
            >
              {canEdit ? (
                <NameEdit
                  ref={nameEdit}
                  defaultValue={product.name}
                  onUpdate={(data) => handleFieldUpdate(data, 'name')}
                >
                  <div className={styles.headerName}>{product.name}</div>
                </NameEdit>
              ) : (
                <div className={styles.headerName}>{product.name}</div>
              )}
            </List.Content>
            <List.Content>
              <div className={styles.productInfo}>
                {canEdit ? (
                  <>
                    {/* <CategoriesPopup
                      item={product.productCategory}
                      allCategories={categories}
                      canEdit={canEdit}
                      title={t('common.category')}
                      onCategorySelect={(data) => handleFieldUpdate(data, 'category')}
                    >
                      <Button
                        className={styles.productCategory}
                        content={product.productCategory.name || ''}
                        size="tiny"
                      />
                    </CategoriesPopup>
                    <AgentsPopup
                      item={product.productAgent}
                      allAgents={agents}
                      canEdit={canEdit}
                      title={t('common.agent')}
                      onAgentSelect={(data) => handleFieldUpdate(data, 'agent')}
                    >
                      <Button
                        className={styles.productAgent}
                        content={product.productAgent.code || ''}
                        size="tiny"
                      />
                    </AgentsPopup> */}
                  </>
                ) : (
                  <>
                    <Button
                      className={styles.productCategory}
                      content={product.productCategory.name || ''}
                      size="tiny"
                    />
                    <Button
                      className={styles.productAgent}
                      content={product.productAgent.code || ''}
                      size="tiny"
                    />
                  </>
                )}
              </div>
              <div className={styles.lastestSellitem}>
                {`${t('common.orderLatest')}: `}
                <b>
                  {sellitems &&
                    sellitems.length > 0 &&
                    t('format:longDateTime', {
                      postProcess: 'formatDate',
                      value: new Date(sellitems[0].createdAt),
                    })}
                </b>
              </div>
              <div className={styles.productImage}>
                <img alt="" src={imageProduct} />
              </div>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right" className={styles.listVariation}>
              {product.variation && product.variation.colors
                ? product.variation.colors.map((color, index) =>
                    index === 0 ? color.name : `,${color.name}`,
                  )
                : product.color || ''}
            </List.Content>
            <List.Content>{t('common.color')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right" className={styles.listVariation}>
              {sizes}
            </List.Content>
            <List.Content>{t('common.size')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right" onClick={() => handleHeaderClick('sellPrice')}>
              {canEdit ? (
                <NameEdit
                  ref={sellPriceEdit}
                  defaultValue={product.sellPrice || ''}
                  onUpdate={(data) => handleFieldUpdate(data, 'sellPrice')}
                >
                  <div className={styles.headerName}>
                    {(product.sellPrice * 1).toLocaleString('en-GB')}
                  </div>
                </NameEdit>
              ) : (
                <div className={styles.headerName}>
                  {(product.sellPrice * 1).toLocaleString('en-GB')}
                </div>
              )}
            </List.Content>
            <List.Content>{t('common.sellPrice')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right" onClick={() => handleHeaderClick('weightAmount')}>
              {canEdit ? (
                <NameEdit
                  ref={weightAmountEdit}
                  defaultValue={product.weightAmount || ''}
                  onUpdate={(data) => handleFieldUpdate(data, 'weightAmount')}
                >
                  <div className={styles.headerName}>
                    {(product.weightAmount * 1).toLocaleString('en-GB')}
                  </div>
                </NameEdit>
              ) : (
                <div className={styles.headerName}>
                  {(product.weightAmount * 1).toLocaleString('en-GB')}
                </div>
              )}
            </List.Content>
            <List.Content>{t('common.weightAmount')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {(
                product.weightAmount * 1 +
                product.sellPrice * 1 +
                product.feeAmount * 1
              ).toLocaleString('en-GB')}
            </List.Content>
            <List.Content>{t('common.finalTotal')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {
                sellitems.filter(
                  (sellItem) => sellItem.status !== 'canceledOk' && sellItem.status !== 'canceled',
                ).length
              }
            </List.Content>
            <List.Content>{t('common.totalSold')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {sellitems.filter((sellItem) => sellItem.status === 'finish').length}
            </List.Content>
            <List.Content>{t('common.totalDelivered')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {
                sellitems.filter(
                  (sellItem) =>
                    sellItem.status !== 'canceledOk' &&
                    sellItem.status !== 'canceled' &&
                    sellItem.status !== 'finish',
                ).length
              }
            </List.Content>
            <List.Content>{t('common.totalNotDelivery')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {
                sellitems.filter(
                  (sellItem) =>
                    sellItem.status === 'webShipped' ||
                    sellItem.status === 'doneOrder' ||
                    sellItem.status === 'send',
                ).length
              }
            </List.Content>
            <List.Content>{t('common.totalOrderNotBeenVN')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{totalProductInProjectAgent}</List.Content>
            <List.Content>
              <span className={styles.projectAgentsLabel} onClick={openModal}>
                {t('common.totalProductInProjectAgent')}:{' '}
              </span>
              {modal && (
                <ProjectAgentsPopup projectAgents={projectAgents} onClose={handleModalClose} />
              )}
            </List.Content>
          </List.Item>
        </List>
      </Segment>
    );
  },
);

RightSidebar.propTypes = {
  isPersisted: PropTypes.bool.isRequired,
  defaultColor: PropTypes.string,
  sellitems: PropTypes.arrayOf(PropTypes.object()).isRequired,
  projectAgents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  agents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  product: PropTypes.objectOf(PropTypes.shape).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onProjectAgentCreate: PropTypes.func.isRequired,
};

RightSidebar.defaultProps = {
  defaultColor: undefined,
};

export default RightSidebar;
