import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  isAdminSelector,
  isSaleAdminSelector,
  blockIdsForCurrentGroupSelector,
  pathSelector,
} from '../selectors';
import { createBlockInCurrentGroup, moveProduct, moveBlock } from '../actions/entry';
import GroupKanban from '../components/GroupKanban';

const mapStateToProps = (state) => {
  const { productId } = pathSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);
  const isSaleAdmin = isSaleAdminSelector(state);
  const blockIds = blockIdsForCurrentGroupSelector(state);

  return {
    blockIds,
    isProductModalOpened: !!productId,
    canEdit: isAdmin || (isCurrentUserMember && isSaleAdmin),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onBlockCreate: createBlockInCurrentGroup,
      onBlockMove: moveBlock,
      onProductMove: moveProduct,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GroupKanban);
