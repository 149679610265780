import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';

import styles from './Item.module.scss';

const Item = React.memo(({ name, code, isPersisted, isActive, onSelect, onDeselect }) => {
  const handleToggleClick = useCallback(() => {
    if (isActive) {
      onDeselect();
    } else {
      onSelect();
    }
  }, [isActive, onSelect, onDeselect]);

  return (
    <div className={styles.wrapper}>
      <Button
        fluid
        content={`${name} - ${code}`}
        active={isActive}
        disabled={!isPersisted}
        className={classNames(styles.labelButton, isActive && styles.labelButtonActive)}
        onClick={handleToggleClick}
      />
    </div>
  );
});

Item.propTypes = {
  name: PropTypes.string,
  code: PropTypes.string,
  isPersisted: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
};

Item.defaultProps = {
  name: undefined,
  code: undefined,
};

export default Item;
