import { call } from 'redux-saga/effects';

import { fetchGroupByCurrentPathRequest } from './group';
import request from '../request';
import api from '../../../api';
import mergeRecords from '../../../utils/merge-records';
// eslint-disable-next-line import/prefer-default-export
export function* fetchCoreRequest() {
  const { item: user } = yield call(request, api.getCurrentUser);
  const { items: users1 } = yield call(request, api.getUsers);

  const {
    items: projects1,
    included: {
      projectManagers,
      agents,
      categories,
      accounts,
      groups,
      groupMemberships: groupMemberships1,
    },
  } = yield call(request, api.getProjects);

  let group;
  let customer;
  let sellitem;
  let users2;
  let projects2;
  let groupMemberships2;
  let labels;
  let blocks;
  let customers;
  let products;
  let bags;
  let bagitems;
  let discounts;
  let sellitems1;
  let sellitemMemberships;
  let sellitemLabels;
  let sellitemCustomers;
  let sellitemDiscounts;
  let payments;
  let attachments;

  try {
    ({
      group,
      customer,
      sellitem,
      users: users2,
      projects: projects2,
      groupMemberships: groupMemberships2,
      labels,
      blocks,
      customers,
      products,
      bags,
      bagitems,
      discounts,
      sellitems: sellitems1,
      sellitemMemberships,
      sellitemLabels,
      sellitemCustomers,
      sellitemDiscounts,
      payments,
      attachments,
    } = yield call(fetchGroupByCurrentPathRequest));
  } catch {} // eslint-disable-line no-empty

  const body = yield call(request, api.getNotifications);

  let { items: notifications } = body;

  const {
    included: { users: users3, sellitems: sellitems2, actions },
  } = body;

  if (sellitem) {
    const notificationIds = notifications.flatMap((item) =>
      item.sellitemId === sellitem.id ? [item.id] : [],
    );

    if (notificationIds.length > 0) {
      yield call(request, api.updateNotifications, notificationIds, {
        isRead: true,
      });

      notifications = notifications.filter(
        (notification) => !notificationIds.includes(notification.id),
      );
    }
  }

  return {
    user,
    group,
    users: mergeRecords(users1, users2, users3),
    projects: mergeRecords(projects1, projects2),
    projectManagers,
    agents,
    categories,
    accounts,
    groups,
    groupMemberships: mergeRecords(groupMemberships1, groupMemberships2),
    labels,
    blocks,
    customers,
    products,
    bags,
    bagitems,
    discounts,
    sellitems: mergeRecords(sellitems1, sellitems2),
    sellitemMemberships,
    sellitemLabels,
    sellitemCustomers,
    sellitemDiscounts,
    payments,
    attachments,
    actions,
    notifications,
    customer,
  };
}
