import EntryActionTypes from '../../constants/EntryActionTypes';

export const createProjectAgent = (data) => ({
  type: EntryActionTypes.PROJECT_AGENT_CREATE,
  payload: {
    data,
  },
});

export const handleProjectAgentCreate = (projectAgent) => ({
  type: EntryActionTypes.PROJECT_AGENT_CREATE_HANDLE,
  payload: {
    projectAgent,
  },
});
