import { all, takeEvery } from 'redux-saga/effects';

import {
  addLabelToSellitemService,
  addLabelToCurrentSellitemService,
  addLabelToFilterInCurrentGroupService,
  createLabelInCurrentGroupService,
  deleteLabelService,
  handleLabelCreateService,
  handleLabelDeleteService,
  handleLabelFromSellitemRemoveService,
  handleLabelToSellitemAddService,
  handleLabelUpdateService,
  removeLabelFromSellitemService,
  removeLabelFromCurrentSellitemService,
  removeLabelFromFilterInCurrentGroupService,
  updateLabelService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* labelWatchers() {
  yield all([
    takeEvery(EntryActionTypes.LABEL_IN_CURRENT_GROUP_CREATE, ({ payload: { data } }) =>
      createLabelInCurrentGroupService(data),
    ),
    takeEvery(EntryActionTypes.LABEL_CREATE_HANDLE, ({ payload: { label } }) =>
      handleLabelCreateService(label),
    ),
    takeEvery(EntryActionTypes.LABEL_UPDATE, ({ payload: { id, data } }) =>
      updateLabelService(id, data),
    ),
    takeEvery(EntryActionTypes.LABEL_UPDATE_HANDLE, ({ payload: { label } }) =>
      handleLabelUpdateService(label),
    ),
    takeEvery(EntryActionTypes.LABEL_DELETE, ({ payload: { id } }) => deleteLabelService(id)),
    takeEvery(EntryActionTypes.LABEL_DELETE_HANDLE, ({ payload: { label } }) =>
      handleLabelDeleteService(label),
    ),
    takeEvery(EntryActionTypes.LABEL_TO_SELLITEM_ADD, ({ payload: { id, sellitemId } }) =>
      addLabelToSellitemService(id, sellitemId),
    ),
    takeEvery(EntryActionTypes.LABEL_TO_CURRENT_SELLITEM_ADD, ({ payload: { id } }) =>
      addLabelToCurrentSellitemService(id),
    ),
    takeEvery(EntryActionTypes.LABEL_TO_SELLITEM_ADD_HANDLE, ({ payload: { sellitemLabel } }) =>
      handleLabelToSellitemAddService(sellitemLabel),
    ),
    takeEvery(EntryActionTypes.LABEL_FROM_SELLITEM_REMOVE, ({ payload: { id, sellitemId } }) =>
      removeLabelFromSellitemService(id, sellitemId),
    ),
    takeEvery(EntryActionTypes.LABEL_FROM_CURRENT_SELLITEM_REMOVE, ({ payload: { id } }) =>
      removeLabelFromCurrentSellitemService(id),
    ),
    takeEvery(
      EntryActionTypes.LABEL_FROM_SELLITEM_REMOVE_HANDLE,
      ({ payload: { sellitemLabel } }) => handleLabelFromSellitemRemoveService(sellitemLabel),
    ),
    takeEvery(EntryActionTypes.LABEL_TO_FILTER_IN_CURRENT_GROUP_ADD, ({ payload: { id } }) =>
      addLabelToFilterInCurrentGroupService(id),
    ),
    takeEvery(EntryActionTypes.LABEL_FROM_FILTER_IN_CURRENT_GROUP_REMOVE, ({ payload: { id } }) =>
      removeLabelFromFilterInCurrentGroupService(id),
    ),
  ]);
}
