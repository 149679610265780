import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { isLocalId } from '../utils/local-id';

export const makeAccountByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Account }, id) => {
      const accountModel = Account.withId(id);

      if (!accountModel) {
        return accountModel;
      }

      return {
        ...accountModel.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const accountByIdSelector = makeAccountByIdSelector();

export const currentAccountSelector = createSelector(
  orm,
  (state) => pathSelector(state).accountId,
  ({ Account }, id) => {
    if (!id) {
      return id;
    }

    const accountModel = Account.withId(id);

    if (!accountModel) {
      return accountModel;
    }

    return accountModel.ref;
  },
);

export const accountsSelector = createSelector(orm, ({ Account }) =>
  Account.getOrderedQuerySet().toRefArray(),
);
