import ActionTypes from '../constants/ActionTypes';

export const addStatusToGroupFilter = (key, groupId) => ({
  type: ActionTypes.STATUS_TO_GROUP_FILTER_ADD,
  payload: {
    key,
    groupId,
  },
});

export const removeStatusFromGroupFilter = (key, groupId) => ({
  type: ActionTypes.STATUS_FROM_GROUP_FILTER_REMOVE,
  payload: {
    key,
    groupId,
  },
});

export const addBagStatusToGroupFilter = (key, groupId) => ({
  type: ActionTypes.BAG_STATUS_TO_GROUP_FILTER_ADD,
  payload: {
    key,
    groupId,
  },
});

export const removeBagStatusFromGroupFilter = (key, groupId) => ({
  type: ActionTypes.BAG_STATUS_FROM_GROUP_FILTER_REMOVE,
  payload: {
    key,
    groupId,
  },
});

export const addProductStatusToGroupFilter = (key, groupId) => ({
  type: ActionTypes.PRODUCT_STATUS_TO_GROUP_FILTER_ADD,
  payload: {
    key,
    groupId,
  },
});

export const removeProductStatusFromGroupFilter = (key, groupId) => ({
  type: ActionTypes.PRODUCT_STATUS_FROM_GROUP_FILTER_REMOVE,
  payload: {
    key,
    groupId,
  },
});

export const removeAllProductStatusFromGroupFilter = (groupId) => ({
  type: ActionTypes.PRODUCT_STATUS_FROM_GROUP_FILTER_REMOVE_ALL,
  payload: {
    groupId,
  },
});
