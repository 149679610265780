/* eslint-disable import/prefer-default-export */
import ActionTypes from '../constants/ActionTypes';

export const fetchDashboard = (id) => ({
  type: ActionTypes.DASHBOARD_FETCH,
  payload: {
    id,
  },
});

fetchDashboard.success = (groupData, dashboard) => ({
  type: ActionTypes.DASHBOARD_FETCH__SUCCESS,
  payload: {
    groupData,
    dashboard,
  },
});

fetchDashboard.failure = (id, error) => ({
  type: ActionTypes.DASHBOARD_FETCH__FAILURE,
  payload: {
    id,
    error,
  },
});
