import { Model, attr } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Account';

  static fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    accountNumber: attr(),
    type: attr(),
    isCloded: attr(),
  };

  static reducer({ type, payload }, Account) {
    switch (type) {
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Account.all().delete();

        payload.accounts.forEach((account) => {
          Account.upsert(account);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
        payload.accounts.forEach((account) => {
          Account.upsert(account);
        });

        break;
      case ActionTypes.ACCOUNT_CREATE:
      case ActionTypes.ACCOUNT_CREATE_HANDLE:
      case ActionTypes.ACCOUNT_UPDATE__SUCCESS:
      case ActionTypes.ACCOUNT_UPDATE_HANDLE:
        Account.upsert(payload.account);

        break;
      case ActionTypes.ACCOUNT_CREATE__SUCCESS:
        Account.withId(payload.localId).delete();
        Account.upsert(payload.account);

        break;
      // case ActionTypes.ACCOUNT_CREATE_HANDLE:
      //   Account.upsert(payload.account);

      //   if (payload.accounts) {
      //     payload.accounts.forEach((account) => {
      //       Account.upsert(account);
      //     });
      //   }

      //   break;
      case ActionTypes.ACCOUNT_DELETE:
        Account.withId(payload.id).delete();

        break;
      case ActionTypes.ACCOUNT_DELETE__SUCCESS:
      case ActionTypes.ACCOUNT_DELETE_HANDLE: {
        const accountModel = Account.withId(payload.account.id);

        if (accountModel) {
          accountModel.delete();
        }

        break;
      }
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('id');
  }
}
