/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../ProgressBar';
import { ProductStatistics, ProductStatuses } from '../../constants/Enums';

import styles from './ProductWidget.module.scss';

const ProductWidget = React.memo(
  ({
    title,
    isProgress,
    total,
    value,
    color,
    onStatusToFilterAdd,
    onStatusFromFilterRemoveAll,
  }) => {
    const [t] = useTranslation();

    const handleUpdateStatus = useCallback(
      (status) => {
        onStatusFromFilterRemoveAll();
        switch (status) {
          case ProductStatistics.AVAILABLE.title:
            // onStatusToFilterAdd({ status: ProductStatuses.BUILDING.name });
            // onStatusToFilterAdd({ status: ProductStatuses.COMMING.name });
            onStatusToFilterAdd({ status: ProductStatuses.SELLING.name });
            break;
          case ProductStatistics.BOOKING.title:
            onStatusToFilterAdd({ status: ProductStatuses.LOCKED.name });
            break;
          case ProductStatistics.DEPOSIT.title:
          case ProductStatistics.SELLED.title:
            onStatusToFilterAdd({ status });
            break;
          default:
        }
      },
      [onStatusFromFilterRemoveAll, onStatusToFilterAdd],
    );

    return (
      <div className={styles.wrapper} onClick={() => handleUpdateStatus(title)}>
        <div className={styles.leftWrapper}>
          <span className={styles.title} style={{ color }}>
            {t(`statistics.${title}`)}
          </span>
          {isProgress && (
            <span className={styles.progressBar}>
              <ProgressBar total={total} value={value} color={color} />
            </span>
          )}
        </div>
        <div className={styles.rightWrapper}>
          <span className={styles.val}>{value}</span>
        </div>
      </div>
    );
  },
);

ProductWidget.propTypes = {
  title: PropTypes.string.isRequired,
  isProgress: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  onStatusToFilterAdd: PropTypes.func.isRequired,
  onStatusFromFilterRemoveAll: PropTypes.func.isRequired,
};

export default ProductWidget;
