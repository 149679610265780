import ActionTypes from '../constants/ActionTypes';

export const createGroupMembership = (groupMembership) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_CREATE,
  payload: {
    groupMembership,
  },
});

createGroupMembership.success = (localId, groupMembership) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_CREATE__SUCCESS,
  payload: {
    localId,
    groupMembership,
  },
});

createGroupMembership.failure = (localId, error) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleGroupMembershipCreate = (
  groupMembership,
  project,
  group,
  users,
  projectManagers,
  groups,
  groupMemberships,
  labels,
  customers,
  products,
  bags,
  bagitems,
  discounts,
  sellitems,
  sellitemMemberships,
  sellitemLabels,
  sellitemCustomers,
  sellitemDiscounts,
  payments,
  attachments,
) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE,
  payload: {
    groupMembership,
    project,
    group,
    users,
    projectManagers,
    groups,
    groupMemberships,
    labels,
    customers,
    products,
    bags,
    bagitems,
    discounts,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    sellitemCustomers,
    sellitemDiscounts,
    payments,
    attachments,
  },
});

handleGroupMembershipCreate.fetchProject = (id, currentUserId, currentGroupId) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE__PROJECT_FETCH,
  payload: {
    id,
    currentUserId,
    currentGroupId,
  },
});

export const deleteGroupMembership = (id) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_DELETE,
  payload: {
    id,
  },
});

deleteGroupMembership.success = (groupMembership) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_DELETE__SUCCESS,
  payload: {
    groupMembership,
  },
});

deleteGroupMembership.failure = (id, error) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleGroupMembershipDelete = (groupMembership) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_DELETE_HANDLE,
  payload: {
    groupMembership,
  },
});
