import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { Input, Popup } from '../../../lib/custom-ui';

import { useForm } from '../../../hooks';

import styles from './EditStep.module.scss';
import { DiscountTypes } from '../../../constants/Enums';

const EditStep = React.memo(({ defaultData, title, onCreate, onUpdate, onBack, onClose }) => {
  const [t] = useTranslation();

  const nameField = useRef(null);
  const [data, handleFieldChange] = useForm({
    name: '',
    description: '',
    type: '',
    amount: 0,
    quantity: 1,
    isClosed: false,
    ...defaultData,
  });
  // const [isClosed, setIsClosed] = useState(defaultData.isClosed);

  const [error, setError] = useState({
    name: false,
    description: false,
    type: false,
    amount: false,
    quantity: false,
  });

  // const handleToggleChange = useCallback(() => {
  //   setIsClosed(!isClosed);
  // }, [isClosed]);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      name: data.name.trim(),
      description: data.description.trim(),
      isClosed: false,
    };

    const err = {
      name: !cleanData.name,
      description: !cleanData.description,
      type: !cleanData.type,
      amount: Number.isNaN(parseInt(cleanData.amount, 10)),
      quantity: !cleanData.quantity,
    };
    setError(err);

    if (Object.values(err).some((e) => e)) {
      return;
    }

    if (title === 'action.editDiscount') {
      onUpdate(cleanData);
    } else {
      onCreate(cleanData);
    }

    onClose();
  }, [data, title, onClose, onUpdate, onCreate]);

  useEffect(() => {
    nameField.current.select();
  }, []);

  return (
    <>
      <Popup.Header onBack={onBack}>
        {t(title, {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field
            required
            error={error.name}
            onFocus={() => setError({ ...error, name: false })}
          >
            <Input
              fluid
              ref={nameField}
              name="name"
              value={data.name}
              // label={t('common.name')}
              className={styles.field}
              placeholder={`${t('common.name')} *`}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.TextArea
            error={error.description}
            onFocus={() => setError({ ...error, description: false })}
            name="description"
            value={data.description}
            className={styles.field}
            placeholder={`${t('common.description')} *`}
            onChange={handleFieldChange}
          />
          <Form.Group widths="equal">
            <Form.Dropdown
              fluid
              required
              search
              selection
              error={error.type}
              name="type"
              value={data.type}
              options={Object.values(DiscountTypes).map((v) => ({
                key: v,
                value: v,
                text: t(`common.${v}`),
              }))}
              className={styles.field}
              placeholder={`${t('common.type')} *`}
              onFocus={() => setError({ ...error, type: false })}
              onChange={handleFieldChange}
            />
            <Form.Field
              required
              error={error.amount}
              onFocus={() => setError({ ...error, amount: false })}
            >
              {/* <Input
                fluid
                name="amount"
                value={data.amount}
                className={styles.field}
                placeholder={`${t(`common.${data.type}`)} *`}
                onChange={handleFieldChange}
              /> */}
              <NumberFormat
                name="amount"
                className={styles.fieldNumber}
                value={data.amount}
                placeholder={`${t(`common.${data.type}`)} *`}
                thousandSeparator
                onValueChange={(values, sourceInfo) => {
                  handleFieldChange(sourceInfo, { name: 'amount', value: values.value });
                }}
              />
            </Form.Field>
          </Form.Group>
          {/* <Form.Field
            required
            error={error.quantity}
            onFocus={() => setError({ ...error, quantity: false })}
          >
            <Input
              fluid
              name="quantity"
              value={data.quantity}
              className={styles.field}
              placeholder={`${t('common.quantity')} *`}
              onChange={handleFieldChange}
            />
          </Form.Field> */}
          <Form.Group widths="equal">
            <Form.Field />
            {/* <Form.Checkbox
              name="isClosed"
              checked={isClosed}
              disabled={title === 'action.editDiscount'}
              label={t('action.setClosed')}
              onChange={handleToggleChange}
            /> */}
            <Button positive content={t('action.save')} />
          </Form.Group>
        </Form>
      </Popup.Content>
    </>
  );
});

EditStep.propTypes = {
  defaultData: PropTypes.objectOf(PropTypes.shape),
  title: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

EditStep.defaultProps = {
  defaultData: undefined,
  title: 'action.createDiscount',
  onBack: undefined,
};

export default EditStep;
