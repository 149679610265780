/* eslint-disable no-unused-vars */
import { call, put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { format } from 'date-fns';

import request from '../request';
import {
  currentGroupSelector,
  currentCustomerSelector,
  isCoreInitializingSelector,
  notificationIdsForCurrentSellitemSelector,
  pathsMatchSelector,
  currentProductSelector,
  currentBagSelector,
  defaultGroupSelector,
} from '../../../selectors';
import { handleLocationChange, fetchProduct, fetchBag, fetchDashboard } from '../../../actions';
import api from '../../../api';
import ActionTypes from '../../../constants/ActionTypes';
import Paths from '../../../constants/Paths';

export function* goToRootService() {
  yield put(push(Paths.ROOT));
}

export function* goToProjectService(projectId) {
  yield put(push(Paths.PROJECTS.replace(':id', projectId)));
}

export function* goToGroupService(groupId) {
  yield put(push(Paths.GROUPS.replace(':id', groupId)));
}

export function* goToCustomerService(customerId) {
  yield put(push(Paths.CUSTOMERS.replace(':id', customerId)));
}

export function* goToProductService(productId) {
  yield put(push(Paths.PRODUCTS.replace(':id', productId)));
}

export function* goToBagService(bagId) {
  yield put(push(Paths.BAGS.replace(':id', bagId)));
}

export function* goToSellitemService(sellitemId) {
  yield put(push(Paths.SELLITEMS.replace(':id', sellitemId)));
}

export function* handleLocationChangeService() {
  const pathsMatch = yield select(pathsMatchSelector);

  if (!pathsMatch) {
    return;
  }

  switch (pathsMatch.path) {
    case Paths.LOGIN:
      yield call(goToRootService);

      break;
    default:
  }

  const isCoreInitializing = yield select(isCoreInitializingSelector);

  if (isCoreInitializing) {
    yield take(ActionTypes.CORE_INITIALIZE);
  }

  let group;
  let customer;
  let block;
  let users;
  let projects;
  let groupMemberships;
  let labels;
  let blocks;
  let customers;
  let products;
  let bags;
  let bagitems;
  let discounts;
  let product;
  let bag;
  let sellitems;
  let sellitemMemberships;
  let sellitemLabels;
  let sellitemCustomers;
  let sellitemDiscounts;
  let payments;
  let attachments;
  let notifications;
  let projectAgents;
  let dashboard;
  let groupData;

  switch (pathsMatch.path) {
    // case Paths.PRODUCTS: {
    //   let currentGroup = yield select(currentGroupSelector);
    //   const currentProduct = yield select(currentProductSelector);
    //   if (!currentGroup) currentGroup = yield select(defaultGroupSelector);
    //   if (
    //     (currentProduct && currentProduct.isFetching === null) ||
    //     (!currentProduct && currentGroup.id && pathsMatch.params.id !== currentGroup.id)
    //   ) {
    //     const productId = currentProduct ? currentProduct.id : pathsMatch.params.id;
    //     yield put(handleLocationChange.fetchProduct(productId));
    //     try {
    //       ({
    //         item: product,
    //         included: {
    //           customers,
    //           sellitems,
    //           sellitemMemberships,
    //           sellitemLabels,
    //           sellitemCustomers,
    //           payments,
    //           attachments,
    //         },
    //       } = yield call(request, api.getProduct, productId));
    //     } catch (error) {} // eslint-disable-line no-empty
    //     yield put(
    //       fetchProduct.success(
    //         product,
    //         customers,
    //         sellitems,
    //         sellitemMemberships,
    //         sellitemLabels,
    //         sellitemCustomers,
    //         payments,
    //         attachments,
    //       ),
    //     );
    //   }
    //   break;
    // }
    // case Paths.BAGS: {
    //   let currentGroup = yield select(currentGroupSelector);
    //   const currentBag = yield select(currentBagSelector);

    //   if (!currentGroup) currentGroup = yield select(defaultGroupSelector);

    //   if (
    //     (currentBag && currentBag.isFetching === null) ||
    //     (!currentBag && currentGroup.id && pathsMatch.params.id !== currentGroup.id)
    //   ) {
    //     const bagId = currentBag ? currentBag.id : pathsMatch.params.id;
    //     yield put(handleLocationChange.fetchBag(bagId));
    //     try {
    //       ({
    //         item: bag,
    //         included: { bagitems, sellitems },
    //       } = yield call(request, api.getBag, bagId));
    //     } catch (error) {
    //       yield put(fetchBag.failure(bagId, error));
    //       return;
    //     } // eslint-disable-line no-empty
    //     yield put(fetchBag.success(bag, bagitems, sellitems));
    //   }
    //   break;
    // }
    case Paths.BAGS:
    case Paths.GROUPS:
    case Paths.PRODUCTS:
    case Paths.TRANSACTIONS:
    case Paths.CUSTOMERS:
    case Paths.ACCOUNTANT:
    case Paths.DEBTS:
    case Paths.SELLITEMS: {
      const currentGroup = yield select(currentGroupSelector);
      if (currentGroup && currentGroup.isFetching === null) {
        yield put(handleLocationChange.fetchGroup(currentGroup.id));

        try {
          ({
            item: group,
            included: {
              users,
              projects,
              groupMemberships,
              labels,
              blocks,
              customers,
              products,
              bags,
              bagitems,
              discounts,
              sellitems,
              sellitemMemberships,
              sellitemLabels,
              sellitemCustomers,
              sellitemDiscounts,
              payments,
              attachments,
            },
          } = yield call(request, api.getGroup, currentGroup.id));
        } catch (error) {} // eslint-disable-line no-empty
      }

      if (pathsMatch.path === Paths.SELLITEMS) {
        const notificationIds = yield select(notificationIdsForCurrentSellitemSelector);

        if (notificationIds && notificationIds.length > 0) {
          try {
            ({ items: notifications } = yield call(
              request,
              api.updateNotifications,
              notificationIds,
              {
                isRead: true,
              },
            ));
          } catch (error) {} // eslint-disable-line no-empty
        }

        const currentCustomer = yield select(currentCustomerSelector);

        if (currentCustomer && currentCustomer.isFetching === null) {
          yield put(handleLocationChange.fetchCustomer(currentCustomer.id));

          try {
            ({
              item: customer,
              included: {
                block,
                products,
                sellitems,
                sellitemMemberships,
                sellitemLabels,
                sellitemCustomers,
                payments,
                attachments,
              },
            } = yield call(request, api.getCustomer, currentCustomer.id));
          } catch (error) {} // eslint-disable-line no-empty
        }
      }

      break;
    }
    case Paths.DASHBOARD: {
      let currentGroup = yield select(currentGroupSelector);
      if (!currentGroup) currentGroup = yield select(defaultGroupSelector);

      if (currentGroup.id) {
        yield put(handleLocationChange.fetchDashboard(currentGroup.id));
        try {
          ({ item: groupData, included: dashboard } = yield call(
            request,
            api.getDashboard,
            currentGroup.id,
            '0',
          ));
        } catch (error) {
          yield put(fetchDashboard.failure(currentGroup.id, error));
          return;
        } // eslint-disable-line no-empty
        yield put(fetchDashboard.success(groupData, dashboard));
      }
      break;
    }
    default:
  }

  yield put(
    handleLocationChange(
      group,
      customer,
      block,
      users,
      projects,
      groupMemberships,
      labels,
      blocks,
      customers,
      products,
      bags,
      bagitems,
      discounts,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      sellitemCustomers,
      sellitemDiscounts,
      payments,
      attachments,
      notifications,
      projectAgents,
      dashboard,
    ),
  );
}
