import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  filterLabelsForCurrentGroupSelector,
  filterUsersForCurrentGroupSelector,
  filterStatusesForCurrentGroupSelector,
  filterCategoriesForCurrentGroupSelector,
  filterAgentsForCurrentGroupSelector,
  isCurrentUserManagerForCurrentProjectSelector,
  membershipsForCurrentGroupSelector,
  usersSelector,
  agentsForCurrentProjectSelector,
  categoriesForCurrentProjectSelector,
  pathsMatchSelector,
  filterProductStatusesForCurrentGroupSelector,
} from '../selectors';
import {
  addLabelToFilterInCurrentGroup,
  addCategoryToFilterInCurrentGroup,
  addAgentToFilterInCurrentGroup,
  addUserToFilterInCurrentGroup,
  addStatusToFilterInCurrentGroup,
  addProductStatusToFilterInCurrentGroup,
  createLabelInCurrentGroup,
  createMembershipInCurrentGroup,
  deleteGroupMembership,
  deleteLabel,
  removeLabelFromFilterInCurrentGroup,
  removeCategoryFromFilterInCurrentGroup,
  removeAgentFromFilterInCurrentGroup,
  removeUserFromFilterInCurrentGroup,
  removeStatusFromFilterInCurrentGroup,
  removeProductStatusFromFilterInCurrentGroup,
  updateLabel,
} from '../actions/entry';
import GroupActions from '../components/GroupActions';

const mapStateToProps = (state) => {
  const { path } = pathsMatchSelector(state);
  const allUsers = usersSelector(state);
  const isCurrentUserManager = isCurrentUserManagerForCurrentProjectSelector(state);
  const memberships = membershipsForCurrentGroupSelector(state);
  const agents = agentsForCurrentProjectSelector(state);
  const categories = categoriesForCurrentProjectSelector(state);
  const filterUsers = filterUsersForCurrentGroupSelector(state);
  const filterLabels = filterLabelsForCurrentGroupSelector(state);
  const filterStatuses = filterStatusesForCurrentGroupSelector(state);
  const filterCategories = filterCategoriesForCurrentGroupSelector(state);
  const filterAgents = filterAgentsForCurrentGroupSelector(state);
  const filterProductStatuses = filterProductStatusesForCurrentGroupSelector(state);

  return {
    path,
    memberships: memberships.filter(({ user: { email } }) => email !== 'kanri@tipici.vn'),
    categories,
    agents,
    filterUsers,
    filterLabels,
    filterStatuses,
    filterProductStatuses,
    filterCategories,
    filterAgents,
    allUsers,
    canEditMemberships: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onMembershipCreate: createMembershipInCurrentGroup,
      onMembershipDelete: deleteGroupMembership,
      onUserToFilterAdd: addUserToFilterInCurrentGroup,
      onUserFromFilterRemove: removeUserFromFilterInCurrentGroup,
      onLabelToFilterAdd: addLabelToFilterInCurrentGroup,
      onLabelFromFilterRemove: removeLabelFromFilterInCurrentGroup,
      onLabelCreate: createLabelInCurrentGroup,
      onLabelUpdate: updateLabel,
      onLabelDelete: deleteLabel,
      onStatusToFilterAdd: addStatusToFilterInCurrentGroup,
      onStatusFromFilterRemove: removeStatusFromFilterInCurrentGroup,
      onAgentToFilterAdd: addAgentToFilterInCurrentGroup,
      onAgentFromFilterRemove: removeAgentFromFilterInCurrentGroup,
      onCategoryToFilterAdd: addCategoryToFilterInCurrentGroup,
      onCategoryFromFilterRemove: removeCategoryFromFilterInCurrentGroup,
      onProductStatusToFilterAdd: addProductStatusToFilterInCurrentGroup,
      onProductStatusFromFilterRemove: removeProductStatusFromFilterInCurrentGroup,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GroupActions);
