import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pathSelector, productsForCurrentGroupSelector, sellitemByIdSelector } from '../selectors';

import { fetchActionsInCurrentSellitem } from '../actions/entry';

import { ProductStatuses } from '../constants/Enums';
import Sellitems from '../components/Sellitems';

const mapStateToProps = (state) => {
  const { groupId, sellitemId } = pathSelector(state);
  let isAllActionsFetched = true;
  if (sellitemId !== groupId) {
    isAllActionsFetched = sellitemByIdSelector(state, sellitemId)?.isAllActionsFetched;
  }

  const products = productsForCurrentGroupSelector(state);
  products.map((pr) => pr.status === ProductStatuses.SELLING.name);

  return {
    isAllActionsFetched,
    sellitemId,
    groupId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetchActions: fetchActionsInCurrentSellitem,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sellitems);
