import { all, takeEvery } from 'redux-saga/effects';

import {
  createAgentService,
  createAgentInCurrentProjectService,
  deleteAgentService,
  handleAgentCreateService,
  handleAgentDeleteService,
  handleAgentUpdateService,
  updateAgentService,
  addAgentToFilterInCurrentGroupService,
  removeAgentFromFilterInCurrentGroupService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* agentWatchers() {
  yield all([
    takeEvery(EntryActionTypes.AGENT_CREATE, ({ payload: { agent } }) => createAgentService(agent)),
    takeEvery(EntryActionTypes.AGENT_IN_CURRENT_PROJECT_CREATE, ({ payload: { data } }) =>
      createAgentInCurrentProjectService(data),
    ),
    takeEvery(EntryActionTypes.AGENT_CREATE_HANDLE, ({ payload: { agent } }) =>
      handleAgentCreateService(agent),
    ),
    takeEvery(EntryActionTypes.AGENT_UPDATE, ({ payload: { id, data } }) =>
      updateAgentService(id, data),
    ),
    takeEvery(EntryActionTypes.AGENT_UPDATE_HANDLE, ({ payload: { agent } }) =>
      handleAgentUpdateService(agent),
    ),
    takeEvery(EntryActionTypes.AGENT_DELETE, ({ payload: { id } }) => deleteAgentService(id)),
    takeEvery(EntryActionTypes.AGENT_DELETE_HANDLE, ({ payload: { agent } }) =>
      handleAgentDeleteService(agent),
    ),
    takeEvery(EntryActionTypes.AGENT_TO_FILTER_IN_CURRENT_GROUP_ADD, ({ payload: { id } }) =>
      addAgentToFilterInCurrentGroupService(id),
    ),
    takeEvery(EntryActionTypes.AGENT_FROM_FILTER_IN_CURRENT_GROUP_REMOVE, ({ payload: { id } }) =>
      removeAgentFromFilterInCurrentGroupService(id),
    ),
  ]);
}
