/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, List } from 'semantic-ui-react';

import styles from './ProductModal.module.scss';

const ProductModal = React.memo(({ product, openModal, onClose }) => {
  const [t] = useTranslation();

  const landTotal = Math.round(product.landPrice * product.landArea * product.factor, 0);
  const floorTotal = product.floorPrice * product.floorArea;
  const finalTotal = landTotal + floorTotal;

  return (
    <Modal
      open={openModal}
      closeIcon
      size="small"
      centered={false}
      onClose={onClose}
      className={styles.productModalWrapper}
    >
      <Modal.Header>{product.name}</Modal.Header>
      <Modal.Content>
        <List divided verticalAlign="middle" className={styles.details}>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.blocks')}:</span>
              {product.block}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.code')}:</span>
              {product.name}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.status')}:</span>
              {t(`status.${product.status}`)}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.landNumber')}:</span>
              {product.landNumber}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.certificate')}:</span>
              {product.certificate}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.street')}:</span>
              {product.street}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.direction')}:</span>
              {product.direction}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.density')}:</span>
              {product.density}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.landArea')}:</span>
              {product.landArea}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.floorArea')}:</span>
              {product.floorArea}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.landPrice')}:</span>
              {(product.landPrice * 1).toLocaleString('en-GB')}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.floorPrice')}:</span>
              {(product.floorPrice * 1).toLocaleString('en-GB')}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.landTotal')}:</span>
              {landTotal.toLocaleString('en-GB')}
              <i> (trước VAT: {Math.round(landTotal / 1.1, 0).toLocaleString('en-GB')})</i>
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.floorTotal')}:</span>
              {floorTotal.toLocaleString('en-GB')}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.factor')}:</span>
              {product.factor}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.total')}:</span>
              {finalTotal.toLocaleString('en-GB')}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.properties')}:</span>
              {product.description}
            </List.Content>
          </List.Item>
          <List.Item className={styles.listItem}>
            <List.Content>
              <span className={styles.label}>{t('common.agent')}:</span>
              {product.agent.name}
            </List.Content>
          </List.Item>
        </List>
      </Modal.Content>
    </Modal>
  );
});

ProductModal.propTypes = {
  product: PropTypes.objectOf(PropTypes.shape),
  openModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ProductModal.defaultProps = {
  product: undefined,
  openModal: undefined,
};

export default ProductModal;
