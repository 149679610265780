import ActionTypes from '../constants/ActionTypes';

export const createBag = (bag) => ({
  type: ActionTypes.BAG_CREATE,
  payload: {
    bag,
  },
});

createBag.success = (localId, bag) => ({
  type: ActionTypes.BAG_CREATE__SUCCESS,
  payload: {
    localId,
    bag,
  },
});

createBag.failure = (localId, error) => ({
  type: ActionTypes.BAG_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleBagCreate = (bag) => ({
  type: ActionTypes.BAG_CREATE_HANDLE,
  payload: {
    bag,
  },
});

export const updateBag = (id, data) => ({
  type: ActionTypes.BAG_UPDATE,
  payload: {
    id,
    data,
  },
});

updateBag.success = (bag) => ({
  type: ActionTypes.BAG_UPDATE__SUCCESS,
  payload: {
    bag,
  },
});

updateBag.failure = (id, error) => ({
  type: ActionTypes.BAG_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBagUpdate = (bag) => ({
  type: ActionTypes.BAG_UPDATE_HANDLE,
  payload: {
    bag,
  },
});

export const deleteBag = (id) => ({
  type: ActionTypes.BAG_DELETE,
  payload: {
    id,
  },
});

deleteBag.success = (bag) => ({
  type: ActionTypes.BAG_DELETE__SUCCESS,
  payload: {
    bag,
  },
});

deleteBag.failure = (id, error) => ({
  type: ActionTypes.BAG_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBagDelete = (bag) => ({
  type: ActionTypes.BAG_DELETE_HANDLE,
  payload: {
    bag,
  },
});

export const fetchBag = (id) => ({
  type: ActionTypes.BAG_FETCH,
  payload: {
    id,
  },
});

fetchBag.success = (bag, bagitems, discounts, sellitems) => ({
  type: ActionTypes.BAG_FETCH__SUCCESS,
  payload: {
    bag,
    bagitems,
    discounts,
    sellitems,
  },
});

fetchBag.failure = (id, error) => ({
  type: ActionTypes.BAG_FETCH__FAILURE,
  payload: {
    id,
    error,
  },
});

export const recalculateCommission = (id) => ({
  type: ActionTypes.BAG_RECALCULATE_COMMISSION,
  payload: {
    id,
  },
});

recalculateCommission.success = (bag, sellitems) => ({
  type: ActionTypes.BAG_RECALCULATE_COMMISSION__SUCCESS,
  payload: {
    bag,
    sellitems,
  },
});

recalculateCommission.failure = (id, error) => ({
  type: ActionTypes.BAG_RECALCULATE_COMMISSION__FAILURE,
  payload: {
    id,
    error,
  },
});
