import socket from './socket';

/* Actions */

const createAgent = (data, headers) => socket.post(`/agents`, data, headers);

const updateAgent = (id, data, headers) => socket.patch(`/agents/${id}`, data, headers);

const deleteAgent = (id, headers) => socket.delete(`/agents/${id}`, undefined, headers);

export default {
  createAgent,
  updateAgent,
  deleteAgent,
};
