/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { closePopup } from '../../lib/popup';

import DroppableTypes from '../../constants/DroppableTypes';
import BlockContainer from '../../containers/BlockContainer';
// import ProductModalContainer from '../../containers/ProductModalContainer';
import BlockAdd from './BlockAdd';
import { ReactComponent as PlusMathIcon } from '../../assets/images/plus-math-icon.svg';

import styles from './GroupKanban.module.scss';

const parseDndId = (dndId) => dndId.split(':')[1];

const GroupKanban = React.memo(
  ({ blockIds, isProductModalOpened, canEdit, onBlockCreate, onBlockMove, onProductMove }) => {
    const [t] = useTranslation();
    const [isBlockAddOpened, setIsBlockAddOpened] = useState(false);

    const wrapper = useRef(null);
    const prevPosition = useRef(null);

    const handleAddBlockClick = useCallback(() => {
      setIsBlockAddOpened(true);
    }, []);

    const handleAddBlockClose = useCallback(() => {
      setIsBlockAddOpened(false);
    }, []);

    const handleDragStart = useCallback(() => {
      closePopup();
    }, []);

    const handleDragEnd = useCallback(
      ({ draggableId, type, source, destination }) => {
        if (
          !destination ||
          (source.droppableId === destination.droppableId && source.index === destination.index)
        ) {
          return;
        }

        const id = parseDndId(draggableId);

        switch (type) {
          case DroppableTypes.BLOCK:
            onBlockMove(id, destination.index);

            break;
          case DroppableTypes.PRODUCT:
            onProductMove(id, parseDndId(destination.droppableId), destination.index);

            break;
          default:
        }
      },
      [onBlockMove, onProductMove],
    );

    const handleMouseDown = useCallback(
      (event) => {
        if (event.target !== wrapper.current && !event.target.dataset.dragScroller) {
          return;
        }

        prevPosition.current = event.clientX;
      },
      [wrapper],
    );

    const handleWindowMouseMove = useCallback(
      (event) => {
        if (!prevPosition.current) {
          return;
        }

        event.preventDefault();

        window.scrollBy({
          left: prevPosition.current - event.clientX,
        });

        prevPosition.current = event.clientX;
      },
      [prevPosition],
    );

    const handleWindowMouseUp = useCallback(() => {
      prevPosition.current = null;
    }, [prevPosition]);

    useEffect(() => {
      document.body.style.overflowX = 'auto';

      return () => {
        document.body.style.overflowX = null;
      };
    }, []);

    useEffect(() => {
      if (isBlockAddOpened) {
        window.scroll(document.body.scrollWidth, 0);
      }
    }, [blockIds, isBlockAddOpened]);

    useEffect(() => {
      window.addEventListener('mouseup', handleWindowMouseUp);
      window.addEventListener('mousemove', handleWindowMouseMove);

      return () => {
        window.removeEventListener('mouseup', handleWindowMouseUp);
        window.removeEventListener('mousemove', handleWindowMouseMove);
      };
    }, [handleWindowMouseUp, handleWindowMouseMove]);

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div ref={wrapper} className={styles.wrapper} onMouseDown={handleMouseDown}>
          <div>
            <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
              <Droppable droppableId="group" type={DroppableTypes.BLOCK} direction="vertical">
                {({ innerRef, droppableProps, placeholder }) => (
                  <div
                    {...droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                    data-drag-scroller
                    ref={innerRef}
                    className={styles.blocks}
                  >
                    {blockIds.map((blockId, index) => (
                      <BlockContainer key={blockId} id={blockId} index={index} />
                    ))}
                    {placeholder}
                    {canEdit && (
                      <div data-drag-scroller className={styles.block}>
                        {isBlockAddOpened ? (
                          <BlockAdd onCreate={onBlockCreate} onClose={handleAddBlockClose} />
                        ) : (
                          <button
                            type="button"
                            className={styles.addBlockButton}
                            onClick={handleAddBlockClick}
                          >
                            <PlusMathIcon className={styles.addBlockButtonIcon} />
                            <span className={styles.addBlockButtonText}>
                              {blockIds.length > 0
                                ? t('action.addAnotherBlock')
                                : t('action.addBlock')}
                            </span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        {/* {isProductModalOpened && <ProductModalContainer />} */}
      </>
    );
  },
);

GroupKanban.propTypes = {
  blockIds: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  isProductModalOpened: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onBlockCreate: PropTypes.func.isRequired,
  onBlockMove: PropTypes.func.isRequired,
  onProductMove: PropTypes.func.isRequired,
};

export default GroupKanban;
