import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { currentUserIdSelector } from './user';
import { isLocalId } from '../utils/local-id';
import { BagStatuses } from '../constants/Enums';

export const defaultGroupSelector = createSelector(orm, ({ Group }) =>
  Group.getOrderedQuerySet()
    .filter((group) => group.isFetching !== null)
    .toRefArray(),
);

export const makeGroupByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Group }, id) => {
      const groupModel = Group.withId(id);

      if (!groupModel) {
        return groupModel;
      }

      return groupModel.ref;
    },
  );

export const groupByIdSelector = makeGroupByIdSelector();

export const currentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    const filterAgents = groupModel.filterAgents.toRefArray().map((agent) => agent.id);
    const filterCategories = groupModel.filterCategories
      .toRefArray()
      .map((category) => category.id);

    return {
      ...groupModel.ref,
      filterAgents,
      filterCategories,
    };
  },
);

export const membershipsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  (state) => currentUserIdSelector(state),
  ({ Group }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel
      .getOrderedMembershipsQuerySet()
      .toModelArray()
      .map((groupMembershipModel) => ({
        ...groupMembershipModel.ref,
        isPersisted: !isLocalId(groupMembershipModel.id),
        user: {
          ...groupMembershipModel.user.ref,
          isCurrent: groupMembershipModel.user.id === currentUserId,
        },
      }));
  },
);

export const labelsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.labels.toRefArray().map((label) => ({
      ...label,
      isPersisted: !isLocalId(label.id),
    }));
  },
);

export const blocksForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.getOrderedBlocksQuerySet().toRefArray();
  },
);

export const blockIdsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel
      .getOrderedBlocksQuerySet()
      .toRefArray()
      .map((block) => block.id);
  },
);

export const customersForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    const customers = groupModel
      .getOrderedSellitemsQuerySet()
      .toModelArray()
      .map((sellitemModel) => ({
        customers: sellitemModel.customers.toRefArray(),
      }));

    return customers
      .map((c) => c.customers)
      .flat()
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id && t.name === value.name),
      )
      .map((c) => ({
        ...c,
        groupId: groupModel.id,
      }));
  },
);

export const productsFilteredForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  (state) => currentGroupSelector(state),
  ({ Group }, id, currentGroup) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    let productsModel = groupModel.getOrderedProductsQuerySet().toModelArray();

    if (currentGroup) {
      const { filterAgents, filterProductStatuses, filterCategories } = currentGroup;

      if (filterProductStatuses.length > 0) {
        productsModel = productsModel.filter((productModel) =>
          filterProductStatuses.includes(productModel.status),
        );
      }
      if (filterCategories.length > 0) {
        productsModel = productsModel.filter((productModel) =>
          filterCategories.includes(productModel.ref.categoryId),
        );
      }

      if (filterAgents) {
        const filterAgentIds = filterAgents; // .toRefArray().map((agent) => agent.id);
        if (filterAgentIds.length > 0) {
          productsModel = productsModel.filter((productModel) =>
            filterAgentIds.includes(productModel.agent?.ref.id),
          );
        }
      }
    }

    // return groupModel.getOrderedSellitemsQuerySet().toRefArray();
    return productsModel.map((productModel) => ({
      ...productModel.ref,
      // creator: productModel.creator?.ref.name,
      blockName: productModel.block?.ref.name,
      agentName: productModel.agent?.ref.name,
      // category: sellitemModel.product?.category?.ref.name,
    }));
  },
);

export const productsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    const products = [];
    const bagitemProductIds = [];
    const today = new Date();
    groupModel
      .getOrderedBagsQuerySet()
      .toModelArray()
      .filter(
        (bagModel) => bagModel.status === BagStatuses.PUBLISH.name && bagModel.endDate >= today,
      )
      .map((bagModel) => {
        return bagModel
          .getOrderedBagitemsQuerySet()
          .toModelArray()
          .forEach((bagitemModel) => {
            const p = bagitemModel.product.ref;
            const { landPrice, floorPrice, factor } = bagitemModel.ref;
            const landTotal = Math.round(landPrice * p.landArea * factor, 0);
            const landTotalWithoutVAT = Math.round(landTotal / 1.1);
            const floorTotal = floorPrice * p.floorArea;
            const discounts = bagModel
              .getOrderedDiscountsQuerySet()
              .toRefArray()
              .filter(({ isClosed }) => !isClosed);
            if (!bagitemProductIds.includes(p.id)) {
              bagitemProductIds.push(p.id);
              products.push({
                ...bagitemModel.product.ref,
                bagitemId: bagitemModel.id,
                bag: bagModel.ref,
                landPrice,
                floorPrice,
                factor,
                landTotal,
                landTotalWithoutVAT,
                floorTotal,
                finalTotal: landTotal + floorTotal,
                block: bagitemModel.product.block?.ref.name,
                agent: bagitemModel.agent?.ref,
                discounts,
              });
            }
          });
      });
    // groupModel
    //   .getOrderedProductsQuerySet()
    //   .toModelArray()
    //   .filter((productModel) => !bagitemProductIds.includes(productModel.id))
    //   .forEach((productModel) => {
    //     const p = productModel.ref;
    //     const { landPrice, floorPrice, factor } = p;
    //     const landTotal = Math.round(landPrice * p.landArea * factor, 0);
    //     const landTotalWithoutVAT = Math.round(landTotal / 1.1);
    //     const floorTotal = floorPrice * p.floorArea;
    //     products.push({
    //       ...productModel.ref,
    //       bagitemId: null,
    //       landTotal,
    //       landTotalWithoutVAT,
    //       floorTotal,
    //       finalTotal: landTotal + floorTotal,
    //       agent: productModel.agent?.ref,
    //       discounts: [],
    //     });
    //   });
    return products.sort((a, b) => a.name.localeCompare(b.name));
    // return groupModel
    //   .getOrderedProductsQuerySet()
    //   .toModelArray()
    //   .map((productModel) => {
    //     const p = productModel.ref;
    //     const { landPrice, floorPrice, factor } = p;
    //     const landTotal = Math.round(landPrice * p.landArea * factor, 0);
    //     const landTotalWithoutVAT = Math.round(landTotal / 1.1);
    //     const floorTotal = floorPrice * p.floorArea;
    //     return {
    //       ...productModel.ref,
    //       landTotal,
    //       landTotalWithoutVAT,
    //       floorTotal,
    //       finalTotal: landTotal + floorTotal,
    //       agent: productModel.agent?.ref,
    //     };
    //   });
  },
);

export const bagIdsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel
      .getOrderedBagsQuerySet()
      .toRefArray()
      .map((bag) => bag.id);
  },
);

export const bagsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel
      .getOrderedBagsQuerySet()
      .toRefArray()
      .map((bag) => bag);
  },
);

export const sellitemIdsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel
      .getOrderedSellitemsQuerySet()
      .toRefArray()
      .map((sellitem) => sellitem.id);
  },
);

export const sellitemsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  (state) => currentGroupSelector(state),
  (state) => currentUserIdSelector(state),
  ({ Group }, id, currentGroup, currentUserId) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    const { filterAgents, filterStatuses, filterCategories } = currentGroup;
    let sellitemsModel = groupModel.getOrderedSellitemsQuerySet().toModelArray();
    if (filterStatuses.length > 0) {
      sellitemsModel = sellitemsModel.filter((sellitemModel) =>
        filterStatuses.includes(sellitemModel.status),
      );
    }
    if (filterCategories.length > 0) {
      sellitemsModel = sellitemsModel.filter((sellitemModel) =>
        filterCategories.includes(sellitemModel.product?.ref.categoryId),
      );
    }

    if (filterAgents.length > 0) {
      sellitemsModel = sellitemsModel.filter((sellitemModel) =>
        filterAgents.includes(sellitemModel.product?.agent?.ref.id),
      );
    }

    return sellitemsModel.map((sellitemModel) => ({
      ...sellitemModel.ref,
      bagitem: sellitemModel.bagitem?.ref,
      creator: sellitemModel.creator?.ref.name,
      customer: sellitemModel.customer?.ref?.name,
      product: sellitemModel.product?.ref,
      block: sellitemModel.product?.block?.ref,
      agent: sellitemModel.product?.agent?.ref,
      agentSelled: sellitemModel.agent?.ref,
      category: sellitemModel.product?.category?.ref.name,
      notifications: sellitemModel
        .getUnreadNotificationsQuerySet()
        .toRefArray()
        .filter((noti) => noti.userId === currentUserId),
    }));
  },
);

export const filterUsersForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterUsers.toRefArray();
  },
);

export const filterStatusesForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterStatuses;
  },
);

export const filterProductStatusesForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterProductStatuses;
  },
);

export const filterLabelsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterLabels.toRefArray();
  },
);

export const filterCategoriesForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterCategories.toRefArray();
  },
);

export const filterAgentsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterAgents.toRefArray();
  },
);

export const isCurrentUserMemberForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  (state) => currentUserIdSelector(state),
  ({ Group }, id, currentUserId) => {
    if (!id) {
      return false;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return false;
    }

    return groupModel.hasMemberUser(currentUserId);
  },
);

export const makeLastSellitemIdByGroupIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Group }, id) => {
      const groupModel = Group.withId(id);

      if (!groupModel) {
        return groupModel;
      }

      const lastSellitemModel = groupModel.getOrderedInGroupSellitemsQuerySet().last();

      return lastSellitemModel && lastSellitemModel.id;
    },
  );

export const lastSellitemIdByGroupIdSelector = makeLastSellitemIdByGroupIdSelector();

export const makeLastProductIdByGroupIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Group }, id) => {
      const groupModel = Group.withId(id);

      if (!groupModel) {
        return groupModel;
      }

      const lastProductModel = groupModel.getOrderedProductsQuerySet().last();

      return lastProductModel && lastProductModel.id;
    },
  );

export const lastProductIdByGroupIdSelector = makeLastProductIdByGroupIdSelector();

export const makeLastBagIdByGroupIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Group }, id) => {
      const groupModel = Group.withId(id);

      if (!groupModel) {
        return groupModel;
      }

      const lastBagModel = groupModel.getOrderedBagsQuerySet().last();

      return lastBagModel && lastBagModel.id;
    },
  );

export const lastBagIdByGroupIdSelector = makeLastBagIdByGroupIdSelector();

export const paymentsSelector = createSelector(orm, ({ Payment, Product, Customer }) => {
  const paymentsModel = Payment.getOrderedQuerySet().toModelArray();

  return paymentsModel.map((paymentModel) => {
    const product =
      paymentModel.sellitem?.ref?.productId && Product.withId(paymentModel.sellitem.ref.productId);
    return {
      ...paymentModel.ref,
      account: paymentModel.account?.ref,
      sellitem: {
        ...paymentModel.sellitem?.ref,
        agent: paymentModel.sellitem?.agent?.ref,
        bagitem: paymentModel.sellitem?.bagitem?.ref,
      },
      product: {
        ...product?.ref,
        agent: product?.agent?.ref,
      },
      customer: (paymentModel.sellitem?.ref?.customerId &&
        Customer.withId(paymentModel.sellitem.ref.customerId).ref) || { name: '' },
    };
  });
});
