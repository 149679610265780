import { all, takeEvery } from 'redux-saga/effects';

import {
  addStatusToFilterInCurrentGroupService,
  removeStatusFromFilterInCurrentGroupService,
  addBagStatusToFilterInCurrentGroupService,
  removeBagStatusFromFilterInCurrentGroupService,
  addProductStatusToFilterInCurrentGroupService,
  removeProductStatusFromFilterInCurrentGroupService,
  removeAllProductStatusFromFilterInCurrentGroupService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* userWatchers() {
  yield all([
    takeEvery(EntryActionTypes.STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD, ({ payload: { key } }) =>
      addStatusToFilterInCurrentGroupService(key),
    ),
    takeEvery(EntryActionTypes.STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE, ({ payload: { key } }) =>
      removeStatusFromFilterInCurrentGroupService(key),
    ),
    takeEvery(EntryActionTypes.BAG_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD, ({ payload: { key } }) =>
      addBagStatusToFilterInCurrentGroupService(key),
    ),
    takeEvery(
      EntryActionTypes.BAG_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
      ({ payload: { key } }) => removeBagStatusFromFilterInCurrentGroupService(key),
    ),
    takeEvery(
      EntryActionTypes.PRODUCT_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD,
      ({ payload: { key } }) => addProductStatusToFilterInCurrentGroupService(key),
    ),
    takeEvery(
      EntryActionTypes.PRODUCT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
      ({ payload: { key } }) => removeProductStatusFromFilterInCurrentGroupService(key),
    ),
    takeEvery(EntryActionTypes.PRODUCT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE_ALL, () =>
      removeAllProductStatusFromFilterInCurrentGroupService(),
    ),
  ]);
}
