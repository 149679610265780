import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import styles from './MessagePopup.module.scss';

const MessagePopup = React.memo(({ error, onClose }) => {
  const [t] = useTranslation();

  return (
    <div>
      <Modal size="mini" closeIcon onClose={onClose} open className={styles.wrapper}>
        <Modal.Header>{t('common.accountants.recalculateCommission')}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {error ? (
              <p>{t('common.accountants.recalculateCommissionFailed')}</p>
            ) : (
              <p>{t('common.accountants.recalculateCommissionSuccessed')}</p>
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
});

MessagePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

export default MessagePopup;
