import EntryActionTypes from '../../constants/EntryActionTypes';

export const createSellitemDiscount = (data) => ({
  type: EntryActionTypes.DISCOUNT_TO_SELLITEM_ADD,
  payload: {
    data,
  },
});

export const handleSellitemDiscountCreate = (sellitemDiscount, discounts) => ({
  type: EntryActionTypes.DISCOUNT_TO_SELLITEM_ADD_HANDLE,
  payload: {
    sellitemDiscount,
    discounts,
  },
});

export const deleteSellitemDiscount = (sellitemId, discountId) => ({
  type: EntryActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE,
  payload: {
    sellitemId,
    discountId,
  },
});

export const handleSellitemDiscountDelete = (discount) => ({
  type: EntryActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE_HANDLE,
  payload: {
    discount,
  },
});
