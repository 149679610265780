import Handsontable from 'handsontable';

export default class NumberEditor extends Handsontable.editors.TextEditor {
  init() {
    this.onKeyUp = this.onKeyUp.bind(this);
    this.number = '';
  }

  setValue(value) {
    this.number = (+value).toLocaleString();
  }

  getValue() {
    return this.number.replace(/[^0-9]/g, '');
  }

  open() {
    super.open();
    this.TEXTAREA.value = this.number;
    this.TEXTAREA.onkeyup = this.onKeyUp;
  }

  close() {
    super.close();
    this.TEXTAREA.onkeyup = undefined;
    this.number = '';
  }

  onKeyUp() {
    this.setValue(this.TEXTAREA.value.replace(/[^0-9]/g, ''));
    if (this.TEXTAREA.value.length > 1) {
      this.TEXTAREA.value = this.number;
    }
  }

  // prepare(row, col, prop, td, originalValue, cellProperties) {
  //   super.prepare(row, col, prop, td, originalValue, cellProperties);
  //   // this.setValue(originalValue);
  //   this.TEXTAREA.value = this.number;
  // }

  // createElements() {
  //   super.createElements();
  //   this.TEXTAREA.value = this.number;
  // }
}
