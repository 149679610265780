import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Popup } from '../../lib/custom-ui';
import { withPopup } from '../../lib/popup';

import styles from './ProductInfosPopup.module.scss';

const AgentsStep = React.memo(({ item, allAgents, title, onAgentSelect, onBack, onClose }) => {
  const [t] = useTranslation();

  const agentOptions = allAgents.map((it) => ({
    value: it.id,
    label: `${it.name} - ${it.code}`,
    id: it.id,
  }));
  const [selectedAgent, setSelectedAgent] = useState({
    value: item.id,
    label: `${item.name} - ${item.code}`,
    id: item.id,
  });

  const handleAgentChange = useCallback(
    (selected) => {
      setSelectedAgent(selected);
      onAgentSelect(selected);
      onClose();
    },
    [onAgentSelect, setSelectedAgent, onClose],
  );

  return (
    <>
      <Popup.Header onBack={onBack}>{title}</Popup.Header>
      <Popup.Content className={styles.select}>
        <Select
          isClearable={false}
          placeholder={t('common.selectAgent')}
          noOptionsMessage={() => t('common.noOptionsMessage')}
          components={{ ClearIndicator: null }}
          value={selectedAgent}
          name="agent"
          options={agentOptions}
          onChange={handleAgentChange}
        />
      </Popup.Content>
    </>
  );
});

AgentsStep.propTypes = {
  item: PropTypes.objectOf(PropTypes.shape).isRequired,
  allAgents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  title: PropTypes.string,
  onAgentSelect: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
};

AgentsStep.defaultProps = {
  title: 'common.agent',
  onBack: undefined,
  onClose: undefined,
};

export default withPopup(AgentsStep);
