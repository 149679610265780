import socket from './socket';

/* Actions */

const createDiscount = (bagId, data, headers) =>
  socket.post(`/bags/${bagId}/discounts`, data, headers).then((body) => ({
    ...body,
  }));

const updateDiscount = (discountId, data, headers) =>
  socket.patch(`/discounts/${discountId}`, data, headers).then((body) => ({
    ...body,
  }));

const deleteDiscount = (discountId, headers) =>
  socket.delete(`/discounts/${discountId}`, undefined, headers).then((body) => ({
    ...body,
  }));

export default {
  createDiscount,
  updateDiscount,
  deleteDiscount,
};
