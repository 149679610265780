import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  data: {
    file: null,
  },
  isSubmitting: false,
  response: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GROUP_IMPORT:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        isSubmitting: true,
      };
    case ActionTypes.GROUP_IMPORT__SUCCESS:
      // return initialState;
      return {
        ...initialState,
        response: payload.message,
      };
    case ActionTypes.GROUP_IMPORT__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        response: payload.error,
      };
    case ActionTypes.GROUP_IMPORT_ERROR_CLEAR:
      return {
        ...state,
        response: null,
      };
    default:
      return state;
  }
};
