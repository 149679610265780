import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Bagitem';

  static fields = {
    id: attr(),
    landPrice: attr(),
    floorPrice: attr(),
    factor: attr(),
    bagId: fk({
      to: 'Bag',
      as: 'bag',
      relatedName: 'bagitems',
    }),
    productId: fk({
      to: 'Product',
      as: 'product',
      relatedName: 'bagitems',
    }),
    agentId: fk({
      to: 'Agent',
      as: 'agent',
      relatedName: 'bagitems',
    }),
  };

  static reducer({ type, payload }, Bagitem) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.PRODUCTS_IMPORT_HANDLE:
        if (payload.bagitems) {
          payload.bagitems.forEach((bagitem) => {
            Bagitem.upsert(bagitem);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Bagitem.all().delete();

        if (payload.bagitems) {
          payload.bagitems.forEach((bagitem) => {
            Bagitem.upsert(bagitem);
          });
        }

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_CREATE_HANDLE:
      case ActionTypes.GROUP_CREATE__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        if (payload.bagitems) {
          payload.bagitems.forEach((bagitem) => {
            Bagitem.upsert(bagitem);
          });
        }

        break;
      case ActionTypes.BAGITEM_CREATE:
        Bagitem.upsert(payload.bagitem);

        break;
      case ActionTypes.BAGITEM_CREATE__SUCCESS:
        Bagitem.withId(payload.localId).delete();
        Bagitem.upsert(payload.bagitem);

        break;
      case ActionTypes.BAGITEM_CREATE_HANDLE:
        Bagitem.upsert(payload.bagitem);

        if (payload.bagitems) {
          payload.bagitems.forEach((bagitem) => {
            Bagitem.upsert(bagitem);
          });
        }

        break;
      case ActionTypes.BAGITEM_UPDATE__SUCCESS:
        Bagitem.upsert(payload.bagitem);

        break;
      case ActionTypes.BAGITEM_DELETE:
        Bagitem.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.BAGITEM_DELETE__SUCCESS:
      case ActionTypes.BAGITEM_DELETE_HANDLE: {
        const bagitemModel = Bagitem.withId(payload.bagitem.id);

        if (bagitemModel) {
          bagitemModel.deleteWithRelated();
        }

        break;
      }
      default:
    }
  }

  getOrderedProductsQuerySet() {
    return this.product.orderBy('name');
  }

  deleteRelated() {
    this.group.bags.toModelArray().forEach((bagModel) => {
      try {
        bagModel.users.remove(this.userId);
      } catch {} // eslint-disable-line no-empty
    });

    try {
      this.group.filterUsers.remove(this.userId);
    } catch {} // eslint-disable-line no-empty
  }

  deleteWithRelated() {
    // this.deleteRelated();
    this.delete();
  }
}
