import { call, put, select } from 'redux-saga/effects';

import { pathSelector } from '../../../selectors';
import {
  addStatusToGroupFilter,
  removeStatusFromGroupFilter,
  addBagStatusToGroupFilter,
  removeBagStatusFromGroupFilter,
  addProductStatusToGroupFilter,
  removeProductStatusFromGroupFilter,
  removeAllProductStatusFromGroupFilter,
} from '../../../actions';

export function* addStatusToGroupFilterService(key, groupId) {
  yield put(addStatusToGroupFilter(key, groupId));
}

export function* addStatusToFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(addStatusToGroupFilterService, key, groupId);
}

export function* removeStatusFromGroupFilterService(key, groupId) {
  yield put(removeStatusFromGroupFilter(key, groupId));
}

export function* removeStatusFromFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(removeStatusFromGroupFilterService, key, groupId);
}

export function* addBagStatusToGroupFilterService(key, groupId) {
  yield put(addBagStatusToGroupFilter(key, groupId));
}

export function* addBagStatusToFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(addBagStatusToGroupFilterService, key, groupId);
}

export function* removeBagStatusFromGroupFilterService(key, groupId) {
  yield put(removeBagStatusFromGroupFilter(key, groupId));
}

export function* removeBagStatusFromFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(removeBagStatusFromGroupFilterService, key, groupId);
}

export function* addProductStatusToGroupFilterService(key, groupId) {
  yield put(addProductStatusToGroupFilter(key, groupId));
}

export function* addProductStatusToFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(addProductStatusToGroupFilterService, key, groupId);
}

export function* removeProductStatusFromGroupFilterService(key, groupId) {
  yield put(removeProductStatusFromGroupFilter(key, groupId));
}

export function* removeProductStatusFromFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(removeProductStatusFromGroupFilterService, key, groupId);
}

export function* removeAllProductStatusFromGroupFilterService(groupId) {
  yield put(removeAllProductStatusFromGroupFilter(groupId));
}

export function* removeAllProductStatusFromFilterInCurrentGroupService() {
  const { groupId } = yield select(pathSelector);

  yield call(removeAllProductStatusFromGroupFilterService, groupId);
}
