import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Dropdown } from 'semantic-ui-react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Doughnut } from 'react-chartjs-2';

import { useTranslation } from 'react-i18next';
import HeaderContainer from '../../containers/HeaderContainer';
import ProjectContainer from '../../containers/ProjectContainer';
import CardInfo from './CardInfo';

import styles from './Dashboard.module.scss';

const Dashboard = ({ dashboard, isFetching, bags, projectId, currentGroup, onfetchDashboard }) => {
  const [t] = useTranslation();

  const [currentBag, setCurrentBag] = useState(0);
  const bagOptions = [{ key: 0, value: 0, text: t('common.allBags') }, ...bags];

  const handleChangeBag = useCallback(
    (e, data) => {
      setCurrentBag(data.value);
      onfetchDashboard(data.value);
    },
    [onfetchDashboard],
  );

  const barLabels = [];
  const barDataRegistered = [];
  const barDataSelled = [];
  if (dashboard?.statistic?.agentProducts && dashboard.statistic.agentProducts.length > 0) {
    dashboard.statistic.agentProducts.forEach((ag) => {
      barLabels.push(ag.name);
      barDataRegistered.push(ag.product_registered);
      barDataSelled.push(ag.product_selled);
    });
  }

  const barOptions = {
    responsive: true,
    scales: {
      yAxes: {
        max: Math.round(Math.max(...barDataRegistered) * 1.5) || 20,
        min: 0,
        ticks: {
          stepSize: 1,
          paddingLeft: 20,
        },
      },
    },
    plugins: {
      datalabels: {
        color: '#000',
        font: {
          weight: 'normal',
          size: '11',
          lineHeight: '0.9',
        },
        padding: 3,
        borderRadius: 5,
        backgroundColor() {
          return '#fff';
        },
      },
    },
  };

  const barData = {
    labels: barLabels,
    datasets: [
      {
        label: t('common.dashboard.selled'),
        data: barDataSelled,
        barThickness: 30,
        backgroundColor: '#2563EB',
      },
      {
        label: t('common.dashboard.registered'),
        data: barDataRegistered,
        barThickness: 30,
        backgroundColor: '#8DC7E6',
      },
    ],
  };

  let totalExpectedRevenue = 0;
  let actualRevenue = 0;
  let expectedRevenue = 0;
  if (dashboard?.statistic?.productSelledCount && dashboard?.statistic?.productCount) {
    expectedRevenue = Math.round(dashboard?.statistic?.expectedRevenue * 1);
    actualRevenue = Math.round(dashboard?.statistic?.actualRevenue * 1);
    totalExpectedRevenue = expectedRevenue + actualRevenue;
  }

  const doughnutSalesData = {
    labels: [
      `${t('common.dashboard.actualRevenue_desc')}:\n ${(actualRevenue * 1).toLocaleString(
        'en-GB',
      )}`,
      `${t('common.dashboard.expectedRevenue_desc')}: ${(expectedRevenue * 1).toLocaleString(
        'en-GB',
      )}`,
    ],
    datasets: [
      {
        label: t('common.dashboard.totalExpectedRevenue'),
        data: [
          ((actualRevenue / totalExpectedRevenue) * 100 || 0).toFixed(2),
          ((expectedRevenue / totalExpectedRevenue) * 100 || 0).toFixed(2),
        ],
        backgroundColor: ['#2563EB', '#8DC7E6'],
        hoverOffset: 1,
        circumference: 57 * Math.PI,
        rotation: 86.1 * Math.PI,
      },
    ],
  };

  let collected = 0;
  let remain = 0;
  if (dashboard?.statistic?.collected) {
    collected = dashboard?.statistic?.collected * 1;
    remain = actualRevenue - collected;
  }

  const doughnutProductData = {
    labels: [
      `${t('common.dashboard.collected')}: ${(collected * 1).toLocaleString('en-GB')}`,
      `${t('common.dashboard.remaining')}: ${(remain * 1).toLocaleString('en-GB')}`,
    ],
    datasets: [
      {
        label: t('common.dashboard.actualRevenue'),
        data: [
          Math.round((collected / actualRevenue) * 100 || 0),
          Math.round((remain / actualRevenue) * 100 || 0),
        ],
        backgroundColor: ['#FFBA09', '#EDD577'],
        hoverOffset: 1,
        circumference: 57 * Math.PI,
        rotation: 86.1 * Math.PI,
      },
    ],
  };

  const doughnutOptions = {
    plugins: {
      datalabels: {
        color: '#fff',
        padding: 3,
        borderRadius: 5,
        backgroundColor(context) {
          return context.dataset.backgroundColor;
        },
        font: {
          weight: 'normal',
          size: '12',
        },
        formatter: (value) => {
          return `${value}%`;
        },
      },
      tooltip: false,
      legend: {
        display: false,
        position: 'top',
        align: 'start',
        itemStyle: {
          width: 100,
        },
      },
    },
  };

  return (
    <>
      {currentGroup && !currentGroup.isFetching && (
        <>
          <HeaderContainer />
          {projectId && <ProjectContainer />}
          {dashboard && !isFetching && (
            <div className={styles.dashboardWrapper}>
              <div className={styles.sidebarLeftFilter}>
                <Dropdown
                  className={styles.filterBag}
                  placeholder={t('common.selectBag')}
                  selection
                  value={currentBag}
                  options={bagOptions}
                  onChange={handleChangeBag}
                />
              </div>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
                    <CardInfo
                      title={t('common.dashboard.bag')}
                      icon="map outline"
                      value={bags.length.toLocaleString('en-GB')}
                      percent=""
                      color="#0284C7"
                      background="#BAE6FD"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
                    <CardInfo
                      title={t('common.dashboard.block')}
                      icon="calculator"
                      value={(dashboard.statistic?.blockCount * 1).toLocaleString('en-GB')}
                      percent=""
                      color="#16A34A"
                      background="#DCFCE7"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
                    <CardInfo
                      title={t('common.dashboard.product')}
                      icon="building outline"
                      value={(dashboard.statistic?.productCount * 1).toLocaleString('en-GB')}
                      percent=""
                      color="#0D9488"
                      background="#CCFBF1"
                    />
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
                    <CardInfo
                      title={t('common.dashboard.feeAgent')}
                      icon="dollar"
                      value={(dashboard.statistic?.commission * 1).toLocaleString('en-GB')}
                      percent=""
                      color="#EA580C"
                      background="#FFEDD5"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={8} widescreen={8}>
                    <div className={styles.chartsWrapper}>
                      <h4>{t('common.dashboard.salesAgent')}</h4>
                      <Bar plugins={[ChartDataLabels]} options={barOptions} data={barData} />
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={8} widescreen={8}>
                    <div className={styles.chartsWrapper}>
                      <h4>{t('common.dashboard.planCompletionRate')}</h4>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={8}
                            computer={8}
                            largeScreen={8}
                            widescreen={8}
                            className={styles.chartWrapper}
                          >
                            <div className={styles.chartContent}>
                              <div className={styles.chartLegent}>
                                {doughnutSalesData.labels.map((label, index) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <div key={`legend-${index}`} className={styles.legend}>
                                    <span
                                      style={{
                                        backgroundColor:
                                          doughnutSalesData.datasets[0].backgroundColor[index],
                                      }}
                                    />
                                    {label}
                                  </div>
                                ))}
                              </div>

                              <Doughnut
                                plugins={[ChartDataLabels]}
                                options={doughnutOptions}
                                data={doughnutSalesData}
                              />
                            </div>
                            <div className={styles.chartTitleTurnover}>
                              <span>{(totalExpectedRevenue * 1).toLocaleString('en-GB')}</span>
                              {t('common.dashboard.totalExpectedRevenue')}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            mobile={16}
                            tablet={8}
                            computer={8}
                            largeScreen={8}
                            widescreen={8}
                            className={styles.chartWrapper}
                          >
                            <div className={styles.chartContent}>
                              <div className={styles.chartLegent}>
                                {doughnutProductData.labels.map((label, index) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <div key={`legend-${index}`} className={styles.legend}>
                                    <span
                                      style={{
                                        backgroundColor:
                                          doughnutProductData.datasets[0].backgroundColor[index],
                                      }}
                                    />
                                    {label}
                                  </div>
                                ))}
                              </div>

                              <Doughnut
                                plugins={[ChartDataLabels]}
                                options={doughnutOptions}
                                data={doughnutProductData}
                              />
                            </div>
                            <div className={styles.chartTitleTurnover}>
                              <span>{(actualRevenue * 1).toLocaleString('en-GB')}</span>
                              {t('common.dashboard.actualRevenue')}
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </>
      )}
    </>
  );
};

Dashboard.propTypes = {
  dashboard: PropTypes.objectOf(PropTypes.shape),
  isFetching: PropTypes.bool.isRequired,
  bags: PropTypes.arrayOf(PropTypes.object()).isRequired,
  projectId: PropTypes.string,
  currentGroup: PropTypes.objectOf(PropTypes.shape),
  onfetchDashboard: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  dashboard: undefined,
  projectId: undefined,
  currentGroup: undefined,
};

export default Dashboard;
