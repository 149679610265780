const DEPOSITYTPE = 'process1';

/* eslint-disable no-unused-vars */
const arrayNumber = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];
const readDozens = (number, daydu) => {
  let string = '';
  const dozens = Math.floor(number / 10);
  const units = number % 10;
  if (dozens > 1) {
    string = ` ${arrayNumber[dozens]} mươi`;
    if (units === 1) {
      string += ' mốt';
    }
  } else if (dozens === 1) {
    string = ' mười';
    if (units === 1) {
      string += ' một';
    }
  } else if (daydu && units > 0) {
    string = ' lẻ';
  }
  if (units === 5 && dozens > 1) {
    string += ' lăm';
  } else if (units > 1 || (units === 1 && dozens === 0)) {
    string += ` ${arrayNumber[units]}`;
  }
  return string;
};

const readBlock = (number, full) => {
  let string = '';
  const hundred = Math.floor(number / 100);
  const dozens = number % 100;
  if (full || hundred > 0) {
    string = ` ${arrayNumber[hundred]} trăm`;
    string += readDozens(dozens, true);
  } else {
    string = readDozens(dozens, false);
  }
  return string;
};

const readMillion = (number, full) => {
  let string = '';
  let newNumber = number;
  let newFull = full;
  const million = Math.floor(newNumber / 1000000);
  newNumber %= 1000000;
  if (million > 0) {
    string = `${readBlock(million, newFull)} triệu`;
    newFull = true;
  }
  const thousand = Math.floor(newNumber / 1000);
  newNumber %= 1000;
  if (thousand > 0) {
    string += `${readBlock(thousand, newFull)} nghìn`;
    newFull = true;
  }
  if (newNumber > 0) {
    string += readBlock(newNumber, newFull);
  }
  return string;
};
const renderNumber = (number) => {
  let newNumber = number;
  if (newNumber === 0) return `${arrayNumber[0]}`;
  let string = '';
  let suffixes = '';
  do {
    const billion = newNumber % 1000000000;
    newNumber = Math.floor(newNumber / 1000000000);
    if (newNumber > 0) {
      string = readMillion(billion, true) + suffixes + string;
    } else {
      string = readMillion(billion, false) + suffixes + string;
    }
    suffixes = ' tỷ';
  } while (newNumber > 0);
  // string += ' đồng';
  return string;
};

const renderFloat = (number) => {
  if (Number.isInteger(number) === false) {
    const newNumbers = number.toString().split('.');
    let string = '';
    if (newNumbers.length === 2) {
      string = renderNumber(newNumbers[0]);
      string += ' phẩy';
      let point = '0';
      if (newNumbers[1].length > 0) {
        // eslint-disable-next-line prefer-destructuring
        point = newNumbers[1];
      }
      do {
        const i = point[0];
        string += ` ${arrayNumber[i]}`;
        point = point.slice(1);
      } while (point.length > 0);
    }
    return string;
  }
  return renderNumber(number);
};

const formatString = (str) => {
  return str !== null ? str : '-';
};

const formatCustomerName = (customer) => {
  if (customer.gender === 'male') {
    return `Ông ${customer.name}`;
  }
  if (customer.gender === 'female') {
    return `Bà ${customer.name}`;
  }
  return `Ông (Bà) ${customer.name}`;
};

const formatDate = (strDate) => {
  const objDate = new Date(strDate);
  return `${objDate.getDate()} tháng ${parseInt(
    objDate.getMonth() + 1,
    10,
  )} năm ${objDate.getFullYear()}`;
};

const formatToday = () => {
  const today = new Date();
  return `${today.getDate()} tháng ${parseInt(
    today.getMonth() + 1,
    10,
  )} năm ${today.getFullYear()}`;
};

const ContractPaper = (
  sellitem,
  payments,
  accounts,
  customers,
  discounts,
  product,
  row,
  description,
  paymentConfirm,
) => {
  let htmlPrint = paymentConfirm;

  // Số tiền cọc
  const paymentDeposit = payments.filter((x, i) => x.type === DEPOSITYTPE && x.amount > 0);
  let paymentDepositAmount = 0;
  if (paymentDeposit.length > 0) {
    paymentDepositAmount = paymentDeposit[0].amount * 1;
    htmlPrint = htmlPrint.replaceAll(
      '@$STCBS$',
      (paymentDeposit[0].amount * 1).toLocaleString('en-GB'),
    );
    htmlPrint = htmlPrint.replaceAll('@$STCBC$', renderNumber(paymentDeposit[0].amount * 1));
  }

  // Số hợp đồng
  htmlPrint = htmlPrint.replaceAll('@$SHD$', product.name.replaceAll(/[A-Z,a-z]/g, ''));

  // Tên khách hàng
  let customerNames = [];
  customerNames = customers.map((customer) => {
    return formatCustomerName(customer);
  });
  htmlPrint = htmlPrint.replaceAll('@$TKH$', customerNames.join(' và '));

  // Ký hiệu lô đất LKXX.YY
  htmlPrint = htmlPrint.replaceAll('@$KHLD$', product.name);

  // Mo ta du an
  htmlPrint = htmlPrint.replaceAll('@$MTDA$', description);

  const personInfos = `<p style="font-size: 13pt;">- Ông (Bà): $CUSTOMERNAME$     Ngày sinh:  $CUSTOMERBIRTHDAY$ </p>
    <p style="font-size: 13pt;">- Số CMND/CCCD/HC: $CMND$     Cấp ngày: $CMNDDATE$ Tại:  $PROVINCE$.`;
  let customerInfos = [];
  customerInfos = customers.map((customer) => {
    let fmtBirthday = '';
    if (customer.birthday) {
      fmtBirthday = new Date(customer.birthday).toLocaleDateString('vi-VN');
    }
    let fmtIdDate = '';
    if (customer.idDate) {
      fmtIdDate = new Date(customer.idDate).toLocaleDateString('vi-VN');
    }

    let persionInfo = personInfos;
    persionInfo = persionInfo.replaceAll('$CUSTOMERNAME$', formatString(customer.name));
    persionInfo = persionInfo.replaceAll('$CUSTOMERBIRTHDAY$', formatString(fmtBirthday));
    persionInfo = persionInfo.replaceAll('$CMND$', formatString(customer.idNumber));
    persionInfo = persionInfo.replaceAll('$CMNDDATE$', formatString(fmtIdDate));
    persionInfo = persionInfo.replaceAll('$PROVINCE$', formatString(customer.idPlace));
    return persionInfo;
  });

  // Thông tin khách hàng
  htmlPrint = htmlPrint.replaceAll('@$TTKH$', customerInfos.join(''));

  // - Địa chỉ liên hệ: $ADDRESS$
  htmlPrint = htmlPrint.replaceAll(
    '@$DCLH$',
    customers && customers.length > 0
      ? `${formatString(customers[0].address)}, ${formatString(customers[0].ward)}, ${formatString(
          customers[0].district,
        )}, ${formatString(customers[0].province)},`
      : '',
  );

  // - Điện thoại: $SDT$
  htmlPrint = htmlPrint.replaceAll(
    '@$SDT$',
    customers && customers.length > 0 ? customers[0].phone : '',
  );

  // const today = new Date();
  // const date = `${today.getDate()} tháng ${parseInt(
  //   today.getMonth() + 1,
  //   10,
  // )} năm ${today.getFullYear()}`;
  // Ngày hôm nay
  htmlPrint = htmlPrint.replaceAll('@$NHN$', formatToday());

  // { text: 'Ngày hôm nay DD/MM/YYYY', value: '$NHNSHORT$' },
  htmlPrint = htmlPrint.replaceAll('@$NHNSHORT$', new Date().toLocaleDateString('vi-VN'));

  // Số đợt nộp tiền
  if (sellitem.type !== DEPOSITYTPE) {
    htmlPrint = htmlPrint.replaceAll('@$SDNT$', row);
  }

  // Số tiền nộp bằng số
  htmlPrint = htmlPrint.replaceAll(
    '@$STNBS$',
    `${(payments[row].amount * 1).toLocaleString('en-GB')}`,
  );
  // Số tiền nộp bằng chữ
  htmlPrint = htmlPrint.replaceAll('@$STNBC$', renderNumber(payments[row].amount * 1));

  // Số tài khoản
  htmlPrint = htmlPrint.replaceAll('@$STK$', payments[row].accountNumber);
  // Tên ngân hàng
  htmlPrint = htmlPrint.replaceAll('@$TNN$', formatString(payments[row].accountDescription));

  // Hình thức nộp tiền $HTNT$
  // nộp tiền mặt / chuyển khoản vào Tài khoản số ..... tại ngân hàng ....
  if (payments[row].accountType === 'cash') {
    htmlPrint = htmlPrint.replaceAll('@$HTNT$', 'nộp tiền mặt');
  } else {
    htmlPrint = htmlPrint.replaceAll(
      '@$HTNT$',
      `chuyển khoản vào Tài khoản số ${payments[row].accountNumber} tại ngân hàng ${formatString(
        payments[row].accountDescription,
      )}`,
    );
  }

  // Ngày nộp tiền
  let fmtGotDate = '';
  let fmtGotDateDDMMYYYY = '';
  if (payments[row].gotDate) {
    fmtGotDate = formatDate(payments[row].gotDate);
    fmtGotDateDDMMYYYY = new Date(payments[row].gotDate).toLocaleDateString('vi-VN');
  } else {
    fmtGotDate = formatToday();
    fmtGotDateDDMMYYYY = new Date().toLocaleDateString('vi-VN');
  }
  htmlPrint = htmlPrint.replaceAll('@$NNT$', fmtGotDate);

  // { text: 'Ngày nộp tiền DD/MM/YYYY', value: '$NNTSHORT$' },
  htmlPrint = htmlPrint.replaceAll('@$NNTSHORT$', fmtGotDateDDMMYYYY);

  // Ngày nộp cọc
  let fmtDepositDate = '';
  let fmtDepositDateDDMMYYYY = '';
  if (paymentDeposit.length > 0 && paymentDeposit[0].gotDate) {
    fmtDepositDate = formatDate(paymentDeposit[0].gotDate);
    fmtDepositDateDDMMYYYY = new Date(paymentDeposit[0].gotDate).toLocaleDateString('vi-VN');
  } else {
    fmtDepositDate = formatToday();
    fmtDepositDateDDMMYYYY = new Date().toLocaleDateString('vi-VN');
  }
  htmlPrint = htmlPrint.replaceAll('@$NNC$', fmtDepositDate);
  // { text: 'Ngày nộp cọc DD/MM/YYYY', value: '$NNCSHORT$' },
  htmlPrint = htmlPrint.replaceAll('@$NNCSHORT$', fmtDepositDateDDMMYYYY);

  htmlPrint = htmlPrint.replaceAll('@$NTM$', '<div class="pagebreak"> </div>');

  // Ký hiệu lô đất
  htmlPrint = htmlPrint.replaceAll('@$SLD$', product.landNumber);

  // Diện tích
  htmlPrint = htmlPrint.replaceAll('@$DTBS$', product.landArea);
  htmlPrint = htmlPrint.replaceAll('@$DTBC$', renderFloat(product.landArea * 1));

  // 'Địa chỉ thửa đất', value: '$DCTD$'
  htmlPrint = htmlPrint.replaceAll('@$DCTD$', product.street);

  // Diện tích sàn
  htmlPrint = htmlPrint.replaceAll('@$DTSBS$', product.floorArea);
  htmlPrint = htmlPrint.replaceAll('@$DTSBC$', renderFloat(product.floorArea * 1));

  // { text: 'Đơn giá đất', value: '$DGD$' },
  htmlPrint = htmlPrint.replaceAll('@$DGD$', (product.landPrice * 1).toLocaleString('en-GB'));

  // { text: 'Đơn giá xây dựng', value: '$DGXD$' },
  htmlPrint = htmlPrint.replaceAll('@$DGXD$', (product.floorPrice * 1).toLocaleString('en-GB'));

  // { text: 'Tổng tiền xây dựng bằng số', value: '$TTXDBS$', url: '#' },
  htmlPrint = htmlPrint.replaceAll(
    '@$TTXDBS$',
    (product.floorPrice * product.floorArea).toLocaleString('en-GB'),
  );

  // { text: 'Tổng tiền xây dựng bằng chữ', value: '$TTXDBC$', url: '#' },
  htmlPrint = htmlPrint.replaceAll(
    '@$TTXDBC$',
    renderNumber(product.floorPrice * product.floorArea),
  );

  // Tổng giá trị Hợp đồng (đã bao gồm VAT 10%)
  htmlPrint = htmlPrint.replaceAll('@$TGTHDBS$', (sellitem.sellPrice * 1).toLocaleString('en-GB'));
  htmlPrint = htmlPrint.replaceAll('@$TGTHDBC$', renderNumber(sellitem.sellPrice * 1));

  // Thành tiền đất trước VAT
  htmlPrint = htmlPrint.replaceAll(
    '@$TTDTVATBS$',
    (product?.landTotalWithoutVAT || 0).toLocaleString('en-GB'),
  );
  htmlPrint = htmlPrint.replaceAll(
    '@$TTDTVATBC$',
    renderNumber((product?.landTotalWithoutVAT || 0) * 1),
  );

  // Thành tiền đất sau VAT
  htmlPrint = htmlPrint.replaceAll(
    '@$TTDSVATBS$',
    (product.landArea * product.landPrice * 1).toLocaleString('en-GB'),
  );
  htmlPrint = htmlPrint.replaceAll(
    '@$TTDSVATBC$',
    renderNumber(product.landArea * product.landPrice * 1),
  );

  // Chính sách ưu đãi tổng cộng:
  const totalDiscount = sellitem.discount1 * 1 + sellitem.discount2 * 1 + sellitem.discount3 * 1;
  htmlPrint = htmlPrint.replaceAll('@$UDTCBS$', totalDiscount.toLocaleString('en-GB'));
  htmlPrint = htmlPrint.replaceAll('@$UDTCBC$', renderNumber(totalDiscount));

  // Chiết khấu thanh toán nhanh 3% theo giá trước thuế
  const discount1Percent = parseFloat(
    (sellitem.discount1 / product.landTotalWithoutVAT) * 100,
  ).toFixed(2);
  htmlPrint = htmlPrint.replaceAll('@$CK1BS$', discount1Percent);
  htmlPrint = htmlPrint.replaceAll('@$CK1STBS$', (sellitem.discount1 * 1).toLocaleString('en-GB'));
  htmlPrint = htmlPrint.replaceAll('@$CK1STBC$', renderNumber(sellitem.discount1 * 1));

  const discount2Percent = parseFloat(
    (sellitem.discount2 / product.landTotalWithoutVAT) * 100,
  ).toFixed(2);
  htmlPrint = htmlPrint.replaceAll('@$CK2BS$', discount2Percent);
  htmlPrint = htmlPrint.replaceAll('@$CK2STBS$', (sellitem.discount2 * 1).toLocaleString('en-GB'));
  htmlPrint = htmlPrint.replaceAll('@$CK2STBC$', renderNumber(sellitem.discount2 * 1));

  const discount3Percent = parseFloat(
    (sellitem.discount3 / product.landTotalWithoutVAT) * 100,
  ).toFixed(2);
  htmlPrint = htmlPrint.replaceAll('@$CK3BS$', discount3Percent);
  htmlPrint = htmlPrint.replaceAll('@$CK3STBS$', (sellitem.discount3 * 1).toLocaleString('en-GB'));
  htmlPrint = htmlPrint.replaceAll('@$CK3STBC$', renderNumber(sellitem.discount3 * 1));

  // Tổng số tiền còn lại sau khi trừ chiết khấu:
  htmlPrint = htmlPrint.replaceAll('@$TSTCLSKTCKBS$', (sellitem.total * 1).toLocaleString('en-GB'));
  htmlPrint = htmlPrint.replaceAll('@$TSTCLSKTCKBC$', renderNumber(sellitem.total * 1));

  // Tiến độ thanh toán
  const paymentProcesses = payments.filter((x, i) => x.type !== DEPOSITYTPE && x.amount > 0);
  // Giai đoạn 1 gồm cọc
  if (paymentProcesses.length > 0) {
    htmlPrint = htmlPrint.replaceAll('@$PTD1$', paymentProcesses[0].percent); // % phần trăm
    htmlPrint = htmlPrint.replaceAll(
      '@$STD1BS$',
      (paymentDepositAmount + paymentProcesses[0].amount * 1).toLocaleString('en-GB'),
    );
    htmlPrint = htmlPrint.replaceAll(
      '@$STD1BC$',
      renderNumber(paymentDepositAmount + paymentProcesses[0].amount * 1),
    );

    htmlPrint = htmlPrint.replaceAll('@$THTTGD1$', formatDate(paymentProcesses[0].dueDate));
  }
  // Từ giai đoạn 2
  /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
  for (let index = 1; index < paymentProcesses.length; index++) {
    const PTDn = `@$PTD${index + 1}$`;
    const STDnBS = `@$STD${index + 1}BS$`;
    const STDnBC = `@$STD${index + 1}BC$`;
    htmlPrint = htmlPrint.replaceAll(PTDn, paymentProcesses[index].percent);
    htmlPrint = htmlPrint.replaceAll(
      STDnBS,
      (paymentProcesses[index].amount * 1).toLocaleString('en-GB'),
    );
    htmlPrint = htmlPrint.replaceAll(STDnBC, renderNumber(paymentProcesses[0].amount * 1));
  }

  // Số tiền nộp tổng cộng bằng số
  const totalPaymentProcesses = payments.reduce((acc, curr) => {
    return acc + curr.amount * 1;
  }, 0);
  htmlPrint = htmlPrint.replaceAll(
    '@$STNTCBS$',
    (totalPaymentProcesses * 1).toLocaleString('en-GB'),
  );

  // Bảng tiến độ thanh toán
  const paymentPercentAndQuick = [
    'Thanh toán <strong>$PERCENT$%</strong> giá trị hợp đồng trong thời gian $DEPOSITDAYS$ ngày kể từ ngày đặt cọc (Bao gồm <strong>$AMOUNT$</strong> đồng tiền đặt cọc)',
    'Thanh toán <strong>$PERCENT$%</strong> giá trị hợp đồng trong thời gian $DAYS$ ngày kể từ ngày nộp tiền đợt 1',
    'Thanh toán <strong>$PERCENT$%</strong> giá trị hợp đồng trong thời gian $DAYS$ ngày kể từ ngày nộp tiền đợt 1 và nhận chứng nhận QSDĐ',
  ];
  const paymentBank = [
    'Thanh toán <strong>$PERCENT$%</strong> giá trị hợp đồng trong thời gian $DEPOSITDAYS$ ngày sau khi đăng ký đặt mua (Bao gồm <strong>$AMOUNT$</strong> đồng tiền đặt cọc)',
    'Ngân hàng giải ngân số tiền còn lại vào tài khoản công ty trong thời gian $DAYS$ ngày từ đợt 1, sau khi có thông báo đồng ý cho vay của ngân hàng hỗ trợ vốn.',
    'Ngân hàng giải ngân số tiền còn lại vào tài khoản công ty trong thời gian $DAYS$ ngày từ đợt 1.',
  ];
  let paymentProcessesTable = [];
  const paymentProcessCount = paymentProcesses.length;
  // const dueDate0 = new Date(payments[0].dueDate);
  const dueDate1 = new Date(paymentProcesses[0].dueDate);
  const gotDate0 = new Date(payments[0].gotDate);
  // One day Time in ms (milliseconds)
  const oneDay = 1000 * 60 * 60 * 24;
  // 5 hoặc 8 giai đoạn  thanh  toán
  if (sellitem.paymentMethod === 'bank') {
    // 2 giai đoạn  thanh  toán
    paymentProcessesTable = paymentProcesses.map((payment, index) => {
      let paymentPercentHtml = '';
      if (index === 0) {
        // eslint-disable-next-line prefer-destructuring
        paymentPercentHtml = paymentBank[0];
      } else if (index === 1) {
        // eslint-disable-next-line prefer-destructuring
        paymentPercentHtml = paymentBank[1];
      } else {
        // eslint-disable-next-line prefer-destructuring
        paymentPercentHtml = paymentBank[2];
      }

      const dueDate = new Date(payment.dueDate);
      let diffInTime = dueDate.getTime() - dueDate1.getTime();
      if (index === 0) {
        diffInTime = dueDate.getTime() - gotDate0.getTime();
      }
      const diffInDays = diffInTime / oneDay;
      paymentPercentHtml = paymentPercentHtml.replaceAll('$DEPOSITDAYS$', parseInt(diffInDays, 10));
      paymentPercentHtml = paymentPercentHtml.replaceAll('$DAYS$', parseInt(diffInDays, 10));
      paymentPercentHtml = paymentPercentHtml.replaceAll('$PERCENT$', payment.percent);
      paymentPercentHtml = paymentPercentHtml.replaceAll(
        '$AMOUNT$',
        paymentDepositAmount.toLocaleString('en-GB'),
      );
      paymentPercentHtml = paymentPercentHtml.replaceAll('$N$', index);
      const payAmount =
        payment.type === 'process2'
          ? payment.amount * 1 + paymentDepositAmount
          : payment.amount * 1;
      return `<tr><td>Đợt ${index + 1}</td>
        <td>${paymentPercentHtml}</td>
        <td>${payAmount.toLocaleString('en-GB')}</td>
        </tr>`;
    });
  } else {
    // 2 giai đoạn  thanh  toán
    paymentProcessesTable = paymentProcesses.map((payment, index) => {
      const dueDate = new Date(payment.dueDate);
      // số  ngày tính từ  ngày  TTGĐĐ1
      let diffInTime = dueDate.getTime() - dueDate1.getTime();

      let paymentPercentHtml = '';
      if (index === 0) {
        // ngày TTGĐĐ1 - ngày đặt  cọc mặc định là  7 ngày
        diffInTime = dueDate.getTime() - gotDate0.getTime();
        // eslint-disable-next-line prefer-destructuring
        paymentPercentHtml = paymentPercentAndQuick[0];
      } else if (index === paymentProcessCount - 1) {
        // eslint-disable-next-line prefer-destructuring
        paymentPercentHtml = paymentPercentAndQuick[2];
      } else {
        // eslint-disable-next-line prefer-destructuring
        paymentPercentHtml = paymentPercentAndQuick[1];
      }
      const diffInDays = diffInTime / oneDay;
      paymentPercentHtml = paymentPercentHtml.replaceAll('$DEPOSITDAYS$', parseInt(diffInDays, 10));
      paymentPercentHtml = paymentPercentHtml.replaceAll('$DAYS$', parseInt(diffInDays, 10));
      paymentPercentHtml = paymentPercentHtml.replaceAll('$PERCENT$', payment.percent);
      paymentPercentHtml = paymentPercentHtml.replaceAll(
        '$AMOUNT$',
        paymentDepositAmount.toLocaleString('en-GB'),
      );
      paymentPercentHtml = paymentPercentHtml.replaceAll('$N$', index);
      const payAmount =
        payment.type === 'process2'
          ? payment.amount * 1 + paymentDepositAmount
          : payment.amount * 1;
      return `<tr><td>Đợt ${index + 1}</td>
      <td>${paymentPercentHtml}</td>
      <td>${payAmount.toLocaleString('en-GB')}</td>
      </tr>`;
    });
  }

  const PAYMENTPROCESSTABLE = ` <table border="1">
    <tr>
        <th>Đợt thanh toán</th>
        <th>Thời điểm thanh toán</th>
        <th>Số tiền ( ĐVT: Đồng)</th>
    </tr>
    ${paymentProcessesTable.join('')}
    <tr>
        <td></td>
        <td>Tổng cộng</td>
        <td>${(totalPaymentProcesses * 1).toLocaleString('en-GB')}</td>
    </tr>
    </table> `;
  htmlPrint = htmlPrint.replaceAll('@$BTDTT$', PAYMENTPROCESSTABLE);

  // Nội dung chiết khấu
  /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
  for (let index = 0; index < discounts.length; index++) {
    const NDCKn = `@$NDCK${discounts[index].position + 1}$`;
    htmlPrint = htmlPrint.replaceAll(NDCKn, discounts[index].description);
  }

  // Danh sách tất cả các chiết khấu
  let discountList = '';
  for (let index = 0; index < discounts.length; index++) {
    let discountAmount = '';
    if (discounts[index].position === 0) {
      if (sellitem.discount1 > 0) {
        discountAmount = ` tương đương với số tiền là: ${(sellitem.discount1 * 1).toLocaleString(
          'en-GB',
        )}đồng. (Bằng chữ: ${renderNumber(sellitem.discount1 * 1)} đồng)`;
      }
    } else if (discounts[index].position === 1) {
      if (sellitem.discount2 > 0) {
        discountAmount = ` tương đương với số tiền là: ${(sellitem.discount2 * 1).toLocaleString(
          'en-GB',
        )}đồng. (Bằng chữ: ${renderNumber(sellitem.discount2 * 1)} đồng)`;
      }
    } else if (discounts[index].position === 2) {
      if (sellitem.discount3 > 0) {
        discountAmount = ` tương đương với số tiền là: ${(sellitem.discount3 * 1).toLocaleString(
          'en-GB',
        )}đồng. (Bằng chữ: ${renderNumber(sellitem.discount3 * 1)} đồng)`;
      }
    } else if (discounts[index].position === 3) {
      if (sellitem.discount4 > 0) {
        discountAmount = ` tương đương với số tiền là: ${(sellitem.discount4 * 1).toLocaleString(
          'en-GB',
        )}đồng. (Bằng chữ: ${renderNumber(sellitem.discount4 * 1)} đồng)`;
      }
    }
    discountList += `<p style="font-size: 13pt;">${discounts[index].description}${discountAmount}.</p>`;
  }
  htmlPrint = htmlPrint.replaceAll('@$DSCK$', discountList);

  return htmlPrint;
};

export default ContractPaper;
