import ActionTypes from '../constants/ActionTypes';

export const createProjectAgent = (projectAgent) => ({
  type: ActionTypes.PROJECT_AGENT_CREATE,
  payload: {
    projectAgent,
  },
});

createProjectAgent.success = (localId, productId, projectAgent) => ({
  type: ActionTypes.PROJECT_AGENT_CREATE__SUCCESS,
  payload: {
    localId,
    productId,
    projectAgent,
  },
});

createProjectAgent.failure = (localId, error) => ({
  type: ActionTypes.PROJECT_AGENT_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const updateProjectAgent = (id, data) => ({
  type: ActionTypes.PROJECT_AGENT_UPDATE,
  payload: {
    id,
    data,
  },
});

updateProjectAgent.success = (projectAgent) => ({
  type: ActionTypes.PROJECT_AGENT_UPDATE__SUCCESS,
  payload: {
    projectAgent,
  },
});

updateProjectAgent.failure = (id, error) => ({
  type: ActionTypes.PROJECT_AGENT_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});
