import { connect } from 'react-redux';

import { currentGroupSelector, pathsMatchSelector } from '../selectors';
import GroupWrapper from '../components/GroupWrapper';

const mapStateToProps = (state) => {
  const { type, isFetching } = currentGroupSelector(state);
  const { path } = pathsMatchSelector(state);

  return {
    type,
    isFetching,
    path,
  };
};

export default connect(mapStateToProps)(GroupWrapper);
