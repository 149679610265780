export default {
  translation: {
    common: {
      emailOrUsername: 'E-mail hoặc username',
      invalidEmailOrUsername: 'Sai e-mail or username',
      invalidPassword: 'Sai mật khẩu',
      logInToTiciLand: 'Đăng nhập',
      noInternetConnection: 'Mất kết nối mạng',
      pageNotFound_title: 'Không tìm thấy trang',
      password: 'Mật khẩu',
      projectManagement: 'Quản lý project',
      serverConnectionFailed: 'Mất kết nối đến server',
      unknownError: 'Lỗi! Hãy thử lại sau',
      userDisble: 'User đã bị khoá',
    },

    action: {
      logIn: 'Đăng nhập',
    },
  },
};
