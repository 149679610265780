import EntryActionTypes from '../../constants/EntryActionTypes';

export const createBag = (data) => ({
  type: EntryActionTypes.BAG_CREATE,
  payload: {
    data,
  },
});

export const handleBagCreate = (bag) => ({
  type: EntryActionTypes.BAG_CREATE_HANDLE,
  payload: {
    bag,
  },
});

export const updateBag = (id, data) => ({
  type: EntryActionTypes.BAG_UPDATE,
  payload: {
    id,
    data,
  },
});

export const updateCurrentBag = (data) => ({
  type: EntryActionTypes.CURRENT_BAG_UPDATE,
  payload: {
    data,
  },
});

export const handleBagUpdate = (bag) => ({
  type: EntryActionTypes.BAG_UPDATE_HANDLE,
  payload: {
    bag,
  },
});

export const deleteBag = (id) => ({
  type: EntryActionTypes.BAG_DELETE,
  payload: {
    id,
  },
});

export const deleteCurrentBag = () => ({
  type: EntryActionTypes.CURRENT_BAG_DELETE,
  payload: {},
});

export const handleBagDelete = (bag) => ({
  type: EntryActionTypes.BAG_DELETE_HANDLE,
  payload: {
    bag,
  },
});

export const recalculateCommission = (id) => ({
  type: EntryActionTypes.BAG_RECALCULATE_COMMISSION,
  payload: {
    id,
  },
});
