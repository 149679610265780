// import { dequal } from 'dequal';
import pickBy from 'lodash/pickBy';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Grid, Checkbox } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { HotTable } from '@handsontable/react';

import { useForm } from '../../../hooks';

import styles from './InformationEdit.module.scss';

const InformationEdit = React.memo(({ defaultData, onUpdate }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm(() => ({
    name: '',
    description: '',
    province: '',
    district: '',
    ward: '',
    address: '',
    ...pickBy(defaultData),
  }));

  const [payment, setPayment] = useState(defaultData.paymentMethod);
  let paymentData = payment;

  const colHeaders = ['type', t('common.name'), t('common.percent'), t('common.due')];
  const columns = [
    { type: 'text', data: 'type' },
    { type: 'text', data: 'name' },
    {
      type: 'numeric',
      data: 'percent',
      className: 'htCenter htMiddle',
      numericFormat: {
        pattern: '0%',
      },
      validator: /(^[01]$|^(\d+.\d+)$)/,
      allowInvalid: false,
    },
    {
      type: 'numeric',
      data: 'due',
      className: 'htCenter htMiddle',
      numericFormat: {
        pattern: '0',
      },
      validator: /^[0-9]+$/,
      allowInvalid: false,
    },
  ];

  const cleanData = useMemo(
    () => ({
      ...data,
      name: data.name?.trim(),
      description: data.description?.trim() || undefined,
      province: data.province?.trim() || undefined,
      district: data.district?.trim() || undefined,
      ward: data.ward?.trim() || undefined,
      address: data.address?.trim() || undefined,
      startDate: data.startDate,
      openDate: data.openDate,
      paymentMethod: paymentData,
      isAutoUpdateSellitem: data.isAutoUpdateSellitem,
      isAutoUpdateProduct: data.isAutoUpdateProduct,
    }),
    [data, paymentData],
  );

  // console.log('defaultData:', defaultData);
  // console.log('cleanData:', cleanData);
  // console.log('payment:', payment);

  const nameField = useRef(null);
  const descriptionField = useRef(null);
  const provinceField = useRef(null);
  const districtField = useRef(null);
  const wardField = useRef(null);
  const addressField = useRef(null);

  const handleStartDateUpdate = useCallback(
    (newDate) => {
      setData((prevData) => ({
        ...prevData,
        startDate: newDate,
      }));
    },
    [setData],
  );
  const handleOpenDateUpdate = useCallback(
    (newDate) => {
      setData((prevData) => ({
        ...prevData,
        openDate: newDate,
      }));
    },
    [setData],
  );
  const handleSubmit = useCallback(() => {
    // console.log('handleSubmit:', cleanData);
    if (!cleanData.name) {
      nameField.current.select();
      return;
    }

    const updateData = {
      ...cleanData,
      paymentMethod: paymentData,
    };
    // console.log('updateData:', updateData);
    onUpdate(updateData);
    // console.log('paymentData2:', paymentData);
    setPayment(paymentData);
  }, [onUpdate, cleanData, paymentData]);

  const afterPercentChange = (mode, changes) => {
    // [[row, prop, oldVal, newVal], ...]
    if (changes) {
      const oldPayment = [...paymentData];
      // console.log('oldPayment', oldPayment);
      const change = changes[0];
      // console.log('change:', change);

      const pm1 = oldPayment.filter((pm) => pm.mode === mode);
      const newPercent = [];
      // add new row
      if (change[0] >= pm1[0].percent.length) {
        pm1[0].percent.map((pc) => newPercent.push(pc));
        newPercent.push({
          [change[1]]: change[3],
          type: `process${change[0] + 1}`,
        });
      } else {
        for (let i = 0; i < pm1[0].percent.length; i += 1) {
          if (i === change[0]) {
            newPercent.push({
              ...pm1[0].percent[i],
              [change[1]]: change[3],
            });
          } else {
            newPercent.push(pm1[0].percent[i]);
          }
        }
      }
      // console.log('newPercent:', newPercent);

      const newPayment = oldPayment.map((pm) =>
        pm.mode === mode ? { ...pm, percent: newPercent } : pm,
      );
      setPayment(newPayment);
    }
  };

  const afterRemoveRow = useCallback(
    (mode, index, amount, physicalRows, source) => {
      // [[row, prop, oldVal, newVal], ...]

      if (source && source === 'ContextMenu.removeRow') {
        if (index > 0) {
          const oldPayment = [...paymentData];
          const pm1 = oldPayment.filter((pm) => pm.mode === mode);
          if (pm1) {
            const oldPercent = [];
            pm1[0].percent.map((pc) => oldPercent.push(pc));
            oldPercent.splice(index, amount);
            // console.log('oldPercent:', oldPercent);
            const newPercent = [];
            for (let i = 0; i < oldPercent.length; i += 1) {
              newPercent.push({
                ...oldPercent[i],
                type: `process${i + 1}`,
              });
            }

            const newPayment = oldPayment.map((pm) =>
              pm.mode === mode ? { ...pm, percent: newPercent } : pm,
            );

            // eslint-disable-next-line react-hooks/exhaustive-deps
            paymentData = newPayment;
          }
        }
      }
    },
    [payment],
  );

  const beforeRemoveRow = (mode, index, amount, physicalRows, source) => {
    // [[row, prop, oldVal, newVal], ...]
    if (source && source === 'ContextMenu.removeRow') {
      if (index > 0) {
        return true;
      }
    }
    return false;
  };

  const handleFieldCheckboxChange = useCallback(
    (e, dataValue) => {
      setData({
        ...data,
        [dataValue.name]: dataValue.checked,
      });
    },
    [data, setData],
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {/* <div className={styles.text}>{t('common.title')}</div> */}
        <Form.Group>
          <Form.Field width={4}>
            <div className={styles.text}>{t('common.projectName')}</div>
            <Input
              fluid
              ref={nameField}
              name="name"
              value={data.name}
              className={styles.field}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Field width={12}>
            <div className={styles.text}>{t('common.description')}</div>
            <Input
              fluid
              ref={descriptionField}
              name="description"
              value={data.description}
              className={styles.field}
              onChange={handleFieldChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <div className={styles.text}>{t('common.province')}</div>
            <Input
              fluid
              ref={provinceField}
              name="province"
              value={data.province}
              className={styles.field}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.text}>{t('common.district')}</div>
            <Input
              fluid
              ref={districtField}
              name="district"
              value={data.district}
              className={styles.field}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.text}>{t('common.ward')}</div>
            <Input
              fluid
              ref={wardField}
              name="ward"
              value={data.ward}
              className={styles.field}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.text}>{t('common.address')}</div>
            <Input
              fluid
              ref={addressField}
              name="address"
              value={data.address}
              className={styles.field}
              onChange={handleFieldChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field className={styles.date}>
            <div className={styles.text}>{t('common.startDate')}</div>
            <DatePicker
              disabledKeydistrictNavigation
              selected={data.startDate || null}
              dateFormat="d/M/yyyy"
              placeholderText="d/M/yyyy"
              // maxDate={new Date()}
              onChange={handleStartDateUpdate}
            />
          </Form.Field>
          <Form.Field className={styles.date}>
            <div className={styles.text}>{t('common.openDate')}</div>
            <DatePicker
              disabledKeydistrictNavigation
              selected={data.openDate || null}
              dateFormat="d/M/yyyy"
              placeholderText="d/M/yyyy"
              // maxDate={new Date()}
              onChange={handleOpenDateUpdate}
            />
          </Form.Field>
          <Form.Field />
          <Form.Field />
        </Form.Group>
        {/* <Button positive disabled={dequal(cleanData, defaultData)} content={t('action.save')} /> */}
      </Form>
      <Form.Group>
        {/* <Form.Field className={styles.fieldCheckbox}>
          <div>{data.isAutoUpdateSellitem}</div>
          <Checkbox
            name="isAutoUpdateSellitem"
            className={styles.field}
            onChange={handleFieldCheckboxChange}
            checked={data.isAutoUpdateSellitem}
            label={t('common.isAutoUpdateSellitem_desc')}
          />
        </Form.Field> */}
        <Form.Field className={styles.fieldCheckbox}>
          <div>{data.isAutoUpdateProduct}</div>
          <Checkbox
            name="isAutoUpdateProduct"
            className={styles.field}
            onChange={handleFieldCheckboxChange}
            checked={data.isAutoUpdateProduct}
            label={t('common.isAutoUpdateProduct_desc')}
          />
        </Form.Field>
      </Form.Group>
      <Grid divided="vertically">
        <Grid.Row columns={3}>
          {payment.map((pm) => (
            <Grid.Column key={`hot-table-${pm.mode}`}>
              <div>
                <div className={styles.text}>{t(`common.${pm.mode}`)}</div>
                <div>
                  <HotTable
                    data={pm.percent.map((pc) => ({
                      name: pc.name,
                      percent: pc.percent,
                      due: pc.due,
                    }))}
                    columns={columns}
                    colHeaders={colHeaders}
                    // colWidths={colWidths}
                    hiddenColumns={{ columns: [0] }}
                    // rowHeaders
                    height={(pm.percent.length + 1) * 24 + 35}
                    // height="auto"
                    width="auto"
                    minSpareRows={1}
                    autoColumnSize={false}
                    autoRowSize={false}
                    stretchH="all"
                    contextMenu={['remove_row']}
                    enterMoves={{ col: 1, row: 0 }}
                    afterChange={(changes, source) => afterPercentChange(pm.mode, changes, source)}
                    // afterCreateRow={afterCreateRow}
                    afterRemoveRow={(index, amount, physicalRows, source) =>
                      afterRemoveRow(pm.mode, index, amount, physicalRows, source)
                    }
                    beforeRemoveRow={(index, amount, physicalRows, source) =>
                      beforeRemoveRow(pm.mode, index, amount, physicalRows, source)
                    }
                    // beforeKeyDown={beforeKeyDown}
                    // beforeChange={beforeChange}
                    licenseKey="non-commercial-and-evaluation"
                  />
                </div>
              </div>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      <Button
        positive
        // disabled={dequal(cleanData, defaultData) && dequal(payment, paymentData)}
        content={t('action.save')}
        onClick={handleSubmit}
      />
    </>
  );
});

InformationEdit.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
};

export default InformationEdit;
