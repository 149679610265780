import EntryActionTypes from '../../constants/EntryActionTypes';

export const createAgentInCurrentProject = (data) => ({
  type: EntryActionTypes.AGENT_IN_CURRENT_PROJECT_CREATE,
  payload: {
    data,
  },
});

export const createAgent = (agent) => ({
  type: EntryActionTypes.AGENT_CREATE,
  payload: {
    agent,
  },
});
export const handleAgentCreate = (agent) => ({
  type: EntryActionTypes.AGENT_CREATE_HANDLE,
  payload: {
    agent,
  },
});

export const updateAgent = (id, data) => ({
  type: EntryActionTypes.AGENT_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleAgentUpdate = (agent) => ({
  type: EntryActionTypes.AGENT_UPDATE_HANDLE,
  payload: {
    agent,
  },
});

export const deleteAgent = (id) => ({
  type: EntryActionTypes.AGENT_DELETE,
  payload: {
    id,
  },
});

export const handleAgentDelete = (agent) => ({
  type: EntryActionTypes.AGENT_DELETE_HANDLE,
  payload: {
    agent,
  },
});

export const addAgentToFilterInCurrentGroup = (id) => ({
  type: EntryActionTypes.AGENT_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    id,
  },
});

export const removeAgentFromFilterInCurrentGroup = (id) => ({
  type: EntryActionTypes.AGENT_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    id,
  },
});
