const ROOT = '/';
const LOGIN = '/login';
const PROJECTS = '/d/:id';
const GROUPS = '/g/:id';
const PRODUCTS = '/p/:id';
const CUSTOMERS = '/c/:groupId/:id';
const BAGS = '/b/:id';
const SELLITEMS = '/s/:id';
const DASHBOARD = '/a/:id';
const TRANSACTIONS = '/t/:id';
const ACCOUNTANT = '/e/:id';
const DEBTS = '/f/:id';

export default {
  ROOT,
  LOGIN,
  PROJECTS,
  GROUPS,
  PRODUCTS,
  CUSTOMERS,
  BAGS,
  SELLITEMS,
  DASHBOARD,
  TRANSACTIONS,
  ACCOUNTANT,
  DEBTS,
};
