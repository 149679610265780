import EntryActionTypes from '../../constants/EntryActionTypes';

export const createBlockInCurrentGroup = (data) => ({
  type: EntryActionTypes.BLOCK_IN_CURRENT_GROUP_CREATE,
  payload: {
    data,
  },
});

export const handleBlockCreate = (block) => ({
  type: EntryActionTypes.BLOCK_CREATE_HANDLE,
  payload: {
    block,
  },
});

export const updateBlock = (id, data) => ({
  type: EntryActionTypes.BLOCK_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleBlockUpdate = (block) => ({
  type: EntryActionTypes.BLOCK_UPDATE_HANDLE,
  payload: {
    block,
  },
});

export const moveBlock = (id, index) => ({
  type: EntryActionTypes.BLOCK_MOVE,
  payload: {
    id,
    index,
  },
});

export const deleteBlock = (id) => ({
  type: EntryActionTypes.BLOCK_DELETE,
  payload: {
    id,
  },
});

export const handleBlockDelete = (block) => ({
  type: EntryActionTypes.BLOCK_DELETE_HANDLE,
  payload: {
    block,
  },
});
