import { Model, attr, fk, many } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';
import Config from '../constants/Config';

export default class extends Model {
  static modelName = 'Group';

  static fields = {
    id: attr(),
    type: attr(),
    position: attr(),
    name: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    isSellitemsFetching: attr({
      getDefault: () => false,
    }),
    isAllSellitemsFetched: attr({
      getDefault: () => false,
    }),
    isProductsFetching: attr({
      getDefault: () => false,
    }),
    isAllProductsFetched: attr({
      getDefault: () => false,
    }),
    isBagsFetching: attr({
      getDefault: () => false,
    }),
    isAllBagsFetched: attr({
      getDefault: () => false,
    }),
    projectId: fk({
      to: 'Project',
      as: 'project',
      relatedName: 'groups',
    }),
    memberUsers: many({
      to: 'User',
      through: 'GroupMembership',
      relatedName: 'groups',
    }),
    filterUsers: many('User', 'filterGroups'),
    filterCategories: many('Category', 'filterGroups'),
    filterAgents: many('Agent', 'filterGroups'),
    filterLabels: many('Label', 'filterGroups'),
    filterProductStatuses: attr({
      getDefault: () => [],
    }),
    filterStatuses: attr({
      getDefault: () => [],
    }),
    filterBagStatuses: attr({
      getDefault: () => [],
    }),
  };

  static reducer({ type, payload }, Group) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        if (payload.group) {
          Group.upsert({
            ...payload.group,
            isFetching: false,
          });
        }

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__GROUP_FETCH:
      case ActionTypes.GROUP_FETCH:
        Group.withId(payload.id).update({
          isFetching: true,
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Group.all().delete();

        if (payload.group) {
          Group.upsert({
            ...payload.group,
            isFetching: false,
          });
        }

        payload.groups.forEach((group) => {
          Group.upsert(group);
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE__CORE_FETCH:
        Group.all()
          .toModelArray()
          .forEach((groupModel) => {
            if (groupModel.id !== payload.currentGroupId) {
              groupModel.update({
                isFetching: null,
              });

              groupModel.deleteRelated(payload.currentUserId);
            }
          });

        break;
      case ActionTypes.CORE_INITIALIZE:
        if (payload.group) {
          Group.upsert({
            ...payload.group,
            isFetching: false,
          });
        }

        payload.groups.forEach((group) => {
          Group.upsert(group);
        });

        break;
      case ActionTypes.USER_TO_GROUP_FILTER_ADD:
        Group.withId(payload.groupId).filterUsers.add(payload.id);

        break;
      case ActionTypes.USER_FROM_GROUP_FILTER_REMOVE:
        Group.withId(payload.groupId).filterUsers.remove(payload.id);

        break;
      case ActionTypes.CATEGORY_TO_GROUP_FILTER_ADD:
        Group.withId(payload.groupId).filterCategories.add(payload.id);

        break;
      case ActionTypes.CATEGORY_FROM_GROUP_FILTER_REMOVE:
        Group.withId(payload.groupId).filterCategories.remove(payload.id);

        break;
      case ActionTypes.AGENT_TO_GROUP_FILTER_ADD:
        Group.withId(payload.groupId).filterAgents.add(payload.id);

        break;
      case ActionTypes.AGENT_FROM_GROUP_FILTER_REMOVE:
        Group.withId(payload.groupId).filterAgents.remove(payload.id);

        break;
      case ActionTypes.STATUS_TO_GROUP_FILTER_ADD:
        {
          const groupModal = Group.withId(payload.groupId);
          groupModal.update({
            isFetching: true,
          });
          groupModal.filterStatuses.push(payload.key.status);
          groupModal.update({
            isFetching: false,
          });
        }

        break;
      case ActionTypes.STATUS_FROM_GROUP_FILTER_REMOVE:
        {
          const groupModal = Group.withId(payload.groupId);
          groupModal.update({
            isFetching: true,
          });
          const { filterStatuses } = groupModal;
          groupModal.filterStatuses.splice(filterStatuses.indexOf(payload.key.status), 1);
          groupModal.update({
            isFetching: false,
          });
        }

        break;
      case ActionTypes.BAG_STATUS_TO_GROUP_FILTER_ADD:
        {
          const groupModal = Group.withId(payload.groupId);
          groupModal.update({
            isFetching: true,
          });
          groupModal.filterBagStatuses.push(payload.key.status);
          groupModal.update({
            isFetching: false,
          });
        }

        break;
      case ActionTypes.BAG_STATUS_FROM_GROUP_FILTER_REMOVE:
        {
          const groupModal = Group.withId(payload.groupId);
          groupModal.update({
            isFetching: true,
          });
          const { filterBagStatuses } = groupModal;
          groupModal.filterBagStatuses.splice(filterBagStatuses.indexOf(payload.key.status), 1);
          groupModal.update({
            isFetching: false,
          });
        }

        break;
      case ActionTypes.PRODUCT_STATUS_TO_GROUP_FILTER_ADD:
        {
          const groupModal = Group.withId(payload.groupId);
          groupModal.update({
            isFetching: true,
          });
          groupModal.filterProductStatuses.push(payload.key.status);
          groupModal.update({
            isFetching: false,
          });
        }

        break;
      case ActionTypes.PRODUCT_STATUS_FROM_GROUP_FILTER_REMOVE:
        {
          const groupModal = Group.withId(payload.groupId);
          groupModal.update({
            isFetching: true,
          });
          const { filterProductStatuses } = groupModal;
          groupModal.filterProductStatuses.splice(
            filterProductStatuses.indexOf(payload.key.status),
            1,
          );
          groupModal.update({
            isFetching: false,
          });
        }

        break;
      case ActionTypes.PRODUCT_STATUS_FROM_GROUP_FILTER_REMOVE_ALL:
        {
          const groupModal = Group.withId(payload.groupId);
          groupModal.update({
            isFetching: true,
          });
          groupModal.filterProductStatuses = [];
          groupModal.update({
            isFetching: false,
          });
        }

        break;
      case ActionTypes.PROJECT_CREATE__SUCCESS:
      case ActionTypes.PROJECT_CREATE_HANDLE:
        payload.groups.forEach((group) => {
          Group.upsert(group);
        });

        break;
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        if (payload.groups) {
          payload.groups.forEach((group) => {
            Group.upsert({
              ...group,
              ...(payload.group &&
                payload.group.id === group.id && {
                  isFetching: false,
                }),
            });
          });
        }

        break;
      case ActionTypes.GROUP_CREATE:
      case ActionTypes.GROUP_CREATE_HANDLE:
      case ActionTypes.GROUP_UPDATE__SUCCESS:
      case ActionTypes.GROUP_UPDATE_HANDLE:
        Group.upsert(payload.group);

        break;
      case ActionTypes.GROUP_CREATE__SUCCESS:
        Group.withId(payload.localId).delete();

        Group.upsert({
          ...payload.group,
          isFetching: false,
        });

        break;
      case ActionTypes.GROUP_FETCH__FAILURE:
        Group.withId(payload.id).update({
          isFetching: null,
        });

        break;
      case ActionTypes.GROUP_UPDATE:
        Group.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.GROUP_DELETE:
        Group.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.GROUP_DELETE__SUCCESS:
      case ActionTypes.GROUP_DELETE_HANDLE: {
        const groupModel = Group.withId(payload.group.id);

        if (groupModel) {
          groupModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.SELLITEMS_FETCH:
        Group.withId(payload.groupId).update({
          isSellitemsFetching: true,
        });

        break;
      case ActionTypes.SELLITEMS_FETCH__SUCCESS:
        Group.withId(payload.groupId).update({
          isSellitemsFetching: false,
          isAllSellitemsFetched: payload.sellitems.length < Config.ACTIONS_LIMIT,
        });

        break;
      case ActionTypes.PRODUCTS_FETCH:
        Group.withId(payload.groupId).update({
          isProductsFetching: true,
        });

        break;
      case ActionTypes.PRODUCTS_FETCH__SUCCESS:
        Group.withId(payload.groupId).update({
          isProductsFetching: false,
          isAllProductsFetched: payload.products.length < Config.ACTIONS_LIMIT,
        });

        break;
      case ActionTypes.BAGS_FETCH:
        Group.withId(payload.groupId).update({
          isBagsFetching: true,
        });

        break;
      case ActionTypes.BAGS_FETCH__SUCCESS:
        Group.withId(payload.groupId).update({
          isBagsFetching: false,
          isAllBagsFetched: payload.bags.length < Config.ACTIONS_LIMIT,
        });

        break;
      case ActionTypes.LABEL_TO_GROUP_FILTER_ADD:
        Group.withId(payload.groupId).filterLabels.add(payload.id);

        break;
      case ActionTypes.LABEL_FROM_GROUP_FILTER_REMOVE:
        Group.withId(payload.groupId).filterLabels.remove(payload.id);

        break;
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('id', false);
  }

  getOrderedMembershipsQuerySet() {
    return this.memberships.orderBy('id');
  }

  getOrderedBlocksQuerySet() {
    return this.blocks.orderBy('position');
  }

  getOrderedProductsQuerySet() {
    return this.products.orderBy('name');
  }

  getOrderedBagsQuerySet() {
    return this.bags.orderBy('id', false);
  }

  getOrderedSellitemsQuerySet() {
    return this.sellitems.orderBy('id', false);
  }

  getOrderedInGroupSellitemsQuerySet() {
    return this.sellitems.orderBy('id', false);
  }

  hasMemberUser(userId) {
    return this.memberships
      .filter({
        userId,
      })
      .exists();
  }

  deleteRelated(exceptMemberUserId) {
    this.memberships.toModelArray().forEach((groupMembershipModel) => {
      if (groupMembershipModel.userId !== exceptMemberUserId) {
        groupMembershipModel.deleteWithRelated();
      }
    });

    this.labels.delete();

    this.blocks.toModelArray().forEach((blockModel) => {
      blockModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
