/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Popup, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ProductTable from '../ProductTable';
import RightSidebar from './RightSidebar';
import ProductsLeftSidebarContainer from '../../containers/ProductsLeftSidebarContainer';
import SellitemAddContainer from '../../containers/SellitemAddContainer';
import { ReactComponent as DotIcon } from '../../assets/images/dots-vertical.svg';

import styles from './Products.module.scss';
import SearchProducts from '../SearchProducts/SearchProducts';

const Products = React.memo(
  ({
    products,
    // categories,
    // agents,
    canEdit,
    toggleRightSidebar,
    onPaymentCreate,
    onProductCreate,
    onProjectAgentCreate,
    onSetRightSidebar,
    onUpdateProduct,
    onUpdateSellitem,
  }) => {
    const [t] = useTranslation();

    return (
      <div className={styles.wrapperGrid}>
        <Grid stackable columns={3}>
          <Grid.Row stretched>
            <Grid.Column width={3}>
              <Segment className={styles.sidebarLeft}>
                <ProductsLeftSidebarContainer />
              </Segment>
            </Grid.Column>
            <Grid.Column width={13}>
              <Segment className={styles.wrapper}>
                <SearchProducts
                  customerId="-"
                  customerCode=""
                  searchResults={{ isSubmitting: false, products: [] }}
                  onSearchProducts={() => {}}
                  onSellitemCreate={() => {}}
                />
                <ProductTable
                  canEdit={canEdit}
                  products={products}
                  page="products"
                  showRightSidebar={false}
                  onCreateProduct={onProductCreate}
                  onUpdateSellitem={onUpdateSellitem}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  },
);

Products.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object()).isRequired,
  canEdit: PropTypes.bool.isRequired,
  toggleRightSidebar: PropTypes.bool.isRequired,
  onProjectAgentCreate: PropTypes.func.isRequired,
  onSetRightSidebar: PropTypes.func.isRequired,
  onPaymentCreate: PropTypes.func.isRequired,
  onProductCreate: PropTypes.func.isRequired,
  onUpdateProduct: PropTypes.func.isRequired,
  onUpdateSellitem: PropTypes.func.isRequired,
};

export default Products;
