import EntryActionTypes from '../../constants/EntryActionTypes';

export const createProduct = (blockId, data) => ({
  type: EntryActionTypes.PRODUCT_CREATE,
  payload: {
    blockId,
    data,
  },
});

export const handleProductCreate = (product) => ({
  type: EntryActionTypes.PRODUCT_CREATE_HANDLE,
  payload: {
    product,
  },
});

export const updateProduct = (id, data) => ({
  type: EntryActionTypes.PRODUCT_UPDATE,
  payload: {
    id,
    data,
  },
});

export const updateCurrentProduct = (data) => ({
  type: EntryActionTypes.CURRENT_PRODUCT_UPDATE,
  payload: {
    data,
  },
});

export const handleProductUpdate = (product) => ({
  type: EntryActionTypes.PRODUCT_UPDATE_HANDLE,
  payload: {
    product,
  },
});

export const moveProduct = (id, blockId, index = 0) => ({
  type: EntryActionTypes.PRODUCT_MOVE,
  payload: {
    id,
    blockId,
    index,
  },
});

export const moveCurrentProduct = (blockId, index = 0) => ({
  type: EntryActionTypes.CURRENT_PRODUCT_MOVE,
  payload: {
    blockId,
    index,
  },
});

export const deleteProduct = (id) => ({
  type: EntryActionTypes.PRODUCT_DELETE,
  payload: {
    id,
  },
});

export const deleteCurrentProduct = () => ({
  type: EntryActionTypes.CURRENT_PRODUCT_DELETE,
  payload: {},
});

export const handleProductDelete = (product) => ({
  type: EntryActionTypes.PRODUCT_DELETE_HANDLE,
  payload: {
    product,
  },
});

export const handleProductImport = (products, bagitems) => ({
  type: EntryActionTypes.PRODUCTS_IMPORT_HANDLE,
  payload: {
    products,
    bagitems,
  },
});

// export const transferProduct = (id, groupId, blockId, index = 0) => ({
//   type: EntryActionTypes.PRODUCT_TRANSFER,
//   payload: {
//     id,
//     groupId,
//     blockId,
//     index,
//   },
// });

// export const transferCurrentProduct = (groupId, blockId, index = 0) => ({
//   type: EntryActionTypes.CURRENT_PRODUCT_TRANSFER,
//   payload: {
//     groupId,
//     blockId,
//     index,
//   },
// });
