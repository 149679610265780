export const ProjectBackgroundTypes = {
  GRADIENT: 'gradient',
  IMAGE: 'image',
};

export const GroupTypes = {
  KANBAN: 'kanban',
  COLLECTION: 'collection',
};

export const ActionTypes = {
  CREATE_SELLITEM: 'createSellitem',
  MOVE_SELLITEM: 'moveSellitem',
  COMMENT_SELLITEM: 'commentSellitem',
  TRANSFER_SELLITEM: 'transferSellitem',
  UPDATE_SELLITEM: 'updateSellitem',
};

export const ProductStatuses = {
  // BUILDING: { name: 'building', color: 'faux-malachite', hex: '#22c55e', label: 'Phiếu' },
  // COMMING: { name: 'comming', color: 'faux-malachite', hex: '#22c55e', label: 'Sắp bán' },
  SELLING: { name: 'selling', color: 'faux-malachite', hex: '#22c55e', label: 'Mở bán' },
  LOCKED: { name: 'locked', color: 'golden-poppy', hex: '#e5be00', label: 'Booking' },
  DEPOSIT: { name: 'deposit', color: 'federal-standard', hex: '#e37300', label: 'Đã cọc' },
  SELLED: { name: 'selled', color: 'harmonies', hex: '#d10044', label: 'Đã bán' },
};

export const ProductStatistics = {
  TOTAL: { name: 'total', title: 'total', hex: '#f012be' },
  AVAILABLE: { name: 'available', title: 'available', hex: '#22c55e' },
  BOOKING: { name: 'booking', title: 'booking', hex: '#e5be00' },
  DEPOSIT: { name: 'deposit', title: 'deposit', hex: '#e37300' },
  SELLED: { name: 'selled', title: 'selled', hex: '#d10044' },
};

export const LegalStatuses = {
  NOT_CERTIFICATE: { name: 'notCertificate', color: 'pumpkin-orange', hex: '#f0982d' },
  HAS_CERTIFICATE: { name: 'hasCertificate', color: 'lagoon-blue', hex: '#109dc0' },
  GOT_CERTIFICATE: { name: 'gotCertificate', color: 'light-mud', hex: '#c7a57b' },
  OTHER: { name: 'other', color: 'tank-green', hex: '#8aa177' },
};

export const Statuses = {
  BOOKING: { name: 'booking', color: 'pumpkin-orange', hex: '#f0982d', label: 'Booking' },
  REVIEW: { name: 'review', color: 'egg-yellow', hex: '#f7d036', label: 'Trình duyệt' },
  APPROVED: {
    name: 'approved',
    color: 'tank-green',
    hex: '#8aa177',
    reviewCancel: true,
    label: 'Đã duyệt',
  },
  PROCESS1: {
    name: 'process1',
    color: 'sunny-grass',
    hex: '#bfca02',
    reviewCancel: true,
    label: 'Đã cọc',
  },
  PROCESS2: {
    name: 'process2',
    color: 'coral-green',
    hex: '#2b6a6c',
    reviewCancel: true,
    label: 'TTGD1',
  },
  PROCESS3: {
    name: 'process3',
    color: 'gun-metal',
    hex: '#355263',
    reviewCancel: true,
    label: 'TTGD2',
  },
  PROCESS4: {
    name: 'process4',
    color: 'lagune-blue',
    hex: '#00b4b1',
    reviewCancel: true,
    label: 'TTGD3',
  },
  PROCESS5: {
    name: 'process5',
    color: 'light-concrete',
    hex: '#afb0a4',
    reviewCancel: true,
    label: 'TTGD4',
  },
  PROCESS6: {
    name: 'process6',
    color: 'light-cocoa',
    hex: '#87564a',
    reviewCancel: true,
    label: 'TTGD5',
  },
  PROCESS7: {
    name: 'process7',
    color: 'light-mud',
    hex: '#c7a57b',
    reviewCancel: true,
    label: 'TTGD6',
  },
  PROCESS8: {
    name: 'process8',
    color: 'dark-granite',
    hex: '#8b8680',
    reviewCancel: true,
    label: 'TTGD7',
  },
  PROCESS9: {
    name: 'process9',
    color: 'light-orange',
    hex: '#ffc66d',
    reviewCancel: true,
    label: 'TTGD8',
  },
  PROCESS10: {
    name: 'process10',
    color: 'egg-yellow',
    hex: '#a28744',
    reviewCancel: true,
    label: 'TTGD9',
  },
  REDO: { name: 'redo', color: 'pink-tulip', hex: '#f97394', label: 'Làm lại' },
  PASSED: { name: 'passed', color: 'antique-blue', hex: '#6c99bb', label: 'Đã duyệt' },
  FINISH: { name: 'finish', color: 'wet-moss', hex: '#4a8753', label: 'Hoàn tất' },
  PAYMENT: { name: 'payment', color: 'lagoon-blue', hex: '#109dc0', label: 'Đang thanh toán' },
  DECIDED: { name: 'decided', color: 'lagoon-blue', hex: '#109dc0', label: 'Đã từ chối' },
  REVIEWCANCEL: { name: 'reviewCancel', color: 'egg-yellow', hex: '#f7d036', label: 'Trình hủy' },
  CANCELED: { name: 'canceled', color: 'red-burgundy', hex: '#ad5f7d', label: 'Đã hủy' },
};

export const CancelStatusNames = [Statuses.CANCELED.name];

export const PaymentTypes = {
  DEPOSIT: 'deposit',
  PAYMENT: 'payment',
};

export const BagStatuses = {
  NEW: { name: 'new', color: 'light-concrete', hex: '#afb0a4' },
  // REVIEW: { name: 'review', color: 'egg-yellow', hex: '#f7d036' },
  // APPROVED: { name: 'approved', color: 'sunny-grass', hex: '#bfca02' },
  PUBLISH: { name: 'publish', color: 'wet-moss', hex: '#4a8753' },
  EXPIRE: { name: 'expire', color: 'pink-tulip', hex: '#f97394' },
};

export const PaymentStatuses = {
  NEW: { name: 'new', color: 'dark-granite', hex: '#8b8680', label: 'Chưa thu' },
  PENDING: { name: 'pending', color: 'orange-lighten', hex: '#f39c12', label: 'Treo' },
  EXPIRE: { name: 'expire', color: 'red-burgundy', hex: '#ad5f7d', label: 'Hết hạn' },
  FINISH: { name: 'finish', color: 'coral-green', hex: '#2b6a6c', label: 'Đã thanh toán' },
  CANCEL: { name: 'cancel', color: 'light-concrete', hex: '#afb0a4', label: 'Hủy' },
};

export const PaymentMethods = {
  PROCESS: 'process',
  QUICK: 'quick',
  BANK: 'bank',
};

export const DiscountTypes = {
  PERCENT: 'percent',
  FIXED: 'fixed',
};

export const Role = {
  DIRECTOR: 'director',
  MANAGER: 'manager',
  ACCOUNTANT: 'accountant',
  SALEADMIN1: 'saleAdmin1',
  SALEADMIN2: 'saleAdmin2',
};
