import socket from './socket';

/* Actions */

const createSellitemDiscount = (sellitemId, data, headers) =>
  socket.post(`/sellitems/${sellitemId}/discounts`, data, headers);

const deleteSellitemDiscount = (sellitemId, discountId, headers) =>
  socket.delete(`/sellitems/${sellitemId}/discounts/${discountId}`, undefined, headers);

export default {
  createSellitemDiscount,
  deleteSellitemDiscount,
};
