import React from 'react';
import PropTypes from 'prop-types';

import styles from './ConvertTextVi.module.scss';

const ConvertStringVi = React.memo(({ numberConvert }) => {
  const arrayNumber = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];
  const readDozens = (number, daydu) => {
    let string = '';
    const dozens = Math.floor(number / 10);
    const units = number % 10;
    if (dozens > 1) {
      string = ` ${arrayNumber[dozens]} mươi`;
      if (units === 1) {
        string += ' mốt';
      }
    } else if (dozens === 1) {
      string = ' mười';
      if (units === 1) {
        string += ' một';
      }
    } else if (daydu && units > 0) {
      string = ' lẻ';
    }
    if (units === 5 && dozens >= 1) {
      string += ' lăm';
    } else if (units > 1 || (units === 1 && dozens === 0)) {
      string += ` ${arrayNumber[units]}`;
    }
    return string;
  };

  const readBlock = (number, full) => {
    let string = '';
    const hundred = Math.floor(number / 100);
    const dozens = number % 100;
    if (full || hundred > 0) {
      string = ` ${arrayNumber[hundred]} trăm`;
      string += readDozens(dozens, true);
    } else {
      string = readDozens(dozens, false);
    }
    return string;
  };

  const readMillion = (number, full) => {
    let string = '';
    let newNumber = number;
    let newFull = full;
    const million = Math.floor(newNumber / 1000000);
    newNumber %= 1000000;
    if (million > 0) {
      string = `${readBlock(million, newFull)} triệu`;
      newFull = true;
    }
    const thousand = Math.floor(newNumber / 1000);
    newNumber %= 1000;
    if (thousand > 0) {
      string += `${readBlock(thousand, newFull)} nghìn`;
      newFull = true;
    }
    if (newNumber > 0) {
      string += readBlock(newNumber, newFull);
    }
    return string;
  };
  const renderNumber = (number) => {
    let newNumber = number;
    if (newNumber === 0) return arrayNumber[0];
    let string = '';
    let suffixes = '';
    do {
      const billion = newNumber % 1000000000;
      newNumber = Math.floor(newNumber / 1000000000);
      if (newNumber > 0) {
        string = readMillion(billion, true) + suffixes + string;
      } else {
        string = readMillion(billion, false) + suffixes + string;
      }
      suffixes = ' tỷ';
    } while (newNumber > 0);
    string += ' đồng';
    return string;
  };

  return <span className={styles.textPrice}>{renderNumber(numberConvert)}</span>;
});

ConvertStringVi.propTypes = {
  numberConvert: PropTypes.number.isRequired,
};

export default ConvertStringVi;
