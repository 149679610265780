import EntryActionTypes from '../../constants/EntryActionTypes';

export const createCustomer = (data) => ({
  type: EntryActionTypes.CUSTOMER_CREATE,
  payload: {
    data,
  },
});

export const handleCustomerCreate = (customer) => ({
  type: EntryActionTypes.CUSTOMER_CREATE_HANDLE,
  payload: {
    customer,
  },
});

export const updateCustomer = (id, data) => ({
  type: EntryActionTypes.CUSTOMER_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleCustomerUpdate = (customer) => ({
  type: EntryActionTypes.CUSTOMER_UPDATE_HANDLE,
  payload: {
    customer,
  },
});

export const moveCustomer = (id, index) => ({
  type: EntryActionTypes.CUSTOMER_MOVE,
  payload: {
    id,
    index,
  },
});

export const deleteCustomer = (id) => ({
  type: EntryActionTypes.CUSTOMER_DELETE,
  payload: {
    id,
  },
});

export const handleCustomerDelete = (customer) => ({
  type: EntryActionTypes.CUSTOMER_DELETE_HANDLE,
  payload: {
    customer,
  },
});
