import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  currentGroupSelector,
  accountsForCurrentProjectSelector,
  pathSelector,
  bagsForCurrentGroupSelector,
} from '../selectors';
import { fetchDashboard } from '../actions/entry';
import Dashboard from '../components/Dashboard';

const mapStateToProps = (state) => {
  const { dashboard, isFetching } = state.dashboard;
  const { projectId } = pathSelector(state);
  const currentGroup = currentGroupSelector(state);
  const accounts = accountsForCurrentProjectSelector(state);
  let bags = bagsForCurrentGroupSelector(state);

  bags = bags.map((bag) => {
    return {
      key: bag.id,
      value: bag.id,
      text: bag.name,
    };
  });

  return {
    dashboard,
    isFetching,
    accounts,
    bags,
    projectId,
    currentGroup,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onfetchDashboard: (bagId, startDate, endDate) => fetchDashboard(bagId, startDate, endDate),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
