const printTable = (products, colHeaders, currentBag) => {
  const iframe = document.createElement('iframe');
  iframe.style.cssText = 'display: none';

  document.body.appendChild(iframe);
  const doc = iframe.contentDocument;

  doc.open('text/html', 'replace');

  // parse table
  const thead = `<thead><tr><th>${colHeaders.join(`</th><th>`)}</th></tr></thead>`;

  const data = products.map(
    (
      {
        name,
        description,
        landNumber,
        houseForm,
        certificate,
        street,
        direction,
        density,
        landArea,
        floorArea,
        landPrice,
        floorPrice,
        landTotal,
        floorTotal,
        factor,
        finalTotal,
        agentName,
      },
      index,
    ) => ({
      id: index + 1,
      name,
      landNumber,
      houseForm,
      certificate,
      street,
      direction,
      density,
      landArea,
      floorArea,
      landPrice,
      floorPrice,
      landTotal,
      floorTotal,
      factor,
      finalTotal,
      description,
      agentName,
    }),
  );

  const body = data.map((row) => {
    return Object.values(row)
      .map((cell, index) => {
        switch (index) {
          case 0:
            return cell;
          case 7:
            return cell.toLocaleString('en-GB', {
              style: 'percent',
              // minimumFractionDigits: 2,
            });
          case 10:
          case 11:
          case 12:
          case 13:
          case 15:
            return parseInt(cell, 10).toLocaleString('en-GB');

          case 8:
          case 9:
          case 14:
            return cell.toFixed(2);
          default:
            break;
        }
        // eslint-disable-next-line consistent-return
        return cell;
      })
      .map((cell) => cell)
      .join(`</td><td>`);
  });
  // table = `<td>${table}</td>`;
  const tbody = `<tbody><tr><td>${body.join(`</td></tr><tr><td>`)}</td></tr></tbody>`;

  // console.log(tbody);

  doc.write(`<!doctype html><html><head>
  <style>
    @page {
      size: A4 landscape;
    }
    @media print {
      body {
        font-family: Roboto, Arial, Tahoma;
      }
      table {
        border-collapse: collapse;
      }
      th, td {
        border: 1px solid #ccc;
        min-width: 40px;
        padding: 2px 4px;
        white-space: nowrap;
      }
      th {
        background-color: #f0f0f0;
        text-align: center;
        font-weight: 400;
        white-space: nowrap;
        -webkit-print-color-adjust: exact;
      }
      th:first-child, td:first-child {
        min-width: auto;
      }
      h1, h2 {
        text-align: center;
      }
      h2 {
        font-size: 1.7em;
      }
      div.signature {
        display: flex;
        margin-top: 20mm;
        text-align: center;
        font-weight: bold;
      }
      div.signature > div {
        display: inline-block;
        width: 50%;
      }
    }
  </style>
  <title>${currentBag.name}</title>
  </head><body>
  <h1>CÔNG TY CỔ PHẦN ĐẦU TƯ - PHÁT TRIỂN ĐÔ THỊ ÂN PHÚ</h1>
  <h2 style="margin-top: -15px">28 - Phan Bội Châu - Phường Hòa Thuận - Tam Kỳ - Quảng Nam</h2>
  <h2>${currentBag.description?.toUpperCase() || currentBag.name.toUpperCase()} (ÁP DỤNG TỪ NGÀY 
    ${currentBag.startDate ? currentBag.startDate.toLocaleString('vi-VN').substring(10) : ''}
    ${
      currentBag.endDate ? ` ĐẾN ${currentBag.endDate.toLocaleString('vi-VN').substring(10)}` : ''
    })</h2>
  <table>${thead}${tbody}</table>
  <div class="signature"><div>Tr.P Kinh Doanh<br><br><br><br><br><br>Lê Vĩnh Thái</div><div>Giám đốc<br><br><br><br><br><br>Nguyễn Quốc Trường</div></div>
  </body></html>`);
  doc.close();

  // console.log(hot.getData());
  doc.defaultView.print();

  setTimeout(() => {
    // this code will be executed 10ms after you close printing window
    iframe.parentElement.removeChild(iframe);
  }, 10);
};

export default printTable;
