/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Icon, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Select, { createFilter } from 'react-select';

import ProductTable from '../ProductTable';
import RightSidebar from './RightSidebar';
import BagsLeftSidebarContainer from '../../containers/BagsLeftSidebarContainer';
import StatusesPopup from '../StatusesPopup';
import printTable from '../ProductTable/print';

import styles from './Bags.module.scss';
import { ProductStatuses, BagStatuses } from '../../constants/Enums';

const Bags = React.memo(
  ({
    products,
    agents,
    blocks,
    categories,
    project,
    allProducts,
    currentBag,
    canEdit,
    toggleRightSidebar,
    onCreateProduct,
    onCreateBagitem,
    onDeleteBagitem,
    onSetRightSidebar,
    onUpdateProduct,
    onUpdateBag,
    onUpdateBagitem,
    onCreateDiscount,
    onUpdateDiscount,
    onDeleteDiscount,
  }) => {
    const [t] = useTranslation();

    const options = useMemo(
      () =>
        allProducts.map(({ id, name, landArea, floorArea }) => ({
          value: id,
          label: `${name} - ${landArea}m²`,
        })),
      [allProducts],
    );

    const handleCreateBagItem = useCallback(
      ({ value }) => {
        const product = allProducts.find(({ id }) => id === value);
        if (product) {
          const data = {
            bagId: currentBag?.id,
            productId: product.id,
            agentId: product.agentId,
            landPrice: product.landPrice,
            floorPrice: product.floorPrice,
            factor: product.factor,
          };
          onCreateBagitem(data);
        }

        // if (bagitems.findIndex((sh) => sh.customerId === customer.id) !== -1) {
        //   alert(t('common.customerExitsInBag'));
        //   error = true;
        // }
        // if (error) return;
        // onCreateBagitem(data);
      },
      [allProducts, currentBag.id, onCreateBagitem],
    );

    const summary = useMemo(
      () => ({
        selled: products.filter(({ status: s }) => s === ProductStatuses.SELLED.name).length,
        deposit: products.filter(({ status: s }) => s === ProductStatuses.DEPOSIT.name).length,
        locked: products.filter(({ status: s }) => s === ProductStatuses.LOCKED.name).length,
      }),
      [products],
    );

    const handUpdateStatus = useCallback(
      (newStatus) => {
        onUpdateBag(currentBag.id, {
          status: newStatus.status,
        });
      },
      [currentBag, onUpdateBag],
    );

    // Print header
    const colHeaders = useMemo(
      () => [
        'STT',
        t('common.code'),
        t('common.landNumber', { context: 'header' }),
        t('common.houseForm', { context: 'header' }),
        t('common.certificate', { context: 'header' }),
        t('common.street'),
        t('common.direction'),
        t('common.density', { context: 'header' }),
        t('common.landArea', { context: 'header' }),
        t('common.floorArea', { context: 'header' }),
        t('common.landPrice', { context: 'header' }),
        t('common.floorPrice', { context: 'header' }),
        t('common.landTotal', { context: 'header' }),
        t('common.floorTotal', { context: 'header' }),
        t('common.factor'),
        t('common.total'),
        t('common.properties'),
        t('common.agent'),
      ],
      [t],
    );

    const handlePrint = useCallback(() => {
      printTable(products, colHeaders, currentBag, agents);
    }, [agents, colHeaders, currentBag, products]);

    return (
      <div className={styles.wrapperGrid}>
        <Grid stackable columns={3}>
          <Grid.Row stretched>
            <Grid.Column width={3}>
              <Segment className={styles.sidebarLeft}>
                <BagsLeftSidebarContainer />
              </Segment>
            </Grid.Column>
            <Grid.Column width={13 * !currentBag + 10 * !!currentBag}>
              <Segment className={styles.wrapper}>
                {canEdit && (
                  <div className={styles.wrapperTop}>
                    {currentBag ? (
                      <Select
                        // closeMenuOnSelect
                        blurInputOnSelect
                        isDisabled={!canEdit}
                        className={styles.selectProduct}
                        filterOption={createFilter({
                          ignoreCase: true,
                          ignoreAccents: true,
                          matchFrom: 'any',
                        })}
                        isClearable
                        noOptionsMessage={() => t('common.noResultsMessage')}
                        placeholder={t('common.searchProduct')}
                        // maxMenuHeight={this.prop === 'status' ? 280 : 218}
                        onChange={handleCreateBagItem}
                        options={options}
                        // styles={this.prop === 'status' && colourStyles}
                        // value={source.find(({ value }) => value === this.originalValue)}
                      />
                    ) : (
                      <div className={styles.productInfo}>
                        {t('common.products')}: {products.length}, {t('status.selling')}:{' '}
                        {products.length - summary.selled - summary.deposit - summary.locked},{' '}
                        {t('status.locked')}: {summary.locked}, {t('status.deposit')}:{' '}
                        {summary.deposit}, {t('status.selled')}: {summary.selled}
                      </div>
                    )}
                    <Button
                      className={styles.addProductButton}
                      size="tiny"
                      icon="cube"
                      color="blue"
                      content={t('action.add')}
                      onClick={onCreateProduct}
                    />
                    <Button icon size="tiny" className={styles.print} onClick={handlePrint}>
                      <Icon name="print" /> {t('action.print')}
                    </Button>
                    {currentBag && (
                      <StatusesPopup
                        items={Object.values(BagStatuses)}
                        canEdit={canEdit}
                        currentStatuses={[currentBag.status]}
                        onSelect={handUpdateStatus}
                      >
                        <Button
                          size="tiny"
                          className={styles.review}
                          style={{ background: BagStatuses[currentBag.status.toUpperCase()]?.hex }}
                        >
                          {t(`status.${currentBag.status}`)}
                        </Button>
                      </StatusesPopup>
                    )}
                  </div>
                )}
                <div>
                  <ProductTable
                    canEdit={canEdit}
                    products={products}
                    agents={agents}
                    blocks={blocks}
                    categories={categories}
                    showRightSidebar={false}
                    currentBag={currentBag}
                    onDeleteBagitem={onDeleteBagitem}
                    onUpdateProduct={onUpdateProduct}
                    onUpdateBagitem={onUpdateBagitem}
                  />
                </div>
              </Segment>
            </Grid.Column>
            {currentBag && (
              <Grid.Column width={3}>
                <RightSidebar
                  currentBag={currentBag}
                  products={products}
                  agents={agents}
                  categories={categories}
                  project={project}
                  canEdit={canEdit}
                  onCreateDiscount={onCreateDiscount}
                  onUpdateDiscount={onUpdateDiscount}
                  onDeleteDiscount={onDeleteDiscount}
                  onUpdate={onUpdateBag}
                  isPersisted
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </div>
    );
  },
);

Bags.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object()).isRequired,
  agents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  blocks: PropTypes.arrayOf(PropTypes.object()).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  project: PropTypes.objectOf(PropTypes.shape).isRequired,
  allProducts: PropTypes.arrayOf(PropTypes.object()).isRequired,
  currentBag: PropTypes.objectOf(PropTypes.shape).isRequired,
  canEdit: PropTypes.bool.isRequired,
  toggleRightSidebar: PropTypes.bool.isRequired,
  onCreateBagitem: PropTypes.func.isRequired,
  onCreateProduct: PropTypes.func.isRequired,
  onDeleteBagitem: PropTypes.func.isRequired,
  onSetRightSidebar: PropTypes.func.isRequired,
  onUpdateProduct: PropTypes.func.isRequired,
  onUpdateBag: PropTypes.func.isRequired,
  onUpdateBagitem: PropTypes.func.isRequired,
  onCreateDiscount: PropTypes.func.isRequired,
  onUpdateDiscount: PropTypes.func.isRequired,
  onDeleteDiscount: PropTypes.func.isRequired,
};

export default Bags;
