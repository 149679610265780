import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon, Menu, Segment, Button } from 'semantic-ui-react';

import Paths from '../../constants/Paths';
import NotificationsPopup from './NotificationsPopup';
// import Search from '../Search';
import UserPopup from '../UserPopup';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import ProductWidgetsContainer from '../../containers/ProductWidgetsContainer';

import styles from './Header.module.scss';
import User from '../User';
import { Role } from '../../constants/Enums';

const Header = React.memo(
  ({
    project,
    user,
    notifications,
    path,
    canEditProject,
    canEditUsers,
    onProjectSettingsClick,
    onUsersClick,
    onNotificationDelete,
    onUserSettingsClick,
    onLogout,
  }) => {
    const [t] = useTranslation();

    const [openMenuMobile, setOpenMenuMobile] = useState(false);
    const menuItems = [
      {
        to: project && Paths.SELLITEMS.replace(':id', project.firstGroupId),
        title: t('common.sellitems'),
        isActive: path === Paths.SELLITEMS,
        hidden: false,
      },
      {
        to: project && Paths.GROUPS.replace(':id', project.firstGroupId),
        title: t('common.kanban'),
        isActive: path === Paths.GROUPS,
        hidden: false,
      },
      {
        to: project && Paths.BAGS.replace(':id', project.firstBagId),
        title: t('common.bags'),
        isActive: path === Paths.BAGS || path === Paths.BAGS,
        hidden: false,
      },
      {
        to:
          project &&
          Paths.CUSTOMERS.replace(':groupId', project.firstGroupId).replace(
            ':id',
            project.firstCustomerId,
          ),
        title: t('common.customers'),
        isActive: path === Paths.CUSTOMERS,
        hidden: false,
      },
      {
        to: project && Paths.DASHBOARD.replace(':id', project.firstGroupId),
        title: t('common.reports'),
        isActive: path === Paths.DASHBOARD,
        hidden: !user.isAdmin && (user.role !== Role.DIRECTOR || user.role !== Role.DIRECTOR),
      },
      {
        to: project && Paths.ACCOUNTANT.replace(':id', project.firstGroupId),
        title: t('common.accountand'),
        isActive: path === Paths.ACCOUNTANT,
        hidden: false,
      },
      {
        to: project && Paths.DEBTS.replace(':id', project.firstGroupId),
        title: t('common.debts'),
        isActive: path === Paths.DEBTS,
        hidden: false,
      },
    ];

    const handleProjectSettingsClick = useCallback(() => {
      if (canEditProject) {
        onProjectSettingsClick();
      }
    }, [canEditProject, onProjectSettingsClick]);

    const handleClickMenu = useCallback(() => {
      setOpenMenuMobile(!openMenuMobile);
    }, [openMenuMobile]);

    return (
      <div className={styles.wrapper}>
        {!project && (
          <Link to={Paths.ROOT} className={classNames(styles.logo, styles.title)}>
            TiciLand
          </Link>
        )}
        <Menu size="large" className={styles.menu}>
          {project && (
            <>
              <Menu.Menu position="left">
                <Menu.Item
                  as={Link}
                  to={Paths.ROOT}
                  className={classNames(styles.item, styles.itemHoverable)}
                >
                  <Logo className={styles.logoSvg} />
                </Menu.Item>
              </Menu.Menu>
              <div style={{ padding: 0 }}>
                <Segment
                  basic
                  vertical
                  className={classNames(styles.headerLine, styles.headerLine1)}
                >
                  <Menu.Menu position="left" className={styles.headerLineMenu}>
                    <Menu.Item
                      className={classNames(
                        styles.item,
                        canEditProject && styles.itemHoverable,
                        styles.title,
                      )}
                      onClick={handleProjectSettingsClick}
                    >
                      {project.name}
                    </Menu.Item>
                    {/* <Menu.Item
                      className={classNames(styles.item, styles.searchItem, 'hidden-tablet')}
                    >
                      <Search title={t('common.search')} />
                    </Menu.Item> */}
                  </Menu.Menu>
                </Segment>
                <Segment
                  basic
                  vertical
                  className={classNames(
                    styles.headerLine,
                    styles.headerLine2,
                    openMenuMobile && styles.menuActive,
                  )}
                >
                  <Menu.Menu position="left" className={styles.menuLeft}>
                    {menuItems
                      .filter(({ hidden }) => !hidden)
                      .map((menu) => (
                        <Menu.Item
                          onClick={handleClickMenu}
                          key={menu.to}
                          as={Link}
                          to={menu.to}
                          className={classNames(
                            styles.item,
                            styles.subItemHoverable,
                            styles.subItem,
                            menu.isActive && styles.active,
                          )}
                        >
                          {menu.title}
                        </Menu.Item>
                      ))}
                    {/* <Menu.Item
                      className={classNames(
                        styles.item,
                        styles.searchMobile,
                        'hidden-desktop show-tablet',
                      )}
                    >
                      <Search title={t('common.search')} />
                    </Menu.Item> */}
                  </Menu.Menu>
                </Segment>
                {openMenuMobile && <div className={styles.bgOverlay} />}
              </div>
              <Menu.Item className={classNames(styles.item, styles.productWidgets)}>
                <ProductWidgetsContainer />
              </Menu.Item>
            </>
          )}

          <Menu.Menu position="right" className={styles.rightMenu}>
            {canEditUsers && (
              <Menu.Item
                className={classNames(styles.item, styles.itemHoverable, 'hidden-tablet')}
                onClick={onUsersClick}
              >
                <Icon fitted name="users" />
              </Menu.Item>
            )}
            <NotificationsPopup items={notifications} onDelete={onNotificationDelete}>
              <Menu.Item className={classNames(styles.item, styles.itemHoverable)}>
                <Icon fitted name="bell" />
                {notifications.length > 0 && (
                  <span className={styles.notification}>{notifications.length}</span>
                )}
              </Menu.Item>
            </NotificationsPopup>
            <UserPopup onSettingsClick={onUserSettingsClick} onLogout={onLogout}>
              <Menu.Item className={classNames(styles.item, styles.itemHoverable)}>
                <User name={user.name} avatarUrl={user.avatarUrl} size="large" />
              </Menu.Item>
            </UserPopup>
            <Button
              primary
              className={styles.buttonOpenMobileMenu}
              icon="bars"
              onClick={handleClickMenu}
            />
          </Menu.Menu>
        </Menu>
      </div>
    );
  },
);

Header.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  project: PropTypes.object,
  user: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  path: PropTypes.string.isRequired,
  canEditProject: PropTypes.bool.isRequired,
  canEditUsers: PropTypes.bool.isRequired,
  onProjectSettingsClick: PropTypes.func.isRequired,
  onUsersClick: PropTypes.func.isRequired,
  onNotificationDelete: PropTypes.func.isRequired,
  onUserSettingsClick: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

Header.defaultProps = {
  project: undefined,
};

export default Header;
