import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Dropdown } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { usePrevious } from '../../../lib/hooks';
import ProductModal from '../../ProductModal';

import { useForm } from '../../../hooks';
import { DiscountTypes, ProductStatuses } from '../../../constants/Enums';
import ConvertNumberToText from '../ConvertNumberToText';

import styles from './AddForm.module.scss';

const AddForm = React.memo(
  ({
    currentProject,
    isAdmin,
    defaultData,
    products,
    bags,
    agents,
    isSubmitting,
    error,
    onUpdateProduct,
    onCreateSellitem,
  }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [data, handleFieldChange, setData] = useForm({
      ...defaultData,
      productId: 0,
    });

    const [ownAgent, setOwnAgent] = useState();
    const [openModal, setOpenModal] = useState();

    const [customerError, setCustomerError] = useState(false);

    useEffect(() => {
      if (wasSubmitting && !isSubmitting) {
        if (!error) {
          setData({
            ...defaultData,
            customerId: 0,
            productId: 0,
          });
        }
      }
    }, [wasSubmitting, error, isSubmitting, defaultData, setData]);

    const productOptions = useMemo(
      () =>
        products
          .filter(({ status }) => status === ProductStatuses.SELLING.name)
          .map(({ id, name }) => ({ key: id, value: id, text: `${name}` })),
      [products],
    );

    const agentOptions = useMemo(
      () => agents.map(({ id, name }) => ({ key: id, value: id, text: name })),
      [agents],
    );

    useEffect(() => {
      const newProduct = products.find((pr) => pr.id === data.productId);
      setOwnAgent(newProduct?.agent?.name);
      if (newProduct) {
        const newData = {
          discount1: 0,
          discount2: 0,
          discount3: 0,
          discount4: 0,
          discount5: 0,
          productId: data.productId,
          agentId: newProduct?.agent?.id,
          name: newProduct.name,
        };
        setData(newData);
      }
    }, [bags, currentProject, data.productId, defaultData, products, setData]);

    const product = products.find((pr) => pr.id === data.productId);

    const handleLockProduct = useCallback(() => {
      if (data.productId) {
        onUpdateProduct({ id: data.productId, status: ProductStatuses.LOCKED.name });
        const newData = {
          ...data,
          bagitemId: product?.bagitemId || undefined,
          bookDate: new Date(),
          name: data.name.trim() || undefined,
        };
        onCreateSellitem(newData);
      }
    }, [data, onCreateSellitem, onUpdateProduct, product?.bagitemId]);

    const handleOpenProductModal = useCallback(() => {
      setOpenModal(true);
    }, []);

    return (
      <>
        <div className={styles.formAddTop}>
          <div className={styles.pageTitle}>
            {t('common.createSellitem', {
              context: 'title',
            })}
          </div>
        </div>
        <Form className={styles.formAddSellitem}>
          <Form.Group className={`${styles.fieldGroup} ${styles.groupLeft}`}>
            <Form.Field className={styles.fields}>
              <label htmlFor="productId">{t('common.productName')}</label>
              <div className={styles.fieldValueProduct}>
                <Dropdown
                  name="productId"
                  placeholder={t('common.enterNameProductToSearch')}
                  fluid
                  search
                  selection
                  disabled={!isAdmin}
                  value={data.productId}
                  options={productOptions}
                  noResultsMessage={t('common.noResultsMessage')}
                  onChange={handleFieldChange}
                />
                <Button
                  className={styles.buttonLock}
                  content={t('action.lock')}
                  onClick={handleLockProduct}
                />
                <Button
                  className={styles.buttonOpenDetail}
                  icon="ellipsis horizontal"
                  onClick={handleOpenProductModal}
                />
                {product && (
                  <ProductModal
                    product={product}
                    openModal={openModal}
                    onClose={() => setOpenModal(false)}
                  />
                )}
              </div>
            </Form.Field>
            <Form.Field
              className={styles.fieldsCustomer}
              required
              error={customerError}
              onFocus={() => setCustomerError(false)}
            >
              <div className={styles.customerWrapper}>
                <div className={styles.labelWrapper}>
                  <label htmlFor="customer">{t('common.customer')}</label>
                </div>
                <div className={styles.customerInputWrapper}>
                  <Input
                    fluid
                    name="customerId"
                    type="text"
                    defaultValue={data.customerId || ''}
                    readOnly={isSubmitting}
                    className={styles.fieldNumber}
                    disabled
                  />
                  <Button
                    size="mini"
                    className={styles.buttonAddCustomer}
                    type="button"
                    icon="plus"
                  />
                </div>
              </div>
            </Form.Field>
            <Form.Field className={styles.fields}>
              <label htmlFor="bonus">{t('common.bonus')}</label>
              <NumberFormat
                className={styles.fieldNumber}
                disabled
                value={data.bonus || 0}
                thousandSeparator
              />
            </Form.Field>
            <Form.Field className={styles.fields}>
              <label htmlFor="agentId">{t('common.sellAgent')}</label>
              <Dropdown
                className={styles.field}
                name="agentId"
                placeholder={t('common.sellAgent')}
                fluid
                search
                selection
                value={data.agentId}
                options={agentOptions}
                noResultsMessage={t('common.noResultsMessage')}
                disabled
              />
            </Form.Field>
            <Form.Field className={styles.fields}>
              <label htmlFor="ownAgent">{t('common.ownAgent')}</label>
              <span className={styles.fieldValue}>{ownAgent || ''}</span>
            </Form.Field>
            <Form.Field className={styles.fieldsSubtotal}>
              <label htmlFor="total">{t('common.totalWithVAT')}</label>
              <span className={styles.fieldValueNumber}>
                {(product?.finalTotal || 0).toLocaleString('en-GB')}
              </span>
            </Form.Field>
            <Form.Field className={styles.fieldsSubtotal}>
              <label htmlFor="total">{t('common.totalLandPriceWithoutVAT')}</label>
              <span className={styles.fieldValueNumber}>
                {(product?.landTotalWithoutVAT || 0).toLocaleString('en-GB')}
              </span>
            </Form.Field>
          </Form.Group>
          <Form.Group className={`${styles.fieldGroup} ${styles.groupRight}`}>
            {product?.discounts?.map(({ id, name, description, type, amount }) => (
              <Form.Field key={id} className={styles.fieldsSubtotal}>
                <label htmlFor="discount">
                  {name}
                  <div className={styles.meta}>{description}</div>
                </label>
                {amount > 0 && (
                  <span className={styles.fieldValueNumber}>
                    {type === DiscountTypes.PERCENT
                      ? Math.round((amount * product?.landTotalWithoutVAT) / 100, 0).toLocaleString(
                          'en-GB',
                        )
                      : amount}
                  </span>
                )}
              </Form.Field>
            ))}
            <Form.Field className={styles.fields}>
              <label htmlFor="discount5">{t('common.discountManual')}</label>
              <NumberFormat
                className={styles.fieldNumber}
                disabled
                value={data.discount5 || 0}
                thousandSeparator
              />
            </Form.Field>
            <Form.Field className={styles.fields}>
              <label htmlFor="totalDiscount">{t('common.totalDiscount')}</label>
              <span className={styles.fieldValueNumber}>{0}</span>
            </Form.Field>
            <Form.Field className={`${styles.fields} ${styles.fieldsTotal}`}>
              <label htmlFor="totalAfterDiscount">{t('common.totalAfterDiscount')}</label>
              <span className={styles.fieldValueNumber}>
                {product ? (product.finalTotal * 1).toLocaleString('en-GB') : '0'}
              </span>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className={styles.priceToText}>
          <span>{t('common.priceToText')}: </span>
          {product && <ConvertNumberToText numberConvert={product.finalTotal} />}
        </div>
      </>
    );
  },
);

AddForm.propTypes = {
  currentProject: PropTypes.objectOf(PropTypes.shape).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  defaultData: PropTypes.objectOf(PropTypes.shape).isRequired,
  products: PropTypes.arrayOf(PropTypes.object()).isRequired,
  bags: PropTypes.arrayOf(PropTypes.object()).isRequired,
  agents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onUpdateProduct: PropTypes.func.isRequired,
  onCreateSellitem: PropTypes.func.isRequired,
};

AddForm.defaultProps = {
  error: undefined,
};

export default AddForm;
