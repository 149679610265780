import ActionTypes from '../constants/ActionTypes';

export const createBlock = (block) => ({
  type: ActionTypes.BLOCK_CREATE,
  payload: {
    block,
  },
});

createBlock.success = (localId, block) => ({
  type: ActionTypes.BLOCK_CREATE__SUCCESS,
  payload: {
    localId,
    block,
  },
});

createBlock.failure = (localId, error) => ({
  type: ActionTypes.BLOCK_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleBlockCreate = (block) => ({
  type: ActionTypes.BLOCK_CREATE_HANDLE,
  payload: {
    block,
  },
});

export const updateBlock = (id, data) => ({
  type: ActionTypes.BLOCK_UPDATE,
  payload: {
    id,
    data,
  },
});

updateBlock.success = (block) => ({
  type: ActionTypes.BLOCK_UPDATE__SUCCESS,
  payload: {
    block,
  },
});

updateBlock.failure = (id, error) => ({
  type: ActionTypes.BLOCK_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBlockUpdate = (block) => ({
  type: ActionTypes.BLOCK_UPDATE_HANDLE,
  payload: {
    block,
  },
});

export const deleteBlock = (id) => ({
  type: ActionTypes.BLOCK_DELETE,
  payload: {
    id,
  },
});

deleteBlock.success = (block) => ({
  type: ActionTypes.BLOCK_DELETE__SUCCESS,
  payload: {
    block,
  },
});

deleteBlock.failure = (id, error) => ({
  type: ActionTypes.BLOCK_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBlockDelete = (block) => ({
  type: ActionTypes.BLOCK_DELETE_HANDLE,
  payload: {
    block,
  },
});
