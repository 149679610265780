import { call, put, select } from 'redux-saga/effects';

import { goToProjectService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createAccount,
  deleteAccount,
  handleAccountCreate,
  handleAccountDelete,
  handleAccountUpdate,
  updateAccount,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createAccountService(data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createAccount({
      ...nextData,
      // projectId,
      id: localId,
    }),
  );

  let account;

  try {
    ({ item: account } = yield call(request, api.createAccount, nextData));
  } catch (error) {
    yield put(createAccount.failure(localId, error));
    return;
  }

  yield put(createAccount.success(localId, account));
}

export function* createAccountInCurrentProjectService(data) {
  const { projectId } = yield select(pathSelector);

  yield call(createAccountService, projectId, data);
}

export function* handleAccountCreateService(account) {
  yield put(handleAccountCreate(account));
}

export function* updateAccountService(id, data) {
  yield put(updateAccount(id, data));

  let account;
  try {
    ({ item: account } = yield call(request, api.updateAccount, id, data));
  } catch (error) {
    yield put(updateAccount.failure(id, error));
    return;
  }

  yield put(updateAccount.success(account));
}

export function* handleAccountUpdateService(account) {
  yield put(handleAccountUpdate(account));
}

export function* deleteAccountService(id) {
  const { accountId, projectId } = yield select(pathSelector);

  if (id === accountId) {
    yield call(goToProjectService, projectId);
  }

  yield put(deleteAccount(id));

  let account;
  try {
    ({ item: account } = yield call(request, api.deleteAccount, id));
  } catch (error) {
    yield put(deleteAccount.failure(id, error));
    return;
  }

  yield put(deleteAccount.success(account));
}

export function* handleAccountDeleteService(account) {
  const { accountId, projectId } = yield select(pathSelector);

  if (account.id === accountId) {
    yield call(goToProjectService, projectId);
  }

  yield put(handleAccountDelete(account));
}
