import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { productsForCurrentGroupSelector } from '../selectors';
import ProductWidgets from '../components/ProductWidgets';

const mapStateToProps = (state) => {
  const products = productsForCurrentGroupSelector(state);
  const itemsTotal = products.length;
  const itemsBooking = products.filter((pi) => pi.status === 'locked').length;
  const itemsSelled = products.filter((pi) => pi.status === 'selled').length;
  const itemsDeposit = products.filter((pi) => pi.status === 'deposit').length;
  const itemsAvailable = itemsTotal - itemsBooking - itemsSelled - itemsDeposit;
  const currentStatuses = [];

  return {
    itemsTotal,
    itemsBooking,
    itemsDeposit,
    itemsSelled,
    itemsAvailable,
    currentStatuses,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductWidgets);
