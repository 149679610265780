import { ORM } from 'redux-orm';

import {
  Action,
  Attachment,
  Group,
  GroupMembership,
  Sellitem,
  SellitemDiscount,
  Label,
  Block,
  Customer,
  Product,
  Bag,
  Bagitem,
  Discount,
  Notification,
  Project,
  ProjectManager,
  Agent,
  Category,
  Account,
  Payment,
  User,
  ProjectAgent,
} from './models';

const orm = new ORM({
  stateSelector: (state) => state.orm,
});

orm.register(
  User,
  Project,
  ProjectManager,
  Agent,
  Category,
  Account,
  Group,
  GroupMembership,
  Label,
  Block,
  Customer,
  Product,
  Bag,
  Bagitem,
  Discount,
  Sellitem,
  SellitemDiscount,
  Payment,
  Attachment,
  Action,
  Notification,
  ProjectAgent,
);

export default orm;
