import { call, put, select } from 'redux-saga/effects';

import { goToGroupService, goToSellitemService } from './router';
import request from '../request';
import { nextSellitemPositionSelector, pathSelector } from '../../../selectors';
import {
  createSellitem,
  deleteSellitem,
  handleSellitemCreate,
  handleSellitemDelete,
  handleSellitemUpdate,
  updateSellitem,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createSellitemService(data) {
  const { groupId } = yield select(pathSelector);

  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createSellitem({
      ...nextData,
      groupId,
      id: localId,
    }),
  );

  let sellitem;
  try {
    ({ item: sellitem } = yield call(request, api.createSellitem, groupId, nextData));
  } catch (error) {
    yield put(createSellitem.failure(localId, error));
    return;
  }

  yield put(createSellitem.success(localId, sellitem));
  yield call(goToSellitemService, sellitem.id);
}

export function* handleSellitemCreateService(sellitem) {
  yield put(handleSellitemCreate(sellitem));
}

export function* updateSellitemService(id, data) {
  yield put(updateSellitem(id, data));

  let sellitem;
  try {
    ({ item: sellitem } = yield call(request, api.updateSellitem, id, data));
  } catch (error) {
    yield put(updateSellitem.failure(id, error));
    return;
  }

  yield put(updateSellitem.success(sellitem));
}

export function* updateCurrentSellitemService(data) {
  const { sellitemId } = yield select(pathSelector);

  yield call(updateSellitemService, sellitemId, data);
}

export function* moveSellitemService(id, customerId, index) {
  const position = yield select(nextSellitemPositionSelector, customerId, index, id);

  yield call(updateSellitemService, id, {
    customerId,
    position,
  });
}

export function* moveCurrentSellitemService(customerId, index) {
  const { sellitemId } = yield select(pathSelector);

  yield call(moveSellitemService, sellitemId, customerId, index);
}

export function* transferSellitemService(id, groupId, customerId, index) {
  const { sellitemId: currentSellitemId, groupId: currentGroupId } = yield select(pathSelector);
  const position = yield select(nextSellitemPositionSelector, customerId, index, id);

  if (id === currentSellitemId) {
    yield call(goToGroupService, currentGroupId);
  }

  yield call(updateSellitemService, id, {
    groupId,
    customerId,
    position,
  });
}

export function* transferCurrentSellitemService(groupId, customerId, index) {
  const { sellitemId } = yield select(pathSelector);

  yield call(transferSellitemService, sellitemId, groupId, customerId, index);
}

export function* handleSellitemUpdateService(sellitem, customers) {
  yield put(handleSellitemUpdate(sellitem, customers));
}

export function* deleteSellitemService(id) {
  const { sellitemId, groupId } = yield select(pathSelector);

  if (id === sellitemId) {
    yield call(goToGroupService, groupId);
  }

  yield put(deleteSellitem(id));

  let sellitem;
  try {
    ({ item: sellitem } = yield call(request, api.deleteSellitem, id));
  } catch (error) {
    yield put(deleteSellitem.failure(id, error));
    return;
  }

  yield put(deleteSellitem.success(sellitem));
}

export function* deleteCurrentSellitemService() {
  const { sellitemId } = yield select(pathSelector);

  yield call(deleteSellitemService, sellitemId);
}

export function* handleSellitemDeleteService(sellitem) {
  const { sellitemId, groupId } = yield select(pathSelector);

  if (sellitem.id === sellitemId) {
    yield call(goToGroupService, groupId);
  }

  yield put(handleSellitemDelete(sellitem));
}
