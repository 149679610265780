import ActionTypes from '../constants/ActionTypes';

export const createSellitemDiscount = (sellitemDiscount) => ({
  type: ActionTypes.DISCOUNT_TO_SELLITEM_ADD,
  payload: {
    sellitemDiscount,
  },
});

createSellitemDiscount.success = (localId, sellitemDiscount) => ({
  type: ActionTypes.DISCOUNT_TO_SELLITEM_ADD__SUCCESS,
  payload: {
    localId,
    sellitemDiscount,
  },
});

createSellitemDiscount.failure = (localId, error) => ({
  type: ActionTypes.DISCOUNT_TO_SELLITEM_ADD__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleSellitemDiscountCreate = (sellitemDiscount, discounts) => ({
  type: ActionTypes.DISCOUNT_TO_SELLITEM_ADD_HANDLE,
  payload: {
    sellitemDiscount,
    discounts,
  },
});

export const deleteSellitemDiscount = (sellitemId, discountId) => ({
  type: ActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE,
  payload: {
    sellitemId,
    discountId,
  },
});

deleteSellitemDiscount.success = (sellitemDiscount) => ({
  type: ActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE__SUCCESS,
  payload: {
    sellitemDiscount,
  },
});

deleteSellitemDiscount.failure = (id, error) => ({
  type: ActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleSellitemDiscountDelete = (sellitemDiscount) => ({
  type: ActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE_HANDLE,
  payload: {
    sellitemDiscount,
  },
});
