import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import { PaymentStatuses, Statuses } from '../constants/Enums';

import {
  paymentsSelector,
  isAdminSelector,
  isManagerSelector,
  isAccountantSelector,
  bagsForCurrentGroupSelector,
} from '../selectors';

import Debts from '../components/Debts';

const mapStateToProps = (state) => {
  const listPayments = paymentsSelector(state);
  const payments = [];
  const listStatus = [
    Statuses.CANCELED.name,
    Statuses.BOOKING.name,
    Statuses.REVIEW.name,
    // Statuses.FINISH.name,
  ];
  listPayments
    // .filter((pm) => pm.status !== PaymentStatuses.FINISH.name && pm.dueDate)
    .forEach((pm) => {
      if (!listStatus.includes(pm.sellitem.status)) {
        payments.push({
          ...pm,
          statusLabel: PaymentStatuses[pm.status.toUpperCase()]?.label,
          gotDate: pm?.gotDate ? format(new Date(pm.gotDate), 'dd/MM/yyyy') : '',
          dueDate: pm?.dueDate ? format(new Date(pm.dueDate), 'dd/MM/yyyy') : '',
        });
      }
    });
  const isAdmin = isAdminSelector(state);
  const isManager = isManagerSelector(state);
  const isAccountant = isAccountantSelector(state);
  let bags = bagsForCurrentGroupSelector(state);

  bags = bags.map((bag) => {
    return {
      key: bag.id,
      value: bag.id,
      text: bag.name,
    };
  });

  return {
    payments,
    bags,
    canEdit: isAdmin || isManager || isAccountant,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Debts);
