import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Input, Search } from 'semantic-ui-react';

import styles from './SearchCustomers.module.scss';

const SearchCustomers = React.memo(({ searchResults, onSearchCustomers, onCustomerSelected }) => {
  const [t] = useTranslation();

  const [query, setQuery] = useState('');
  const [typing, setTyping] = useState({ isSearching: false });
  const searchField = useRef(null);
  const { isSubmitting, customers } = searchResults;

  const handleSearchChange = useCallback(
    (e, data) => {
      if (typing) {
        clearTimeout(typing.timeout);
      }
      if (data && data.value.length >= data.minCharacters) {
        setQuery(data.value);
      } else {
        setQuery('');
        setTyping({ isSearching: false });
      }
      setTyping({
        isSearching: true,
        timeout: setTimeout(() => {
          if (data && data.value.length >= data.minCharacters) {
            onSearchCustomers(data.value);
          }
        }, 200),
      });
    },
    [onSearchCustomers, typing],
  );

  const results = customers.map(({ id, name, phone }) => ({
    id,
    title: name,
    phone,
  }));

  const resultRenderer = ({ id, title, phone }) => {
    return (
      <div key={id} className={styles.result}>
        <span className={styles.title}>{title}</span>
        <span className={styles.phone}>{phone !== null ? ` - ${phone}` : ''}</span>
      </div>
    );
  };

  const handleResultSelect = (event, { result }) => {
    console.log('result:', result);
    const customer = customers.find((p) => p.id === result.id);
    console.log('customers:', customers);
    setQuery(`${customer.name} - ${customer.phone}`);
    onCustomerSelected(customer);
  };

  return (
    <div className={styles.wrapper}>
      <Search
        fluid
        minCharacters={1}
        loading={isSubmitting}
        noResultsMessage={t('common.noResultsMessage')}
        input={
          <Input
            className={styles.input_search}
            fluid
            ref={searchField}
            icon="user"
            size="small"
            iconPosition="left"
            placeholder={t('common.searchCustomer')}
          />
        }
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        resultRenderer={resultRenderer}
        results={typing.isSearching ? results : []}
        value={query}
      />
    </div>
  );
});

SearchCustomers.propTypes = {
  searchResults: PropTypes.objectOf(PropTypes.shape).isRequired,
  onSearchCustomers: PropTypes.func.isRequired,
  onCustomerSelected: PropTypes.func.isRequired,
};

export default SearchCustomers;
