import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  isAdminSelector,
  isSaleAdminSelector,
  pathSelector,
  paymentsForSellitemIdSelector,
  sellitemByIdSelector,
  accountsSelector,
  customersForCurrentSellitemSelector,
  currentProjectSelector,
  productsForCurrentGroupSelector,
  discountsForCurrentSellitemSelector,
} from '../selectors';
import {
  updateSellitem,
  updatePayment,
  createPaymentInCurrentSellitem,
  deletePayment,
} from '../actions/entry';
import SellitemPayments from '../components/Sellitems/Payments';

const mapStateToProps = (state) => {
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);
  const isSaleAdmin = isSaleAdminSelector(state);
  const { sellitemId } = pathSelector(state);
  const sellitem = sellitemByIdSelector(state, sellitemId);
  const accounts = accountsSelector(state);
  const payments = paymentsForSellitemIdSelector(state, sellitemId);
  const customers = customersForCurrentSellitemSelector(state, sellitemId);
  // const product = productByIdSelector(state, sellitem.productId);
  const products = productsForCurrentGroupSelector(state);
  const product = products.find((pr) => pr.id === sellitem.productId);
  const discounts = discountsForCurrentSellitemSelector(state, sellitemId);

  const discountsSorted = sortBy(discounts, ['position']);

  const {
    description,
    paymentDeposit,
    paymentConfirm1,
    paymentConfirm2,
    contract1,
    contract2,
    contract3,
    contract4,
    appendix1,
    appendix2,
    appendix3,
    appendix4,
    appendix5,
  } = currentProjectSelector(state);

  return {
    sellitem,
    payments,
    accounts,
    customers,
    discounts: discountsSorted,
    product,
    description,
    paymentDeposit: paymentDeposit || '',
    paymentConfirm1: paymentConfirm1 || '',
    paymentConfirm2: paymentConfirm2 || '',
    contract1: contract1 || '',
    contract2: contract2 || '',
    contract3: contract3 || '',
    contract4: contract4 || '',
    appendix1: appendix1 || '',
    appendix2: appendix2 || '',
    appendix3: appendix3 || '',
    appendix4: appendix4 || '',
    appendix5: appendix5 || '',
    canEdit: isAdmin || (isCurrentUserMember && isSaleAdmin),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdateSellitem: (id, data) => updateSellitem(id, data),
      onUpdatePayment: (id, data) => updatePayment(id, data),
      onPaymentCreate: (data) => createPaymentInCurrentSellitem(data),
      onDeletePayment: deletePayment,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SellitemPayments);
