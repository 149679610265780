export default {
  /* Router */

  LOCATION_CHANGE_HANDLE: 'LOCATION_CHANGE_HANDLE',
  LOCATION_CHANGE_HANDLE__GROUP_FETCH: 'LOCATION_CHANGE_HANDLE__GROUP_FETCH',
  LOCATION_CHANGE_HANDLE__CUSTOMER_FETCH: 'LOCATION_CHANGE_HANDLE__CUSTOMER_FETCH',
  LOCATION_CHANGE_HANDLE__PRODUCT_FETCH: 'LOCATION_CHANGE_HANDLE__PRODUCT_FETCH',
  LOCATION_CHANGE_HANDLE__BAG_FETCH: 'LOCATION_CHANGE_HANDLE__BAG_FETCH',
  LOCATION_CHANGE_HANDLE__DASHBOARD_FETCH: 'LOCATION_CHANGE_HANDLE__DASHBOARD_FETCH',

  /* Socket */

  SOCKET_DISCONNECT_HANDLE: 'SOCKET_DISCONNECT_HANDLE',
  SOCKET_RECONNECT_HANDLE: 'SOCKET_RECONNECT_HANDLE',
  SOCKET_RECONNECT_HANDLE__CORE_FETCH: 'SOCKET_RECONNECT_HANDLE__CORE_FETCH',

  /* Login */

  AUTHENTICATE: 'AUTHENTICATE',
  AUTHENTICATE__SUCCESS: 'AUTHENTICATE__SUCCESS',
  AUTHENTICATE__FAILURE: 'AUTHENTICATE__FAILURE',
  AUTHENTICATE_ERROR_CLEAR: 'AUTHENTICATE_ERROR_CLEAR',

  AUTHENTICATE_IMGUR: 'AUTHENTICATE_IMGUR',
  AUTHENTICATE_IMGUR__SUCCESS: 'AUTHENTICATE_IMGUR__SUCCESS',
  AUTHENTICATE_IMGUR__FAILURE: 'AUTHENTICATE_IMGUR__FAILURE',

  LOGOUT: 'LOGOUT',

  /* Core */

  CORE_INITIALIZE: 'CORE_INITIALIZE',

  /* Modal */

  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',

  /* User */

  USER_CREATE: 'USER_CREATE',
  USER_CREATE__SUCCESS: 'USER_CREATE__SUCCESS',
  USER_CREATE__FAILURE: 'USER_CREATE__FAILURE',
  USER_CREATE_HANDLE: 'USER_CREATE_HANDLE',
  USER_CREATE_ERROR_CLEAR: 'USER_CREATE_ERROR_CLEAR',
  USER_UPDATE: 'USER_UPDATE',
  USER_UPDATE__SUCCESS: 'USER_UPDATE__SUCCESS',
  USER_UPDATE__FAILURE: 'USER_UPDATE__FAILURE',
  USER_UPDATE_HANDLE: 'USER_UPDATE_HANDLE',
  USER_EMAIL_UPDATE: 'USER_EMAIL_UPDATE',
  USER_EMAIL_UPDATE__SUCCESS: 'USER_EMAIL_UPDATE__SUCCESS',
  USER_EMAIL_UPDATE__FAILURE: 'USER_EMAIL_UPDATE__FAILURE',
  USER_EMAIL_UPDATE_ERROR_CLEAR: 'USER_EMAIL_UPDATE_ERROR_CLEAR',
  USER_PASSWORD_UPDATE: 'USER_PASSWORD_UPDATE',
  USER_PASSWORD_UPDATE__SUCCESS: 'USER_PASSWORD_UPDATE__SUCCESS',
  USER_PASSWORD_UPDATE__FAILURE: 'USER_PASSWORD_UPDATE__FAILURE',
  USER_PASSWORD_UPDATE_ERROR_CLEAR: 'USER_PASSWORD_UPDATE_ERROR_CLEAR',
  USER_USERNAME_UPDATE: 'USER_USERNAME_UPDATE',
  USER_USERNAME_UPDATE__SUCCESS: 'USER_USERNAME_UPDATE__SUCCESS',
  USER_USERNAME_UPDATE__FAILURE: 'USER_USERNAME_UPDATE__FAILURE',
  USER_USERNAME_UPDATE_ERROR_CLEAR: 'USER_USERNAME_UPDATE_ERROR_CLEAR',
  USER_AVATAR_UPDATE: 'USER_AVATAR_UPDATE',
  USER_AVATAR_UPDATE__SUCCESS: 'USER_AVATAR_UPDATE__SUCCESS',
  USER_AVATAR_UPDATE__FAILURE: 'USER_AVATAR_UPDATE__FAILURE',
  USER_DELETE: 'USER_DELETE',
  USER_DELETE__SUCCESS: 'USER_DELETE__SUCCESS',
  USER_DELETE__FAILURE: 'USER_DELETE__FAILURE',
  USER_DELETE_HANDLE: 'USER_DELETE_HANDLE',
  USER_TO_SELLITEM_ADD: 'USER_TO_SELLITEM_ADD',
  USER_TO_SELLITEM_ADD__SUCCESS: 'USER_TO_SELLITEM_ADD__SUCCESS',
  USER_TO_SELLITEM_ADD__FAILURE: 'USER_TO_SELLITEM_ADD__FAILURE',
  USER_TO_SELLITEM_ADD_HANDLE: 'USER_TO_SELLITEM_ADD_HANDLE',
  USER_FROM_SELLITEM_REMOVE: 'USER_FROM_SELLITEM_REMOVE',
  USER_FROM_SELLITEM_REMOVE__SUCCESS: 'USER_FROM_SELLITEM_REMOVE__SUCCESS',
  USER_FROM_SELLITEM_REMOVE__FAILURE: 'USER_FROM_SELLITEM_REMOVE__FAILURE',
  USER_FROM_SELLITEM_REMOVE_HANDLE: 'USER_FROM_SELLITEM_REMOVE_HANDLE',
  USER_TO_GROUP_FILTER_ADD: 'USER_TO_GROUP_FILTER_ADD',
  USER_FROM_GROUP_FILTER_REMOVE: 'USER_FROM_GROUP_FILTER_REMOVE',

  /* Project */

  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_CREATE__SUCCESS: 'PROJECT_CREATE__SUCCESS',
  PROJECT_CREATE__FAILURE: 'PROJECT_CREATE__FAILURE',
  PROJECT_CREATE_HANDLE: 'PROJECT_CREATE_HANDLE',
  PROJECT_UPDATE: 'PROJECT_UPDATE',
  PROJECT_UPDATE__SUCCESS: 'PROJECT_UPDATE__SUCCESS',
  PROJECT_UPDATE__FAILURE: 'PROJECT_UPDATE__FAILURE',
  PROJECT_UPDATE_HANDLE: 'PROJECT_UPDATE_HANDLE',
  PROJECT_BACKGROUND_IMAGE_UPDATE: 'PROJECT_BACKGROUND_IMAGE_UPDATE',
  PROJECT_BACKGROUND_IMAGE_UPDATE__SUCCESS: 'PROJECT_BACKGROUND_IMAGE_UPDATE__SUCCESS',
  PROJECT_BACKGROUND_IMAGE_UPDATE__FAILURE: 'PROJECT_BACKGROUND_IMAGE_UPDATE__FAILURE',
  PROJECT_DELETE: 'PROJECT_DELETE',
  PROJECT_DELETE__SUCCESS: 'PROJECT_DELETE__SUCCESS',
  PROJECT_DELETE__FAILURE: 'PROJECT_DELETE__FAILURE',
  PROJECT_DELETE_HANDLE: 'PROJECT_DELETE_HANDLE',

  /* Project manager */

  PROJECT_MANAGER_CREATE: 'PROJECT_MANAGER_CREATE',
  PROJECT_MANAGER_CREATE__SUCCESS: 'PROJECT_MANAGER_CREATE__SUCCESS',
  PROJECT_MANAGER_CREATE__FAILURE: 'PROJECT_MANAGER_CREATE__FAILURE',
  PROJECT_MANAGER_CREATE_HANDLE: 'PROJECT_MANAGER_CREATE_HANDLE',
  PROJECT_MANAGER_CREATE_HANDLE__PROJECT_FETCH: 'PROJECT_MANAGER_CREATE_HANDLE__PROJECT_FETCH',
  PROJECT_MANAGER_DELETE: 'PROJECT_MANAGER_DELETE',
  PROJECT_MANAGER_DELETE__SUCCESS: 'PROJECT_MANAGER_DELETE__SUCCESS',
  PROJECT_MANAGER_DELETE__FAILURE: 'PROJECT_MANAGER_DELETE__FAILURE',
  PROJECT_MANAGER_DELETE_HANDLE: 'PROJECT_MANAGER_DELETE_HANDLE',

  /* Agent */

  AGENT_CREATE: 'AGENT_CREATE',
  AGENT_CREATE__SUCCESS: 'AGENT_CREATE__SUCCESS',
  AGENT_CREATE__FAILURE: 'AGENT_CREATE__FAILURE',
  AGENT_CREATE_HANDLE: 'AGENT_CREATE_HANDLE',
  AGENT_CREATE_HANDLE__PROJECT_FETCH: 'AGENT_CREATE_HANDLE__PROJECT_FETCH',
  AGENT_UPDATE: 'AGENT_UPDATE',
  AGENT_UPDATE__SUCCESS: 'AGENT_UPDATE__SUCCESS',
  AGENT_UPDATE__FAILURE: 'AGENT_UPDATE__FAILURE',
  AGENT_UPDATE_HANDLE: 'AGENT_UPDATE_HANDLE',
  AGENT_DELETE: 'AGENT_DELETE',
  AGENT_DELETE__SUCCESS: 'AGENT_DELETE__SUCCESS',
  AGENT_DELETE__FAILURE: 'AGENT_DELETE__FAILURE',
  AGENT_DELETE_HANDLE: 'AGENT_DELETE_HANDLE',
  AGENT_TO_GROUP_FILTER_ADD: 'AGENT_TO_GROUP_FILTER_ADD',
  AGENT_FROM_GROUP_FILTER_REMOVE: 'AGENT_FROM_GROUP_FILTER_REMOVE',

  /* Category */

  CATEGORY_CREATE: 'CATEGORY_CREATE',
  CATEGORY_CREATE__SUCCESS: 'CATEGORY_CREATE__SUCCESS',
  CATEGORY_CREATE__FAILURE: 'CATEGORY_CREATE__FAILURE',
  CATEGORY_CREATE_HANDLE: 'CATEGORY_CREATE_HANDLE',
  CATEGORY_CREATE_HANDLE__PROJECT_FETCH: 'CATEGORY_CREATE_HANDLE__PROJECT_FETCH',
  CATEGORY_UPDATE: 'CATEGORY_UPDATE',
  CATEGORY_UPDATE__SUCCESS: 'CATEGORY_UPDATE__SUCCESS',
  CATEGORY_UPDATE__FAILURE: 'CATEGORY_UPDATE__FAILURE',
  CATEGORY_UPDATE_HANDLE: 'CATEGORY_UPDATE_HANDLE',
  CATEGORY_DELETE: 'CATEGORY_DELETE',
  CATEGORY_DELETE__SUCCESS: 'CATEGORY_DELETE__SUCCESS',
  CATEGORY_DELETE__FAILURE: 'CATEGORY_DELETE__FAILURE',
  CATEGORY_DELETE_HANDLE: 'CATEGORY_DELETE_HANDLE',
  CATEGORY_TO_GROUP_FILTER_ADD: 'CATEGORY_TO_GROUP_FILTER_ADD',
  CATEGORY_FROM_GROUP_FILTER_REMOVE: 'CATEGORY_FROM_GROUP_FILTER_REMOVE',

  /* Account */

  ACCOUNT_CREATE: 'ACCOUNT_CREATE',
  ACCOUNT_CREATE__SUCCESS: 'ACCOUNT_CREATE__SUCCESS',
  ACCOUNT_CREATE__FAILURE: 'ACCOUNT_CREATE__FAILURE',
  ACCOUNT_CREATE_HANDLE: 'ACCOUNT_CREATE_HANDLE',
  ACCOUNT_CREATE_HANDLE__PROJECT_FETCH: 'ACCOUNT_CREATE_HANDLE__PROJECT_FETCH',
  ACCOUNT_UPDATE: 'ACCOUNT_UPDATE',
  ACCOUNT_UPDATE__SUCCESS: 'ACCOUNT_UPDATE__SUCCESS',
  ACCOUNT_UPDATE__FAILURE: 'ACCOUNT_UPDATE__FAILURE',
  ACCOUNT_UPDATE_HANDLE: 'ACCOUNT_UPDATE_HANDLE',
  ACCOUNT_DELETE: 'ACCOUNT_DELETE',
  ACCOUNT_DELETE__SUCCESS: 'ACCOUNT_DELETE__SUCCESS',
  ACCOUNT_DELETE__FAILURE: 'ACCOUNT_DELETE__FAILURE',
  ACCOUNT_DELETE_HANDLE: 'ACCOUNT_DELETE_HANDLE',

  /* Customer group */

  BLOCK_CREATE: 'BLOCK_CREATE',
  BLOCK_CREATE__SUCCESS: 'BLOCK_CREATE__SUCCESS',
  BLOCK_CREATE__FAILURE: 'BLOCK_CREATE__FAILURE',
  BLOCK_CREATE_HANDLE: 'BLOCK_CREATE_HANDLE',
  BLOCK_CREATE_HANDLE__PROJECT_FETCH: 'BLOCK_CREATE_HANDLE__PROJECT_FETCH',
  BLOCK_UPDATE: 'BLOCK_UPDATE',
  BLOCK_UPDATE__SUCCESS: 'BLOCK_UPDATE__SUCCESS',
  BLOCK_UPDATE__FAILURE: 'BLOCK_UPDATE__FAILURE',
  BLOCK_UPDATE_HANDLE: 'BLOCK_UPDATE_HANDLE',
  BLOCK_DELETE: 'BLOCK_DELETE',
  BLOCK_DELETE__SUCCESS: 'BLOCK_DELETE__SUCCESS',
  BLOCK_DELETE__FAILURE: 'BLOCK_DELETE__FAILURE',
  BLOCK_DELETE_HANDLE: 'BLOCK_DELETE_HANDLE',

  /* Group */

  GROUP_CREATE: 'GROUP_CREATE',
  GROUP_CREATE__SUCCESS: 'GROUP_CREATE__SUCCESS',
  GROUP_CREATE__FAILURE: 'GROUP_CREATE__FAILURE',
  GROUP_CREATE_HANDLE: 'GROUP_CREATE_HANDLE',
  GROUP_FETCH: 'GROUP_FETCH',
  GROUP_FETCH__SUCCESS: 'GROUP_FETCH__SUCCESS',
  GROUP_FETCH__FAILURE: 'GROUP_FETCH__FAILURE',
  GROUP_UPDATE: 'GROUP_UPDATE',
  GROUP_UPDATE__SUCCESS: 'GROUP_UPDATE__SUCCESS',
  GROUP_UPDATE__FAILURE: 'GROUP_UPDATE__FAILURE',
  GROUP_UPDATE_HANDLE: 'GROUP_UPDATE_HANDLE',
  GROUP_DELETE: 'GROUP_DELETE',
  GROUP_DELETE__SUCCESS: 'GROUP_DELETE__SUCCESS',
  GROUP_DELETE__FAILURE: 'GROUP_DELETE__FAILURE',
  GROUP_DELETE_HANDLE: 'GROUP_DELETE_HANDLE',

  /* Group membership */

  GROUP_MEMBERSHIP_CREATE: 'GROUP_MEMBERSHIP_CREATE',
  GROUP_MEMBERSHIP_CREATE__SUCCESS: 'GROUP_MEMBERSHIP_CREATE__SUCCESS',
  GROUP_MEMBERSHIP_CREATE__FAILURE: 'GROUP_MEMBERSHIP_CREATE__FAILURE',
  GROUP_MEMBERSHIP_CREATE_HANDLE: 'GROUP_MEMBERSHIP_CREATE_HANDLE',
  GROUP_MEMBERSHIP_CREATE_HANDLE__PROJECT_FETCH: 'GROUP_MEMBERSHIP_CREATE_HANDLE__PROJECT_FETCH',
  GROUP_MEMBERSHIP_DELETE: 'GROUP_MEMBERSHIP_DELETE',
  GROUP_MEMBERSHIP_DELETE__SUCCESS: 'GROUP_MEMBERSHIP_DELETE__SUCCESS',
  GROUP_MEMBERSHIP_DELETE__FAILURE: 'GROUP_MEMBERSHIP_DELETE__FAILURE',
  GROUP_MEMBERSHIP_DELETE_HANDLE: 'GROUP_MEMBERSHIP_DELETE_HANDLE',

  /* Label */

  LABEL_CREATE: 'LABEL_CREATE',
  LABEL_CREATE__SUCCESS: 'LABEL_CREATE__SUCCESS',
  LABEL_CREATE__FAILURE: 'LABEL_CREATE__FAILURE',
  LABEL_CREATE_HANDLE: 'LABEL_CREATE_HANDLE',
  LABEL_UPDATE: 'LABEL_UPDATE',
  LABEL_UPDATE__SUCCESS: 'LABEL_UPDATE__SUCCESS',
  LABEL_UPDATE__FAILURE: 'LABEL_UPDATE__FAILURE',
  LABEL_UPDATE_HANDLE: 'LABEL_UPDATE_HANDLE',
  LABEL_DELETE: 'LABEL_DELETE',
  LABEL_DELETE__SUCCESS: 'LABEL_DELETE__SUCCESS',
  LABEL_DELETE__FAILURE: 'LABEL_DELETE__FAILURE',
  LABEL_DELETE_HANDLE: 'LABEL_DELETE_HANDLE',
  LABEL_TO_SELLITEM_ADD: 'LABEL_TO_SELLITEM_ADD',
  LABEL_TO_SELLITEM_ADD__SUCCESS: 'LABEL_TO_SELLITEM_ADD__SUCCESS',
  LABEL_TO_SELLITEM_ADD__FAILURE: 'LABEL_TO_SELLITEM_ADD__FAILURE',
  LABEL_TO_SELLITEM_ADD_HANDLE: 'LABEL_TO_SELLITEM_ADD_HANDLE',
  LABEL_FROM_SELLITEM_REMOVE: 'LABEL_FROM_SELLITEM_REMOVE',
  LABEL_FROM_SELLITEM_REMOVE__SUCCESS: 'LABEL_FROM_SELLITEM_REMOVE__SUCCESS',
  LABEL_FROM_SELLITEM_REMOVE__FAILURE: 'LABEL_FROM_SELLITEM_REMOVE__FAILURE',
  LABEL_FROM_SELLITEM_REMOVE_HANDLE: 'LABEL_FROM_SELLITEM_REMOVE_HANDLE',
  LABEL_TO_GROUP_FILTER_ADD: 'LABEL_TO_GROUP_FILTER_ADD',
  LABEL_FROM_GROUP_FILTER_REMOVE: 'LABEL_FROM_GROUP_FILTER_REMOVE',

  /* Customer */

  CUSTOMER_CREATE: 'CUSTOMER_CREATE',
  CUSTOMER_CREATE__SUCCESS: 'CUSTOMER_CREATE__SUCCESS',
  CUSTOMER_CREATE__FAILURE: 'CUSTOMER_CREATE__FAILURE',
  CUSTOMER_CREATE_HANDLE: 'CUSTOMER_CREATE_HANDLE',
  CUSTOMER_FETCH: 'CUSTOMER_FETCH',
  CUSTOMER_FETCH__SUCCESS: 'CUSTOMER_FETCH__SUCCESS',
  CUSTOMER_FETCH__FAILURE: 'CUSTOMER_FETCH__FAILURE',
  CUSTOMER_UPDATE: 'CUSTOMER_UPDATE',
  CUSTOMER_UPDATE__SUCCESS: 'CUSTOMER_UPDATE__SUCCESS',
  CUSTOMER_UPDATE__FAILURE: 'CUSTOMER_UPDATE__FAILURE',
  CUSTOMER_UPDATE_HANDLE: 'CUSTOMER_UPDATE_HANDLE',
  CUSTOMER_DELETE: 'CUSTOMER_DELETE',
  CUSTOMER_DELETE__SUCCESS: 'CUSTOMER_DELETE__SUCCESS',
  CUSTOMER_DELETE__FAILURE: 'CUSTOMER_DELETE__FAILURE',
  CUSTOMER_DELETE_HANDLE: 'CUSTOMER_DELETE_HANDLE',

  CUSTOMER_TO_SELLITEM_ADD: 'CUSTOMER_TO_SELLITEM_ADD',
  CUSTOMER_TO_SELLITEM_ADD__SUCCESS: 'CUSTOMER_TO_SELLITEM_ADD__SUCCESS',
  CUSTOMER_TO_SELLITEM_ADD__FAILURE: 'CUSTOMER_TO_SELLITEM_ADD__FAILURE',
  CUSTOMER_TO_SELLITEM_ADD_HANDLE: 'CUSTOMER_TO_SELLITEM_ADD_HANDLE',
  CUSTOMER_FROM_SELLITEM_REMOVE: 'CUSTOMER_FROM_SELLITEM_REMOVE',
  CUSTOMER_FROM_SELLITEM_REMOVE__SUCCESS: 'CUSTOMER_FROM_SELLITEM_REMOVE__SUCCESS',
  CUSTOMER_FROM_SELLITEM_REMOVE__FAILURE: 'CUSTOMER_FROM_SELLITEM_REMOVE__FAILURE',
  CUSTOMER_FROM_SELLITEM_REMOVE_HANDLE: 'CUSTOMER_FROM_SELLITEM_REMOVE_HANDLE',
  CUSTOMER_TO_SELLITEM_UPDATE: 'CUSTOMER_TO_SELLITEM_UPDATE',
  CUSTOMER_TO_SELLITEM_UPDATE__SUCCESS: 'CUSTOMER_TO_SELLITEM_UPDATE__SUCCESS',
  CUSTOMER_TO_SELLITEM_UPDATE__FAILURE: 'CUSTOMER_TO_SELLITEM_UPDATE__FAILURE',

  /* Import */
  GROUP_IMPORT: 'GROUP_IMPORT',
  GROUP_IMPORT__SUCCESS: 'GROUP_IMPORT__SUCCESS',
  GROUP_IMPORT__FAILURE: 'GROUP_IMPORT__FAILURE',
  GROUP_IMPORT_ERROR_CLEAR: 'GROUP_IMPORT_ERROR_CLEAR',

  /* Product */

  PRODUCT_CREATE: 'PRODUCT_CREATE',
  PRODUCT_CREATE__SUCCESS: 'PRODUCT_CREATE__SUCCESS',
  PRODUCT_CREATE__FAILURE: 'PRODUCT_CREATE__FAILURE',
  PRODUCT_CREATE_HANDLE: 'PRODUCT_CREATE_HANDLE',
  PRODUCT_FETCH: 'PRODUCT_FETCH',
  PRODUCT_FETCH__SUCCESS: 'PRODUCT_FETCH__SUCCESS',
  PRODUCT_FETCH__FAILURE: 'PRODUCT_FETCH__FAILURE',
  PRODUCT_UPDATE: 'PRODUCT_UPDATE',
  PRODUCT_UPDATE__SUCCESS: 'PRODUCT_UPDATE__SUCCESS',
  PRODUCT_UPDATE__FAILURE: 'PRODUCT_UPDATE__FAILURE',
  PRODUCT_UPDATE_HANDLE: 'PRODUCT_UPDATE_HANDLE',
  PRODUCT_TRANSFER: 'PRODUCT_TRANSFER',
  PRODUCT_TRANSFER__SUCCESS: 'PRODUCT_TRANSFER__SUCCESS',
  PRODUCT_TRANSFER__FAILURE: 'PRODUCT_TRANSFER__FAILURE',
  PRODUCT_DELETE: 'PRODUCT_DELETE',
  PRODUCT_DELETE__SUCCESS: 'PRODUCT_DELETE__SUCCESS',
  PRODUCT_DELETE__FAILURE: 'PRODUCT_DELETE__FAILURE',
  PRODUCT_DELETE_HANDLE: 'PRODUCT_DELETE_HANDLE',
  PRODUCTS_IMPORT_HANDLE: 'PRODUCTS_IMPORT_HANDLE',

  /* Products */
  PRODUCTS_FETCH: 'PRODUCTS_FETCH',
  PRODUCTS_FETCH__SUCCESS: 'PRODUCTS_FETCH__SUCCESS',
  PRODUCTS_FETCH__FAILURE: 'PRODUCTS_FETCH__FAILURE',

  /* Sellitem */

  SELLITEM_CREATE: 'SELLITEM_CREATE',
  SELLITEM_CREATE__SUCCESS: 'SELLITEM_CREATE__SUCCESS',
  SELLITEM_CREATE__FAILURE: 'SELLITEM_CREATE__FAILURE',
  SELLITEM_CREATE_HANDLE: 'SELLITEM_CREATE_HANDLE',
  SELLITEM_FETCH: 'SELLITEM_FETCH',
  SELLITEM_FETCH__SUCCESS: 'SELLITEM_FETCH__SUCCESS',
  SELLITEM_FETCH__FAILURE: 'SELLITEM_FETCH__FAILURE',
  SELLITEM_UPDATE: 'SELLITEM_UPDATE',
  SELLITEM_UPDATE__SUCCESS: 'SELLITEM_UPDATE__SUCCESS',
  SELLITEM_UPDATE__FAILURE: 'SELLITEM_UPDATE__FAILURE',
  SELLITEM_UPDATE_HANDLE: 'SELLITEM_UPDATE_HANDLE',
  SELLITEM_TRANSFER: 'SELLITEM_TRANSFER',
  SELLITEM_TRANSFER__SUCCESS: 'SELLITEM_TRANSFER__SUCCESS',
  SELLITEM_TRANSFER__FAILURE: 'SELLITEM_TRANSFER__FAILURE',
  SELLITEM_DELETE: 'SELLITEM_DELETE',
  SELLITEM_DELETE__SUCCESS: 'SELLITEM_DELETE__SUCCESS',
  SELLITEM_DELETE__FAILURE: 'SELLITEM_DELETE__FAILURE',
  SELLITEM_DELETE_HANDLE: 'SELLITEM_DELETE_HANDLE',
  SELLITEM_ADD_TODO: 'SELLITEM_ADD_TODO',
  SELLITEM_TOGGLE_TODO: 'SELLITEM_TOGGLE_TODO',

  /* Sellitems */
  SELLITEMS_FETCH: 'SELLITEMS_FETCH',
  SELLITEMS_FETCH__SUCCESS: 'SELLITEMS_FETCH__SUCCESS',
  SELLITEMS_FETCH__FAILURE: 'SELLITEMS_FETCH__FAILURE',

  // /* SellitemDiscount */

  // SELLITEM_DISCOUNT_CREATE: 'SELLITEM_DISCOUNT_CREATE',
  // SELLITEM_DISCOUNT_CREATE__SUCCESS: 'SELLITEM_DISCOUNT_CREATE__SUCCESS',
  // SELLITEM_DISCOUNT_CREATE__FAILURE: 'SELLITEM_DISCOUNT_CREATE__FAILURE',
  // SELLITEM_DISCOUNT_CREATE_HANDLE: 'SELLITEM_DISCOUNT_CREATE_HANDLE',
  // SELLITEM_DISCOUNT_CREATE_HANDLE__PROJECT_FETCH: 'SELLITEM_DISCOUNT_CREATE_HANDLE__PROJECT_FETCH',
  // SELLITEM_DISCOUNT_DELETE: 'SELLITEM_DISCOUNT_DELETE',
  // SELLITEM_DISCOUNT_DELETE__SUCCESS: 'SELLITEM_DISCOUNT_DELETE__SUCCESS',
  // SELLITEM_DISCOUNT_DELETE__FAILURE: 'SELLITEM_DISCOUNT_DELETE__FAILURE',
  // SELLITEM_DISCOUNT_DELETE_HANDLE: 'SELLITEM_DISCOUNT_DELETE_HANDLE',

  /* Bag */

  BAG_CREATE: 'BAG_CREATE',
  BAG_CREATE__SUCCESS: 'BAG_CREATE__SUCCESS',
  BAG_CREATE__FAILURE: 'BAG_CREATE__FAILURE',
  BAG_CREATE_HANDLE: 'BAG_CREATE_HANDLE',
  BAG_FETCH: 'BAG_FETCH',
  BAG_FETCH__SUCCESS: 'BAG_FETCH__SUCCESS',
  BAG_FETCH__FAILURE: 'BAG_FETCH__FAILURE',
  BAG_UPDATE: 'BAG_UPDATE',
  BAG_UPDATE__SUCCESS: 'BAG_UPDATE__SUCCESS',
  BAG_UPDATE__FAILURE: 'BAG_UPDATE__FAILURE',
  BAG_UPDATE_HANDLE: 'BAG_UPDATE_HANDLE',
  BAG_DELETE: 'BAG_DELETE',
  BAG_DELETE__SUCCESS: 'BAG_DELETE__SUCCESS',
  BAG_DELETE__FAILURE: 'BAG_DELETE__FAILURE',
  BAG_DELETE_HANDLE: 'BAG_DELETE_HANDLE',

  BAG_RECALCULATE_COMMISSION: 'BAG_RECALCULATE_COMMISSION',
  BAG_RECALCULATE_COMMISSION__SUCCESS: 'BAG_RECALCULATE_COMMISSION__SUCCESS',
  BAG_RECALCULATE_COMMISSION__FAILURE: 'BAG_RECALCULATE_COMMISSION__FAILURE',

  BAGITEM_CREATE: 'BAGITEM_CREATE',
  BAGITEM_CREATE__SUCCESS: 'BAGITEM_CREATE__SUCCESS',
  BAGITEM_CREATE__FAILURE: 'BAGITEM_CREATE__FAILURE',
  BAGITEM_CREATE_HANDLE: 'BAGITEM_CREATE_HANDLE',
  BAGITEM_UPDATE: 'BAGITEM_UPDATE',
  BAGITEM_UPDATE__SUCCESS: 'BAGITEM_UPDATE__SUCCESS',
  BAGITEM_UPDATE__FAILURE: 'BAGITEM_UPDATE__FAILURE',
  BAGITEM_UPDATE_HANDLE: 'BAGITEM_UPDATE_HANDLE',
  BAGITEM_DELETE: 'BAGITEM_DELETE',
  BAGITEM_DELETE__SUCCESS: 'BAGITEM_DELETE__SUCCESS',
  BAGITEM_DELETE__FAILURE: 'BAGITEM_DELETE__FAILURE',
  BAGITEM_DELETE_HANDLE: 'BAGITEM_DELETE_HANDLE',

  /* Bags */
  BAGS_FETCH: 'BAGS_FETCH',
  BAGS_FETCH__SUCCESS: 'BAGS_FETCH__SUCCESS',
  BAGS_FETCH__FAILURE: 'BAGS_FETCH__FAILURE',

  /* Discount */

  DISCOUNT_CREATE: 'DISCOUNT_CREATE',
  DISCOUNT_CREATE__SUCCESS: 'DISCOUNT_CREATE__SUCCESS',
  DISCOUNT_CREATE__FAILURE: 'DISCOUNT_CREATE__FAILURE',
  DISCOUNT_CREATE_HANDLE: 'DISCOUNT_CREATE_HANDLE',
  DISCOUNT_UPDATE: 'DISCOUNT_UPDATE',
  DISCOUNT_UPDATE__SUCCESS: 'DISCOUNT_UPDATE__SUCCESS',
  DISCOUNT_UPDATE__FAILURE: 'DISCOUNT_UPDATE__FAILURE',
  DISCOUNT_UPDATE_HANDLE: 'DISCOUNT_UPDATE_HANDLE',
  DISCOUNT_DELETE: 'DISCOUNT_DELETE',
  DISCOUNT_DELETE__SUCCESS: 'DISCOUNT_DELETE__SUCCESS',
  DISCOUNT_DELETE__FAILURE: 'DISCOUNT_DELETE__FAILURE',
  DISCOUNT_DELETE_HANDLE: 'DISCOUNT_DELETE_HANDLE',

  DISCOUNT_TO_SELLITEM_ADD: 'DISCOUNT_TO_SELLITEM_ADD',
  DISCOUNT_TO_SELLITEM_ADD__SUCCESS: 'DISCOUNT_TO_SELLITEM_ADD__SUCCESS',
  DISCOUNT_TO_SELLITEM_ADD__FAILURE: 'DISCOUNT_TO_SELLITEM_ADD__FAILURE',
  DISCOUNT_TO_SELLITEM_ADD_HANDLE: 'DISCOUNT_TO_SELLITEM_ADD_HANDLE',
  DISCOUNT_FROM_SELLITEM_REMOVE: 'DISCOUNT_FROM_SELLITEM_REMOVE',
  DISCOUNT_FROM_SELLITEM_REMOVE__SUCCESS: 'DISCOUNT_FROM_SELLITEM_REMOVE__SUCCESS',
  DISCOUNT_FROM_SELLITEM_REMOVE__FAILURE: 'DISCOUNT_FROM_SELLITEM_REMOVE__FAILURE',
  DISCOUNT_FROM_SELLITEM_REMOVE_HANDLE: 'DISCOUNT_FROM_SELLITEM_REMOVE_HANDLE',

  /* Payment */

  PAYMENT_CREATE: 'PAYMENT_CREATE',
  PAYMENT_CREATE__SUCCESS: 'PAYMENT_CREATE__SUCCESS',
  PAYMENT_CREATE__FAILURE: 'PAYMENT_CREATE__FAILURE',
  PAYMENT_CREATE_HANDLE: 'PAYMENT_CREATE_HANDLE',
  PAYMENT_UPDATE: 'PAYMENT_UPDATE',
  PAYMENT_UPDATE__SUCCESS: 'PAYMENT_UPDATE__SUCCESS',
  PAYMENT_UPDATE__FAILURE: 'PAYMENT_UPDATE__FAILURE',
  PAYMENT_UPDATE_HANDLE: 'PAYMENT_UPDATE_HANDLE',
  PAYMENT_DELETE: 'PAYMENT_DELETE',
  PAYMENT_DELETE__SUCCESS: 'PAYMENT_DELETE__SUCCESS',
  PAYMENT_DELETE__FAILURE: 'PAYMENT_DELETE__FAILURE',
  PAYMENT_DELETE_HANDLE: 'PAYMENT_DELETE_HANDLE',
  PAYMENT_INIT_HANDLE: 'PAYMENT_INIT_HANDLE',

  /* Attachment */

  ATTACHMENT_CREATE: 'ATTACHMENT_CREATE',
  ATTACHMENT_CREATE__SUCCESS: 'ATTACHMENT_CREATE__SUCCESS',
  ATTACHMENT_CREATE__FAILURE: 'ATTACHMENT_CREATE__FAILURE',
  ATTACHMENT_CREATE_HANDLE: 'ATTACHMENT_CREATE_HANDLE',
  ATTACHMENT_UPDATE: 'ATTACHMENT_UPDATE',
  ATTACHMENT_UPDATE__SUCCESS: 'ATTACHMENT_UPDATE__SUCCESS',
  ATTACHMENT_UPDATE__FAILURE: 'ATTACHMENT_UPDATE__FAILURE',
  ATTACHMENT_UPDATE_HANDLE: 'ATTACHMENT_UPDATE_HANDLE',
  ATTACHMENT_DELETE: 'ATTACHMENT_DELETE',
  ATTACHMENT_DELETE__SUCCESS: 'ATTACHMENT_DELETE__SUCCESS',
  ATTACHMENT_DELETE__FAILURE: 'ATTACHMENT_DELETE__FAILURE',
  ATTACHMENT_DELETE_HANDLE: 'ATTACHMENT_DELETE_HANDLE',
  ATTACHMENT_IMGUR_CREATE: 'ATTACHMENT_IMGUR_CREATE',
  ATTACHMENT_IMGUR_CREATE__SUCCESS: 'ATTACHMENT_IMGUR_CREATE__SUCCESS',
  ATTACHMENT_IMGUR_CREATE__FAILURE: 'ATTACHMENT_IMGUR_CREATE__FAILURE',

  /* Actions */

  ACTIONS_FETCH: 'ACTIONS_FETCH',
  ACTIONS_FETCH__SUCCESS: 'ACTIONS_FETCH__SUCCESS',
  ACTIONS_FETCH__FAILURE: 'ACTIONS_FETCH__FAILURE',

  /* Action */

  ACTION_CREATE_HANDLE: 'ACTION_CREATE_HANDLE',
  ACTION_UPDATE_HANDLE: 'ACTION_UPDATE_HANDLE',
  ACTION_DELETE_HANDLE: 'ACTION_DELETE_HANDLE',

  /* Comment action */

  COMMENT_ACTION_CREATE: 'COMMENT_ACTION_CREATE',
  COMMENT_ACTION_CREATE__SUCCESS: 'COMMENT_ACTION_CREATE__SUCCESS',
  COMMENT_ACTION_CREATE__FAILURE: 'COMMENT_ACTION_CREATE__FAILURE',
  COMMENT_ACTION_UPDATE: 'COMMENT_ACTION_UPDATE',
  COMMENT_ACTION_UPDATE__SUCCESS: 'COMMENT_ACTION_UPDATE__SUCCESS',
  COMMENT_ACTION_UPDATE__FAILURE: 'COMMENT_ACTION_UPDATE__FAILURE',
  COMMENT_ACTION_DELETE: 'COMMENT_ACTION_DELETE',
  COMMENT_ACTION_DELETE__SUCCESS: 'COMMENT_ACTION_DELETE__SUCCESS',
  COMMENT_ACTION_DELETE__FAILURE: 'COMMENT_ACTION_DELETE__FAILURE',

  /* Notification */

  NOTIFICATION_CREATE_HANDLE: 'NOTIFICATION_CREATE_HANDLE',
  NOTIFICATION_DELETE: 'NOTIFICATION_DELETE',
  NOTIFICATION_DELETE__SUCCESS: 'NOTIFICATION_DELETE__SUCCESS',
  NOTIFICATION_DELETE__FAILURE: 'NOTIFICATION_DELETE__FAILURE',
  NOTIFICATION_DELETE_HANDLE: 'NOTIFICATION_DELETE_HANDLE',

  /* Customer */
  CUSTOMERS_FETCH: 'CUSTOMERS_FETCH',
  CUSTOMERS_FETCH__SUCCESS: 'CUSTOMERS_FETCH__SUCCESS',
  CUSTOMERS_FETCH__FAILURE: 'CUSTOMERS_FETCH__FAILURE',

  /* ProjectAgent */
  PROJECT_AGENT_CREATE: 'PROJECT_AGENT_CREATE',
  PROJECT_AGENT_CREATE__SUCCESS: 'PROJECT_AGENT_CREATE__SUCCESS',
  PROJECT_AGENT_CREATE__FAILURE: 'PROJECT_AGENT_CREATE__FAILURE',
  PROJECT_AGENT_UPDATE: 'PROJECT_AGENT_UPDATE',
  PROJECT_AGENT_UPDATE__SUCCESS: 'PROJECT_AGENT_UPDATE__SUCCESS',
  PROJECT_AGENT_UPDATE__FAILURE: 'PROJECT_AGENT_UPDATE__FAILURE',

  /* Status */
  STATUS_TO_GROUP_FILTER_ADD: 'STATUS_TO_GROUP_FILTER_ADD',
  STATUS_FROM_GROUP_FILTER_REMOVE: 'STATUS_FROM_GROUP_FILTER_REMOVE',
  BAG_STATUS_TO_GROUP_FILTER_ADD: 'BAG_STATUS_TO_GROUP_FILTER_ADD',
  BAG_STATUS_FROM_GROUP_FILTER_REMOVE: 'BAG_STATUS_FROM_GROUP_FILTER_REMOVE',
  PRODUCT_STATUS_TO_GROUP_FILTER_ADD: 'PRODUCT_STATUS_TO_GROUP_FILTER_ADD',
  PRODUCT_STATUS_FROM_GROUP_FILTER_REMOVE: 'PRODUCT_STATUS_FROM_GROUP_FILTER_REMOVE',
  PRODUCT_STATUS_FROM_GROUP_FILTER_REMOVE_ALL: 'PRODUCT_STATUS_FROM_GROUP_FILTER_REMOVE_ALL',

  /* Dashboard */
  DASHBOARD_FETCH: 'DASHBOARD_FETCH',
  DASHBOARD_FETCH__SUCCESS: 'DASHBOARD_FETCH__SUCCESS',
  DASHBOARD_FETCH__FAILURE: 'DASHBOARD_FETCH__FAILURE',
};
