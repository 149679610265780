import { call, put } from 'redux-saga/effects';

import request from '../request';
import {
  createSellitemDiscount,
  deleteSellitemDiscount,
  handleSellitemDiscountCreate,
  handleSellitemDiscountDelete,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createSellitemDiscountService(data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createSellitemDiscount({
      ...nextData,
      id: localId,
    }),
  );

  let discount;
  try {
    ({ item: discount } = yield call(
      request,
      api.createSellitemDiscount,
      nextData.sellitemId,
      nextData,
    ));
  } catch (error) {
    yield put(createSellitemDiscount.failure(localId, error));
    return;
  }

  yield put(createSellitemDiscount.success(localId, discount));
}

export function* handleSellitemDiscountCreateService(sellitemDiscount, discounts) {
  yield put(handleSellitemDiscountCreate(sellitemDiscount, discounts));
}

export function* deleteSellitemDiscountService(sellitemId, discountId) {
  yield put(deleteSellitemDiscount(sellitemId, discountId));

  let discount;
  try {
    ({ item: discount } = yield call(request, api.deleteSellitemDiscount, sellitemId, discountId));
  } catch (error) {
    yield put(deleteSellitemDiscount.failure(sellitemId, error));
    return;
  }

  yield put(deleteSellitemDiscount.success(discount));
}

export function* handleSellitemDiscountDeleteService(label) {
  yield put(handleSellitemDiscountDelete(label));
}

export function* updateSellitemDiscountService(data) {
  yield call(deleteSellitemDiscountService, data.sellitemId, data.oldDiscountId);
  yield call(createSellitemDiscountService, data);
}
