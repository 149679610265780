import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isAdminSelector,
  isCurrentUserMemberForCurrentGroupSelector,
  bagIdsForCurrentGroupSelector,
  categoriesForCurrentProjectSelector,
  currentBagSelector,
  pathSelector,
} from '../selectors';
import { createBag } from '../actions/entry';

import LeftSidebar from '../components/Bags/LeftSidebar';

const mapStateToProps = (state) => {
  const { groupId } = pathSelector(state);
  const bagIds = bagIdsForCurrentGroupSelector(state);
  const categories = categoriesForCurrentProjectSelector(state);
  const currentBag = currentBagSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);

  return {
    bagIds,
    categories,
    currentBag,
    groupId,
    canEdit: isAdmin && isCurrentUserMember,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onBagCreate: createBag,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
