const GROUP = 'GROUP';
const BLOCK = 'BLOCK';
const PRODUCT = 'PRODUCT';
const SELLITEM = 'SELLITEM';

export default {
  GROUP,
  BLOCK,
  PRODUCT,
  SELLITEM,
};
