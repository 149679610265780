import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import { useToggle } from '../../../lib/hooks';
import Item from './Item';
import AttachmentAddZone from './AttachmentAddZone';
import AttachmentAddPopup from './AttachmentAddPopup';

import styles from './Attachments.module.scss';
import { Statuses } from '../../../constants/Enums';

const Attachments = React.memo(
  ({ status, items, onAttachmentCreate, onAttachmentUpdate, onAttachmentDelete }) => {
    const [t] = useTranslation();
    const [isOpened, toggleOpened] = useToggle();

    const handleToggleClick = useCallback(() => {
      toggleOpened();
    }, [toggleOpened]);

    const handleUpdate = useCallback(
      (id, data) => {
        onAttachmentUpdate(id, data);
      },
      [onAttachmentUpdate],
    );

    const handleDelete = useCallback(
      (id) => {
        onAttachmentDelete(id);
      },
      [onAttachmentDelete],
    );

    const visibleItems = isOpened ? items : items.slice(0, 4);
    return (
      <AttachmentAddZone onCreate={onAttachmentCreate}>
        <div className={styles.wrapper}>
          <div className={styles.wrapperHeader}>
            <Icon name="attach" className={styles.moduleIcon} />
            <div className={styles.moduleHeader}>{t('common.attachments')}</div>
            <AttachmentAddPopup onCreate={onAttachmentCreate}>
              <Button
                fluid
                className={styles.uploadFileButton}
                disabled={status === Statuses.CANCELED.name}
              >
                {t('common.uploadFile')}
              </Button>
            </AttachmentAddPopup>
            {/* <Button
          className={styles.uploadFileButton}
          content={t('common.uploadFile')}
          onClick={onUpdateSellitem}
        /> */}
          </div>
          <div className={styles.wrapperItems}>
            {visibleItems.map((item) => (
              <Item
                key={item.id}
                name={item.name}
                uuid={item.uuid}
                url={item.uuid ? item.uuid : item.url}
                coverUrl={item.coverUrl}
                createdAt={item.createdAt}
                isCover={item.isCover}
                isPersisted={item.isPersisted}
                onUpdate={(data) => handleUpdate(item.id, data)}
                onDelete={() => handleDelete(item.id)}
              />
            ))}
          </div>
          {items.length > 4 && (
            <Button
              fluid
              content={
                isOpened
                  ? t('action.showFewerAttachments')
                  : t('action.showAllAttachments', {
                      hidden: items.length - visibleItems.length,
                    })
              }
              className={styles.toggleButton}
              onClick={handleToggleClick}
            />
          )}
        </div>
      </AttachmentAddZone>
    );
  },
);

Attachments.propTypes = {
  status: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onAttachmentCreate: PropTypes.func.isRequired,
  onAttachmentUpdate: PropTypes.func.isRequired,
  onAttachmentDelete: PropTypes.func.isRequired,
};

export default Attachments;
