import { takeEvery, all } from 'redux-saga/effects';

import { searchBagsInCurrentGroupService, fetchBagsInCurrentGroupService } from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* bagsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.BAGS_SEARCH, ({ payload: { query } }) =>
      searchBagsInCurrentGroupService(query),
    ),
    takeEvery(EntryActionTypes.BAGS_FETCH, ({ payload: { query } }) =>
      fetchBagsInCurrentGroupService(query),
    ),
  ]);
}
