import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { PaymentStatuses, Statuses } from '../constants/Enums';

import {
  // isCurrentUserMemberForCurrentGroupSelector,
  // isAdminSelector,
  // isSaleAdminSelector,
  pathSelector,
  paymentsBySellitemIdSelector,
  sellitemsByCustomerIdSelector,
  customerByIdSelector,
  agentByIdSelector,
} from '../selectors';
import { updateCustomer } from '../actions/entry';
import Customers from '../components/Customers';
// import { addTodoSellitem } from '../actions';

const mapStateToProps = (state) => {
  const { customerId } = pathSelector(state);
  // const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  // const isAdmin = isAdminSelector(state);
  // const isSaleAdmin = isSaleAdminSelector(state);

  let sellitems = [];
  let customer = {};

  if (customerId) {
    sellitems = sellitemsByCustomerIdSelector(state, customerId);

    sellitems = sellitems.map((si) => {
      let payments = paymentsBySellitemIdSelector(state, si.id);
      const { name: agentSelledName } = agentByIdSelector(state, si.agentId);

      const totalPaid = payments
        .filter((pm) => pm.status === PaymentStatuses.FINISH.name)
        .reduce((sum, { amount }) => sum + amount * 1, 0);
      payments = payments.map((pm) => {
        if (pm.status === PaymentStatuses.FINISH.name) {
          return {
            amount: pm.amount,
            gotDate: pm?.gotDate ? format(new Date(pm.gotDate), 'dd/MM/yyyy') : '',
          };
        }
        return {
          amount: 0,
          gotDate: '',
        };
      });

      const totalDiscount =
        si.discount1 * 1 +
        si.discount2 * 1 +
        si.discount3 * 1 +
        si.discount4 * 1 +
        si.discount5 * 1;
      const totalWithoutDiscount = si.total - totalDiscount;

      let discountMore = 0;
      if (si.loanPercent && si.loanAmount) {
        discountMore = Math.round(((si.loanPercent * 1) / 100) * si.loanAmount * 1);
      }

      return {
        ...si,
        statusLabel: Statuses[si.status.toUpperCase()]?.label,
        agentSelledName,
        totalDiscount,
        totalWithoutDiscount,
        totalPaid,
        discountMore,
        remain: si.total * 1 - totalPaid * 1,
        totalLandPrice: si.product.landArea * si.product.landPrice * si.product.factor,
        totalFloorPrice: si.product.floorArea * si.product.floorPrice,
        payments,
      };
    });

    customer = customerByIdSelector(state, customerId);
  }

  console.log('sellitems:', sellitems);

  const totalProduct = sellitems.length;
  const totalProductAmount = sellitems.reduce((amount, current) => {
    return amount + current.sellPrice * 1;
  }, 0);
  const totalDiscount = sellitems.reduce((amount, current) => {
    return amount + current.totalDiscount * 1;
  }, 0);
  const totalAmountPaid = sellitems.reduce((amount, current) => {
    return amount + current.totalPaid * 1;
  }, 0);
  const totalAmountNotPaid = sellitems.reduce((amount, current) => {
    return amount + current.remain * 1;
  }, 0);

  return {
    customer: {
      ...customer,
      totalProduct,
      totalProductAmount,
      totalDiscount,
      totalAmountPaid,
      totalAmountNotPaid,
    },
    sellitems,
    totalProductAmount,
    // canEdit: isAdmin || (isCurrentUserMember && isSaleAdmin),
    canEdit: false,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdate: (id, data) => updateCustomer(id, data),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
