import EntryActionTypes from '../../constants/EntryActionTypes';

export const addStatusToFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    key,
  },
});

export const removeStatusFromFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    key,
  },
});

export const addBagStatusToFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.BAG_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    key,
  },
});

export const removeBagStatusFromFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.BAG_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    key,
  },
});

export const addProductStatusToFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.PRODUCT_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    key,
  },
});

export const removeProductStatusFromFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.PRODUCT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    key,
  },
});

export const removeAllProductStatusFromFilterInCurrentGroup = () => ({
  type: EntryActionTypes.PRODUCT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE_ALL,
  payload: {},
});
