import EntryActionTypes from '../../constants/EntryActionTypes';

export const searchProducts = (query) => ({
  type: EntryActionTypes.PRODUCTS_SEARCH,
  payload: {
    query,
  },
});

export const fetchProducts = () => ({
  type: EntryActionTypes.PRODUCTS_FETCH,
  payload: {},
});
