import ActionTypes from '../constants/ActionTypes';

export const createSellitemCustomer = (sellitemCustomer) => ({
  type: ActionTypes.CUSTOMER_TO_SELLITEM_ADD,
  payload: {
    sellitemCustomer,
  },
});

createSellitemCustomer.success = (localId, sellitemCustomer) => ({
  type: ActionTypes.CUSTOMER_TO_SELLITEM_ADD__SUCCESS,
  payload: {
    localId,
    sellitemCustomer,
  },
});

createSellitemCustomer.failure = (localId, error) => ({
  type: ActionTypes.CUSTOMER_TO_SELLITEM_ADD__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleSellitemCustomerCreate = (sellitemCustomer, customers) => ({
  type: ActionTypes.CUSTOMER_TO_SELLITEM_ADD_HANDLE,
  payload: {
    sellitemCustomer,
    customers,
  },
});

export const deleteSellitemCustomer = (sellitemId, customerId) => ({
  type: ActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE,
  payload: {
    sellitemId,
    customerId,
  },
});

deleteSellitemCustomer.success = (sellitemCustomer) => ({
  type: ActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE__SUCCESS,
  payload: {
    sellitemCustomer,
  },
});

deleteSellitemCustomer.failure = (id, error) => ({
  type: ActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleSellitemCustomerDelete = (sellitemCustomer) => ({
  type: ActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE_HANDLE,
  payload: {
    sellitemCustomer,
  },
});
