import React from 'react';
import PropTypes from 'prop-types';

// import GroupsContainer from '../../containers/GroupsContainer';
import ProjectSettingsModalContainer from '../../containers/ProjectSettingsModalContainer';

// import styles from './Project.module.scss';

const Project = React.memo(({ isSettingsModalOpened }) => {
  return (
    <>
      {/* <div className={styles.wrapper}>
        <GroupsContainer />
      </div> */}
      {isSettingsModalOpened && <ProjectSettingsModalContainer />}
    </>
  );
});

Project.propTypes = {
  isSettingsModalOpened: PropTypes.bool.isRequired,
};

export default Project;
