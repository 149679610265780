import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Payment';

  static fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    type: attr(),
    percent: attr(),
    amount: attr(),
    refNo: attr(),
    dueDate: attr(),
    gotDate: attr(),
    status: attr(),
    accountId: fk({
      to: 'Account',
      as: 'account',
      relatedName: 'payments',
    }),
    sellitemId: fk({
      to: 'Sellitem',
      as: 'sellitem',
      relatedName: 'payments',
    }),
  };

  static reducer({ type, payload }, Payment) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        if (payload.payments) {
          payload.payments.forEach((payment) => {
            Payment.upsert(payment);
          });
        }

        break;
      case ActionTypes.PAYMENT_INIT_HANDLE:
        if (payload.payments) {
          const { sellitemId } = payload.payments[0];
          Payment.filter({ sellitemId }).delete();
          payload.payments.forEach((payment) => {
            Payment.upsert(payment);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Payment.all().delete();

        if (payload.payments) {
          payload.payments.forEach((payment) => {
            Payment.upsert(payment);
          });
        }

        break;
      case ActionTypes.CUSTOMERS_FETCH__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        if (payload.payments) {
          payload.payments.forEach((payment) => {
            Payment.upsert(payment);
          });
        }

        break;
      case ActionTypes.PAYMENT_CREATE:
      case ActionTypes.PAYMENT_CREATE_HANDLE:
      case ActionTypes.PAYMENT_UPDATE__SUCCESS:
      case ActionTypes.PAYMENT_UPDATE_HANDLE:
        Payment.upsert(payload.payment);

        break;
      case ActionTypes.PAYMENT_CREATE__SUCCESS:
        Payment.withId(payload.localId).delete();
        Payment.upsert(payload.payment);

        break;
      case ActionTypes.PAYMENT_UPDATE:
        Payment.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.PAYMENT_DELETE:
        Payment.withId(payload.id).delete();

        break;
      case ActionTypes.PAYMENT_DELETE__SUCCESS:
      case ActionTypes.PAYMENT_DELETE_HANDLE: {
        const paymentModel = Payment.withId(payload.payment.id);

        if (paymentModel) {
          paymentModel.delete();
        }

        break;
      }
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('dueDate', true);
  }
}
