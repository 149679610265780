import ActionTypes from '../constants/ActionTypes';

const initialState = {
  query: '',
  customers: [],
  isSubmitting: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.CUSTOMERS_FETCH:
      return {
        ...initialState,
        query: payload.query,
        isSubmitting: true,
      };
    case ActionTypes.CUSTOMERS_FETCH__SUCCESS:
      return {
        ...state,
        ...payload,
        query: state.query,
        isSubmitting: false,
      };
    case ActionTypes.CUSTOMERS_FETCH__FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return state;
  }
};
