import ActionTypes from '../constants/ActionTypes';

export const createAgent = (agent) => ({
  type: ActionTypes.AGENT_CREATE,
  payload: {
    agent,
  },
});

createAgent.success = (localId, agent) => ({
  type: ActionTypes.AGENT_CREATE__SUCCESS,
  payload: {
    localId,
    agent,
  },
});

createAgent.failure = (localId, error) => ({
  type: ActionTypes.AGENT_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleAgentCreate = (agent) => ({
  type: ActionTypes.AGENT_CREATE_HANDLE,
  payload: {
    agent,
  },
});

export const updateAgent = (id, data) => ({
  type: ActionTypes.AGENT_UPDATE,
  payload: {
    id,
    data,
  },
});

updateAgent.success = (agent) => ({
  type: ActionTypes.AGENT_UPDATE__SUCCESS,
  payload: {
    agent,
  },
});

updateAgent.failure = (id, error) => ({
  type: ActionTypes.AGENT_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleAgentUpdate = (agent) => ({
  type: ActionTypes.AGENT_UPDATE_HANDLE,
  payload: {
    agent,
  },
});

export const deleteAgent = (id) => ({
  type: ActionTypes.AGENT_DELETE,
  payload: {
    id,
  },
});

deleteAgent.success = (agent) => ({
  type: ActionTypes.AGENT_DELETE__SUCCESS,
  payload: {
    agent,
  },
});

deleteAgent.failure = (id, error) => ({
  type: ActionTypes.AGENT_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleAgentDelete = (agent) => ({
  type: ActionTypes.AGENT_DELETE_HANDLE,
  payload: {
    agent,
  },
});

export const addAgentToGroupFilter = (id, groupId) => ({
  type: ActionTypes.AGENT_TO_GROUP_FILTER_ADD,
  payload: {
    id,
    groupId,
  },
});

export const removeAgentFromGroupFilter = (id, groupId) => ({
  type: ActionTypes.AGENT_FROM_GROUP_FILTER_REMOVE,
  payload: {
    id,
    groupId,
  },
});
