import { fetch } from 'whatwg-fetch';
import Config from '../constants/Config';

/* Actions */

const createAccessTokenImgur = () => {
  const data = {
    ...Config.IMGUR_CONFIG,
    grant_type: 'refresh_token',
  };

  const formData = Object.keys(data).reduce((result, key) => {
    result.append(key, data[key]);

    return result;
  }, new FormData());

  return fetch(`https://api.imgur.com/oauth2/token`, {
    method: 'POST',
    body: formData,
  })
    .then((response) =>
      response.json().then((body) => ({
        body,
        isError: response.status !== 200,
      })),
    )
    .then(({ body, isError }) => {
      if (isError) {
        throw body;
      }

      return body;
    });
};

export default {
  createAccessTokenImgur,
};
