import { all, takeEvery } from 'redux-saga/effects';

import {
  createMembershipInCurrentGroupService,
  deleteGroupMembershipService,
  handleGroupMembershipCreateService,
  handleGroupMembershipDeleteService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* groupMembershipWatchers() {
  yield all([
    takeEvery(EntryActionTypes.MEMBERSHIP_IN_CURRENT_GROUP_CREATE, ({ payload: { data } }) =>
      createMembershipInCurrentGroupService(data),
    ),
    takeEvery(EntryActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE, ({ payload: { groupMembership } }) =>
      handleGroupMembershipCreateService(groupMembership),
    ),
    takeEvery(EntryActionTypes.GROUP_MEMBERSHIP_DELETE, ({ payload: { id } }) =>
      deleteGroupMembershipService(id),
    ),
    takeEvery(EntryActionTypes.GROUP_MEMBERSHIP_DELETE_HANDLE, ({ payload: { groupMembership } }) =>
      handleGroupMembershipDeleteService(groupMembership),
    ),
  ]);
}
