import ActionTypes from '../constants/ActionTypes';

export const createLabel = (label) => ({
  type: ActionTypes.LABEL_CREATE,
  payload: {
    label,
  },
});

createLabel.success = (localId, label) => ({
  type: ActionTypes.LABEL_CREATE__SUCCESS,
  payload: {
    localId,
    label,
  },
});

createLabel.failure = (localId, error) => ({
  type: ActionTypes.LABEL_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleLabelCreate = (label) => ({
  type: ActionTypes.LABEL_CREATE_HANDLE,
  payload: {
    label,
  },
});

export const updateLabel = (id, data) => ({
  type: ActionTypes.LABEL_UPDATE,
  payload: {
    id,
    data,
  },
});

updateLabel.success = (label) => ({
  type: ActionTypes.LABEL_UPDATE__SUCCESS,
  payload: {
    label,
  },
});

updateLabel.failure = (id, error) => ({
  type: ActionTypes.LABEL_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleLabelUpdate = (label) => ({
  type: ActionTypes.LABEL_UPDATE_HANDLE,
  payload: {
    label,
  },
});

export const deleteLabel = (id) => ({
  type: ActionTypes.LABEL_DELETE,
  payload: {
    id,
  },
});

deleteLabel.success = (label) => ({
  type: ActionTypes.LABEL_DELETE__SUCCESS,
  payload: {
    label,
  },
});

deleteLabel.failure = (id, error) => ({
  type: ActionTypes.LABEL_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleLabelDelete = (label) => ({
  type: ActionTypes.LABEL_DELETE_HANDLE,
  payload: {
    label,
  },
});

export const addLabelToSellitem = (id, sellitemId) => ({
  type: ActionTypes.LABEL_TO_SELLITEM_ADD,
  payload: {
    id,
    sellitemId,
  },
});

addLabelToSellitem.success = (sellitemLabel) => ({
  type: ActionTypes.LABEL_TO_SELLITEM_ADD__SUCCESS,
  payload: {
    sellitemLabel,
  },
});

addLabelToSellitem.failure = (id, sellitemId, error) => ({
  type: ActionTypes.LABEL_TO_SELLITEM_ADD__FAILURE,
  payload: {
    id,
    sellitemId,
    error,
  },
});

export const handleLabelToSellitemAdd = (sellitemLabel) => ({
  type: ActionTypes.LABEL_TO_SELLITEM_ADD_HANDLE,
  payload: {
    sellitemLabel,
  },
});

export const removeLabelFromSellitem = (id, sellitemId) => ({
  type: ActionTypes.LABEL_FROM_SELLITEM_REMOVE,
  payload: {
    id,
    sellitemId,
  },
});

removeLabelFromSellitem.success = (sellitemLabel) => ({
  type: ActionTypes.LABEL_FROM_SELLITEM_REMOVE__SUCCESS,
  payload: {
    sellitemLabel,
  },
});

removeLabelFromSellitem.failure = (id, sellitemId, error) => ({
  type: ActionTypes.LABEL_FROM_SELLITEM_REMOVE__FAILURE,
  payload: {
    id,
    sellitemId,
    error,
  },
});

export const handleLabelFromSellitemRemove = (sellitemLabel) => ({
  type: ActionTypes.LABEL_FROM_SELLITEM_REMOVE_HANDLE,
  payload: {
    sellitemLabel,
  },
});

export const addLabelToGroupFilter = (id, groupId) => ({
  type: ActionTypes.LABEL_TO_GROUP_FILTER_ADD,
  payload: {
    id,
    groupId,
  },
});

export const removeLabelFromGroupFilter = (id, groupId) => ({
  type: ActionTypes.LABEL_FROM_GROUP_FILTER_REMOVE,
  payload: {
    id,
    groupId,
  },
});
