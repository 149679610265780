import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { withPopup } from '../../../lib/popup';
import { Popup } from '../../../lib/custom-ui';

import { useSteps } from '../../../hooks';
import DeleteStep from '../../DeleteStep';

import styles from './ActionsPopup.module.scss';
import EditStep from './EditStep';

const StepTypes = {
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

const ActionsStep = React.memo(
  ({ defaultData, isClosed, onCreate, onUpdate, onDelete, onClose }) => {
    const [t] = useTranslation();
    const [step, openStep, handleBack] = useSteps();

    const handleUpdate = useCallback(() => {
      onUpdate({ isClosed: !isClosed });
      onClose();
    }, [isClosed, onClose, onUpdate]);

    const handleUpdateClick = useCallback(() => {
      openStep(StepTypes.UPDATE);
    }, [openStep]);

    const handleDeleteClick = useCallback(() => {
      openStep(StepTypes.DELETE);
    }, [openStep]);

    if (step) {
      switch (step.type) {
        case StepTypes.UPDATE:
          return (
            <EditStep
              title="action.editDiscount"
              defaultData={defaultData}
              onCreate={onCreate}
              onUpdate={onUpdate}
              onBack={handleBack}
              onClose={onClose}
            />
          );
        case StepTypes.DELETE:
          return (
            <DeleteStep
              title={t('action.deleteDiscount', {
                context: 'title',
              })}
              content={t('common.areYouSureYouWantToDeleteThisDiscount')}
              buttonContent={t('action.deleteDiscount')}
              onConfirm={onDelete}
              onBack={handleBack}
            />
          );
        default:
          break;
      }
    }

    return (
      <>
        <Popup.Header>
          {t('common.actions', {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          <Menu secondary vertical className={styles.menu}>
            <Menu.Item className={styles.menuItem} onClick={handleUpdateClick}>
              {t('action.editDiscount', {
                context: 'title',
              })}
            </Menu.Item>
            <Menu.Item className={styles.menuItem} onClick={handleUpdate}>
              {t('action.setClosed', {
                context: 'title',
              })}
            </Menu.Item>
            <Menu.Item className={styles.menuItem} onClick={handleDeleteClick}>
              {t('action.deleteDiscount', {
                context: 'title',
              })}
            </Menu.Item>
          </Menu>
        </Popup.Content>
      </>
    );
  },
);

ActionsStep.propTypes = {
  defaultData: PropTypes.objectOf(PropTypes.shape).isRequired,
  isClosed: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withPopup(ActionsStep);
