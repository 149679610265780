import EntryActionTypes from '../../constants/EntryActionTypes';

export const searchCustomers = (query) => ({
  type: EntryActionTypes.CUSTOMERS_SEARCH,
  payload: {
    query,
  },
});

export const fetchCustomers = () => ({
  type: EntryActionTypes.CUSTOMERS_FETCH,
  payload: {},
});
