import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { lastBagIdByGroupIdSelector, pathSelector } from '../../../selectors';
import { searchBags, fetchBags } from '../../../actions';
import api from '../../../api';

export function* searchBagsService(groupId, query) {
  yield put(searchBags(groupId, query));
  let bags;

  try {
    ({ items: bags } = yield call(request, api.searchBags, groupId, {
      query,
    }));
  } catch (error) {
    yield put(searchBags.failure(groupId, error));
    return;
  }

  yield put(searchBags.success(groupId, bags));
}

export function* searchBagsInCurrentGroupService(query) {
  const { groupId } = yield select(pathSelector);

  yield call(searchBagsService, groupId, query);
}

export function* fetchBagsService(groupId) {
  const lastId = yield select(lastBagIdByGroupIdSelector, groupId);
  yield put(fetchBags(groupId));

  let bags;
  let bagitems;
  let discounts;
  let sellitems;

  try {
    ({
      items: bags,
      included: { bagitems, discounts, sellitems },
    } = yield call(request, api.getBags, groupId, {
      beforeId: lastId,
    }));
  } catch (error) {
    yield put(fetchBags.failure(groupId, error));
    return;
  }

  yield put(fetchBags.success(groupId, bags, bagitems, discounts, sellitems));
}

export function* fetchBagsInCurrentGroupService() {
  const { groupId } = yield select(pathSelector);

  yield call(fetchBagsService, groupId);
}
