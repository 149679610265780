/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, TextArea, Button } from 'semantic-ui-react';
import { Statuses, ProductStatuses } from '../../../constants/Enums';

import styles from './ReviewForm.module.scss';
import { useForm } from '../../../hooks';

const ReviewForm = React.memo(
  ({ type, sellitem, onUpdateSellitem, openModal, onClose, oldStatus, onUpdateProduct }) => {
    const [t] = useTranslation();

    const [error, setError] = useState(false);
    const [data, handleChangeData] = useForm({
      note: '',
    });
    const handleSubmitAgree = useCallback(() => {
      const newData = {
        id: sellitem.id,
        status: type === 'approve' ? Statuses.APPROVED.name : Statuses.CANCELED.name,
        statusNote: data.note || 'Đồng ý',
      };
      if (type === 'cancel') {
        onUpdateProduct({ id: sellitem.productId, status: ProductStatuses.SELLING.name });
      }
      onUpdateSellitem(newData);
      onClose();
    }, [data.note, onClose, onUpdateSellitem, onUpdateProduct, sellitem, type]);

    const handleSubmitRefuse = useCallback(() => {
      if (!data.note) {
        setError(true);
        return;
      }
      const newData = {
        id: sellitem.id,
        status: type === 'approve' ? Statuses.BOOKING.name : oldStatus,
        statusNote: data.note,
      };
      onUpdateSellitem(newData);
      onClose();
    }, [data.note, oldStatus, onClose, onUpdateSellitem, sellitem.id, type]);

    return (
      <Modal open={openModal} closeIcon size="small" centered={false} onClose={onClose}>
        <Modal.Header>
          {type === 'approve' && t('common.approve')}
          {type === 'cancel' && t('common.approveCancel')}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field required error={error} onFocus={() => setError(false)}>
              <TextArea name="note" onChange={handleChangeData} placeholder={t('common.note')} />
            </Form.Field>
            <Button className={styles.buttonReview} primary onClick={handleSubmitAgree}>
              {t('action.agree')}
            </Button>
            <Button className={styles.buttonReview} color="red" onClick={handleSubmitRefuse}>
              {t('action.refuse')}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  },
);

ReviewForm.propTypes = {
  type: PropTypes.string.isRequired,
  sellitem: PropTypes.objectOf(PropTypes.shape),
  openModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onUpdateSellitem: PropTypes.func.isRequired,
  oldStatus: PropTypes.string,
  onUpdateProduct: PropTypes.func,
};

ReviewForm.defaultProps = {
  sellitem: undefined,
  openModal: undefined,
  oldStatus: undefined,
  onUpdateProduct: undefined,
};

export default ReviewForm;
