import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  sellitemByIdSelector,
  isCurrentUserMemberForCurrentGroupSelector,
  isAdminSelector,
  isSaleAdminSelector,
  pathSelector,
  customersForCurrentSellitemSelector,
} from '../selectors';
import {
  updateSellitem,
  searchCustomers,
  createCustomer,
  createSellitemCustomer,
  deleteSellitemCustomer,
  updateSellitemCustomer,
  updateCustomer,
} from '../actions/entry';
import SearchCustomers from '../components/Sellitems/SearchCustomers';

const mapStateToProps = (state, { isPrimary, disabled, currentValue, disableEdit, onRemove }) => {
  const { customers } = state;
  const { sellitemId } = pathSelector(state);

  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);
  const isSaleAdmin = isSaleAdminSelector(state);
  const sellitem = sellitemByIdSelector(state, sellitemId);
  const sellitemCustomers = customersForCurrentSellitemSelector(state);

  return {
    isAdmin: isAdmin || (isCurrentUserMember && isSaleAdmin),
    isPrimary,
    disabled,
    currentValue,
    disableEdit,
    sellitem,
    onRemove,
    sellitemCustomers,
    searchResults: customers || { isSubmitting: false, customers: [], query: '' },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSearchCustomers: searchCustomers,
      onUpdateSellitem: (data) => updateSellitem(data.id, data),
      onCreateCustomer: createCustomer,
      onUpdateCustomer: (data) => updateCustomer(data.id, data),
      onupdateSellitemCustomer: updateSellitemCustomer,
      onCreateSellitemCustomer: createSellitemCustomer,
      onDeleteSellitemCustomer: (sellitemId, currenCustomerId) =>
        deleteSellitemCustomer(sellitemId, currenCustomerId),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchCustomers);
