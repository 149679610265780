/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, List, Message, Modal } from 'semantic-ui-react';
import { Input } from '../../lib/custom-ui';
import { usePrevious } from '../../lib/hooks';

import { useForm } from '../../hooks';

import styles from './ProductAddModal.module.scss';

const createMessage = (error) => {
  if (!error) {
    return error;
  }

  switch (error) {
    default:
      return {
        type: 'warning',
        content: 'common.unknownError',
      };
  }
};

const ProductAddModal = React.memo(
  ({
    blocks,
    agents,
    categories,
    defaultData,
    isSubmitting,
    error,
    onCreate,
    onMessageDismiss,
    onClose,
  }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [data, handleFieldChange, setData] = useForm(() => ({
      ...defaultData,
    }));

    const message = useMemo(() => createMessage(error), [error]);

    const nameField = useRef(null);

    const [errors, setErrors] = useState({
      blockId: false,
      name: false,
      landNumber: false,
      houseForm: false,
      certificate: false,
      street: false,
      direction: false,
      density: false,
      landArea: false,
      floorArea: false,
      landPrice: false,
      factor: false,
      legalStatus: false,
      agentId: false,
      categoryId: false,
    });

    const blockOptions = useMemo(
      () =>
        blocks.map((item) => ({
          key: item.id,
          value: item.id,
          text: item.name,
        })),
      [blocks],
    );

    const agentOptions = useMemo(
      () =>
        agents.map((item) => ({
          key: item.id,
          value: item.id,
          text: item.name,
        })),
      [agents],
    );

    const categoryOptions = useMemo(
      () =>
        categories.map((item) => ({
          key: item.id,
          value: item.id,
          text: item.name,
        })),
      [categories],
    );

    // if (!data.name && data.blockId) {
    //   // console.log(data.blockId);
    //   // setData((prevData) => ({
    //   //   ...prevData,
    //   //   name: blockOptions.find((it) => it.value === data.blockId)?.text,
    //   // }));
    //   nameField.current.focus();
    // }

    const handleSubmit = useCallback(() => {
      const cleanData = {
        ...data,
        name: data.name.trim(),
        description: data.description.trim() || null,
        landNumber: data.landNumber.trim() || null,
        landDescription: data.landDescription.trim() || null,
        mapNumber: data.mapNumber.trim() || null,
        houseForm: data.houseForm.trim() || null,
        certificate: data.certificate.trim() || null,
        street: data.street.trim() || null,
        buildingLine: data.buildingLine.trim() || null,
        direction: data.direction.trim() || null,
      };

      const err = {
        blockId: !cleanData.blockId,
        name: !cleanData.name,
        landNumber: !cleanData.landNumber,
        certificate: !cleanData.certificate,
        street: !cleanData.street,
        direction: !cleanData.direction,
        density: cleanData.density === '' || Number.isNaN(cleanData.density),
        landArea: cleanData.landArea === '' || Number.isNaN(cleanData.landArea),
        floorArea: cleanData.floorArea === '' || Number.isNaN(cleanData.floorArea),
        landPrice: cleanData.landPrice === '' || Number.isNaN(cleanData.landPrice),
        factor: cleanData.factor === '' || Number.isNaN(cleanData.factor),
        agentId: !cleanData.agentId,
        categoryId: !cleanData.categoryId,
      };
      // console.log(err);
      setErrors(err);

      if (Object.values(err).some((e) => e)) {
        return;
      }

      onCreate(cleanData);
    }, [data, onCreate]);

    useEffect(() => {
      if (wasSubmitting && !isSubmitting) {
        if (!error) {
          onClose();
        }
      }
    }, [wasSubmitting, error, isSubmitting, onClose]);

    return (
      <Modal open closeIcon size="large" onClose={onClose} className={styles.addProductPopup}>
        <Modal.Header>
          {t('common.addProduct', {
            context: 'title',
          })}
        </Modal.Header>
        <Modal.Content>
          {message && (
            <Message
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                [message.type]: true,
              }}
              visible
              content={t(message.content)}
              onDismiss={onMessageDismiss}
            />
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <Form.Field
                required
                error={errors.blockId}
                onFocus={() => setErrors({ ...errors, blockId: false })}
              >
                <label htmlFor="blockId">{t('common.block')}</label>
                <Form.Select
                  name="blockId"
                  className={styles.field}
                  placeholder={t('common.selectBlock')}
                  search
                  fluid
                  selection
                  value={data.blockId}
                  options={blockOptions}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.name}
                onFocus={() => setErrors({ ...errors, name: false })}
              >
                <label htmlFor="name">{t('common.name')}</label>
                <Input
                  fluid
                  ref={nameField}
                  name="name"
                  value={data.name}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.categoryId}
                onFocus={() => setErrors({ ...errors, categoryId: false })}
              >
                <label htmlFor="category">{t('common.category')}</label>
                <Form.Select
                  name="categoryId"
                  className={styles.field}
                  placeholder={t('common.selectCategory')}
                  search
                  fluid
                  selection
                  value={data.categoryId}
                  options={categoryOptions}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field />
              <Form.Field />
              <Form.Field />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                required
                error={errors.landNumber}
                onFocus={() => setErrors({ ...errors, landNumber: false })}
              >
                <label htmlFor="landNumber">{t('common.landNumber')}</label>
                <Input
                  fluid
                  name="landNumber"
                  value={data.landNumber}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="houseForm">{t('common.houseForm')}</label>
                <Input
                  fluid
                  name="houseForm"
                  value={data.houseForm}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.certificate}
                onFocus={() => setErrors({ ...errors, certificate: false })}
              >
                <label htmlFor="certificate">{t('common.certificate')}</label>
                <Input
                  fluid
                  name="certificate"
                  value={data.certificate}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.street}
                onFocus={() => setErrors({ ...errors, street: false })}
              >
                <label htmlFor="street">{t('common.street')}</label>
                <Input
                  fluid
                  name="street"
                  value={data.street}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.direction}
                onFocus={() => setErrors({ ...errors, direction: false })}
              >
                <label htmlFor="direction">{t('common.direction')}</label>
                <Input
                  fluid
                  name="direction"
                  value={data.direction}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.density}
                onFocus={() => setErrors({ ...errors, density: false })}
              >
                <label htmlFor="density">{t('common.density')}</label>
                <Input
                  fluid
                  name="density"
                  value={data.density}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                required
                error={errors.landArea}
                onFocus={() => setErrors({ ...errors, landArea: false })}
              >
                <label htmlFor="landArea">{t('common.landArea')}</label>
                <Input
                  fluid
                  name="landArea"
                  value={data.landArea}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.landPrice}
                onFocus={() => setErrors({ ...errors, landPrice: false })}
              >
                <label htmlFor="landPrice">{t('common.landPrice')}</label>
                <Input
                  fluid
                  name="landPrice"
                  value={data.landPrice}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.floorArea}
                onFocus={() => setErrors({ ...errors, floorArea: false })}
              >
                <label htmlFor="floorArea">{t('common.floorArea')}</label>
                <Input
                  fluid
                  name="floorArea"
                  value={data.floorArea}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.floorPrice}
                onFocus={() => setErrors({ ...errors, floorPrice: false })}
              >
                <label htmlFor="floorPrice">{t('common.floorPrice')}</label>
                <Input
                  fluid
                  name="floorPrice"
                  value={data.floorPrice}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="description">{t('common.properties')}</label>
                <Input
                  fluid
                  name="description"
                  value={data.description}
                  readOnly={isSubmitting}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.agentId}
                onFocus={() => setErrors({ ...errors, agentId: false })}
              >
                <label htmlFor="agent">{t('common.agent')}</label>
                <Form.Select
                  name="agentId"
                  className={styles.field}
                  placeholder={t('common.selectAgent')}
                  search
                  fluid
                  selection
                  value={data.agentId}
                  options={agentOptions}
                  onChange={handleFieldChange}
                />
              </Form.Field>
            </Form.Group>
            <div className={styles.summaryWrapper}>
              <div className={styles.summary}>
                <List verticalAlign="middle">
                  <List.Item>
                    <List.Content floated="right">
                      {(data.landArea * data.landPrice * data.factor).toLocaleString('en-GB')}
                    </List.Content>
                    <List.Content>{t('common.landTotal')}</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content floated="right">
                      {(data.floorArea * data.floorPrice).toLocaleString('en-GB')}
                    </List.Content>
                    <List.Content>{t('common.floorTotal')}</List.Content>
                  </List.Item>
                  <hr />
                  <List.Item>
                    <List.Content floated="right">
                      {(
                        data.landArea * data.landPrice * data.factor +
                        data.floorArea * data.floorPrice
                      ).toLocaleString('en-GB')}
                    </List.Content>
                    <List.Content>{t('common.total')}</List.Content>
                  </List.Item>
                </List>
              </div>
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button positive content={t('action.save')} onClick={handleSubmit} />
        </Modal.Actions>
      </Modal>
    );
  },
);

ProductAddModal.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object()).isRequired,
  agents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  defaultData: PropTypes.objectOf(PropTypes.shape).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onMessageDismiss: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAttachmentCreate: PropTypes.func.isRequired,
};

ProductAddModal.defaultProps = {
  error: undefined,
};

export default ProductAddModal;
