import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  isAdminSelector,
  isSaleAdminSelector,
  bagIdsForCurrentGroupSelector,
  currentBagSelector,
} from '../selectors';
import { searchProducts, createBag } from '../actions/entry';

import LeftSidebar from '../components/Products/LeftSidebar';

const mapStateToProps = (state) => {
  const bagIds = bagIdsForCurrentGroupSelector(state);
  const bag = currentBagSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);
  const isSaleAdmin = isSaleAdminSelector(state);

  const { products } = state;

  return {
    bagIds,
    bag,
    canEdit: isAdmin || (isCurrentUserMember && isSaleAdmin),
    searchResults: products || { isSubmitting: false, products: [], query: '' },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onBagCreate: createBag,
      onSearchProducts: (query) => searchProducts(query),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
