import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { isLocalId } from '../utils/local-id';

export const makeAllCustomerSelector = createSelector(orm, ({ Customer }) =>
  Customer.all().orderBy('name').toRefArray(),
);

export const makeCustomerByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Customer }, id) => {
      const customerModel = Customer.withId(id);

      if (!customerModel) {
        return customerModel;
      }

      return {
        ...customerModel.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const customerByIdSelector = makeCustomerByIdSelector();

export const currentCustomerSelector = createSelector(
  orm,
  (state) => pathSelector(state).customerId,
  ({ Customer }, id) => {
    if (!id) {
      return id;
    }

    const customerModel = Customer.withId(id);

    if (!customerModel) {
      return customerModel;
    }

    return customerModel.ref;
  },
);

export const makeSellitemIdsByCustomerIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Customer }, id) => {
      const customerModel = Customer.withId(id);

      if (!customerModel) {
        return customerModel;
      }

      return customerModel
        .getOrderedFilteredSellitemsModelArray()
        .map((sellitemModel) => sellitemModel.id);
    },
  );

export const sellitemIdsByCustomerIdSelector = makeSellitemIdsByCustomerIdSelector();

export const makeSellitemsByCustomerIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Customer }, id) => {
      const customerModel = Customer.withId(id);

      if (!customerModel) {
        return customerModel;
      }

      return customerModel.getOrderedFilteredSellitemsModelArray().map((sellitemModel) => ({
        ...sellitemModel.ref,
        creator: sellitemModel.creator?.ref.name,
        // customer: sellitemModel.customer.ref.name,
        customers: sellitemModel.customers.toRefArray(),
        agent: sellitemModel.product?.agent.ref,
        product: sellitemModel.product?.ref,
      }));
    },
  );

export const sellitemsByCustomerIdSelector = makeSellitemsByCustomerIdSelector();

export const paymentsForCurrentCustomerSelector = createSelector(
  orm,
  (state) => pathSelector(state).customerId,
  ({ Customer }, id) => {
    if (!id) {
      return id;
    }

    const customerModel = Customer.withId(id);

    if (!customerModel) {
      return customerModel;
    }

    return customerModel
      .getOrderedPaymentsQuerySet()
      .toRefArray()
      .map((payment) => ({
        ...payment,
        isPersisted: !isLocalId(payment.id),
      }));
  },
);

export const paymentsCustomerIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ Customer }, id) => {
    if (!id) {
      return id;
    }

    const customerModel = Customer.withId(id);

    if (!customerModel) {
      return customerModel;
    }

    return customerModel
      .getOrderedPaymentsQuerySet()
      .toRefArray()
      .map((payment) => ({
        ...payment,
        isPersisted: !isLocalId(payment.id),
      }));
  },
);
