import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  attachmentsForCurrentSellitemSelector,
  pathSelector,
  sellitemByIdSelector,
} from '../selectors';
import {
  updateSellitem,
  createAttachmentInCurrentSellitem,
  updateAttachment,
  deleteAttachment,
} from '../actions/entry';

import SellitemAttachments from '../components/Sellitems/Attachments';

const mapStateToProps = (state) => {
  const { sellitemId } = pathSelector(state);
  const { status } = sellitemByIdSelector(state, sellitemId);
  const attachments = attachmentsForCurrentSellitemSelector(state);

  return {
    status,
    items: attachments,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdateSellitem: (id, data) => updateSellitem(id, data),
      onAttachmentCreate: createAttachmentInCurrentSellitem,
      onAttachmentUpdate: updateAttachment,
      onAttachmentDelete: deleteAttachment,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SellitemAttachments);
