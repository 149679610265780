import { takeEvery, all } from 'redux-saga/effects';

import {
  searchProductsInCurrentGroupService,
  fetchProductsInCurrentGroupService,
  handleProductImportService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* productsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.PRODUCTS_SEARCH, ({ payload: { query } }) =>
      searchProductsInCurrentGroupService(query),
    ),
    takeEvery(EntryActionTypes.PRODUCTS_FETCH, ({ payload: { query } }) =>
      fetchProductsInCurrentGroupService(query),
    ),
    takeEvery(EntryActionTypes.PRODUCTS_IMPORT_HANDLE, ({ payload: { products, bagitems } }) =>
      handleProductImportService(products, bagitems),
    ),
  ]);
}
