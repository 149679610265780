import ActionTypes from '../../constants/ActionTypes';
import { ProductStatuses } from '../../constants/Enums';

const initialState = {
  data: {
    blockId: null,
    agentId: null,
    categoryId: null,
    name: '',
    description: '',
    landNumber: '',
    landDescription: '',
    mapNumber: '',
    houseForm: '',
    certificate: '',
    street: '',
    buildingLine: '',
    direction: '',
    density: 0.0,
    width: 0,
    length: 0,
    landArea: 0.0,
    floorArea: 0.0,
    landPrice: 0.0,
    floorPrice: 0.0,
    factor: 1.0,
    status: ProductStatuses.SELLING.name,
    legalStatus: null,
  },
  isSubmitting: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.PRODUCT_CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        isSubmitting: true,
      };
    case ActionTypes.PRODUCT_CREATE__SUCCESS:
      return initialState;
    case ActionTypes.PRODUCT_CREATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
      };
    case ActionTypes.USER_CREATE_ERROR_CLEAR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
