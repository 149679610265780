/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Label, Segment } from 'semantic-ui-react';
import HotTable, { HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';

import AddPopup from './AddPopup';
import SearchCustomers from '../SearchCustomers';
import ActionsPopup from './ActionsPopup';
import { ReactComponent as DotIcon } from '../../assets/images/dots-vertical.svg';
import Paths from '../../constants/Paths';

import styles from './LeftSidebar.module.scss';

const LeftSidebar = React.memo(
  ({
    customerId,
    customers,
    searchCustomerResults,
    canEdit,
    onCustomerCreate,
    onSearchCustomers,
  }) => {
    const [t] = useTranslation();
    const history = useHistory();

    const hotTableRef = useRef();

    const handleResultSelect = useCallback(
      ({ id }) => {
        const groupId = customers.find((c) => c.id === id)?.groupId;
        history.push(Paths.CUSTOMERS.replace(':groupId', groupId).replace(':id', id));
      },
      [customers, history],
    );

    const afterSelection = useCallback(
      (row) => {
        const id = hotTableRef.current.hotInstance.getDataAtRowProp(row, 'id');
        const groupId = customers.find((c) => c.id === id)?.groupId;
        if (id !== customerId) {
          history.push(Paths.CUSTOMERS.replace(':groupId', groupId).replace(':id', id));
        }
      },
      [customerId, customers, history],
    );

    const itemRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      /* eslint-disable no-param-reassign */
      if (value) {
        const id = instance.getDataAtRowProp(row, 'id');
        const phone = instance.getDataAtRowProp(row, 'phone');

        const div = document.createElement('div');
        div.classList.add(styles.item);
        ReactDOM.render(
          <>
            {value}
            <br />
            <span className={styles.phone}>{phone}</span>
          </>,
          div,
        );
        Handsontable.dom.empty(td);
        td.appendChild(div);
        if (id === customerId) td.classList.add(styles.current);
      }
      /* eslint-enable no-param-reassign */
      return td;
    };

    console.log('canEdit:', canEdit);

    return (
      <Segment className={styles.sidebarLeft}>
        <div className={styles.wrapperSearchCustomers}>
          <SearchCustomers
            searchResults={searchCustomerResults}
            onSearchCustomers={onSearchCustomers}
            onCustomerSelected={handleResultSelect}
          />
          {canEdit && (
            <>
              <AddPopup onCreate={onCustomerCreate}>
                <Button circular size="mini" icon="user add" className={styles.addCustomerButton} />
              </AddPopup>
              <ActionsPopup>
                <div className={styles.actions}>
                  <DotIcon className={styles.dotIcon} />
                </div>
              </ActionsPopup>
            </>
          )}
        </div>
        <div className={styles.wrapperCustomers}>
          <HotTable
            ref={hotTableRef}
            data={customers}
            height="100%"
            stretchH="all"
            autoColumnSize={false}
            autoRowSize={false}
            disableVisualSelection
            readOnly
            colHeaders={false}
            rowHeaders={false}
            rowHeights={43}
            enterMoves={{ col: 1, row: 0 }}
            selectionMode="single"
            afterSelection={afterSelection}
            licenseKey="non-commercial-and-evaluation"
          >
            <HotColumn data="name" renderer={itemRenderer} />
          </HotTable>
        </div>
      </Segment>
    );
  },
);

LeftSidebar.propTypes = {
  customerId: PropTypes.string,
  customers: PropTypes.arrayOf(PropTypes.object()).isRequired,
  searchCustomerResults: PropTypes.objectOf(PropTypes.shape).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onCustomerCreate: PropTypes.func.isRequired,
  onSearchCustomers: PropTypes.func.isRequired,
};

LeftSidebar.defaultProps = {
  customerId: undefined,
};

export default LeftSidebar;
