import EntryActionTypes from '../../constants/EntryActionTypes';

export const createSellitemCustomer = (data) => ({
  type: EntryActionTypes.CUSTOMER_TO_SELLITEM_ADD,
  payload: {
    data,
  },
});

export const handleSellitemCustomerCreate = (sellitemCustomer, customers) => ({
  type: EntryActionTypes.CUSTOMER_TO_SELLITEM_ADD_HANDLE,
  payload: {
    sellitemCustomer,
    customers,
  },
});

export const deleteSellitemCustomer = (sellitemId, customerId) => ({
  type: EntryActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE,
  payload: {
    sellitemId,
    customerId,
  },
});

export const handleSellitemCustomerDelete = (customer) => ({
  type: EntryActionTypes.CUSTOMER_FROM_SELLITEM_REMOVE_HANDLE,
  payload: {
    customer,
  },
});

export const updateSellitemCustomer = (data) => ({
  type: EntryActionTypes.CUSTOMER_TO_SELLITEM_UPDATE,
  payload: {
    data,
  },
});
