import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import SellitemsLeftSidebarContainer from '../../containers/SellitemsLeftSidebarContainer';
import SellitemsRightSidebarContainer from '../../containers/SellitemsRightSidebarContainer';
import SellitemAddFormContainer from '../../containers/SellitemAddFormContainer';
import SellitemEditFormContainer from '../../containers/SellitemEditFormContainer';
import SellitemAttachmentsContainer from '../../containers/SellitemAttachmentsContainer';
import SellitemPaymentsContainer from '../../containers/SellitemPaymentsContainer';

import styles from './Sellitems.module.scss';

const Sellitems = React.memo(({ sellitemId, groupId, isAllActionsFetched, onFetchActions }) => {
  useEffect(() => {
    if (!isAllActionsFetched) onFetchActions();
  }, [sellitemId, isAllActionsFetched, onFetchActions]);

  return (
    <div className={styles.wrapperGrid}>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column
            className={styles.sidebarLeft}
            mobile={16}
            tablet={5}
            computer={4}
            largeScreen={3}
            widescreen={3}
          >
            <SellitemsLeftSidebarContainer />
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={11}
            computer={12}
            largeScreen={sellitemId !== groupId ? 10 : 13}
            widescreen={sellitemId !== groupId ? 10 : 13}
          >
            <div className={`scroller ${styles.wrapper}`}>
              {sellitemId !== groupId ? (
                <>
                  <SellitemEditFormContainer />
                  <SellitemPaymentsContainer />
                  <SellitemAttachmentsContainer />
                  <div className="hidden-desktop show-computer">
                    <SellitemsRightSidebarContainer />
                  </div>
                </>
              ) : (
                <SellitemAddFormContainer />
              )}
            </div>
          </Grid.Column>
          {sellitemId !== groupId && (
            <Grid.Column className="show-desktop hidden-computer" largeScreen={3} widescreen={3}>
              <SellitemsRightSidebarContainer />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
});

Sellitems.propTypes = {
  sellitemId: PropTypes.string,
  groupId: PropTypes.string,
  isAllActionsFetched: PropTypes.bool.isRequired,
  onFetchActions: PropTypes.func.isRequired,
};

Sellitems.defaultProps = {
  sellitemId: undefined,
  groupId: undefined,
};

export default Sellitems;
