import { combineReducers } from 'redux';

import authenticateForm from './authenticate-form';
import userCreateForm from './user-create-form';
import projectCreateForm from './project-create-form';
import productCreateForm from './product-create-form';
import importForm from './import-form';
import sellitemCreateForm from './sellitem-create-form';
import bagCommissionForm from './bag-commission-form';

export default combineReducers({
  authenticateForm,
  userCreateForm,
  projectCreateForm,
  productCreateForm,
  importForm,
  sellitemCreateForm,
  bagCommissionForm,
});
