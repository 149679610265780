const ACCESS_TOKEN_KEY = 'accessToken';
const ACCESS_TOKEN_IMGUR_KEY = 'accessTokenImgur';

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const setAccessToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const getAccessTokenImgur = () => localStorage.getItem(ACCESS_TOKEN_IMGUR_KEY);

export const setAccessTokenImgur = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN_IMGUR_KEY, accessToken);
};

export const removeAccessTokenImgur = () => {
  localStorage.removeItem(ACCESS_TOKEN_IMGUR_KEY);
};
