import { call, put, select } from 'redux-saga/effects';

import { logoutService } from './login';
import request from '../request';
import { currentUserIdSelector, currentUserSelector, pathSelector } from '../../../selectors';
import {
  addUserToGroupFilter,
  addUserToSellitem,
  clearUserCreateError,
  clearUserEmailUpdateError,
  clearUserPasswordUpdateError,
  clearUserUsernameUpdateError,
  createUser,
  deleteUser,
  handleUserCreate,
  handleUserDelete,
  handleUserFromSellitemRemove,
  handleUserToSellitemAdd,
  handleUserUpdate,
  removeUserFromGroupFilter,
  removeUserFromSellitem,
  updateUser,
  updateUserAvatar,
  updateUserEmail,
  updateUserPassword,
  updateUserUsername,
} from '../../../actions';
import api from '../../../api';

export function* createUserService(data) {
  yield put(createUser(data));

  let user;
  try {
    ({ item: user } = yield call(request, api.createUser, data));
  } catch (error) {
    yield put(createUser.failure(error));
    return;
  }

  yield put(createUser.success(user));
}

export function* handleUserCreateService(user) {
  yield put(handleUserCreate(user));
}

export function* clearUserCreateErrorService() {
  yield put(clearUserCreateError());
}

export function* updateUserService(id, data) {
  yield put(updateUser(id, data));

  let user;
  try {
    ({ item: user } = yield call(request, api.updateUser, id, data));
  } catch (error) {
    yield put(updateUser.failure(id, error));
    return;
  }

  yield put(updateUser.success(user));
}

export function* updateCurrentUserService(data) {
  const id = yield select(currentUserIdSelector);

  yield call(updateUserService, id, data);
}

export function* handleUserUpdateService(user) {
  const currentUser = yield select(currentUserSelector);
  const isCurrent = user.id === currentUser.id;

  let users;
  if (isCurrent && !currentUser.isAdmin && user.isAdmin) {
    ({ items: users } = yield call(request, api.getUsers));
  }

  if (isCurrent && user.status !== 'active') {
    yield call(logoutService);
  }

  yield put(handleUserUpdate(user, users, isCurrent));
}

export function* updateUserEmailService(id, data) {
  yield put(updateUserEmail(id, data));

  let user;
  try {
    ({ item: user } = yield call(request, api.updateUserEmail, id, data));
  } catch (error) {
    yield put(updateUserEmail.failure(id, error));
    return;
  }

  yield put(updateUserEmail.success(user));
}

export function* updateCurrentUserEmailService(data) {
  const id = yield select(currentUserIdSelector);

  yield call(updateUserEmailService, id, data);
}

export function* clearUserEmailUpdateErrorService(id) {
  yield put(clearUserEmailUpdateError(id));
}

export function* clearCurrentUserEmailUpdateErrorService() {
  const id = yield select(currentUserIdSelector);

  yield call(clearUserEmailUpdateErrorService, id);
}

export function* updateUserPasswordService(id, data) {
  yield put(updateUserPassword(id, data));

  let user;
  try {
    ({ item: user } = yield call(request, api.updateUserPassword, id, data));
  } catch (error) {
    yield put(updateUserPassword.failure(id, error));
    return;
  }

  yield put(updateUserPassword.success(user));
}

export function* updateCurrentUserPasswordService(data) {
  const id = yield select(currentUserIdSelector);

  yield call(updateUserPasswordService, id, data);
}

export function* clearUserPasswordUpdateErrorService(id) {
  yield put(clearUserPasswordUpdateError(id));
}

export function* clearCurrentUserPasswordUpdateErrorService() {
  const id = yield select(currentUserIdSelector);

  yield call(clearUserPasswordUpdateErrorService, id);
}

export function* updateUserUsernameService(id, data) {
  yield put(updateUserUsername(id, data));

  let user;
  try {
    ({ item: user } = yield call(request, api.updateUserUsername, id, data));
  } catch (error) {
    yield put(updateUserUsername.failure(id, error));
    return;
  }

  yield put(updateUserUsername.success(user));
}

export function* updateCurrentUserUsernameService(data) {
  const id = yield select(currentUserIdSelector);

  yield call(updateUserUsernameService, id, data);
}

export function* clearUserUsernameUpdateErrorService(id) {
  yield put(clearUserUsernameUpdateError(id));
}

export function* clearCurrentUserUsernameUpdateErrorService() {
  const id = yield select(currentUserIdSelector);

  yield call(clearUserUsernameUpdateErrorService, id);
}

export function* updateUserAvatarService(id, data) {
  yield put(updateUserAvatar(id));

  let user;
  try {
    ({ item: user } = yield call(request, api.updateUserAvatar, id, data));
  } catch (error) {
    yield put(updateUserAvatar.failure(id, error));
    return;
  }

  yield put(updateUserAvatar.success(user));
}

export function* updateCurrentUserAvatarService(data) {
  const id = yield select(currentUserIdSelector);

  yield call(updateUserAvatarService, id, data);
}

export function* deleteUserService(id) {
  yield put(deleteUser(id));

  let user;
  try {
    ({ item: user } = yield call(request, api.deleteUser, id));
  } catch (error) {
    yield put(deleteUser.failure(id, error));
    return;
  }

  yield put(deleteUser.success(user));
}

export function* handleUserDeleteService(user) {
  const currentUserId = yield select(currentUserIdSelector);

  if (user.id === currentUserId) {
    yield call(logoutService);
  }

  yield put(handleUserDelete(user));
}

export function* addUserToSellitemService(id, sellitemId) {
  const currentUserId = yield select(currentUserIdSelector);

  yield put(addUserToSellitem(id, sellitemId, id === currentUserId));

  let sellitemMembership;
  try {
    ({ item: sellitemMembership } = yield call(request, api.createSellitemMembership, sellitemId, {
      userId: id,
    }));
  } catch (error) {
    yield put(addUserToSellitem.failure(id, sellitemId, error));
    return;
  }

  yield put(addUserToSellitem.success(sellitemMembership));
}

export function* addUserToCurrentSellitemService(id) {
  const { sellitemId } = yield select(pathSelector);

  yield call(addUserToSellitemService, id, sellitemId);
}

export function* handleUserToSellitemAddService(sellitemMembership) {
  yield put(handleUserToSellitemAdd(sellitemMembership));
}

export function* removeUserFromSellitemService(id, sellitemId) {
  yield put(removeUserFromSellitem(id, sellitemId));

  let sellitemMembership;
  try {
    ({ item: sellitemMembership } = yield call(
      request,
      api.deleteSellitemMembership,
      sellitemId,
      id,
    ));
  } catch (error) {
    yield put(removeUserFromSellitem.failure(id, sellitemId, error));
    return;
  }

  yield put(removeUserFromSellitem.success(sellitemMembership));
}

export function* removeUserFromCurrentSellitemService(id) {
  const { sellitemId } = yield select(pathSelector);

  yield call(removeUserFromSellitemService, id, sellitemId);
}

export function* handleUserFromSellitemRemoveService(sellitemMembership) {
  yield put(handleUserFromSellitemRemove(sellitemMembership));
}

export function* addUserToGroupFilterService(id, groupId) {
  yield put(addUserToGroupFilter(id, groupId));
}

export function* addUserToFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(addUserToGroupFilterService, id, groupId);
}

export function* removeUserFromGroupFilterService(id, groupId) {
  yield put(removeUserFromGroupFilter(id, groupId));
}

export function* removeUserFromFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(removeUserFromGroupFilterService, id, groupId);
}
