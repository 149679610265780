import http from './http';
import socket from './socket';
import accessTokens from './access-tokens';
import accessTokensImgur from './access-tokens-imgur';
import users from './users';
import projects from './projects';
import projectManagers from './project-managers';
import agents from './agents';
import categories from './categories';
import accounts from './accounts';
import groups from './groups';
import groupMemberships from './group-memberships';
import labels from './labels';
import blocks from './blocks';
import customers from './customers';
import products from './products';
import bags from './bags';
import bagitems from './bagitems';
import discounts from './discounts';
import sellitems from './sellitems';
import sellitemMemberships from './sellitem-memberships';
import sellitemLabels from './sellitem-labels';
import sellitemCustomers from './sellitem-customers';
import sellitemDiscounts from './sellitem-discounts';
import payments from './payments';
import attachments from './attachments';
import actions from './actions';
import commentActions from './comment-actions';
import notifications from './notifications';
import projectAgents from './project-agents';
import dashboard from './dashboard';

export { http, socket };

export default {
  ...accessTokens,
  ...accessTokensImgur,
  ...users,
  ...projects,
  ...projectManagers,
  ...agents,
  ...categories,
  ...accounts,
  ...groups,
  ...groupMemberships,
  ...labels,
  ...blocks,
  ...customers,
  ...products,
  ...bags,
  ...bagitems,
  ...discounts,
  ...sellitems,
  ...sellitemMemberships,
  ...sellitemLabels,
  ...sellitemCustomers,
  ...sellitemDiscounts,
  ...payments,
  ...attachments,
  ...actions,
  ...commentActions,
  ...notifications,
  ...projectAgents,
  ...dashboard,
};
