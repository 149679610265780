/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Modal, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './ProjectAgentsPopup.module.scss';

const ProjectAgentsProduct = React.memo(({ projectAgents, onClose }) => {
  const [t] = useTranslation();

  return (
    <Modal open closeIcon size="large" onClose={onClose} className={styles.projectAgentsPopup}>
      <Modal.Header>
        {t('common.historyProjectAgents', {
          context: 'title',
        })}
      </Modal.Header>
      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('common.dateAddProjectAgent')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.productName')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.users')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.quantity')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.webPrice')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.exchangeRate')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.note')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {projectAgents.map((projectAgent) => (
              <Table.Row key={projectAgent.id}>
                <Table.Cell>
                  {t('format:longDateTime', {
                    postProcess: 'formatDate',
                    value: new Date(projectAgent.createdAt),
                  })}
                </Table.Cell>
                <Table.Cell>{projectAgent.product?.name}</Table.Cell>
                <Table.Cell>{projectAgent.creator}</Table.Cell>
                <Table.Cell>{projectAgent.quantity}</Table.Cell>
                <Table.Cell>{(projectAgent.webPrice * 1).toLocaleString('en-GB')}</Table.Cell>
                <Table.Cell>{(projectAgent.exchangeRate * 1).toLocaleString('en-GB')}</Table.Cell>
                <Table.Cell>{projectAgent.note}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
});

ProjectAgentsProduct.propTypes = {
  onClose: PropTypes.func.isRequired,
  projectAgents: PropTypes.arrayOf(PropTypes.object()).isRequired,
};

export default ProjectAgentsProduct;
