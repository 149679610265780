import { all, takeEvery } from 'redux-saga/effects';

import {
  createSellitemDiscountService,
  deleteSellitemDiscountService,
  handleSellitemDiscountCreateService,
  handleSellitemDiscountDeleteService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* discountWatchers() {
  yield all([
    takeEvery(EntryActionTypes.DISCOUNT_TO_SELLITEM_ADD, ({ payload: { data } }) =>
      createSellitemDiscountService(data),
    ),
    takeEvery(
      EntryActionTypes.DISCOUNT_TO_SELLITEM_ADD_HANDLE,
      ({ payload: { sellitemDiscount, discounts } }) =>
        handleSellitemDiscountCreateService(sellitemDiscount, discounts),
    ),
    takeEvery(
      EntryActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE,
      ({ payload: { sellitemId, discountId } }) =>
        deleteSellitemDiscountService(sellitemId, discountId),
    ),
    takeEvery(EntryActionTypes.DISCOUNT_FROM_SELLITEM_REMOVE_HANDLE, ({ payload: { discount } }) =>
      handleSellitemDiscountDeleteService(discount),
    ),
  ]);
}
