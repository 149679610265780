/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Input,
  Dropdown,
  Message,
  Checkbox,
  Popup as SemanticUIPopup,
} from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import Label from '../Label';
import ProductModal from '../../ProductModal';
import ReviewForm from '../ReviewForm';
import SearchCustomersContainer from '../../../containers/SearchCustomersContainer';

import { useForm } from '../../../hooks';
import {
  Statuses,
  ProductStatuses,
  PaymentStatuses,
  DiscountTypes,
} from '../../../constants/Enums';
import ConvertNumberToText from '../ConvertNumberToText';
import DeleteStep from '../../DeleteStep';

import styles from './EditForm.module.scss';
import ConfirmForm from '../ConfirmForm';

const EditForm = React.memo(
  ({
    canEdit,
    isManager,
    defaultData,
    products,
    discounts,
    customers,
    agents,
    payments,
    sellitem,
    onUpdateSellitem,
    onUpdateProduct,
    onCreateSellitem,
    onDeleteSellitemCustomer,
    onCreateSellitemDiscount,
    onDeleteSellitemDiscount,
    oldStatus,
  }) => {
    const [t] = useTranslation();

    const product = products.find((pr) => pr.id === sellitem.productId);

    const { landTotalWithoutVAT } = product;

    const [data, handleFieldChange, setData] = useForm({
      ...defaultData,
      discount_1: 0,
      discount_2: 0,
      discount_3: 0,
      discount_4: 0,
      discount5Percent: new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
      }).format((defaultData.discount5 / landTotalWithoutVAT) * 100),
    });

    // const totalDiscount =
    //   data.discount1 * 1 +
    //   data.discount2 * 1 +
    //   data.discount3 * 1 +
    //   data.discount4 * 1 +
    //   data.discount5 * 1;
    // tổng chiết khấu = all chiết khấu trong bảng sellitem-discount + chiết khấu tay(discount5)
    const totalDiscount =
      discounts.reduce((sum, { discountValue }) => sum + discountValue * 1, 0) + data.discount5 * 1;

    const handleChangeDiscount = useCallback(
      (e, dataField) => {
        switch (dataField.name) {
          case 'discount5Percent': {
            const discount5Field = {
              name: 'discount5',
              value: ((dataField.value / 100) * landTotalWithoutVAT).toFixed(0),
            };
            handleFieldChange(e, discount5Field);
            break;
          }
          default:
        }
        handleFieldChange(e, dataField);
      },
      [handleFieldChange, landTotalWithoutVAT],
    );

    const [openModal, setOpenModal] = useState();
    const [openModalReview, setOpenModalReview] = useState();
    const [openModalConfirm, setOpenModalConfirm] = useState();

    const [error, setError] = useState([]);

    const currentStatusIndex = Object.values(Statuses).findIndex(
      (st) => st.name === sellitem.status,
    );

    const productOptions = useMemo(
      () =>
        products
          .filter(
            ({ id, status }) =>
              status === ProductStatuses.SELLING.name || id === sellitem.productId,
          )
          .map(({ id, name }) => ({ key: id, value: id, text: `${name}` })),
      [sellitem.productId, products],
    );

    const agentOptions = useMemo(
      () => agents.map(({ id, name }) => ({ key: id, value: id, text: name })),
      [agents],
    );

    const [fields, setFields] = useState([]);

    const handleAdd = () => {
      const values = [...fields];
      values.push({ id: values.length, name: '' });
      setFields(values);
    };

    const handleRemove = (i) => {
      if (i > -1) {
        onDeleteSellitemCustomer(sellitem.id, i);
      } else {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
      }
    };

    useEffect(() => {
      setFields([]);
    }, [customers]);

    useEffect(() => {
      setData({
        ...defaultData,
        discount5Percent: new Intl.NumberFormat('en-GB', {
          maximumFractionDigits: 2,
        }).format((defaultData.discount5 / landTotalWithoutVAT) * 100),
      });
      setError([]);
    }, [defaultData, sellitem.id, landTotalWithoutVAT, setData]);

    const ownAgent = useMemo(
      () => products.find((pr) => pr.id === sellitem.productId)?.agent,
      [sellitem.productId, products],
    );

    const handleLockProduct = useCallback(() => {
      if (product) {
        const newStatus =
          product.status === ProductStatuses.LOCKED.name
            ? ProductStatuses.SELLING.name
            : ProductStatuses.LOCKED.name;
        onUpdateProduct({ id: sellitem.productId, status: newStatus });
      }
    }, [sellitem.productId, onUpdateProduct, product]);

    const handleChangeStatus = useCallback(
      (status) => {
        if (status === Statuses.REVIEW.name) {
          const haveError = [];
          if (!data.productId) {
            haveError.push(t(`common.selectProduct`));
          }
          if (product.status !== ProductStatuses.LOCKED.name) {
            haveError.push(t(`common.productNotLocked`));
          }
          if (product.finalTotal <= 0) {
            haveError.push(t(`common.productNoPrice`));
          }
          if (!data.customerId) {
            haveError.push(t(`common.addCustomer`));
          }
          if (!data.agentId) {
            haveError.push(t(`common.selectAgent`));
          }
          if (!data.paymentMethod) {
            haveError.push(t(`common.selectPaymentMethod`));
          }
          if (haveError.length > 0) {
            setError(haveError);
            return;
          }
        }
        const newData = {
          id: sellitem.id,
          status,
        };
        if (status === Statuses.CANCELED.name) {
          onUpdateProduct({ id: sellitem.productId, status: ProductStatuses.SELLING.name });
        }
        onUpdateSellitem(newData);
      },
      [
        sellitem.id,
        sellitem.productId,
        onUpdateSellitem,
        data.productId,
        data.customerId,
        data.agentId,
        data.paymentMethod,
        product.status,
        product.finalTotal,
        t,
        onUpdateProduct,
      ],
    );

    const handleOpenProductModal = useCallback(() => {
      setOpenModal(true);
    }, []);

    const handleSubmitReview = useCallback(() => {
      setOpenModalReview(true);
    }, []);

    const handleUpdateSellitem = useCallback(
      (field, value) => {
        switch (field) {
          case 'productId':
            if (sellitem[field] !== value.toString()) {
              const newData = {
                id: sellitem.id,
                [field]: value,
                bagitemId: product?.bagitemId || undefined,
              };
              onUpdateSellitem(newData);
            }
            break;
          case 'agentId':
          case 'bonus': {
            const bonusValue = value.trim() !== '' ? value : '0';
            if (sellitem[field] !== bonusValue.toString()) {
              const newData = {
                id: sellitem.id,
                [field]: bonusValue,
              };
              onUpdateSellitem(newData);
            }
            break;
          }
          case 'discount5':
            if (sellitem[field] !== value.toString()) {
              const newData = {
                id: sellitem.id,
                [field]: value,
              };
              onUpdateSellitem(newData);
            }
            break;
          case 'discount5Percent': {
            if (
              parseFloat((defaultData.discount5 / landTotalWithoutVAT) * 100).toFixed(2) !==
              (value * 1).toFixed(2)
            ) {
              const newData = {
                id: sellitem.id,
                discount5: (((value * 1) / 100) * landTotalWithoutVAT).toFixed(0),
              };
              onUpdateSellitem(newData);
            }
            break;
          }
          default:
        }
      },
      [sellitem, product?.bagitemId, onUpdateSellitem, defaultData.discount5, landTotalWithoutVAT],
    );

    const handleSubmit = useCallback(() => {
      let cleanData = {
        id: data.id,
      };
      if (data.bonus && data.bonus !== defaultData.bonus) {
        cleanData = {
          ...cleanData,
          bonus: data.bonus,
        };
      }
      if (data.discount1 !== defaultData.discount1) {
        cleanData = {
          ...cleanData,
          discount1: data.discount1,
        };
      }
      if (data.discount2 !== defaultData.discount2) {
        cleanData = {
          ...cleanData,
          discount2: data.discount2,
        };
      }
      if (data.discount3 !== defaultData.discount3) {
        cleanData = {
          ...cleanData,
          discount3: data.discount3,
        };
      }
      if (data.discount4 !== defaultData.discount4) {
        cleanData = {
          ...cleanData,
          discount4: data.discount4,
        };
      }
      if (data.discount5 !== defaultData.discount5) {
        cleanData = {
          ...cleanData,
          discount5: data.discount5,
        };
      }

      if (Object.values(cleanData).length > 1) {
        onUpdateSellitem(cleanData);
      }
    }, [data, defaultData, onUpdateSellitem]);

    const paymentStatus = [];
    let totalPayment = 0;
    if (payments && payments.length > 0) {
      payments.forEach((pm) => {
        const color = Object.values(Statuses).find((st) => st.name === pm.type)?.color;
        paymentStatus.push({ status: pm.type, name: pm.name, color: color || 'lagoon-blue' });
        if (pm.status === PaymentStatuses.FINISH.name) {
          totalPayment += pm.amount * 1;
        }
      });
    }

    const listReviewCancel = Object.values(Statuses)
      .filter((st) => st.reviewCancel === true)
      .map((st) => st.name);

    const handleUpdateDiscount = useCallback(
      (e, dataValue) => {
        const discount = product.discounts.find((dc) => dc.id === dataValue.id);
        if (dataValue.checked) {
          let { amount: discountValue } = discount;
          if (discount.type === DiscountTypes.PERCENT) {
            discountValue = Math.round((discount.amount / 100) * product.landTotalWithoutVAT);
          }
          // const newData = {
          //   id: sellitem.id,
          //   [`discount${dataValue.tabIndex + 1}`]: amount,
          // };
          // onUpdateSellitem(newData);
          onCreateSellitemDiscount({
            discountId: discount.id,
            sellitemId: sellitem.id,
            discountValue,
            discountAmount: discount.amount,
          });
        } else {
          // const newData = {
          //   id: sellitem.id,
          //   [`discount${dataValue.tabIndex + 1}`]: 0,
          // };
          // onUpdateSellitem(newData);
          onDeleteSellitemDiscount(sellitem.id, discount.id);
        }
      },
      [
        onCreateSellitemDiscount,
        onDeleteSellitemDiscount,
        product.discounts,
        product.landTotalWithoutVAT,
        sellitem.id,
      ],
    );

    const handleTransfer = useCallback(() => {
      const newData = {
        discount1: 0,
        discount2: 0,
        discount3: 0,
        discount4: 0,
        discount5: 0,
        productId: sellitem.productId,
        agentId: sellitem.agentId,
        bagitemId: sellitem.bagitemId,
        bookDate: sellitem.bookDate,
        name: sellitem.name,
        oldSellitemId: sellitem.id,
      };
      onUpdateProduct({ id: sellitem.productId, status: ProductStatuses.LOCKED.name });
      onCreateSellitem(newData);
    }, [sellitem, onCreateSellitem, onUpdateProduct]);

    return (
      <>
        <div className={styles.formAddTop}>
          <div className={styles.statusWrapper}>
            {sellitem.status !== Statuses.REVIEWCANCEL.name &&
              sellitem.status !== Statuses.CANCELED.name && (
                <>
                  <Label
                    key={Statuses.BOOKING.name}
                    color={Statuses.BOOKING.color}
                    isActived={
                      (currentStatusIndex >= 0 && currentStatusIndex < 13) ||
                      currentStatusIndex === 15
                    }
                    name={t(`statusCompact.${Statuses.BOOKING.name}`)}
                    size="small"
                  />
                  <Label
                    key={Statuses.REVIEW.name}
                    color={Statuses.REVIEW.color}
                    isActived={
                      (currentStatusIndex >= 1 && currentStatusIndex < 13) ||
                      currentStatusIndex === 15
                    }
                    name={t(`statusCompact.${Statuses.REVIEW.name}`)}
                    size="small"
                  />
                  <Label
                    key={Statuses.APPROVED.name}
                    color={Statuses.APPROVED.color}
                    isActived={
                      (currentStatusIndex >= 2 && currentStatusIndex < 13) ||
                      currentStatusIndex === 15
                    }
                    name={t(`statusCompact.${Statuses.APPROVED.name}`)}
                    size="small"
                  />
                  {paymentStatus.length > 0 ? (
                    paymentStatus.map((pms, index) => (
                      <Label
                        key={pms.status}
                        color={pms.color}
                        isActived={
                          (currentStatusIndex >= index + 3 && currentStatusIndex < 13) ||
                          currentStatusIndex === 15
                        }
                        name={pms.name}
                        size="small"
                      />
                    ))
                  ) : (
                    <Label
                      key={Statuses.PAYMENT.name}
                      color={Statuses.PAYMENT.color}
                      isActived={Statuses.PAYMENT.name === sellitem.status}
                      name={t(`statusCompact.${Statuses.PAYMENT.name}`)}
                      size="small"
                    />
                  )}
                  {sellitem.isDenied === true ? (
                    <Label
                      key={Statuses.REDO.name}
                      color={Statuses.REDO.color}
                      isActived={Statuses.REDO.name === sellitem.status}
                      name={t(`statusCompact.${Statuses.REDO.name}`)}
                      size="small"
                    />
                  ) : (
                    <Label
                      key={Statuses.FINISH.name}
                      color={Statuses.FINISH.color}
                      isActived={Statuses.FINISH.name === sellitem.status}
                      name={t(`statusCompact.${Statuses.FINISH.name}`)}
                      size="small"
                    />
                  )}
                </>
              )}
            {(sellitem.status === Statuses.REVIEWCANCEL.name ||
              sellitem.status === Statuses.CANCELED.name) && (
              <>
                <Label
                  key={Statuses.REVIEWCANCEL.name}
                  color={Statuses.REVIEWCANCEL.color}
                  isActived={Statuses.REVIEWCANCEL.name === sellitem.status}
                  name={t(`statusCompact.${Statuses.REVIEWCANCEL.name}`)}
                  size="small"
                />
                <Label
                  key={Statuses.CANCELED.name}
                  color={Statuses.CANCELED.color}
                  isActived={Statuses.CANCELED.name === sellitem.status}
                  name={t(`statusCompact.${Statuses.CANCELED.name}`)}
                  size="small"
                />
              </>
            )}
          </div>
          <div className={styles.actionsWrapper}>
            {canEdit && (
              <>
                {sellitem.status === Statuses.BOOKING.name && (
                  <>
                    <Button
                      type="button"
                      className={styles.buttonReview}
                      primary
                      onClick={() => handleChangeStatus(Statuses.REVIEW.name)}
                    >
                      {t('action.review')}
                    </Button>
                    <SemanticUIPopup
                      position="bottom center"
                      trigger={
                        <Button className={styles.buttonCancel} type="button" color="red">
                          {t('action.cancel')}
                        </Button>
                      }
                      content={
                        <DeleteStep
                          title={t('common.cancelSellitem', {
                            context: 'title',
                          })}
                          content={t('common.areYouSureYouWantToCancelThisSellitem')}
                          buttonContent={t('action.cancelSellitem')}
                          onConfirm={() => handleChangeStatus(Statuses.CANCELED.name)}
                        />
                      }
                      on="click"
                      hideOnScroll
                    />
                  </>
                )}
                {sellitem.status === Statuses.REVIEW.name && (
                  <SemanticUIPopup
                    position="bottom center"
                    trigger={
                      <Button className={styles.buttonCancel} type="button" color="red">
                        {t('action.cancel')}
                      </Button>
                    }
                    content={
                      <DeleteStep
                        title={t('common.cancelSellitem', {
                          context: 'title',
                        })}
                        content={t('common.areYouSureYouWantToCancelThisSellitem')}
                        buttonContent={t('action.cancelSellitem')}
                        onConfirm={() => handleChangeStatus(Statuses.BOOKING.name)}
                      />
                    }
                    on="click"
                    hideOnScroll
                  />
                )}
                {listReviewCancel.includes(sellitem.status) && (
                  <>
                    <ConfirmForm
                      sellitem={sellitem}
                      onUpdateSellitem={onUpdateSellitem}
                      openModal={openModalConfirm}
                      onClose={() => setOpenModalConfirm(false)}
                    />
                    <Button
                      type="button"
                      className={styles.buttonCancel}
                      color="red"
                      onClick={() => setOpenModalConfirm(true)}
                    >
                      {t('action.reviewCancel')}
                    </Button>
                  </>
                )}
              </>
            )}
            {isManager && sellitem.status === Statuses.REVIEW.name && (
              <>
                <Button
                  type="button"
                  className={styles.buttonReview}
                  primary
                  onClick={handleSubmitReview}
                >
                  {t('action.approve')}
                </Button>
                <ReviewForm
                  type="approve"
                  sellitem={sellitem}
                  onUpdateSellitem={onUpdateSellitem}
                  openModal={openModalReview}
                  onClose={() => setOpenModalReview(false)}
                  onUpdateProduct={onUpdateProduct}
                />
              </>
            )}
            {isManager && sellitem.status === Statuses.REVIEWCANCEL.name && (
              <>
                <Button
                  type="button"
                  className={styles.buttonReviewCancel}
                  color="red"
                  onClick={handleSubmitReview}
                >
                  {t('action.approveCancel')}
                </Button>
                <ReviewForm
                  type="cancel"
                  sellitem={sellitem}
                  onUpdateSellitem={onUpdateSellitem}
                  openModal={openModalReview}
                  onClose={() => setOpenModalReview(false)}
                  oldStatus={oldStatus}
                  onUpdateProduct={onUpdateProduct}
                />
              </>
            )}
            {canEdit &&
              sellitem.status === Statuses.CANCELED.name &&
              sellitem.product.status === ProductStatuses.SELLING.name && (
                <Button
                  type="button"
                  className={styles.buttonTransfer}
                  primary
                  onClick={handleTransfer}
                >
                  {t('action.transfer')}
                </Button>
              )}
          </div>
        </div>
        {error.length > 0 && (
          <div className={styles.messageWrapper}>
            <Button
              type="button"
              icon="close"
              size="tiny"
              className={styles.closeMessage}
              onClick={() => setError([])}
            />
            <Message error header={t('common.pleaseFixAllIssueBeforeSubmitReview')} list={error} />
          </div>
        )}
        <Form className={styles.formAddSellitem} onSubmit={handleSubmit}>
          <Form.Group className={`${styles.fieldGroup} ${styles.groupLeft}`}>
            <Form.Field className={styles.fields}>
              <label htmlFor="productId">{t('common.productName')}</label>
              <div className={styles.fieldValueProduct}>
                <Dropdown
                  name="productId"
                  placeholder={t('common.enterNameProductToSearch')}
                  fluid
                  search
                  selection
                  disabled
                  value={sellitem.productId}
                  options={productOptions}
                  noResultsMessage={t('common.noResultsMessage')}
                  onChange={(e, dataValue) => handleUpdateSellitem('productId', dataValue.value)}
                />
                {canEdit && sellitem.status === Statuses.BOOKING.name && (
                  <Button
                    type="button"
                    className={styles.buttonLock}
                    content={
                      product.status === ProductStatuses.LOCKED.name
                        ? t('action.unlock')
                        : t('action.lock')
                    }
                    onClick={handleLockProduct}
                  />
                )}
                <Button
                  type="button"
                  className={styles.buttonOpenDetail}
                  icon="ellipsis horizontal"
                  onClick={handleOpenProductModal}
                />
                <ProductModal
                  product={product}
                  openModal={openModal}
                  onClose={() => setOpenModal(false)}
                />
              </div>
            </Form.Field>
            <Form.Field className={styles.fieldsCustomer}>
              <div className={styles.customerWrapper}>
                <div className={styles.labelWrapper}>
                  <label htmlFor="customer">{t('common.customer')}</label>
                </div>
                <div className={styles.customerInputWrapper}>
                  <SearchCustomersContainer
                    isPrimary
                    disabled={
                      !canEdit ||
                      sellitem.status === Statuses.FINISH.name ||
                      sellitem.status === Statuses.CANCELED.name
                    }
                    currentValue={
                      customers.find(({ id }) => id === sellitem.customerId) || { name: '' }
                    }
                    disableEdit={sellitem.product?.status === ProductStatuses.SELLED.name}
                    sellitemId={sellitem.id}
                  />
                  {canEdit && sellitem.customerId && sellitem.status !== Statuses.CANCELED.name && (
                    <Button
                      size="mini"
                      className={styles.buttonAddCustomer}
                      type="button"
                      onClick={() => handleAdd()}
                      icon="plus"
                    />
                  )}
                </div>
              </div>
              {customers
                .filter(({ id }) => id !== sellitem.customerId)
                .map((customer) => (
                  <div className={styles.fieldCustomer} key={customer.id}>
                    <SearchCustomersContainer
                      isPrimary={false}
                      disabled={
                        !canEdit ||
                        sellitem.status === Statuses.FINISH.name ||
                        sellitem.status === Statuses.CANCELED.name
                      }
                      currentValue={customer}
                      disableEdit={sellitem.product?.status === ProductStatuses.SELLED.name}
                      sellitemId={sellitem.id}
                      onRemove={() => handleRemove(customer.customerId)}
                    />
                  </div>
                ))}
              {fields
                .filter(({ id }) => id !== sellitem.customerId)
                .map((customer) => (
                  <div className={styles.fieldCustomer} key={customer.id}>
                    <SearchCustomersContainer
                      isPrimary={false}
                      disabled={
                        !canEdit ||
                        sellitem.status === Statuses.FINISH.name ||
                        sellitem.status === Statuses.CANCELED.name
                      }
                      currentValue={customer}
                      disableEdit={sellitem.product?.status === ProductStatuses.SELLED.name}
                      sellitemId={sellitem.id}
                      onRemove={() => handleRemove(customer.customerId)}
                    />
                  </div>
                ))}
            </Form.Field>
            <Form.Field className={styles.fields}>
              <label htmlFor="bonus">{t('common.bonus')}</label>
              <NumberFormat
                className={styles.fieldNumber}
                disabled={!canEdit || sellitem.status !== Statuses.BOOKING.name}
                value={data.bonus || ''}
                thousandSeparator
                onValueChange={(values, sourceInfo) => {
                  handleFieldChange(sourceInfo, { name: 'bonus', value: values.value });
                }}
                onBlur={() => handleUpdateSellitem('bonus', data.bonus)}
              />
            </Form.Field>
            <Form.Field className={styles.fields}>
              <label htmlFor="agentId">{t('common.sellAgent')}</label>
              <Dropdown
                disabled={!canEdit || sellitem.status !== Statuses.BOOKING.name}
                className={styles.field}
                name="agentId"
                placeholder={t('common.sellAgent')}
                fluid
                search
                selection
                value={data.agentId}
                options={agentOptions}
                noResultsMessage={t('common.noResultsMessage')}
                onChange={(e, dataValue) => handleUpdateSellitem('agentId', dataValue.value)}
              />
            </Form.Field>
            <Form.Field className={styles.fields}>
              <label htmlFor="ownAgent">{t('common.ownAgent')}</label>
              <span className={styles.fieldValue}>{ownAgent?.name || ''}</span>
            </Form.Field>
            <Form.Field className={styles.fieldsSubtotal}>
              <label htmlFor="total">{t('common.totalWithVAT')}</label>
              <span className={styles.fieldValueNumber}>
                {(sellitem.sellPrice * 1).toLocaleString('en-GB')}
              </span>
            </Form.Field>
            <Form.Field className={styles.fieldsSubtotal}>
              <label htmlFor="total">{t('common.totalLandPriceWithoutVAT')}</label>
              <span className={styles.fieldValueNumber}>
                {(landTotalWithoutVAT * 1).toLocaleString('en-GB')}
              </span>
            </Form.Field>
          </Form.Group>
          <Form.Group className={`${styles.fieldGroup} ${styles.groupRight}`}>
            {product?.discounts?.map(({ id, name, description, type, amount }, index) => (
              <Form.Field key={id} className={styles.fieldsDiscount}>
                <Checkbox
                  disabled={
                    !canEdit ||
                    sellitem.status !== Statuses.BOOKING.name ||
                    (discounts.length > 3 && !discounts.find(({ discountId }) => discountId === id))
                  }
                  tabIndex={index}
                  id={id}
                  name={`discount_${index + 1}`}
                  checked={!!discounts.find(({ discountId }) => discountId === id)}
                  onChange={handleUpdateDiscount}
                />
                <label htmlFor={`discount_${index + 1}`}>
                  {name}
                  <div className={styles.meta}>{description}</div>
                </label>
                {amount > 0 && (
                  <span className={styles.fieldValueNumber}>
                    {type === DiscountTypes.PERCENT
                      ? Math.round((amount * product?.landTotalWithoutVAT) / 100, 0).toLocaleString(
                          'en-GB',
                        )
                      : amount.toLocaleString('en-GB')}
                  </span>
                )}
              </Form.Field>
            ))}
            <Form.Field className={styles.fields}>
              <label htmlFor="discount5">{t('common.discountManual')}</label>
              <div className={styles.discountWrapper}>
                <Input
                  disabled={!canEdit || sellitem.status !== Statuses.BOOKING.name}
                  fluid
                  name="discount5Percent"
                  type="number"
                  step="any"
                  min={0}
                  max={100}
                  value={data.discount5Percent || ''}
                  className={styles.fieldNumber}
                  onChange={handleChangeDiscount}
                  onBlur={() => handleUpdateSellitem('discount5Percent', data.discount5Percent)}
                />
                %
                <NumberFormat
                  name="discount5"
                  className={styles.fieldValueInput}
                  disabled={!canEdit || sellitem.status !== Statuses.BOOKING.name}
                  value={data.discount5 || ''}
                  thousandSeparator
                  min={0}
                  max={landTotalWithoutVAT}
                  onValueChange={(values, sourceInfo) => {
                    handleFieldChange(sourceInfo, { name: 'discount5', value: values.value });
                  }}
                  onBlur={() => handleUpdateSellitem('discount5', data.discount5)}
                />
              </div>
            </Form.Field>
            <Form.Field className={styles.fields}>
              <label htmlFor="totalDiscount">{t('common.totalDiscount')}</label>
              <span className={styles.fieldValueNumber}>
                {totalDiscount.toLocaleString('en-GB')}
              </span>
            </Form.Field>
            <Form.Field className={`${styles.fields} ${styles.fieldsTotal}`}>
              <label htmlFor="totalAfterDiscount">{t('common.totalAfterDiscount')}</label>
              <span className={styles.fieldValueNumber}>
                {(sellitem.sellPrice * 1 - totalDiscount).toLocaleString('en-GB')}
              </span>
            </Form.Field>
            <Form.Field className={styles.fields}>
              <label htmlFor="totalPayments">{t('common.totalPayments')}</label>
              <span className={styles.fieldValueNumber}>
                {totalPayment.toLocaleString('en-GB')}
              </span>
            </Form.Field>
            <Form.Field className={styles.fields}>
              <label htmlFor="totalRemains">{t('common.totalRemains')}</label>
              <span className={styles.fieldValueNumber}>
                {(sellitem.sellPrice * 1 - totalDiscount - totalPayment).toLocaleString('en-GB')}
              </span>
            </Form.Field>
          </Form.Group>
          <Button type="submit" className={styles.buttonSubmit} />
        </Form>
        <div className={styles.priceToText}>
          <span>{t('common.priceToText')}: </span>
          <ConvertNumberToText numberConvert={sellitem.sellPrice * 1 - totalDiscount} />
        </div>
      </>
    );
  },
);

EditForm.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  defaultData: PropTypes.objectOf(PropTypes.shape).isRequired,
  products: PropTypes.arrayOf(PropTypes.object()).isRequired,
  discounts: PropTypes.arrayOf(PropTypes.object()).isRequired,
  customers: PropTypes.arrayOf(PropTypes.object()).isRequired,
  agents: PropTypes.arrayOf(PropTypes.object()).isRequired,
  sellitem: PropTypes.objectOf(PropTypes.shape).isRequired,
  onUpdateSellitem: PropTypes.func.isRequired,
  onUpdateProduct: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(PropTypes.object()),
  onDeleteSellitemCustomer: PropTypes.func.isRequired,
  oldStatus: PropTypes.string,
  onCreateSellitemDiscount: PropTypes.func.isRequired,
  onDeleteSellitemDiscount: PropTypes.func.isRequired,
  onCreateSellitem: PropTypes.func.isRequired,
};

EditForm.defaultProps = {
  payments: undefined,
  oldStatus: undefined,
};

export default EditForm;
