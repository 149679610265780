/* eslint-disable no-param-reassign */
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import React, { useMemo, useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import { Button, Segment, Form, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { HotTable, HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';
import LinkRenderer from '../HotTable/LinkRenderer';
import exportXlsx from './export';
import { PaymentStatuses } from '../../constants/Enums';
import Summary from '../ProductTable/Summary';
import MenusPopup from './MenusPopup';

import { useForm } from '../../hooks';

import styles from './Debts.module.scss';
import globalStyles from '../../styles.module.scss';

const PaymentTable = React.memo(({ payments, bags, canEdit }) => {
  const [t] = useTranslation();
  const hotTableRef = useRef();
  const summaryRef = useRef();
  const colHeaders = useMemo(
    () => [
      '#',
      t('common.paymentsSellitem.productName'),
      t('common.paymentsSellitem.customerName'),
      t('common.paymentsSellitem.phone'),
      t('common.paymentsSellitem.agent'),
      t('common.paymentsSellitem.agentSelled'),
      t('common.paymentsSellitem.paymentName'),
      t('common.paymentsSellitem.percent'),
      t('common.paymentsSellitem.amount'),
      t('common.paymentsSellitem.dueDate'),
      t('common.paymentsSellitem.statusPayment'),
      t('common.paymentsSellitem.accountId'),
      t('common.paymentsSellitem.gotDate'),
      t('common.paymentsSellitem.note'),
    ],
    [t],
  );

  const [dataTable, setDataTable] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(PaymentStatuses.NEW.name);
  const [currentBag, setCurrentBag] = useState(0);

  const handleExport = useCallback(() => {
    const headers = [
      'STT',
      t('common.paymentsSellitem.productName'),
      t('common.paymentsSellitem.customerName'),
      t('common.paymentsSellitem.phone'),
      t('common.paymentsSellitem.agent'),
      t('common.paymentsSellitem.agentSelled'),
      t('common.paymentsSellitem.paymentName'),
      t('common.paymentsSellitem.percent'),
      t('common.paymentsSellitem.amount'),
      t('common.paymentsSellitem.dueDate'),
      t('common.paymentsSellitem.statusPayment'),
      t('common.paymentsSellitem.accountId'),
      t('common.paymentsSellitem.gotDate'),
      t('common.paymentsSellitem.note'),
    ];
    exportXlsx(dataTable, headers, { name: 'TiciLand - Công nợ' });
  }, [dataTable, t]);

  const afterSelection = useCallback(() => {
    const selected = hotTableRef.current.hotInstance.getSelected();
    if (
      !(
        selected.length === 1 &&
        selected[0][0] === selected[0][2] &&
        selected[0][1] === selected[0][3]
      )
    ) {
      const data = [];
      selected.forEach((it) => {
        data.push(hotTableRef.current.hotInstance.getData(...it).flat());
      });
      if (summaryRef.current) summaryRef.current.show(data);
    } else if (summaryRef.current) {
      summaryRef.current.show([]);
    }
  }, []);

  const afterDeselect = () => {
    if (summaryRef.current) summaryRef.current.show([]);
  };

  const statusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    Handsontable.renderers.TextRenderer.apply(this, [
      instance,
      td,
      row,
      col,
      prop,
      value,
      cellProperties,
    ]);
    if (value) {
      const status = Object.values(PaymentStatuses).find(({ label }) => label === value) || {
        name: '-',
        color: 'red',
      };
      td.style.color = '#fff';
      td.classList.add(globalStyles[`background${upperFirst(camelCase(status.color))}`]);
    }
    return td;
  };

  const percentRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    Handsontable.renderers.TextRenderer.apply(this, [
      instance,
      td,
      row,
      col,
      prop,
      value,
      cellProperties,
    ]);
    if (value) {
      td.innerText = `${value} %`;
    }
    td.classList.add(styles.valueNumber);
    return td;
  };

  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);
  const [dataCommission, , setDataCommission] = useForm({
    startDate: new Date(),
    endDate,
  });

  const [errors, setErrors] = useState({
    startDate: false,
    endDate: false,
  });

  const handleDatePickerChange = useCallback(
    (date, type) => {
      setDataCommission((prevData) => ({
        ...prevData,
        [type]: date,
      }));
    },
    [setDataCommission],
  );

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...dataCommission,
      startDate: dataCommission.startDate,
      endDate: dataCommission.endDate,
    };

    const err = {
      startDate: !cleanData.startDate,
      endDate: !cleanData.endDate,
    };
    setErrors(err);

    if (Object.values(err).some((e) => e)) {
      return;
    }
    const startDateTime = new Date(cleanData.startDate).getTime();
    const endDateTime = new Date(cleanData.endDate).getTime();
    const newData = [];
    payments.forEach((pm) => {
      const dueDateTime = parse(pm.dueDate, 'dd/MM/yyyy', new Date()).getTime() || 0;
      if (dueDateTime * 1 >= startDateTime * 1 && dueDateTime * 1 <= endDateTime * 1)
        newData.push(pm);
    });
    setDataTable(newData);
  }, [dataCommission, payments]);

  const afterFilter = useCallback(() => {
    const dataFilter = hotTableRef.current.hotInstance.getData().map((dt) => dt[0]);
    const newData = payments.filter((pm) => dataFilter.includes(pm.id));
    setDataTable(newData);
  }, [payments]);

  const handleCancel = useCallback(() => {
    setDataTable([...payments]);
    setCurrentStatus('all');
  }, [payments]);

  const statusOptions = [{ key: 'all', value: 'all', text: t('common.allStatuses') }];
  Object.values(PaymentStatuses).forEach((st) => {
    statusOptions.push({ key: st.name, value: st.name, text: t(`statusPayment.${st.name}`) });
  });

  const handleChangeStatus = useCallback((e, data) => {
    setCurrentStatus(data.value);
  }, []);

  useEffect(() => {
    let newDataTable = [...payments];
    if (currentStatus !== 'all') {
      newDataTable = payments.filter((pm) => pm.status === currentStatus);
    }
    setDataTable(newDataTable);
  }, [payments, currentStatus]);

  const bagOptions = [{ key: 0, value: 0, text: t('common.allBags') }, ...bags];
  const handleChangeBag = useCallback(
    (e, data) => {
      setCurrentBag(data.value);
      let newDataTable = [...payments];
      if (data.value !== 0) {
        newDataTable = payments.filter((pm) => pm.sellitem?.bagitem?.bagId === data.value);
      }
      setDataTable(newDataTable);
    },
    [payments],
  );

  return (
    <Segment className={styles.wrapper}>
      <div className={styles.filterWrapper}>
        <Dropdown
          className={styles.filterBag}
          placeholder={t('common.selectBag')}
          search
          selection
          value={currentBag}
          options={bagOptions}
          onChange={handleChangeBag}
        />
        <Dropdown
          className={styles.filterStatus}
          placeholder={t('common.selectStatus')}
          selection
          value={currentStatus}
          options={statusOptions}
          onChange={handleChangeStatus}
        />
        <Form>
          <Form.Group className={styles.fieldGroup}>
            <Form.Field
              required
              error={errors.startDate}
              onFocus={() => setErrors({ ...errors, startDate: false })}
              className={styles.field}
            >
              <label htmlFor="startDate">{t('common.paymentsSellitem.startDate')}</label>
              <DatePicker
                key="startDate"
                dateFormat={t('format:longDate')}
                className={styles.datePicker}
                placeholderText={t('common.paymentsSellitem.startDate')}
                selectsStart
                startDate={dataCommission.startDate}
                endDate={dataCommission.endDate}
                selected={dataCommission.startDate}
                onChange={(date) => handleDatePickerChange(date, 'startDate')}
              />
            </Form.Field>
            <Form.Field
              required
              error={errors.endDate}
              onFocus={() => setErrors({ ...errors, endDate: false })}
              className={styles.field}
            >
              <label htmlFor="startDate">{t('common.paymentsSellitem.endDate')}</label>
              <DatePicker
                key="endDate"
                dateFormat={t('format:longDate')}
                className={styles.datePicker}
                placeholderText={t('common.paymentsSellitem.startDate')}
                selectsEnd
                startDate={dataCommission.startDate}
                endDate={dataCommission.endDate}
                minDate={dataCommission.startDate}
                selected={dataCommission.endDate}
                onChange={(date) => handleDatePickerChange(date, 'endDate')}
              />
            </Form.Field>
            <Form.Field>
              <Button type="button" className={styles.buttonFilter} onClick={handleSubmit}>
                {t('action.filter')}
              </Button>
              <Button
                type="button"
                color="red"
                className={styles.buttonCancel}
                onClick={handleCancel}
              >
                {t('action.cancel')}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      <div id="hot-app" className={styles.tableWrapper}>
        <HotTable
          ref={hotTableRef}
          className={styles.hottable}
          data={dataTable}
          colHeaders={colHeaders}
          hiddenColumns={{ columns: [0] }}
          height="100%"
          stretchH="all"
          autoColumnSize={false}
          autoRowSize={false}
          manualColumnResize
          fixedColumnsLeft={2}
          rowHeaders
          enterMoves={{ col: 1, row: 0 }}
          dropdownMenu={['filter_by_value', 'filter_action_bar']}
          filters
          afterSelection={afterSelection}
          afterDeselect={afterDeselect}
          afterFilter={afterFilter}
          licenseKey="non-commercial-and-evaluation"
        >
          <HotColumn width={0} data="id" />
          <HotColumn width={110} data="product.name" readOnly>
            <LinkRenderer hot-renderer />
          </HotColumn>
          <HotColumn width={120} data="customer.name" readOnly />
          <HotColumn width={120} data="customer.phone" readOnly />
          <HotColumn width={120} data="product.agent.name" readOnly />
          <HotColumn width={120} data="sellitem.agent.name" readOnly />
          <HotColumn width={120} data="name" readOnly />
          <HotColumn
            width={70}
            data="percent"
            readOnly
            type="numeric"
            numericFormat={{ pattern: '0,0.00' }}
            renderer={percentRenderer}
          />
          <HotColumn
            width={140}
            data="amount"
            readOnly
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
          />
          <HotColumn width={80} data="dueDate" readOnly />
          <HotColumn
            width={90}
            data="statusLabel"
            wordWrap={false}
            renderer={statusRenderer}
            readOnly
          />
          <HotColumn width={100} data="account.name" readOnly />
          <HotColumn width={70} data="gotDate" readOnly />
          <HotColumn
            width={80}
            data="product.note"
            type="numeric"
            numericFormat={{ pattern: '0,0.00' }}
            readOnly
          />
        </HotTable>
        <div>
          <Summary ref={summaryRef} />
          <Button.Group className={styles.actionButtons}>
            <MenusPopup
              closeIcon={false}
              canExport={canEdit && payments.length > 0}
              canImport={canEdit}
              onExport={handleExport}
            >
              <Button size="mini" icon="bars" />
            </MenusPopup>
          </Button.Group>
        </div>
      </div>
    </Segment>
  );
});

PaymentTable.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object()).isRequired,
  bags: PropTypes.arrayOf(PropTypes.object()).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default PaymentTable;
