import { takeEvery, all } from 'redux-saga/effects';

import { fetchDashboardService } from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* dashboardWatchers() {
  yield all([
    takeEvery(EntryActionTypes.DASHBOARD_FETCH, ({ payload: { bagId } }) =>
      fetchDashboardService(bagId),
    ),
  ]);
}
