/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';

import RightSidebar from './RightSidebar';
import CustomersLeftSidebarContainer from '../../containers/CustomersLeftSidebarContainer';
import CustomerTable from '../CustomerTable';

import styles from './Customers.module.scss';

const Customers = React.memo(({ customer, sellitems, canEdit, onUpdate }) => {
  return (
    <div className={styles.wrapperGrid}>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={5} computer={3} largeScreen={3} widescreen={3}>
            <CustomersLeftSidebarContainer />
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={11}
            computer={10}
            largeScreen={customer && customer.id ? 10 : 13}
            widescreen={customer && customer.id ? 10 : 13}
          >
            <Segment className={styles.wrapper}>
              {customer && customer.id && (
                <>
                  <CustomerTable sellitems={sellitems} />
                </>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={3} largeScreen={3} widescreen={3}>
            {customer && customer.id && (
              <RightSidebar canEdit={canEdit} customer={customer} onUpdate={onUpdate} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
});

Customers.propTypes = {
  customer: PropTypes.objectOf(PropTypes.shape).isRequired,
  sellitems: PropTypes.arrayOf(PropTypes.object()).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Customers;
