import { fetch } from 'whatwg-fetch';
import http from './http';
import socket from './socket';
import { getAccessTokenImgur } from '../utils/access-token-storage';

/* Transformers */

export const transformAttachment = (attachment) => ({
  ...attachment,
  createdAt: new Date(attachment.createdAt),
});

/* Actions */

const createAttachment = (sellitemId, data, requestId, headers) =>
  http
    .post(`/sellitems/${sellitemId}/attachments?requestId=${requestId}`, data, headers)
    .then((body) => ({
      ...body,
      item: transformAttachment(body.item),
    }));

const createAttachmentProduct = (productId, data, requestId, headers) =>
  http
    .post(`/products/${productId}/attachments?requestId=${requestId}`, data, headers)
    .then((body) => ({
      ...body,
      item: transformAttachment(body.item),
    }));

const updateAttachment = (id, data, headers) =>
  socket.patch(`/attachments/${id}`, data, headers).then((body) => ({
    ...body,
    item: transformAttachment(body.item),
  }));

const deleteAttachment = (id, headers) =>
  socket.delete(`/attachments/${id}`, undefined, headers).then((body) => ({
    ...body,
    item: transformAttachment(body.item),
  }));

/* Event handlers */

const makeHandleAttachmentCreate = (next) => (body) => {
  next({
    ...body,
    item: transformAttachment(body.item),
  });
};

const makeHandleAttachmentUpdate = makeHandleAttachmentCreate;

const makeHandleAttachmentDelete = makeHandleAttachmentCreate;

const createAttachmentImgur = (data) => {
  const accessTokenImgur = getAccessTokenImgur();
  const headers = { Authorization: `Bearer ${accessTokenImgur}` };
  const formData = new FormData();
  formData.append('image', data);
  const attachment = fetch(`https://api.imgur.com/3/image`, {
    method: 'POST',
    headers,
    body: formData,
  })
    .then((response) =>
      response.json().then((body) => ({
        body,
        isError: response.status !== 200,
      })),
    )
    .then(({ body, isError }) => {
      if (isError) {
        throw body;
      }

      return body;
    });
  return attachment;
};

export default {
  createAttachment,
  createAttachmentImgur,
  createAttachmentProduct,
  updateAttachment,
  deleteAttachment,
  makeHandleAttachmentCreate,
  makeHandleAttachmentUpdate,
  makeHandleAttachmentDelete,
};
