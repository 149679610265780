import { createSelector } from 'redux-orm';

import orm from '../orm';

export const currentUserIdSelector = ({ auth: { userId } }) => userId;

export const currentUserSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel.ref;
  },
);

export const isManagerSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return ['manager', 'director'].includes(userModel.role);
  },
);

export const isAdminSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }
    return userModel.isAdmin;
    // return ['admin'].includes(userModel.role);
  },
);

export const isSaleAdminSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return ['saleAdmin1', 'saleAdmin2'].includes(userModel.role);
  },
);

export const isAccountantSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return ['accountant'].includes(userModel.role);
  },
);

export const projectsForCurrentUserSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel.getOrderedAvailableProjectsModelArray().map((projectModel) => {
      const groupsModels = projectModel.getOrderedAvailableGroupsModelArray(userModel.id);

      let notificationsTotal = 0;
      groupsModels.forEach((groupModel) => {
        groupModel.sellitems.toModelArray().forEach((sellitemModel) => {
          notificationsTotal += sellitemModel.getUnreadNotificationsQuerySet().count();
        });
      });

      return {
        ...projectModel.ref,
        notificationsTotal,
        firstGroupId: groupsModels[0] && groupsModels[0].id,
      };
    });
  },
);

export const projectsToBlocksForCurrentUserSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel.getOrderedAvailableProjectsModelArray().map((projectModel) => ({
      ...projectModel.ref,
      groups: projectModel.getOrderedAvailableGroupsModelArray(id).map((groupModel) => ({
        ...groupModel.ref,
        blocks: groupModel.getOrderedBlocksQuerySet().toRefArray(),
      })),
    }));
  },
);

export const notificationsForCurrentUserSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User, Sellitem, Product }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel
      .getOrderedUnreadNotificationsQuerySet()
      .toModelArray()
      .map((notificationModel) => {
        const sellitem =
          notificationModel.action?.sellitemId && Sellitem.withId(notificationModel.sellitemId).ref;
        return {
          ...notificationModel.ref,
          action: notificationModel.action && {
            ...notificationModel.action.ref,
            user: notificationModel.action.user.ref,
          },
          sellitem: {
            ...sellitem,
            productName: Product.withId(sellitem.productId)?.ref?.name,
          },
        };
      });
  },
);
