/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, Label } from 'semantic-ui-react';

import styles from './BagItem.module.scss';
import Paths from '../../constants/Paths';
import { BagStatuses } from '../../constants/Enums';

const BagItem = React.memo(
  ({ id, name, categoryName, startDate, endDate, status, selledTotal, total, isActive }) => {
    const [t] = useTranslation();

    return (
      <List.Item
        as={Link}
        to={Paths.BAGS.replace(':id', id)}
        className={classNames(styles.bagItem, isActive && styles.active)}
      >
        <div className={classNames(styles.wrapper)}>
          <div className={styles.name}>
            {name}
            <Label basic attached="top right" color="orange" className={styles.status}>
              {`${t('status.selled')}: ${selledTotal}/${total}`}{' '}
            </Label>
          </div>
          <div className={styles.meta}>
            <Label circular color={status === BagStatuses.PUBLISH.name ? 'green' : 'grey'} empty />
            &nbsp;
            {t('format:longDate', {
              postProcess: 'formatDate',
              value: startDate,
            })}
            ~
            {t('format:longDate', {
              postProcess: 'formatDate',
              value: endDate,
            })}
            {categoryName && <span> [{categoryName}]</span>}
          </div>
        </div>
      </List.Item>
    );
  },
);

BagItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  status: PropTypes.string.isRequired,
  selledTotal: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
};

BagItem.defaultProps = {
  startDate: undefined,
  endDate: undefined,
};

export default BagItem;
