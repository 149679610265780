import React from 'react';
import PropTypes from 'prop-types';
import ProductWidgetContainer from '../../containers/ProductWidgetContainer';
import { ProductStatistics } from '../../constants/Enums';

import styles from './ProductWidgets.module.scss';

const ProductWidgets = React.memo(
  ({ itemsTotal, itemsBooking, itemsDeposit, itemsSelled, itemsAvailable }) => {
    const items = [
      {
        title: ProductStatistics.TOTAL.title,
        isProgress: false,
        total: itemsTotal,
        value: itemsTotal,
        color: ProductStatistics.TOTAL.hex,
      },
      {
        title: ProductStatistics.AVAILABLE.title,
        isProgress: true,
        total: itemsTotal,
        value: itemsAvailable,
        color: ProductStatistics.AVAILABLE.hex,
      },
      {
        title: ProductStatistics.BOOKING.title,
        isProgress: true,
        total: itemsTotal,
        value: itemsBooking,
        color: ProductStatistics.BOOKING.hex,
      },
      {
        title: ProductStatistics.DEPOSIT.title,
        isProgress: true,
        total: itemsTotal,
        value: itemsDeposit,
        color: ProductStatistics.DEPOSIT.hex,
      },
      {
        title: ProductStatistics.SELLED.title,
        isProgress: true,
        total: itemsTotal,
        value: itemsSelled,
        color: ProductStatistics.SELLED.hex,
      },
    ];

    return (
      <div className={styles.productWidgetOuterWrapper}>
        <div className={styles.widgets}>
          {items.map((item) => (
            <ProductWidgetContainer
              key={item.title}
              title={item.title}
              isProgress={item.isProgress}
              total={item.total}
              value={item.value}
              color={item.color}
            />
          ))}
        </div>
      </div>
    );
  },
);

ProductWidgets.propTypes = {
  itemsTotal: PropTypes.number.isRequired,
  itemsBooking: PropTypes.number.isRequired,
  itemsDeposit: PropTypes.number.isRequired,
  itemsSelled: PropTypes.number.isRequired,
  itemsAvailable: PropTypes.number.isRequired,
};

export default ProductWidgets;
