import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Block';

  static fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    groupId: fk({
      to: 'Group',
      as: 'group',
      relatedName: 'blocks',
    }),
  };

  static reducer({ type, payload }, Block) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        if (payload.blocks) {
          payload.blocks.forEach((block) => {
            Block.upsert(block);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Block.all().delete();

        if (payload.blocks) {
          payload.blocks.forEach((block) => {
            Block.upsert(block);
          });
        }

        break;
      case ActionTypes.PRODUCTS_FETCH__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.blocks.forEach((block) => {
          Block.upsert(block);
        });

        break;
      case ActionTypes.BLOCK_CREATE:
      case ActionTypes.BLOCK_CREATE_HANDLE:
      case ActionTypes.BLOCK_UPDATE__SUCCESS:
      case ActionTypes.BLOCK_UPDATE_HANDLE:
        Block.upsert(payload.block);

        break;
      case ActionTypes.BLOCK_CREATE__SUCCESS:
        Block.withId(payload.localId).delete();
        Block.upsert(payload.block);

        break;
      case ActionTypes.BLOCK_UPDATE:
        Block.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.BLOCK_DELETE:
        Block.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.BLOCK_DELETE__SUCCESS:
      case ActionTypes.BLOCK_DELETE_HANDLE: {
        const blockModel = Block.withId(payload.block.id);

        if (blockModel) {
          blockModel.deleteWithRelated();
        }

        break;
      }
      default:
    }
  }

  getOrderedProductsQuerySet() {
    return this.products.orderBy('position');
  }

  getOrderedFilteredProductsModelArray() {
    let productModels = this.getOrderedProductsQuerySet().toModelArray();

    const filterUserIds = this.group.filterUsers.toRefArray().map((user) => user.id);
    const filterAgentIds = this.group.filterAgents.toRefArray().map((agent) => agent.id);
    const { filterProductStatuses } = this.group;

    if (filterUserIds.length > 0) {
      productModels = productModels.filter((productModel) => {
        const users = productModel.users.toRefArray();

        return users.some((user) => filterUserIds.includes(user.id));
      });
    }

    if (filterAgentIds.length > 0) {
      productModels = productModels.filter((productModel) =>
        filterAgentIds.includes(productModel.agentId),
      );
    }

    if (filterProductStatuses.length > 0) {
      productModels = productModels.filter((productModel) =>
        filterProductStatuses.includes(productModel.status),
      );
    }

    return productModels;
  }

  deleteRelated() {
    this.products.toModelArray().forEach((productModel) => {
      productModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
