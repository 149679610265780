import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parse';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import Handsontable from 'handsontable';

import { embedLocales, languages } from './locales';

i18n.dateFns = {
  locales: {},
  addLocale(language, locale) {
    this.locales[language] = locale;

    registerLocale(language, locale);
  },
  getLocale(language = i18n.language) {
    return this.locales[language];
  },
  format(date, format, { language, ...options } = {}) {
    return formatDate(date, format, {
      locale: this.getLocale(language),
      ...options,
    });
  },
  parse(dateString, format, backupDate, { language, ...options } = {}) {
    return parseDate(dateString, format, backupDate, {
      locale: this.getLocale(language),
      ...options,
    });
  },
};

i18n.on('languageChanged', (language) => {
  setDefaultLocale(language);

  const enUSDictionary = Handsontable.languages.getLanguageDictionary('en-US');
  const C = Handsontable.languages.dictionaryKeys;

  enUSDictionary[C.CONTEXTMENU_ITEMS_NO_ITEMS] = 'Không còn tùy chọn';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ROW_ABOVE] = 'Chèn hàng lên trên';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ROW_BELOW] = 'Chèn hàng xuống dưới';
  enUSDictionary[C.CONTEXTMENU_ITEMS_INSERT_LEFT] = 'Chèn cột bên trái';
  enUSDictionary[C.CONTEXTMENU_ITEMS_INSERT_RIGHT] = 'Chèn cột bên phải';
  enUSDictionary[C.CONTEXTMENU_ITEMS_REMOVE_ROW] = ['Xóa hàng', 'Xóa hàng'];
  enUSDictionary[C.CONTEXTMENU_ITEMS_REMOVE_COLUMN] = ['Xóa cột', 'Xóa cột'];
  enUSDictionary[C.CONTEXTMENU_ITEMS_UNDO] = 'Hoàn tác';
  enUSDictionary[C.CONTEXTMENU_ITEMS_REDO] = 'Làm lại';
  enUSDictionary[C.CONTEXTMENU_ITEMS_READ_ONLY] = 'Chỉ đọc';
  enUSDictionary[C.CONTEXTMENU_ITEMS_CLEAR_COLUMN] = 'Xóa nội dung cột';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ALIGNMENT] = 'Căn chỉnh';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ALIGNMENT_LEFT] = 'Căn trái';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ALIGNMENT_CENTER] = 'Giữa';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ALIGNMENT_RIGHT] = 'Căn phải';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ALIGNMENT_JUSTIFY] = 'Hai bên';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ALIGNMENT_TOP] = 'Trên';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ALIGNMENT_MIDDLE] = 'Giữa';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ALIGNMENT_BOTTOM] = 'Dưới';
  enUSDictionary[C.CONTEXTMENU_ITEMS_FREEZE_COLUMN] = 'Cố định cột';
  enUSDictionary[C.CONTEXTMENU_ITEMS_UNFREEZE_COLUMN] = 'Hủy cố định cột';
  enUSDictionary[C.CONTEXTMENU_ITEMS_BORDERS] = 'Đường viền';
  enUSDictionary[C.CONTEXTMENU_ITEMS_BORDERS_TOP] = 'Viền trên';
  enUSDictionary[C.CONTEXTMENU_ITEMS_BORDERS_RIGHT] = 'Viền phải';
  enUSDictionary[C.CONTEXTMENU_ITEMS_BORDERS_BOTTOM] = 'Viền dưới';
  enUSDictionary[C.CONTEXTMENU_ITEMS_BORDERS_LEFT] = 'Viền trái';
  enUSDictionary[C.CONTEXTMENU_ITEMS_REMOVE_BORDERS] = 'Xóa đường viền';
  enUSDictionary[C.CONTEXTMENU_ITEMS_ADD_COMMENT] = 'Thêm bình luận';
  enUSDictionary[C.CONTEXTMENU_ITEMS_EDIT_COMMENT] = 'Sửa bình luận';
  enUSDictionary[C.CONTEXTMENU_ITEMS_REMOVE_COMMENT] = 'Xóa bình luận';
  enUSDictionary[C.CONTEXTMENU_ITEMS_READ_ONLY_COMMENT] = 'Bình luận chỉ đọc';
  enUSDictionary[C.CONTEXTMENU_ITEMS_MERGE_CELLS] = 'Nối ô';
  enUSDictionary[C.CONTEXTMENU_ITEMS_UNMERGE_CELLS] = 'Hủy nối ô';
  enUSDictionary[C.CONTEXTMENU_ITEMS_COPY] = 'Sao chép';
  enUSDictionary[C.CONTEXTMENU_ITEMS_CUT] = 'Cắt';
  enUSDictionary[C.CONTEXTMENU_ITEMS_HIDE_COLUMN] = ['Ẩn cột', 'Ẩn cột'];
  enUSDictionary[C.CONTEXTMENU_ITEMS_SHOW_COLUMN] = ['Hiện cột', 'Hiện cột'];
  enUSDictionary[C.CONTEXTMENU_ITEMS_HIDE_ROW] = ['Ẩn hàng', 'Ẩn hàng'];
  enUSDictionary[C.CONTEXTMENU_ITEMS_SHOW_ROW] = ['Hiện hàng', 'Hiện hàng'];
  enUSDictionary[C.FILTERS_DIVS_FILTER_BY_VALUE] = 'Tìm kiếm bằng từ khóa';
  enUSDictionary[C.FILTERS_BUTTONS_SELECT_ALL] = 'Chọn tất cả';
  enUSDictionary[C.FILTERS_BUTTONS_CLEAR] = 'Xóa';
  enUSDictionary[C.FILTERS_BUTTONS_PLACEHOLDER_SEARCH] = 'Tìm kiếm';
  enUSDictionary[C.FILTERS_BUTTONS_OK] = 'Ok';
  enUSDictionary[C.FILTERS_BUTTONS_CANCEL] = 'Hủy';

  // enUSDictionary[C.FILTERS_CONDITIONS_NONE] = 'None';
  // enUSDictionary[C.FILTERS_CONDITIONS_EMPTY] = 'Is empty';
  // enUSDictionary[C.FILTERS_CONDITIONS_NOT_EMPTY] = 'Is not empty';
  // enUSDictionary[C.FILTERS_CONDITIONS_EQUAL] = 'Is equal to';
  // enUSDictionary[C.FILTERS_CONDITIONS_NOT_EQUAL] = 'Is not equal to';
  // enUSDictionary[C.FILTERS_CONDITIONS_BEGINS_WITH] = 'Begins with';
  // enUSDictionary[C.FILTERS_CONDITIONS_ENDS_WITH] = 'Ends with';
  // enUSDictionary[C.FILTERS_CONDITIONS_CONTAINS] = 'Contains';
  // enUSDictionary[C.FILTERS_CONDITIONS_NOT_CONTAIN] = 'Does not contain';
  // enUSDictionary[C.FILTERS_CONDITIONS_GREATER_THAN] = 'Greater than or equal to';
  // enUSDictionary[C.FILTERS_CONDITIONS_LESS_THAN] = 'Less than';
  // enUSDictionary[C.FILTERS_CONDITIONS_LESS_THAN_OR_EQUAL] = 'Less than or equal to';
  // enUSDictionary[C.FILTERS_CONDITIONS_BETWEEN] = 'Is between';
  // enUSDictionary[C.FILTERS_CONDITIONS_NOT_BETWEEN] = 'Is not between';
  // enUSDictionary[C.FILTERS_CONDITIONS_AFTER] = 'After';
  // enUSDictionary[C.FILTERS_CONDITIONS_BEFORE] = 'Before';
  enUSDictionary[C.FILTERS_CONDITIONS_TODAY] = 'Hôm nay';
  enUSDictionary[C.FILTERS_CONDITIONS_TOMORROW] = 'Ngày mai';
  enUSDictionary[C.FILTERS_CONDITIONS_YESTERDAY] = 'Hôm qua';
  enUSDictionary[C.FILTERS_VALUES_BLANK_CELLS] = 'Ô trống';
  // enUSDictionary[C.FILTERS_DIVS_FILTER_BY_CONDITION] = 'Filter by condition';
  // enUSDictionary[C.FILTERS_DIVS_FILTER_BY_VALUE] = 'Filter by value';
  // enUSDictionary[C.FILTERS_LABELS_CONJUNCTION] = 'And';
  // enUSDictionary[C.FILTERS_LABELS_DISJUNCTION] = 'Or';
  // enUSDictionary[C.FILTERS_BUTTONS_PLACEHOLDER_VALUE] = 'Value';
  // enUSDictionary[C.FILTERS_BUTTONS_PLACEHOLDER_SECOND_VALUE] = 'Second value';

  Handsontable.languages.registerLanguageDictionary(enUSDictionary); // re-registration
});

const formatDatePostProcessor = {
  type: 'postProcessor',
  name: 'formatDate',
  process(value, _, options) {
    return i18n.dateFns.format(options.value, value);
  },
};

const parseDatePostProcessor = {
  type: 'postProcessor',
  name: 'parseDate',
  process(value, _, options) {
    return i18n.dateFns.parse(options.value, value, new Date());
  },
};

i18n
  .use(languageDetector)
  .use(formatDatePostProcessor)
  .use(parseDatePostProcessor)
  .use(initReactI18next)
  .init({
    resources: embedLocales,
    lng: languages[0],
    fallbackLng: false,
    whitelist: languages,
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
      format(value, format, language) {
        if (value instanceof Date) {
          return i18n.dateFns.format(value, format, {
            language,
          });
        }

        return value;
      },
    },
    react: {
      useSuspense: false,
    },
    debug: process.env.NODE_ENV !== 'production',
  });

i18n.loadCoreLocale = (language) =>
  import(`./locales/${language}/core`).then((module) => {
    const locale = module.default;

    Object.keys(locale).forEach((namespace) => {
      if (namespace === 'dateFns') {
        i18n.dateFns.addLocale(language, locale[namespace]);
      } else {
        i18n.addResourceBundle(language, namespace, locale[namespace], true, true);
      }
    });
  });

export default i18n;
