import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  isAdminSelector,
  isSaleAdminSelector,
  pathSelector,
  sellitemsForCurrentGroupSelector,
  productsForCurrentGroupSelector,
  customerByIdSelector,
} from '../selectors';
import { createSellitem } from '../actions/entry';
import { ProductStatuses } from '../constants/Enums';
import LeftSidebar from '../components/Sellitems/LeftSidebar';

const mapStateToProps = (state) => {
  const { sellitemId, groupId } = pathSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isAdmin = isAdminSelector(state);
  const isSaleAdmin = isSaleAdminSelector(state);
  let allSellitems = sellitemsForCurrentGroupSelector(state);
  const products = productsForCurrentGroupSelector(state);
  products.map((pr) => pr.status === ProductStatuses.SELLING.name);

  allSellitems = allSellitems.map((sl) => {
    let customer;
    if (sl.customerId) customer = customerByIdSelector(state, sl.customerId);
    return {
      ...sl,
      customer,
    };
  });

  return {
    groupId,
    products,
    sellitemId,
    sellitems: allSellitems,
    canEdit: isAdmin || (isCurrentUserMember && isSaleAdmin),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSellitemCreate: createSellitem,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
