import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Modal, Form, Input, Button, Checkbox } from 'semantic-ui-react';
import vndb from '../../assets/vndb.json';

import { useForm } from '../../hooks';

import styles from './AddPopup.module.scss';

const AddStep = React.memo(({ customer, openModal, onClose, onCustomerCreate }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm({
    name: '',
    phone: '',
    email: '',
    idNumber: '',
    gender: '',
    ...customer,
  });

  const provinceOptions = useMemo(
    () =>
      vndb.province.map((p) => ({
        key: p.idProvince,
        value: p.name,
        text: p.name,
      })),
    [],
  );

  const districtOptions = useMemo(() => {
    let province = vndb.province.find((p) => p.name === data.province);
    if (!province) province = { idProvince: 0 };
    return vndb.district
      .filter((d) => d.idProvince === province.idProvince)
      .map((d) => ({
        key: d.idDistrict,
        value: d.name,
        text: d.name,
      }));
  }, [data.province]);

  const wardOptions = useMemo(() => {
    let district = vndb.district.find((d) => d.name === data.district);
    if (!district) district = { idDistrict: 0 };
    return vndb.ward
      .filter((w) => w.idDistrict === district.idDistrict)
      .map((w) => ({
        key: w.idWard,
        value: w.name,
        text: w.name,
      }));
  }, [data.district]);

  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [birthday, setBirthday] = useState(data.birthday ? new Date(data.birthday) : null);
  const [idDate, setIdDate] = useState(data.idDate ? new Date(data.idDate) : null);

  const handleChangeBirthday = (date) => {
    setBirthday(date);
  };

  const handleChangeIdDate = (date) => {
    setIdDate(date);
  };

  const handleSubmit = useCallback(() => {
    let newBirthday = null;
    let newIdDate = null;
    if (birthday) {
      newBirthday = t('format:postDate', {
        postProcess: 'formatDate',
        value: birthday,
      });
    }
    if (idDate) {
      newIdDate = t('format:postDate', {
        postProcess: 'formatDate',
        value: idDate,
      });
    }
    const newData = {
      ...data,
      birthday: newBirthday,
      idDate: newIdDate,
    };

    let isError;

    if (!newData.name) {
      setNameError(true);
      isError = true;
    }
    if (!newData.phone) {
      setPhoneError(true);
      isError = true;
    }
    if (!newData.email) {
      newData.email = null;
    }
    if (!newData.idNumber) {
      newData.idNumber = null;
    }
    if (isError) return;
    onCustomerCreate(newData);
    onClose();
  }, [birthday, data, idDate, onClose, onCustomerCreate, t]);

  useEffect(() => {
    setData({ ...customer });
  }, [customer, setData]);

  return (
    <Modal
      open={openModal}
      closeIcon
      size="small"
      centered={false}
      onClose={onClose}
      className={styles.modalWrapper}
    >
      <Modal.Header>{t('common.customer')}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={2}>
            <Form.Field required error={nameError} onFocus={() => setNameError(false)}>
              <label htmlFor="name">{t('common.customerGroup.name')}</label>
              <Input
                fluid
                name="name"
                placeholder={t('common.customerGroup.name')}
                value={data.name}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field required error={phoneError} onFocus={() => setPhoneError(false)}>
              <label htmlFor="phone">{t('common.customerGroup.phone')}</label>
              <Input
                fluid
                name="phone"
                placeholder={t('common.customerGroup.phone')}
                value={data.phone}
                onChange={handleFieldChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label htmlFor="email">{t('common.customerGroup.email')}</label>
              <Input
                fluid
                name="email"
                placeholder={t('common.customerGroup.email')}
                value={data.email || ''}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="birthDay">{t('common.customerGroup.birthDay')}</label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={birthday}
                onChange={handleChangeBirthday}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label htmlFor="idNumber">{t('common.customerGroup.idNumber')}</label>
              <Input
                fluid
                name="idNumber"
                placeholder={t('common.customerGroup.idNumber')}
                value={data.idNumber || ''}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="idDate">{t('common.customerGroup.idDate')}</label>
              <DatePicker dateFormat="dd/MM/yyyy" selected={idDate} onChange={handleChangeIdDate} />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label htmlFor="idPlace">{t('common.customerGroup.idPlace')}</label>
              <Input
                fluid
                name="idPlace"
                placeholder={t('common.customerGroup.idPlace')}
                value={data.idPlace || ''}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="province">{t('common.customerGroup.province')}</label>
              <Form.Dropdown
                fluid
                search
                selection
                name="province"
                value={data.province || ''}
                options={provinceOptions}
                className={styles.field}
                placeholder={t('common.customerGroup.province')}
                onChange={handleFieldChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label htmlFor="district">{t('common.customerGroup.district')}</label>
              <Form.Dropdown
                fluid
                search
                selection
                name="district"
                value={data.district || ''}
                options={districtOptions}
                className={styles.field}
                placeholder={t('common.customerGroup.district')}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="ward">{t('common.customerGroup.ward')}</label>
              <Form.Dropdown
                fluid
                search
                selection
                name="ward"
                value={data.ward || ''}
                options={wardOptions}
                className={styles.field}
                placeholder={t('common.customerGroup.ward')}
                onChange={handleFieldChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label htmlFor="address">{t('common.customerGroup.address')}</label>
              <Input
                fluid
                name="address"
                value={data.address || ''}
                className={styles.field}
                placeholder={t('common.customerGroup.address')}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field className={styles.fieldGender}>
              <label htmlFor="gender">{t('common.customerGroup.gender')}</label>
              <Checkbox
                radio
                className={styles.fieldCheckbox}
                label={t('common.customerGroup.male')}
                name="gender"
                value="male"
                checked={data.gender === 'male'}
                onChange={handleFieldChange}
              />
              <Checkbox
                radio
                className={styles.fieldCheckbox}
                label={t('common.customerGroup.female')}
                name="gender"
                value="female"
                checked={data.gender === 'female'}
                onChange={handleFieldChange}
              />
            </Form.Field>
          </Form.Group>
          <Button className={styles.buttonReview} primary onClick={handleSubmit}>
            {t('action.save')}
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
});

AddStep.propTypes = {
  customer: PropTypes.objectOf(PropTypes.shape).isRequired,
  openModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCustomerCreate: PropTypes.func.isRequired,
};

AddStep.defaultProps = {
  openModal: false,
};

export default AddStep;
