/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Input, Button } from 'semantic-ui-react';

import styles from './CustomerForm.module.scss';
import { useForm } from '../../../hooks';

const CustomerForm = React.memo(
  ({ isPrimary, defaultName, sellitemId, onCreateCustomer, onClose }) => {
    const [t] = useTranslation();

    const [data, handleFieldChange] = useForm({
      name: defaultName,
      phone: '',
      email: '',
      idNumber: '',
    });

    const [nameError, setNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const handleSubmit = useCallback(() => {
      const newData = {
        ...data,
        isPrimary,
        sellitemId,
      };

      let isError;

      if (!newData.name) {
        setNameError(true);
        isError = true;
      }
      if (!newData.phone) {
        setPhoneError(true);
        isError = true;
      }
      if (!newData.email) {
        newData.email = null;
      }
      if (!newData.idNumber) {
        newData.idNumber = null;
      }
      if (isError) return;
      onCreateCustomer(newData);
      onClose();
    }, [data, isPrimary, onClose, onCreateCustomer, sellitemId]);

    return (
      <Modal open closeIcon size="mini" centered={false} onClose={onClose}>
        <Modal.Header>{t('common.customer')}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field required error={nameError} onFocus={() => setNameError(false)}>
              <label htmlFor="name">{t('common.customerGroup.name')}</label>
              <Input
                fluid
                name="name"
                placeholder={t('common.customerGroup.name')}
                value={data.name}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field required error={phoneError} onFocus={() => setPhoneError(false)}>
              <label htmlFor="phone">{t('common.customerGroup.phone')}</label>
              <Input
                fluid
                name="phone"
                placeholder={t('common.phone')}
                value={data.phone}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="email">{t('common.customerGroup.email')}</label>
              <Input
                fluid
                name="email"
                placeholder={t('common.email')}
                value={data.email}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="idNumber">{t('common.customerGroup.idNumber')}</label>
              <Input
                fluid
                name="idNumber"
                placeholder={t('common.customerGroup.idNumber')}
                value={data.idNumber}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Button className={styles.buttonReview} primary onClick={handleSubmit}>
              {t('action.save')}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  },
);

CustomerForm.propTypes = {
  isPrimary: PropTypes.bool,
  defaultName: PropTypes.string,
  sellitemId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateCustomer: PropTypes.func.isRequired,
};

CustomerForm.defaultProps = {
  defaultName: undefined,
  isPrimary: false,
};

export default CustomerForm;
