import { call, put, select } from 'redux-saga/effects';

import { fetchCoreRequest } from '../requests';
import { currentUserIdSelector, pathSelector } from '../../../selectors';
import { handleSocketDisconnect, handleSocketReconnect } from '../../../actions';

export function* handleSocketDisconnectService() {
  yield put(handleSocketDisconnect());
}

export function* handleSocketReconnectService() {
  const currentUserId = yield select(currentUserIdSelector);
  const { groupId } = yield select(pathSelector);

  yield put(handleSocketReconnect.fetchCore(currentUserId, groupId));

  const {
    user,
    group,
    users,
    projects,
    projectManagers,
    agents,
    categories,
    accounts,
    groups,
    groupMemberships,
    labels,
    blocks,
    customers,
    products,
    bags,
    bagitems,
    discounts,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    sellitemCustomers,
    sellitemDiscounts,
    payments,
    attachments,
    actions,
    notifications,
  } = yield call(fetchCoreRequest); // TODO: handle error

  yield put(
    handleSocketReconnect(
      user,
      group,
      users,
      projects,
      projectManagers,
      agents,
      categories,
      accounts,
      groups,
      groupMemberships,
      labels,
      blocks,
      customers,
      products,
      bags,
      bagitems,
      discounts,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      sellitemCustomers,
      sellitemDiscounts,
      payments,
      attachments,
      actions,
      notifications,
    ),
  );
}
