import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createBagitem,
  deleteBagitem,
  handleBagitemCreate,
  handleBagitemDelete,
  handleBagitemUpdate,
  updateBagitem,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createBagitemService(bagId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createBagitem({
      ...nextData,
      bagId,
      id: localId,
    }),
  );

  let bagitem;
  try {
    ({ item: bagitem } = yield call(request, api.createBagitem, bagId, nextData));
  } catch (error) {
    yield put(createBagitem.failure(localId, error));
    return;
  }

  yield put(createBagitem.success(localId, bagitem));
}

export function* createBagitemInCurrentBagService(data) {
  const { bagId } = yield select(pathSelector);

  yield call(createBagitemService, bagId, data);
}

export function* handleBagitemCreateService(label) {
  yield put(handleBagitemCreate(label));
}

export function* updateBagitemService(id, data) {
  yield put(updateBagitem(id, data));

  let bagitem;
  try {
    ({ item: bagitem } = yield call(request, api.updateBagitem, id, data));
  } catch (error) {
    yield put(updateBagitem.failure(id, error));
    return;
  }

  yield put(updateBagitem.success(bagitem));
}

export function* handleBagitemUpdateService(bag) {
  yield put(handleBagitemUpdate(bag));
}

export function* deleteBagitemService(id) {
  yield put(deleteBagitem(id));

  let bagitem;
  try {
    ({ item: bagitem } = yield call(request, api.deleteBagitem, id));
  } catch (error) {
    yield put(deleteBagitem.failure(id, error));
    return;
  }

  yield put(deleteBagitem.success(bagitem));
}

export function* handleBagitemDeleteService(bagitem) {
  yield put(handleBagitemDelete(bagitem));
}
