import { connect } from 'react-redux';

import { currentGroupSelector, pathSelector, pathsMatchSelector } from '../selectors';
import Fixed from '../components/Fixed';

const mapStateToProps = (state) => {
  const { projectId } = pathSelector(state);
  const currentGroup = currentGroupSelector(state);
  const { path } = pathsMatchSelector(state);

  return {
    path,
    projectId,
    group: currentGroup,
  };
};

export default connect(mapStateToProps)(Fixed);
