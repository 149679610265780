import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, List } from 'semantic-ui-react';
// import ActionsPopup from './ActionsPopup';
import AddPopup from './AddPopup';
import BagListItemContainer from '../../containers/BagListItemContainer';
// import { ReactComponent as DotIcon } from '../../assets/images/dots-vertical.svg';

import styles from './LeftSidebar.module.scss';

const LeftSidebar = React.memo(({ bagIds, categories, canEdit, onBagCreate }) => {
  const [t] = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebarLeftHeader}>
        <div className={styles.searchWrapper}>{`${bagIds.length - 1} ${t('common.bag')}`}</div>
        {canEdit && (
          <>
            <AddPopup categories={categories} onCreate={onBagCreate}>
              <Button
                circular
                size="mini"
                icon="shopping bag"
                className={styles.addProductButton}
              />
            </AddPopup>
            {/* <ActionsPopup>
              <div className={styles.actions}>
                <DotIcon className={styles.dotIcon} />
              </div>
            </ActionsPopup> */}
          </>
        )}
      </div>
      <List divided selection className={styles.wrapperProducts}>
        {bagIds.map((bagId, index) => (
          <BagListItemContainer key={bagId} id={bagId} index={index} />
        ))}
      </List>
    </div>
  );
});

LeftSidebar.propTypes = {
  bagIds: PropTypes.arrayOf(PropTypes.object()).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object()).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onBagCreate: PropTypes.func.isRequired,
};

export default LeftSidebar;
