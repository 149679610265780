import { call, put, select } from 'redux-saga/effects';

import { goToProjectService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createAgent,
  deleteAgent,
  handleAgentCreate,
  handleAgentDelete,
  handleAgentUpdate,
  updateAgent,
  addAgentToGroupFilter,
  removeAgentFromGroupFilter,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createAgentService(data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createAgent({
      ...nextData,
      // projectId,
      id: localId,
    }),
  );

  let agent;

  try {
    ({ item: agent } = yield call(request, api.createAgent, nextData));
  } catch (error) {
    yield put(createAgent.failure(localId, error));
    return;
  }

  yield put(createAgent.success(localId, agent));
}

export function* createAgentInCurrentProjectService(data) {
  const { projectId } = yield select(pathSelector);

  yield call(createAgentService, projectId, data);
}

export function* handleAgentCreateService(agent) {
  yield put(handleAgentCreate(agent));
}

export function* updateAgentService(id, data) {
  yield put(updateAgent(id, data));

  let agent;
  try {
    ({ item: agent } = yield call(request, api.updateAgent, id, data));
  } catch (error) {
    yield put(updateAgent.failure(id, error));
    return;
  }

  yield put(updateAgent.success(agent));
}

export function* handleAgentUpdateService(agent) {
  yield put(handleAgentUpdate(agent));
}

export function* deleteAgentService(id) {
  const { agentId, projectId } = yield select(pathSelector);

  if (id === agentId) {
    yield call(goToProjectService, projectId);
  }

  yield put(deleteAgent(id));

  let agent;
  try {
    ({ item: agent } = yield call(request, api.deleteAgent, id));
  } catch (error) {
    yield put(deleteAgent.failure(id, error));
    return;
  }

  yield put(deleteAgent.success(agent));
}

export function* handleAgentDeleteService(agent) {
  const { agentId, projectId } = yield select(pathSelector);

  if (agent.id === agentId) {
    yield call(goToProjectService, projectId);
  }

  yield put(handleAgentDelete(agent));
}

export function* addAgentToGroupFilterService(id, groupId) {
  yield put(addAgentToGroupFilter(id, groupId));
}

export function* addAgentToFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(addAgentToGroupFilterService, id, groupId);
}

export function* removeAgentFromGroupFilterService(id, groupId) {
  yield put(removeAgentFromGroupFilter(id, groupId));
}

export function* removeAgentFromFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(removeAgentFromGroupFilterService, id, groupId);
}
