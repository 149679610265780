import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import { updateSellitem, recalculateCommission } from '../actions/entry';
import { PaymentStatuses, Statuses, ProductStatuses } from '../constants/Enums';

import {
  pathSelector,
  sellitemsForCurrentGroupSelector,
  paymentsBySellitemIdSelector,
  customerByIdSelector,
  isAdminSelector,
  bagsForCurrentGroupSelector,
  isAccountantSelector,
  isManagerSelector,
} from '../selectors';

import Accountant from '../components/Accountant';

const mapStateToProps = (state) => {
  const { isSubmitting, error } = state.ui.bagCommissionForm;
  const { groupId } = pathSelector(state);
  const listStatus = [Statuses.CANCELED.name, Statuses.BOOKING.name, Statuses.REVIEW.name];
  let sellitems = sellitemsForCurrentGroupSelector(state);
  sellitems = sellitems
    .filter((sl) => !listStatus.includes(sl.status))
    .map((sl) => {
      let customer;
      if (sl.customerId) customer = customerByIdSelector(state, sl.customerId);
      let payments = paymentsBySellitemIdSelector(state, sl.id);
      const totalPaid = payments
        .filter((pm) => pm.status === PaymentStatuses.FINISH.name)
        .reduce((sum, { amount }) => sum + amount * 1, 0);
      payments = payments.map((pm) => {
        if (pm.status === PaymentStatuses.FINISH.name) {
          return {
            amount: pm.amount,
            gotDate: pm?.gotDate ? format(new Date(pm.gotDate), 'dd/MM/yyyy') : '',
          };
        }
        return {
          amount: 0,
          gotDate: '',
        };
      });
      const totalDiscount =
        sl.discount1 * 1 +
        sl.discount2 * 1 +
        sl.discount3 * 1 +
        sl.discount4 * 1 +
        sl.discount5 * 1;

      let discountMore = 0;
      if (sl.loanPercent && sl.loanAmount) {
        discountMore = Math.round(((sl.loanPercent * 1) / 100) * sl.loanAmount * 1);
      }

      let totalCommissionAmount = 0;
      if (sl.product?.status === ProductStatuses.SELLED.name) {
        totalCommissionAmount = Math.round(
          (sl.totalForCommission * 1 * sl.commissionPercent) / 100 + sl.bonus * 1,
        );
      }

      return {
        ...sl,
        statusLabel: Statuses[sl.status.toUpperCase()]?.label,
        customer,
        totalDiscount,
        totalPaid,
        discountMore,
        remain: sl.total * 1 - totalPaid * 1,
        totalLandPrice: sl.product.landArea * sl.product.landPrice * sl.product.factor,
        totalFloorPrice: sl.product.floorArea * sl.product.floorPrice,
        commissionAmount: (sl.totalForCommission * 1 * sl.commissionPercent) / 100,
        totalCommissionAmount,
        payments,
      };
    });

  let bags = bagsForCurrentGroupSelector(state);

  bags = bags.map((bag) => {
    return {
      key: bag.id,
      value: bag.id,
      text: bag.name,
    };
  });

  const isAdmin = isAdminSelector(state);
  const isManager = isManagerSelector(state);
  const isAccountant = isAccountantSelector(state);

  return {
    groupId,
    sellitems,
    bags,
    canEdit: isAdmin || isAccountant || isManager,
    canRecalculate: isAdmin || isAccountant,
    isSubmitting,
    error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdateSellitem: updateSellitem,
      onRecalculateCommission: recalculateCommission,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Accountant);
