import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import styles from './Dashboard.module.scss';

const CardInfo = ({ title, icon, value, percent, color, background }) => {
  return (
    <div className={styles.cardInfoWrapper}>
      <div className={styles.cardData}>
        <div className={styles.cardTitle}>{title}</div>
        <div className={styles.cardValue}>
          <span className={styles.cardValueNumber}>{value}</span>
          <span className={styles.cardValuePerson}>{percent}</span>
        </div>
      </div>
      <div className={styles.cardIcon} style={{ color, backgroundColor: background }}>
        <Icon name={icon} />
      </div>
    </div>
  );
};

CardInfo.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percent: PropTypes.string,
  color: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};

CardInfo.defaultProps = {
  percent: '',
};

export default CardInfo;
