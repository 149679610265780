import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { isLocalId } from '../utils/local-id';
import { BagStatuses } from '../constants/Enums';

export const makeBlockByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Block }, id) => {
      const blockModel = Block.withId(id);

      if (!blockModel) {
        return blockModel;
      }

      return {
        ...blockModel.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const blockByIdSelector = makeBlockByIdSelector();

export const currentBlockSelector = createSelector(
  orm,
  (state) => pathSelector(state).blockId,
  ({ Block }, id) => {
    if (!id) {
      return id;
    }

    const blockModel = Block.withId(id);

    if (!blockModel) {
      return blockModel;
    }

    return blockModel.ref;
  },
);

// export const makeProductIdsByBlockIdSelector = () =>
//   createSelector(
//     orm,
//     (_, id) => id,
//     ({ Block }, id) => {
//       const blockModel = Block.withId(id);

//       if (!blockModel) {
//         return blockModel;
//       }

//       return blockModel
//         .getOrderedFilteredProductsModelArray()
//         .map((productModel) => productModel.id);
//     },
//   );

// export const productIdsByBlockIdSelector = makeProductIdsByBlockIdSelector();

export const makeProductIdsByBlockIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Group, Block }, id) => {
      if (!id) {
        return id;
      }

      const blockModel = Block.withId(id);

      if (!blockModel) {
        return blockModel;
      }

      const groupModel = Group.withId(blockModel.groupId);

      if (!groupModel) {
        return groupModel;
      }

      let products = [];
      const bagitemProductIds = [];
      const today = new Date();
      groupModel
        .getOrderedBagsQuerySet()
        .toModelArray()
        .filter(
          (bagModel) => bagModel.status === BagStatuses.PUBLISH.name && bagModel.endDate >= today,
        )
        .map((bagModel) => {
          return bagModel
            .getOrderedBagitemsQuerySet()
            .toModelArray()
            .forEach((bagitemModel) => {
              const p = bagitemModel.product.ref;
              if (!bagitemProductIds.includes(p.id)) {
                bagitemProductIds.push(p.id);
                products.push(p);
              }
            });
        });

      const filterUserIds = groupModel.filterUsers.toRefArray().map((user) => user.id);
      const filterAgentIds = groupModel.filterAgents.toRefArray().map((agent) => agent.id);
      const { filterProductStatuses } = groupModel;

      if (filterUserIds.length > 0) {
        products = products.filter((productModel) => {
          const users = productModel.users.toRefArray();

          return users.some((user) => filterUserIds.includes(user.id));
        });
      }

      if (filterAgentIds.length > 0) {
        products = products.filter((productModel) => filterAgentIds.includes(productModel.agentId));
      }

      if (filterProductStatuses.length > 0) {
        products = products.filter((productModel) =>
          filterProductStatuses.includes(productModel.status),
        );
      }

      return products.filter((p) => p.blockId === id).map((p) => p.id);
    },
  );

export const productIdsByBlockIdSelector = makeProductIdsByBlockIdSelector();

export const makeProductsByBlockIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Block }, id) => {
      const blockModel = Block.withId(id);

      if (!blockModel) {
        return blockModel;
      }

      return blockModel.getOrderedFilteredProductsModelArray().map((productModel) => ({
        ...productModel.ref,
        creator: productModel.creator?.ref.name,
        block: productModel.block.ref.name,
        agent: productModel.product?.agent.ref,
        product: productModel.product?.ref,
      }));
    },
  );

export const productsByBlockIdSelector = makeProductsByBlockIdSelector();
