import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { withPopup } from '../../lib/popup';
import { Popup } from '../../lib/custom-ui';

import styles from './MenusPopup.module.scss';

const MenusStep = React.memo(({ canExport, onExport, onClose }) => {
  const [t] = useTranslation();

  const handleExportClick = useCallback(() => {
    onExport();
    onClose();
  }, [onClose, onExport]);

  return (
    <div>
      <Popup.Content>
        <Menu secondary vertical className={styles.menu}>
          <Menu.Item className={styles.menuItem} onClick={handleExportClick} disabled={!canExport}>
            {t('action.exportReport')}
          </Menu.Item>
        </Menu>
      </Popup.Content>
    </div>
  );
});

MenusStep.propTypes = {
  canExport: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withPopup(MenusStep);
