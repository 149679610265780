import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import PaymentTable from '../../PaymentTable';
import styles from './Payments.module.scss';

const Payments = React.memo(
  ({
    sellitem,
    payments,
    accounts,
    customers,
    discounts,
    product,
    description,
    paymentDeposit,
    paymentConfirm1,
    paymentConfirm2,
    contract1,
    contract2,
    contract3,
    contract4,
    appendix1,
    appendix2,
    appendix3,
    appendix4,
    appendix5,
    canEdit,
    onUpdateSellitem,
    onUpdatePayment,
    onPaymentCreate,
    onDeletePayment,
  }) => {
    // const [t] = useTranslation();
    return (
      <div className={styles.wrapper}>
        <PaymentTable
          sellitem={sellitem}
          payments={payments}
          accounts={accounts}
          customers={customers}
          discounts={discounts}
          product={product}
          description={description}
          paymentDeposit={paymentDeposit}
          paymentConfirm1={paymentConfirm1}
          paymentConfirm2={paymentConfirm2}
          contract1={contract1}
          contract2={contract2}
          contract3={contract3}
          contract4={contract4}
          appendix1={appendix1}
          appendix2={appendix2}
          appendix3={appendix3}
          appendix4={appendix4}
          appendix5={appendix5}
          canEdit={canEdit}
          onUpdateSellitem={onUpdateSellitem}
          onUpdatePayment={onUpdatePayment}
          onPaymentCreate={onPaymentCreate}
          onDeletePayment={onDeletePayment}
        />
      </div>
    );
  },
);

Payments.propTypes = {
  sellitem: PropTypes.objectOf(PropTypes.shape).isRequired,
  payments: PropTypes.arrayOf(PropTypes.object()).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object()).isRequired,
  customers: PropTypes.arrayOf(PropTypes.object()).isRequired,
  discounts: PropTypes.arrayOf(PropTypes.object()).isRequired,
  product: PropTypes.objectOf(PropTypes.shape).isRequired,
  description: PropTypes.string.isRequired,
  paymentDeposit: PropTypes.string.isRequired,
  paymentConfirm1: PropTypes.string.isRequired,
  paymentConfirm2: PropTypes.string.isRequired,
  contract1: PropTypes.string.isRequired,
  contract2: PropTypes.string.isRequired,
  contract3: PropTypes.string.isRequired,
  contract4: PropTypes.string.isRequired,
  appendix1: PropTypes.string.isRequired,
  appendix2: PropTypes.string.isRequired,
  appendix3: PropTypes.string.isRequired,
  appendix4: PropTypes.string.isRequired,
  appendix5: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onUpdateSellitem: PropTypes.func.isRequired,
  onUpdatePayment: PropTypes.func.isRequired,
  onPaymentCreate: PropTypes.func.isRequired,
  onDeletePayment: PropTypes.func.isRequired,
};

export default Payments;
