import React from 'react';
import PropTypes from 'prop-types';

import Paths from '../constants/Paths';
import ModalTypes from '../constants/ModalTypes';
import FixedContainer from '../containers/FixedContainer';
import StaticContainer from '../containers/StaticContainer';
import UsersModalContainer from '../containers/UsersModalContainer';
import UserSettingsModalContainer from '../containers/UserSettingsModalContainer';
import ProjectAddModalContainer from '../containers/ProjectAddModalContainer';
import ProductAddModalContainer from '../containers/ProductAddModalContainer';
import DashboardContainer from '../containers/DashboardContainer';
import Background from './Background';

const Core = ({ path, currentModal, currentProject }) => (
  <>
    {currentProject && currentProject.background && (
      <Background
        type={currentProject.background.type}
        name={currentProject.background.name}
        imageUrl={currentProject.backgroundImage && currentProject.backgroundImage.url}
      />
    )}
    {path !== Paths.DASHBOARD ? (
      <>
        <FixedContainer />
        <StaticContainer />
      </>
    ) : (
      <DashboardContainer />
    )}
    {currentModal === ModalTypes.USERS && <UsersModalContainer />}
    {currentModal === ModalTypes.USER_SETTINGS && <UserSettingsModalContainer />}
    {currentModal === ModalTypes.PROJECT_ADD && <ProjectAddModalContainer />}
    {currentModal === ModalTypes.PRODUCT_ADD && <ProductAddModalContainer />}
  </>
);

Core.propTypes = {
  path: PropTypes.string,
  currentModal: PropTypes.oneOf(Object.values(ModalTypes)),
  currentProject: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Core.defaultProps = {
  path: undefined,
  currentModal: undefined,
  currentProject: undefined,
};

export default Core;
