import socket from './socket';

/* Actions */

const createBagitem = (bagId, data, headers) =>
  socket.post(`/bags/${bagId}/bagitems`, data, headers).then((body) => ({
    ...body,
  }));

const updateBagitem = (bagitemId, data, headers) =>
  socket.patch(`/bagitems/${bagitemId}`, data, headers).then((body) => ({
    ...body,
  }));

const deleteBagitem = (bagitemId, headers) =>
  socket.delete(`/bagitems/${bagitemId}`, undefined, headers).then((body) => ({
    ...body,
  }));

export default {
  createBagitem,
  updateBagitem,
  deleteBagitem,
};
