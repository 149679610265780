import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import ContractPaper from '../PaymentTable/ContractPaper';
import PrintContractPaper from '../PaymentTable/PrintContractPaper';

import { Statuses } from '../../constants/Enums';

const ButtonContractRenderer = React.memo(
  ({
    row,
    // col,
    // prop,
    // cellProperties,
    // value,
    sellitem,
    payments,
    accounts,
    customers,
    discounts,
    product,
    description,
    contract1,
    contract2,
    contract3,
    appendix1,
    appendix2,
    appendix3,
    appendix4,
    appendix5,
  }) => {
    // The avaiable renderer-related props are:
    // - row (row index)
    // - col (column index)
    // - prop (column property name)
    // - TD (the HTML cell element)
    // - cellProperties (the cellProperties object for the edited cell)
    // console.log(props);

    const printPayment = () => {
      if (row === 0) {
        let contract;
        if (sellitem.paymentMethod === 'process') {
          contract = contract1;
        } else if (sellitem.paymentMethod === 'quick') {
          contract = contract2;
        } else {
          contract = contract3;
        }
        let htmlPrint = ContractPaper(
          sellitem,
          payments,
          accounts,
          customers,
          discounts,
          product,
          row,
          description,
          contract,
        );
        if (appendix1) {
          htmlPrint += ContractPaper(
            sellitem,
            payments,
            accounts,
            customers,
            discounts,
            product,
            row,
            description,
            appendix1,
          );
          // htmlPrint += '<div class="pagebreak"> </div>';
        }
        if (appendix2) {
          htmlPrint += ContractPaper(
            sellitem,
            payments,
            accounts,
            customers,
            discounts,
            product,
            row,
            description,
            appendix2,
          );
          // htmlPrint += '<div class="pagebreak"> </div>';
        }
        if (appendix3) {
          htmlPrint += ContractPaper(
            sellitem,
            payments,
            accounts,
            customers,
            discounts,
            product,
            row,
            description,
            appendix3,
          );
          // htmlPrint += '<div class="pagebreak"> </div>';
        }
        if (appendix4) {
          htmlPrint += ContractPaper(
            sellitem,
            payments,
            accounts,
            customers,
            discounts,
            product,
            row,
            description,
            appendix4,
          );
          // htmlPrint += '<div class="pagebreak"> </div>';
        }
        if (appendix5) {
          htmlPrint += ContractPaper(
            sellitem,
            payments,
            accounts,
            customers,
            discounts,
            product,
            row,
            description,
            appendix5,
          );
          // htmlPrint += '<div class="pagebreak"> </div>';
        }
        PrintContractPaper(htmlPrint);
      }
    };
    const listStatus = [Statuses.BOOKING.name, Statuses.REVIEW.name, Statuses.CANCELED.name];
    return (
      <div style={{ width: '100%' }}>
        {row === 0 && (
          <Button
            disabled={listStatus.includes(sellitem.status)}
            size="mini"
            icon="print"
            style={{ width: '100%', margin: '-10px 0px 0px -10px' }}
            onClick={printPayment}
          />
        )}
      </div>
    );
  },
);

ButtonContractRenderer.propTypes = {
  row: PropTypes.number,
  // col: PropTypes.number,
  // prop: PropTypes.string,
  // cellProperties: PropTypes.objectOf(PropTypes.shape),
  // value: PropTypes.string,
  // payments: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  sellitem: PropTypes.objectOf(PropTypes.shape).isRequired,
  payments: PropTypes.arrayOf(PropTypes.object()).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object()).isRequired,
  customers: PropTypes.arrayOf(PropTypes.object()).isRequired,
  discounts: PropTypes.arrayOf(PropTypes.object()).isRequired,
  product: PropTypes.objectOf(PropTypes.shape).isRequired,
  description: PropTypes.string.isRequired,
  contract1: PropTypes.string.isRequired,
  contract2: PropTypes.string.isRequired,
  contract3: PropTypes.string.isRequired,
  appendix1: PropTypes.string.isRequired,
  appendix2: PropTypes.string.isRequired,
  appendix3: PropTypes.string.isRequired,
  appendix4: PropTypes.string.isRequired,
  appendix5: PropTypes.string.isRequired,
};

ButtonContractRenderer.defaultProps = {
  row: undefined,
  // col: undefined,
  // prop: undefined,
  // value: undefined,
  // cellProperties: undefined,
};

export default ButtonContractRenderer;
