import EntryActionTypes from '../../constants/EntryActionTypes';

export const createGroupInCurrentProject = (data) => ({
  type: EntryActionTypes.GROUP_IN_CURRENT_PROJECT_CREATE,
  payload: {
    data,
  },
});

export const handleGroupCreate = (group) => ({
  type: EntryActionTypes.GROUP_CREATE_HANDLE,
  payload: {
    group,
  },
});

export const fetchGroup = (id) => ({
  type: EntryActionTypes.GROUP_FETCH,
  payload: {
    id,
  },
});

export const updateGroup = (id, data) => ({
  type: EntryActionTypes.GROUP_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleGroupUpdate = (group) => ({
  type: EntryActionTypes.GROUP_UPDATE_HANDLE,
  payload: {
    group,
  },
});

export const moveGroup = (id, index) => ({
  type: EntryActionTypes.GROUP_MOVE,
  payload: {
    id,
    index,
  },
});

export const deleteGroup = (id) => ({
  type: EntryActionTypes.GROUP_DELETE,
  payload: {
    id,
  },
});

export const handleGroupDelete = (group) => ({
  type: EntryActionTypes.GROUP_DELETE_HANDLE,
  payload: {
    group,
  },
});
