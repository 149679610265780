import ActionTypes from '../constants/ActionTypes';

export const createAccount = (account) => ({
  type: ActionTypes.ACCOUNT_CREATE,
  payload: {
    account,
  },
});

createAccount.success = (localId, account) => ({
  type: ActionTypes.ACCOUNT_CREATE__SUCCESS,
  payload: {
    localId,
    account,
  },
});

createAccount.failure = (localId, error) => ({
  type: ActionTypes.ACCOUNT_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleAccountCreate = (account) => ({
  type: ActionTypes.ACCOUNT_CREATE_HANDLE,
  payload: {
    account,
  },
});

export const updateAccount = (id, data) => ({
  type: ActionTypes.ACCOUNT_UPDATE,
  payload: {
    id,
    data,
  },
});

updateAccount.success = (account) => ({
  type: ActionTypes.ACCOUNT_UPDATE__SUCCESS,
  payload: {
    account,
  },
});

updateAccount.failure = (id, error) => ({
  type: ActionTypes.ACCOUNT_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleAccountUpdate = (account) => ({
  type: ActionTypes.ACCOUNT_UPDATE_HANDLE,
  payload: {
    account,
  },
});

export const deleteAccount = (id) => ({
  type: ActionTypes.ACCOUNT_DELETE,
  payload: {
    id,
  },
});

deleteAccount.success = (account) => ({
  type: ActionTypes.ACCOUNT_DELETE__SUCCESS,
  payload: {
    account,
  },
});

deleteAccount.failure = (id, error) => ({
  type: ActionTypes.ACCOUNT_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleAccountDelete = (account) => ({
  type: ActionTypes.ACCOUNT_DELETE_HANDLE,
  payload: {
    account,
  },
});
