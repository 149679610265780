import { eventChannel } from 'redux-saga';
import { all, call, cancelled, put, take, takeEvery } from 'redux-saga/effects';

import { handleSocketDisconnectService, handleSocketReconnectService } from '../services';
import {
  handleProjectManagerCreate as handleProjectManagerCreateAction,
  handleProjectManagerDelete as handleProjectManagerDeleteAction,
  handleAgentCreate as handleAgentCreateAction,
  handleAgentUpdate as handleAgentUpdateAction,
  handleAgentDelete as handleAgentDeleteAction,
  handleCategoryCreate as handleCategoryCreateAction,
  handleCategoryUpdate as handleCategoryUpdateAction,
  handleCategoryDelete as handleCategoryDeleteAction,
  handleAccountCreate as handleAccountCreateAction,
  handleAccountUpdate as handleAccountUpdateAction,
  handleAccountDelete as handleAccountDeleteAction,
  handleGroupCreate as handleGroupCreateAction,
  handleGroupUpdate as handleGroupUpdateAction,
  handleGroupDelete as handleGroupDeleteAction,
  handleGroupMembershipCreate as handleGroupMembershipCreateAction,
  handleGroupMembershipDelete as handleGroupMembershipDeleteAction,
  handleBlockCreate as handleBlockCreateAction,
  handleBlockUpdate as handleBlockUpdateAction,
  handleBlockDelete as handleBlockDeleteAction,
  handleCustomerCreate as handleCustomerCreateAction,
  handleCustomerUpdate as handleCustomerUpdateAction,
  handleCustomerDelete as handleCustomerDeleteAction,
  handleProductCreate as handleProductCreateAction,
  handleProductUpdate as handleProductUpdateAction,
  handleProductDelete as handleProductDeleteAction,
  handleProductImport as handleProductImportAction,
  handleLabelCreate as handleLabelCreateAction,
  handleLabelUpdate as handleLabelUpdateAction,
  handleLabelDelete as handleLabelDeleteAction,
  handleSellitemCreate as handleSellitemCreateAction,
  handleSellitemUpdate as handleSellitemUpdateAction,
  handleSellitemDelete as handleSellitemDeleteAction,
  handleUserToSellitemAdd as handleUserToSellitemAddAction,
  handleUserFromSellitemRemove as handleUserFromSellitemRemoveAction,
  handleLabelToSellitemAdd as handleLabelToSellitemAddAction,
  handleLabelFromSellitemRemove as handleLabelFromSellitemRemoveAction,
  handlePaymentCreate as handlePaymentCreateAction,
  handlePaymentUpdate as handlePaymentUpdateAction,
  handlePaymentDelete as handlePaymentDeleteAction,
  handlePaymentInit as handlePaymentInitAction,
  handleAttachmentCreate as handleAttachmentCreateAction,
  handleAttachmentUpdate as handleAttachmentUpdateAction,
  handleAttachmentDelete as handleAttachmentDeleteAction,
  handleActionCreate as handleActionCreateAction,
  handleActionUpdate as handleActionUpdateAction,
  handleActionDelete as handleActionDeleteAction,
  handleNotificationCreate as handleNotificationCreateAction,
  handleNotificationDelete as handleNotificationDeleteAction,
  handleSocketDisconnect as handleSocketDisconnectAction,
  handleUserCreate as handleUserCreateAction,
  handleUserUpdate as handleUserUpdateAction,
  handleUserDelete as handleUserDeleteAction,
  handleProjectCreate as handleProjectCreateAction,
  handleProjectUpdate as handleProjectUpdateAction,
  handleProjectDelete as handleProjectDeleteAction,
  handleSocketReconnect as handleSocketReconnectAction,
  handleBagCreate as handleBagCreateAction,
  handleBagUpdate as handleBagUpdateAction,
  handleBagDelete as handleBagDeleteAction,
  handleSellitemCustomerDelete as handleSellitemCustomerDeleteAction,
  handleSellitemCustomerCreate as handleSellitemCustomerCreateAction,
  handleSellitemDiscountCreate as handleSellitemDiscountCreateAction,
  handleSellitemDiscountDelete as handleSellitemDiscountDeleteAction,
} from '../../../actions/entry';
import api, { socket } from '../../../api';
import EntryActionTypes from '../../../constants/EntryActionTypes';

const createSocketEventsChannel = () =>
  eventChannel((emit) => {
    const handleDisconnect = () => {
      emit(handleSocketDisconnectAction());
    };

    const handleReconnect = () => {
      emit(handleSocketReconnectAction());
    };

    const handleUserCreate = ({ item }) => {
      emit(handleUserCreateAction(item));
    };

    const handleUserUpdate = ({ item }) => {
      emit(handleUserUpdateAction(item));
    };

    const handleUserDelete = ({ item }) => {
      emit(handleUserDeleteAction(item));
    };

    const handleProductCreate = ({ item }) => {
      emit(handleProductCreateAction(item));
    };

    const handleProductUpdate = ({ item }) => {
      emit(handleProductUpdateAction(item));
    };

    const handleProductDelete = ({ item }) => {
      emit(handleProductDeleteAction(item));
    };

    const handleProductImport = ({ item, included }) => {
      emit(handleProductImportAction(item, included.bagitems));
    };

    const handleProjectCreate = ({ item }) => {
      emit(handleProjectCreateAction(item));
    };

    const handleProjectUpdate = ({ item }) => {
      emit(handleProjectUpdateAction(item));
    };

    const handleProjectDelete = ({ item }) => {
      emit(handleProjectDeleteAction(item));
    };

    const handleProjectManagerCreate = ({ item }) => {
      emit(handleProjectManagerCreateAction(item));
    };

    const handleProjectManagerDelete = ({ item }) => {
      emit(handleProjectManagerDeleteAction(item));
    };

    const handleAgentCreate = ({ item }) => {
      emit(handleAgentCreateAction(item));
    };

    const handleAgentUpdate = ({ item }) => {
      emit(handleAgentUpdateAction(item));
    };

    const handleAgentDelete = ({ item }) => {
      emit(handleAgentDeleteAction(item));
    };

    const handleCategoryCreate = ({ item }) => {
      emit(handleCategoryCreateAction(item));
    };

    const handleCategoryUpdate = ({ item }) => {
      emit(handleCategoryUpdateAction(item));
    };

    const handleCategoryDelete = ({ item }) => {
      emit(handleCategoryDeleteAction(item));
    };

    const handleAccountCreate = ({ item }) => {
      emit(handleAccountCreateAction(item));
    };

    const handleAccountUpdate = ({ item }) => {
      emit(handleAccountUpdateAction(item));
    };

    const handleAccountDelete = ({ item }) => {
      emit(handleAccountDeleteAction(item));
    };

    const handleGroupCreate = ({ item }) => {
      emit(handleGroupCreateAction(item));
    };

    const handleGroupUpdate = ({ item }) => {
      emit(handleGroupUpdateAction(item));
    };

    const handleGroupDelete = ({ item }) => {
      emit(handleGroupDeleteAction(item));
    };

    const handleGroupMembershipCreate = ({ item }) => {
      emit(handleGroupMembershipCreateAction(item));
    };

    const handleGroupMembershipDelete = ({ item }) => {
      emit(handleGroupMembershipDeleteAction(item));
    };

    const handleBlockCreate = ({ item }) => {
      emit(handleBlockCreateAction(item));
    };

    const handleBlockUpdate = ({ item }) => {
      emit(handleBlockUpdateAction(item));
    };

    const handleBlockDelete = ({ item }) => {
      emit(handleBlockDeleteAction(item));
    };

    const handleCustomerCreate = ({ item }) => {
      emit(handleCustomerCreateAction(item));
    };

    const handleCustomerUpdate = ({ item }) => {
      emit(handleCustomerUpdateAction(item));
    };

    const handleCustomerDelete = ({ item }) => {
      emit(handleCustomerDeleteAction(item));
    };

    const handleLabelCreate = ({ item }) => {
      emit(handleLabelCreateAction(item));
    };

    const handleLabelUpdate = ({ item }) => {
      emit(handleLabelUpdateAction(item));
    };

    const handleLabelDelete = ({ item }) => {
      emit(handleLabelDeleteAction(item));
    };

    const handleSellitemCreate = api.makeHandleSellitemCreate(({ item }) => {
      emit(handleSellitemCreateAction(item));
    });

    const handleSellitemUpdate = api.makeHandleSellitemUpdate(({ item, includes }) => {
      emit(handleSellitemUpdateAction(item, includes.customers));
    });

    const handleSellitemDelete = api.makeHandleSellitemDelete(({ item }) => {
      emit(handleSellitemDeleteAction(item));
    });

    const handleUserToSellitemAdd = ({ item }) => {
      emit(handleUserToSellitemAddAction(item));
    };

    const handleUserFromSellitemRemove = ({ item }) => {
      emit(handleUserFromSellitemRemoveAction(item));
    };

    const handleLabelToSellitemAdd = ({ item }) => {
      emit(handleLabelToSellitemAddAction(item));
    };

    const handleLabelFromSellitemRemove = ({ item }) => {
      emit(handleLabelFromSellitemRemoveAction(item));
    };

    const handlePaymentCreate = ({ item }) => {
      emit(handlePaymentCreateAction(item));
    };

    const handlePaymentUpdate = ({ item }) => {
      emit(handlePaymentUpdateAction(item));
    };

    const handlePaymentDelete = ({ item }) => {
      emit(handlePaymentDeleteAction(item));
    };

    const handlePaymentInit = ({ item }) => {
      emit(handlePaymentInitAction(item));
    };

    const handleBagCreate = api.makeHandleBagCreate(({ item }) => {
      emit(handleBagCreateAction(item));
    });

    const handleBagUpdate = api.makeHandleBagUpdate(({ item }) => {
      emit(handleBagUpdateAction(item));
    });

    const handleBagDelete = api.makeHandleBagDelete(({ item }) => {
      emit(handleBagDeleteAction(item));
    });

    const handleAttachmentCreate = api.makeHandleAttachmentCreate(({ item, requestId }) => {
      emit(handleAttachmentCreateAction(item, requestId));
    });

    const handleAttachmentUpdate = api.makeHandleAttachmentUpdate(({ item }) => {
      emit(handleAttachmentUpdateAction(item));
    });

    const handleAttachmentDelete = api.makeHandleAttachmentDelete(({ item }) => {
      emit(handleAttachmentDeleteAction(item));
    });

    const handleActionCreate = api.makeHandleActionCreate(({ item }) => {
      emit(handleActionCreateAction(item));
    });

    const handleActionUpdate = api.makeHandleActionUpdate(({ item }) => {
      emit(handleActionUpdateAction(item));
    });

    const handleActionDelete = api.makeHandleActionDelete(({ item }) => {
      emit(handleActionDeleteAction(item));
    });

    const handleNotificationCreate = ({ item }) => {
      emit(handleNotificationCreateAction(item));
    };

    const handleNotificationDelete = ({ item }) => {
      emit(handleNotificationDeleteAction(item));
    };

    const handleSellitemCustomerDelete = ({ item }) => {
      emit(handleSellitemCustomerDeleteAction(item));
    };

    const handleSellitemCustomerCreate = ({ item, includes }) => {
      emit(handleSellitemCustomerCreateAction(item, includes.discounts));
    };

    const handleSellitemDiscountDelete = ({ item }) => {
      emit(handleSellitemDiscountDeleteAction(item));
    };

    const handleSellitemDiscountCreate = ({ item, includes }) => {
      emit(handleSellitemDiscountCreateAction(item, includes.discounts));
    };

    socket.on('disconnect', handleDisconnect);
    socket.on('reconnect', handleReconnect);

    socket.on('userCreate', handleUserCreate);
    socket.on('userUpdate', handleUserUpdate);
    socket.on('userDelete', handleUserDelete);

    socket.on('projectCreate', handleProjectCreate);
    socket.on('projectUpdate', handleProjectUpdate);
    socket.on('projectDelete', handleProjectDelete);

    socket.on('projectManagerCreate', handleProjectManagerCreate);
    socket.on('projectManagerDelete', handleProjectManagerDelete);

    socket.on('agentCreate', handleAgentCreate);
    socket.on('agentUpdate', handleAgentUpdate);
    socket.on('agentDelete', handleAgentDelete);

    socket.on('categoryCreate', handleCategoryCreate);
    socket.on('categoryUpdate', handleCategoryUpdate);
    socket.on('categoryDelete', handleCategoryDelete);

    socket.on('accountCreate', handleAccountCreate);
    socket.on('accountUpdate', handleAccountUpdate);
    socket.on('accountDelete', handleAccountDelete);

    socket.on('groupCreate', handleGroupCreate);
    socket.on('groupUpdate', handleGroupUpdate);
    socket.on('groupDelete', handleGroupDelete);

    socket.on('groupMembershipCreate', handleGroupMembershipCreate);
    socket.on('groupMembershipDelete', handleGroupMembershipDelete);

    socket.on('blockCreate', handleBlockCreate);
    socket.on('blockUpdate', handleBlockUpdate);
    socket.on('blockDelete', handleBlockDelete);

    socket.on('customerCreate', handleCustomerCreate);
    socket.on('customerUpdate', handleCustomerUpdate);
    socket.on('customerDelete', handleCustomerDelete);

    socket.on('labelCreate', handleLabelCreate);
    socket.on('labelUpdate', handleLabelUpdate);
    socket.on('labelDelete', handleLabelDelete);

    socket.on('productCreate', handleProductCreate);
    socket.on('productUpdate', handleProductUpdate);
    socket.on('productDelete', handleProductDelete);
    socket.on('productImport', handleProductImport);

    socket.on('sellitemCreate', handleSellitemCreate);
    socket.on('sellitemUpdate', handleSellitemUpdate);
    socket.on('sellitemDelete', handleSellitemDelete);

    socket.on('sellitemMembershipCreate', handleUserToSellitemAdd);
    socket.on('sellitemMembershipDelete', handleUserFromSellitemRemove);

    socket.on('sellitemLabelCreate', handleLabelToSellitemAdd);
    socket.on('sellitemLabelDelete', handleLabelFromSellitemRemove);

    socket.on('paymentCreate', handlePaymentCreate);
    socket.on('paymentUpdate', handlePaymentUpdate);
    socket.on('paymentDelete', handlePaymentDelete);
    socket.on('paymentInit', handlePaymentInit);

    socket.on('bagCreate', handleBagCreate);
    socket.on('bagUpdate', handleBagUpdate);
    socket.on('bagDelete', handleBagDelete);

    socket.on('attachmentCreate', handleAttachmentCreate);
    socket.on('attachmentUpdate', handleAttachmentUpdate);
    socket.on('attachmentDelete', handleAttachmentDelete);

    socket.on('actionCreate', handleActionCreate);
    socket.on('actionUpdate', handleActionUpdate);
    socket.on('actionDelete', handleActionDelete);

    socket.on('notificationCreate', handleNotificationCreate);
    socket.on('notificationUpdate', handleNotificationDelete);

    socket.on('sellitemCustomerDelete', handleSellitemCustomerDelete);
    socket.on('sellitemCustomerCreate', handleSellitemCustomerCreate);

    socket.on('sellitemDiscountCreate', handleSellitemDiscountCreate);
    socket.on('sellitemDiscountDelete', handleSellitemDiscountDelete);

    return () => {
      socket.off('disconnect', handleDisconnect);
      socket.off('reconnect', handleReconnect);

      socket.off('userCreate', handleUserCreate);
      socket.off('userUpdate', handleUserUpdate);
      socket.off('userDelete', handleUserDelete);

      socket.off('projectCreate', handleProjectCreate);
      socket.off('projectUpdate', handleProjectUpdate);
      socket.off('projectDelete', handleProjectDelete);

      socket.off('projectManagerCreate', handleProjectManagerCreate);
      socket.off('projectManagerDelete', handleProjectManagerDelete);

      socket.off('agentCreate', handleAgentCreate);
      socket.off('agentUpdate', handleAgentUpdate);
      socket.off('agentDelete', handleAgentDelete);

      socket.off('categoryCreate', handleCategoryCreate);
      socket.off('categoryUpdate', handleCategoryUpdate);
      socket.off('categoryDelete', handleCategoryDelete);

      socket.off('accountCreate', handleAccountCreate);
      socket.off('accountUpdate', handleAccountUpdate);
      socket.off('accountDelete', handleAccountDelete);

      socket.off('groupCreate', handleGroupCreate);
      socket.off('groupUpdate', handleGroupUpdate);
      socket.off('groupDelete', handleGroupDelete);

      socket.off('groupMembershipCreate', handleGroupMembershipCreate);
      socket.off('groupMembershipDelete', handleGroupMembershipDelete);

      socket.off('blockCreate', handleBlockCreate);
      socket.off('blockUpdate', handleBlockUpdate);
      socket.off('blockDelete', handleBlockDelete);

      socket.off('customerCreate', handleCustomerCreate);
      socket.off('customerUpdate', handleCustomerUpdate);
      socket.off('customerDelete', handleCustomerDelete);

      socket.off('productCreate', handleProductCreate);
      socket.off('productUpdate', handleProductUpdate);
      socket.off('productDelete', handleProductDelete);
      socket.off('productImport', handleProductImport);

      socket.off('labelCreate', handleLabelCreate);
      socket.off('labelUpdate', handleLabelUpdate);
      socket.off('labelDelete', handleLabelDelete);

      socket.off('sellitemCreate', handleSellitemCreate);
      socket.off('sellitemUpdate', handleSellitemUpdate);
      socket.off('sellitemDelete', handleSellitemDelete);

      socket.off('sellitemMembershipCreate', handleUserToSellitemAdd);
      socket.off('sellitemMembershipDelete', handleUserFromSellitemRemove);

      socket.off('sellitemLabelCreate', handleLabelToSellitemAdd);
      socket.off('sellitemLabelDelete', handleLabelFromSellitemRemove);

      socket.off('paymentCreate', handlePaymentCreate);
      socket.off('paymentUpdate', handlePaymentUpdate);
      socket.off('paymentDelete', handlePaymentDelete);
      socket.off('paymentInit', handlePaymentInit);

      socket.off('bagCreate', handleBagCreate);
      socket.off('bagUpdate', handleBagUpdate);
      socket.off('bagDelete', handleBagDelete);

      socket.off('attachmentCreate', handleAttachmentCreate);
      socket.off('attachmentUpdate', handleAttachmentUpdate);
      socket.off('attachmentDelete', handleAttachmentDelete);

      socket.off('actionCreate', handleActionCreate);
      socket.off('actionUpdate', handleActionUpdate);
      socket.off('actionDelete', handleActionDelete);

      socket.off('notificationCreate', handleNotificationCreate);
      socket.off('notificationUpdate', handleNotificationDelete);

      socket.off('sellitemCustomerDelete', handleSellitemCustomerDelete);
      socket.off('sellitemCustomerCreate', handleSellitemCustomerCreate);

      socket.off('sellitemDiscountCreate', handleSellitemDiscountCreate);
      socket.off('sellitemDiscountDelete', handleSellitemDiscountDelete);
    };
  });

export default function* socketWatchers() {
  yield all([
    yield takeEvery(EntryActionTypes.SOCKET_DISCONNECT_HANDLE, () =>
      handleSocketDisconnectService(),
    ),
    yield takeEvery(EntryActionTypes.SOCKET_RECONNECT_HANDLE, () => handleSocketReconnectService()),
  ]);

  const socketEventsChannel = yield call(createSocketEventsChannel);

  try {
    while (true) {
      const action = yield take(socketEventsChannel);

      yield put(action);
    }
  } finally {
    if (yield cancelled()) {
      socketEventsChannel.close();
    }
  }
}
