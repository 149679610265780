import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { currentUserIdSelector } from './user';
import { isLocalId } from '../utils/local-id';

export const currentProjectSelector = createSelector(
  orm,
  (state) => pathSelector(state).projectId,
  (state) => currentUserIdSelector(state),
  (state) => pathSelector(state).groupId,
  ({ Project, Group, Customer }, id, currentUserId, groupId) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    let firstGroupId = groupId;
    let firstCustomerId;
    let firstBagId;

    if (!firstGroupId) {
      const groupsModels = projectModel.getOrderedAvailableGroupsModelArray(currentUserId);
      firstGroupId = groupsModels[0] && groupsModels[0].id;
    }
    if (firstGroupId) {
      const groupModel = Group.withId(firstGroupId);
      // const customerIds = groupModel
      //   .getOrderedCustomersQuerySet()
      //   .toRefArray()
      //   .map((customer) => customer.id);
      // firstCustomerId = customerIds.length > 0 ? customerIds[0] : firstGroupId;
      firstCustomerId = Customer.last()?.ref.id || firstGroupId;

      const bagIds = groupModel
        .getOrderedBagsQuerySet()
        .toRefArray()
        .map((bag) => bag.id);
      firstBagId = bagIds.length > 0 ? bagIds[0] : firstGroupId;
    }
    // if (groupId) {
    //   const groupsModels = projectModel.getOrderedAvailableGroupsModelArray(currentUserId);
    //   firstGroupId = groupsModels[0] && groupsModels[0].id;
    // }

    return {
      ...projectModel.ref,
      firstGroupId,
      firstCustomerId,
      firstBagId,
    };
    // return projectModel.ref;
  },
);

export const managersForCurrentProjectSelector = createSelector(
  orm,
  (state) => pathSelector(state).projectId,
  (state) => currentUserIdSelector(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    return projectModel
      .getOrderedManagersQuerySet()
      .toModelArray()
      .map((projectManagerModel) => ({
        ...projectManagerModel.ref,
        isPersisted: !isLocalId(projectManagerModel.id),
        user: {
          ...projectManagerModel.user.ref,
          isCurrent: projectManagerModel.user.id === currentUserId,
        },
      }));
  },
);

export const agentsForCurrentProjectSelector = createSelector(
  orm,
  (state) => pathSelector(state).projectId,
  ({ Agent }, id) => {
    if (!id) {
      return id;
    }

    return Agent.all()
      .toModelArray()
      .map((agentModel) => ({
        ...agentModel.ref,
        isPersisted: !isLocalId(agentModel.id),
      }));
  },
);

export const categoriesForCurrentProjectSelector = createSelector(
  orm,
  (state) => pathSelector(state).projectId,
  ({ Category }, id) => {
    if (!id) {
      return id;
    }

    return Category.all()
      .toModelArray()
      .map((categoryModel) => ({
        ...categoryModel.ref,
        isPersisted: !isLocalId(categoryModel.id),
      }));
  },
);

export const accountsForCurrentProjectSelector = createSelector(
  orm,
  (state) => pathSelector(state).projectId,
  ({ Account }, id) => {
    if (!id) {
      return id;
    }

    // const projectModel = Project.withId(id);

    // if (!projectModel) {
    //   return projectModel;
    // }

    // TODO: nht
    return Account.all().toRefArray();
    // return projectModel
    //   .getOrderedAccountsQuerySet()
    //   .toModelArray()
    //   .map((accountModel) => ({
    //     ...accountModel.ref,
    //     isPersisted: !isLocalId(accountModel.id),
    //   }));
  },
);

export const groupsForCurrentProjectSelector = createSelector(
  orm,
  (state) => pathSelector(state).projectId,
  (state) => currentUserIdSelector(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    return projectModel.getOrderedAvailableGroupsModelArray(currentUserId).map((groupModel) => ({
      ...groupModel.ref,
      isPersisted: !isLocalId(groupModel.id),
    }));
  },
);

export const isCurrentUserManagerForCurrentProjectSelector = createSelector(
  orm,
  (state) => pathSelector(state).projectId,
  (state) => currentUserIdSelector(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return false;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return false;
    }

    return projectModel.hasManagerUser(currentUserId);
  },
);

export const agentByProductIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ Agent, Product }, id) => {
    if (!id) {
      return id;
    }

    const productModel = Product.withId(id);

    if (!productModel) {
      return productModel;
    }

    const agentModel = Agent.withId(productModel.agentId);

    if (!agentModel) {
      return agentModel;
    }

    return agentModel.ref;
  },
);

export const categoryByProductIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ Category, Product }, id) => {
    if (!id) {
      return id;
    }

    const productModel = Product.withId(id);

    if (!productModel) {
      return productModel;
    }

    const categoryModel = Category.withId(productModel.categoryId);

    if (!categoryModel) {
      return categoryModel;
    }

    return categoryModel.ref;
  },
);
