import EntryActionTypes from '../../constants/EntryActionTypes';

export const createBagitem = (data) => ({
  type: EntryActionTypes.BAGITEM_CREATE,
  payload: {
    data,
  },
});

export const handleBagitemCreate = (bagitem) => ({
  type: EntryActionTypes.BAGITEM_CREATE_HANDLE,
  payload: {
    bagitem,
  },
});

export const updateBagitem = (id, data) => ({
  type: EntryActionTypes.BAGITEM_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleBagitemUpdate = (bagitem) => ({
  type: EntryActionTypes.BAGITEM_UPDATE_HANDLE,
  payload: {
    bagitem,
  },
});

export const deleteBagitem = (id) => ({
  type: EntryActionTypes.BAGITEM_DELETE,
  payload: {
    id,
  },
});

export const handleBagitemDelete = (bagitem) => ({
  type: EntryActionTypes.BAGITEM_DELETE_HANDLE,
  payload: {
    bagitem,
  },
});
