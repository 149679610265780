import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { lastActionIdBySellitemIdSelector, pathSelector } from '../../../selectors';
import { fetchActions } from '../../../actions';
import api from '../../../api';

export function* fetchActionsService(sellitemId) {
  const lastId = yield select(lastActionIdBySellitemIdSelector, sellitemId);

  yield put(fetchActions(sellitemId));

  let actions;
  let users;

  try {
    ({
      items: actions,
      included: { users },
    } = yield call(request, api.getActions, sellitemId, {
      beforeId: lastId,
    }));
  } catch (error) {
    yield put(fetchActions.failure(sellitemId, error));
    return;
  }

  yield put(fetchActions.success(sellitemId, actions, users));
}

export function* fetchActionsInCurrentSellitemService() {
  const { sellitemId } = yield select(pathSelector);

  yield call(fetchActionsService, sellitemId);
}
