/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'semantic-ui-react';
import HotTable, { HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';

import Paths from '../../constants/Paths';

import styles from './LeftSidebar.module.scss';

const LeftSidebar = React.memo(({ groupId, products, sellitemId, sellitems, canEdit }) => {
  const [t] = useTranslation();
  const history = useHistory();

  const hotTableRef = useRef();
  const [sidebarActive, setSidebarActive] = useState(false);
  const [tableData, setTableData] = useState([...sellitems]);

  const afterSelection = useCallback(
    (row) => {
      const id = hotTableRef.current.hotInstance.getDataAtRowProp(row, 'id');
      if (id !== sellitemId) {
        history.push(Paths.SELLITEMS.replace(':id', id));
      }
    },
    [sellitemId, history],
  );

  const itemRenderer = (instance, td, row, col, prop, value) => {
    /* eslint-disable no-param-reassign */
    if (value) {
      const sellitemData = sellitems[row];
      const div = document.createElement('div');
      div.classList.add(styles.item);
      if (sellitemData) {
        ReactDOM.render(
          <>
            <div className={styles.dateCreated}>
              <span className={styles.dateMonth}>
                {format(new Date(sellitemData.bookDate), 'dd/MM')}
              </span>
              <span className={styles.year}>{format(new Date(sellitemData.bookDate), 'yyyy')}</span>
            </div>
            <div className={styles.itemLeftWrapper}>
              <b>{value}</b> | {sellitemData.customer?.name || ''}
              <br />
              <span className={styles.status}>{t(`status.${sellitemData.status}`)}</span>
            </div>
            <div className={styles.itemRightWrapper}>
              {sellitemData.notifications.length > 0 && (
                <span className={styles.notiCount}>{sellitemData.notifications.length}</span>
              )}
              <span className={styles.agentName}>{sellitemData.agent?.name}</span>
              <span className={styles.sellPrice}>
                {(sellitemData.sellPrice * 1).toLocaleString('en-GB')} đ
              </span>
            </div>
          </>,
          div,
        );
      }
      Handsontable.dom.empty(td);
      td.appendChild(div);
      td.classList.add(styles.sellitem);
      if (sellitemData?.id && sellitemData.id === sellitemId) td.classList.add(styles.current);
    }
    /* eslint-enable no-param-reassign */
    return td;
  };

  const productOptions = [{ key: 0, value: 0, text: t('common.all') }];
  products.forEach((pr) => {
    productOptions.push({ key: pr.id, value: pr.id, text: pr.name });
  });

  const handleCreateSellitem = useCallback(() => {
    history.push(Paths.SELLITEMS.replace(':id', groupId));
  }, [groupId, history]);

  const handleToggleSidebar = useCallback(() => {
    setSidebarActive(!sidebarActive);
  }, [sidebarActive]);

  const handleChangeProduct = useCallback(
    (e, dataValue) => {
      if (dataValue.value !== 0) {
        setTableData(sellitems.filter((sl) => sl.productId === dataValue.value));
      } else {
        setTableData(sellitems);
      }
    },
    [sellitems],
  );

  useEffect(() => {
    setTableData(sellitems);
  }, [sellitems]);

  return (
    <>
      <Button
        size="mini"
        icon={sidebarActive ? 'angle left' : 'angle right'}
        className={`${styles.buttonToggleSidebar} ${sidebarActive && styles.active}`}
        onClick={handleToggleSidebar}
      />
      {sidebarActive && <div className={styles.bgOverlay} />}
      <div className={`${styles.sidebarLeft} ${sidebarActive && styles.active}`}>
        <div className={styles.wrapperSearchSellitems}>
          <div className={styles.addSellitemWrapper}>
            <Dropdown
              placeholder={t('common.enterNameProductToSearch')}
              fluid
              search
              selection
              onChange={handleChangeProduct}
              options={productOptions}
            />
            {canEdit && (
              <Button
                size="mini"
                icon="add"
                className={styles.addSellitemButton}
                onClick={handleCreateSellitem}
              />
            )}
          </div>
        </div>
        <div className={`scroller ${styles.wrapperSellitems} ${canEdit && styles.isAdmin}`}>
          {sellitems.length > 0 && (
            <HotTable
              ref={hotTableRef}
              data={tableData}
              height="100%"
              stretchH="all"
              autoColumnSize={false}
              autoRowSize={false}
              disableVisualSelection
              readOnly
              colHeaders={false}
              rowHeaders={false}
              rowHeights={43}
              selectionMode="single"
              afterSelection={afterSelection}
              licenseKey="non-commercial-and-evaluation"
            >
              <HotColumn data="product.name" renderer={itemRenderer} />
            </HotTable>
          )}
        </div>
      </div>
    </>
  );
});

LeftSidebar.propTypes = {
  groupId: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.object()).isRequired,
  sellitemId: PropTypes.string.isRequired,
  sellitems: PropTypes.arrayOf(PropTypes.object()).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default LeftSidebar;
