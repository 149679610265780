import ActionTypes from '../constants/ActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const initializeCore = (
  user,
  group,
  users,
  projects,
  projectManagers,
  agents,
  categories,
  accounts,
  groups,
  groupMemberships,
  labels,
  blocks,
  customers,
  products,
  bags,
  bagitems,
  discounts,
  sellitems,
  sellitemMemberships,
  sellitemLabels,
  sellitemCustomers,
  sellitemDiscounts,
  payments,
  attachments,
  actions,
  notifications,
) => ({
  type: ActionTypes.CORE_INITIALIZE,
  payload: {
    user,
    group,
    users,
    projects,
    projectManagers,
    agents,
    categories,
    accounts,
    groups,
    groupMemberships,
    labels,
    blocks,
    customers,
    products,
    bags,
    bagitems,
    discounts,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    sellitemCustomers,
    sellitemDiscounts,
    payments,
    attachments,
    actions,
    notifications,
  },
});
