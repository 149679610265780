import ActionTypes from '../constants/ActionTypes';

export const createBagitem = (bagitem) => ({
  type: ActionTypes.BAGITEM_CREATE,
  payload: {
    bagitem,
  },
});

createBagitem.success = (localId, bagitem, bag) => ({
  type: ActionTypes.BAGITEM_CREATE__SUCCESS,
  payload: {
    localId,
    bagitem,
    bag,
  },
});

createBagitem.failure = (localId, error) => ({
  type: ActionTypes.BAGITEM_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleBagitemCreate = (bagitem) => ({
  type: ActionTypes.BAGITEM_CREATE_HANDLE,
  payload: {
    bagitem,
  },
});

export const updateBagitem = (id, data) => ({
  type: ActionTypes.BAGITEM_UPDATE,
  payload: {
    id,
    data,
  },
});

updateBagitem.success = (bagitem, bag) => ({
  type: ActionTypes.BAGITEM_UPDATE__SUCCESS,
  payload: {
    bagitem,
    bag,
  },
});

updateBagitem.failure = (id, error) => ({
  type: ActionTypes.BAGITEM_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBagitemUpdate = (bagitem) => ({
  type: ActionTypes.BAGITEM_UPDATE_HANDLE,
  payload: {
    bagitem,
  },
});

export const deleteBagitem = (id) => ({
  type: ActionTypes.BAGITEM_DELETE,
  payload: {
    id,
  },
});

deleteBagitem.success = (bagitem) => ({
  type: ActionTypes.BAGITEM_DELETE__SUCCESS,
  payload: {
    bagitem,
  },
});

deleteBagitem.failure = (id, error) => ({
  type: ActionTypes.BAGITEM_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBagitemDelete = (bagitem) => ({
  type: ActionTypes.BAGITEM_DELETE_HANDLE,
  payload: {
    bagitem,
  },
});
