/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Form, TextArea, Button } from 'semantic-ui-react';

import styles from './ConfirmForm.module.scss';
import { useForm } from '../../../hooks';

const ConfirmForm = React.memo(
  ({ sellitem, onUpdateSellitem, openModal, onClose, paymentMethod, changePaymentMethod }) => {
    const [t] = useTranslation();

    const [data, handleChangeData] = useForm({
      note: '',
    });
    const handleSubmitConfirm = useCallback(() => {
      const newData = {
        paymentMethod,
        statusNote: data.note || undefined,
      };
      onUpdateSellitem(sellitem.id, newData);
      onClose();
    }, [data.note, onClose, onUpdateSellitem, paymentMethod, sellitem.id]);

    const handleSubmitCancel = useCallback(() => {
      changePaymentMethod(sellitem.paymentMethod);
      onClose();
    }, [changePaymentMethod, onClose, sellitem.paymentMethod]);

    return (
      <Modal open={openModal} closeIcon size="small" centered={false} onClose={onClose}>
        <Modal.Header>{t('common.confirmChangePaymentMethod')}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <TextArea name="note" onChange={handleChangeData} placeholder={t('common.reason')} />
            </Form.Field>
            <Button className={styles.buttonReview} primary onClick={handleSubmitConfirm}>
              {t('action.confirm')}
            </Button>
            <Button className={styles.buttonReview} color="red" onClick={handleSubmitCancel}>
              {t('action.cancel')}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  },
);

ConfirmForm.propTypes = {
  sellitem: PropTypes.objectOf(PropTypes.shape),
  openModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onUpdateSellitem: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string,
  changePaymentMethod: PropTypes.func.isRequired,
};

ConfirmForm.defaultProps = {
  sellitem: undefined,
  openModal: undefined,
  paymentMethod: undefined,
};

export default ConfirmForm;
